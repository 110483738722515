import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import SectionRecord from "models/view-models/section-record";
import UserBookmarkRecord from "models/view-models/user-bookmark-record";
import MenuButton from "molecules/menu-button/menu-button";
import { ConfirmationModal } from "molecules/modals/confirmation-modal";
import ExpandableRichTextArea from "molecules/rich-text-area/expandable-rich-text-area";
import OfflineAlertModal from "organisms/modals/offline-alert-modal/offline-alert-modal";
import BookmarkCollectionsBadge from "organisms/my-link/my-bookmarks/bookmark-collections-badge";
import { BookmarkSliderProps } from "organisms/section-detail/bookmark-slider";
import React, { useCallback, useMemo, useState } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import useBookmarkCollections from "utilities/hooks/domain/my-link/use-bookmark-collections";
import useDeleteBookmark from "utilities/hooks/domain/my-link/use-delete-bookmark";
import useBookviewTableOfContents from "utilities/hooks/domain/publications/use-bookview-table-of-contents";
import useBookmarks from "utilities/hooks/domain/user-bookmarks/use-bookmarks";
import useUser from "utilities/hooks/domain/users/use-user";
import { useLocalization } from "utilities/hooks/use-localization";
import useModalActions from "utilities/hooks/use-modal-actions";
import RichTextUtils from "utilities/rich-text-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface BookmarkReadOnlyDialogProps
    extends Omit<BookmarkSliderProps, "bookmarks"> {
    bookmark: UserBookmarkRecord;
    isMobile?: boolean;
    modifierClass?: string;
    section?: SectionRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-bookmark-read-only-dialog";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const BookmarkReadOnlyDialog: React.FC<BookmarkReadOnlyDialogProps> = (
    props: BookmarkReadOnlyDialogProps
) => {
    const {
        bookmark,
        isMobile,
        modifierClass,
        onDeleted,
        onEdit,
        title,
        section,
    } = props;

    // -----------------------------------------------------------------------------------------
    // #region Custom Hooks
    // -----------------------------------------------------------------------------------------

    const { collections } = useBookmarkCollections(bookmark.id, false);
    const { deleteBookmark } = useDeleteBookmark(bookmark, onDeleted);
    const { globalState } = useGlobalState();
    const { isOnline } = useNetworkInformation();
    const { removeUserBookmark } = useBookmarks();
    const { t } = useLocalization();
    const { user } = useUser(bookmark.createdById);

    const {
        isOpen: isOfflineAlertModalOpen,
        handleClose: handleOfflineAlertModalClose,
        handleOpen: handleOfflineAlertModalOpen,
    } = useModalActions();

    const { tableOfContents, updateBookmarkIndicator } =
        useBookviewTableOfContents();

    // #endregion Custom Hooks

    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------

    const [hasExpandableContent, setHasExpandableContent] = useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
        useState(false);

    // #endregion State

    // -----------------------------------------------------------------------------------------
    // #region Computations/Transformations
    // -----------------------------------------------------------------------------------------

    const bookmarkModifier = bookmark.getColorClassModifier();

    const cssClassNames = [CSS_CLASS_NAME];
    if (modifierClass) {
        cssClassNames.push(modifierClass);
    }

    const expandableContentModifier = hasExpandableContent
        ? "-has-expandable-content"
        : "";

    const currentUser = globalState.currentIdentity?.userId();
    const createdByCurrentUser = bookmark?.userId === currentUser;

    const createdByText = useMemo(() => {
        if (user == null) {
            return "";
        }

        if (user.id === currentUser) {
            return t("byUser", { user: t("you") });
        }

        return t("byUser", { user: user.getFirstAndLastName() });
    }, [currentUser, t, user]);

    const publicationLabel = `${bookmark.publicationEdition} ${t("edition")}`;

    const createdDatePublicationText = t("bookmarkModifiedText", {
        modifiedDate: bookmark.getModifiedDateFromNowText(),
        publication: publicationLabel,
    });

    // #endregion Computations/Transformations

    // -----------------------------------------------------------------------------------------
    // #region Event Handlers
    // -----------------------------------------------------------------------------------------

    const handleDeleteConfirmed = useCallback(async () => {
        setShowDeleteConfirmationModal(false);
        await deleteBookmark();
        removeUserBookmark(bookmark);
        updateBookmarkIndicator(false, tableOfContents, section);
    }, [
        deleteBookmark,
        removeUserBookmark,
        bookmark,
        updateBookmarkIndicator,
        section,
        tableOfContents,
    ]);

    const handleDelete = useCallback(() => {
        if (isOnline) {
            setShowDeleteConfirmationModal(true);
            return;
        }

        if (isMobile) {
            handleOfflineAlertModalOpen();
        }
    }, [handleOfflineAlertModalOpen, isMobile, isOnline]);

    const handleEdit = useCallback(
        (bookmarkId: number) => {
            if (isOnline) {
                onEdit(bookmarkId);
                return;
            }

            if (isMobile) {
                handleOfflineAlertModalOpen();
            }
        },
        [handleOfflineAlertModalOpen, isMobile, isOnline, onEdit]
    );

    // #endregion Event Handlers

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    return (
        <div className={cssClassNames.join(" ")}>
            <div className={`${CSS_CLASS_NAME}__header`}>
                <span
                    className={`${CSS_CLASS_NAME}__header__icon`}
                    tabIndex={0}>
                    <Icon
                        cssClassName={bookmarkModifier}
                        type={Icons.Bookmark}
                    />
                    {title}
                </span>
                <div className={CSS_CLASS_NAME + "__header__actions"}>
                    {createdByCurrentUser && !isMobile && (
                        <MenuButton
                            buttonAccessibleText={t("actionsForItem", {
                                item: t("bookmark"),
                            })}
                            dataTestId="menu-button"
                            // disabled={!isOnline}
                            icon={Icons.MoreHorizontal}
                            iconSize={IconSizes.Base}
                            triggerButtonSize={ButtonSizes.Small}
                            triggerButtonStyle={ButtonStyles.TertiaryAlt}
                            offlineAlertOptions={{
                                enabled: true,
                                tooltipDescription: t(
                                    "offline-fallback-description"
                                ),
                                tooltipHeader: t(
                                    "section-detail-menu-feature-name"
                                ),
                            }}>
                            <Button
                                dataTestId={"edit"}
                                onClick={() => onEdit(bookmark.id!)}>
                                {t("editItem", { item: t("bookmark") })}
                            </Button>
                            <Button
                                dataTestId={"delete"}
                                onClick={() =>
                                    setShowDeleteConfirmationModal(true)
                                }>
                                {t("deleteItem", {
                                    item: t("bookmark"),
                                })}
                            </Button>
                        </MenuButton>
                    )}
                </div>
            </div>
            <div className={`${CSS_CLASS_NAME}__content`}>
                {
                    //if
                    RichTextUtils.hasValue(bookmark.description) && (
                        <div
                            className={`${CSS_CLASS_NAME}__body__description ${bookmarkModifier} ${expandableContentModifier}`}
                            tabIndex={0}>
                            <ExpandableRichTextArea
                                content={bookmark.description}
                                collapsedMaxHeight={93}
                                hasExpandableContent={hasExpandableContent}
                                setHasExpandableContent={
                                    setHasExpandableContent
                                }
                            />
                        </div>
                    )
                }
            </div>
            <div tabIndex={0} className={`${CSS_CLASS_NAME}__footer`}>
                <div className={`${CSS_CLASS_NAME}__footer__meta-info`}>
                    {CollectionUtils.hasValues(collections) && (
                        <BookmarkCollectionsBadge collections={collections} />
                    )}
                    <div
                        className={`${CSS_CLASS_NAME}__footer__timestamp`}
                        tabIndex={0}>
                        {createdDatePublicationText}
                        <span
                            className={`${CSS_CLASS_NAME}__footer__timestamp-author`}>
                            {` ${createdByText}`}
                        </span>
                    </div>
                </div>
                {createdByCurrentUser && isMobile && (
                    <div className={`${CSS_CLASS_NAME}__footer__actions`}>
                        <Button
                            cssClassName={`${CSS_CLASS_NAME}__footer__actions__delete-button`}
                            onClick={handleDelete}
                            size={ButtonSizes.XSmall}
                            style={ButtonStyles.Tertiary}>
                            <Icon type={Icons.Trashcan} size={IconSizes.Base} />
                        </Button>
                        <Button
                            onClick={() => handleEdit(bookmark.id!)}
                            size={ButtonSizes.Small}>
                            {t("edit")}
                        </Button>
                    </div>
                )}
            </div>
            <ConfirmationModal
                confirmButtonText={t("yesAction", { action: t("delete") })}
                isVisible={showDeleteConfirmationModal}
                label={t("userbookmarks-deleteLabel")}
                message={t("userbookmarks-deleteMessage")}
                onCancel={() => setShowDeleteConfirmationModal(false)}
                onConfirm={handleDeleteConfirmed}
            />
            {isOfflineAlertModalOpen && (
                <OfflineAlertModal
                    isOpen={isOfflineAlertModalOpen}
                    handleClose={handleOfflineAlertModalClose}
                />
            )}
        </div>
    );

    // #endregion Render
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default BookmarkReadOnlyDialog;

// #endregion Exports
