import { RecordUtils } from "@rsm-hcd/javascript-core";
import { List, Record } from "immutable";
import type File from "models/interfaces/file";
import FileRecord from "models/view-models/file-record";
import type { ResourceRecord } from "models/interfaces/resource-record";
import SolutionResourceRecord from "models/view-models/situational-navigation/solutions/solution-resource-record";
import ResourceType from "utilities/enumerations/resource-type";
import type ResourceCollection from "utilities/interfaces/resources/resource-collection";
import type { Constructor } from "utilities/types/constructor";

const defaultValues: ResourceCollection<any> = RecordUtils.defaultValuesFactory(
    {
        resources: List(),
        files: List<FileRecord>(),
    }
);

export default class ResourceCollectionRecord<T extends ResourceRecord>
    extends Record(defaultValues)
    implements ResourceCollection<T>
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    private readonly recordConstructor: Constructor<T>;

    // #endregion Properties

    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(
        recordConstructor: Constructor<T>,
        params?: ResourceCollection<T>
    ) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        params.resources = List(
            params.resources.map((r: T) =>
                RecordUtils.ensureRecord(r, recordConstructor)
            )
        );
        params.files = List(
            params.files.map((f: File) =>
                RecordUtils.ensureRecord(f, FileRecord)
            )
        );

        super(params);
        this.recordConstructor = recordConstructor;
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Return whether any files match predicate.
     * @param predicate
     */
    public anyFiles(predicate: (f: FileRecord) => boolean): boolean {
        return this.files.some(predicate);
    }

    /**
     * Return whether any resources match predicate.
     * @param predicate
     */
    public anyResources(predicate: (r: T) => boolean): boolean {
        return this.resources.some(predicate);
    }

    /**
     * Create a copy of this collection.
     */
    public clone(): ResourceCollectionRecord<T> {
        return new ResourceCollectionRecord(
            this.recordConstructor,
            this.toJS() as ResourceCollection<T>
        );
    }

    /**
     * Get resources where the file type is Document
     */
    public documents(): ResourceCollectionRecord<T> {
        return this.filter(
            (r: T, f?: FileRecord) =>
                f != null && f.resourceType() === ResourceType.Document
        );
    }

    /**
     * Get external resources.
     */
    public externalResources(): ResourceCollectionRecord<T> {
        return this.filter((r: T) => r.isExternal());
    }

    public filter(
        predicate: (r: T, f?: FileRecord) => boolean,
        draft: boolean = false
    ): ResourceCollectionRecord<T> {
        const resources: Array<T> = [];
        const files: Array<FileRecord> = [];
        this.resources.forEach((r: T) => {
            const file = this.getFile(draft ? r.fileDraftId : r.fileId);
            const shouldInclude = predicate(r, file);
            if (shouldInclude) {
                resources.push(r);
                if (file != null) {
                    files.push(file);
                }
            }
        });
        return ResourceCollectionRecord.fromArrays(
            this.recordConstructor,
            resources,
            files
        );
    }

    public getFile(id?: number): FileRecord | undefined {
        if (id == null) {
            return undefined;
        }

        return this.files.find((f: FileRecord) => f.id === id);
    }

    /**
     * Get the FileRecord associated with the SolutionResourceRecord
     * specified by the id param
     * @param id
     */
    public getFileByResourceId(id: number): FileRecord | undefined {
        const resource = this.getResource(id);

        if (resource == null || resource.fileId == null) {
            return undefined;
        }

        return this.files.find((f: FileRecord) => resource.fileId === f.id);
    }

    /**
     * Get SolutionResourceRecord by id, if it exists.
     * @param id
     */
    public getResource(id: number): T | undefined {
        return this.resources.find((r: T) => r.id === id);
    }

    /**
     * Finds the resource by id. If the resource is external, returns
     * resource.absoluteUrl, otherwise, finds the file associated with
     * the resource, if it exists, and returns the filename.
     * @param id
     */
    public getUrlOrFilenameByResourceId(id: number): string {
        const resource = this.getResource(id);

        if (resource == null) {
            return "";
        }

        if (resource.isExternal()) {
            return resource.absoluteUrl!;
        }

        const file = this.getFileByResourceId(id);
        if (file == null) {
            return "";
        }

        return file.fileName();
    }

    /**
     * Return a new collection filtered by file predicate.
     * @param predicate
     */
    public filterByFile(
        predicate: (f: FileRecord) => boolean
    ): ResourceCollectionRecord<T> {
        return this.with({ files: this.files.filter(predicate) });
    }

    /**
     * Return a new collection filtered by resource predicate.
     * @param predicate
     */
    public filterResources(
        predicate: (r: SolutionResourceRecord) => boolean
    ): ResourceCollectionRecord<T> {
        return this.with({ resources: this.resources.filter(predicate) });
    }

    /**
     * Returns true is this.resources is not empty, false otherwise.
     */
    public hasValues(): boolean {
        return !this.isEmpty();
    }

    /**
     * Returns true if this.resources is empty, false otherwise.
     */
    public isEmpty(): boolean {
        return this.resources.isEmpty();
    }

    /**
     * Get resources where the file type is Image
     */
    public images(): ResourceCollectionRecord<T> {
        return this.filter(
            (r: T, f?: FileRecord) =>
                f != null && f.resourceType() === ResourceType.Image
        );
    }

    public map<V>(
        predicate: (
            resource: T,
            file: FileRecord | undefined,
            index: number
        ) => V,
        draft: boolean = false
    ): List<V> {
        if (draft) {
            return List(
                this.resources.map((r: T, index: number) =>
                    predicate(r, this.getFile(r.fileDraftId), index)
                )
            );
        }

        return List(
            this.resources.map((r: T, index: number) =>
                predicate(r, this.getFile(r.fileId), index)
            )
        );
    }

    /**
     * Find and return the first resource/file pair that matches the predicate.
     * Returns a 2 element array, where the first element is the resource and
     * the 2nd is the file, if it exists. If no items match the predicate,
     * returns [undefined, undefined].
     * @param predicate
     * @returns {resource: SolutionResourceRecord | undefined, file: FileRecord | undefined}
     */
    public find(predicate: (resource: T, file?: FileRecord) => boolean): {
        resource: T | undefined;
        file: FileRecord | undefined;
    } {
        for (let resource of this.resources.toArray()) {
            const file = this.getFileByResourceId(resource.id ?? -1);
            if (predicate(resource, file)) {
                return { resource, file };
            }
        }

        return { resource: undefined, file: undefined };
    }

    /**
     * Get total number of resources.
     */
    public size(): number {
        return this.resources.size;
    }

    /**
     * Get resources where the file type is unknown (File)
     */
    public otherFiles(): ResourceCollectionRecord<T> {
        return this.filter(
            (r: T, f?: FileRecord) =>
                f != null && f.resourceType() === ResourceType.File
        );
    }

    /**
     * Get resources where the file type is Video
     */
    public videos(): ResourceCollectionRecord<T> {
        return this.filter(
            (r: T, f?: FileRecord) =>
                f != null && f.resourceType() === ResourceType.Video
        );
    }

    public with(
        values: Partial<ResourceCollection<T>>
    ): ResourceCollectionRecord<T> {
        return new ResourceCollectionRecord(
            this.recordConstructor,
            Object.assign(this.toJS(), values) as ResourceCollection<T>
        );
    }

    // #endregion Public Methods

    // -----------------------------------------------------------------------------------------
    // #region Static Members
    // -----------------------------------------------------------------------------------------

    /**
     * Create a new collection from an array of resources and array of files
     * @param recordConstructor
     * @param resources
     * @param files
     */
    public static fromArrays<V extends ResourceRecord>(
        recordConstructor: Constructor<V>,
        resources: Array<V>,
        files: Array<FileRecord>
    ): ResourceCollectionRecord<V> {
        return new ResourceCollectionRecord(recordConstructor, {
            resources: List(resources),
            files: List(files),
        });
    }

    // #endregion Static Members
}
