import { Record } from "immutable";
import type SituationCategory from "models/interfaces/situational-navigation/situations/situation-category";
import { RecordUtils } from "@rsm-hcd/javascript-core";

const defaultValues: SituationCategory =
    RecordUtils.auditableDefaultValuesFactory({
        categoryId: 0,
        deletedDraftById: undefined,
        deletedDraftOn: undefined,
        publishedById: undefined,
        publishedOn: undefined,
        situationId: 0,
    });

export default class SituationCategoryRecord
    extends Record(defaultValues)
    implements SituationCategory
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: SituationCategory) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    public with(values: Partial<SituationCategory>): SituationCategoryRecord {
        return new SituationCategoryRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}
