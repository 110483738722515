import { PagedQuery } from "@rsm-hcd/javascript-core";
import FreeAccessPublicationRecord from "models/view-models/free-access-publication-record";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AdminFreeAccessQueryParams extends PagedQuery {
    code?: string;
}

export interface AdminFreeAccessIdsQueryParams {
    ids: number[];
}

export interface AdminFreeAccessPathParams {
    id: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = "admin/free-access/publications";
const updateEndpoint = `${baseEndpoint}/:id`;
const publishEndpoint = `${updateEndpoint}/is-published`;
const idsListEndpoint = `${baseEndpoint}/ids`;
const resourceType = FreeAccessPublicationRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const AdminFreeAccessService = {
    list: ServiceFactory.list<
        FreeAccessPublicationRecord,
        AdminFreeAccessQueryParams
    >(resourceType, baseEndpoint),

    useListByIds: ServiceHookFactory.useBatchedList<
        FreeAccessPublicationRecord,
        AdminFreeAccessIdsQueryParams
    >(resourceType, idsListEndpoint),

    usePublish: ServiceHookFactory.useUpdate<
        FreeAccessPublicationRecord,
        AdminFreeAccessPathParams
    >(resourceType, publishEndpoint),

    useUpdate: ServiceHookFactory.useUpdate<
        FreeAccessPublicationRecord,
        AdminFreeAccessPathParams
    >(resourceType, updateEndpoint),

    useCreate: ServiceHookFactory.useCreate<FreeAccessPublicationRecord>(
        resourceType,
        baseEndpoint
    ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default AdminFreeAccessService;

// #endregion Exports
