import { Record } from "immutable";
import type TableDisplay from "models/interfaces/table-display";
import type { CSSProperties } from "react";
import { TableCellAlignmentStyle } from "utilities/enumerations/table-cell-align-style";
import { TableCellVerticalAlignmentStyle } from "utilities/enumerations/table-cell-vertical-align-style";
import type { TableCellBorderStyle } from "utilities/interfaces/table-cell-border-style";
import type TableStyle from "utilities/interfaces/table-style";
import type { TextAlignStyles } from "utilities/types/text-align-style";
import { RecordUtils } from "@rsm-hcd/javascript-core";

const defaultValues: TableDisplay =
    RecordUtils.defaultValuesFactory<TableDisplay>({
        codStyle: undefined,
        width: undefined,
    });

export default class TableDisplayRecord
    extends Record(defaultValues)
    implements TableDisplay
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // #endregion Properties

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: TableDisplay) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }
        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Translate string array of styles from xml into typed alignment style to be read in components
     * @param styles array of properties recieved
     */
    public getAlignmentStyle(styles: string[]): TableCellAlignmentStyle {
        const alignments: string[] = styles.filter((x) => {
            return x.match("^align") ? x : null;
        });

        if (alignments.includes("align-left")) {
            return TableCellAlignmentStyle.Left;
        }
        if (alignments.includes("align-right")) {
            return TableCellAlignmentStyle.Right;
        }
        if (alignments.includes("align-center")) {
            return TableCellAlignmentStyle.Center;
        }
        if (alignments.includes("align-justify")) {
            return TableCellAlignmentStyle.Justify;
        }
        return TableCellAlignmentStyle.None;
    }

    /**
     * Translate string array from xml into typed border styles to be read in components
     *
     * @param borders
     */
    public getBorderStyle(styles: string[]): TableCellBorderStyle {
        const borders: string[] = styles.filter((x) => {
            return x.includes("border") ? x : null;
        });

        if (borders.includes("border")) {
            return { Bottom: true, Left: true, Top: true, Right: true };
        }
        return {
            Bottom: borders.includes("border-bottom"),
            Left: borders.includes("border-left"),
            Top: borders.includes("border-top"),
            Right: borders.includes("border-right"),
        };
    }

    /**
     * Get CSS Properties to render on table object
     */
    public getStyle(): CSSProperties {
        let styles: CSSProperties = {};

        if (this.codStyle == null) {
            return styles;
        }

        const tableStyles: TableStyle = this.getTableStyle(this.codStyle);

        // Uncomment to add back initial border styles
        const borderStyle = tableStyles.borderStyle;
        if (borderStyle != null) {
            styles = {
                borderBottomStyle: borderStyle.Bottom ? "solid" : "initial",
                borderLeftStyle: borderStyle.Left ? "solid" : "initial",
                borderRightStyle: borderStyle.Right ? "solid" : "initial",
                borderTopStyle: borderStyle.Top ? "solid" : "initial",
            };
        }
        const alignStyles = tableStyles.cellAlign;
        if (alignStyles != null) {
            styles = {
                ...styles,
                textAlign: alignStyles as TextAlignStyles,
            };
        }
        const valign = tableStyles.vAlign;
        if (valign != null) {
            styles = {
                ...styles,
                verticalAlign: valign,
            };
        }

        if (this.width != null) {
            styles = {
                ...styles,
                width: this.width,
            };
        }

        return styles;
    }

    public getStyleWithoutBorders(): CSSProperties {
        let styles: CSSProperties = {};

        if (this.codStyle == null) {
            return styles;
        }

        const tableStyles: TableStyle = this.getTableStyleWithoutBorders(
            this.codStyle
        );

        const alignStyles = tableStyles.cellAlign;
        if (alignStyles != null) {
            styles = {
                ...styles,
                textAlign: alignStyles as TextAlignStyles,
            };
        }
        const valign = tableStyles.vAlign;
        if (valign != null) {
            styles = {
                ...styles,
                verticalAlign: valign,
            };
        }

        if (this.width != null) {
            styles = {
                ...styles,
                width: this.width,
            };
        }

        return styles;
    }

    public getTableStyleWithoutBorders(value: string) {
        const styles: string[] = value.split(" ");

        const alignmentStyle: TableCellAlignmentStyle =
            this.getAlignmentStyle(styles);
        const valignStyle: TableCellVerticalAlignmentStyle =
            this.getVerticalAlignmentStyle(styles);
        const charAlignStyle: boolean = styles.includes("char-align");

        const tableStyle: TableStyle = {
            cellAlign: alignmentStyle,
            vAlign: valignStyle,
            charDecimalAlign: charAlignStyle,
        };

        return tableStyle;
    }

    /**
     * Take a string value from xml and return a typed to properly style Table cells
     * @param value
     */
    public getTableStyle(value: string) {
        const styles: string[] = value.split(" ");
        const borderStyle: TableCellBorderStyle = this.getBorderStyle(styles);
        const alignmentStyle: TableCellAlignmentStyle =
            this.getAlignmentStyle(styles);
        const valignStyle: TableCellVerticalAlignmentStyle =
            this.getVerticalAlignmentStyle(styles);
        const charAlignStyle: boolean = styles.includes("char-align");

        const tableStyle: TableStyle = {
            borderStyle: borderStyle,
            cellAlign: alignmentStyle,
            vAlign: valignStyle,
            charDecimalAlign: charAlignStyle,
        };

        return tableStyle;
    }

    /**
     * Translate string array of styles from xml into typed vertical alignment to be read in components
     */
    public getVerticalAlignmentStyle(
        styles: string[]
    ): TableCellVerticalAlignmentStyle {
        const alignments: string[] = styles.filter((x) => {
            return x.match("^valign") ? x : null;
        });

        if (alignments.includes("valign-bottom")) {
            return TableCellVerticalAlignmentStyle.Bottom;
        }
        if (alignments.includes("valign-middle")) {
            return TableCellVerticalAlignmentStyle.Middle;
        }
        if (alignments.includes("valign-top")) {
            return TableCellVerticalAlignmentStyle.Top;
        }
        return TableCellVerticalAlignmentStyle.None;
    }

    // #endregion Public Methods
}
