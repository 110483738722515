import Anchor from "atoms/anchors/anchor";
import { Icons } from "atoms/constants/icons";
import TextInputIcon from "atoms/forms/text-input-icon";
import Loader from "atoms/loaders/loader";
import Paragraph from "atoms/typography/paragraph";
import { siteMap } from "internal-sitemap";
import PublishStatus from "models/enumerations/publish-status";
import SituationRecord from "models/view-models/situational-navigation/situations/situation-record";
import SolutionRecord from "models/view-models/situational-navigation/solutions/solution-record";
import EditorSidebarListItems from "organisms/admin/situational-navigation/editor-sidebar-list-items";
import PublishStatusFiltersBar from "organisms/admin/situational-navigation/publish-status-filters-bar";
import React, { useCallback, useEffect, useState } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import { useAdminEditorPageContext } from "utilities/contexts/admin/use-admin-editor-page-context";
import useDebounce from "utilities/hooks/use-debounce";
import useQuery from "utilities/hooks/use-query";
import PublishableFilters from "utilities/interfaces/publishable-filters";
import AdminSituationService from "utilities/services/admin/situational-navigation/situations/admin-situation-service";
import AdminSolutionService from "utilities/services/admin/situational-navigation/solutions/admin-solution-service";
import { ListServiceHook } from "utilities/services/service-hook-factory";
import StringUtils from "utilities/string-utils";
import { ToastManager } from "utilities/toast/toast-manager";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface EditorPageSidebarProps {
    type: typeof SituationRecord | typeof SolutionRecord;
}

interface EditorPageSideBarQueryParams {
    hasUnpublishedChanges?: boolean;
    publishStatus?: PublishStatus;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EditorPageSidebar: React.FC<EditorPageSidebarProps> = (
    props: EditorPageSidebarProps
) => {
    const CSS_CLASS_NAME = "c-admin-editor-page-layout";

    const { context } = useAdminEditorPageContext();
    const [searchText, setSearchText] = useState("");
    const debouncedSearchText = useDebounce(searchText);
    const service = (
        props.type === SituationRecord
            ? AdminSituationService
            : AdminSolutionService
    ) as {
        useList: ListServiceHook<
            SituationRecord | SolutionRecord,
            PublishableFilters
        >;
    };

    const getRecordLabel = useCallback(
        () => (props.type === SituationRecord ? "Situation" : "Solution"),
        [props.type]
    );

    const { query, setQuery, values, setValues, loading } = useQuery<
        SituationRecord | SolutionRecord,
        EditorPageSideBarQueryParams
    >(
        service,
        {},
        useCallback(
            () =>
                ToastManager.error(
                    `There was an issue searching ${getRecordLabel()}s.`
                ),
            [getRecordLabel]
        )
    );

    const handleSearchTextChanged = (e: React.ChangeEvent<HTMLInputElement>) =>
        setSearchText(e.target.value);

    const handlePublishStatusFiltersChanged = (
        newFilters: PublishableFilters
    ) =>
        setQuery(
            Object.assign({}, newFilters, { searchText: debouncedSearchText })
        );

    const getCreateLink = useCallback(
        () =>
            props.type === SituationRecord
                ? siteMap.admin.situationalNavigation.situations.create
                : siteMap.admin.situationalNavigation.solutions.create,
        [props.type]
    );

    const currentValueMatchesFilter = useCallback((): boolean => {
        if (context.currentValue == null) {
            return false;
        }

        let matches = true;
        if (StringUtils.hasValue(debouncedSearchText)) {
            if (
                !(
                    (
                        context.currentValue as SituationRecord | SolutionRecord
                    ).titleDraft
                        .toLowerCase()
                        .includes(debouncedSearchText!.toLowerCase()) ||
                    (
                        context.currentValue as SituationRecord | SolutionRecord
                    ).subtitleDraft
                        .toLowerCase()
                        .includes(debouncedSearchText!.toLowerCase())
                )
            ) {
                matches = false;
            }
        }

        if (query.publishStatus != null) {
            matches =
                matches &&
                (
                    context.currentValue as SituationRecord | SolutionRecord
                ).getPublishStatus() === query.publishStatus;
        }

        if (query.hasUnpublishedChanges != null) {
            matches =
                matches &&
                (
                    context.currentValue as SituationRecord | SolutionRecord
                ).hasUnpublishedChanges() === query.hasUnpublishedChanges;
        }

        return matches;
    }, [query, context.currentValue, debouncedSearchText]);

    const updateSidebarItemFromContext = useCallback(() => {
        setValues((values: Array<SolutionRecord | SituationRecord>) => {
            if (StringUtils.hasValue(context.currentValue?.deletedOn)) {
                if (props.type === SituationRecord) {
                    return (values as Array<SituationRecord>).filter(
                        (s: SituationRecord) =>
                            s.id !== context.currentValue?.id
                    );
                }

                return (values as Array<SolutionRecord>).filter(
                    (s: SolutionRecord) => s.id !== context.currentValue?.id
                );
            }

            const index = values.findIndex(
                (e: SituationRecord | SolutionRecord) =>
                    e.id === context.currentValue?.id
            );

            if (index < 0 && context.currentValue != null) {
                if (currentValueMatchesFilter()) {
                    // current search matches newly created record
                    return [
                        ...values,
                        context.currentValue as
                            | SituationRecord
                            | SolutionRecord,
                    ];
                }
            }

            if (currentValueMatchesFilter()) {
                return CollectionUtils.replaceElementAt(
                    values,
                    index,
                    context.currentValue! as SituationRecord | SolutionRecord
                );
            }

            return CollectionUtils.removeElementAt(values, index);
        });
    }, [
        context.currentValue,
        props.type,
        currentValueMatchesFilter,
        setValues,
    ]);

    useEffect(() => {
        setQuery((filters: PublishableFilters) =>
            Object.assign({}, filters, { searchText: debouncedSearchText })
        );
    }, [debouncedSearchText, setQuery]);

    useEffect(
        () => updateSidebarItemFromContext(),
        [context.currentValue, updateSidebarItemFromContext]
    );

    return (
        <div className={`${CSS_CLASS_NAME}__content__left`}>
            <TextInputIcon
                icon={Icons.Search}
                id="situation-sidebar-search"
                onChange={handleSearchTextChanged}
                value={searchText}
                placeholder={`Filter by ${getRecordLabel()?.toLowerCase()} title or subtitle...`}
            />
            <PublishStatusFiltersBar
                value={query}
                onChange={handlePublishStatusFiltersChanged}
            />
            <div className={`${CSS_CLASS_NAME}__content__left__list`}>
                {loading && (
                    <Loader
                        accessibleText={`Loading ${getRecordLabel()}s...`}
                    />
                )}
                {!loading && CollectionUtils.isEmpty(values) && (
                    <Paragraph cssClassName="-no-records-message">
                        No {getRecordLabel()}s
                    </Paragraph>
                )}
                {!loading && (
                    <EditorSidebarListItems
                        baseClassName={CSS_CLASS_NAME}
                        items={values}
                        type={props.type}
                    />
                )}
            </div>
            <div className={`${CSS_CLASS_NAME}__content__left__footer`}>
                <Anchor to={getCreateLink()} cssClassName="c-button">
                    New {getRecordLabel()}
                </Anchor>
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default EditorPageSidebar;

// #endregion Exports
