import type { HeaderData, MetaTag } from "models/interfaces/header-data";
import { Map, Record } from "immutable";
import StringUtils from "utilities/string-utils";
import { RecordUtils } from "@rsm-hcd/javascript-core";
import { AppNameTm } from "constants/app-name-tm";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

export const DefaultPageTitle = AppNameTm;

const defaultValues: HeaderData = RecordUtils.defaultValuesFactory<HeaderData>({
    metaTags: Map(),
    titles: Map(),
});

const DEFAULT_SEPARATOR = "-";

// #endregion Constants

export default class HeaderDataRecord
    extends Record(defaultValues)
    implements HeaderData
{
    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: Partial<HeaderData>) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.metaTags != null && !(params.metaTags instanceof Map)) {
            params.metaTags = Map(params.metaTags);
        }

        if (params.titles != null && !(params.titles instanceof Map)) {
            params.titles = Map(params.titles);
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Builds a page title based upon the provided url heirarchy
     *
     * @param {string} url Generate the page title for this URL
     * @param {string} [separator="-"] Default separator '-'
     * @returns {string} Page titles based on the provided and each parent's page title all concatenated by the provided separator
     */
    public getFullPageTitle(
        locationUrl: string,
        separator: string = DEFAULT_SEPARATOR
    ): string {
        if (locationUrl == null) {
            return "";
        }

        // Find path matches and order from shortest to longest so that the page titles can be
        // concatenated in the correct heirarchial order
        const orderedPageTitles = this.titles
            .filter(
                (v) =>
                    locationUrl.startsWith(v.url) &&
                    StringUtils.hasValue(v.title)
            )
            .sortBy((v) => v.url.length)
            .map((v) => v.title)
            .toArray()
            .map((v) => v[1]);

        return orderedPageTitles.join(` ${separator ?? DEFAULT_SEPARATOR} `);
    }

    public getMetaTags(locationUrl: string): Array<MetaTag> {
        return this.metaTags
            .filter((v, k) => {
                return k === locationUrl;
            })
            .toArray()
            .flatMap((v) => v[1]);
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<HeaderData>} values
     */
    public with(values: Partial<HeaderData>): HeaderDataRecord {
        return new HeaderDataRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}
