import { RecordUtils } from "@rsm-hcd/javascript-core";
import FreeAccessPublicationPage from "models/interfaces/free-access-publication-page";
import { Record as ImmutableRecord } from "immutable";

const defaultValues: FreeAccessPublicationPage =
    RecordUtils.defaultValuesFactory<FreeAccessPublicationPage>({
        imageUri: undefined,
        pageNumber: undefined,
    });

export default class FreeAccessPublicationPageRecord
    extends ImmutableRecord(defaultValues)
    implements FreeAccessPublicationPage
{
    constructor(params?: FreeAccessPublicationPage) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<FreeAccessPublicationPage>} values
     */
    public with(
        values: Partial<FreeAccessPublicationPage>
    ): FreeAccessPublicationPageRecord {
        return new FreeAccessPublicationPageRecord(
            Object.assign(this.toJS(), values)
        );
    }
}
