import Loader from "atoms/loaders/loader";
import { chain } from "lodash";
import FileRecord from "models/view-models/file-record";
import PublicationRecord from "models/view-models/publication-record";
import UserPublicationFavoriteRecord from "models/view-models/user-publication-favorite-record";
import PublicationSelectionListGroup from "organisms/modals/publication-selection-modal/publication-selection-list-group";
import PublicationSelectionListGroupItem from "organisms/modals/publication-selection-modal/publication-selection-list-group-item";
import * as React from "react";
import { useMemo } from "react";
import { useLocalization } from "utilities/hooks/use-localization";
import StringUtils from "utilities/string-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface PublicationSelectionListProps {
    coverImageFiles: FileRecord[];
    displayMenuActions?: boolean;
    favorites: UserPublicationFavoriteRecord[];
    filtering: boolean;
    onChangeFavorites: (newValue: Array<UserPublicationFavoriteRecord>) => void;
    publications: PublicationRecord[];
    rowCount?: number;
    searching: boolean;
    searchText?: string;
    onResultClick?: Function;
}

interface PublicationSelectionGroup {
    group: PublicationRecord[];
    title: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS = "c-publication-selection-list";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const PublicationSelectionList: React.FunctionComponent<
    PublicationSelectionListProps
> = (props: PublicationSelectionListProps) => {
    const {
        coverImageFiles,
        displayMenuActions = true,
        favorites,
        filtering,
        onChangeFavorites,
        publications,
        rowCount,
        searching,
        searchText,
        onResultClick = () => {},
    } = props;
    const { t } = useLocalization();

    const groupedPublications = useMemo<PublicationSelectionGroup[]>(
        () =>
            chain(publications)
                .groupBy((pub) => pub.getDisplayTitle(false))
                .map((group, title) => ({ group, title }))
                .value(),
        [publications]
    );

    if (searching) {
        return (
            <div className={BASE_CLASS}>
                <Loader />
            </div>
        );
    }

    return (
        <React.Fragment>
            {filtering && (
                <div className={BASE_CLASS}>
                    <Loader />
                </div>
            )}
            <div className={BASE_CLASS}>
                {StringUtils.hasValue(searchText) ? (
                    <div className={`${BASE_CLASS}__search-results`}>
                        <span>
                            {t("result", {
                                count: rowCount ?? publications.length,
                            })}
                        </span>
                        {publications.map((publication) => {
                            const coverImage = coverImageFiles.find(
                                (f) => f.id === publication.coverImageFileId
                            )!;

                            return (
                                <PublicationSelectionListGroupItem
                                    coverImageFile={coverImage}
                                    displayMenuActions={displayMenuActions}
                                    favorites={favorites}
                                    key={publication.id}
                                    onChangeFavorites={onChangeFavorites}
                                    publication={publication}
                                    onResultClick={onResultClick}
                                />
                            );
                        })}
                    </div>
                ) : (
                    <React.Fragment>
                        {groupedPublications.map((g) => (
                            <PublicationSelectionListGroup
                                coverImageFiles={coverImageFiles}
                                cssClassName={`${BASE_CLASS}__group`}
                                displayMenuActions={displayMenuActions}
                                favorites={favorites}
                                key={g.title}
                                onChangeFavorites={onChangeFavorites}
                                publications={g.group}
                                title={g.title}
                                onResultClick={onResultClick}
                            />
                        ))}
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default PublicationSelectionList;

// #endregion Export
