import NumberUtils from "utilities/number-utils";
import NumberIndexedObject from "utilities/types/number-indexed-object";
/* eslint-disable-next-line no-restricted-imports */
import { CoreUtils as AndcultureCodeCoreUtils } from "@rsm-hcd/javascript-core";

// -----------------------------------------------------------------------------------------
// #region Private Methods
// -----------------------------------------------------------------------------------------

/**
 * Example:
 * ```
 * const somePublication = ...
 * const someBooks = ..
 * const filteredBooks = someBooks.filter(
 *  filterByProperties<PublicationRecord, BookRecord>(somePublication, ["code", "edition"])
 * );
 * ```
 *
 * @param src
 * @param properties
 * @returns a function to filter an abject by another object via shared properties
 */
const _filterByProperties =
    <T, U>(src: T | U, properties: Array<keyof T & keyof U>) =>
    (value: T | U) =>
        properties.every((p) => src[p] === value[p]);

/**
 * Transforms an enum into an array of its keys
 *
 * @example
 * const roleTypes = CoreUtils.enumToArray<RoleType>(RoleType);
 * // Returns [
 *      AUTHOR,
 *      ENTERPRISE,
 *      FREE_TRIAL,
 *      INDIVIDUAL,
 *      NON_ADMIN_AUTHORIZED_USER,
 *      PUBLISHER,
 *      SYS_ADMIN,
 *      TEAM
 * ]
 * @template TEnum The enum to be transformed
 * @param {*} enumObject The enum to be transformed (cannot be typed to TEnum, or TS will return 'typeof TEnum'
 * instead of a value of TEnum)
 * @returns {TEnum[]}
 */
const _getEnumKeys = (enumObject: any): string[] =>
    Object.keys(AndcultureCodeCoreUtils.numericEnumToPojo(enumObject));

/**
 * Transforms an enum into an array of its values
 *
 * @example
 * const roleTypes = CoreUtils.enumToArray<RoleType>(RoleType);
 * // Returns [0, 1, 2, 3, 4, 5]
 * @template TEnum The enum to be transformed
 * @param {*} enumObject The enum to be transformed (cannot be typed to TEnum, or TS will return 'typeof TEnum'
 * instead of a value of TEnum)
 * @returns {TEnum[]}
 */
const _getEnumValues = <TEnum = any>(enumObject: any): TEnum[] =>
    AndcultureCodeCoreUtils.enumToArray<TEnum>(enumObject);

/**
 * Returns all keys of `object` typed as a `number`
 *
 * @template TValue Typed value of the key
 * @param {NumberIndexedObject<TValue>} object Object to iterate over & parse `number` keys from
 * @returns {number[]}
 */
const _getNumericKeys = <TValue>(
    object: NumberIndexedObject<TValue>
): number[] => {
    // First, filter out any key that cannot properly be parsed into a number.
    const parsableKeys = Object.keys(object).filter(
        (key: string) => NumberUtils.parseInt(key) != null
    );

    // At this point, we know all of the keys can be properly parsed, so force them into non-nullable numbers.
    return parsableKeys.map((key: string) => NumberUtils.parseInt(key)!);
};

/**
 * Check equality when the values are possibly null/undefined. In this case,
 * simple == or === doesn't quite work as expected.
 * @param a
 * @param b
 */
const _nullableEquals = <TValue>(
    a: TValue | undefined | null,
    b: TValue | undefined | null
) => {
    if (a == null || b == null) {
        return a == null && b == null;
    }

    return a === b;
};

/**
 * Run a setTimeout as a promise. Useful for those pesky cases
 * when you have to use setTimeout(handler, 0);
 * @param handler
 * @param timeout
 */
const _setTimeoutPromise = (
    handler: (...args: any[]) => void,
    timeout: number = 0
): Promise<void> =>
    new Promise<void>((resolve) =>
        setTimeout(() => {
            handler();
            resolve();
        }, timeout)
    );

// #endregion Private Methods

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export const CoreUtils = {
    ...AndcultureCodeCoreUtils,
    filterByProperties: _filterByProperties,
    getEnumKeys: _getEnumKeys,
    getEnumValues: _getEnumValues,
    getNumericKeys: _getNumericKeys,
    nullableEquals: _nullableEquals,
    merge: AndcultureCodeCoreUtils.merge,
    numericEnumToPojo: AndcultureCodeCoreUtils.numericEnumToPojo,
    objectToArray: AndcultureCodeCoreUtils.objectToArray,
    setTimeoutPromise: _setTimeoutPromise,
    sleep: AndcultureCodeCoreUtils.sleep,
    timer: AndcultureCodeCoreUtils.timer,
};

// #endregion Exports
