import Button from "atoms/buttons/button";
import PublishStatus from "models/enumerations/publish-status";
import { EnhancedContentPanelProps } from "organisms/enhanced-content/enhanced-content-panel";
import React, { forwardRef } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface EnhancedContentDeleteActionProps
    extends Pick<EnhancedContentPanelProps, "enhancedContent"> {
    onClick: () => void;
    ref?: React.RefObject<HTMLButtonElement>;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Components
// -----------------------------------------------------------------------------------------

const EnhancedContentDeleteAction = forwardRef(
    (
        props: EnhancedContentDeleteActionProps,
        ref: React.Ref<HTMLButtonElement>
    ) => {
        const { enhancedContent, onClick, ref: buttonRef } = props;

        const { globalState } = useGlobalState();

        const authorCanDelete =
            globalState.currentIdentity?.isAuthor() &&
            enhancedContent.getPublishStatus() !== PublishStatus.Published;

        const canDelete =
            authorCanDelete ||
            globalState.currentIdentity?.isAdmin() ||
            globalState.currentIdentity?.isPublisher();

        if (!canDelete) {
            return null;
        }

        return (
            <Button
                accessibleText="Delete Enhanced Content"
                onClick={onClick}
                ref={buttonRef}>
                Delete
            </Button>
        );
    }
);

// #endregion Components

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default EnhancedContentDeleteAction;

// #endregion Exports
