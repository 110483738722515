import Loader from "atoms/loaders/loader";
import { AppNameTm } from "constants/app-name-tm";
import UserBookmarkSortOption from "models/enumerations/sorting/user-bookmarks/user-bookmark-sort-option";
import { MetaTag, MetaTagTypes } from "models/interfaces/header-data";
import UserBookmarkRecord from "models/view-models/user-bookmark-record";
import UserCollectionBookmarkRecord from "models/view-models/user-collection-bookmark-record";
import UserCollectionRecord from "models/view-models/user-collection-record";
import NoResultsBanner from "molecules/banners/no-results-banner";
import FilterLabel from "molecules/filter-label/filter-label";
import CodesModal from "molecules/modals/codes-modal";
import PageNavigationMenu from "molecules/page-navigation/page-navigation-menu";
import { CountedColorSelectOption } from "organisms/my-link/bookmark-color-filter-select";
import BookmarkCard from "organisms/my-link/my-bookmarks/bookmark-card";
import { MyBookmarksTabFilters } from "organisms/my-link/my-bookmarks/my-bookmarks-tab-filters";
import React, { useMemo, useState } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import { useHeaderData } from "utilities/contexts/use-header-data-context";
import { CustomColorUtils } from "utilities/custom-color-utils";
import { UserBookmarkColors } from "utilities/enumerations/user-bookmark-colors";
import UserBookmarkType from "utilities/enumerations/user-bookmark-type";
import useMyBookmarks from "utilities/hooks/domain/my-link/use-my-bookmarks";
import useSelectedSectionBookmark from "utilities/hooks/domain/my-link/use-selected-section-bookmark";
import useSelectedTableBookmark from "utilities/hooks/domain/my-link/use-selected-table-bookmark";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import StringUtils from "utilities/string-utils";
import { ToastManager } from "utilities/toast/toast-manager";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface MyBookmarksListProps {
    collectionId?: number;
    showPagination?: boolean;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BookmarkColors: Record<
    keyof typeof UserBookmarkColors,
    keyof CultureResources
> = {
    Yellow: "yellow",
    Green: "green",
    Teal: "teal",
    Blue: "blue",
    Purple: "purple",
    Pink: "pink",
    Red: "red",
    Black: "black",
};

export const MY_BOOKMARKS_CSS_CLASS_NAME = "c-my-bookmarks-tab";

const DEFAULT_PAGE_SIZES = [10, 20, 50, 100];

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const MyBookmarksList: React.FC<MyBookmarksListProps> = (
    props: MyBookmarksListProps
) => {
    const { t } = useLocalization();

    const META_TAGS = useMemo(
        function buildMetaTags(): Array<MetaTag> {
            return [
                {
                    name: MetaTagTypes.Title,
                    content: AppNameTm,
                },
                {
                    name: MetaTagTypes.Description,
                    content: t("myBookmarksTab-metaTags-description", {
                        appName: AppNameTm,
                    }),
                },
            ];
        },
        [t]
    );

    useHeaderData({ metaTags: META_TAGS });

    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------

    const [searchText, setSearchText] = useState("");

    const [selectedColors, setSelectedColors] = useState<
        Array<UserBookmarkColors>
    >([]);

    const [showUpdateBookmarkModal, setShowUpdateBookmarkModal] =
        useState<boolean>(false);

    const [sortBy, setSortBy] = useState(
        UserBookmarkSortOption.CreatedOnDescending
    );

    // #endregion State

    // -----------------------------------------------------------------------------------------
    // #region Hooks
    // -----------------------------------------------------------------------------------------
    const collectionId = useMemo(
        () => props.collectionId,
        [props.collectionId]
    );
    const filterByCurrentUser = useMemo(
        () => collectionId == null,
        [collectionId]
    );

    const {
        myBookmarks,
        refresh,
        refreshCollections,
        loading,
        currentPage,
        numberOfPages,
        onPageLast,
        onPageNext,
        onPageSizeChange,
        onSelectPage,
        rowCount,
        take,
    } = useMyBookmarks({
        collectionId,
        searchText,
        colors: selectedColors,
        filterByCurrentUser,
        sortBy,
        includeProperties: ["section", "user", "publication", "collection"],
        showPagination: props.showPagination,
    });

    const {
        isLoading: selectedBookmarkIsLoading,
        selectedBookmark: selectedSectionBookmark,
        selectedSection,
        setSelectedBookmark: setSelectedSectionBookmark,
    } = useSelectedSectionBookmark();

    const {
        loading: selectedTableBookmarkIsLoading,
        selectedBookmark: selectedTableBookmark,
        selectedTable,
        setSelectedBookmark: setSelectedTableBookmark,
    } = useSelectedTableBookmark();

    const isSection =
        selectedSectionBookmark != null && selectedSection != null;
    const isTable = selectedTableBookmark != null && selectedTable != null;

    const getSelectedContent = () => {
        if (isSection) {
            return selectedSection;
        }
        if (isTable) {
            return selectedTable;
        }
    };

    const getSelectedBookmark = () => {
        if (isSection) {
            return selectedSectionBookmark;
        }
        if (isTable) {
            return selectedTableBookmark;
        }
    };

    const setSelectedBookmark = () => {
        if (isSection) {
            setSelectedSectionBookmark(undefined);
        }
        if (isTable) {
            setSelectedTableBookmark(undefined);
        }
    };

    const isVisible = useMemo(
        () =>
            (selectedSectionBookmark != null && selectedSection != null) ||
            (selectedTableBookmark != null && selectedTable != null),
        [
            selectedSection,
            selectedSectionBookmark,
            selectedTable,
            selectedTableBookmark,
        ]
    );

    const colorOptions: CountedColorSelectOption[] =
        // CustomColorUtils.buildBookmarkColorSelectOptions(myBookmarks);
        CustomColorUtils.buildSelectOptions(UserBookmarkColors).map(
            (option) => ({ ...option, count: 1 })
        );

    // #endregion Hooks

    // -----------------------------------------------------------------------------------------
    // #region Event Handlers
    // -----------------------------------------------------------------------------------------

    const handleBookmarkClick = (bookmark: UserBookmarkRecord) => {
        const isSection =
            bookmark.userBookmarkType === UserBookmarkType.Section;
        const isTable = bookmark.userBookmarkType === UserBookmarkType.Table;

        if (!showUpdateBookmarkModal && isSection) {
            setSelectedSectionBookmark(bookmark);
        }

        if (!showUpdateBookmarkModal && isTable) {
            setSelectedTableBookmark(bookmark);
        }
    };

    const handleBookmarkUpdated = () => {
        refresh();
        refreshCollections();
    };

    const handleBookmarkDeleted = () => {
        refresh();

        if (props.collectionId == null) {
            ToastManager.success(t("myBookmarksTab-success-removeBookmark"));
            return;
        }

        ToastManager.success(
            t("myBookmarksTab-success-removeBookmarkFromCollection")
        );
    };

    const handleClearFilters = () => {
        setSearchText("");
        setSelectedColors([]);
    };

    // #endregion Event Handlers

    // -----------------------------------------------------------------------------------------
    // #region Utilities
    // -----------------------------------------------------------------------------------------

    const getCollectionBookmark = (bookmark: UserBookmarkRecord) => {
        if (props.collectionId == null) {
            return undefined;
        }

        return bookmark.userCollectionBookmarks?.find(
            (ucb: UserCollectionBookmarkRecord) =>
                ucb.collectionId === props.collectionId
        );
    };

    const getCollectionsForBookmark = (bookmark: UserBookmarkRecord) =>
        (
            bookmark.userCollectionBookmarks?.map((ucb) => ucb.collection) ?? []
        ).filter((e) => e != null) as Array<UserCollectionRecord>;

    const translateColor = (color: UserBookmarkColors) =>
        t(
            BookmarkColors[
                UserBookmarkColors[color] as keyof typeof BookmarkColors
            ]
        );

    const hasNoBookmarks =
        !loading &&
        CollectionUtils.isEmpty(myBookmarks) &&
        StringUtils.isEmpty(searchText) &&
        CollectionUtils.isEmpty(selectedColors);

    const hasAnyBookmarks = !(
        CollectionUtils.isEmpty(myBookmarks) &&
        StringUtils.isEmpty(searchText) &&
        CollectionUtils.isEmpty(selectedColors)
    );

    const hasNoSearchResults =
        !loading &&
        CollectionUtils.isEmpty(myBookmarks) &&
        (StringUtils.hasValue(searchText) ||
            CollectionUtils.hasValues(selectedColors));

    // #endregion Utilities

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    return (
        <>
            <div className={`${MY_BOOKMARKS_CSS_CLASS_NAME}__filter-wrapper`}>
                {hasAnyBookmarks && (
                    <MyBookmarksTabFilters
                        colorOptions={colorOptions}
                        onChangeColorFilters={setSelectedColors}
                        onChangeSearchText={setSearchText}
                        onChangeSortBy={setSortBy}
                        searchText={searchText}
                        sortBy={sortBy}
                        colorFilters={selectedColors}
                    />
                )}
            </div>
            <div className={`${MY_BOOKMARKS_CSS_CLASS_NAME}__body`}>
                {/* Collection Page renders its own loader; we don't want to show 2 loaders */}
                {loading && (
                    <Loader
                        accessibleText={t("loadingItem", {
                            item: t("bookmark_plural"),
                        })}
                    />
                )}

                {hasAnyBookmarks &&
                    (StringUtils.hasValue(searchText) ||
                        CollectionUtils.hasValues(selectedColors)) && (
                        <FilterLabel
                            filters={[
                                searchText,
                                ...selectedColors.map(translateColor),
                            ]}
                            itemName="bookmark"
                            numberOfResults={myBookmarks.length}
                            onClear={handleClearFilters}
                        />
                    )}
                {hasAnyBookmarks && !loading && (
                    <div
                        className={`${MY_BOOKMARKS_CSS_CLASS_NAME}__bookmarks`}>
                        {myBookmarks.map((bookmark: UserBookmarkRecord) => (
                            <BookmarkCard
                                bookmark={bookmark}
                                collections={getCollectionsForBookmark(
                                    bookmark
                                )}
                                inCollectionView={props.collectionId != null}
                                key={bookmark.id}
                                onBookmarkDeleted={handleBookmarkDeleted}
                                onBookmarkUpdated={handleBookmarkUpdated}
                                onClick={() => handleBookmarkClick(bookmark)}
                                publication={bookmark.createdOnPublication}
                                showUpdateBookmarkModal={
                                    setShowUpdateBookmarkModal
                                }
                                userCollectionBookmark={getCollectionBookmark(
                                    bookmark
                                )}
                            />
                        ))}
                    </div>
                )}
                {hasNoBookmarks && (
                    <NoResultsBanner
                        title={t(
                            "myBookmarksTab-noBookmarksResultsBanner-title"
                        )}
                        subtitle={t(
                            "myBookmarksTab-noBookmarksResultsBanner-subTitle"
                        )}
                    />
                )}
                {hasNoSearchResults && (
                    <NoResultsBanner
                        className="-secondary"
                        title={t("noSearchResultsBanner-title")}
                        subtitle={t("noSearchResultsBanner-subTitle")}
                    />
                )}
            </div>
            {hasAnyBookmarks && props.showPagination && (
                <div className={`${MY_BOOKMARKS_CSS_CLASS_NAME}__footer`}>
                    <PageNavigationMenu
                        currentPage={currentPage}
                        numberOfPages={numberOfPages}
                        onNavigateBack={onPageLast}
                        onNavigateForward={onPageNext}
                        onPageSizeChange={onPageSizeChange}
                        onSelectPage={onSelectPage}
                        pageSize={take}
                        pageSizes={DEFAULT_PAGE_SIZES}
                        resultTotal={rowCount}
                    />
                </div>
            )}

            <CodesModal
                isLoading={
                    selectedBookmarkIsLoading || selectedTableBookmarkIsLoading
                }
                route={getSelectedBookmark()?.getSectionRoute() ?? "#"}
                content={getSelectedContent()!}
                subContent={selectedSection?.subSections}
                closeDialog={() => setSelectedBookmark()}
                publicationDisplayTitle={getSelectedBookmark()?.subtitle!}
                publicationType={getSelectedBookmark()?.publicationType}
                isVisible={isVisible}
                code={getSelectedBookmark()?.publicationCode}
                edition={getSelectedBookmark()?.publicationEdition}
            />
        </>
    );

    // #endregion Render
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------
export default MyBookmarksList;

// #endregion Exports
