import SelectSizes from "atoms/constants/select-sizes";
import CheckboxInput from "atoms/forms/checkbox-input";
import MultiSelect from "atoms/forms/multi-select";
import { SelectOption } from "atoms/forms/select";
import { KeyboardConstants } from "constants/keyboard-constants";
import { ColorSelectType } from "organisms/my-link/my-bookmarks/color-select";
import React, { useEffect, useRef, useState } from "react";
import { ActionMeta, components, ValueContainerProps } from "react-select";
import { CollectionUtils } from "utilities/collection-utils";
import { CustomColorUtils } from "utilities/custom-color-utils";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import { UserBookmarkColors } from "utilities/enumerations/user-bookmark-colors";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import { useLocalization } from "utilities/hooks/use-localization";
import useOutsideClick from "utilities/hooks/use-outside-click";
import { t } from "utilities/localization-utils";
import uuid from "uuid";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface BookmarkColorFilterSelectProps {
    onChange: (newValues: Array<number>) => void;
    onMenuClosed?: () => void;
    options: Array<CountedColorSelectOption>;
    value: Array<number>;
}

export interface CountedColorSelectOption
    extends SelectOption<number, number> {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-bookmark-color-filter-select";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const BookmarkColorFilterSelect: React.FC<BookmarkColorFilterSelectProps> = (
    props: BookmarkColorFilterSelectProps
) => {
    const { onChange, onMenuClosed, options, value } = props;

    const { t } = useLocalization();

    /**
     * We need to manually control the menu open state because
     * of a bug with react-select when using a custom component
     * for react-select's ValueContainer
     * @see https://stackoverflow.com/questions/61467417/how-to-change-valuecontainer-of-react-select-so-that-minifying-does-not-introduc
     * @see https://github.com/JedWatson/react-select/issues/2597
     */
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const menuContainerRef = useRef<HTMLDivElement>(null);
    const [multiSelectId] = useState(`color-filter-multi-select-${uuid.v4()}`);

    // since we're manually controlling menu state, sometimes the --is-focused class
    // from react-select doesn't quite sync properly.
    const [forceUnfocused, setForceUnfocused] = useState(false);

    useEffect(() => {
        if (menuIsOpen) {
            setForceUnfocused(false);
            return;
        }

        onMenuClosed?.();
    }, [menuIsOpen, onMenuClosed]);

    const isTablet = useBreakpoint(
        Breakpoints.Tablet,
        BreakpointComparer.MaxWidth
    );

    useOutsideClick(containerRef, () => {
        setMenuIsOpen(false);
        setForceUnfocused(true);
    });

    const handleContainerClick = (
        e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
    ) => {
        setForceUnfocused(false);

        const selectElement = document.getElementById(multiSelectId);
        const literalClickedElement = e.target;

        if (selectElement == null || literalClickedElement == null) {
            return;
        }

        if (selectElement.contains(literalClickedElement as Node)) {
            setMenuIsOpen(!menuIsOpen);
        }

        e.stopPropagation();
        e.preventDefault();
    };

    const handleChange = (
        newValues: Array<CountedColorSelectOption>,
        actionMeta?: ActionMeta<CountedColorSelectOption>
    ) => {
        let values = newValues.map((o: CountedColorSelectOption) => o.value);
        if (actionMeta?.action === "remove-value") {
            const removedValue = (actionMeta as any)
                .removedValue as CountedColorSelectOption;
            values = value.filter((v: number) => v !== removedValue.value);
        }

        if (actionMeta?.action === "clear") {
            values = [];
        }

        onChange(values);
    };

    const handleKeydown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === KeyboardConstants.Escape) {
            setMenuIsOpen(false);
        }

        if (
            (e.key === KeyboardConstants.Enter ||
                e.key === KeyboardConstants.Space) &&
            !menuIsOpen
        ) {
            setMenuIsOpen(true);
        }
    };

    const handleBlur = () => {
        if (isTablet) {
            // this event bubbles when it shouldn't on iPad
            return;
        }

        setForceUnfocused(true);
        setMenuIsOpen(false);
    };

    const customComponents = {
        ValueContainer: CustomValueContainer,
    };

    return (
        <div
            className={`${CSS_CLASS_NAME} ${
                forceUnfocused ? "-unfocused" : ""
            }`}
            onBlur={handleBlur}
            onClick={handleContainerClick}
            onTouchStart={handleContainerClick} // iOS/iPadOS sometimes prevents click events from bubbling on certain elements
            onKeyDown={handleKeydown}
            ref={containerRef}>
            <MultiSelect<number, number>
                closeMenuOnSelect={false}
                cssClassName={`${CSS_CLASS_NAME}__multi-select`}
                customComponents={customComponents}
                dropdownPortal={menuContainerRef.current as HTMLElement}
                hideSelectedOptions={false}
                id={multiSelectId}
                menuIsOpen={menuIsOpen}
                onChange={handleChange}
                options={options}
                placeholder={t(
                    "bookmarkColorFilterSelect-multiSelect-placeholder"
                )}
                renderOption={CustomOptionRenderer}
                searchable={false}
                size={SelectSizes.Small}
                value={value}
            />
            <div
                className={`${CSS_CLASS_NAME}__menu-container`}
                ref={menuContainerRef}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Functions
// -------------------------------------------------------------------------------------------------

const CustomValueContainer = (
    props: ValueContainerProps<CountedColorSelectOption>
) => {
    const { children, selectProps } = props;
    const selectedOptions = selectProps.value;

    if (
        selectedOptions == null ||
        !Array.isArray(selectedOptions) ||
        CollectionUtils.isEmpty(selectedOptions)
    ) {
        return (
            <components.ValueContainer {...props}>
                {children}
            </components.ValueContainer>
        );
    }

    if (selectedOptions.length > 1) {
        return (
            <components.ValueContainer {...props}>
                <span
                    className={`${CSS_CLASS_NAME}__menu-container__value__multiple`}>
                    {t("filteredByWithCount", {
                        count: selectedOptions.length,
                        filter: t("color", { count: selectedOptions.length }),
                    })}
                </span>
                {children}
            </components.ValueContainer>
        );
    }

    const value = selectedOptions[0] as CountedColorSelectOption;

    return (
        <components.ValueContainer {...props}>
            <span
                className={`${CSS_CLASS_NAME}__menu-container__value__single`}>
                <div
                    className={`${CSS_CLASS_NAME}__menu-container__value__single__color-circle ${CustomColorUtils.getClassModifier(
                        UserBookmarkColors,
                        value.value,
                        ColorSelectType.Bookmark
                    )}`}
                />
                <span
                    className={`${CSS_CLASS_NAME}__menu-container__value__single__label`}>
                    {value.label}
                </span>
            </span>
            {children}
        </components.ValueContainer>
    );
};

const CustomOptionRenderer = (
    option: CountedColorSelectOption,
    isSelected: boolean
) => (
    <CheckboxInput
        checked={isSelected}
        label={renderColorLabel(option)}
        onChange={() => {}}
        tabindex={-1}
    />
);

const renderColorLabel = (option: CountedColorSelectOption) => {
    const { label, value } = option;

    return (
        <div className={`${CSS_CLASS_NAME}__menu-container__option-label`}>
            <div
                className={`${CSS_CLASS_NAME}__menu-container__option-label__color ${CustomColorUtils.getClassModifier(
                    UserBookmarkColors,
                    value,
                    ColorSelectType.Bookmark
                )}`}
            />
            <span
                className={`${CSS_CLASS_NAME}__menu-container__option-label__label`}>
                {label}
            </span>
        </div>
    );
};

// #endregion Functions

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default BookmarkColorFilterSelect;

// #endregion Exports
