import IdentityMetadataType from "models/enumerations/pendo/identity-meta-data-type";
import GlobalStateRecord from "models/view-models/global-state-record";
import IdentityRecord from "models/view-models/identity-record";
import AccountRecord from "models/view-models/pendo/account-record";
import VisitorRecord from "models/view-models/pendo/visitor-record";
import SubscriptionRecord from "models/view-models/subscription-record";
import { useMemo } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import LocalizationUtils from "utilities/localization-utils";
import StringUtils from "utilities/string-utils";
import useSubscription from "./domain/subscriptions/use-subscription";

type Validated<T> = [false, undefined] | [true, T];

interface ValidatedMetadataMap {
    [IdentityMetadataType.Visitor]: Validated<VisitorRecord>;
    [IdentityMetadataType.Account]: Validated<AccountRecord>;
}

/**
 * Custom hook to build out the pendo identity metadata
 */
export function usePendoIdentityMetadata<T extends IdentityMetadataType>(
    identityMetadataType: T,
    identity?: IdentityRecord
) {
    const { globalState } = useGlobalState();

    const subsctriptionId = identity?.getCurrentUserRole()?.subscriptionId;
    const { resultObject: subscription } = useSubscription(subsctriptionId);

    return useMemo(() => {
        const isFreeAccessUser = identity?.hasActiveSubscription() ?? true;
        if (isFreeAccessUser) {
            return invalid();
        }
        switch (identityMetadataType) {
            case IdentityMetadataType.Account:
                return buildPendoIdentityAccountMetadata(
                    globalState,
                    identity
                ) as ValidatedMetadataMap[T];
            case IdentityMetadataType.Visitor:
                return buildPendoIdentityVisitorMetadata(
                    globalState,
                    identity,
                    LocalizationUtils.currentCultureCode(),
                    subscription
                );
            default:
                return invalid();
        }
    }, [globalState, subscription, identity, identityMetadataType]);
}

const buildPendoIdentityAccountMetadata = (
    globalState: GlobalStateRecord,
    identity?: IdentityRecord
): Validated<AccountRecord> => {
    const { group } = identity?.getCurrentUserRole()?.userRoleGroup ?? {};

    if (group?.externalIdentifier == null) {
        return valid(new AccountRecord());
    }

    const idWithEnvironment = appendEnvironmentBasedIdentifier(
        group.externalIdentifier,
        globalState
    );

    return valid(new AccountRecord({ id: idWithEnvironment }));
};

const buildPendoIdentityVisitorMetadata = (
    globalState: GlobalStateRecord,
    identity?: IdentityRecord,
    language?: string,
    subscription?: SubscriptionRecord
): Validated<VisitorRecord> => {
    if (
        identity?.user?.id == null ||
        StringUtils.isEmpty(identity.user.email) ||
        StringUtils.isEmpty(language)
    ) {
        return invalid();
    }
    const idWithEnvironment = appendEnvironmentBasedIdentifier(
        identity.user.email,
        globalState
    );
    return valid(
        new VisitorRecord({
            id: idWithEnvironment,
            email: identity.user.email,
            full_name: identity.user.getFirstAndLastName(),
            role: identity.getCurrentUserRole()?.role?.name ?? "",
            isAdminOfCurrentTeam:
                identity.isAdminOfCurrentGroup().toString() ?? "",
            language: language,
            environment: globalState?.systemSettings?.environmentName ?? "",
            teamName: identity.getCurrentTeam()?.name ?? "",
            subscriptionPin: subscription?.sku ?? "",
            userCreatedOnDate: identity.user.createdOn ?? "",
        })
    );
};

const appendEnvironmentBasedIdentifier = (
    value: string,
    globalState: GlobalStateRecord
): string => {
    let valueWithEnv: string;
    switch (globalState?.systemSettings?.environmentName) {
        case "Development":
            valueWithEnv = `${globalState?.systemSettings?.environmentName}-${globalState?.systemSettings.machineName}-${value}`;
            break;
        case "staging":
            valueWithEnv = `${globalState?.systemSettings?.environmentName}-${value}`;
            break;
        case "working":
            valueWithEnv = `${globalState?.systemSettings?.environmentName}-${value}`;
            break;
        case "production":
            valueWithEnv = value;
            break;
        default:
            valueWithEnv = value;
    }
    return valueWithEnv;
};

const invalid = (): [false, undefined] => [false, undefined];
const valid = <T>(value: T): [true, T] => [true, value];
