import { Icons } from "atoms/constants/icons";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import MultiSelect from "atoms/forms/multi-select";
import { SelectOption } from "atoms/forms/select";
import Icon from "atoms/icons/icon";
import Paragraph from "atoms/typography/paragraph";
import { CategoryType } from "models/enumerations/situational-navigation/categories/category-type";
import CategoryCollectionRecord from "models/view-models/situational-navigation/categories/category-collection-record";
import { SituationEditorContentAreaId } from "organisms/admin/situational-navigation/situations/situation-editor";
import { SolutionEditorContentAreaId } from "organisms/admin/situational-navigation/solutions/solution-editor";
import React, { useEffect, useState } from "react";
import CategoryCollection from "utilities/interfaces/categories/category-collection";
import { CategoryUtils } from "utilities/situational-navigation/categories/category-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AdminCategoriesSectionProps {
    baseClassName: string;
    dropdownPortal?: HTMLElement;
    loading: boolean;
    onChange: (newValue: CategoryCollectionRecord) => void;
    options: CategoryCollectionRecord;
    showSituationHelpText?: boolean;
    value: CategoryCollectionRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AdminCategoriesSection: React.FC<AdminCategoriesSectionProps> = (
    props: AdminCategoriesSectionProps
) => {
    const {
        baseClassName,
        dropdownPortal,
        value,
        onChange,
        loading,
        showSituationHelpText,
    } = props;

    const categoryTypes = [
        CategoryType.Occupancies,
        CategoryType.Systems,
        CategoryType.Spaces,
        CategoryType.Equipment,
    ];

    const [values, setValues] = useState(new CategoryCollectionRecord(value));

    useEffect(() => setValues(new CategoryCollectionRecord(value)), [value]);

    const changeHandlerFactory = (
        propertyName: keyof CategoryCollection
    ): ((newValues: SelectOption[]) => void) => {
        return (newValues: SelectOption[]) => {
            const newValueRecord = values.with({
                [propertyName]: props.options
                    .mapFromSelectOptions(newValues)
                    .toList(),
            });
            setValues(newValueRecord);
            onChange(newValueRecord);
        };
    };

    const getPlaceholderText = (type: CategoryType): string =>
        `Select ${CategoryUtils.toLabel(type).plural.toLowerCase()}...`;

    const handleShouldCloseMenuOnScroll = (e: Event) =>
        e.target != null &&
        ((e.target as HTMLElement).id === SolutionEditorContentAreaId ||
            (e.target as HTMLElement).id === SituationEditorContentAreaId);

    return (
        <div className={`${baseClassName}__content__tags`}>
            <Paragraph
                cssClassName={
                    showSituationHelpText === true ? "" : "-no-help-text"
                }
                size={ParagraphSizes.Large}>
                Category Tags
            </Paragraph>
            {showSituationHelpText === true && (
                <Paragraph
                    cssClassName={`${baseClassName}__content__tags__help-text`}>
                    <Icon type={Icons.Information} />
                    Categories from related situations and solutions will also
                    apply to this situation.
                </Paragraph>
            )}
            {categoryTypes.map((type: CategoryType) => (
                <MultiSelect
                    animated={false}
                    key={type}
                    closeMenuOnScroll={handleShouldCloseMenuOnScroll}
                    closeMenuOnSelect={false}
                    dropdownPortal={dropdownPortal}
                    label={CategoryUtils.toLabel(type).plural}
                    onChange={changeHandlerFactory(
                        CategoryUtils.toCategoryCollectionProperty(type)
                    )}
                    placeholder={getPlaceholderText(type)}
                    options={props.options.toSelectOptions(type).toArray()}
                    value={values.toSelectOptionValues(type).toArray()}
                    loading={loading}
                    disabled={loading}
                />
            ))}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default AdminCategoriesSection;

// #endregion Exports
