import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { HeadingPriority } from "atoms/constants/heading-priority";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import Heading from "atoms/typography/heading";
import UserPublicationFavoriteRecord from "models/view-models/user-publication-favorite-record";
import { ModalCloseButtonStyle } from "molecules/constants/modal-close-button-style";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import { SearchFormStyles } from "molecules/enums/search-form-style";
import SearchForm from "molecules/forms/search-form";
import Modal, { ModalProps } from "molecules/modals/modal";
import * as React from "react";
import { ChangeEvent, useCallback, useRef, useState } from "react";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import usePublicationCodeGroups from "utilities/hooks/domain/publications/use-publication-code-groups";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import { useLocalization } from "utilities/hooks/use-localization";
import PublicationsList from "./publications-list";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASS_NAME = "c-publication-selection-modal";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

type IgnoredModalProps =
    | "closeButtonStyle"
    | "cssClassName"
    | "label"
    | "transition"
    | "type";

interface PublicationSelectionModalProps
    extends Omit<ModalProps, IgnoredModalProps> {
    /**
     * The currently selected favorite publications
     */
    favorites: UserPublicationFavoriteRecord[];
    /**
     * Called whenever a publication is either selected or unselected within the modal
     */
    onChangeFavorites: (newValue: Array<UserPublicationFavoriteRecord>) => void;
    initialSearchText?: string;
    onResultClick: () => void;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const DATA_TEST_ID = "publication-selection-modal";
const DATA_TEST_ID_SEARCH = `${DATA_TEST_ID}-search`;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

export default function PublicationSelectionModal(
    props: PublicationSelectionModalProps
) {
    const {
        closeDialog,
        favorites,
        onChangeFavorites,
        initialSearchText = "",
        onResultClick,
    } = props;
    const { t } = useLocalization();
    const [searchText, setSearchText] = useState(initialSearchText);

    const intersectionObserverRef = useRef<IntersectionObserver | null>(null);

    const {
        loading: publicationGroupsLoading,
        publicationGroups,
        setSkip,
        handleSearch,
    } = usePublicationCodeGroups();

    const isMobile = useBreakpoint(
        Breakpoints.SmallTablet,
        BreakpointComparer.MaxWidth
    );

    const transitionEffect = isMobile
        ? ModalTransitions.SlideUp
        : ModalTransitions.Fade;

    const type = isMobile ? ModalTypes.Fullscreen : ModalTypes.Base;

    const modalTitle = t("allItem", {
        item: t("publication_plural"),
    });

    const observerRef = useCallback(
        (node) => {
            if (node !== null && !publicationGroupsLoading) {
                if (intersectionObserverRef.current != null)
                    intersectionObserverRef.current.disconnect();

                intersectionObserverRef.current = new IntersectionObserver(
                    (entries: IntersectionObserverEntry[]) => {
                        if (entries.some((e) => e.isIntersecting))
                            setSkip((prev) => prev + 1);
                    },
                    {
                        threshold: 1.0,
                    }
                );
                intersectionObserverRef.current.observe(node);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    // const handleSearch = (newSearchText: string) => {
    //     setPublicationGroups([]);
    //     setSkip(0);
    //     setSubmittedSearchText(newSearchText);
    // };
    const handleSearchTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.currentTarget.value);
    };

    const handleSearchClear = () => {
        setSearchText("");
        handleSearch("");
    };

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    return (
        <Modal
            {...props}
            closeButtonStyle={ModalCloseButtonStyle.Hidden}
            cssClassName={CSS_BASE_CLASS_NAME}
            dataTestId={DATA_TEST_ID}
            label={modalTitle}
            transition={transitionEffect}
            type={type}>
            <div className={`${CSS_BASE_CLASS_NAME}__header`}>
                <Heading priority={HeadingPriority.Two}>{modalTitle}</Heading>
                <Button
                    accessibleText={t("closeItem", { item: t("dialog") })}
                    cssClassName={"-modal-close -icon -modal-close-tertiary"}
                    onClick={closeDialog}
                    size={ButtonSizes.Medium}
                    style={ButtonStyles.Tertiary}>
                    <Icon type={Icons.Close} size={IconSizes.Large} />
                </Button>
            </div>
            <div className={`${CSS_BASE_CLASS_NAME}__search`}>
                <SearchForm
                    dataTestId={DATA_TEST_ID_SEARCH}
                    onClear={handleSearchClear}
                    onSearchClick={() => handleSearch(searchText)}
                    onSearchTextChange={handleSearchTextChange}
                    placeholder={t(
                        "publicationSelectionModal-searchPlaceholder"
                    )}
                    searchText={searchText}
                    style={SearchFormStyles.TertiaryAlt}
                />
            </div>
            <div className={`${CSS_BASE_CLASS_NAME}__body`} tabIndex={0}>
                <PublicationsList
                    observerRef={observerRef}
                    favorites={favorites}
                    onChangeFavorites={onChangeFavorites}
                    onResultClick={onResultClick}
                    publicationGroups={publicationGroups}
                    loading={publicationGroupsLoading}
                />
            </div>
            <div className={`${CSS_BASE_CLASS_NAME}__footer`}>
                <Button onClick={closeDialog} style={ButtonStyles.Primary}>
                    {t("done")}
                </Button>
            </div>
        </Modal>
    );

    // #endregion Render
}

// #endregion Component
