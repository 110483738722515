import { useEffect, useState } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import GlobalStateRecord from "models/view-models/global-state-record";
import { CollectionUtils } from "utilities/collection-utils";
import { ResultRecord } from "@rsm-hcd/javascript-core";

/**
 * Retrieve and clear the unauthorizedResult from GlobalState if specified key is found in the ResultRecord
 *
 * @template T
 * @param {...string[]} keys Specific key(s) to trigger the clearing of GlobalState's copy of unauthorizedResult
 */
const useUnauthorizedResult = <T = any>(...keys: string[]): ResultRecord<T> => {
    const { globalState, setGlobalState } = useGlobalState();
    const [unauthorizedResult, setUnauthorizedResult] = useState<
        ResultRecord<T>
    >(new ResultRecord());

    useEffect(() => {
        const { unauthorizedResult } = globalState;

        if (unauthorizedResult == null) {
            return;
        }

        // Found an unauthorizedResult - set this in state to be returned
        setUnauthorizedResult(unauthorizedResult);

        // If we aren't watching for specific keys to clean up, just clear the result
        if (CollectionUtils.isEmpty(keys)) {
            setGlobalState((globalState: GlobalStateRecord) =>
                globalState.clearUnauthorizedResult()
            );
            return;
        }

        if (
            unauthorizedResult.doesNotHaveErrors() ||
            !unauthorizedResult.hasErrorFor(...keys)
        ) {
            return;
        }

        setGlobalState((globalState: GlobalStateRecord) =>
            globalState.clearUnauthorizedResult()
        );
    }, [globalState, keys, setGlobalState]);

    return unauthorizedResult;
};

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default useUnauthorizedResult;

// #endregion Exports
