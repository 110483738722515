import { SkipNavContent } from "@reach/skip-nav";
import { ServiceResponse } from "@rsm-hcd/javascript-core";
import DirectBackAnchor from "atoms/anchors/direct-back-anchor";
import { HeadingPriority } from "atoms/constants/heading-priority";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import Loader from "atoms/loaders/loader";
import Tab from "atoms/tabs/tab";
import TabPanel from "atoms/tabs/tab-panel";
import Heading from "atoms/typography/heading";
import FileRecord from "models/view-models/file-record";
import PublicationRecord from "models/view-models/publication-record";
import ResourceCollectionRecord from "models/view-models/resource-collection-record";
import SectionRecord from "models/view-models/section-record";
import SolutionRecord from "models/view-models/situational-navigation/solutions/solution-record";
import SolutionRelationshipRecord from "models/view-models/situational-navigation/solutions/solution-relationship-record";
import SolutionResourceRecord from "models/view-models/situational-navigation/solutions/solution-resource-record";
import RichTextArea from "molecules/rich-text-area/rich-text-area";
import SectionLinkCardList from "molecules/section-link-card-list/section-link-card-list";
import ShowMore from "molecules/show-more/show-more";
import DisclaimerText from "molecules/situational-navigation/disclaimer-text/disclaimer-text";
import TabList from "molecules/tabs/tab-list";
import TabPanels from "molecules/tabs/tab-panels";
import Tabs from "molecules/tabs/tabs";
import RelatedSolutionsSection from "organisms/situational-navigation/related-solutions/related-solutions-section";
import ResourcesSection from "organisms/situational-navigation/resources/resources-section";
import NotFoundPage from "pages/errors/not-found";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CollectionUtils } from "utilities/collection-utils";
import { useHeaderData } from "utilities/contexts/use-header-data-context";
import useLoading from "utilities/hooks/use-loading";
import { useLocalization } from "utilities/hooks/use-localization";
import usePageErrors from "utilities/hooks/use-page-errors";
import NumberUtils from "utilities/number-utils";
import useSortAlphabeticallyReducer from "utilities/reducers/use-sort-alphabetically-reducer";
import FileService from "utilities/services/file-service";
import PublicationService from "utilities/services/publications/publication-service";
import SectionService from "utilities/services/sections/section-service";
import SolutionRelationshipService from "utilities/services/situational-navigation/solutions/relationships/solution-relationship-service";
import SolutionResourceService from "utilities/services/situational-navigation/solutions/resources/solution-resource-service";
import SolutionService from "utilities/services/situational-navigation/solutions/solution-service";
import { ToastManager } from "utilities/toast/toast-manager";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface SolutionPageProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASS = "c-solution-page";

// #endregion Constants

const SolutionPage: React.FunctionComponent<SolutionPageProps> = () => {
    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------

    const [loadingRelatedSolutions, setLoadingRelatedSolutions] =
        useState(false);
    const [loadingResources, setLoadingResources] = useState(false);
    const [loadingSections, setLoadingSections] = useState(false);
    const [loadingSolution, setLoadingSolution] = useState(true);
    const [relatedSections, setRelatedSections] =
        useState<Array<SectionRecord>>();
    const [resources, setResources] = useState(
        new ResourceCollectionRecord(SolutionResourceRecord)
    );
    const [solution, setSolution] = useState<SolutionRecord>();
    const [showSectionsTab, setShowSectionsTab] = useState<boolean>(false);
    const [showRelatedTab, setShowRelatedTab] = useState<boolean>(false);

    const { setPageTitle } = useHeaderData();

    // #endregion State

    const { t, tErrorLoading } = useLocalization();
    const { id: solutionId } = useParams<{ id: string }>();
    const loading = useLoading(
        loadingSolution,
        loadingResources,
        loadingSections,
        loadingRelatedSolutions
    );
    const { get: getFileApi } = FileService.useGet();
    const { get: getSolutionApi } = SolutionService.useGet();
    const { list: listPublicationApi } = PublicationService.useList();
    const { list: listResourcesApi } = SolutionResourceService.useList();
    const { list: listSectionsApi } = SectionService.useList();
    const { list: listRelationshipsApi } =
        SolutionRelationshipService.useList();

    const { handlePageLoadError } = usePageErrors();

    const initializeSections = (
        sections?: Array<SectionRecord>,
        publications?: Array<PublicationRecord>
    ) => {
        let sectionRecords = sections?.map((s: SectionRecord) => {
            const publication = publications?.find(
                (p: PublicationRecord) => p.id === s.publicationId
            );
            if (publication == null) return s;
            return s.with({ publication });
        });

        setRelatedSections(sectionRecords);
    };

    const errorLoadingCodeSection = tErrorLoading("section_plural");
    const errorLoadingResourceFiles = tErrorLoading("resourceType-File_plural");
    const errorLoadingRelatedSolutions = tErrorLoading("solution_plural");
    const errorLoadingSolutionResources = tErrorLoading(
        "relatedResource_plural"
    );

    // #endregion Properties

    // -----------------------------------------------------------------------------------------
    // #region Reducers
    // -----------------------------------------------------------------------------------------

    const [relatedSolutions, setRelatedSolutions] =
        useSortAlphabeticallyReducer([], (s: SolutionRecord) => s.title);

    // #endregion Reducers

    // -----------------------------------------------------------------------------------------
    // #region Side Effects
    // -----------------------------------------------------------------------------------------

    useEffect(() => setPageTitle(solution?.title), [setPageTitle, solution]);

    useEffect(() => {
        // get solution
        const getSolution = async (id: number) => {
            try {
                setLoadingSolution(true);
                const solutionGetResult = await getSolutionApi({ id });

                if (solutionGetResult.result?.hasErrors()) {
                    handlePageLoadError(solutionGetResult.result);
                }
                if (solutionGetResult.resultObject == null) {
                    handlePageLoadError(solutionGetResult);
                    setLoadingSolution(false);
                    return;
                }

                setSolution(solutionGetResult.resultObject);
                setLoadingSolution(false);
            } catch (result) {
                handlePageLoadError(result);
                setLoadingSolution(false);
            }
        };

        const id = NumberUtils.parseInt(solutionId);
        if (id == null) {
            setSolution(new SolutionRecord());
            return;
        }
        getSolution(id);
    }, [solutionId, handlePageLoadError, getSolutionApi]);

    useEffect(() => {
        const loadSolutionSections = async (id?: number) => {
            if (id == null) {
                return;
            }
            setLoadingSections(true);
            try {
                const listSectionsResult = await listSectionsApi({
                    solutionId: id,
                    useLatest: true,
                });

                const publicationIds = listSectionsResult.resultObjects?.map(
                    (s: SectionRecord) => s.publicationId!
                );

                const publicationListResult = await listPublicationApi({
                    publicationIds,
                });

                initializeSections(
                    listSectionsResult.resultObjects,
                    publicationListResult.resultObjects
                );
            } catch (e) {
                ToastManager.error(errorLoadingCodeSection);
            }
            setLoadingSections(false);
        };

        loadSolutionSections(NumberUtils.parseInt(solutionId));
    }, [
        solutionId,
        listSectionsApi,
        listPublicationApi,
        errorLoadingCodeSection,
    ]);

    useEffect(() => {
        const loadFiles = async (
            ids: Array<number>
        ): Promise<Array<FileRecord>> => {
            try {
                const results = (await Promise.all(
                    ids.map((id: number) => getFileApi({ id }))
                )) as Array<ServiceResponse<FileRecord>>;

                return results.map(
                    (response: ServiceResponse<FileRecord>) =>
                        response.resultObject!
                );
            } catch (e) {
                ToastManager.error(errorLoadingResourceFiles);
                return [];
            }
        };

        const loadRelatedSolutions = async (id: number) => {
            setLoadingRelatedSolutions(true);

            try {
                const result = await listRelationshipsApi({
                    solutionId: id,
                });
                if (
                    result.resultObjects == null ||
                    result.resultObjects.length === 0
                ) {
                    setLoadingRelatedSolutions(false);
                    return;
                }
                // get list of IDs which are NOT this current solution's ID (i.e. the related solution IDs)
                const relatedSolutionIds = result.resultObjects!.map(
                    (relationship: SolutionRelationshipRecord) =>
                        relationship.solution1Id === id
                            ? relationship.solution2Id
                            : relationship.solution1Id
                );
                const getResults = (await Promise.all(
                    relatedSolutionIds.map((id: number) =>
                        getSolutionApi({ id })
                    )
                )) as Array<ServiceResponse<SolutionRecord>>;
                const slnRecords = getResults.map(
                    (response: ServiceResponse<SolutionRecord>) =>
                        response.resultObject!
                );
                setRelatedSolutions(slnRecords);
            } catch (e) {
                ToastManager.error(errorLoadingRelatedSolutions);
            }

            setLoadingRelatedSolutions(false);
        };

        const loadResources = async (id: number) => {
            setLoadingResources(true);

            try {
                const result = await listResourcesApi({ solutionId: id });
                const resources = result.resultObjects!;
                if (resources == null || resources.length === 0) {
                    setLoadingResources(false);
                    return;
                }
                const files = await loadFiles(
                    resources
                        .map((r: SolutionResourceRecord) => r.fileId)
                        .filter(
                            (id: number | undefined) => id != null
                        ) as Array<number>
                );
                setResources(
                    ResourceCollectionRecord.fromArrays(
                        SolutionResourceRecord,
                        resources,
                        files
                    )
                );
            } catch (e) {
                ToastManager.error(errorLoadingSolutionResources);
            }

            setLoadingResources(false);
        };

        const id = NumberUtils.parseInt(solutionId);
        if (id == null) {
            return;
        }
        loadResources(id);
        loadRelatedSolutions(id);
    }, [
        solutionId,
        getFileApi,
        listResourcesApi,
        getSolutionApi,
        listRelationshipsApi,
        setRelatedSolutions,
        errorLoadingResourceFiles,
        errorLoadingRelatedSolutions,
        errorLoadingSolutionResources,
    ]);

    useEffect(() => {
        setShowSectionsTab(CollectionUtils.hasValues(relatedSections));
    }, [relatedSections]);

    useEffect(() => {
        setShowRelatedTab(
            CollectionUtils.hasValues(resources.resources) ||
                CollectionUtils.hasValues(relatedSolutions)
        );
    }, [resources, relatedSolutions]);

    // #endregion Side Effects

    // -----------------------------------------------------------------------------------------
    // #region Component
    // -----------------------------------------------------------------------------------------

    if (loading) {
        return (
            <Loader
                accessibleText={t("loadingItem", { item: t("solution") })}
            />
        );
    }

    if (solution == null) {
        return <NotFoundPage />;
    }

    return (
        <div className={CSS_BASE_CLASS}>
            <section className={`${CSS_BASE_CLASS}__top`}>
                <DirectBackAnchor />
                <div>
                    <Icon
                        cssClassName={`${CSS_BASE_CLASS}__top__solution-icon`}
                        type={Icons.Lightbulb}
                    />
                    <span className="c-label -small -solution-label">
                        {t("solution")}
                    </span>
                </div>
            </section>
            <SkipNavContent tabIndex={-1}>
                <article className={`${CSS_BASE_CLASS}__content`}>
                    <Heading priority={HeadingPriority.Two}>
                        {solution.subtitle}
                    </Heading>
                    <Heading priority={HeadingPriority.Four}>
                        {solution.title}
                    </Heading>
                    <ShowMore
                        cssClassName={`${CSS_BASE_CLASS}__body`}
                        showMoreButtonText={t("readMore")}
                        showLessButtonText={t("readLess")}>
                        <RichTextArea content={solution.body} />
                    </ShowMore>
                </article>
                <Tabs>
                    <TabList>
                        {
                            // if
                            showSectionsTab && (
                                <Tab>
                                    {t("code")} ({relatedSections?.length})
                                </Tab>
                            )
                        }
                        {
                            // if
                            showRelatedTab && (
                                <Tab>
                                    {t("relatedResource_plural")} (
                                    {resources.size() + relatedSolutions.length}
                                    )
                                </Tab>
                            )
                        }
                    </TabList>
                    <TabPanels>
                        {
                            // if
                            showSectionsTab && (
                                <TabPanel>
                                    <SectionLinkCardList
                                        sections={relatedSections || []}
                                    />
                                </TabPanel>
                            )
                        }
                        {
                            // if
                            showRelatedTab && (
                                <TabPanel>
                                    {
                                        // if
                                        CollectionUtils.hasValues(
                                            resources.resources
                                        ) && (
                                            <ResourcesSection
                                                resources={resources}
                                            />
                                        )
                                    }
                                    {
                                        // if
                                        CollectionUtils.hasValues(
                                            relatedSolutions
                                        ) && (
                                            <RelatedSolutionsSection
                                                relatedsolutions={
                                                    relatedSolutions
                                                }
                                            />
                                        )
                                    }
                                </TabPanel>
                            )
                        }
                    </TabPanels>
                </Tabs>
                <section className={`${CSS_BASE_CLASS}__bottom`}>
                    <DisclaimerText />
                </section>
            </SkipNavContent>
        </div>
    );

    // #endregion Component
};

export default SolutionPage;
