import React from "react";
import * as Reach from "@reach/tabs";
import StringUtils from "utilities/string-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface TabPanelProps extends Reach.TabPanelProps {
    cssClassName?: string;
    tabIndex?: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_BASE_CLASS = "c-tabs__panel";
// #endregion Constants

/**
 * Extension of the Reach UI TabPanel component
 *
 * https://reacttraining.com/reach-ui/tabs
 *
 * @param props
 */
const TabPanel: React.FunctionComponent<TabPanelProps> = (
    props: TabPanelProps
) => {
    // -----------------------------------------------------------------------------------------
    // #region Components
    // -----------------------------------------------------------------------------------------
    const { tabIndex = 0 } = props;

    // #endregion Components
    return (
        <Reach.TabPanel
            tabIndex={tabIndex}
            className={`${CSS_BASE_CLASS} ${
                StringUtils.hasValue(props.cssClassName)
                    ? props.cssClassName
                    : ""
            }`}>
            {props.children}
        </Reach.TabPanel>
    );
};

export default TabPanel;
