import Anchor from "atoms/anchors/anchor";
import { HeadingPriority } from "atoms/constants/heading-priority";
import Trans from "atoms/i18n/trans";
import Heading from "atoms/typography/heading";
import Paragraph from "atoms/typography/paragraph";
import { AppNameTm, AppNameWithHtml } from "constants/app-name-tm";
import { siteMap } from "internal-sitemap";
import React from "react";
import { useLocalization } from "utilities/hooks/use-localization";

const BASE_CLASS_NAME = "c-select-plan-page";

interface SelectPlanPageHeaderProps {
    hasExpiredCurrentRoleError: boolean;
    isCurrentRoleExpiredFreeTrial?: boolean;
    selectPlanDescriptionHtml: any;
}

const SelectPlanPageHeader = ({
    hasExpiredCurrentRoleError,
    isCurrentRoleExpiredFreeTrial,
    selectPlanDescriptionHtml,
}: SelectPlanPageHeaderProps) => {
    const { t } = useLocalization();

    return (
        <React.Fragment>
            {!hasExpiredCurrentRoleError && (
                <React.Fragment>
                    <Heading
                        cssClassName={`${BASE_CLASS_NAME}__title`}
                        priority={HeadingPriority.One}>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: t("plansPage-selectPlanTitle", {
                                    appName: AppNameWithHtml,
                                }),
                            }}
                        />
                    </Heading>
                    <Paragraph cssClassName={`${BASE_CLASS_NAME}__subtitle`}>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: selectPlanDescriptionHtml,
                            }}
                        />{" "}
                        <Trans
                            i18nKey="plansPage-browseAllPublications"
                            values={{ appName: AppNameTm }}>
                            <>
                                <Anchor to={siteMap.publications.public.all}>
                                    Browse All Available Publications
                                </Anchor>{" "}
                                in {{ appName: AppNameTm }}.
                            </>
                        </Trans>
                    </Paragraph>
                </React.Fragment>
            )}
            {hasExpiredCurrentRoleError && (
                <React.Fragment>
                    <Heading
                        cssClassName={`${BASE_CLASS_NAME}__expiration-title`}
                        priority={HeadingPriority.One}>
                        {isCurrentRoleExpiredFreeTrial
                            ? t("plansPage-freeTrialExpired-title", {
                                  appName: AppNameTm,
                              })
                            : t("plansPage-subscriptionExpired-title", {
                                  appName: AppNameTm,
                              })}
                    </Heading>
                    <Heading
                        cssClassName={`${BASE_CLASS_NAME}__expiration-subtitle`}
                        priority={HeadingPriority.Two}>
                        {isCurrentRoleExpiredFreeTrial ? (
                            t("plansPage-freeTrialExpired-subtitle", {
                                appName: AppNameTm,
                            })
                        ) : (
                            <Paragraph
                                cssClassName={`${BASE_CLASS_NAME}__subtitle`}>
                                <Trans
                                    i18nKey="plansPage-subscriptionExpired-content"
                                    values={{ appName: AppNameTm }}>
                                    <>
                                        {{ appName: AppNameTm }} is a
                                        breakthrough digital platform providing
                                        instant access to NFPA codes and
                                        standards, plus exclusive expert
                                        commentary, visual aids, and more.
                                        Explore a list of
                                        <Anchor
                                            to={
                                                siteMap.publications.public.all
                                            }>
                                            available publications
                                        </Anchor>{" "}
                                        to find out what codes and standards are
                                        included with a subscription to{" "}
                                        {{ appName: AppNameTm }}.
                                    </>
                                </Trans>
                            </Paragraph>
                        )}
                    </Heading>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default SelectPlanPageHeader;
