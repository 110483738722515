import { Record } from "immutable";

import type SectionShareLink from "models/interfaces/share-links/section-share-link";

import moment from "moment";

import { siteMap } from "internal-sitemap";
import StringUtils from "utilities/string-utils";

import { RecordUtils } from "@rsm-hcd/javascript-core";

const DEFAULT_SHARE_LINK_TEMPLATE = `${window.location.origin}${siteMap.share.section}`;

const defaultValues: SectionShareLink =
    RecordUtils.auditableDefaultValuesFactory<SectionShareLink>({
        emailAddresses: [],
        expiresOn: undefined,
        guid: undefined,
        magicLinkUrlTemplate: DEFAULT_SHARE_LINK_TEMPLATE,
        message: "",
        sectionId: 0,
        subject: "",
    });

export default class SectionShareLinkRecord
    extends Record(defaultValues)
    implements SectionShareLink
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: SectionShareLink) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Returns a new instance of SectionShareLinkRecord with the specified
     * email address added to the emailAddresses array.
     * @param emailAddress
     */
    public addEmailAddress(emailAddress: string): SectionShareLinkRecord {
        if (
            this.emailAddresses.some(
                (s: string) => s.toLowerCase() === emailAddress.toLowerCase()
            )
        ) {
            return this.with({});
        }

        return this.with({
            emailAddresses: [...this.emailAddresses, emailAddress],
        });
    }

    public timeTillExpiration(): string {
        if (StringUtils.isEmpty(this.expiresOn)) {
            return "14 days";
        }

        const expirationDate = moment(this.expiresOn);
        if (!expirationDate.isValid()) {
            return "14 days";
        }

        return expirationDate.fromNow(true);
    }

    /**
     * Returns a new instance of SectionShareLinkRecord with the specified
     * email address removed from he emailAddresses array.
     * @param emailAddress
     */
    public removeEmailAddress(emailAddress: string): SectionShareLinkRecord {
        return this.with({
            emailAddresses: this.emailAddresses.filter(
                (s: string) => s.toLowerCase() !== emailAddress.toLowerCase()
            ),
        });
    }

    public with(values: Partial<SectionShareLink>): SectionShareLinkRecord {
        return new SectionShareLinkRecord(
            Object.assign(this.toJS(), values) as SectionShareLinkRecord
        );
    }

    // #endregion Public Methods
}
