import SupportUserRecord from "models/view-models/support-user-record";
import { useCallback, useState } from "react";
import SupportUserService, {
    SupportUserListQueryParams,
} from "utilities/services/support/support-users-service";
import { ToastManager } from "utilities/toast/toast-manager";
import usePagedResults from "utilities/hooks/use-paged-results";
import { ResultRecord } from "@rsm-hcd/javascript-core";

export default function useSupportUsers() {
    const DEFAULT_TAKE_SIZE = 10;

    const [supportUsers, setSupportUsers] = useState<SupportUserRecord[]>([]);
    const [loadingSupportUsers, setLoadingSupportUsers] = useState(false);

    const {
        currentPage,
        numberOfPages,
        onPageSizeChange,
        onSelectPage,
        setRowCount,
        rowCount,
        take,
        onPageLast,
        onPageNext,
        skip,
        resetPagination,
    } = usePagedResults(DEFAULT_TAKE_SIZE);

    const resetPage = () => {
        resetPagination();
        setRowCount(0);
        setSupportUsers([]);
    };

    const loadSupportUsers = useCallback(
        async (params: SupportUserListQueryParams) => {
            setLoadingSupportUsers(true);
            try {
                const results = await SupportUserService.list(params);
                setRowCount(results.rowCount);
                setSupportUsers(results.resultObjects);
            } catch (error) {
                if (error instanceof ResultRecord) {
                    ToastManager.error(error.listErrorMessages());
                }
            } finally {
                setLoadingSupportUsers(false);
            }
        },
        [setSupportUsers, setRowCount]
    );

    return {
        loadingSupportUsers,
        supportUsers,
        loadSupportUsers,
        rowCount,
        take,
        skip,
        currentPage,
        numberOfPages,
        onPageSizeChange,
        onSelectPage,
        onPageLast,
        onPageNext,
        resetPage,
    };
}
