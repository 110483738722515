import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import { useLocalization } from "utilities/hooks/use-localization";
import { v4 } from "uuid";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface CollapsePanelProps {
    /**
     * Text to be rendered inside an "sr-only" class span for the collapse/expand button trigger,
     * typically the text that is the header of the panel.
     */
    buttonAriaText: string;
    collapse: boolean;
    id?: string;
    isEmpty?: boolean;
    modifierCSSClassName?: string;
    panelTop: React.ReactNode;
    scrollToWhenActive?: boolean;
    useChevronIcons?: boolean;
    isCollapseDisabled?: boolean;
    iconSize?: IconSizes;
    onOpen?: () => void;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-collapse-panel";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const CollapsePanel: React.FC<PropsWithChildren<CollapsePanelProps>> = (
    props: PropsWithChildren<CollapsePanelProps>
) => {
    const {
        buttonAriaText,
        children,
        collapse = true,
        id,
        modifierCSSClassName,
        panelTop,
        scrollToWhenActive = false,
        useChevronIcons = true,
        isCollapseDisabled = false,
        iconSize = IconSizes.Large,
        onOpen = () => {},
    } = props;
    const { t } = useLocalization();
    const [isCollapsed, setCollapsed] = useState<boolean>(collapse);

    const hasChildren = CollectionUtils.hasValues(
        React.Children.toArray(children)
    );
    const accessibleText = isCollapsed ? t("expand") : t("collapse");
    const buttonStyle = isCollapsed
        ? ButtonStyles.Secondary
        : ButtonStyles.Tertiary;
    const expandedClassName = hasChildren && !isCollapsed ? "-expanded" : "";

    const icons = useChevronIcons
        ? { expand: Icons.ChevronRight, collapse: Icons.ChevronDown }
        : { expand: Icons.Expand, collapse: Icons.Collapse };

    const iconType = isCollapsed ? icons.expand : icons.collapse;

    const accordionContentId = v4();
    const buttonId = v4();
    const handleTitleClick = () => {
        if (!isCollapseDisabled) {
            // If panel about to open, fire open event
            if (isCollapsed) {
                onOpen();
            } else if (!isCollapsed) {
                return;
            }
            setCollapsed(!isCollapsed);
        }
    };
    const handleToggle = () => {
        if (!isCollapseDisabled) {
            // If panel about to open, fire open event
            if (isCollapsed) {
                onOpen();
            }
            setCollapsed(!isCollapsed);
        }
    };

    const panelRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!collapse) {
            onOpen();
        }
        setCollapsed(collapse);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collapse]);

    useEffect(() => {
        if (scrollToWhenActive && !isCollapsed && panelRef.current) {
            panelRef.current.scrollIntoView();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCollapsed]);

    return (
        <div
            id={id}
            className={`${BASE_CLASS_NAME}  ${modifierCSSClassName} ${expandedClassName}`}
            ref={panelRef}>
            {hasChildren && (
                <Button
                    accessibleText={`${accessibleText} ${buttonAriaText}`}
                    ariaControls={accordionContentId}
                    ariaExpanded={!isCollapsed}
                    cssClassName="-icon"
                    id={buttonId}
                    onClick={handleToggle}
                    style={buttonStyle}
                    disabled={isCollapseDisabled}>
                    <Icon type={iconType} size={iconSize} />
                    <span className={"sr-only"}>{buttonAriaText}</span>
                </Button>
            )}
            <div
                className={`${BASE_CLASS_NAME}__heading`}
                onClick={handleTitleClick}>
                {panelTop}
            </div>
            <div
                aria-hidden={isCollapsed}
                aria-labelledby={buttonId}
                className={`${BASE_CLASS_NAME}__content ${expandedClassName}`}
                id={accordionContentId}
                role="region">
                {!isCollapsed && (
                    <div
                        className={`${BASE_CLASS_NAME}__accordion ${expandedClassName}`}>
                        {children}
                    </div>
                )}
            </div>
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default CollapsePanel;

// #endregion Exports
