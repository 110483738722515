import { Record } from "immutable";
import type LinkPreviewContext from "models/interfaces/link-preview-context";
import { RecordUtils } from "@rsm-hcd/javascript-core";

const defaultValues: LinkPreviewContext =
    RecordUtils.defaultValuesFactory<LinkPreviewContext>({
        isModalOpen: false,
        route: undefined,
        sectionId: undefined,
    });

export default class LinkPreviewContextRecord
    extends Record(defaultValues)
    implements LinkPreviewContext
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: LinkPreviewContext) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<LinkPreviewContext>} values
     */
    public with(values: Partial<LinkPreviewContext>): LinkPreviewContextRecord {
        return new LinkPreviewContextRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}
