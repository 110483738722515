import { XmlChangeNotationConstants } from "constants/xml-change-notation-constants";
import { XmlConvertedComponentProps } from "interfaces/forms/xml-converted-component-props";
import Symbol, { SYMBOL_CONVERTER_ID } from "molecules/equations/symbol";
import * as React from "react";
import useCurrentPublication from "utilities/contexts/use-current-publication";
import { tForCulture } from "utilities/localization-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface WhereListProps extends XmlConvertedComponentProps {
    cols: number;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CLASS_NAME = "c-where-list";

const DELETED_CLASS_NAME = "c-code-changes -deletion";

const CONVERTER_ID_PROP = "converterId";

export const DEFINITION_CONVERTER = "definition";

// #endregion Constants

const WhereList: React.FunctionComponent<WhereListProps> = (
    props: WhereListProps
) => {
    const { publication } = useCurrentPublication();

    const isDeleted = props.diffchanged === XmlChangeNotationConstants.DELETION;

    // Remove whitespace and invalid elements
    const preProcessedChildren: JSX.Element[] =
        React.Children.map(props.children, (child) => {
            if (!React.isValidElement(child) || child == null) {
                return;
            }
            return child;
        }) ?? [];

    // Prep children for display
    const children = React.Children.map(
        preProcessedChildren,
        (child, index) => {
            if (child?.props[CONVERTER_ID_PROP] === SYMBOL_CONVERTER_ID) {
                const nextElement = preProcessedChildren[index + 1];

                // If the next child is not a <Definition> skip it
                if (
                    nextElement?.props[CONVERTER_ID_PROP] !==
                    DEFINITION_CONVERTER
                ) {
                    return child;
                }
                return <Symbol includeEqualsSign={true} {...child.props} />;
            }

            return child;
        }
    );

    return (
        <div className={`${CLASS_NAME} ${isDeleted && DELETED_CLASS_NAME}`}>
            {`${tForCulture(publication?.locale, "where")}:`}
            <div className={`${CLASS_NAME}__content`}>{children}</div>
        </div>
    );
};

export default WhereList;
