import { Do } from "@rsm-hcd/javascript-core";
import Button from "atoms/buttons/button";
import ModalCloseButton from "atoms/buttons/modal-close-button";
import { ButtonStyles } from "atoms/constants/button-styles";
import MultiSelect from "atoms/forms/multi-select";
import { SelectOption } from "atoms/forms/select";
import Loader from "atoms/loaders/loader";
import Paragraph from "atoms/typography/paragraph";
import RoleRecord from "models/view-models/role-record";
import UserRecord from "models/view-models/user-record";
import UserRoleRecord from "models/view-models/user-role-record";
import { ModalTypes } from "molecules/constants/modal-types";
import Modal, { ModalProps } from "molecules/modals/modal";
import Typeahead from "molecules/typeahead/typeahead";
import React, { useCallback, useState } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import AdminUserService from "utilities/services/admin/admin-user-service";
import StringUtils from "utilities/string-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import { AdminRoleTypes } from "utilities/enumerations/role-type";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AddAdminRoleModalProps
    extends Pick<ModalProps, "closeDialog"> {
    initialUser?: UserRecord;
    onConfirm: (userRoles: Array<UserRoleRecord>) => void;
    loading: boolean;
    roles: Array<RoleRecord>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-add-admin-role-modal";

const ADD_ADMIN_TITLE = "Add Admin";
const ADD_ADMIN_SAVE_BUTTON_TEXT = "Add";
const EDIT_ADMIN_ROLES_TITLE = "Edit Roles";
const EDIT_ADMIN_SAVE_BUTTON_TEXT = "Save Changes";

const USER_SEARCH_LABEL = "Select an existing user to add as admin";
const USER_SEARCH_PLACEHOLDER = "Start typing to search users...";

const ROLE_SELECT_LABEL = "Admin role";
const ROLE_SELECT_PLACEHOLDER = "Select roles...";

const USER_REQUIRED_ERROR_MESSAGE = "You must select a user.";
const ONE_ROLE_REQUIRED_ERROR_MESSAGE = "You must choose at least one role.";

const NAME_NOT_AVAILABLE_TEXT = "Name not available.";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AddAdminRoleModal: React.FC<AddAdminRoleModalProps> = (
    props: AddAdminRoleModalProps
) => {
    // -------------------------------------------------------------------------------------------------
    // #region APIs
    // -------------------------------------------------------------------------------------------------

    const { list: listUsersApi } = AdminUserService.useList();

    // #endregion APIs

    // -------------------------------------------------------------------------------------------------
    // #region State
    // -------------------------------------------------------------------------------------------------

    const [userRoles, setUserRoles] = useState(
        props.initialUser?.userRoles ?? []
    );
    const [user, setUser] = useState(props.initialUser);
    const [userErrorMessage, setUserErrorMessage] = useState<string>();
    const [roleErrorMessage, setRoleErrorMessage] = useState<string>();

    // #endregion State

    // -------------------------------------------------------------------------------------------------
    // #region Functions
    // -------------------------------------------------------------------------------------------------

    const getUserOptionLabel = (user: UserRecord) =>
        `${user.getFirstAndLastName()} (${user.email})`;

    const searchUsers = useCallback(
        (searchText: string) =>
            Do.try<UserRecord, Array<UserRecord>>(async () => {
                const result = await listUsersApi({
                    excludeRoleTypes: AdminRoleTypes,
                    searchText,
                });
                return result.resultObjects!;
            })
                .catch(() =>
                    ToastManager.error("There was an issue searching users.")
                )
                .getAwaiter(),
        [listUsersApi]
    );

    const validate = (): boolean => {
        setUserErrorMessage(undefined);
        setRoleErrorMessage(undefined);

        let hasErrors = false;
        if (user == null) {
            setUserErrorMessage(USER_REQUIRED_ERROR_MESSAGE);
            hasErrors = true;
        }

        const roleCount = userRoles.filter(
            (ur: UserRoleRecord) => ur.roleId > 0
        ).length;
        if (roleCount < 1) {
            setRoleErrorMessage(ONE_ROLE_REQUIRED_ERROR_MESSAGE);
            hasErrors = true;
        }

        return !hasErrors;
    };

    // #endregion Functions

    // -------------------------------------------------------------------------------------------------
    // #region Event Handlers
    // -------------------------------------------------------------------------------------------------

    const handleUserSelected = (user?: UserRecord) => {
        setUser(user);
        if (user == null) {
            setUserRoles(
                userRoles.map((ur: UserRoleRecord) =>
                    ur.with({
                        userId: undefined,
                        user,
                    })
                )
            );
            return;
        }

        if (CollectionUtils.isEmpty(userRoles)) {
            setUserRoles([
                new UserRoleRecord().with({
                    userId: user.id,
                    user,
                }),
            ]);
            return;
        }

        setUserRoles(
            userRoles.map((ur: UserRoleRecord) =>
                ur.with({
                    userId: user.id,
                    user,
                })
            )
        );
    };

    const handleRolesSelected = (
        newValues: Array<SelectOption<RoleRecord, number>>
    ) => {
        const userRolesNotRemoved = userRoles.filter((ur: UserRoleRecord) =>
            newValues.some(
                (o: SelectOption<RoleRecord, number>) =>
                    o.data!.id === ur.roleId
            )
        );

        const userRolesToAdd = newValues
            .filter(
                (o: SelectOption<RoleRecord, number>) =>
                    !userRoles.some(
                        (ur: UserRoleRecord) => o.data!.id === ur.roleId
                    )
            )
            .map((o: SelectOption<RoleRecord, number>) =>
                new UserRoleRecord().with({
                    userId: user?.id,
                    user: user,
                    roleId: o.data!.id!,
                })
            );

        const newUserRoles = [...userRolesNotRemoved, ...userRolesToAdd];
        setUserRoles(newUserRoles);
    };

    const handleSaveClick = async () => {
        if (!validate()) {
            return;
        }

        // ensure UserRoles all have userId assigned
        const confirmedUserRoles = userRoles.map((ur: UserRoleRecord) =>
            ur.with({ userId: user!.id! })
        );
        await props.onConfirm(confirmedUserRoles);
        props.closeDialog();
    };

    // #endregion Event Handlers

    const saveButtonText =
        props.initialUser != null
            ? EDIT_ADMIN_SAVE_BUTTON_TEXT
            : ADD_ADMIN_SAVE_BUTTON_TEXT;

    return (
        <Modal
            {...props}
            cssClassName={CSS_CLASS_NAME}
            isVisible={true}
            label={"Add Admin"}
            type={ModalTypes.Base}>
            <div className={`${CSS_CLASS_NAME}__header`}>
                <Paragraph>
                    {props.initialUser == null && ADD_ADMIN_TITLE}
                    {props.initialUser != null && EDIT_ADMIN_ROLES_TITLE}
                </Paragraph>
                <ModalCloseButton onClick={props.closeDialog} />
            </div>
            <div className={`${CSS_CLASS_NAME}__body`}>
                {props.loading && (
                    <Loader accessibleText={"Saving user role..."} />
                )}
                {!props.loading && (
                    <React.Fragment>
                        {props.initialUser == null && (
                            <Typeahead<UserRecord>
                                errorMessage={userErrorMessage}
                                getOptionText={getUserOptionLabel}
                                isValid={StringUtils.isEmpty(userErrorMessage)}
                                label={USER_SEARCH_LABEL}
                                menuPosition={"fixed"}
                                onChange={handleUserSelected}
                                placeholder={USER_SEARCH_PLACEHOLDER}
                                required={true}
                                search={searchUsers}
                                value={user}
                            />
                        )}
                        {props.initialUser != null && (
                            <div
                                className={`${CSS_CLASS_NAME}__body__user-details`}>
                                <div
                                    className={`${CSS_CLASS_NAME}__body__user-details__item`}>
                                    <label>NAME</label>
                                    {StringUtils.hasValue(
                                        props.initialUser.getFirstAndLastName()
                                    ) && (
                                        <Paragraph>
                                            {props.initialUser.getFirstAndLastName()}
                                        </Paragraph>
                                    )}
                                    {StringUtils.isEmpty(
                                        props.initialUser.getFirstAndLastName()
                                    ) && (
                                        <Paragraph cssClassName={"-empty"}>
                                            {NAME_NOT_AVAILABLE_TEXT}
                                        </Paragraph>
                                    )}
                                </div>
                                <div
                                    className={`${CSS_CLASS_NAME}__body__user-details__item`}>
                                    <label>EMAIL ADDRESS</label>
                                    <Paragraph>
                                        {props.initialUser.email}
                                    </Paragraph>
                                </div>
                            </div>
                        )}
                        <MultiSelect
                            errorMessage={roleErrorMessage}
                            isClearable={false}
                            isValid={StringUtils.isEmpty(roleErrorMessage)}
                            label={ROLE_SELECT_LABEL}
                            menuPosition={"fixed"}
                            onChange={handleRolesSelected}
                            options={roleOptions(props.roles)}
                            placeholder={ROLE_SELECT_PLACEHOLDER}
                            required={true}
                            value={selectedRolesValue(userRoles)}
                        />
                    </React.Fragment>
                )}
            </div>
            <div className={`${CSS_CLASS_NAME}__footer`}>
                <Button
                    onClick={props.closeDialog}
                    style={ButtonStyles.Secondary}>
                    Cancel
                </Button>
                <Button onClick={handleSaveClick}>{saveButtonText}</Button>
            </div>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Functions
// -------------------------------------------------------------------------------------------------

const roleOptions = (
    roles: Array<RoleRecord>
): Array<SelectOption<RoleRecord, number>> =>
    roles.map((r: RoleRecord) => ({
        label: r.getName(),
        value: r.id!,
        data: r,
    }));

const selectedRolesValue = (userRoles: Array<UserRoleRecord>) =>
    userRoles.map((ur: UserRoleRecord) => ur.roleId);

// #endregion Functions

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default AddAdminRoleModal;

// #endregion Exports
