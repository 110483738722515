import { ResultRecord, ServiceResponse } from "@rsm-hcd/javascript-core";
import { QueryObserverOptions, QueryObserverResult } from "react-query";
import useServiceQuery from "utilities/hooks/queries/use-service-query";
import QueryFunctionFactory from "utilities/hooks/queries/query-function-factory";
import { useCallback } from "react";
import ServiceFactory from "utilities/services/service-factory";

// -----------------------------------------------------------------------------------------
// #region Types
// -----------------------------------------------------------------------------------------

export type ListQuery<TRecord, TQueryParams> = (
    queryParams?: TQueryParams,
    options?: QueryObserverOptions<
        ServiceResponse<TRecord>,
        ResultRecord<TRecord>
    >
) => QueryObserverResult<ServiceResponse<TRecord>, ResultRecord<TRecord>>;

export type NestedListQuery<TRecord, TRouteParams, TQueryParams> = (
    routeParams: TRouteParams,
    queryParams?: TQueryParams,
    options?: QueryObserverOptions<
        ServiceResponse<TRecord>,
        ResultRecord<TRecord>
    >
) => QueryObserverResult<ServiceResponse<TRecord>, ResultRecord<TRecord>>;

export type BatchedListQuery<TRecord, TQueryParams> = (
    queryParams: TQueryParams,
    property: keyof TQueryParams,
    size: number
) => QueryObserverResult<ServiceResponse<TRecord>, ResultRecord<TRecord>>;

export type NestedBatchedListQuery<TRecord, TPathParams, TQueryParams> = (
    pathParams: TPathParams,
    queryParams: TQueryParams,
    property: keyof TQueryParams,
    size: number
) => QueryObserverResult<ServiceResponse<TRecord>, ResultRecord<TRecord>>;

export type ListQueryHook<TRecord, TQueryParams> = () => {
    list: ListQuery<TRecord, TQueryParams>;
};

export type NestedListQueryHook<TRecord, TRouteParams, TQueryParams> = () => {
    list: NestedListQuery<TRecord, TRouteParams, TQueryParams>;
};
export type GetQueryHook<
    TRecord,
    TRouteParams,
    TQueryParams = undefined,
> = () => {
    get: NestedListQuery<TRecord, TRouteParams, TQueryParams>;
};

export type BatchedListQueryHook<TRecord, TQueryParams> = () => {
    list: BatchedListQuery<TRecord, TQueryParams>;
};

export type NestedBatchedListQueryHook<TRecord, TPathParams, TQueryParams> =
    () => {
        list: NestedBatchedListQuery<TRecord, TPathParams, TQueryParams>;
    };

// #endregion Types

const QueryHookFactory = {
    useGetQuery<TRecord, TRouteParams, TQueryParams = undefined>(
        key: string,
        resourceType: new () => TRecord,
        endpoint: string
    ): GetQueryHook<TRecord, TRouteParams, TQueryParams> {
        return () => {
            const get = ServiceFactory.get<TRecord, TRouteParams, TQueryParams>(
                resourceType,
                endpoint
            );
            const queryFunction = QueryFunctionFactory.get<
                TRecord,
                TRouteParams,
                TQueryParams
            >(get);
            const _get = (
                routeParams: TRouteParams,
                queryParams?: TQueryParams,
                options?: QueryObserverOptions<
                    ServiceResponse<TRecord>,
                    ResultRecord<TRecord>
                >
            ) =>
                useServiceQuery(
                    [key, { routeParams, queryParams }],
                    queryFunction,
                    options
                );

            return { get: useCallback(_get, [queryFunction]) };
        };
    },
    useListQuery<TRecord, TQueryParams>(
        key: string,
        resourceType: new () => TRecord,
        endpoint: string
    ): ListQueryHook<TRecord, TQueryParams> {
        return () => {
            const list = ServiceFactory.list<TRecord, TQueryParams>(
                resourceType,
                endpoint
            );
            const queryFunction = QueryFunctionFactory.list<
                TRecord,
                TQueryParams
            >(list);
            const _list = (
                queryParams?: TQueryParams,
                options?: QueryObserverOptions<
                    ServiceResponse<TRecord>,
                    ResultRecord<TRecord>
                >
            ) => useServiceQuery([key, queryParams], queryFunction, options);

            return { list: useCallback(_list, [queryFunction]) };
        };
    },
    useNestedListQuery<TRecord, TRouteParams, TQueryParams>(
        key: string,
        resourceType: new () => TRecord,
        endpoint: string
    ): NestedListQueryHook<TRecord, TRouteParams, TQueryParams> {
        return () => {
            const nestedList = ServiceFactory.nestedList<
                TRecord,
                TRouteParams,
                TQueryParams
            >(resourceType, endpoint);

            const queryFunction = QueryFunctionFactory.nestedList<
                TRecord,
                TRouteParams,
                TQueryParams
            >(nestedList);

            const _list = (
                routeParams: TRouteParams,
                queryParams?: TQueryParams,
                options?: QueryObserverOptions<
                    ServiceResponse<TRecord>,
                    ResultRecord<TRecord>
                >
            ) =>
                useServiceQuery(
                    [key, { routeParams, queryParams }],
                    queryFunction,
                    options
                );

            return { list: useCallback(_list, [queryFunction]) };
        };
    },
    useBatchedListQuery<TRecord, TQueryParams>(
        key: string,
        resourceType: new () => TRecord,
        baseEndpoint: string
    ): BatchedListQueryHook<TRecord, TQueryParams> {
        return () => {
            const serviceList = ServiceFactory.batchedList<
                TRecord,
                TQueryParams
            >(resourceType, baseEndpoint);

            const queryFunction = QueryFunctionFactory.batchedList<
                TRecord,
                TQueryParams
            >(serviceList);

            const _list = (
                queryParams: TQueryParams,
                property: keyof TQueryParams,
                size: number,
                options?: QueryObserverOptions<
                    ServiceResponse<TRecord>,
                    ResultRecord<TRecord>
                >
            ) =>
                useServiceQuery(
                    [key, { queryParams, property, size }],
                    queryFunction,
                    options
                );

            return { list: useCallback(_list, [queryFunction]) };
        };
    },
    useNestedBatchedListQuery<TRouteParams, TRecord, TQueryParams>(
        key: string,
        resourceType: new () => TRecord,
        baseEndpoint: string
    ): NestedBatchedListQueryHook<TRecord, TRouteParams, TQueryParams> {
        return () => {
            const serviceList = ServiceFactory.nestedBatchedList<
                TRecord,
                TRouteParams,
                TQueryParams
            >(resourceType, baseEndpoint);

            const queryFunction = QueryFunctionFactory.nestedBatchedList<
                TRecord,
                TRouteParams,
                TQueryParams
            >(serviceList);

            const _list = (
                routeParams: TRouteParams,
                queryParams: TQueryParams,
                property: keyof TQueryParams,
                size: number,
                options?: QueryObserverOptions<
                    ServiceResponse<TRecord>,
                    ResultRecord<TRecord>
                >
            ) =>
                useServiceQuery(
                    [key, { routeParams, queryParams, property, size }],
                    queryFunction,
                    options
                );

            return { list: useCallback(_list, [queryFunction]) };
        };
    },
};
export default QueryHookFactory;
