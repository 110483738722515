import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useLocalization } from "./use-localization";
import { UnicodeCharacterConstants } from "constants/unicode-character-constants";

const useAccountInformationTab = () => {
    const { globalState } = useGlobalState();
    const { t } = useLocalization();
    const accountInfoLabel = t("accountInformationTab-header");
    const emailLabel = t("email");
    const emailNotAvailable = t("field-notAvailable", { item: emailLabel });

    const nameLabel = t("field-fullName");
    const nameNotAvailable = t("field-notAvailable", { item: nameLabel });

    const email = globalState.currentIdentity?.user?.email;

    const fullName = globalState.currentIdentity?.user?.getFirstAndLastName();

    const password = UnicodeCharacterConstants.Bullet.repeat(12);

    return {
        accountInfoLabel,
        email,
        emailLabel,
        emailNotAvailable,
        fullName,
        nameLabel,
        nameNotAvailable,
        password,
    };
};

export default useAccountInformationTab;
