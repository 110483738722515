import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import Icon from "atoms/icons/icon";
import UserCollectionRecord from "models/view-models/user-collection-record";
import React from "react";
import useUserCollectionInfo from "utilities/hooks/domain/users/user-collections/use-user-collection-info";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import { useLocalization } from "utilities/hooks/use-localization";
import useModalActions from "utilities/hooks/use-modal-actions";
import UserCollectionListItemMenu from "./user-collection-list-item-menu";
import UserCollectionVisibilityModal from "./user-collection-visibility-modal";

const BASE_CLASS_NAME = "c-user-collection-list-item";

export interface UserCollectionListItemHeaderProps {
    userCollection: UserCollectionRecord;
}

const UserCollectionListItemHeader = (
    props: UserCollectionListItemHeaderProps
) => {
    const { userCollection } = props;

    const { useWorkGroups } = useFeatureFlags();

    const showItemMenu =
        useWorkGroups && userCollection.isWorkGroupCollection();

    const { pageLabel, collectionIconType } =
        useUserCollectionInfo(userCollection);

    const { handleClose, handleOpen, isOpen } = useModalActions();

    const handleIconClick = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault();
        e.stopPropagation();
        handleOpen();
    };

    const { t } = useLocalization();

    const userBookmarkCount = userCollection.userBookmarkCount ?? 0;
    const userBookmarkCountLabel = t("bookmark", { count: userBookmarkCount });
    const isWorkGroupCollection = userCollection.isWorkGroupCollection();
    const collectionVisibility = t("collectionVisibility");

    const workGroupTitles =
        userCollection?.workGroups
            ?.map((workGroup) => workGroup?.title ?? "")
            .sort((a, b) => a.localeCompare(b)) ?? [];

    return (
        <div className={`${BASE_CLASS_NAME}__meta`}>
            <div className={`${BASE_CLASS_NAME}__meta__left`}>
                {isWorkGroupCollection ? (
                    <>
                        <Button
                            accessibleText={collectionVisibility}
                            onClick={handleIconClick}
                            style={ButtonStyles.None}>
                            <Icon
                                cssClassName={pageLabel}
                                type={collectionIconType}
                                size={IconSizes.Large}
                            />
                        </Button>
                        <span>({userCollection.workGroups.length})</span>
                    </>
                ) : (
                    <Icon
                        cssClassName={pageLabel}
                        type={collectionIconType}
                        size={IconSizes.Large}
                    />
                )}
            </div>
            <div className={`${BASE_CLASS_NAME}__meta__right`}>
                <span>
                    {userBookmarkCount} {userBookmarkCountLabel}
                </span>
                {showItemMenu && (
                    <UserCollectionListItemMenu handleOpen={handleOpen}>
                        <UserCollectionVisibilityModal
                            closeDialog={handleClose}
                            isVisible={isOpen}
                            title={userCollection.name}
                            workGroupTitles={workGroupTitles}
                        />
                    </UserCollectionListItemMenu>
                )}
            </div>
        </div>
    );
};

export default UserCollectionListItemHeader;
