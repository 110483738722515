import { AnchorTargetTypes } from "@rsm-hcd/javascript-core";
import Anchor from "atoms/anchors/anchor";
import Button from "atoms/buttons/button";
import useAzure from "pages/azure-login/use-azure";
import React from "react";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import useNfpaUrlPaths from "utilities/hooks/use-nfpa-url-paths";
import { t } from "utilities/localization-utils";

interface ForgotPasswordProps {
    cssClassName?: string;
    buttonText?: string;
}

const ForgotPasswordButton = (props: ForgotPasswordProps) => {
    const { cssClassName, buttonText } = props;
    const { useAzureB2CForSSO } = useFeatureFlags();
    const { forgotPassword } = useAzure();
    const { forgotPasswordUrl } = useNfpaUrlPaths();

    return useAzureB2CForSSO ? (
        <Button onClick={forgotPassword} cssClassName={cssClassName}>
            {buttonText ? buttonText : t("forgotPassword")}
        </Button>
    ) : (
        <Anchor
            cssClassName={cssClassName}
            external={true}
            target={AnchorTargetTypes.Blank}
            to={forgotPasswordUrl!}>
            {buttonText ? buttonText : t("forgotPassword")}
        </Anchor>
    );
};

export default ForgotPasswordButton;
