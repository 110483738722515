import { Record } from "immutable";
import type AdminEditorPageContext from "models/interfaces/situational-navigation/admin-editor-page-context";
import CategoryCollectionRecord from "models/view-models/situational-navigation/categories/category-collection-record";

import { RecordUtils } from "@rsm-hcd/javascript-core";

const defaultValues: AdminEditorPageContext =
    RecordUtils.defaultValuesFactory<AdminEditorPageContext>({
        categories: new CategoryCollectionRecord(),
        currentValue: undefined,
        publicationBatch: [],
        shouldFetchNavItems: true,
    });

export default class AdminEditorPageContextRecord
    extends Record(defaultValues)
    implements AdminEditorPageContext
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: AdminEditorPageContext) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        // ensure members are the Immutable Record classes
        params.categories = new CategoryCollectionRecord(params.categories);

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<AdminEditorPageContext>} values
     */
    public with(
        values: Partial<AdminEditorPageContext>
    ): AdminEditorPageContextRecord {
        const updatedValues = {
            ...this.toObject(),
            ...values,
        };

        return new AdminEditorPageContextRecord(updatedValues);
    }
    // #endregion Public Methods
}
