import FullScreenTransition from "organisms/full-screen-transition/full-screen-transition";
import React, { useEffect } from "react";
import useAzure from "./use-azure";
import { useUrlCallback } from "utilities/routing/url-callback/use-url-callback";

const AzureRedirectPage = () => {
    const { login } = useAzure();
    const { callbackUrl } = useUrlCallback();

    useEffect(() => {
        if (callbackUrl) {
            login();
        }
    }, [callbackUrl, login]);
    return <FullScreenTransition transitionText={""} />;
};

export default AzureRedirectPage;
