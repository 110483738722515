import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Trans from "atoms/i18n/trans";
import Paragraph from "atoms/typography/paragraph";
import { siteMap } from "internal-sitemap";
import CategoryCollectionRecord from "models/view-models/situational-navigation/categories/category-collection-record";
import CategoryRecord from "models/view-models/situational-navigation/categories/category-record";
import SituationRecord from "models/view-models/situational-navigation/situations/situation-record";
import SolutionRecord from "models/view-models/situational-navigation/solutions/solution-record";
import { LinkCardTypes } from "molecules/constants/link-card-types";
import LinkCard from "molecules/link-card/link-card";
import SituationRelatedSituationCard from "organisms/situational-navigation/situations/situation-related-situation-card";
import React from "react";
import { useLocalization } from "utilities/hooks/use-localization";
import { RouteUtils } from "utilities/route-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface SituationalNavigationResultsViewProps {
    categoriesFromContext: CategoryCollectionRecord;
    situations: Array<SituationRecord>;
    solutions: Array<SolutionRecord>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const SituationalNavigationResultsView: React.FC<
    SituationalNavigationResultsViewProps
> = (props: SituationalNavigationResultsViewProps) => {
    // -----------------------------------------------------------------------------------------
    // #region Constants
    // -----------------------------------------------------------------------------------------
    const { categoriesFromContext, situations, solutions } = props;
    const { t } = useLocalization();
    const cssBaseClassName = "c-situational-navigation-results-view";
    const total = situations.length + solutions.length;
    const categoryFilters = categoriesFromContext
        .mapToArray((c: CategoryRecord) => c.title)
        .join(", ");

    // #endregion Constants

    return (
        <div className={cssBaseClassName}>
            <Paragraph
                cssClassName={`${cssBaseClassName}__parameters`}
                size={ParagraphSizes.Small}>
                <Trans i18nKey="sitnav-results-message" count={total}>
                    <>
                        <strong>
                            <>{{ total }}</>
                        </strong>
                        &nbsp; Results filtered by
                        <span>
                            <>{{ categoryFilters }}</>
                        </span>
                    </>
                </Trans>
            </Paragraph>

            <div className={`${cssBaseClassName}__situations`}>
                <Paragraph
                    cssClassName={`${cssBaseClassName}__situations__header`}
                    size={ParagraphSizes.Small}>
                    <span>{situations.length}</span>{" "}
                    {t("situation", { count: situations.length })}
                </Paragraph>
                <div className={`${cssBaseClassName}__situations__row`}>
                    {situations.map((situation) => (
                        <SituationRelatedSituationCard
                            accessibleText={situation.title}
                            key={situation.id!}
                            situation={situation}
                        />
                    ))}
                </div>
            </div>
            <div className={`${cssBaseClassName}__solutions`}>
                <Paragraph
                    cssClassName={`${cssBaseClassName}__solutions__header`}
                    size={ParagraphSizes.Small}>
                    <span>{solutions.length}</span>{" "}
                    {t("solution", { count: solutions.length })}
                </Paragraph>
                {solutions.map((solution, index) => {
                    return (
                        <LinkCard
                            includeIcon={true}
                            key={index}
                            label={solution.subtitle}
                            to={RouteUtils.getUrl(
                                siteMap.situationalNavigation.solutions,
                                { id: solution.id }
                            )}
                            type={LinkCardTypes.Link}>
                            {solution.title}
                        </LinkCard>
                    );
                })}
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default SituationalNavigationResultsView;

// #endregion Exports
