import SolutionRelationshipRecord from "models/view-models/situational-navigation/solutions/solution-relationship-record";
import { PagedQuery } from "@rsm-hcd/javascript-core";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AdminSolutionRelationshipPathParams {
    solutionId: number;
}

export interface AdminSolutionRelationshipQueryParams extends PagedQuery {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint =
    "admin/situational-navigation/solutions/:solutionId/relationships";
const resourceEndpoint = `${baseEndpoint}/:id`;
const resourceType = SolutionRelationshipRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const AdminSolutionRelationshipService = {
    list: ServiceFactory.nestedList<
        SolutionRelationshipRecord,
        AdminSolutionRelationshipPathParams,
        AdminSolutionRelationshipQueryParams
    >(resourceType, baseEndpoint),
    useList: ServiceHookFactory.useNestedList<
        SolutionRelationshipRecord,
        AdminSolutionRelationshipPathParams,
        AdminSolutionRelationshipQueryParams
    >(resourceType, baseEndpoint),
    create: ServiceFactory.nestedCreate<
        SolutionRelationshipRecord,
        AdminSolutionRelationshipPathParams
    >(resourceType, baseEndpoint),
    useCreate: ServiceHookFactory.useNestedCreate<
        SolutionRelationshipRecord,
        AdminSolutionRelationshipPathParams
    >(resourceType, baseEndpoint),
    delete: ServiceFactory.delete<AdminSolutionRelationshipPathParams>(
        resourceEndpoint
    ),
    useDelete:
        ServiceHookFactory.useDelete<AdminSolutionRelationshipPathParams>(
            resourceEndpoint
        ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default AdminSolutionRelationshipService;

// #endregion Exports
