import { ResultRecord } from "@rsm-hcd/javascript-core";
import { useCallback, useEffect, useRef } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import TableOfContentsService from "utilities/services/publications/table-of-contents/table-of-contents-service";
import { ToastManager } from "utilities/toast/toast-manager";
import useBookviewTableOfContents from "./use-bookview-table-of-contents";

export default function useListArticleSections(
    articleId: number | undefined,
    publicationId: number | undefined
) {
    const { list: listArticleApi } = TableOfContentsService.useGetArticle();
    const { replaceArticle, tableOfContents } = useBookviewTableOfContents();

    const callInProgress = useRef<boolean>(false);

    const listArticleSections = useCallback(
        async (
            articleId: number | undefined,
            publicationId: number | undefined
        ) => {
            if (articleId == null || publicationId == null) return;
            const findArticle = (articleId: number) =>
                tableOfContents.chapters
                    ?.find((c) => c.articles?.some((a) => a.id === articleId))
                    ?.articles?.find((a) => a.id === articleId);

            const article = findArticle(articleId);

            if (article == null || CollectionUtils.hasValues(article.sections))
                return;

            callInProgress.current = true;

            try {
                const response = await listArticleApi({
                    articleId,
                    publicationId,
                });

                const updatedArticle = article.with({
                    sections: response.resultObjects,
                });

                replaceArticle(updatedArticle, tableOfContents);
            } catch (error) {
                if (error instanceof ResultRecord)
                    ToastManager.error(error.listErrorMessages);
            } finally {
                callInProgress.current = false;
            }
        },
        [listArticleApi, replaceArticle, tableOfContents]
    );

    useEffect(() => {
        if (!callInProgress.current) {
            listArticleSections(articleId, publicationId);
        }
    }, [articleId, listArticleSections, publicationId]);
}
