import { PagedQuery } from "@rsm-hcd/javascript-core";
import PublishStatus from "models/enumerations/publish-status";
import PublicationRecord from "models/view-models/publication-record";
import SearchQuery from "utilities/interfaces/search-query";
import ServiceHookFactory from "utilities/services/service-hook-factory";

export interface AdminPublicationQueryParams extends PagedQuery, SearchQuery {
    code?: string;
    edition?: string;
    groupByPrinting?: boolean;
    hasPublishedEnhancedContent?: boolean;
    publishStatus?: PublishStatus;
}

export interface AdminPublicationPathParams {
    id?: number;
}

export interface AdminPublicationQueryByPublicationIdsParams
    extends PagedQuery,
        SearchQuery {
    publicationIds: number[];
}

const baseEndpoint = "admin/publications/legacy";

const AdminPublicationService = {
    useCreate: ServiceHookFactory.useCreate<PublicationRecord>(
        PublicationRecord,
        baseEndpoint
    ),
};

export default AdminPublicationService;
