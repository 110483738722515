import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import Image from "atoms/images/image";
import Paragraph from "atoms/typography/paragraph";
import Video from "atoms/videos/video";
import EnhancedContentResourceRecord from "models/view-models/enhanced-content-resource-record";
import FileRecord from "models/view-models/file-record";
import SolutionResourceRecord from "models/view-models/situational-navigation/solutions/solution-resource-record";
import { ResourceCardStyle } from "organisms/situational-navigation/resources/resource-card";
import React, { useEffect, useState } from "react";
import ResourceType from "utilities/enumerations/resource-type";
import ExternalResourceUtils from "utilities/external-resource-utils";
import FileUtils from "utilities/file-utils";
import useBrightcoveMetadata from "utilities/hooks/domain/external/use-brightcove-metadata";
import { useLocalization } from "utilities/hooks/use-localization";
import { t } from "utilities/localization-utils";
import YoutubeMetadataService from "utilities/services/external/youtube/youtube-metadata-service";
import StringUtils from "utilities/string-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ResourceCardContentsProps {
    absoluteFileUrl?: string;
    cssBaseClass?: string;
    draftMode: boolean;
    file?: FileRecord;
    resource: SolutionResourceRecord | EnhancedContentResourceRecord;
    style: ResourceCardStyle;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ResourceCardContents: React.FC<ResourceCardContentsProps> = (
    props: ResourceCardContentsProps
) => {
    const CSS_CLASS_NAME = props.cssBaseClass ?? "c-resource-card";

    const { get: getYoutubeMetadataApi } = YoutubeMetadataService.useGet();
    const { t } = useLocalization();

    const [fileMetadata, setFileMetadata] = useState("...");

    const includeResourceType = props.style === ResourceCardStyle.Tile;
    const includeMetadata = props.style === ResourceCardStyle.Card;
    const includeDescription = props.style === ResourceCardStyle.Card;

    let absoluteUrl = props.resource.absoluteUrl;
    let title = props.resource.title;
    let description = props.resource.description;

    const { brightcoveMetadata } = useBrightcoveMetadata(absoluteUrl);

    if (props.draftMode) {
        absoluteUrl = props.resource.absoluteUrlDraft;
        title = props.resource.titleDraft;
        description = props.resource.descriptionDraft;
    }

    useEffect(() => {
        const loadYoutubeDuration = async () => {
            try {
                const result = await getYoutubeMetadataApi({
                    videoId: ExternalResourceUtils.getYoutubeVideoId(
                        absoluteUrl!
                    )!,
                });
                setFileMetadata(
                    ExternalResourceUtils.roundVideoDuration(
                        result.resultObject!.duration,
                        t
                    ) ?? ""
                );
            } catch (e) {
                setFileMetadata("");
            }
        };

        const loadFileSize = async () => {
            try {
                const result = await FileUtils.getFormattedFileSize(
                    props.file!
                );
                setFileMetadata(result);
            } catch (e) {
                setFileMetadata("");
            }
        };

        if (
            ExternalResourceUtils.isYoutubeLink(absoluteUrl) &&
            includeMetadata
        ) {
            loadYoutubeDuration();
            return;
        }

        if (props.file != null && includeMetadata) {
            loadFileSize();
            return;
        }

        setFileMetadata("");
    }, [
        absoluteUrl,
        getYoutubeMetadataApi,
        includeMetadata,
        props.absoluteFileUrl,
        props.file,
        t,
    ]);

    const shouldRenderPlayIcon = (): boolean => {
        if (props.resource.isExternal(props.draftMode)) {
            return (
                ExternalResourceUtils.isYoutubeLink(absoluteUrl) ||
                ExternalResourceUtils.isBrightcoveLink(absoluteUrl)
            );
        }

        if (props.file == null) {
            return false;
        }

        return props.file.resourceType() === ResourceType.Video;
    };

    return (
        <React.Fragment>
            <div className={`${CSS_CLASS_NAME}__thumbnail-container`}>
                {
                    // if, putting this here because of absolute positioning and overflow
                    shouldRenderPlayIcon() && (
                        <div
                            className={`${CSS_CLASS_NAME}__thumbnail-container__video-play-icon`}>
                            <Icon type={Icons.MediaPlay} />
                        </div>
                    )
                }
                <div
                    className={`${CSS_CLASS_NAME}__thumbnail-container__thumbnail`}>
                    {getThumbnail(
                        CSS_CLASS_NAME,
                        absoluteUrl,
                        props.file,
                        props.absoluteFileUrl ?? absoluteUrl,
                        title,
                        brightcoveMetadata?.thumbnailUrl
                    )}
                </div>
            </div>
            <div className={`${CSS_CLASS_NAME}__info`}>
                <Paragraph cssClassName={`${CSS_CLASS_NAME}__info__title`}>
                    {title}
                </Paragraph>
                {
                    // if
                    StringUtils.hasValue(description) && includeDescription && (
                        <div className={`${CSS_CLASS_NAME}__info__subtitle`}>
                            <Paragraph>{description}</Paragraph>
                        </div>
                    )
                }
                {includeMetadata && (
                    <div className={`${CSS_CLASS_NAME}__info__metadata`}>
                        <Paragraph>{fileMetadata}</Paragraph>
                    </div>
                )}
                {includeResourceType && (
                    <label className={`c-label -small`}>
                        {getResourceDisplayType(absoluteUrl, props.file)}
                    </label>
                )}
            </div>
        </React.Fragment>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

const getThumbnail = (
    baseCssClassName: string,
    absoluteUrl?: string,
    file?: FileRecord,
    link: string = "",
    title?: string,
    brightcoveThumbnailUrl?: string
): React.ReactNode => {
    if (file?.resourceType() === ResourceType.Video) {
        return (
            <div
                className={`${baseCssClassName}__thumbnail-container__thumbnail__video`}>
                <Video
                    autoplay={false}
                    muted={true}
                    showControls={false}
                    sources={[link]}
                />
            </div>
        );
    }

    if (file?.resourceType() === ResourceType.Image) {
        return (
            <div
                className={`${baseCssClassName}__thumbnail-container__thumbnail__image`}>
                <Image altText={title} src={link} />
            </div>
        );
    }

    if (
        file == null &&
        absoluteUrl != null &&
        (ExternalResourceUtils.isYoutubeLink(absoluteUrl) ||
            ExternalResourceUtils.isBrightcoveLink(absoluteUrl))
    ) {
        return (
            <div
                className={`${baseCssClassName}__thumbnail-container__thumbnail__image`}>
                <Image
                    altText={title}
                    src={
                        ExternalResourceUtils.isYoutubeLink(absoluteUrl)
                            ? ExternalResourceUtils.getYoutubeThumbnailUrl(
                                  absoluteUrl!
                              )!
                            : brightcoveThumbnailUrl?.length
                              ? brightcoveThumbnailUrl
                              : "https://img.brightcove.com/logo-download-4-2013.gif"
                    }
                />
            </div>
        );
    }

    // Document icon is default (if type is Document OR unknown)
    return (
        <div
            className={`${baseCssClassName}__thumbnail-container__thumbnail__icon`}>
            <Icon type={Icons.Document} size={IconSizes.Large} />
        </div>
    );
};

const getResourceDisplayType = (
    absoluteUrl?: string,
    file?: FileRecord
): string => {
    if (
        ExternalResourceUtils.isYoutubeLink(absoluteUrl) ||
        ExternalResourceUtils.isBrightcoveLink(absoluteUrl)
    ) {
        return t("resourceType-Video");
    }

    if (file == null) {
        return t("resourceType-File");
    }

    return file.resourceDisplayType();
};

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default ResourceCardContents;

// #endregion Exports
