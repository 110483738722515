import { ResultRecord, ServiceResponse } from "@rsm-hcd/javascript-core";
import { useAsyncEffect } from "@rsm-hcd/javascript-react";
import { siteMap } from "internal-sitemap";
import GlobalStateRecord from "models/view-models/global-state-record";
import SubscriptionRecord from "models/view-models/subscription-record";
import UserLoginRecord from "models/view-models/user-login-record";
import UserRoleRecord from "models/view-models/user-role-record";
import FullScreenTransition from "organisms/full-screen-transition/full-screen-transition";
import React from "react";
import { useMutation } from "react-query";
import { Redirect, useParams } from "react-router-dom";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import useServiceQuery from "utilities/hooks/queries/use-service-query";
import { useLocalization } from "utilities/hooks/use-localization";
import NumberUtils from "utilities/number-utils";
import SubscriptionService from "utilities/services/subscriptions/subscription-service";
import UserLoginService from "utilities/services/user-logins/user-login-service";
import StringUtils from "utilities/string-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------
interface GetUserLoginPageRouteParams {
    subscriptionPin: string;
    externalIdentifier?: string;
}

interface GetUserLoginPageProps {}

// #endregion Interfaces

const GetUserLoginPage: React.FunctionComponent<GetUserLoginPageProps> = (
    props: GetUserLoginPageProps
) => {
    const { subscriptionPin, externalIdentifier } =
        useParams<GetUserLoginPageRouteParams>();
    const { globalState, setGlobalState } = useGlobalState();
    const { currentIdentity } = globalState;
    const userRoles = currentIdentity?.getActiveUserRoles();
    const currentLogin = currentIdentity?.userLogin ?? new UserLoginRecord();

    const { t } = useLocalization();
    const authenticatingMessage = t("switchProfile-loadingMessage");

    // TODO: https://app.clickup.com/t/2219993/NFPA-4524
    const subscriptionsResult = useServiceQuery<SubscriptionRecord>(
        [SubscriptionService.key, { subscriptionPin }],
        () =>
            SubscriptionService.list({ sku: subscriptionPin }) as Promise<
                ServiceResponse<SubscriptionRecord>
            >,
        { enabled: StringUtils.hasValue(subscriptionPin) }
    );

    const handleUpdateSuccess = (
        data: ServiceResponse<UserLoginRecord>
    ): void =>
        setGlobalState((globalState: GlobalStateRecord) =>
            globalState.setIdentity(
                globalState.currentIdentity!.withUserLogin(data.resultObject!)
            )
        );

    // TODO: https://app.clickup.com/t/2219993/NFPA-4524
    const userloginMutation = useMutation<
        ServiceResponse<UserLoginRecord>,
        ResultRecord<UserLoginRecord>,
        UserLoginRecord,
        ResultRecord<UserLoginRecord>
    >(
        (login: UserLoginRecord) =>
            UserLoginService.update(login, { id: currentLogin.id }),
        { onSuccess: handleUpdateSuccess }
    );

    const subscription =
        subscriptionsResult.data?.resultObjects[0] ?? new SubscriptionRecord();

    const userRoleMatchingGroup = currentIdentity?.userRoles.find((ur) =>
        filterUserRoleByExternalGroupId(ur, externalIdentifier)
    );

    const nextUserRole = userRoles?.find(
        (ur: UserRoleRecord) =>
            filterUserRoleBySubscriptionRoleId(ur, subscription) &&
            filterUserRoleByGroupUserRole(ur, userRoleMatchingGroup)
    );

    useAsyncEffect(
        async function updateUserLogin() {
            if (nextUserRole == null || userloginMutation.isLoading) {
                return;
            }
            await userloginMutation.mutateAsync(
                currentLogin.with({
                    roleId: nextUserRole?.roleId,
                    userRoleId: nextUserRole?.id,
                })
            );
        },
        [nextUserRole]
    );

    const redirectToLogin =
        !globalState.isAuthenticated() ||
        (subscriptionsResult.isFetched &&
            NumberUtils.isDefault(subscription.id));

    // Bad request, return to login
    if (redirectToLogin) {
        return <Redirect to={siteMap.userlogins.new} />;
    }

    // Waiting for update to complete
    if (!userloginMutation.isSuccess) {
        return <FullScreenTransition transitionText={authenticatingMessage} />;
    }

    // Update complete, return to the dashboard
    return <Redirect to={siteMap.dashboards.user} />;
};

export default GetUserLoginPage;

// -----------------------------------------------------------------------------------------
// #region Private Functions
// -----------------------------------------------------------------------------------------

function filterUserRoleBySubscriptionRoleId(
    userRole: UserRoleRecord,
    subscription: SubscriptionRecord
) {
    return userRole.roleId === subscription.roleId;
}

function filterUserRoleByGroupUserRole(
    userRole: UserRoleRecord,
    groupUserRole?: UserRoleRecord
) {
    return groupUserRole == null || userRole.id === groupUserRole.id;
}

function filterUserRoleByExternalGroupId(
    userRole: UserRoleRecord,
    externalIdentifier?: string
) {
    return (
        externalIdentifier != null &&
        userRole.userRoleGroup?.group?.externalIdentifier === externalIdentifier
    );
}

// #endregion Private Functions
