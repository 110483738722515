import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import { siteMap } from "internal-sitemap";
import React from "react";
import { Redirect } from "react-router-dom";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import useWorkGroupReadService from "utilities/hooks/domain/work-groups/use-work-group-read-service";
import { useLocalization } from "utilities/hooks/use-localization";
import AddNewWorkGroup from "./add-new-work-group";
import WorkGroupManageSearch from "./work-groups-manage-search";
import WorkGroupManageTable from "./work-groups-manage-table";

interface WorkGroupTabPanelProps {}

const BASE_CLASS = "c-account-dashboard__workgroups";

const WorkGroupTabPanel: React.FC<WorkGroupTabPanelProps> = () => {
    const { globalState } = useGlobalState();
    const workGroupReadService = useWorkGroupReadService();
    const { t } = useLocalization();
    const title = t("accountDashboard-workGroup");

    if (!globalState.currentIdentity?.isAdminOfCurrentEnterpriseGroup()) {
        return <Redirect to={siteMap.account.information} />;
    }

    return (
        <React.Fragment>
            <div className={BASE_CLASS}>
                <Heading priority={HeadingPriority.Five}>{title}</Heading>
                <div className={`${BASE_CLASS}__actions`}>
                    <WorkGroupManageSearch
                        searchText={workGroupReadService.searchText}
                        setSearchText={workGroupReadService.setSearchText}
                    />
                    <AddNewWorkGroup />
                </div>
                <WorkGroupManageTable
                    workGroups={workGroupReadService.filteredWorkGroups}
                />
            </div>
        </React.Fragment>
    );
};
export default WorkGroupTabPanel;
