import { AnchorTargetTypes } from "@rsm-hcd/javascript-core";
import Anchor from "atoms/anchors/anchor";
import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { HeadingPriority } from "atoms/constants/heading-priority";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import Heading from "atoms/typography/heading";
import OrganizationManifest from "models/interfaces/organization-manifest";
import CopyTextButton from "molecules/buttons/copy-text-button";
import { ModalCloseButtonStyle } from "molecules/constants/modal-close-button-style";
import { ModalTypes } from "molecules/constants/modal-types";
import Modal from "molecules/modals/modal";
import React from "react";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useBreakpoint from "utilities/hooks/use-breakpoint";
import { t } from "utilities/localization-utils";
import StringUtils from "utilities/string-utils";

const BASE_CSS_CLASS = "c-organization-support-modal";
interface OrganizationSupportModalProps {
    showModal: boolean;
    onHideModal: () => void;
    organization: OrganizationManifest;
}

const OrganizationSupportModal = ({
    showModal,
    onHideModal,
    organization,
}: OrganizationSupportModalProps) => {
    const isDesktop = useBreakpoint(Breakpoints.SmallTablet);

    return (
        <Modal
            cssClassName={`${BASE_CSS_CLASS}__modal`}
            closeDialog={onHideModal}
            isVisible={showModal}
            label={t("organizationAccountSupport")}
            closeButtonStyle={ModalCloseButtonStyle.InsideDialog}
            type={isDesktop ? ModalTypes.Base : ModalTypes.Bottom}>
            <header className={`${BASE_CSS_CLASS}__header`}>
                <Heading
                    cssClassName={`${BASE_CSS_CLASS}__heading`}
                    priority={HeadingPriority.Two}>
                    {t("organizationAccountSupport")}
                </Heading>
                <Button
                    accessibleText={t("closeItem", { item: t("dialog") })}
                    cssClassName={`${BASE_CSS_CLASS}__closeButton`}
                    onClick={onHideModal}
                    size={ButtonSizes.Small}
                    style={ButtonStyles.None}>
                    <Icon type={Icons.Close} size={IconSizes.Large} />
                </Button>
            </header>
            <section className={`${BASE_CSS_CLASS}__content`}>
                <dl>
                    <dt className={`${BASE_CSS_CLASS}__itemLabel`}>
                        {t("organization")}
                    </dt>
                    <dd className={`${BASE_CSS_CLASS}__item`}>
                        {organization.name}
                    </dd>
                    <dt className={`${BASE_CSS_CLASS}__itemLabel`}>
                        {t("contactPerson")}
                    </dt>
                    <dd className={`${BASE_CSS_CLASS}__item`}>
                        <CopyTextButton
                            text={organization.supportEmailAddress}
                        />
                    </dd>
                    {StringUtils.hasValue(organization.supportEmailAddress) && (
                        <Anchor
                            external
                            target={AnchorTargetTypes.Blank}
                            cssClassName="c-button -secondary"
                            to={`mailto:${organization.supportEmailAddress}`}>
                            {t("emailOrganizationSupportSpecialist")}
                        </Anchor>
                    )}
                </dl>
            </section>
        </Modal>
    );
};

export default OrganizationSupportModal;
