import { RecordUtils } from "@rsm-hcd/javascript-core";
import PublicationTypes from "constants/publication-types";
import { XmlChangeNotationConstants } from "constants/xml-change-notation-constants";
import { Record } from "immutable";
import Table from "models/interfaces/table";
import { ReactNode } from "react";
import XmlUtils from "utilities/xml-utils";
import AnnexRecord from "./annex-record";
import ArticleRecord from "./article-record";
import ChapterRecord from "./chapter-record";
import { PartRecord } from "internal";
import PublicationAnchorRecord from "./publication-anchor-record";
import PublicationRecord from "./publication-record";
import { PublicationSidebarNavItem } from "molecules/sidebar-chapter-navigation/publication-sidebar-nav-link";
import SectionRecord from "./section-record";
import StringUtils from "utilities/string-utils";
import { siteMap } from "internal-sitemap";
import InformationalNoteSection from "organisms/section-detail-components/informational-note-section";
import Bookmarkable from "models/interfaces/bookmarkable";

const defaultValues: Table = RecordUtils.auditableDefaultValuesFactory<Table>({
    annex: undefined,
    annexId: undefined,
    article: undefined,
    articleId: undefined,
    body: undefined,
    changes: undefined,
    chapter: undefined,
    chapterId: undefined,
    chapterNfpaLabel: undefined,
    displaySequence: undefined,
    externalId: "",
    label: undefined,
    nfpaLabel: undefined,
    part: undefined,
    partId: undefined,
    publication: undefined,
    publicationId: undefined,
    section: undefined,
    sectionId: undefined,
    title: undefined,
    titleAsPlainText: undefined,
});

export default class TableRecord
    extends Record(defaultValues)
    implements Table, Bookmarkable
{
    constructor(params?: Table) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.publication != null) {
            params.publication = RecordUtils.ensureRecord(
                params.publication,
                PublicationRecord
            );
        }

        if (params.article != null) {
            params.article = RecordUtils.ensureRecord(
                params.article,
                ArticleRecord
            );
        }

        if (params.annex != null) {
            params.annex = RecordUtils.ensureRecord(params.annex, AnnexRecord);
        }

        if (params.chapter != null) {
            params.chapter = RecordUtils.ensureRecord(
                params.chapter,
                ChapterRecord
            );
        }

        if (params.part != null) {
            params.part = RecordUtils.ensureRecord(params.part, PartRecord);
        }

        if (params.section != null) {
            params.section = RecordUtils.ensureRecord(
                params.section,
                SectionRecord
            );
        }

        super(params);
    }

    /**
     * Converts the XML body to a react node
     * @param debug
     * @returns React node converted from the XML
     */
    public getBody(debug?: boolean): ReactNode {
        // If we have no body, just return an empty string.
        if (this.body == null) {
            return "";
        }

        // Otherwise, converts the XML to React components using default and custom converters.
        const xmlConverters = {
            ...XmlUtils.defaultConverters,
            ...XmlUtils.publicationInlineConverters,
            ...XmlUtils.publicationTableConverters,
            note: XmlUtils.xmlConverter(InformationalNoteSection),
        };

        return XmlUtils.convert(this.body, xmlConverters, debug);
    }

    /**
     * Get the title in the format 'Table A.4.8.2.1(3) Occupant Evacuation Strategies'
     * or return just the title if the label is empty
     */
    public getDisplayTitle() {
        if (StringUtils.isEmpty(this.getLabel())) {
            return this.getNavItemTitle();
        }

        return `${this.getLabel()} ${this.getNavItemTitle()}`;
    }

    /**
     * Get the title of the section including the fully qualified Section Label.
     * @example 390.15(B) Raceways over 100mm (4 in.) Wide But Not over 200 mm (8 in.) Wide
     */
    public getFullyQualifiedDisplayTitle(): string {
        if (StringUtils.isEmpty(this.nfpaLabel)) {
            return this.getDisplayTitle();
        }

        // If the label is already in the title, strip it out
        const title = this.getTitleAsPlainText().replace(this.getLabel(), "");
        return `${this.nfpaLabel} ${title}`;
    }

    /**
     * Get the externalId preceded by '#'
     * @returns
     */
    public getHashLink(): string {
        return `#${this.externalId}`;
    }

    /**
     * Returns the label as a string (defaults to empty string if label is undefined/null)
     */
    public getLabel(): string {
        return this.hasLabel() ? this.label! : "";
    }

    /**
     * Get the title of the table to be displayed in the Metanav
     * if there is no title it will return 'Untitled Table'
     * the title will be trimmed to be 80 characters
     * @returns
     */
    public getNavItemTitle(): string {
        let title = this.getTitleInPlainText();

        // if the table does not have a title element in the XML
        if (StringUtils.isEmpty(title)) {
            title = "Untitled Table";
        }

        if (title.length > 80) {
            title = this.trimTitle();
        }

        return title;
    }

    /**
     * Get direct link to current table in Book View
     * @param publicationType
     * @param isAdminPreview
     * @returns string, url to table or empty string
     */
    public getRoute(
        publicationType?: PublicationTypes,
        isAdminPreview: boolean = false
    ): string {
        if (this.annexId == null && this.chapterId == null) {
            return this.getHashLink();
        }

        if (this.publicationId == null) {
            return siteMap.errors.notFound;
        }

        const anchorRecord = PublicationAnchorRecord.fromTableRecord(
            this,
            isAdminPreview,
            publicationType
        );

        const url = anchorRecord.getRoute();

        return url !== undefined ? url : "";
    }

    /**
     * Get the title as a react node converted from XML
     */
    public getTitle(debug?: boolean): ReactNode {
        if (this.title == null) {
            return "";
        }

        const xmlConverters = {
            title: XmlUtils.publicationInlineConverters.title,
        };

        return XmlUtils.convert(this.title, xmlConverters, debug);
    }

    /**
     * Returns the title stripped of XML.
     *
     * @returns
     */
    public getTitleAsPlainText(): string {
        if (StringUtils.isEmpty(this.titleAsPlainText)) {
            return "";
        }

        return StringUtils.translateDomainTerms(this.titleAsPlainText!);
    }

    /**
     * Get the title of the table translated based on the publication language
     * @returns
     */
    public getTitleInPlainText() {
        if (StringUtils.isEmpty(this.titleAsPlainText)) {
            return "";
        }

        return StringUtils.translateDomainTerms(
            this.titleAsPlainText,
            this.publication?.locale
        );
    }

    /**
     * Returns whether or not the record has a non-null, non-whitespace label value
     */
    public hasLabel(): boolean {
        return StringUtils.hasValue(this.label);
    }

    public isDeleted(): boolean {
        return this.changes === XmlChangeNotationConstants.DELETION;
    }

    /**
     * Creates a Metanav item from the table record
     * @param values
     * @param publicationType
     * @param isAdminPreview
     * @returns
     */
    public toNavItem(
        values?: Partial<PublicationSidebarNavItem>,
        publicationType?: PublicationTypes,
        isAdminPreview: boolean = false
    ): PublicationSidebarNavItem {
        const label = this.getLabel();

        const defaultNavItem: PublicationSidebarNavItem = {
            id: this.id!,
            label: `${label} `,
            route: this.getHashLink(),
            title: this.getNavItemTitle(),
            isDeleted: this.isDeleted(),
            dataTestDisplaySequence: this.displaySequence,
            singleLine: true,
            useRelativePath: true,
            searchForLinkOnPage: true,
        };

        return Object.assign(defaultNavItem, values);
    }

    /**
     *
     * @returns Shortened title to 80 chars with no trailing spaces
     */
    private trimTitle() {
        return `${this.getTitleInPlainText().slice(0, 80).trim()}...`;
    }
}
