import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import Paragraph from "atoms/typography/paragraph";
// import { List } from "immutable";
import UserBookmarkRecord from "models/view-models/user-bookmark-record";
import Tooltip from "molecules/tooltips/tooltip";
import React from "react";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import { useLocalization } from "utilities/hooks/use-localization";

const CSS_CLASS_NAME = "c-bookmark-slider__header";
export interface BookmarkSliderHeaderProps {
    bookmarks: Array<UserBookmarkRecord>;
    index: number;
    setIndex: React.Dispatch<React.SetStateAction<number>>;
    isOnFirstBookmark: boolean;
    isOnLastBookmark: boolean;
    handleAddBookmark: () => void;
    onClose: () => void;
    canAddBookmark: boolean;
}
const BookmarkSliderHeader: React.FC<BookmarkSliderHeaderProps> = (
    props: BookmarkSliderHeaderProps
) => {
    const {
        index,
        setIndex,
        isOnFirstBookmark,
        isOnLastBookmark,
        handleAddBookmark,
        bookmarks,
        onClose,
        canAddBookmark,
    } = props;
    const { t } = useLocalization();

    const handleNextClick = () => setIndex((index: number) => index + 1);
    const handlePreviousClick = () => setIndex((index: number) => index - 1);
    const { isOnline } = useNetworkInformation();

    const previousDisabledClass = isOnFirstBookmark ? "-disabled" : "";
    const nextDisabledClass = isOnLastBookmark ? "-disabled" : "";

    return (
        <div className={`${CSS_CLASS_NAME}`}>
            <div className={`${CSS_CLASS_NAME}__actions`}>
                <div className={`${CSS_CLASS_NAME}__actions__left`}>
                    {bookmarks.length > 1 && (
                        <>
                            <Paragraph>
                                {`${index + 1}/${bookmarks.length}`}
                            </Paragraph>
                            <div
                                className={`${CSS_CLASS_NAME}__actions__left__paging`}>
                                <Button
                                    accessibleText={t("previousItem", {
                                        item: t("bookmark"),
                                    })}
                                    cssClassName={previousDisabledClass}
                                    disabled={isOnFirstBookmark}
                                    onClick={handlePreviousClick}
                                    style={ButtonStyles.TertiaryAlt}>
                                    <Icon type={Icons.ChevronLeft} />
                                </Button>
                                <Button
                                    accessibleText={t("nextItem", {
                                        item: t("bookmark"),
                                    })}
                                    cssClassName={nextDisabledClass}
                                    disabled={isOnLastBookmark}
                                    onClick={handleNextClick}
                                    style={ButtonStyles.TertiaryAlt}>
                                    <Icon type={Icons.ChevronRight} />
                                </Button>
                            </div>
                        </>
                    )}
                    {canAddBookmark && (
                        <Tooltip
                            content={t("offline-fallback-description")}
                            disabled={isOnline}>
                            <Button
                                accessibleText={t("addBookmarkButton")}
                                ariaDisabled={!isOnline}
                                dataTestId={"bookmark"}
                                cssClassName="-view-bookmarks"
                                onClick={
                                    isOnline
                                        ? () => {
                                              handleAddBookmark();
                                          }
                                        : () => {}
                                }
                                size={ButtonSizes.XSmall}
                                style={ButtonStyles.Secondary}>
                                <Icon
                                    type={Icons.Bookmark}
                                    size={IconSizes.Base}
                                />
                                +
                            </Button>
                        </Tooltip>
                    )}
                </div>
                <div className={`${CSS_CLASS_NAME}__actions__right`}>
                    <Button
                        accessibleText={t("closeItem", {
                            item: t("bookmark"),
                        })}
                        onClick={onClose}
                        size={ButtonSizes.Small}
                        style={ButtonStyles.TertiaryAlt}>
                        <Icon size={IconSizes.Base} type={Icons.Close} />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default BookmarkSliderHeader;
