import { RecordUtils } from "@rsm-hcd/javascript-core";
import { Record } from "immutable";
import type OfflineBook from "models/interfaces/offline/offline-book";
import BookRecord from "models/view-models/book-record";
import OfflineDeviceRecord from "models/view-models/offline/offline-device-record";
import OfflineBookSyncStatusRecord from "models/view-models/offline/offline-book-sync-status-record";
import moment from "moment";
import StringUtils from "utilities/string-utils";
import { OfflineBookDownloadStatus } from "molecules/enums/offline-book-download-status";

const defaultValues: OfflineBook =
    RecordUtils.auditableDefaultValuesFactory<OfflineBook>({
        book: undefined,
        bookId: 0,
        includeBookmarks: false,
        includeEnhancedContent: false,
        includeExternalReferences: false,
        offlineDevice: undefined,
        offlineDeviceId: 0,
        syncedOn: undefined,
        syncStatus: undefined,
    });

export default class OfflineBookRecord
    extends Record(defaultValues)
    implements OfflineBook
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: OfflineBook) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.book != null) {
            params.book = RecordUtils.ensureRecord(params.book, BookRecord);
        }

        if (params.offlineDevice != null) {
            params.offlineDevice = RecordUtils.ensureRecord(
                params.offlineDevice,
                OfflineDeviceRecord
            );
        }

        if (params.syncStatus != null) {
            params.syncStatus = RecordUtils.ensureRecord(
                params.syncStatus,
                OfflineBookSyncStatusRecord
            );
        }

        super(params);
    }
    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Convenience method to return the downloaded state
     *
     */
    public getDownloadStatus(): OfflineBookDownloadStatus {
        if (this.isUpdateAvailable()) {
            return OfflineBookDownloadStatus.UpdateAvailable;
        }

        if (this._hasValidSyncedOnDate()) {
            return OfflineBookDownloadStatus.Downloaded;
        }

        return OfflineBookDownloadStatus.NotDownloaded;
    }

    /**
     * Compares the SyncedOn and LastUpdateOn dates to determine if there is an update available
     */
    public isUpdateAvailable(): boolean {
        if (
            StringUtils.isEmpty(this.syncedOn) ||
            StringUtils.isEmpty(this.syncStatus?.lastUpdatedOn)
        ) {
            return false;
        }

        const createdOnDate = moment(this.createdOn);
        const syncedOnDate = moment(this.syncedOn);
        const lastUpdateDate = moment(this.syncStatus?.lastUpdatedOn);

        return (
            syncedOnDate.isAfter(createdOnDate) &&
            syncedOnDate.isBefore(lastUpdateDate)
        );
    }

    public with(values: Partial<OfflineBook>): OfflineBookRecord {
        return new OfflineBookRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods

    // -----------------------------------------------------------------------------------------
    // #region Private Methods
    // -----------------------------------------------------------------------------------------

    private _hasValidSyncedOnDate(): boolean {
        return (
            StringUtils.hasValue(this.syncedOn) &&
            moment(this.createdOn).isBefore(moment(this.syncedOn))
        );
    }

    // #endregion Private Methods
}
