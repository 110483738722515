import { XmlConvertedComponentProps } from "interfaces/forms/xml-converted-component-props";
import { LabelConverterId } from "molecules/labels/label-converter";
import * as React from "react";
import XmlUtils from "utilities/xml-utils";

export interface ListItemProps extends XmlConvertedComponentProps {
    label?: string;
}

const ListItem: React.FunctionComponent<ListItemProps> = (
    props: ListItemProps
) => {
    const changedModifier =
        props.diffchanged != null ? ` c-code-change -${props.diffchanged}` : "";

    const cssClassName = "c-publication-list__item";

    const children = React.Children.toArray(props.children ?? []);
    let labelElement = XmlUtils.findElementByConverterId(
        LabelConverterId,
        children as JSX.Element[]
    );
    const preprocessedChildren = XmlUtils.filterChildrenByConverterId(
        LabelConverterId,
        children as JSX.Element[]
    );

    if (labelElement == null) {
        labelElement = (
            <span className={`${cssClassName}__label`}>
                {`${props.label} `}
            </span>
        );
    }

    return (
        <li id={props.externalId} className={cssClassName + changedModifier}>
            <React.Fragment>{labelElement}</React.Fragment>
            <div className={`${cssClassName}__content`}>
                {preprocessedChildren}
            </div>
        </li>
    );
};

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default ListItem;

// #endregion Exports
