import SectionTableOfContentsRecord from "models/view-models/table-of-contents/section-table-of-contents-record";
import NavItem from "molecules/sidebar-chapter-navigation/nav-item";
import React from "react";
import { SectionParentType } from "utilities/enumerations/section-parent-type";
import useSectionScrollSpyHandlers from "utilities/hooks/scroll-spy/use-section-scroll-spy-handlers";
import StringUtils from "utilities/string-utils";

interface ExpandableMetaNavSectionsProps {
    sections?: SectionTableOfContentsRecord[];
    isAdminPreview: boolean;
    parentType: SectionParentType;
    routeParams: any;
    isCurrentActivePanel: boolean;
}

const ExpandableMetaNavSections: React.FC<ExpandableMetaNavSectionsProps> = (
    props: ExpandableMetaNavSectionsProps
) => {
    const {
        sections = [],
        isAdminPreview,
        isCurrentActivePanel,
        parentType,
        routeParams,
    } = props;

    const { handleClick } = useSectionScrollSpyHandlers();

    return (
        <>
            {sections
                .filter(
                    (section) =>
                        !section?.isDeleted &&
                        StringUtils.hasValue(section.getDisplayTitle())
                )
                .sort(
                    (a, b) =>
                        (a.displaySequence ?? 0) - (b.displaySequence ?? 0)
                )
                .map((section) => {
                    const link = section.getRoute(
                        {
                            ...routeParams,
                            externalId: section.externalId,
                        },
                        isAdminPreview,
                        parentType
                    );
                    return (
                        <div
                            onClick={() => handleClick(section.id ?? 0)}
                            key={section.id}>
                            <NavItem
                                key={section.id}
                                cssModifierClassName="-expandableToC"
                                hasBookmark={section.hasBookmark}
                                externalId={section.externalId}
                                id={section.id}
                                replace={isCurrentActivePanel}
                                label={""}
                                route={link}
                                shouldScroll={false}
                                title={section.title ?? ""}
                            />
                        </div>
                    );
                })}
        </>
    );
};

export default ExpandableMetaNavSections;
