import { RecordUtils } from "@rsm-hcd/javascript-core";
import PublicationTypes from "constants/publication-types";
import { Record } from "immutable";
import ChangeSummary from "models/interfaces/change-summary";
import DateUtils from "utilities/date-utils";
import PublicationComponentType from "utilities/enumerations/publication-component-type";
import PublicationAnchorRecord from "./publication-anchor-record";

const defaultValues: ChangeSummary = RecordUtils.auditableDefaultValuesFactory({
    code: "",
    edition: "",
    externalId: "",
    bodyDraft: "",
    body: "",
    title: "",
    updatedByName: "",
    deletedDraftById: undefined,
    deletedDraftOn: undefined,
    publishedById: undefined,
    publishedOn: undefined,
    adminNote: "",
    type: PublicationComponentType.Section,
    typeId: undefined,
    chapterId: undefined,
    chapterNfpaLabel: "",
    articleId: undefined,
    articleNfpaLabel: "",
    annexId: undefined,
    annexNfpaLabel: "",
    partId: undefined,
    partNfpaLabel: "",
    sectionId: undefined,
    sectionNfpaLabel: "",
    tableId: undefined,
    tableNfpaLabel: "",
    publishStatus: undefined,
});

class ChangeSummaryRecord
    extends Record(defaultValues)
    implements ChangeSummary
{
    constructor(params?: ChangeSummary) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    public getDisplayStatusAndDate(): string {
        return `Last Updated on ${DateUtils.formatLastEditedDate(this)}`;
    }

    public isPersisted(): boolean {
        return (this.id ?? 0) > 0;
    }

    public with(values: Partial<ChangeSummary>): ChangeSummaryRecord {
        return new ChangeSummaryRecord(Object.assign(this.toJS(), values));
    }

    public getType(): string {
        switch (this.type) {
            case PublicationComponentType.Chapter:
                return "chapter";
            case PublicationComponentType.Annex:
                return "annex";
            case PublicationComponentType.Section:
                return "section";
            case PublicationComponentType.Table:
                return "table";
            case PublicationComponentType.Article:
                return "article";
            case PublicationComponentType.Part:
                return "part";
            default:
                return "";
        }
    }
    // TODO: Update once backend work finished
    public getBookviewRoute(): string {
        const changeSummaryAsPublicationAnchorRecord =
            new PublicationAnchorRecord().with({
                ...this.toJS(),
                publicationCode: this.code,
                publicationEdition: this.edition,
                publicationType: this.code?.includes("70")
                    ? PublicationTypes.NEC
                    : PublicationTypes.NFC,
            });
        const route = changeSummaryAsPublicationAnchorRecord.getRoute() || "";
        return route;
    }
}

export default ChangeSummaryRecord;
