import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import React from "react";
import { useLocalization } from "utilities/hooks/use-localization";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface BookmarkSettingsFooterProps {
    cssClassName: string;
    isMobile: boolean;
    loading: boolean;
    onCancelClick: () => void;
    onConfirm: () => void;
    setShowDeleteConfirmationModal: (value: boolean) => void;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const BookmarkSettingsFooter: React.FC<BookmarkSettingsFooterProps> = (
    props
) => {
    const {
        cssClassName,
        isMobile,
        loading,
        onCancelClick,
        onConfirm,
        setShowDeleteConfirmationModal,
    } = props;

    const buttonSize = !isMobile ? ButtonSizes.Small : undefined;
    const { t } = useLocalization();
    return (
        <div className={cssClassName}>
            <div className={`${cssClassName}__actions`}>
                <div>
                    {isMobile && (
                        <Button
                            cssClassName={`${cssClassName}__actions__delete-button`}
                            onClick={() => setShowDeleteConfirmationModal(true)}
                            style={ButtonStyles.Tertiary}
                            size={ButtonSizes.Small}>
                            <Icon
                                type={Icons.Trashcan}
                                size={IconSizes.Large}
                            />
                        </Button>
                    )}
                </div>
                <div>
                    <Button
                        dataTestId="bookmark-settings-cancel"
                        disabled={loading}
                        onClick={onCancelClick}
                        size={buttonSize}
                        style={ButtonStyles.Secondary}>
                        {t("cancel")}
                    </Button>
                    <Button
                        dataTestId="bookmark-settings-save"
                        disabled={loading}
                        onClick={onConfirm}
                        size={buttonSize}
                        style={ButtonStyles.Primary}>
                        {t("save")}
                    </Button>
                </div>
            </div>
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default BookmarkSettingsFooter;

// #endregion Export
