import { PagedQuery } from "@rsm-hcd/javascript-core";
import PublishStatus from "models/enumerations/publish-status";
import PublicationRecord from "models/view-models/publication-record";
import SearchQuery from "utilities/interfaces/search-query";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";
import PublicationContentFormatType from "utilities/enumerations/publication-content-format-type";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AdminPublicationQueryParams extends PagedQuery, SearchQuery {
    code?: string;
    edition?: string;
    groupByPrinting?: boolean;
    hasPublishedEnhancedContent?: boolean;
    publishStatus?: PublishStatus;
    publicationContentFormatType?: PublicationContentFormatType;
}

export interface AdminPublicationPathParams {
    id?: number;
}

export interface AdminPublicationQueryByPublicationIdsParams
    extends PagedQuery,
        SearchQuery {
    publicationIds: number[];
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = "admin/publications";
const resourceEndpoint = `${baseEndpoint}/:id`;
const resourceEndpointList = `${baseEndpoint}/ids`;
const resourceType = PublicationRecord;
const key = "admin-publications";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const AdminPublicationService = {
    get: ServiceFactory.get<PublicationRecord, AdminPublicationPathParams>(
        resourceType,
        resourceEndpoint
    ),

    list: ServiceFactory.list<PublicationRecord, AdminPublicationQueryParams>(
        resourceType,
        baseEndpoint
    ),

    create: ServiceFactory.create<PublicationRecord>(
        resourceType,
        baseEndpoint
    ),

    update: ServiceFactory.update<
        PublicationRecord,
        AdminPublicationPathParams
    >(resourceType, resourceEndpoint),

    useGet: ServiceHookFactory.useGet<
        PublicationRecord,
        AdminPublicationPathParams
    >(resourceType, resourceEndpoint),

    useCreate: ServiceHookFactory.useCreate<PublicationRecord>(
        PublicationRecord,
        baseEndpoint
    ),
    useList: ServiceHookFactory.useList<
        PublicationRecord,
        AdminPublicationQueryParams
    >(resourceType, baseEndpoint),

    useBatchedListByPublicationId: ServiceHookFactory.useBatchedList<
        PublicationRecord,
        AdminPublicationQueryByPublicationIdsParams
    >(resourceType, resourceEndpointList),

    useUpdate: ServiceHookFactory.useUpdate<
        PublicationRecord,
        AdminPublicationPathParams
    >(resourceType, resourceEndpoint),

    useListQuery: QueryHookFactory.useListQuery<
        PublicationRecord,
        AdminPublicationQueryParams
    >(key, resourceType, baseEndpoint),

    useGetQuery: QueryHookFactory.useGetQuery<
        PublicationRecord,
        AdminPublicationPathParams
    >(key, resourceType, resourceEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default AdminPublicationService;

// #endregion Exports
