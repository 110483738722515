import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import Paragraph from "atoms/typography/paragraph";
import React from "react";

interface AccountDisplayInformationProps {
    heading: string;
    information: string | JSX.Element;
}

const BASE_CLASS = "c-account-dashboard__information";

const AccountDisplayInformation = ({
    heading,
    information,
}: AccountDisplayInformationProps): React.ReactNode => {
    return (
        <div className={`${BASE_CLASS}-display`}>
            <Heading priority={HeadingPriority.Six}>{heading}</Heading>
            <Paragraph>{information}</Paragraph>
        </div>
    );
};

export default AccountDisplayInformation;
