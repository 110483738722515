import { AnchorTargetTypes } from "@rsm-hcd/javascript-core";
import Anchor from "atoms/anchors/anchor";
import FileLink from "atoms/anchors/file-link";
import EnhancedContentResourceRecord from "models/view-models/enhanced-content-resource-record";
import FileRecord from "models/view-models/file-record";
import SolutionResourceRecord from "models/view-models/situational-navigation/solutions/solution-resource-record";
import Tooltip from "molecules/tooltips/tooltip";
import ResourceCardContents from "organisms/situational-navigation/resources/resource-card-contents";
import React, { ReactElement } from "react";
import StringUtils from "utilities/string-utils";

// -----------------------------------------------------------------------------------------
// #region Enums
// -----------------------------------------------------------------------------------------

export enum ResourceCardStyle {
    Card,
    Tile,
}

// #endregion Enums

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ResourceCardProps {
    accessibleText: string;
    cssClassName?: string;
    disableTooltip?: boolean;
    file?: FileRecord;
    resource: SolutionResourceRecord | EnhancedContentResourceRecord;
    style?: ResourceCardStyle;
    draftMode?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ResourceCard: React.FC<ResourceCardProps> = (
    props: ResourceCardProps
) => {
    const style = props.style ?? ResourceCardStyle.Card;
    const CSS_CARD_CLASS = "c-resource-card";
    const CSS_TILE_CLASS = "c-resource-tile";
    const CSS_BASE_CLASS =
        style === ResourceCardStyle.Card ? CSS_CARD_CLASS : CSS_TILE_CLASS;

    const classNames: string[] = [CSS_BASE_CLASS];
    const disableTooltip = props.disableTooltip ?? false;
    const useDraftMode = props.draftMode ?? false;

    if (StringUtils.hasValue(props.cssClassName)) {
        classNames.push(props.cssClassName!);
    }

    /**
     * Utility component to either render or not render a tooltip
     * based on resource type.
     * @param innerProps props passed to CardWrapper component
     */
    const CardWrapper = (innerProps: { children: ReactElement }) => {
        // for enhanced content resources, show description in tooltip
        const useTooltip =
            !disableTooltip &&
            props.resource instanceof EnhancedContentResourceRecord &&
            ((useDraftMode &&
                StringUtils.hasValue(props.resource.descriptionDraft)) ||
                StringUtils.hasValue(props.resource.description));

        if (useTooltip) {
            return (
                <Tooltip
                    content={
                        useDraftMode
                            ? props.resource.descriptionDraft
                            : props.resource.description
                    }>
                    <div className={classNames.join(" ")}>
                        {innerProps.children}
                    </div>
                </Tooltip>
            );
        }

        return (
            <div className={classNames.join(" ")}>{innerProps.children}</div>
        );
    };

    if (props.file != null) {
        return (
            <CardWrapper>
                <FileLink
                    accessibleText={props.accessibleText}
                    file={props.file}>
                    <ResourceCardContents
                        absoluteFileUrl={props.file.presignedUrl}
                        cssBaseClass={CSS_BASE_CLASS}
                        draftMode={useDraftMode}
                        file={props.file}
                        style={style}
                        resource={props.resource}
                    />
                </FileLink>
            </CardWrapper>
        );
    }

    return (
        <CardWrapper>
            <Anchor
                ariaLabel={props.accessibleText}
                external={true}
                target={AnchorTargetTypes.Blank}
                to={props.resource.getAbsoluteUrl(useDraftMode)}>
                <ResourceCardContents
                    cssBaseClass={CSS_BASE_CLASS}
                    draftMode={useDraftMode}
                    style={style}
                    resource={props.resource}
                />
            </Anchor>
        </CardWrapper>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default ResourceCard;

// #endregion Exports
