import { Record } from "immutable";
import { CancelSubscriptionFormSubmission } from "models/interfaces/cancel-subscription-form-submission";
import { RecordUtils } from "@rsm-hcd/javascript-core";

const defaultValues: CancelSubscriptionFormSubmission =
    RecordUtils.defaultValuesFactory({
        reason: "",
        description: "",
    });

export default class CancelSubscriptionFormSubmissonRecord
    extends Record(defaultValues)
    implements CancelSubscriptionFormSubmission
{
    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: CancelSubscriptionFormSubmission) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<CancelSubscriptionFormSubmission>} values
     */
    public with(
        values: Partial<CancelSubscriptionFormSubmission>
    ): CancelSubscriptionFormSubmissonRecord {
        return new CancelSubscriptionFormSubmissonRecord(
            Object.assign(this.toJS(), values)
        );
    }

    // #endregion Public Methods
}
