import SectionRecord from "models/view-models/section-record";
import { PagedQuery } from "@rsm-hcd/javascript-core";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AdminSectionQueryParams extends PagedQuery {
    excludeSectionIds?: Array<number>;
    publicationId?: number;
    searchText?: string;
    solutionId?: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = "admin/situational-navigation/sections";
const resourceType = SectionRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const AdminSectionService = {
    list: ServiceFactory.list<SectionRecord, AdminSectionQueryParams>(
        resourceType,
        baseEndpoint
    ),
    useList: ServiceHookFactory.useList<SectionRecord, AdminSectionQueryParams>(
        resourceType,
        baseEndpoint
    ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default AdminSectionService;

// #endregion Exports
