import SolutionResourceRecord from "models/view-models/situational-navigation/solutions/solution-resource-record";
import { PagedQuery } from "@rsm-hcd/javascript-core";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface SolutionResourcePathParams {
    solutionId: number;
}

export interface SolutionResourceGetPathParams
    extends SolutionResourcePathParams {
    id: number;
}

export interface SolutionResourceQueryParams extends PagedQuery {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const key = "situational-navigation-solutions-resources";
const baseEndpoint = "situational-navigation/solutions/:solutionId/resources";
const resourceEndpoint = `${baseEndpoint}/:id`;
const resourceType = SolutionResourceRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const SolutionResourceService = {
    get: ServiceFactory.get<
        SolutionResourceRecord,
        SolutionResourceGetPathParams
    >(resourceType, resourceEndpoint),
    useGet: ServiceHookFactory.useGet<
        SolutionResourceRecord,
        SolutionResourceGetPathParams
    >(resourceType, resourceEndpoint),
    useGetQuery: QueryHookFactory.useGetQuery<
        SolutionResourceRecord,
        SolutionResourceGetPathParams
    >(key, resourceType, resourceEndpoint),
    list: ServiceFactory.nestedList<
        SolutionResourceRecord,
        SolutionResourcePathParams,
        SolutionResourceQueryParams
    >(resourceType, baseEndpoint),
    useList: ServiceHookFactory.useNestedList<
        SolutionResourceRecord,
        SolutionResourcePathParams,
        SolutionResourceQueryParams
    >(resourceType, baseEndpoint),
    useListQuery: QueryHookFactory.useNestedListQuery<
        SolutionResourceRecord,
        SolutionResourcePathParams,
        SolutionResourceQueryParams
    >(key, resourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default SolutionResourceService;

// #endregion Exports
