import { RecordUtils } from "@rsm-hcd/javascript-core";
import SupportUserSubscription from "../interfaces/support-user-subscription";
import { Record } from "immutable";
import DateUtils from "utilities/date-utils";
import RoleType from "utilities/enumerations/role-type";
import moment from "moment";

const defaultValues: SupportUserSubscription =
    RecordUtils.defaultValuesFactory<SupportUserSubscription>({
        id: undefined,
        eulaAccepted: false,
        expiresOn: undefined,
        description: undefined,
        externalProductId: undefined,
        isAutoRenewing: false,
        roleType: undefined,
        sku: undefined,
        teamId: undefined,
        teamName: undefined,
    });

export default class SupportUserSubscriptionRecord
    extends Record(defaultValues)
    implements SupportUserSubscription
{
    constructor(params?: SupportUserSubscription) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    formatExpirationDate(format?: string) {
        if (format == null) return DateUtils.formatIsoDate(this.expiresOn);

        return DateUtils.formatCustomDate(this.expiresOn, format);
    }

    isTeamOrEnterprise() {
        return (
            this.roleType === RoleType.TEAM ||
            this.roleType === RoleType.ENTERPRISE
        );
    }

    isExpired() {
        return moment().isAfter(this.expiresOn);
    }

    public with(
        values: Partial<SupportUserSubscription>
    ): SupportUserSubscriptionRecord {
        return new SupportUserSubscriptionRecord(
            Object.assign(this.toJS(), values)
        );
    }
}
