import { Record } from "immutable";
import PublishStatus from "models/enumerations/publish-status";
import type Situation from "models/interfaces/situational-navigation/situations/situation";
import FileRecord from "models/view-models/file-record";
import PublishableUtils, {
    PublishableRecord,
} from "utilities/publishable-utils";
import { RecordUtils } from "@rsm-hcd/javascript-core";
import { siteMap } from "internal-sitemap";
import { RouteUtils } from "utilities/route-utils";

const defaultValues: Situation =
    RecordUtils.auditableDefaultValuesFactory<Situation>({
        body: "",
        bodyDraft: "",
        deletedDraftById: undefined,
        deletedDraftOn: undefined,
        hotSpotImageFileId: undefined,
        hotSpotImageFile: undefined,
        hotSpotImageFileDraftId: undefined,
        hotSpotImageFileDraft: undefined,
        publishedById: undefined,
        publishedOn: undefined,
        subtitle: "",
        subtitleDraft: "",
        title: "",
        titleDraft: "",
    });

export default class SituationRecord
    extends Record(defaultValues)
    implements Situation, PublishableRecord
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Situation) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }
        if (params.hotSpotImageFile != null) {
            params.hotSpotImageFile = RecordUtils.ensureRecord(
                params.hotSpotImageFile,
                FileRecord
            );
        }

        if (params.hotSpotImageFileDraft != null) {
            params.hotSpotImageFileDraft = RecordUtils.ensureRecord(
                params.hotSpotImageFileDraft,
                FileRecord
            );
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Returns the route to access non-admin situation page
     */
    public getRoute(): string {
        return RouteUtils.getUrl(siteMap.situationalNavigation.situations, {
            id: this.id,
        });
    }

    public hasUnpublishedChanges(): boolean {
        return PublishableUtils.hasUnpublishedChanges(this);
    }

    public getPublishStatus(): PublishStatus {
        return PublishableUtils.getPublishStatus(this);
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<Situation>} values
     */
    public with(values: Partial<Situation>): SituationRecord {
        return new SituationRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}
