import { RecordUtils } from "@rsm-hcd/javascript-core";
import { Record } from "immutable";
import PublishStatus from "models/enumerations/publish-status";
import type { EnhancedContent } from "models/interfaces/enhanced-content";
import AnnexRecord from "models/view-models/annex-record";
import ArticleRecord from "models/view-models/article-record";
import ChapterRecord from "models/view-models/chapter-record";
import EnhancedContentComponentRecord from "models/view-models/enhanced-content-component-record";
import EnhancedContentResourceRecord from "models/view-models/enhanced-content-resource-record";
import PublicationRecord from "models/view-models/publication-record";
import SectionRecord from "models/view-models/section-record";
import UserRecord from "models/view-models/user-record";
import { CollectionUtils } from "utilities/collection-utils";
import DateUtils from "utilities/date-utils";
import PublicationComponentType from "utilities/enumerations/publication-component-type";
import PublishableUtils, {
    PublishableRecord,
} from "utilities/publishable-utils";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

export const EnhancedContentStatusDescriptions = {
    PublishedWithChanges: "Unpublished Changes Saved",
    Published: "Published",
    Unpublished: "Changes Saved",
} as const;

// #endregion Constants

const defaultValues: EnhancedContent =
    RecordUtils.auditableDefaultValuesFactory({
        adminNote: undefined,
        annex: undefined,
        article: undefined,
        code: "",
        body: undefined,
        bodyDraft: undefined,
        chapter: undefined,
        component: undefined,
        deletedDraftById: undefined,
        deletedDraftOn: undefined,
        edition: "",
        externalId: "",
        publication: undefined,
        publishedById: undefined,
        publishedOn: undefined,
        resources: undefined,
        section: undefined,
        title: "",
        type: PublicationComponentType.Section,
        createdBy: undefined,
        updatedBy: undefined,
    });

export default class EnhancedContentRecord
    extends Record(defaultValues)
    implements EnhancedContent, PublishableRecord
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: EnhancedContent) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.annex != null) {
            params.annex = RecordUtils.ensureRecord(params.annex, AnnexRecord);
        }

        if (params.article != null) {
            params.article = RecordUtils.ensureRecord(
                params.article,
                ArticleRecord
            );
        }

        if (params.chapter != null) {
            params.chapter = RecordUtils.ensureRecord(
                params.chapter,
                ChapterRecord
            );
        }

        if (params.component != null) {
            params.component = RecordUtils.ensureRecord(
                params.component,
                EnhancedContentComponentRecord
            );
        }

        if (params.publication != null) {
            params.publication = RecordUtils.ensureRecord(
                params.publication,
                PublicationRecord
            );
        }

        if (params.section != null) {
            params.section = RecordUtils.ensureRecord(
                params.section,
                SectionRecord
            );
        }

        if (params.createdBy != null) {
            params.createdBy = RecordUtils.ensureRecord(
                params.createdBy,
                UserRecord
            );
        }

        if (params.updatedBy != null) {
            params.updatedBy = RecordUtils.ensureRecord(
                params.updatedBy,
                UserRecord
            );
        }

        if (params.resources != null) {
            params.resources = params.resources.map((resource) =>
                RecordUtils.ensureRecord(
                    resource,
                    EnhancedContentResourceRecord
                )
            );
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region PublishableRecord Implementation
    // -----------------------------------------------------------------------------------------

    hasUnpublishedChanges(): boolean {
        // this can be updated if we extend the Publication publishing process to include
        // fields, rather than just the entire record wholesale.
        return PublishableUtils.hasUnpublishedChanges(this);
    }

    getPublishStatus(): PublishStatus {
        return PublishableUtils.getPublishStatus(this);
    }

    // #endregion PublishableRecord Implementation

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Convenience method to return the body content based on if the user has admin access or not.
     * @param hasAdminAccess
     */
    public getBodyContentBasedOnRole(hasAdminAccess: boolean): string {
        if (hasAdminAccess) {
            return this.bodyDraft ?? this.body ?? "";
        }

        return this.body ?? "";
    }

    /**
     * Convience method to return content title
     *
     * @return {*}  {string}
     */
    public getContentName(): string {
        const publicationComponent = this.getPublicationComponent();
        if (publicationComponent == null) {
            return this.title ?? "";
        }

        // for annexes, include the annex number
        if (RecordUtils.isRecord(publicationComponent, AnnexRecord)) {
            return publicationComponent.getDisplayTitleWithNumber();
        }

        // for sections, show fully qualified section number
        if (RecordUtils.isRecord(publicationComponent, SectionRecord)) {
            return publicationComponent.getFullyQualifiedDisplayTitle();
        }

        return publicationComponent.getDisplayTitle();
    }

    /**
     * Convenience method to return the publication edition and code for an enhanced content record
     *
     * @return {*}  {string}
     */
    public getDisplayCodeAndEdition(): string {
        return `${this.code} (${this.edition})`;
    }

    /**
     * Builds a string of breadcrumbs based on the related entities that are attached, such as:
     *
     * NFPA 70 — National Electrical Code (2020) / Chapter 4 — Equipment for General Use / Article 425 Fixed Resistance and Electrode Industrial Process Heating Equipment
     */
    public getBreadcrumbText(): string {
        const breadcrumbs: string[] = [];
        if (this.publication?.getDisplayTitle()) {
            breadcrumbs.push(this.publication!.getDisplayTitle());
        }

        if (this.chapter?.getDisplayTitle()) {
            breadcrumbs.push(this.chapter!.getDisplayTitle());
        }

        if (this.annex?.getDisplayTitleWithNumber()) {
            breadcrumbs.push(this.annex!.getDisplayTitleWithNumber());
        }

        if (this.article?.getDisplayTitle()) {
            breadcrumbs.push(this.article!.getDisplayTitle());
        }

        if (CollectionUtils.hasValues(breadcrumbs)) {
            return breadcrumbs.join(" / ");
        }

        return "";
    }

    /**
     * Convenience method to display the current enhanced content status
     *
     * @return {*}  {string}
     */
    public getDisplayStatus(): string {
        if (this.hasUnpublishedChanges()) {
            return EnhancedContentStatusDescriptions.PublishedWithChanges;
        }

        if (this.getPublishStatus() === PublishStatus.Published) {
            return EnhancedContentStatusDescriptions.Published;
        }

        return EnhancedContentStatusDescriptions.Unpublished;
    }

    /**
     * Convenience method to display the current enhanced content status and last updated date
     *
     * @return {*}  {string}
     */
    public getDisplayStatusAndDate(): string {
        return `${this.getDisplayStatus()} on ${DateUtils.formatLastEditedDate(
            this
        )}`;
    }

    public getHtmlId(): string {
        return `${this.id}-${this.externalId}`;
    }

    /**
     * Convenience method to the return the text for the last updated date text along with the user
     * Ex: on YYYY/MM/D at 8:00 AM by User
     */
    public getLastUpdatedText(): string {
        const message = `on ${DateUtils.formatLastEditedDate(this)}`;
        const updatedBy = this.updatedBy ?? this.createdBy;

        if (updatedBy == null) {
            return message;
        }

        return `${message} by ${updatedBy.getFirstAndLastName()}`;
    }

    public getPublicationComponent():
        | AnnexRecord
        | ArticleRecord
        | ChapterRecord
        | SectionRecord
        | undefined {
        switch (this.type) {
            case PublicationComponentType.Annex:
                return this.annex;
            case PublicationComponentType.Article:
                return this.article;
            case PublicationComponentType.Chapter:
                return this.chapter;
            case PublicationComponentType.Section:
                return this.section;
            default:
                return undefined;
        }
    }

    /**
     * Convenience method to return the id of the user who last updated the record (or created it,
     * if it has not yet been updated)
     *
     */
    public getUpdatedOrCreatedById(): number | undefined {
        return this.updatedById ?? this.createdById;
    }

    /**
     * Convenience method to display the user that last updated the record
     *
     * @return {*}  {(string | undefined)}
     */
    public getUpdatedByText(): string | undefined {
        const updatedByName = this.updatedBy?.getFirstAndLastName();

        if (updatedByName == null) {
            return;
        }

        return `by ${updatedByName}`;
    }

    /**
     * Convenience method to the return the route to the related to this enhanced content record
     *
     * @return {*}  {string}
     */
    public getRoute(isAdminPreview: boolean = false): string {
        const includeHash = false;
        const hash = `#${this.getHtmlId()}`;
        if (this.type === PublicationComponentType.Annex) {
            return (
                (this.annex?.getRoute(isAdminPreview, includeHash) ?? "") + hash
            );
        }

        if (this.type === PublicationComponentType.Chapter) {
            return (
                (this.chapter?.getRoute(isAdminPreview, includeHash) ?? "") +
                hash
            );
        }

        if (this.type === PublicationComponentType.Article) {
            return (
                (this.article?.getRoute(isAdminPreview, includeHash) ?? "") +
                hash
            );
        }

        if (this.type === PublicationComponentType.Section) {
            return (
                (this.section?.getRoute(
                    this.publication?.type,
                    isAdminPreview,
                    includeHash
                ) ?? "") + hash
            );
        }

        return "";
    }

    /**
     * Convenience method to check if this record is new or a persisted entity
     */
    public isPersisted(): boolean {
        return (this.id ?? 0) > 0;
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<EnhancedContent>} values
     */
    public with(values: Partial<EnhancedContent>): EnhancedContentRecord {
        return new EnhancedContentRecord(Object.assign(this.toJS(), values));
    }

    /**
     * Given annexes, sets annex navigation property.
     *
     * Note: Requires that the record already has a `component` property set.
     *
     * @param {Array<AnnexRecord>} annexes
     */
    public withAnnex(annexes: Array<AnnexRecord>): EnhancedContentRecord {
        const annex = annexes.find((e) => e.id === this.component?.annexId);

        return this.with({ annex: annex });
    }

    /**
     *
     * Given articles, sets article navigation property.
     *
     * Note: Requires that the record already has a `component` property set.
     *
     * @param {Array<ArticleRecord>} articles
     */
    public withArticle(articles: Array<ArticleRecord>): EnhancedContentRecord {
        const article = articles.find(
            (e) => e.id === this.component?.articleId
        );

        return this.with({ article: article });
    }

    /**
     *
     * Given chapters, sets chapter navigation property.
     *
     * Note: Requires that the record already has a `component` property set.
     *
     * @param {Array<ChapterRecord>} chapters
     */
    public withChapter(chapters: Array<ChapterRecord>): EnhancedContentRecord {
        const chapter = chapters.find(
            (e) => e.id === this.component?.chapterId
        );

        return this.with({ chapter: chapter });
    }

    /**
     *
     * Given enhanced content components, sets component navigation property.
     *
     * @param {Array<EnhancedContentComponentRecord>} components
     */
    public withComponent(
        components: Array<EnhancedContentComponentRecord>
    ): EnhancedContentRecord {
        const component = components.find(
            (e) => e.enhancedContentId === this.id
        );

        return this.with({ component: component });
    }

    /**
     *
     * Given publications, sets publication navigation property.
     *
     * Note: Requires that the record already has a `component` property set.
     *
     * @param {Array<PublicationRecord>} publications
     */
    public withPublication(
        publications: Array<PublicationRecord>
    ): EnhancedContentRecord {
        const publication = publications.find(
            (e) => e.id === this.component?.publicationId
        );

        return this.with({ publication: publication });
    }

    public withResources(
        resources?: Array<EnhancedContentResourceRecord>
    ): EnhancedContentRecord {
        if (CollectionUtils.isEmpty(resources)) {
            return this;
        }

        const relatedResources = resources.filter(
            (r: EnhancedContentResourceRecord) =>
                r.enhancedContentId === this.id
        );

        return this.with({ resources: relatedResources });
    }

    /**
     *
     * Given sections, sets section navigation property.
     *
     * Note: Requires that the record already has a `component` property set.
     *
     * @param {Array<SectionRecord>} sections
     */
    public withSection(sections: Array<SectionRecord>): EnhancedContentRecord {
        const section = sections.find(
            (e) => e.id === this.component?.sectionId
        );

        return this.with({ section: section });
    }

    /**
     *
     * Given users, sets user navigation property.
     *
     * @param {Array<UserRecord>} users
     */
    public withUpdatedBy(users: Array<UserRecord>): EnhancedContentRecord {
        const user = users.find((e) => e.id === this.getUpdatedOrCreatedById());

        return this.with({ updatedBy: user });
    }

    // #endregion Public Methods
}
