import { ResultRecord } from "@rsm-hcd/javascript-core";
import SupportUserRecord from "models/view-models/support-user-record";
import SupportUserSubscriptionRecord from "models/view-models/support-user-subscription-record";
import { useCallback, useState } from "react";
import RoleType from "utilities/enumerations/role-type";
import NumberUtils from "utilities/number-utils";
import SupportUserService from "utilities/services/support/support-users-service";
import { ToastManager } from "utilities/toast/toast-manager";

export default function useSupportUserDetail() {
    const [supportUser, setSupportUser] = useState<SupportUserRecord | null>(
        null
    );
    const [loading, setLoading] = useState(false);

    const getUserDetail = useCallback(async (id: number | undefined) => {
        if (NumberUtils.isDefault(id) || id == null) return;

        setLoading(true);

        try {
            const result = await SupportUserService.get({ id });

            const user = result.resultObject ?? null;

            setSupportUser(user);
        } catch (error) {
            if (error instanceof ResultRecord)
                ToastManager.error(error.listErrorMessages());
        } finally {
            setLoading(false);
        }
    }, []);

    const updateFreeTrialExpDate = async (
        supportUserSubscriptionRecord: SupportUserSubscriptionRecord,
        expiresOn: string
    ) => {
        try {
            const updatedSupportUserSubscription =
                supportUserSubscriptionRecord.with({ expiresOn });
            const request = await SupportUserService.update(
                updatedSupportUserSubscription,
                { id: supportUserSubscriptionRecord.id }
            );

            if (request.resultObject == null) {
                throw new Error();
            }

            ToastManager.success(
                `Extended Free Trial until ${request.resultObject.formatExpirationDate()}`
            );
            setSupportUser((prev) => {
                if (prev == null) {
                    return prev;
                }

                if (request.resultObject == null) {
                    return prev;
                }
                const subscriptions =
                    prev.subscriptions?.filter(
                        (x) => x.roleType !== RoleType.FREE_TRIAL
                    ) ?? [];

                return prev.with({
                    subscriptions: [...subscriptions, request.resultObject],
                });
            });
            return request;
        } catch (error) {
            ToastManager.error("Unable to update expiration date");
        }
    };

    return { getUserDetail, loading, supportUser, updateFreeTrialExpDate };
}
