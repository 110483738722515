import { RecordUtils } from "@rsm-hcd/javascript-core";
import { Record } from "immutable";
import PublishStatus from "models/enumerations/publish-status";
import type { Announcement } from "models/interfaces/announcement";
import UserRecord from "models/view-models/user-record";
import DateUtils from "utilities/date-utils";
import PublishableUtils, {
    PublishableRecord,
} from "utilities/publishable-utils";
import StringUtils from "utilities/string-utils";

// -----------------------------------------------------------------------------------------
// #region Enums
// -----------------------------------------------------------------------------------------

export enum AnnouncementStatusDescriptions {
    PublishedWithChanges = "Unpublished Changes Saved",
    Published = "Published",
    Unpublished = "Changes Saved",
}

// #endregion Enums

const defaultValues: Announcement =
    RecordUtils.auditableDefaultValuesFactory<Announcement>({
        acknowledged: false,
        body: undefined,
        bodyDraft: "",
        createdBy: undefined,
        deletedDraftById: undefined,
        deletedDraftOn: undefined,
        publishedById: undefined,
        publishedOn: undefined,
        title: "",
        titleDraft: "",
        updatedBy: undefined,
        url: "",
        urlDraft: "",
        version: "",
    });

export default class AnnouncementRecord
    extends Record(defaultValues)
    implements Announcement, PublishableRecord
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<Announcement>) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.createdBy != null) {
            params.createdBy = RecordUtils.ensureRecord(
                params.createdBy,
                UserRecord
            );
        }

        if (params.updatedBy != null) {
            params.updatedBy = RecordUtils.ensureRecord(
                params.updatedBy,
                UserRecord
            );
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region PublishableRecord Implementation
    // -----------------------------------------------------------------------------------------

    hasUnpublishedChanges(): boolean {
        // this can be updated if we extend the Publication publishing process to include
        // fields, rather than just the entire record wholesale.
        return PublishableUtils.hasUnpublishedChanges(this);
    }

    getPublishStatus(): PublishStatus {
        return PublishableUtils.getPublishStatus(this);
    }

    // #endregion PublishableRecord Implementation

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Convenience method to return the body content based on if the user has admin access or not.
     * @param hasAdminAccess
     */
    public getBodyContentBasedOnRole(hasAdminAccess: boolean): string {
        if (hasAdminAccess) {
            return this.bodyDraft ?? this.body ?? "";
        }

        return this.body ?? "";
    }

    /**
     * Convenience method to display the current enhanced content status
     *
     * @return {*}  {string}
     */
    public getDisplayStatus(): string {
        if (this.hasUnpublishedChanges()) {
            return AnnouncementStatusDescriptions.PublishedWithChanges;
        }

        if (this.getPublishStatus() === PublishStatus.Published) {
            return AnnouncementStatusDescriptions.Published;
        }

        return AnnouncementStatusDescriptions.Unpublished;
    }

    /**
     * Convenience method to display the current enhanced content status and last updated date
     *
     * @return {*}  {string}
     */
    public getDisplayStatusAndDate(): string {
        return `${this.getDisplayStatus()} on ${DateUtils.formatLastEditedDate(
            this
        )}`;
    }

    /**
     * Convenience method to the return the text for the last updated date text along with the user
     * Ex: on YYYY/MM/D at 8:00 AM by User
     */
    public getLastUpdatedText(): string {
        const message = `on ${DateUtils.formatLastEditedDate(this)}`;
        const updatedBy = this.updatedBy ?? this.createdBy;

        if (updatedBy == null) {
            return message;
        }

        return `${message} by ${updatedBy.getFirstAndLastName()}`;
    }

    /**
     * Convenience method to return the id of the user who last updated the record (or created it,
     * if it has not yet been updated)
     *
     */
    public getUpdatedOrCreatedById(): number | undefined {
        return this.updatedById ?? this.createdById;
    }

    /**
     * Convenience method to display the user that last updated the record
     *
     * @return {*}  {(string | undefined)}
     */
    public getUpdatedByText(): string | undefined {
        const updatedByName = this.updatedBy?.getFirstAndLastName();

        if (updatedByName == null) {
            return;
        }

        return `by ${updatedByName}`;
    }

    /**
     * Convenience method to check for changes against an initial record.
     * @param initialRecord
     */
    public isDirty(initialRecord: AnnouncementRecord): boolean {
        return (
            initialRecord.urlDraft !== this.urlDraft ||
            initialRecord.bodyDraft !== this.bodyDraft ||
            initialRecord.titleDraft !== this.titleDraft
        );
    }

    /**
     * Convenience method to check if this record is new or a persisted entity
     */
    public isPersisted(): boolean {
        return (this.id ?? 0) > 0;
    }

    public shouldAlertUser(): boolean {
        return (
            StringUtils.hasValue(this.version) &&
            !this.acknowledged &&
            this.title != null
        );
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<Announcement>} values
     */
    public with(values: Partial<Announcement>): AnnouncementRecord {
        return new AnnouncementRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}
