import "assets/scss/app.scss";
import "lazysizes";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "react-toastify/dist/ReactToastify.min.css";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import { useMsal } from "@azure/msal-react";
import { AuthenticationResult, InteractionStatus } from "@azure/msal-browser";
import B2C_CONFIG from "pages/azure-login/b2c-config";
import AuthService from "utilities/services/auth/auth-service";
import { siteMap } from "internal-sitemap";
import { RouteUtils } from "utilities/route-utils";
import useCurrentIdentity from "utilities/hooks/use-current-identity";

export enum AuthenticationLifeCycleStatus {
    Idle = "idle",
    Loading = "loading",
    LoggedIn = "LoggedIn",
    FailedToLogin = "FailedToLogin",
}

// This hook will attempt to log the user in and build the globalState.
// If the useAzureB2CForSSO feature flag is on, it will first retreive and accessToken from Azure, then attempt to log the user into LiNK
// If the useAzureB2CForSSO feature flag is off, it will attempt to log the user into LiNK
const useAuthentication = () => {
    const loadingRef = useRef(false);
    const [authenticationLifeCycleStatus, setAuthenticationLifeCycleStatus] =
        useState<AuthenticationLifeCycleStatus>(
            AuthenticationLifeCycleStatus.Idle
        );
    const { useAzureB2CForSSO, useSsoSilent } = useFeatureFlags();
    const { accounts, instance, inProgress } = useMsal();
    const { globalState, setGlobalState } = useGlobalState();
    const { create: createAuth } = AuthService.useCreate();

    const { build, isLoggedIn } = useCurrentIdentity();

    const cookieAlreadyCreated = useMemo(
        () => globalState.isAuthenticated(),
        [globalState]
    );

    const attemptLogin = useCallback(
        async (refreshIdentity = false) => {
            if (cookieAlreadyCreated && !refreshIdentity) return;

            setAuthenticationLifeCycleStatus(
                AuthenticationLifeCycleStatus.Loading
            );
            const response = await createAuth();
            build(response.resultObject);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [createAuth, setGlobalState]
    );

    useEffect(() => {
        if (isLoggedIn) {
            loadingRef.current = false;
            setAuthenticationLifeCycleStatus(
                AuthenticationLifeCycleStatus.LoggedIn
            );
        }
    }, [authenticationLifeCycleStatus, isLoggedIn]);

    const attemptLoginByAzure = useCallback(async () => {
        if (inProgress === InteractionStatus.None) {
            // console.info("Number of Accounts: ", accounts.length);
            try {
                // Not logged in yet -> let's check if we logged in somewhere else
                if (accounts.length === 0 && useSsoSilent) {
                    // console.log(
                    //     "Not logged in yet -> let's check if we logged in somewhere else"
                    // );
                    const result = await instance.ssoSilent({
                        scopes: [B2C_CONFIG.CLIENT_ID],
                    });
                    setAccessToken(result);
                    attemptLogin();
                }
                // Already logged in -> let's try to refresh our token
                else {
                    // console.log(
                    //     "Already logged in -> let's try to refresh our token"
                    // );
                    const result = await instance.acquireTokenSilent({
                        scopes: [B2C_CONFIG.CLIENT_ID],
                        account: accounts[0],
                    });
                    setAccessToken(result);
                    attemptLogin();
                }
            } catch (error) {
                // console.error("Error in use-authentication", error);
                // Not signed in to azure
                setAuthenticationLifeCycleStatus(
                    AuthenticationLifeCycleStatus.FailedToLogin
                );
            }
        }
    }, [accounts, attemptLogin, inProgress, instance, useSsoSilent]);

    useEffect(() => {
        const handleAzureRedirect = async () => {
            const response = await instance.handleRedirectPromise();
            if (response == null) return;

            setAccessToken(response);
            attemptLogin();
        };

        handleAzureRedirect();
    }, [attemptLogin, instance]);

    useEffect(() => {
        if (
            authenticationLifeCycleStatus ===
                AuthenticationLifeCycleStatus.LoggedIn ||
            authenticationLifeCycleStatus ===
                AuthenticationLifeCycleStatus.FailedToLogin ||
            loadingRef.current ||
            RouteUtils.assertCurrentUrl(siteMap.redirectToLogin)
        )
            return;

        loadingRef.current = true;
        attemptLoginByAzure();
    }, [
        attemptLoginByAzure,
        attemptLogin,
        authenticationLifeCycleStatus,
        useAzureB2CForSSO,
    ]);

    return {
        authenticationLifeCycleStatus,
        attemptLogin,
    };
};

const setAccessToken = (result: AuthenticationResult) => {
    const B2C_COOKIE_KEY = "AccessToken";
    document.cookie = `${B2C_COOKIE_KEY}=${
        result.accessToken
    };expires=${result.expiresOn?.toUTCString()};path=/`;
};

export default useAuthentication;
