/* eslint-disable-next-line no-restricted-imports */
import { RouteUtils as AndcultureCodeCoreRouteUtils } from "@rsm-hcd/javascript-core";
import { RouteUtils as AndcultureCodeReactRouteUtils } from "@rsm-hcd/javascript-react";
import SubscriptionType from "organisms/enums/subscription-type";
import { siteMap } from "internal-sitemap";
import { RouteMap } from "utilities/interfaces/route-map";
import LocalizationUtils from "utilities/localization-utils";
import StringUtils from "utilities/string-utils";
import SubscriptionTypeUtils from "utilities/subscription-type-utils";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

export const CULTURE_PARAM = "/:culture?";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

/**
 * regex removes everything preceding the first since / (i.e. the location)
 * so https://google.com/some/url/path becomes some/url/path
 * @param absolutePath
 */
const absoluteToRelativePath = (absolutePath: string): string =>
    absolutePath.replace(/^(?:\/\/|[^/]+)*\//, "");

/**
 * Assert that the current URL matches the Route given. Ignores query string.
 * @param route             the Route to compile
 * @param routeParams       any parameters needed to build the Route
 * @param pageUrl           optionally pass the current URL as a param so that we can use location.pathname from
 *                          useLocation()
 */
const assertCurrentUrl = (
    route: string,
    routeParams?: any,
    pageUrl?: string
): boolean => {
    const givenUrl = getUrl(route, routeParams);
    let currentUrl = pageUrl ?? window.location.pathname;

    // cut off the query string, if there is one
    currentUrl = removeQueryString(currentUrl);

    return givenUrl === currentUrl;
};

/**
 * Assert that the current URL does **NOT** match the Route given. Ignores query string.
 * @param route the Route to compile
 * @param routeParams any parameters needed to build the Route
 * @param pageUrl optionally pass the current URL as a param so that we can use location.pathname from
 * useLocation()
 */
const assertIsNotCurrentUrl = (
    route: string,
    routeParams?: any,
    pageUrl?: string
): boolean => !assertCurrentUrl(route, routeParams, pageUrl);

const join = (target: RouteMap, source: RouteMap, name: string): RouteMap => {
    const routes = Object.assign(target[name].routes, source);
    return Object.assign(target, routes);
};

/**
 * Assert that the current location contains a culture code:
 * https://regex101.com/r/oCApY8/1/
   @param location Current location.pathname from useLocation()
 */
const locationContainsCultureCode = (location: string): boolean =>
    /^\/[a-z]{2,3}-[a-zA-Z]{2,3}\//.test(location);

/**
 * Generates a culture coded route with the current culture code from a route if the
 * current location contains a culture code.
 * @param route the Route to compile
 * @param locationPathname Current location.pathname from useLocation()
 * @param  currentCultureCode Users current culture code
 */
const generateCultureCodedRoute = (
    route: string,
    locationPathname: string,
    currentCultureCode: string
) =>
    `${
        locationContainsCultureCode(locationPathname)
            ? `/${currentCultureCode.toLowerCase()}`
            : ""
    }${route}`;

/**
 * Determines if the current location needs to be redirected to a culture coded location
 * @param locationPathname Current location.pathname from useLocation()
 * @param cultureCode Users current culture code
 */
const getCultureRedirectPath = (
    locationPathname: string,
    cultureCode: string
): string | undefined => {
    const defaultCulture = LocalizationUtils.defaultCultureCode();
    const currentCulture = cultureCode.toLowerCase();
    const currentMatchesDefault = StringUtils.isEqual(
        currentCulture,
        defaultCulture
    );

    if (locationPathname.startsWith(`/${currentCulture}`)) {
        return;
    }

    // Check if the current location has a culture code
    // https://regex101.com/r/glVxhH/1
    const locationPieces = locationPathname.split("/");
    const locationHasCultureCode = /^[a-z]{2,3}-[a-zA-Z]{2,3}?$/.test(
        locationPieces[1]
    );

    // If location doesnt have a culture code AND the current code is default, just return
    if (currentMatchesDefault && !locationHasCultureCode) {
        return;
    }

    if (locationHasCultureCode) {
        locationPieces.splice(1, 1, currentCulture);

        return locationPieces.join("/");
    }

    return `/${currentCulture}${locationPathname}`;
};

/**
 * Convenience function for constructing the route to the registration page based on subscription
 * and billing cycle pin, if provided. Assumes that proper matching of SubscriptionType -> BillingCycleOption
 * was performed.
 *
 * @param subscriptionType Subscription type to replace route parameters with
 * @param pin (optional) Billing cycle pin which is used to append the sku to the route
 */
const getRegistrationPageRoute = (
    subscriptionType: SubscriptionType,
    pin?: string
): string => {
    const subscriptionTypeParam =
        SubscriptionTypeUtils.toRouteParam(subscriptionType);

    const baseRoute = RouteUtils.getUrl(siteMap.signup.registerPlanSelected, {
        subscriptionType: subscriptionTypeParam,
    });

    if (StringUtils.isEmpty(pin)) {
        return baseRoute;
    }

    return RouteUtils.appendQueryParams(baseRoute, {
        sku: pin,
    });
};

/**
 * Constructs a url from a formatted route path.
 * @param path Route path template. Parameters in the path are denoted withed a colon `:id`
 * @param pathParams Object with keys matching supplied path template components
 * @param queryParams Object to get translated to the query string of the url
 * @param hashLink String value of an element's id attribute to anchor to
 */
const getUrl = (
    path: string,
    pathParams?: any,
    queryParams?: any,
    hashLink?: string
) => {
    const newPath =
        AndcultureCodeReactRouteUtils.getUrlFromPath(
            path,
            pathParams,
            queryParams
        ) ?? "";

    if (StringUtils.hasValue(hashLink)) {
        return newPath + hashLink;
    }

    return newPath;
};

const removeQueryString = (url: string): string => {
    const index = url.indexOf("?");
    if (index > -1) {
        return url.substr(0, index);
    }

    return url;
};

/**
 * Modifies the RouteDefinition with an optional :culture? param
 * @param route RouteDefinition to be modified if necessary
 */
const withCulture = <T extends { path: string }>(route: T): T => {
    if (route.path.startsWith(CULTURE_PARAM)) {
        return route;
    }

    return { ...route, path: `${CULTURE_PARAM}${route.path}` };
};

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export const RouteUtils = {
    absoluteToRelativePath,
    appendQueryParams: AndcultureCodeReactRouteUtils.appendQueryParams,
    assertCurrentUrl,
    assertIsNotCurrentUrl,
    containsCultureCode: locationContainsCultureCode,
    debugRoutes: AndcultureCodeReactRouteUtils.debugRoutes,
    generateCultureCodedRoute,
    getCultureRedirectPath,
    getFlattenedRoutes: AndcultureCodeReactRouteUtils.getFlattenedRoutes,
    getRegistrationPageRoute,
    getUrl,
    queryStringToObject: AndcultureCodeCoreRouteUtils.queryStringToObject,
    removeQueryString,
    replacePathParams: AndcultureCodeReactRouteUtils.replacePathParams,
    withCulture,
    join,
};

// #endregion Exports
