import SituationRecord from "models/view-models/situational-navigation/situations/situation-record";
import { PagedQuery } from "@rsm-hcd/javascript-core";
import PublishableFilters from "utilities/interfaces/publishable-filters";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AdminSituationQueryParams
    extends PagedQuery,
        PublishableFilters {
    relatedSituationId?: number;
}

export interface AdminSituationPathParams {
    id: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = "admin/situational-navigation/situations";
const resourceEndpoint = `${baseEndpoint}/:id`;
const resourceType = SituationRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const AdminSituationService = {
    get: ServiceFactory.get<
        SituationRecord,
        AdminSituationPathParams,
        AdminSituationQueryParams
    >(resourceType, resourceEndpoint),
    useGet: ServiceHookFactory.useGet<
        SituationRecord,
        AdminSituationPathParams,
        AdminSituationQueryParams
    >(resourceType, resourceEndpoint),
    list: ServiceFactory.list<SituationRecord, AdminSituationQueryParams>(
        resourceType,
        baseEndpoint
    ),
    useList: ServiceHookFactory.useList<
        SituationRecord,
        AdminSituationQueryParams
    >(resourceType, baseEndpoint),
    create: ServiceFactory.create<SituationRecord>(resourceType, baseEndpoint),
    useCreate: ServiceHookFactory.useCreate<SituationRecord>(
        resourceType,
        baseEndpoint
    ),
    update: ServiceFactory.update<SituationRecord, AdminSituationPathParams>(
        resourceType,
        resourceEndpoint
    ),
    useUpdate: ServiceHookFactory.useUpdate<
        SituationRecord,
        AdminSituationPathParams
    >(resourceType, resourceEndpoint),
    delete: ServiceFactory.delete<AdminSituationPathParams>(resourceEndpoint),
    useDelete:
        ServiceHookFactory.useDelete<AdminSituationPathParams>(
            resourceEndpoint
        ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default AdminSituationService;

// #endregion Exports
