import PublicationSidebarNavAnchor from "atoms/anchors/publication-sidebar-nav-anchor";
import { LoaderStyles } from "atoms/constants/loader-styles";
import Loader from "atoms/loaders/loader";
import { AnnexParams } from "interfaces/routing/annex-params";
import { NestedPublicationParams } from "interfaces/routing/nested-publication-params";
import { TablePageParams } from "interfaces/routing/table-page-params";
import UnorderedList from "molecules/lists/unordered-list";
import PublicationSidebarNav from "molecules/sidebar-chapter-navigation/publication-sidebar-nav";
import { PublicationSidebarNavItem } from "molecules/sidebar-chapter-navigation/publication-sidebar-nav-link";
import PublicationSidebarNavLinks from "molecules/sidebar-chapter-navigation/publication-sidebar-nav-links";
import React from "react";
import { match } from "react-router-dom";
import { CollectionUtils } from "utilities/collection-utils";
import useAnnex from "utilities/hooks/domain/publications/annexes/use-annex";
import useAnnexTables from "utilities/hooks/domain/publications/annexes/use-annex-tables";
import usePublication from "utilities/hooks/domain/publications/use-publication";
import useAdminPreview from "utilities/hooks/use-admin-preview";
import useDeepCompareMemo from "utilities/hooks/use-deep-compare-memo";
import { useErrorBoundary } from "utilities/hooks/use-error-boundary";
import useErrors from "utilities/hooks/use-errors";
import useLoading from "utilities/hooks/use-loading";
import { useLocalization } from "utilities/hooks/use-localization";
import { useLocationHash } from "utilities/hooks/use-location-hash";
import StringUtils from "utilities/string-utils";

interface AnnexTablesMetanaveProps {
    match: match<NestedPublicationParams & AnnexParams & TablePageParams>;
}

const AnnexTablesMetanav: React.FunctionComponent<AnnexTablesMetanaveProps> = (
    props: AnnexTablesMetanaveProps
) => {
    const { match } = props;
    const { annexId, annexNfpaLabel, code, edition, publicationId } =
        match.params;
    const activeExternalId = useLocationHash();
    const { isAdminPreview } = useAdminPreview();
    const { t } = useLocalization();

    const {
        errors: publicationErrors,
        loaded: publicationLoaded,
        loading: publicationLoading,
        resultObject: publication,
    } = usePublication({
        code,
        edition,
        publicationId,
    });

    const {
        errors: annexErrors,
        loaded: annexLoaded,
        loading: annexLoading,
        resultObject: annex,
    } = useAnnex(publication?.id, annexId, annexNfpaLabel);

    const {
        errors: tablesErrors,
        loaded: tablesLoaded,
        loading: tablesLoading,
        resultObject: tables,
    } = useAnnexTables(annex.id, isAdminPreview);

    const navItems: Array<PublicationSidebarNavItem> =
        useDeepCompareMemo(() => {
            return tables.map((table) => {
                const active =
                    StringUtils.hasValue(activeExternalId) &&
                    table.externalId === activeExternalId;

                return table.toNavItem({ active });
            });
        }, [activeExternalId, tables]);

    const errors = useErrors(annexErrors, publicationErrors, tablesErrors);
    useErrorBoundary(errors);

    const loaded = annexLoaded && publicationLoaded && tablesLoaded;
    const loading = useLoading(annexLoading, publicationLoading, tablesLoading);
    const loaderText = t("loadingItem", { item: t("table") });

    const backButtonText = t("backToLocation", {
        location: annex.getDisplayLabel(),
    });
    const backButtonRoute = annex.getRoute(isAdminPreview);

    const title = `Tables in ${annex.getDisplayTitleWithNumber()}`;
    const publicationType = publication?.type;

    return (
        <React.Fragment>
            <PublicationSidebarNavAnchor
                accessibleText={backButtonText}
                linkText={backButtonText}
                to={backButtonRoute}
            />
            {CollectionUtils.hasValues(errors) && (
                <UnorderedList listItems={errors} />
            )}
            {loading && (
                <Loader
                    accessibleText={loaderText}
                    type={LoaderStyles.LinkGlyphGray}
                />
            )}
            {annex != null && loaded && (
                <PublicationSidebarNav title={title}>
                    <PublicationSidebarNavLinks
                        navItems={navItems}
                        publicationType={publicationType}
                    />
                </PublicationSidebarNav>
            )}
        </React.Fragment>
    );
};

export default AnnexTablesMetanav;
