import PublicationSidebarNavAnchor from "atoms/anchors/publication-sidebar-nav-anchor";
import { LoaderStyles } from "atoms/constants/loader-styles";
import Loader from "atoms/loaders/loader";
import AlertLevels from "constants/alert-levels";
import { AdminPreviewParams } from "interfaces/routing/admin-preview-params";
import { NestedPublicationParams } from "interfaces/routing/nested-publication-params";
import { NfpaLabelParams } from "interfaces/routing/nfpa-label-params";
import AnnexTableOfContentsRecord from "models/view-models/table-of-contents/annex-table-of-contents-record";
import AlertBanner from "molecules/alerts/alert-banner";
import UnorderedList from "molecules/lists/unordered-list";
import NavItem from "molecules/sidebar-chapter-navigation/nav-item";
import PublicationSidebarNav from "molecules/sidebar-chapter-navigation/publication-sidebar-nav";
import React, { Fragment, useMemo } from "react";
import { match } from "react-router-dom";
import { CollectionUtils } from "utilities/collection-utils";
import useBookviewTableOfContents from "utilities/hooks/domain/publications/use-bookview-table-of-contents";
import usePublication from "utilities/hooks/domain/publications/use-publication";
import useAdminPreview from "utilities/hooks/use-admin-preview";
import { useErrorBoundary } from "utilities/hooks/use-error-boundary";
import useErrors from "utilities/hooks/use-errors";
import { useLocalization } from "utilities/hooks/use-localization";
import NumberUtils from "utilities/number-utils";
import StringUtils from "utilities/string-utils";

interface AnnexMetaNavProps {
    match: match<
        NestedPublicationParams & NfpaLabelParams & AdminPreviewParams
    >;
}

const AnnexMetaNav: React.FunctionComponent<AnnexMetaNavProps> = (
    props: AnnexMetaNavProps
) => {
    const {
        id: annexId,
        code,
        edition,
        nfpaLabel,
        publicationId,
    } = props.match.params;
    const { isAdminPreview } = useAdminPreview();
    const { t } = useLocalization();

    const { tableOfContents, loading } = useBookviewTableOfContents();
    const annexes = useMemo(
        () => tableOfContents.annexes ?? [],
        [tableOfContents.annexes]
    );

    const { resultObject: publication, errors: publicationErrors } =
        usePublication({
            code,
            edition,
            publicationId,
        });

    const errors = useErrors(publicationErrors);
    useErrorBoundary(errors);

    const current: AnnexTableOfContentsRecord | undefined = useMemo(() => {
        if (
            (NumberUtils.isDefault(annexId) &&
                StringUtils.isEmpty(nfpaLabel)) ||
            CollectionUtils.isEmpty(annexes)
        ) {
            return;
        }
        const id = NumberUtils.parseInt(annexId);

        const byLabel = (annex: AnnexTableOfContentsRecord) =>
            annex.nfpaLabel === nfpaLabel;
        const byId = (annex: AnnexTableOfContentsRecord) => annex.id === id;
        const filter = NumberUtils.isDefault(id) ? byLabel : byId;

        return annexes.find(filter);
    }, [annexId, nfpaLabel, annexes]);

    const anchorTo = publication.getRoute(isAdminPreview);
    const text = t("tableOfContents");

    const navItems = useMemo(() => {
        if (annexes == null) {
            return [];
        }

        return annexes?.map((annex) => (
            <NavItem
                key={annex.id}
                hasBookmark={annex.hasBookmark}
                externalId={annex.externalId}
                id={annex.id}
                label={annex.getDisplayLabel(
                    publication.type,
                    publication.locale
                )}
                isActive={
                    nfpaLabel === annex.nfpaLabel ||
                    annexId === annex.id?.toString()
                }
                route={annex.getRoute(props.match.params, isAdminPreview)}
                shouldScroll={false}
                title={annex.getTitleWithoutLabel(
                    publication.type,
                    publication.locale
                )}
            />
        ));
    }, [
        annexId,
        annexes,
        isAdminPreview,
        nfpaLabel,
        props.match.params,
        publication.locale,
        publication.type,
    ]);

    if (CollectionUtils.hasValues(errors)) {
        return (
            <AlertBanner alertLevel={AlertLevels.Error}>
                <UnorderedList listItems={errors} />
            </AlertBanner>
        );
    }

    return (
        <Fragment>
            <PublicationSidebarNavAnchor
                accessibleText={text}
                linkText={text}
                to={anchorTo}
            />

            {current != null && !loading ? (
                <PublicationSidebarNav
                    title={current.getPluralLabel(publication.type)}>
                    {navItems}
                </PublicationSidebarNav>
            ) : (
                <Loader type={LoaderStyles.LinkGlyphGray} />
            )}
        </Fragment>
    );
};

export default AnnexMetaNav;
