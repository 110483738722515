import { PagedQuery } from "@rsm-hcd/javascript-core";
import PublishableFilters from "utilities/interfaces/publishable-filters";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import SolutionRecord from "models/view-models/situational-navigation/solutions/solution-record";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

/**
 * Solutions parameters when listing Solution resources.
 * These get translated to QueryString parameters
 */
export interface AdminSolutionQueryParams
    extends PagedQuery,
        PublishableFilters {
    situationId?: number;
}

export interface AdminSolutionPathParams {
    id: number;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const baseEndpoint = "admin/situational-navigation/solutions";
const resourceType = SolutionRecord;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Service
// -----------------------------------------------------------------------------------------

const AdminSolutionService = {
    get: ServiceFactory.get<SolutionRecord, AdminSolutionPathParams>(
        resourceType,
        `${baseEndpoint}/:id`
    ),
    useGet: ServiceHookFactory.useGet<SolutionRecord, AdminSolutionPathParams>(
        resourceType,
        `${baseEndpoint}/:id`
    ),
    /**
     * Query Solutions provider with solution parameters and get a result record
     * with an array of hit records
     */
    list: ServiceFactory.list<SolutionRecord, AdminSolutionQueryParams>(
        resourceType,
        baseEndpoint
    ),

    /**
     * Custom hook for leveraging service list calls in react components
     */
    useList: ServiceHookFactory.useList<
        SolutionRecord,
        AdminSolutionQueryParams
    >(resourceType, baseEndpoint),

    update: ServiceFactory.update<SolutionRecord, AdminSolutionPathParams>(
        resourceType,
        `${baseEndpoint}/:id`
    ),

    useUpdate: ServiceHookFactory.useUpdate<
        SolutionRecord,
        AdminSolutionPathParams
    >(resourceType, `${baseEndpoint}/:id`),

    create: ServiceFactory.create<SolutionRecord>(resourceType, baseEndpoint),

    useCreate: ServiceHookFactory.useCreate<SolutionRecord>(
        resourceType,
        baseEndpoint
    ),

    delete: ServiceFactory.delete(`${baseEndpoint}/:id`),

    useDelete: ServiceHookFactory.useDelete(`${baseEndpoint}/:id`),
};

// #endregion Service

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default AdminSolutionService;

// #endregion Exports
