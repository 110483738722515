import React, { useMemo } from "react";
import LocalizationUtils from "utilities/localization-utils";
import { SupportedCultures } from "constants/supported-cultures";
import Icon from "atoms/icons/icon";
import { Icons } from "atoms/constants/icons";
import { ButtonSizes } from "atoms/constants/button-sizes";
import DropdownButton, {
    DropdownItem,
} from "molecules/dropdown-button/dropdown-button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { useHistory, useLocation } from "react-router-dom";
import { History, Location } from "history";
import { RouteUtils } from "utilities/route-utils";
import { EnvironmentUtils } from "@rsm-hcd/javascript-core";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface CultureDropdownButtonProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const CultureDropdownButton: React.FunctionComponent<
    CultureDropdownButtonProps
> = (props: CultureDropdownButtonProps) => {
    const history = useHistory();
    const location = useLocation();
    const options = useMemo(
        () => getOptions(history, location),
        [history, location]
    );
    const currentCultureLabel = LocalizationUtils.getLanguageCodeShortLabel(
        LocalizationUtils.currentCultureCode()
    );

    const trigger = (
        <React.Fragment>
            {currentCultureLabel}
            <Icon type={Icons.ChevronDown} />
        </React.Fragment>
    );

    return (
        <DropdownButton
            buttonContents={trigger}
            items={options}
            label={currentCultureLabel}
            size={ButtonSizes.Small}
            style={ButtonStyles.TertiaryAlt}
        />
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Private Functions
// -----------------------------------------------------------------------------------------

const handleSelectCulture = (
    culture: string,
    history: History,
    location: Location
) => {
    EnvironmentUtils.runIfDevelopment(() => {
        console.log(
            `[culture-dropdown.tsx#handleSelectCulture]: Changed culture to ${culture}`
        );
    });

    LocalizationUtils.changeCultureCode(culture);
    const routeWithNewCulture = RouteUtils.getCultureRedirectPath(
        location.pathname,
        culture
    );

    if (routeWithNewCulture == null) {
        return;
    }

    history.replace(routeWithNewCulture);
};

const getOptions = (
    history: History,
    location: Location
): Array<DropdownItem> =>
    SupportedCultures.map((culture: string) => ({
        component: LocalizationUtils.getLanguageCodeShortLabel(culture),
        onSelect: () => {
            handleSelectCulture(culture, history, location);
        },
    }));

// #endregion Private Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default CultureDropdownButton;

// #endregion Exports
