import Button from "atoms/buttons/button";
import React, { forwardRef } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useLocalization } from "utilities/hooks/use-localization";

interface SwitchProfileButtonProps {
    setSwitchProfileModalIsOpen: (value: React.SetStateAction<boolean>) => void;
    setIsOpen: (value: React.SetStateAction<boolean>) => void;
}

const SwitchProfileButton = forwardRef(
    (
        { setSwitchProfileModalIsOpen, setIsOpen }: SwitchProfileButtonProps,
        _ref
    ) => {
        const { t } = useLocalization();
        const { globalState } = useGlobalState();

        const userRoles = globalState?.currentIdentity?.getActiveUserRoles();
        const switchProfileLabel = t("switchProfile");

        if (CollectionUtils.isEmpty(userRoles) || userRoles!.length === 1) {
            return null;
        }

        return (
            <Button
                onClick={() => {
                    setTimeout(() => {
                        setSwitchProfileModalIsOpen(true);
                        setIsOpen(false);
                    }, 0);
                }}>
                {switchProfileLabel}
            </Button>
        );
    }
);

export default SwitchProfileButton;
