import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import UserRoleGroupRecord from "models/view-models/user-role-group-record";
import { PagedQuery } from "@rsm-hcd/javascript-core";

// ---------------------------------------------------------------------------------------------
// #region Interfaces
// ---------------------------------------------------------------------------------------------

export interface UserRoleGroupBasePathParams {}

export interface UserRoleGroupResourcePathParams
    extends UserRoleGroupBasePathParams {
    id: number;
}

/**
 * Search parameters when listing UserRoleGroup resources.
 * These get translated to QueryString parameters
 */
export interface UserRoleGroupListQueryParams extends PagedQuery {
    groupId?: number;
    searchText?: string;
    userId?: number;
}

// #endregion Interfaces

// ---------------------------------------------------------------------------------------------
// #region Constants
// ---------------------------------------------------------------------------------------------

const baseEndpoint = "userrolegroups";
const resourceType = UserRoleGroupRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;

// #endregion Constants

// ---------------------------------------------------------------------------------------------
// #region Service
// ---------------------------------------------------------------------------------------------

const UserRoleGroupService = {
    /**
     * Create a new UserRoleGroup
     */
    create: ServiceFactory.create(resourceType, baseEndpoint),

    /**
     * Deletes a UserRoleGroup by id
     */
    delete: ServiceFactory.delete(resourceEndpoint),

    /**
     * Retrieve a specific UserRoleGroup resource
     */
    get: ServiceFactory.get<
        UserRoleGroupRecord,
        UserRoleGroupResourcePathParams
    >(resourceType, resourceEndpoint),

    /**
     * Retrieves a list of UserRoleGroup resources based upon supplied search criteria
     */
    list: ServiceFactory.list<
        UserRoleGroupRecord,
        UserRoleGroupListQueryParams
    >(resourceType, baseEndpoint),

    /**
     * Update a UserRoleGroup
     */
    update: ServiceFactory.update(resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service create calls in react components
     */
    useCreate: ServiceHookFactory.useCreate(resourceType, baseEndpoint),

    /**
     * Custom hook for deleting a UserRoleGroup from a component
     */
    useDelete: ServiceHookFactory.useDelete(resourceEndpoint),

    /**
     * Custom hook for leveraging service get calls in react components
     */
    useGet: ServiceHookFactory.useGet<
        UserRoleGroupRecord,
        UserRoleGroupResourcePathParams
    >(resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service list calls in react components
     */
    useList: ServiceHookFactory.useList<
        UserRoleGroupRecord,
        UserRoleGroupListQueryParams
    >(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service update calls in react components
     */
    useUpdate: ServiceHookFactory.useUpdate<
        UserRoleGroupRecord,
        UserRoleGroupResourcePathParams
    >(resourceType, resourceEndpoint),
};

// #endregion Service

// ---------------------------------------------------------------------------------------------
// #region Exports
// ---------------------------------------------------------------------------------------------

export default UserRoleGroupService;

// #region Exports
