import { Record } from "immutable";
import { RecordUtils } from "@rsm-hcd/javascript-core";
import WorkGroup from "models/interfaces/work-groups/work-group";

const defaultValues: WorkGroup =
    RecordUtils.auditableDefaultValuesFactory<WorkGroup>({
        groupId: undefined,
        title: undefined,
        workGroupMembers: undefined,
        workGroupCollections: undefined,
    });

export default class WorkGroupRecord
    extends Record(defaultValues)
    implements WorkGroup
{
    constructor(params?: WorkGroup) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<WorkGroup>} values
     */
    public with(values: Partial<WorkGroup>): WorkGroupRecord {
        return new WorkGroupRecord(Object.assign(this.toJS(), values));
    }
}
