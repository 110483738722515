import {
    AnchorTargetTypes,
    ServiceResponse,
    ResultRecord,
} from "@rsm-hcd/javascript-core";
import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { LoaderSizes } from "atoms/constants/loader-sizes";
import RemoteImage from "atoms/images/remote-image";
import Loader from "atoms/loaders/loader";
import Paragraph from "atoms/typography/paragraph";
import AlertLevels from "constants/alert-levels";
import JobStatuses from "models/enumerations/jobs/job-statuses";
import type BookRecord from "models/view-models/book-record";
import type FileRecord from "models/view-models/file-record";

import JobRecord from "models/view-models/job-record";
import type AdminEditorPageContextRecord from "models/view-models/situational-navigation/admin-editor-page-context-record";
import AlertBanner from "molecules/alerts/alert-banner";
import CheckboxFormField from "molecules/form-fields/checkbox-form-field";
import FileUploadField from "molecules/form-fields/file-upload-field";
import InputFormField from "molecules/form-fields/input-form-field";
import UploadedFileField from "molecules/form-fields/uploaded-file-field";
import UnsavedChangesPrompt from "molecules/unsaved-changes-prompt/unsaved-changes-prompt";
import EnhancedContentMigrationField from "organisms/admin/publications/enhanced-content/enhanced-content-migration-field";
import PublishStatusMenu from "organisms/admin/situational-navigation/publish-status-menu";
import ColorSelect, {
    ColorSelectType,
} from "organisms/my-link/my-bookmarks/color-select";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useAdminEditorPageContext } from "utilities/contexts/admin/use-admin-editor-page-context";
import FileUploadDestination from "utilities/enumerations/file-upload-destination";
import MimeTypes from "utilities/enumerations/mime-type";
import { PublicationColor } from "utilities/enumerations/publication-color";
import { WorkerNames } from "utilities/enumerations/worker-names";
import usePublicationAdmin from "utilities/hooks/domain/admin/publications/use-publication-admin";
import useBook from "utilities/hooks/domain/books/use-book";
import useLoading from "utilities/hooks/use-loading";
import { t } from "utilities/localization-utils";
import NumberUtils from "utilities/number-utils";
import JobService from "utilities/services/jobs/job-service";
import { ToastManager } from "utilities/toast/toast-manager";
import useBookTopics from "utilities/hooks/domain/books/topics/use-book-topics";
import BookTopicRecord from "models/view-models/book-topic-record";
import { SelectOption } from "atoms/forms/select";
import type TopicRecord from "models/view-models/topic-record";
import TopicsSelects from "organisms/admin/publications/topics/topics-selects";
import { CollectionUtils } from "utilities/collection-utils";
import StringUtils from "utilities/string-utils";
import { ButtonSizes } from "atoms/constants/button-sizes";
import DateUtils from "utilities/date-utils";
import { useMutation } from "react-query";
import BookService from "utilities/services/books/book-service";
import useFeatureFlags from "utilities/hooks/use-feature-flags";

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------
interface PublicationEditorParams {
    id: string;
}

const PublicationEditor: React.FC = () => {
    const CSS_CLASS_NAME = "c-publication-editor";
    const { id } = useParams<PublicationEditorParams>();
    const { setContext } = useAdminEditorPageContext();
    const formRef = useRef<HTMLDivElement>(null);
    const [isDirty, setIsDirty] = useState(false);
    const [isFeatured, setIsFeatured] = useState(false);
    const [primaryTopicErrorMessage, setPrimaryTopicErrorMessage] = useState<
        string | undefined
    >(undefined);
    const { prependLabelToSectionTitle, extractTablesFromPublicationImport } =
        useFeatureFlags();

    const {
        jobs,
        loaded,
        loading: loadingPublication,
        publish,
        refresh,
        resultObject: publication,
        saving,
        setPublication,
        unpublish,
        update,
    } = usePublicationAdmin({
        publicationId: NumberUtils.parseInt(id) ?? 0,
    });

    const { loading: booksLoading, resultObject: books } = useBook({
        code: publication.code,
        edition: publication.edition,
    });

    const { update: updateBook } = BookService.useUpdate();

    const bookUpdateResult = useMutation<
        ServiceResponse<BookRecord>,
        ResultRecord<BookRecord>,
        BookRecord,
        ResultRecord<BookRecord>
    >((book: BookRecord) => updateBook(book, { id: book.id }));

    const book = (books.first() as BookRecord) ?? undefined;

    useEffect(() => {
        if (book == null) {
            return;
        }

        setIsFeatured(book.isFeatured);
    }, [book]);

    const { create: createJob } = JobService.useBulkCreate();

    const {
        loading: bookTopicsLoading,
        loaded: bookTopicsLoaded,
        resultObject: bookTopics,
        refresh: refreshBookTopics,
        createOrDeleteBookTopics: updateAssignedBookTopics,
    } = useBookTopics({ bookId: book?.id });

    const [initialBookTopics, setInitialSelectedBookTopics] = useState<
        BookTopicRecord[]
    >([]);
    const [selectedBookTopics, setSelectedBookTopics] = useState<
        BookTopicRecord[]
    >([]);

    const loading = useLoading(loadingPublication, saving);

    const inProgressJobs = jobs?.filter(
        (job: JobRecord) => job.status === JobStatuses.InProgress
    );
    const importRunning = CollectionUtils.hasValues(inProgressJobs);
    const erroredJobs = jobs?.filter(
        (job: JobRecord) => job.status === JobStatuses.Errored
    );

    const hasErrors = CollectionUtils.hasValues(erroredJobs);
    const isPublished = publication.isPublished();
    const publishDisabledMessage = hasErrors
        ? "Publishing disabled due to errors in import."
        : "Publishing disabled while importing.";

    useEffect(() => {
        if (loading) {
            return;
        }

        // when done loading, focus the container div so that
        // pressing tab once focuses the file input
        formRef.current?.focus();
    }, [id, loading]);

    // Reset dirty form indicator when loading a new page
    useEffect(() => {
        setIsDirty(false);
    }, [id, setIsDirty]);

    useEffect(() => {
        setContext((prevState: AdminEditorPageContextRecord) =>
            prevState.with({ currentValue: publication, publicationBatch: [] })
        );
    }, [publication, setContext]);

    useEffect(
        function initializeBookTopics() {
            if (bookTopicsLoading) {
                return;
            }

            setSelectedBookTopics(bookTopics);
            setInitialSelectedBookTopics(bookTopics);
        },
        [
            bookTopics,
            bookTopicsLoading,
            setSelectedBookTopics,
            setInitialSelectedBookTopics,
        ]
    );

    const handlePublishClick = async () => {
        await publish(isDirty);
        cleanSearchIndex();
        migrateEnhancedContentSearchIndex();
        refresh();
        setContext((prevContext) =>
            prevContext.with({ shouldFetchNavItems: true })
        );
        setIsDirty(false);
    };

    const handleUnpublishClick = async () => {
        await unpublish();
        refresh();
        setContext((prevContext) =>
            prevContext.with({ shouldFetchNavItems: true })
        );
    };

    const handleSaveClick = async () => {
        const primaryTopic = selectedBookTopics.find((b) => b.isPrimary);
        if (primaryTopic == null) {
            setPrimaryTopicErrorMessage(
                t("propertyIsRequired", {
                    name: StringUtils.capitalize(t("topic")),
                })
            );
            return;
        }
        setPrimaryTopicErrorMessage(undefined);
        await update(publication);
        await updateAssignedBookTopics(initialBookTopics, selectedBookTopics);
        await bookUpdateResult.mutateAsync(
            book.with({ isFeatured: isFeatured })
        );

        refresh();
        refreshBookTopics?.(book.id!);
        setIsDirty(false);
    };

    const handleColorChanged = (newValue: number) => {
        setIsDirty(true);
        setPublication(publication.with({ color: newValue }));
    };

    const handleSectionTitleHasLabelChanged = () => {
        setIsDirty(true);

        setPublication((prevPublication) => {
            return publication.with({
                sectionTitleHasLabel: !prevPublication.sectionTitleHasLabel,
            });
        });
    };

    const handleFeaturedBookChanges = () => {
        setIsDirty(true);
        setIsFeatured((prev) => !prev);
    };

    const handleImageFileChanged = (file?: FileRecord) => {
        setIsDirty(true);
        setPublication(
            publication.with({
                coverImageFileId: file?.id,
                coverImageFile: file,
            })
        );
    };

    const handlePrimaryTopicChange = (newValue: SelectOption<TopicRecord>) => {
        setIsDirty(true);
        if (!bookTopicsLoaded) {
            return;
        }

        const topicId = newValue?.data?.id;
        if (topicId == null) {
            setSelectedBookTopics([]);
            return;
        }

        const updatedSelections = selectedBookTopics.filter(
            (sbt) => !sbt.isPrimary && sbt.topicId !== topicId
        );
        const newRecord = new BookTopicRecord({
            bookId: book.id!,
            isPrimary: true,
            topicId: topicId!,
        });
        setSelectedBookTopics([...updatedSelections, newRecord]);
    };

    const handleSecondaryTopicChange = (
        newValues: SelectOption<TopicRecord>[]
    ) => {
        setIsDirty(true);
        if (!bookTopicsLoaded) {
            return;
        }

        const secondaryTopics = selectedBookTopics.filter(
            (sbt) => !sbt.isPrimary
        );
        const currentTopicIds = secondaryTopics.map((sbt) => sbt.topicId);
        const newTopicIds = newValues.map((nv) => nv.data?.id!);

        const addedTopicIds = CollectionUtils.difference(
            newTopicIds,
            currentTopicIds
        );
        const deletedTopicIds = CollectionUtils.difference(
            currentTopicIds,
            newTopicIds
        );

        const newRecords = addedTopicIds.map(
            (id) =>
                new BookTopicRecord({
                    bookId: book.id!,
                    isPrimary: false,
                    topicId: id,
                })
        );

        const updatedTopics = secondaryTopics
            .filter((e) => !deletedTopicIds.includes(e.topicId!))
            .concat(newRecords);

        const primaryTopic = selectedBookTopics.find((sbt) => sbt.isPrimary);
        setSelectedBookTopics([primaryTopic!, ...updatedTopics]);
    };

    const handleNewPublicationReferenceJobClick = useCallback(async () => {
        const referenceCreateJob = new JobRecord({
            workerName: WorkerNames.PublicationReferenceCreate,
            workerArgs: JSON.stringify([publication.id]),
        });
        try {
            await createJob([referenceCreateJob]);
            ToastManager.success("Reference generation started successfully");
        } catch (error) {
            ToastManager.error("Unable to start reference generation process");
        }
    }, [createJob, publication]);

    const handleNewTableExtractJobClick = useCallback(async () => {
        const tableCreateJob = new JobRecord({
            workerName: WorkerNames.TableExtractWorker,
            workerArgs: JSON.stringify([publication.id]),
        });

        try {
            await createJob([tableCreateJob]);
            ToastManager.success("Extracting table data");
        } catch (error) {
            ToastManager.error("Failed to start table extraction");
        }
    }, [createJob, publication.id]);

    const createNewSearchIndexJob = useCallback(async () => {
        const referenceCreateJob = new JobRecord({
            workerName: WorkerNames.PublicationSearchUpload,
            workerArgs: JSON.stringify([publication.id]),
        });
        try {
            await createJob([referenceCreateJob]);
            ToastManager.success("Indexing publication data for search");
        } catch (error) {
            ToastManager.error("Failed to start search indexing");
        }
    }, [createJob, publication]);

    const cleanSearchIndex = useCallback(async () => {
        const referenceCreateJob = new JobRecord({
            workerName: WorkerNames.PublicationSearchDocumentClean,
            workerArgs: JSON.stringify([publication.code, publication.edition]),
        });
        try {
            await createJob([referenceCreateJob]);
            ToastManager.success("Cleaning search index of obsolete data");
        } catch (error) {
            ToastManager.error("Failed to start index clean");
        }
    }, [createJob, publication]);

    const migrateEnhancedContentSearchIndex = useCallback(async () => {
        const enhancedContentSearchMigrateJob = new JobRecord({
            workerName: WorkerNames.EnhancedContentSearchIndexMigration,
            workerArgs: JSON.stringify([publication.id]),
        });

        try {
            await createJob([enhancedContentSearchMigrateJob]);
            ToastManager.success("Migrating enhanced content search index");
        } catch (error) {
            ToastManager.error("Failed to start enhanced content migration");
        }
    }, [createJob, publication.id]);

    const handleLegacyExtractTableOfContentsClick = useCallback(async () => {
        try {
            const tableOfContentsJob = new JobRecord({
                workerName: WorkerNames.LegacyPublicationCreateTableOfContents,
                workerArgs: JSON.stringify([publication.id]),
            });

            await createJob([tableOfContentsJob]);
            ToastManager.success("Extracting table of contents");
        } catch (error) {
            ToastManager.error("Failed to extract table of contents");
        }
    }, [createJob, publication.id]);

    const previewButton = (
        <Button
            style={ButtonStyles.TertiaryAlt}
            onClick={() => {
                window.open(
                    publication.getRoute(!isPublished),
                    AnchorTargetTypes.Blank
                );
            }}>
            {isPublished ? "View" : "Preview"}
        </Button>
    );

    const publicationReferenceButton = (
        <Button
            style={ButtonStyles.TertiaryAlt}
            onClick={handleNewPublicationReferenceJobClick}>
            Regenerate Reference Links
        </Button>
    );

    const publicationSearchIndexButton = (
        <Button
            onClick={createNewSearchIndexJob}
            style={ButtonStyles.TertiaryAlt}>
            Index for Search
        </Button>
    );

    const tableExtractionButton = extractTablesFromPublicationImport && (
        <Button
            onClick={handleNewTableExtractJobClick}
            style={ButtonStyles.TertiaryAlt}>
            Extract Tables
        </Button>
    );

    const legacyTableOfContentsExtractionButton = (
        <Button
            onClick={handleLegacyExtractTableOfContentsClick}
            style={ButtonStyles.TertiaryAlt}>
            Extract Table Of Contents
        </Button>
    );

    const customMenuItem = publication.getIsLegacy()
        ? [previewButton, legacyTableOfContentsExtractionButton]
        : [
              previewButton,
              publicationReferenceButton,
              publicationSearchIndexButton,
              tableExtractionButton,
          ];

    if (!publication.isPersisted()) {
        return (
            <div className="-no-publication">
                <Paragraph>Select a Publication on the Left to Edit</Paragraph>
            </div>
        );
    }

    const selectedPrimaryTopicId =
        selectedBookTopics.find((sbt) => sbt.isPrimary)?.topicId ??
        initialBookTopics.find((ibt) => ibt.isPrimary);
    const selectedSecondaryTopicIds = selectedBookTopics
        .filter((sbt) => !sbt.isPrimary)
        .map((e) => e.topicId.toString());
    return (
        <div className={CSS_CLASS_NAME}>
            <div
                className={`${CSS_CLASS_NAME}__form`}
                ref={formRef}
                tabIndex={0}>
                {importRunning && _renderInProgressBanner(jobs!, refresh)}
                {hasErrors && _renderErrorBanners(erroredJobs!)}

                <React.Fragment>
                    <InputFormField
                        disabled={true}
                        isValid={true}
                        label="Publication Title"
                        onChange={() => {}}
                        showCharacterCount={false}
                        value={publication.getDisplayTitle(true)}
                    />

                    {publication.contentSourceFile != null && (
                        <UploadedFileField
                            cssClassName="c-form-field c-file-upload-field"
                            file={publication.contentSourceFile}
                            includeDelete={false}
                            label={"Publication Data"}
                            loading={importRunning}
                            loadingMessage={"Import in progress..."}
                        />
                    )}

                    {!importRunning && (
                        <EnhancedContentMigrationField
                            cssClassName={`${CSS_CLASS_NAME}__form__migrate-enhanced-content`}
                            publication={publication}
                        />
                    )}

                    <TopicsSelects
                        errorMessage={primaryTopicErrorMessage}
                        handlePrimaryTopicChange={handlePrimaryTopicChange}
                        handleSecondaryTopicsChange={handleSecondaryTopicChange}
                        primaryTopicId={selectedPrimaryTopicId?.toString()}
                        secondaryTopicIds={selectedSecondaryTopicIds}
                    />

                    <CheckboxFormField
                        checked={isFeatured}
                        disabled={loading || booksLoading}
                        label={t("featuredBook-admin-checkbox")}
                        onChange={handleFeaturedBookChanges}
                    />

                    {prependLabelToSectionTitle && (
                        <CheckboxFormField
                            checked={publication.sectionTitleHasLabel ?? false}
                            disabled={loading || booksLoading}
                            label={t(
                                "publication-admin-sectionTitleHasLabel-checkbox"
                            )}
                            onChange={handleSectionTitleHasLabelChanged}
                        />
                    )}

                    <ColorSelect
                        enumObject={PublicationColor}
                        onChange={handleColorChanged}
                        type={ColorSelectType.Publication}
                        value={publication.getColorOrDefault()}
                    />

                    <FileUploadField
                        accept={MimeTypes.Image}
                        confirmRemove={true}
                        disabled={saving}
                        fileUploadDestination={
                            FileUploadDestination.PublicationCover
                        }
                        helpText={"Recommended image size is 410x550"}
                        label="Publication Cover Image"
                        onFileChanged={handleImageFileChanged}
                        uploadPathPrefix={publication!.id!.toString()}
                        value={publication.coverImageFile}
                    />
                    <div className={`${CSS_CLASS_NAME}__form__image-container`}>
                        {loaded && publication.coverImageFile != null && (
                            <RemoteImage file={publication.coverImageFile} />
                        )}
                    </div>
                </React.Fragment>
            </div>
            <div className={`${CSS_CLASS_NAME}__footer`}>
                <div className={`${CSS_CLASS_NAME}__footer__left`}>
                    <Button
                        disabled={
                            loading || saving || importRunning || hasErrors
                        }
                        onClick={handleSaveClick}>
                        Save Publication
                    </Button>
                </div>

                <div className={`${CSS_CLASS_NAME}__footer__right`}>
                    {
                        // if
                        loading && (
                            <Loader
                                accessibleText={"Saving publication..."}
                                size={LoaderSizes.Small}
                            />
                        )
                    }
                    {
                        // if
                        !loading && (
                            <PublishStatusMenu
                                customMenuItem={customMenuItem}
                                disabled={importRunning || hasErrors}
                                disabledComment={publishDisabledMessage}
                                hasDraftableFields={false}
                                isValueDirty={false}
                                onPublishClick={handlePublishClick}
                                onUnpublishClick={handleUnpublishClick}
                                recordLabel={"Publication"}
                                value={publication}
                            />
                        )
                    }
                </div>
            </div>
            <UnsavedChangesPrompt
                isDirty={isDirty}
                message={"Unsaved changes will be lost."}
            />
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Private Functions
// -----------------------------------------------------------------------------------------

const _renderInProgressBanner = (
    jobs: Array<JobRecord>,
    refresh: () => void
) => {
    const completeJobs = jobs?.filter(
        (job: JobRecord) => job.status === JobStatuses.Completed
    );

    return (
        <AlertBanner alertLevel={AlertLevels.Warning}>
            {`Import in progress... (${completeJobs?.length}/${jobs?.length} jobs complete, more may spawn) `}
            <Button onClick={refresh} size={ButtonSizes.XSmall}>
                Refresh
            </Button>
        </AlertBanner>
    );
};

const _renderErrorBanners = (jobs: Array<JobRecord>): React.ReactNode => (
    <React.Fragment>
        <AlertBanner alertLevel={AlertLevels.Error}>
            {jobs.length} {StringUtils.pluralize(jobs.length, "job")} failed to
            complete.
        </AlertBanner>
        {jobs.map((job: JobRecord) => {
            const { workerName: name, id, error } = job;
            const date = DateUtils.formatLastEditedDate(job);
            const errorHeader = `${name} (Id ${id}) failed on ${date} with error: `;
            return (
                <AlertBanner alertLevel={AlertLevels.Error}>
                    {errorHeader}
                    <br />
                    <br />
                    {error}
                </AlertBanner>
            );
        })}
    </React.Fragment>
);

// #endregion Private Functions

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default PublicationEditor;

// #endregion Exports
