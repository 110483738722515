import { SkipNavContent, SkipNavLink } from "@reach/skip-nav";
import logo from "assets/images/nfpa-link-color.png";
import Anchor from "atoms/anchors/anchor";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Paragraph from "atoms/typography/paragraph";
import { siteMap } from "internal-sitemap";
import UnauthenticatedFooter from "molecules/footer/unauthenticated-footer";
import LogInAnchor from "molecules/log-in-anchor/log-in-anchor";
import PlansAnchor from "molecules/plans-anchor/plans-anchor";
import ProfileMenu from "organisms/profile/profile-menu";
import * as React from "react";
import { useLocation } from "react-router-dom";
import useEmulationMode from "utilities/contexts/emulation/use-emulation-mode";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useLocalization } from "utilities/hooks/use-localization";
import { NestedRoutes } from "utilities/routing/nested-route";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS = "c-onboarding-layout";
const MENU_CLASS = "c-onboarding-layout__menu";
const CONTENT_CLASS = "c-onboarding-layout__content";

const EXCLUDE_SIGNUP_BUTTON_FROM_ROUTES = [
    siteMap.signup.register,
    siteMap.signup.plans,
];

// #endregion Constants
// -----------------------------------------------------------------------------------------
// #region Layout Component
// -----------------------------------------------------------------------------------------

const OnboardingLayout: React.FC<any> = (props: any) => {
    const { showActions = true } = props;
    // -----------------------------------------------------------------------------------------
    // #region Hooks
    // -----------------------------------------------------------------------------------------

    const { t } = useLocalization();
    const { globalState } = useGlobalState();
    const location = useLocation();

    const showSignUpButton = !EXCLUDE_SIGNUP_BUTTON_FROM_ROUTES.some(
        (route: string) => location.pathname.includes(route)
    );

    const { isInEmulationMode } = useEmulationMode();
    const className = isInEmulationMode
        ? `${BASE_CLASS} -emulation-mode`
        : BASE_CLASS;

    // -----------------------------------------------------------------------------------------
    // #region Returns
    // -----------------------------------------------------------------------------------------

    return (
        <React.Fragment>
            <SkipNavLink>{t("skipToMainContent")}</SkipNavLink>
            <div className={className}>
                <div className={MENU_CLASS}>
                    <div className={`${MENU_CLASS}__logo`}>
                        <Anchor to={siteMap.home} ariaLabel="Home">
                            <img src={logo} alt="Home" />
                        </Anchor>
                    </div>
                    {showActions && (
                        <div className={`${MENU_CLASS}__auth`}>
                            {globalState.isAuthenticated() ? (
                                <React.Fragment>
                                    <Paragraph
                                        cssClassName={`${MENU_CLASS}__auth__name`}
                                        size={ParagraphSizes.Small}>
                                        <span data-test-id={"signed-in-as"}>
                                            {`${t("signedInAs")} `}
                                        </span>
                                        <strong
                                            data-test-id={
                                                "signed-in-user-name"
                                            }>
                                            {globalState.currentIdentity?.user?.getFirstAndLastName()}
                                        </strong>
                                    </Paragraph>
                                    <ProfileMenu hideActions={true} />
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <LogInAnchor
                                        cssClassName={`c-button -medium`}
                                    />
                                    {showSignUpButton && (
                                        <PlansAnchor
                                            cssClassName={`${MENU_CLASS}__auth__link c-button -primary`}
                                        />
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                    )}
                </div>
                <div className={CONTENT_CLASS}>
                    <SkipNavContent tabIndex={-1}>
                        <NestedRoutes routes={props.routes} />
                        {props.children}
                    </SkipNavContent>
                </div>
                <UnauthenticatedFooter />
            </div>
        </React.Fragment>
    );

    // #endregion Returns
};

// #endregion Layout Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default OnboardingLayout;

// #endregion Exports
