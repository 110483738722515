import UserRecord from "models/view-models/user-record";
import { useMemo } from "react";
import UserConfigurationService from "utilities/services/user-configurations/user-configuration-service";
import UserLoginService from "utilities/services/user-logins/user-login-service";
import useUserRoleGroups from "./domain/user-role-groups/use-user-role-groups";
import useCurrentIdentity from "./use-current-identity";

export default function useTeams() {
    const { currentIdentity } = useCurrentIdentity();
    const { update: updateUserLoginApi } = UserLoginService.useUpdate();
    const { get: getUserConfigurationApi } = UserConfigurationService.useGet();

    const currentTeam = currentIdentity?.getCurrentTeam();

    const { resultObject: userRoleGroups } = useUserRoleGroups({
        groupId: currentTeam?.id,
        includeUserRoleAndUser: true,
    });

    const isMemberOfTeam = (groupId: string) => {
        return (
            currentIdentity &&
            currentIdentity
                ?.getActiveUserRoles()
                .findIndex(
                    (role) => role.userRoleGroup?.groupId.toString() === groupId
                ) !== -1
        );
    };

    const switchToDifferentTeam = async (groupId: string) => {
        const newUserRole = currentIdentity
            ?.getActiveUserRoles()
            .find((role) => role.userRoleGroup?.groupId.toString() === groupId);

        try {
            const dto = currentIdentity?.userLogin?.with({
                roleId: newUserRole?.roleId,
                userRoleId: newUserRole?.userRoleGroup?.userRoleId,
            });

            const userLoginResponse = await updateUserLoginApi(dto!);
            if (userLoginResponse.result?.hasErrors()) {
                return;
            }

            await getUserConfigurationApi({});
        } catch (error) {
            console.log(error);
        }
    };

    const teamAdmins: UserRecord[] = useMemo(() => {
        const allTeamMembers = userRoleGroups.map((userRoleGroup) => {
            return userRoleGroup.isAdmin
                ? userRoleGroup.userRole?.user
                : undefined;
        });
        return allTeamMembers.filter(
            (member) => member !== undefined
        ) as UserRecord[];
    }, [userRoleGroups]);

    return {
        currentTeam,
        switchToDifferentTeam,
        isMemberOfTeam,
        teamAdmins,
    };
}
