import { EnvironmentUtils } from "@rsm-hcd/javascript-core";
import { Delta, RangeStatic, Sources } from "quill";
import { Dispatch, RefObject, SetStateAction, useCallback } from "react";
import ReactQuill from "react-quill";
import { Quill } from "quill";
import { BrowserUtils } from "utilities/browser-utils";
import { CoreUtils } from "utilities/core-utils";
import ReferenceLink, {
    ReferenceLinkValue,
} from "utilities/quill/formats/reference-link";
import { ToastManager } from "utilities/toast/toast-manager";

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

/**
 * Custom hook to wrap up Quill event handlers.
 * @param closeImageModal a callback which closes the image modal
 * @param closeReferenceLinkModal a callback which closes the reference link modal
 * @param getCurrentIndex a function returning the current selection index, or the cached index
 * @param onChange triggered when the Quill editor contents change
 * @param onFocus triggered when the Quill editor area gains focus
 * @param quillRef the RefObject to ReactQuill with the Quill instance in it
 * @param setQuillSelectionIndex the Dispatch<SetStateAction<number>> to update the cached selection index
 */
export default function useQuillHandlers(
    closeImageModal: () => void,
    closeReferenceLinkModal: () => void,
    getCurrentIndex: (quill: Quill) => number,
    onChange: (newValue: string, rawValue: string) => void,
    onFocus: (() => void) | undefined,
    quillRef: RefObject<ReactQuill>,
    setQuillSelectionIndex: Dispatch<SetStateAction<number | undefined>>
) {
    const handleSelectionChange = async (range: RangeStatic) => {
        const index = range?.index;
        if (index == null) {
            return;
        }

        setQuillSelectionIndex(index);
    };

    /**
     * `editor` here is an instance of UnprivilegedEditor
     * from "react-quill", but we can't import it because
     * the types.d.ts file from react-quill doesn't export it
     * @see react-quill/types.d.ts
     */
    const handleChange = useCallback(
        (content: string, delta: Delta, source: Sources, editor: any) =>
            onChange(content, editor.getText()),
        [onChange]
    );

    const handleFocus = () => {
        onFocus?.();
        const quill: Quill | undefined = quillRef.current?.getEditor();
        if (quill == null) {
            return;
        }

        const index = getCurrentIndex(quill);
        restoreCursorPosition(quill, index);
    };

    const handleImageConfirmed = (src: string, alt: string) => {
        const quill: Quill | undefined = quillRef.current?.getEditor();
        if (quill == null) {
            EnvironmentUtils.runIfDevelopment(() =>
                console.error("Quill ref is null or undefined.")
            );
            ToastManager.error("There was an issue adding the image.");
            return;
        }

        const index = getCurrentIndex(quill);
        quill.removeFormat(index, 1, "api");
        quill.insertEmbed(index, "image", { src, alt }, "api");
        // on IE there's a format bug where the image pushes the cursor way outside
        // the actual editor area, we can fix it by programatically inserting
        // a newline character which should reset formatting
        if (BrowserUtils.isIE()) {
            quill.insertText(index + 1, "\n", "api");
        }
        onChange(quill.root.innerHTML, quill.getText());
        closeImageModal();

        restoreCursorPosition(quill, index);
    };

    const handleReferenceLinkConfirmed = (link: ReferenceLinkValue) => {
        const quill: Quill | undefined = quillRef.current?.getEditor();
        if (quill == null) {
            EnvironmentUtils.runIfDevelopment(() =>
                console.error("Quill ref is null or undefined.")
            );
            ToastManager.error("There was an issue adding the reference.");
            return;
        }

        const index = getCurrentIndex(quill);
        quill.insertEmbed(index, ReferenceLink.blotName, link, "user");
        restoreCursorPosition(quill, index + 1);

        onChange(quill.root.innerHTML, quill.getText());
        closeReferenceLinkModal();
    };

    return {
        handleChange,
        handleFocus,
        handleImageConfirmed,
        handleReferenceLinkConfirmed,
        handleSelectionChange,
    };
}

// #endregion Hook

// -------------------------------------------------------------------------------------------------
// #region Functions
// -------------------------------------------------------------------------------------------------

/**
 * Restore the cursor position back to where it was, plus 1,
 * to account for newly inserted blots
 * @param originalIndex the index at which a new blot was inserted
 * @param quill the editor instance
 */
const restoreCursorPosition = (quill: Quill, originalIndex: number) =>
    CoreUtils.setTimeoutPromise(() =>
        quill.setSelection({ index: originalIndex, length: 0 }, "api")
    );

// #endregion Functions
