import React from "react";
import Paragraph from "atoms/typography/paragraph";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import { t, tErrorLoading } from "utilities/localization-utils";
import VideoCard from "molecules/video-card/video-card";
import GettingStartedLink from "molecules/getting-started-link/getting-started-link";
import { Icons } from "atoms/constants/icons";
import { siteMap } from "internal-sitemap";
import { AnchorTargetTypes } from "@rsm-hcd/javascript-core";
import RemoteAccessDetailsService from "utilities/services/remote-access-details-service";
import FileUtils from "utilities/file-utils";
import { FileConstants } from "constants/file-constants";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import RemoteAccessDetailsRecord from "models/view-models/remote-access-details-record";
import StringUtils from "utilities/string-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface GettingStartedDashboardPanelProps {
    cssClassName: string;
}
// #endregion Interfaces

const GettingStartedDashboardPanel: React.FunctionComponent<
    GettingStartedDashboardPanelProps
> = (props: GettingStartedDashboardPanelProps) => {
    const { cssClassName } = props;
    const { globalState } = useGlobalState();
    const { dashboardVideo } = globalState?.getSystemSettings();
    const { get: getPdf } = RemoteAccessDetailsService.useGetQuery();
    const { isOnline } = useNetworkInformation();
    const relativeProviderPath = FileUtils.getLocalizedPath(
        FileConstants.GettingStarted,
        globalState.getPreferredOrCurrentCulture()
    );
    const getPdfEnabled =
        StringUtils.hasValue(relativeProviderPath) && isOnline;

    const getPdfResult = getPdf(
        undefined,
        {
            relativeProviderPath: relativeProviderPath,
        },
        { enabled: getPdfEnabled }
    );

    let remoteAccessDetails = new RemoteAccessDetailsRecord();

    if (getPdfResult.isSuccess) {
        remoteAccessDetails = remoteAccessDetails.with({
            ...getPdfResult.data.resultObject?.toJS(),
        });
    }

    if (getPdfResult.isError) {
        ToastManager.error(
            `${tErrorLoading("resourceType-File")} ${
                FileConstants.GettingStarted
            }`
        );
    }

    return (
        <div className={cssClassName}>
            <Paragraph cssClassName="-title" size={ParagraphSizes.Large}>
                {t("gettingStarted")}
                <sup>®</sup>
            </Paragraph>
            {isOnline && (
                <React.Fragment>
                    <VideoCard
                        videoId={dashboardVideo?.videoIdentifier}
                        videoThumbnailUrl={dashboardVideo?.thumbnailUrl}
                        videoTitle={dashboardVideo?.videoTitle}
                        videoDescription={
                            dashboardVideo?.videoDescription
                        }></VideoCard>
                    <GettingStartedLink
                        icon={Icons.Sitnav}
                        label={t("useNfpaDirect-link-label")}
                        to={siteMap.situationalNavigation.dashboard}>
                        {t("useNfpaDirect")}
                    </GettingStartedLink>
                    <GettingStartedLink
                        external={true}
                        icon={Icons.Document}
                        label={t("quickStartGuide-link-label")}
                        target={AnchorTargetTypes.Blank}
                        to={remoteAccessDetails.url}>
                        {t("viewItem", {
                            item: t("quickStartGuide"),
                        })}
                    </GettingStartedLink>
                </React.Fragment>
            )}
            <GettingStartedLink
                icon={Icons.Question}
                label={t("checkOutFaq-link-label")}
                to={siteMap.support.faq}>
                {t("checkOutFaq")}
            </GettingStartedLink>
        </div>
    );
};

export default GettingStartedDashboardPanel;
