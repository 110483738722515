import SelectSizes from "atoms/constants/select-sizes";
import MultiSelect, { MultiSelectProps } from "atoms/forms/multi-select";
import { SelectOption } from "atoms/forms/select";
import UserCollectionRecord from "models/view-models/user-collection-record";
import React, { useMemo } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import { useLocalization } from "utilities/hooks/use-localization";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface BookmarkCollectionMultiSelectProps
    extends Pick<MultiSelectProps, "menuPosition"> {
    allCollections: Array<UserCollectionRecord>;
    onChange: (newValues: Array<UserCollectionRecord>) => void;
    selectSize?: SelectSizes;
    value: Array<UserCollectionRecord>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const BookmarkCollectionMultiSelect: React.FC<
    BookmarkCollectionMultiSelectProps
> = (props: BookmarkCollectionMultiSelectProps) => {
    const CSS_CLASS_NAME = "c-bookmark-collections-multi-select";

    const { allCollections, onChange, selectSize, value } = props;
    const { t } = useLocalization();
    const collectionOptions = useMemo(
        () =>
            allCollections.map((c: UserCollectionRecord) => ({
                value: c.id!.toString(),
                label: c.name,
                data: c,
            })),
        [allCollections]
    );
    const selectedCollectionsValue = useMemo(
        () => value.map((c: UserCollectionRecord) => c.id!.toString()),
        [value]
    );

    const noOptionsMessage = useMemo(() => {
        if (CollectionUtils.isEmpty(allCollections)) {
            return t("noCollections");
        }

        return t("noOtherCollections");
    }, [allCollections, t]);

    const handleChange = (
        newValues: Array<SelectOption<UserCollectionRecord>>
    ) =>
        onChange(
            newValues.map((o: SelectOption<UserCollectionRecord>) => o.data!)
        );

    return (
        <div className={CSS_CLASS_NAME}>
            <MultiSelect
                isClearable={false}
                label={t("collection_plural")}
                labelHelpText={t("field-optional")}
                menuPosition={props.menuPosition}
                noOptionsMessage={noOptionsMessage}
                onChange={handleChange}
                options={collectionOptions}
                placeholder={`${t("select")}...`}
                size={selectSize}
                value={selectedCollectionsValue}
            />
            <label className="-collections-help">
                {t("collections-helpText")}
            </label>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default BookmarkCollectionMultiSelect;

// #endregion Exports
