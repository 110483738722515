import { Record } from "immutable";
import type SituationRelationship from "models/interfaces/situational-navigation/situations/situation-relationship";
import type { CSSProperties } from "react";
import NumberUtils from "utilities/number-utils";
import { RecordUtils } from "@rsm-hcd/javascript-core";

const defaultValues: SituationRelationship =
    RecordUtils.auditableDefaultValuesFactory({
        deletedDraftById: undefined,
        deletedDraftOn: undefined,
        displaySequence: 0,
        displaySequenceDraft: 0,
        hotSpotX: undefined,
        hotSpotXDraft: undefined,
        hotSpotY: undefined,
        hotSpotYDraft: undefined,
        publishedById: undefined,
        publishedOn: undefined,
        relatedSituationId: undefined,
        relatedSolutionId: undefined,
        situationId: 0,
    });

export default class SituationRelationshipRecord
    extends Record(defaultValues)
    implements SituationRelationship
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: SituationRelationship) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.hotSpotX != null) {
            params.hotSpotX = NumberUtils.parseInt(params.hotSpotX.toFixed(0));
        }

        if (params.hotSpotXDraft != null) {
            params.hotSpotXDraft = NumberUtils.parseInt(
                params.hotSpotXDraft.toFixed(0)
            );
        }

        if (params.hotSpotY != null) {
            params.hotSpotY = NumberUtils.parseInt(params.hotSpotY.toFixed(0));
        }

        if (params.hotSpotYDraft != null) {
            params.hotSpotYDraft = NumberUtils.parseInt(
                params.hotSpotYDraft.toFixed(0)
            );
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    public isDraftHotSpot(): boolean {
        return this.hotSpotXDraft != null && this.hotSpotY != null;
    }

    public isPublishedHotSpot(): boolean {
        return this.hotSpotX != null && this.hotSpotY != null;
    }

    public toPublishedInlineStyles(): CSSProperties {
        return {
            left: `${this.hotSpotX?.toString()}%`,
            top: `${this.hotSpotY?.toString()}%`,
        };
    }

    public toDraftInlineStyles(): CSSProperties {
        return {
            left: `${this.hotSpotXDraft?.toString()}%`,
            top: `${this.hotSpotYDraft?.toString()}%`,
        };
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<SituationRelationship>} values
     */
    public with(
        values: Partial<SituationRelationship>
    ): SituationRelationshipRecord {
        return new SituationRelationshipRecord(
            Object.assign(this.toJS(), values)
        );
    }

    // #endregion Public Methods
}
