import { RecordUtils, EnvironmentUtils } from "@rsm-hcd/javascript-core";
import { Record } from "immutable";
import UserSetting from "models/interfaces/user-setting";
import RoleRecord from "models/view-models/role-record";
import UserRecord from "models/view-models/user-record";
import StringUtils from "utilities/string-utils";
import NumberUtils from "utilities/number-utils";
import { UserSettingTypes } from "utilities/types/user-setting-types";

const defaultValues: UserSetting =
    RecordUtils.auditableDefaultValuesFactory<UserSetting>({
        key: "",
        role: undefined,
        roleId: 0,
        type: "",
        user: undefined,
        userId: undefined,
        value: "",
    });

export default class UserSettingRecord
    extends Record(defaultValues)
    implements UserSetting
{
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: UserSetting) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.role != null) {
            params.role = RecordUtils.ensureRecord(params.role, RoleRecord);
        }

        if (params.user != null) {
            params.user = RecordUtils.ensureRecord(params.user, UserRecord);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Returns the attempted json parsed value of the value property
     */
    public getValue<T extends UserSettingTypes>(): T | undefined {
        if (StringUtils.isEmpty(this.value)) {
            return undefined;
        }

        // Override generic typing if the underlying type is 'String' - JSON.parse will likely throw
        // an error if this method is accidentally called
        if (StringUtils.isEqual(this.type, "string")) {
            return this.value as any;
        }

        try {
            return JSON.parse(this.sanitizeValue()) as T;
        } catch (error) {
            EnvironmentUtils.runIfDevelopment(() => {
                console.warn(
                    `UserSettingRecord.getValue failed to parse '${this.value}': \n\n${error}`
                );
            });
        }

        return undefined;
    }

    /**
     * Returns true for default user settings.
     *
     * These are user settings where a userId is not set.
     */
    public isDefault(): boolean {
        return NumberUtils.isDefault(this.userId);
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<UserSetting>} values
     */
    public with(values: Partial<UserSetting>): UserSettingRecord {
        return new UserSettingRecord(Object.assign(this.toJS(), values));
    }

    /**
     * Adds Role relationship to UserSetting from a list of Roles.
     *
     * @param {Array<RoleRecord>} [roles]
     */
    public withRole(roles?: Array<RoleRecord>): UserSettingRecord {
        if (roles == null) {
            return this.with({});
        }

        var role = roles.find((role: RoleRecord) => role.id === this.roleId);

        return this.with({ role: role });
    }

    // #endregion Public Methods

    // -----------------------------------------------------------------------------------------
    // #region Private Methods
    // -----------------------------------------------------------------------------------------

    private sanitizeValue(): string {
        return this.value
            ?.replace(/[tT][rR][uU][eE]/g, "true")
            ?.replace(/[fF][aA][lL][sS][eE]/g, "false");
    }

    // #endregion Private Methods
}
