import React from "react";
import Modal from "molecules/modals/modal";
import { ModalTypes } from "molecules/constants/modal-types";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { ButtonSizes } from "atoms/constants/button-sizes";
import Icon from "atoms/icons/icon";
import { Icons } from "atoms/constants/icons";
import { IconSizes } from "atoms/constants/icon-sizes";
import Paragraph from "atoms/typography/paragraph";
import { RouteUtils } from "utilities/route-utils";
import { siteMap } from "internal-sitemap";
import { SupportConstants } from "constants/phone-number-constants";
import Anchor from "atoms/anchors/anchor";
import { AnchorTargetTypes } from "@rsm-hcd/javascript-core";
import ForgotPasswordButton from "molecules/forgot-password-button/forgot-password-button";
import { t } from "utilities/localization-utils";
import Tooltip from "molecules/tooltips/tooltip";
import useConfigurableAlertMessages from "utilities/hooks/use-configurable-alert-messages";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface AccountLoginIssuesModalProps {
    closeDialog: () => void;
    isVisible: boolean;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASS = "c-account-login-issues-modal";
const modalLabel = "Account and Login Issues";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const AccountLoginIssuesModal: React.FC<AccountLoginIssuesModalProps> = (
    props
) => {
    const { closeDialog, isVisible } = props;
    const isMobile = window.innerWidth < Breakpoints.Phone;
    const transitionEffect = isMobile
        ? ModalTransitions.SlideUp
        : ModalTransitions.Fade;
    const { subscriptionFreezeActive, alertMessageContents } =
        useConfigurableAlertMessages();

    return (
        <Modal
            cssClassName={CSS_BASE_CLASS}
            closeDialog={closeDialog}
            isVisible={isVisible}
            label={modalLabel}
            transition={transitionEffect}
            type={ModalTypes.Base}>
            <div className={`${CSS_BASE_CLASS}__header`}>
                <h2>{modalLabel}</h2>
                <Button
                    style={ButtonStyles.Tertiary}
                    size={ButtonSizes.Medium}
                    onClick={closeDialog}
                    cssClassName={"-modal-close -icon"}
                    accessibleText="Close Dialog">
                    <Icon type={Icons.Close} size={IconSizes.Large} />
                </Button>
            </div>
            <div className={`${CSS_BASE_CLASS}__content`}>
                <Paragraph>
                    In order to access NFPA LiNK&reg; you must have an NFPA
                    account.
                </Paragraph>
                <Paragraph>
                    <span
                        className={`${CSS_BASE_CLASS}__content__paragraph__intro`}>
                        If you already have an NFPA account
                    </span>
                    and would like to purchase NFPA LiNK,{" "}
                    <Anchor
                        external={true}
                        target={AnchorTargetTypes.Blank}
                        to={RouteUtils.getUrl(siteMap.home)}>
                        sign in
                    </Anchor>{" "}
                    using your NFPA login credentials and select a plan. Forget
                    your NFPA password?{" "}
                    <ForgotPasswordButton
                        cssClassName={"-anchor"}
                        buttonText={`${t("resetItHere")}.`}
                    />
                </Paragraph>
                <Paragraph>
                    <span
                        className={`${CSS_BASE_CLASS}__content__paragraph__intro`}>
                        If you need to create an NFPA account,
                    </span>
                    select the{" "}
                    <Tooltip
                        content={alertMessageContents?.tooltip}
                        disabled={!subscriptionFreezeActive}>
                        <Anchor
                            disabled={subscriptionFreezeActive}
                            external={true}
                            target={AnchorTargetTypes.Blank}
                            to={RouteUtils.getUrl(siteMap.signup.plans)}>
                            sign-up
                        </Anchor>
                    </Tooltip>{" "}
                    option and you will be prompted to register a new account
                    once you’ve selected a plan.
                </Paragraph>
                <Paragraph>
                    <span
                        className={`${CSS_BASE_CLASS}__content__paragraph__intro`}>
                        If you are still experiencing problems,
                    </span>
                    please email us at{" "}
                    <a href={`mailto:${SupportConstants.CustomerServiceEmail}`}>
                        {SupportConstants.CustomerServiceEmail}
                    </a>
                    , or call us at {SupportConstants.UsPhoneNumber}; if you are
                    calling from outside of the U.S. or Canada, dial{" "}
                    {SupportConstants.InternationalPhoneNumber}. Our customer
                    support team is available to assist you{" "}
                    {SupportConstants.NonAbbreviatedBusinessHours}.
                </Paragraph>
                <div className={`${CSS_BASE_CLASS}__footer`}>
                    <Button
                        accessibleText={"Close Dialog"}
                        style={ButtonStyles.Secondary}
                        onClick={closeDialog}>
                        Close
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default AccountLoginIssuesModal;

// #endregion Export
