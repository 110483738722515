import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import AlertLevels from "constants/alert-levels";
import AnnouncementRecord from "models/view-models/announcement-record";
import AlertBanner from "molecules/alerts/alert-banner";
import MenuButton from "molecules/menu-button/menu-button";
import { ConfirmationModal } from "molecules/modals/confirmation-modal";
import PublishStatusBadge from "organisms/admin/situational-navigation/publish-status-badge";
import AnnouncementModal from "organisms/announcements/announcement-modal";
import React, { useState } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import StringUtils from "utilities/string-utils";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-announcement-publish-status-menu";
const publishConfirmButtonText = `Yes, Publish`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AnnouncementPublishStatusMenuProps {
    announcement: AnnouncementRecord;
    isDirty?: boolean;
    onDeleteClick?: () => void;
    onPublishClick: (withAlert?: boolean) => void;
    onUnpublishClick?: () => void;
    recordLabel: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AnnouncementPublishStatusMenu: React.FC<
    AnnouncementPublishStatusMenuProps
> = (props: AnnouncementPublishStatusMenuProps) => {
    const { announcement, isDirty } = props;

    const modalClass = `${CSS_CLASS_NAME}-confirm-modal`;

    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------

    const { globalState } = useGlobalState();
    const [confirmPublishModalOpen, setConfirmPublishModalOpen] =
        useState(false);
    const [confirmPublishAsNewModalOpen, setConfirmPublishAsNewModalOpen] =
        useState(false);
    const [confirmUnpublishModalOpen, setConfirmUnpublishModalOpen] =
        useState(false);
    const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
    const [previewModalOpen, setPreviewModalOpen] = useState(false);

    // #endregion State

    // -----------------------------------------------------------------------------------------
    // #region Computations
    // -----------------------------------------------------------------------------------------

    const canDelete = announcement.id != null && props.onDeleteClick != null;

    const hasPublishingRole =
        globalState.currentIdentity!.isPublisher() ||
        globalState.currentIdentity!.isAdmin();

    const isPublished = StringUtils.hasValue(announcement.publishedOn);

    const canPublish =
        (announcement.hasUnpublishedChanges() || !isPublished) &&
        hasPublishingRole;

    const canUnpublish =
        props.onUnpublishClick != null && isPublished && hasPublishingRole;

    const disablePublishing = !announcement.isPersisted() || isDirty;

    const previewAnnouncement = announcement.with({
        body: announcement.bodyDraft,
        title: announcement.titleDraft,
    });

    const publishText = `Publish${
        announcement.hasUnpublishedChanges() ? " Changes" : ""
    }`;

    const menuItems = buildMenuItems();

    // #endregion Computations

    // -----------------------------------------------------------------------------------------
    // #region Event Handlers
    // -----------------------------------------------------------------------------------------

    const handleCloseAnnouncementModal = () => {
        setPreviewModalOpen(false);
    };

    const handleDeleteConfirm = () => {
        props.onDeleteClick?.();
        setConfirmDeleteModalOpen(false);
    };

    const handlePublishConfirm = () => {
        props.onPublishClick();
        setConfirmPublishModalOpen(false);
    };
    const handlePublishAsNewConfirm = () => {
        props.onPublishClick(true);
        setConfirmPublishAsNewModalOpen(false);
    };

    const handleUnpublishConfirm = () => {
        props.onUnpublishClick?.();
        setConfirmUnpublishModalOpen(false);
    };

    // #endregion Event Handlers

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    const publishConfirmModalText = (
        <React.Fragment>
            {`Are you sure you want to publish${
                announcement.hasUnpublishedChanges() ? " changes" : ""
            }?`}
            {announcement.hasUnpublishedChanges() && (
                <AlertBanner alertLevel={AlertLevels.Warning}>
                    <strong>Note: </strong>Publishing DOES NOT reset the
                    announcement. If a user has already seen the announcement
                    they will not see the announcement again. Use the "Publish
                    as New Announcement" option to display the announcement to
                    all users.
                </AlertBanner>
            )}
        </React.Fragment>
    );

    const publishAsNewConfirmModalText = (
        <React.Fragment>
            {`Are you sure you want to publish as a new announcement?`}
            <AlertBanner alertLevel={AlertLevels.Warning}>
                <strong>Note: </strong>Publishing as a new announcement will
                reset the announcement and display to all users.
            </AlertBanner>
        </React.Fragment>
    );

    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__right`}>
                <PublishStatusBadge
                    hasDraftableFields={true}
                    hasUnpublishedChanges={announcement.hasUnpublishedChanges()}
                    status={announcement.getPublishStatus()}
                />
                {CollectionUtils.hasValues(
                    React.Children.toArray(menuItems)
                ) && (
                    <MenuButton
                        buttonAccessibleText="Publishing Options"
                        cssClassName={`-items-${menuItems.length}`}>
                        {buildMenuItems()}
                    </MenuButton>
                )}
            </div>
            <ConfirmationModal
                confirmButtonStyle={ButtonStyles.Primary}
                confirmButtonText={publishConfirmButtonText}
                cssClassName={modalClass}
                isVisible={confirmPublishModalOpen}
                label={`Confirm ${publishText}`}
                message={publishConfirmModalText}
                onCancel={() => setConfirmPublishModalOpen(false)}
                onConfirm={handlePublishConfirm}
            />
            <ConfirmationModal
                confirmButtonStyle={ButtonStyles.Primary}
                confirmButtonText={publishConfirmButtonText}
                cssClassName={modalClass}
                isVisible={confirmPublishAsNewModalOpen}
                label="Confirm Publish as New Announcement"
                message={publishAsNewConfirmModalText}
                onCancel={() => setConfirmPublishAsNewModalOpen(false)}
                onConfirm={handlePublishAsNewConfirm}
            />
            <ConfirmationModal
                confirmButtonStyle={ButtonStyles.Primary}
                confirmButtonText="Yes, Unpublish"
                cssClassName={modalClass}
                isVisible={confirmUnpublishModalOpen}
                label="Confirm Unpublish"
                message={`Are you sure you want to unpublish this ${props.recordLabel.toLowerCase()}?`}
                onCancel={() => setConfirmUnpublishModalOpen(false)}
                onConfirm={handleUnpublishConfirm}
            />
            <ConfirmationModal
                confirmButtonText="Yes, Delete"
                cssClassName={modalClass}
                isVisible={confirmDeleteModalOpen}
                message={`Are you sure you want to delete this ${props.recordLabel.toLowerCase()}?`}
                onCancel={() => setConfirmDeleteModalOpen(false)}
                onConfirm={handleDeleteConfirm}
            />
            <AnnouncementModal
                announcement={previewAnnouncement}
                isVisible={previewModalOpen}
                onClose={handleCloseAnnouncementModal}
            />
        </div>
    );

    // #endregion Render

    // -----------------------------------------------------------------------------------------
    // #region Private Methods
    // -----------------------------------------------------------------------------------------

    function buildMenuItems(): React.ReactNodeArray {
        if (announcement?.id == null) {
            return [...getPreviewMenuItem()];
        }

        return [
            ...getPublishMenuItem(),
            ...getPreviewMenuItem(),
            ...getUnpublishMenuItem(),
            ...getDeleteMenuItem(),
            ...getPublishAsNewMenuItem(),
        ];
    }

    function getDeleteMenuItem(): React.ReactNodeArray {
        if (canDelete) {
            return [
                <Button
                    accessibleText={"Delete"}
                    key="delete-menu-item"
                    onClick={() => setConfirmDeleteModalOpen(true)}>
                    Delete
                </Button>,
            ];
        }

        return [];
    }

    function getPreviewMenuItem(): React.ReactNodeArray {
        return [
            <Button
                accessibleText={"Preview"}
                key="preview-menu-item"
                onClick={() => setPreviewModalOpen(true)}>
                Preview
            </Button>,
        ];
    }

    function getPublishAsNewMenuItem(): React.ReactNodeArray {
        return [
            <Button
                accessibleText="Publish As New Announcement"
                cssClassName="-as-new-announcement"
                disabled={disablePublishing}
                title={
                    disablePublishing ? "You have unsaved changes" : undefined
                }
                key="publish-with-alert-menu-item"
                onClick={() => {
                    setConfirmPublishAsNewModalOpen(true);
                }}>
                {"Publish As New Announcement"}
            </Button>,
        ];
    }

    function getPublishMenuItem(): React.ReactNodeArray {
        if (canPublish) {
            return [
                <Button
                    accessibleText={publishText}
                    cssClassName="-bold"
                    disabled={disablePublishing}
                    title={
                        disablePublishing
                            ? "You have unsaved changes"
                            : undefined
                    }
                    key="publish-menu-item"
                    onClick={() => setConfirmPublishModalOpen(true)}>
                    {publishText}
                </Button>,
            ];
        }

        return [];
    }

    function getUnpublishMenuItem(): React.ReactNodeArray {
        if (canUnpublish) {
            return [
                <Button
                    accessibleText={"Unpublish"}
                    key="unpublish-menu-item"
                    onClick={() => setConfirmUnpublishModalOpen(true)}>
                    Unpublish
                </Button>,
            ];
        }

        return [];
    }

    // #endregion Private Methods
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default AnnouncementPublishStatusMenu;

// #endregion Exports
