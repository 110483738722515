import SelectSizes from "atoms/constants/select-sizes";
import { SelectOption } from "atoms/forms/select";
import React from "react";
import Select, {
    OptionProps,
    ValueContainerProps,
    components,
} from "react-select";
import { CustomColorUtils } from "utilities/custom-color-utils";
import { useLocalization } from "utilities/hooks/use-localization";
import StringUtils from "utilities/string-utils";
import { CustomColor } from "utilities/types/custom-color";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ColorSelectProps {
    /**
     * Enum containing string keys & numeric values of the colors they represent. See `UserBookmarkColors`
     * as an example. This is used to dynamically build out the list of SelectOptions for the dropdown.
     */
    enumObject: CustomColor;

    /**
     * Event handler for updating a parent component when the value of this select component changes.
     */
    onChange: (newValue: number) => void;

    /**
     * Represents the 'size' of the select used, ie 'small', 'base', etc.
     */
    selectSize?: SelectSizes;

    /**
     * Represents the 'type' of color this picker should pull colors from, ie 'bookmark', 'publication', etc.
     */
    type?: ColorSelectType;

    /**
     * Represents the current color value of the select dropdown. If no value is passed in, defaults
     * to the first select option.
     */
    value?: number;
}

/**
 * Wrapper interface for a SelectOption used in the context of this component
 *
 * @interface ColorSelectOption
 * @extends {SelectOption<undefined, number>}
 */
export interface ColorSelectOption extends SelectOption<undefined, number> {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-color-select";

// -----------------------------------------------------------------------------------------
// #region Variables
// -----------------------------------------------------------------------------------------

let selectType: ColorSelectType | undefined = undefined;
let selectEnum: CustomColor | undefined = undefined;

// #endregion Variables

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Enums
// -----------------------------------------------------------------------------------------

export enum ColorSelectType {
    Bookmark = "bookmark",
    Publication = "publication",
}

// #endregion Enums

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ColorSelect: React.FC<ColorSelectProps> = (props: ColorSelectProps) => {
    const { enumObject, value, onChange, selectSize } = props;
    const options = CustomColorUtils.buildSelectOptions(enumObject);
    const type = props.type ?? ColorSelectType.Bookmark;
    const size = selectSize ?? SelectSizes.Base;
    selectType = type;
    selectEnum = enumObject;

    const { t } = useLocalization();

    return (
        <div className={`${CSS_CLASS_NAME} -${type} ${size}`}>
            <label>{t("color")}</label>
            <Select
                classNamePrefix={`${CSS_CLASS_NAME}__select`}
                components={{
                    Option: CustomOptionComponent,
                    ValueContainer: CustomValueContainerComponent,
                }}
                isSearchable={false}
                aria-label={t("color")}
                onChange={(selectedOption) =>
                    handleChange(onChange, selectedOption)
                }
                options={options}
                value={getSelectOption(options, value)}
            />
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

const getSelectOption = (
    selectOptions: ColorSelectOption[],
    defaultValue?: number
): ColorSelectOption => {
    if (defaultValue == null) {
        return selectOptions[0];
    }

    const selectOption = selectOptions.find(
        (selectOption: ColorSelectOption) => selectOption.value === defaultValue
    );

    if (selectOption == null) {
        return selectOptions[0];
    }

    return selectOption;
};

const getOptionClassName = (
    appendedClassName: string,
    enumObject: CustomColor,
    type: ColorSelectType,
    value: number
): string => `${CSS_CLASS_NAME}${appendedClassName}
    ${CustomColorUtils.getClassModifier(enumObject, value, type)}`;

const handleChange = (
    handleChange: (newValue: number) => void,
    selectedOption
) => {
    handleChange((selectedOption as ColorSelectOption).value);
};

const CustomOptionComponent: React.FunctionComponent<
    OptionProps<ColorSelectOption>
> = (props: OptionProps<ColorSelectOption>) => {
    const { data, innerRef } = props;
    if (StringUtils.isEmpty(data?.label)) {
        return null;
    }

    return (
        <components.Option
            {...props}
            className={`${CSS_CLASS_NAME}__option`}
            innerRef={innerRef}>
            <div
                className={getOptionClassName(
                    "__option__color",
                    selectEnum!,
                    selectType!,
                    data.value
                )}
            />
            {data.label}
        </components.Option>
    );
};

const CustomValueContainerComponent = (
    props: ValueContainerProps<ColorSelectOption>
) => {
    const { children, selectProps } = props;
    const option: ColorSelectOption = selectProps?.value as ColorSelectOption;
    const value = option.value;
    return (
        <components.ValueContainer {...props}>
            <div
                className={getOptionClassName(
                    "__selected-color",
                    selectEnum!,
                    selectType!,
                    value
                )}
            />
            {children}
        </components.ValueContainer>
    );
};
// #endregion Functions

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default ColorSelect;

// #endregion Exports
