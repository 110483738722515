import { useEffect, useRef } from "react";
import { ServiceWorkerMessageMap } from "utilities/service-worker/interfaces/messages/service-worker-message-map";
import moment from "moment";
import useServiceWorker from "utilities/contexts/service-worker/use-service-worker";
import { ServiceWorkerCommandTypes } from "utilities/service-worker/constants/service-worker-command-types";
import { v4 } from "uuid";

/**
 * Pass a callback to respond to a ServiceWorker message of the given messageType
 * @param messageType
 * @param onEvent
 */
export default function useOnServiceWorkerMessageEvent<
    T extends keyof ServiceWorkerMessageMap,
>(messageType: T, onEvent: (event: ServiceWorkerMessageMap[T]) => void): void {
    const keepAliveTimeout = useRef(moment());
    const { sendCommand } = useServiceWorker();
    useEffect(() => {
        const clientServiceWorker = navigator?.serviceWorker;

        if (clientServiceWorker == null) {
            return;
        }

        const event: (event: ServiceWorkerMessageMap[T]) => void = (
            event: ServiceWorkerMessageMap[T]
        ) => {
            if (event.data.type !== messageType) {
                return;
            }

            onEvent(event);

            if (moment().diff(keepAliveTimeout.current, "seconds") >= 10) {
                sendCommand({
                    type: ServiceWorkerCommandTypes.KeepAlive,
                    token: v4(),
                });
                keepAliveTimeout.current = moment();
            }
        };

        clientServiceWorker.addEventListener(
            "message",
            event as EventListenerOrEventListenerObject
        );

        return function cancel() {
            clientServiceWorker.removeEventListener(
                "message",
                event as EventListenerOrEventListenerObject
            );
        };
    }, [messageType, onEvent, sendCommand]);
}
