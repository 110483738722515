import TableRecord from "models/view-models/table-record";
import { useCallback, useEffect } from "react";
import UserBookmarkType from "utilities/enumerations/user-bookmark-type";
import useServiceHookResultState from "utilities/hooks/use-service-hook-result-state";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import NumberUtils from "utilities/number-utils";
import TableService from "utilities/services/publications/table-service";
import StringUtils from "utilities/string-utils";

export default function useTableBookmark(
    publicationId: number | undefined,
    externalId: string,
    code: string | undefined,
    edition: string | undefined,
    type: UserBookmarkType
): ServiceHookResult<TableRecord> {
    const { result, setError, setInitial, setLoading, setSuccess } =
        useServiceHookResultState<TableRecord>(new TableRecord());

    const { get: getTableApi } = TableService.useGetByExternalId();

    const loadTable = useCallback(
        async (
            publicationId: number | undefined,
            externalId: string | undefined,
            code: string | undefined,
            edition: string | undefined
        ) => {
            if (
                type !== UserBookmarkType.Table ||
                NumberUtils.isDefault(publicationId) ||
                StringUtils.isEmpty(externalId) ||
                StringUtils.isEmpty(code) ||
                StringUtils.isEmpty(edition)
            ) {
                setInitial();
                return;
            }

            setLoading();

            try {
                const { resultObject: table } = await getTableApi(
                    {
                        publicationId,
                        externalId,
                    },
                    { code, edition }
                );

                if (table != null) {
                    setSuccess(table);
                }
            } catch (error) {
                setError(error);
            }
        },
        [getTableApi, setError, setInitial, setLoading, setSuccess, type]
    );

    const refresh = useCallback(() => {
        loadTable(publicationId, externalId, code, edition);
    }, [code, edition, externalId, loadTable, publicationId]);

    useEffect(() => {
        loadTable(publicationId, externalId, code, edition);
    }, [code, edition, externalId, loadTable, publicationId]);

    return { ...result, refresh };
}
