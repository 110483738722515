import { NfpaUrlPaths } from "constants/nfpa-urls/nfpa-url-paths";
import { Record as ImmutableRecord } from "immutable";
import type SystemSettings from "models/interfaces/system-settings";
import { siteMap } from "internal-sitemap";
import { RouteUtils } from "utilities/route-utils";
import StringUtils from "utilities/string-utils";
import type { MarketingQueryParams } from "utilities/hooks/use-marketing-query-params";
import type { EcommerceParams } from "interfaces/routing/ecommerce-params";
import { RecordUtils } from "@rsm-hcd/javascript-core";
import DashboardVideoRecord from "models/view-models/dashboard-video-record";
import OrderCloudParams from "models/interfaces/order-cloud-params";
import NewEcommerceParams from "models/interfaces/new-ecommerce-params";
import SubscriptionType from "organisms/enums/subscription-type";
import { NfpaUrlPathsV2 } from "constants/nfpa-urls/nfpa-url-paths-v2";
import { t } from "utilities/localization-utils";

const defaultValues: SystemSettings =
    RecordUtils.defaultValuesFactory<SystemSettings>({
        configurableAlertMessages: undefined,
        dashboardVideo: undefined,
        databaseName: undefined,
        ecommerceBaseUrl: undefined,
        ecommerceNewUrl: undefined,
        ecommercePrices: undefined,
        environmentName: undefined,
        featureFlags: undefined,
        groupInvitiationsBaseUrl: undefined,
        privacyPolicyCookieName: "",
        machineName: undefined,
        maintenanceMode: undefined,
        nfpaBaseUrl: undefined,
        reCaptchaKeyName: undefined,
        reCaptchaOrgId: undefined,
        reCaptchaSiteKey: undefined,
        salesforceReturnUrlBase: undefined,
        salesforceWebToLeadRecordType: undefined,
        salesforceWebToLeadUrl: undefined,
        subscriptionContentSettings: undefined,
        subscriptionExpirationErrorWindowInDays: undefined,
        subscriptionExpirationWarningWindowInDays: undefined,
        termsAndConditions: undefined,
        version: "",
    });

export default class SystemSettingsRecord
    extends ImmutableRecord(defaultValues)
    implements SystemSettings
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: SystemSettings) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.dashboardVideo != null) {
            params.dashboardVideo = RecordUtils.ensureRecord(
                params.dashboardVideo,
                DashboardVideoRecord
            );
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Utility method for returning the E-commerce url for checkout
     */
    public getEcommerceCheckoutUrl(
        pin: string,
        marketingQueryParams?: MarketingQueryParams,
        newEcommerceParams?: OrderCloudParams,
        useNewEcommerce: boolean = false
    ): string {
        // Update OrderCloudParams and general structure of this method once real parameters are known
        if (useNewEcommerce) {
            const redirectUrl = new URL("https://www.nfpa.org");
            for (const property in newEcommerceParams) {
                redirectUrl.searchParams.append(
                    `${property}`,
                    `${newEcommerceParams[property as keyof OrderCloudParams]}`
                );
            }
            return redirectUrl.href;
        } else {
            if (
                StringUtils.isEmpty(this.ecommerceBaseUrl) ||
                StringUtils.isEmpty(pin)
            ) {
                return "";
            }

            const ecommerceParams: EcommerceParams = {
                addtobasket: pin,
                clearbasket: true,
                refresh: true,
            };

            const ecommerceCheckoutUrl = RouteUtils.appendQueryParams(
                `${this.ecommerceBaseUrl}/Basket.aspx`,
                Object.assign({}, ecommerceParams, marketingQueryParams)
            );

            return ecommerceCheckoutUrl;
        }
    }

    private getNewEcommerceUrlBase(selectedSubscriptionType: SubscriptionType) {
        if (selectedSubscriptionType === SubscriptionType.Individual)
            return `${this.ecommerceNewUrl}/product/nfpa-link-reg-individual-plan/linksingle`;
        if (selectedSubscriptionType === SubscriptionType.Team)
            return `${this.ecommerceNewUrl}/product/link-team/plinkteam`;
        throw new Error("something went wrong");
    }

    public getNewEcommerceUrl(
        selectedSubscriptionType: SubscriptionType,
        newEcommerceParams: NewEcommerceParams
    ) {
        const redirectUrl = this.getNewEcommerceUrlBase(
            selectedSubscriptionType
        );
        const properties = Object.keys(newEcommerceParams);

        const searchParams = properties.reduce((prev, current, index) => {
            return `${prev}${current}=${newEcommerceParams[current]}${
                index === properties.length - 1 ? "" : "&"
            }`;
        }, "");
        return encodeURI(`${redirectUrl}?${searchParams}`);
    }
    /**
     * Utility method for returning an external Nfpa Url
     */
    public getNfpaUrl(path: NfpaUrlPaths): string {
        const baseUrl = this.nfpaBaseUrl;

        if (StringUtils.isEmpty(baseUrl) || StringUtils.isEmpty(path)) {
            return "";
        }

        return `${baseUrl}/${path}`;
    }

    public getNfpaUrlV2(path: NfpaUrlPathsV2) {
        const baseUrl = this.ecommerceNewUrl;

        if (StringUtils.isEmpty(baseUrl) || StringUtils.isEmpty(path))
            return "";

        return `${baseUrl}/${t("urlLanguagePath")}/${path}`;
    }

    /**
     * Utility method for returning the Salesforce cancellation form return url
     * When submitting a form to Salesforce, a return url is required to redirect
     * user back to LiNK.
     */
    public getSalesforceCancellationReturnUrl(): string {
        if (StringUtils.isEmpty(this.salesforceReturnUrlBase)) {
            return "";
        }

        return RouteUtils.getUrl(
            `${this.salesforceReturnUrlBase}${siteMap.account.subscription}`,
            undefined,
            { fromSalesforce: true }
        );
    }

    /**
     * Get top features from the subscription content settings
     *
     * @return {*}  {string[]}
     */
    public getSubscriptionTopFeatures(cultureCode: string = "en-US"): string[] {
        return this.subscriptionContentSettings?.topFeatures[cultureCode] ?? [];
    }

    /**
     * Utility method for returning the underlying termsAndConditions.content (if both are present)
     * If either are null/empty, it returns an empty string.
     */
    public getTermsAndConditionsContent(): string {
        if (
            !this.hasTermsAndConditions() ||
            this.termsAndConditions!.content == null
        ) {
            return "";
        }

        return this.termsAndConditions!.content;
    }

    /**
     * Returns whether or not the termsAndConditions field is non-null
     */
    public hasTermsAndConditions(): boolean {
        return this.termsAndConditions != null;
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<SystemSettings>} values
     */
    public with(values: Partial<SystemSettings>): SystemSettingsRecord {
        return new SystemSettingsRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}
