import { PagedQuery } from "@rsm-hcd/javascript-core";
import type UserRoleGroupRecord from "models/view-models/user-role-group-record";
import type UserRoleRecord from "models/view-models/user-role-record";
import { useCallback, useEffect, useState } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import usePageErrors from "utilities/hooks/use-page-errors";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import NumberUtils from "utilities/number-utils";
import UserRoleGroupService from "utilities/services/user-role-groups/user-role-group-service";
import UserRoleService from "utilities/services/user-roles/user-role-service";
import UserService from "utilities/services/users/user-service";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface UseUserRoleGroupsOptions extends PagedQuery {
    groupId?: string | number;
    includeUserRoleAndUser?: boolean;
    searchText?: string;
}

interface UseUserRoleGroupsResult
    extends ServiceHookResult<UserRoleGroupRecord[]> {
    /**
     * Function for the consumer to call to trigger a refresh of the data gathered by this hook
     */
    refresh: () => void;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const defaultOptionValues: UseUserRoleGroupsOptions = {
    groupId: undefined,
    includeUserRoleAndUser: false,
    searchText: undefined,
    skip: undefined,
    take: undefined,
};

// #endregion Constants
/**
 * Custom hook for retrieving UserRoleGroups, with the option to include nested data.
 *
 * @param groupId Id of the Group to retrieve UserRoleGroups by.
 * @param includeUserRoleAndUser Flag to determine whether or not UserRoles and Users should be
 * included on the UserRoleGroupRecords. Requires two additional API calls.
 */
export default function useUserRoleGroups(
    options: UseUserRoleGroupsOptions
): UseUserRoleGroupsResult {
    const { groupId, includeUserRoleAndUser, searchText, skip, take } =
        Object.assign({}, defaultOptionValues, options);
    const [userRoleGroups, setUserRoleGroups] = useState<UserRoleGroupRecord[]>(
        []
    );
    const [userRoleGroupsRowCount, setUserRoleGroupsRowCount] =
        useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [refreshFlag, setRefreshFlag] = useState<boolean>(false);

    const refresh = useCallback(() => {
        setRefreshFlag((refreshFlag: boolean) => !refreshFlag);
    }, [setRefreshFlag]);

    const { list: listUsersApi } = UserService.useList();
    const { list: listUserRolesApi } = UserRoleService.useList();
    const { list: listUserRoleGroupsApi } = UserRoleGroupService.useList();
    const { handlePageLoadError, pageErrors: errors } = usePageErrors();

    const listUserRoles = useCallback(
        async (ids: number[]): Promise<UserRoleRecord[]> => {
            if (CollectionUtils.isEmpty(ids)) {
                return [];
            }

            try {
                const response = await listUserRolesApi({
                    ids: ids,
                });
                return response.resultObjects;
            } catch (error) {
                handlePageLoadError(error);
            }

            return [];
        },
        [handlePageLoadError, listUserRolesApi]
    );

    const buildIncludes = useCallback(
        async (
            userRoleIds: number[],
            userRoleGroups: UserRoleGroupRecord[]
        ): Promise<UserRoleGroupRecord[]> => {
            try {
                const userRoles = await listUserRoles(userRoleIds);
                if (CollectionUtils.isEmpty(userRoles)) {
                    return [];
                }

                const userIds = userRoles.map((urg) => urg.userId);
                const response = await listUsersApi({
                    ids: userIds,
                });

                const users = response.resultObjects;
                if (CollectionUtils.isEmpty(users)) {
                    return userRoleGroups;
                }

                const userRoleGroupsWithIncludes = userRoleGroups.map((urg) => {
                    const userRole = userRoles.find(
                        (ur) => ur.id === urg.userRoleId
                    );
                    const user = users.find((u) => u.id === userRole?.userId);

                    return urg.with({
                        userRole: userRole?.with({ user: user }),
                    });
                });

                return userRoleGroupsWithIncludes;
            } catch (error) {
                handlePageLoadError(error);
            }

            return [];
        },
        [handlePageLoadError, listUsersApi, listUserRoles]
    );

    const listUserRoleGroups = useCallback(
        async (
            groupId?: number,
            searchText?: string,
            skip?: number,
            take?: number
        ) => {
            if (groupId == null) {
                return;
            }

            try {
                setLoading(true);
                const response = await listUserRoleGroupsApi({
                    groupId: groupId,
                    searchText: searchText,
                    skip: skip,
                    take: take,
                });
                let records = response.resultObjects;
                setUserRoleGroups(records);
                setUserRoleGroupsRowCount(response.rowCount);

                if (!includeUserRoleAndUser) {
                    setLoaded(true);
                    setLoading(false);
                    return;
                }

                const recordsWithIncludes = await buildIncludes(
                    records.map((r) => r.userRoleId),
                    records
                );
                setUserRoleGroups(recordsWithIncludes);
            } catch (error) {
                handlePageLoadError(error);
            }

            setLoaded(true);
            setLoading(false);
        },
        [
            buildIncludes,
            handlePageLoadError,
            includeUserRoleAndUser,
            listUserRoleGroupsApi,
        ]
    );

    useEffect(() => {
        listUserRoleGroups(
            NumberUtils.parseInt(groupId),
            searchText,
            skip,
            take
        );
    }, [groupId, listUserRoleGroups, refreshFlag, searchText, skip, take]);

    return {
        errors,
        loaded,
        loading,
        refresh,
        resultObject: userRoleGroups,
        rowCount: userRoleGroupsRowCount,
    };
}
