import SelectSizes from "atoms/constants/select-sizes";
import Select, { SelectOption, SelectStyles } from "atoms/forms/select";
import PageNavigation, {
    PageNavigationProps,
} from "molecules/page-navigation/page-navigation";
import * as React from "react";
import { useLocalization } from "utilities/hooks/use-localization";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface PageNavigationMenuProps extends PageNavigationProps {
    disableAll?: boolean;
    id?: string;
    onPageSizeChange?: (selectedOption?: SelectOption<number, number>) => void;
    resultTotal: number;
    pageSize: number;
    pageSizes?: Array<number>;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const DEFAULT_PAGE_SIZES = [10, 20, 50, 100];

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const PageNavigationMenu: React.FunctionComponent<PageNavigationMenuProps> = (
    props
) => {
    const {
        currentPage,
        disableAll = false,
        id,
        numberOfPages,
        onNavigateBack,
        onNavigateForward,
        onPageSizeChange,
        onSelectPage,
        pageSize,
        pageSizes,
        resultTotal,
    } = props;

    const pagerProps: PageNavigationProps = {
        currentPage,
        numberOfPages,
        onNavigateBack,
        onNavigateForward,
        onSelectPage,
    };
    const cssBaseClassName = "c-page-navigation-menu";
    const pageSizeOptions = pageSizes ?? DEFAULT_PAGE_SIZES;

    const { t } = useLocalization();

    const startIndex = (currentPage - 1) * pageSize + 1;
    const endIndex = Math.min(startIndex + pageSize - 1, resultTotal);

    const pageSizeSelectOptions =
        pageSizeOptions?.map((pageSize) => {
            return {
                data: pageSize,
                label: pageSize.toString(),
                value: pageSize,
            } as SelectOption<number, number>;
        }) ?? [];

    return (
        <div className={cssBaseClassName} id={id}>
            {
                // if
                onPageSizeChange != null && (
                    <div className={`${cssBaseClassName}__max-items`}>
                        <span>{t("resultsPerPage")}</span>
                        <Select
                            disabled={disableAll}
                            label={t("pageNavigation-pageSize-label")}
                            onChange={onPageSizeChange}
                            options={pageSizeSelectOptions}
                            size={SelectSizes.Small}
                            style={SelectStyles.Button}
                            value={pageSize}
                        />
                    </div>
                )
            }
            <div className={`${cssBaseClassName}__page-actions`}>
                <div className={`${cssBaseClassName}__pages`}>
                    {resultTotal > 0 && (
                        <React.Fragment>
                            <span>
                                {t("pageNavigation-results-label", {
                                    startIndex: startIndex,
                                    endIndex: endIndex,
                                    resultTotal: resultTotal,
                                })}
                            </span>
                        </React.Fragment>
                    )}
                    {resultTotal <= 0 && (
                        <span>{t("pageNavigation-noResults-label")}</span>
                    )}
                </div>
                <PageNavigation {...pagerProps} />
            </div>
        </div>
    );
};

// #endregion Component

export default PageNavigationMenu;
