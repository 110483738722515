import { Record } from "immutable";
import File from "models/interfaces/file";
import PathUtils from "utilities/path-utils";
import ResourceType from "utilities/enumerations/resource-type";
import { t } from "utilities/localization-utils";
import CultureResources from "utilities/interfaces/culture-resources";
import { RecordUtils } from "@rsm-hcd/javascript-core";

const defaultValues: File = RecordUtils.auditableDefaultValuesFactory<File>({
    presignedUrl: undefined, // virtual property for caching purposes
    relativeProviderPath: "",
    storageContainer: undefined,
    suggestedCode: undefined,
    suggestedEdition: undefined,
    suggestedLocale: undefined,
});

export default class FileRecord extends Record(defaultValues) implements File {
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: File) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    public fileName(): string {
        return PathUtils.relativePathToFilename(this.relativeProviderPath);
    }

    /**
     * Return the resource type associated with the file extension
     * for the file path, for example, .docx => "Document", .jpg => "Image"
     */
    public resourceType(): ResourceType {
        return PathUtils.resourceTypeFromPath(this.relativeProviderPath);
    }

    /**
     * Return the localized string representation of the `ResourceType`
     */
    public resourceDisplayType(): string {
        return t(
            `resourceType-${this.resourceType()}` as keyof CultureResources
        );
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<File>} values
     */
    public with(values: Partial<File>): FileRecord {
        return new FileRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}
