import TableOfContentsRecord from "models/view-models/table-of-contents/table-of-contents-record";
import { useCallback, useMemo, useState } from "react";
import TableOfContentsService from "utilities/services/publications/table-of-contents/table-of-contents-service";
import PublicTableOfContentsService from "utilities/services/public/publications/table-of-contents-service";
import NumberUtils from "utilities/number-utils";
import { ResultRecord } from "@rsm-hcd/javascript-core";
import { ToastManager } from "utilities/toast/toast-manager";
import { atom, useAtom } from "jotai";
import ArticleTableOfContentsRecord from "models/view-models/table-of-contents/article-table-of-contents-record";
import AnnexTableOfContentsRecord from "models/view-models/table-of-contents/annex-table-of-contents-record";
import SectionTableOfContentsRecord from "models/view-models/table-of-contents/section-table-of-contents-record";
import { SectionParentType } from "utilities/enumerations/section-parent-type";
import SectionRecord from "models/view-models/section-record";
import { ArticlePartsService } from "internal";

const TableOfContentsAtom = atom<TableOfContentsRecord>(
    new TableOfContentsRecord()
);

const LoadingAtom = atom<boolean>(false);

export default function useBookviewTableOfContents(isPublic: boolean = false) {
    const [tableOfContents, setTableOfContents] = useAtom(TableOfContentsAtom);
    const [loading, setLoading] = useAtom(LoadingAtom);
    const [articlesLoading, setArticlesLoading] = useState(false);

    const { get: getTableOfContentsApi } = TableOfContentsService.useGet();
    const { getArticleSections } = TableOfContentsService;
    const { get: getPublicTableOfContentsApi } =
        PublicTableOfContentsService.useGet();

    const { list: getPartsApi } = ArticlePartsService.default.useList();

    const get = useMemo(
        () => (isPublic ? getPublicTableOfContentsApi : getTableOfContentsApi),
        [getPublicTableOfContentsApi, getTableOfContentsApi, isPublic]
    );

    const getTableOfContents = useCallback(
        async (publicationId: number | undefined) => {
            if (NumberUtils.isDefault(publicationId)) return;
            setLoading(true);
            try {
                const response = await get({ publicationId });

                setTableOfContents(
                    response.resultObject ?? new TableOfContentsRecord()
                );
            } catch (error) {
                if (error instanceof ResultRecord) {
                    ToastManager.error(error.listErrorMessages);
                }
            } finally {
                setLoading(false);
            }
        },
        [get, setTableOfContents, setLoading]
    );

    const getTableOfContentsArticleSection = async (
        publicationId: number,
        articleId: number | undefined,
        chapterId: number | undefined
    ) => {
        if (
            NumberUtils.isDefault(articleId) ||
            NumberUtils.isDefault(publicationId) ||
            NumberUtils.isDefault(chapterId) ||
            articlesLoading ||
            tableOfContents.chapters === undefined
        ) {
            return;
        }
        setArticlesLoading(true);

        try {
            const response = await getArticleSections({
                articleId,
                publicationId,
            });

            const findArticle = (articleId: number) =>
                tableOfContents.chapters
                    ?.find((c) => c.articles?.some((a) => a.id === articleId))
                    ?.articles?.find((a) => a.id === articleId);
            const article = findArticle(articleId);

            const hasParts =
                findPartIdsInArticleSections(response.resultObjects ?? [])
                    ?.length > 0;

            const partsResponse = hasParts
                ? await getPartsApi({
                      publicationId,
                      chapterId,
                      articleId,
                  })
                : null;

            const updatedArticle = article?.with({
                sections: response.resultObjects,
                parts: partsResponse?.resultObjects ?? [],
            });

            replaceArticle(updatedArticle, tableOfContents);
        } catch (error) {
            if (error instanceof ResultRecord) {
                ToastManager.error(error.listErrorMessages);
            }
            console.log(error);
        } finally {
            setArticlesLoading(false);
        }
    };

    // Returns list of parts needed for Parts service
    const findPartIdsInArticleSections = (
        sections: SectionTableOfContentsRecord[]
    ) => {
        return sections.reduce((accumulator: number[], currentValue) => {
            if (
                typeof currentValue.partId === "number" &&
                !accumulator.includes(currentValue.partId)
            ) {
                accumulator.push(currentValue.partId);
            }
            return accumulator;
        }, []);
    };

    const findSection = (
        id: number,
        type: SectionParentType,
        toc: TableOfContentsRecord
    ) => {
        switch (type) {
            case SectionParentType.Annex:
                return findAnnexSection(id, toc);
            case SectionParentType.Article:
                return findArticleSection(id, toc);
            case SectionParentType.Chapter:
                return findChapterSection(id, toc);
            default:
                return;
        }
    };

    const findAnnexSection = (id: number, toc: TableOfContentsRecord) => {
        const annex = toc.annexes?.find((a) =>
            a.sections?.some((s) => s.id === id)
        );

        const section = annex?.sections?.find((s) => s.id === id);

        return section;
    };

    const findArticleSection = (id: number, toc: TableOfContentsRecord) => {
        const section = toc.chapters
            ?.find((c) =>
                c.articles?.find((a) => a.sections?.some((s) => s.id === id))
            )
            ?.articles?.find((a) => a.sections?.some((s) => s.id === id))
            ?.sections?.find((s) => s.id === id);

        return section;
    };

    const findChapterSection = (id: number, toc: TableOfContentsRecord) => {
        const chapter = toc.chapters?.find((c) =>
            c.sections?.some((s) => s.id === id)
        );

        const section = chapter?.sections?.find((s) => s.id === id);

        return section;
    };

    const replaceAnnex = (
        annex: AnnexTableOfContentsRecord,
        toc: TableOfContentsRecord
    ) => {
        const { annexes } = toc;

        const newAnnexes = annexes?.map((a) => (a.id === annex.id ? annex : a));

        const newTOC = toc.with({ annexes: newAnnexes });
        setTableOfContents(newTOC);
    };

    const replaceArticle = (
        article: ArticleTableOfContentsRecord | undefined,
        toc: TableOfContentsRecord
    ) => {
        if (article == null) return;

        const { annexes, chapters, publicationMetadata } = toc;
        const chapter = chapters?.find((c) => c.id === article.chapterId);

        const newArticles = chapter?.articles?.map((a) =>
            a.id === article.id ? article : a
        );

        const newChapters = chapters?.map((c) =>
            c.id === chapter?.id ? c.with({ articles: newArticles }) : c
        );

        const newStuff = new TableOfContentsRecord({
            annexes,
            chapters: newChapters,
            publicationMetadata,
        });

        setTableOfContents(newStuff);
    };

    const replaceSection = (
        section: SectionTableOfContentsRecord,
        toc: TableOfContentsRecord,
        type: SectionParentType
    ) => {
        switch (type) {
            case SectionParentType.Annex:
                return replaceAnnexSection(section, toc);
            case SectionParentType.Article:
                return replaceArticleSection(section, toc);
            case SectionParentType.Chapter:
                return replaceChapterSection(section, toc);
            default:
                return;
        }
    };

    const replaceAnnexSection = (
        section: SectionTableOfContentsRecord,
        toc: TableOfContentsRecord
    ) => {
        const { annexes } = toc;
        const annex = annexes?.find((a) =>
            a.sections?.some((s) => s.id === section.id)
        );

        if (annex == null) return;

        const newSections = annex.sections?.map((s) =>
            s.id === section.id ? section : s
        );

        const newAnnex = annex.with({
            sections: newSections,
            hasBookmark: newSections?.some((s) => s.hasBookmark),
        });

        replaceAnnex(newAnnex, toc);
    };

    const replaceArticleSection = (
        section: SectionTableOfContentsRecord,
        toc: TableOfContentsRecord
    ) => {
        const article = toc.chapters
            ?.find((c) =>
                c.articles?.some((a) =>
                    a.sections?.some((s) => s.id === section.id)
                )
            )
            ?.articles?.find((a) =>
                a.sections?.some((s) => s.id === section.id)
            );

        if (article == null) return;

        const newSections = article.sections?.map((s) =>
            s.id === section.id ? section : s
        );

        const newArticle = article.with({
            sections: newSections,
            hasBookmark: newSections?.some((s) => s.hasBookmark),
        });

        replaceArticle(newArticle, toc);
    };

    const replaceChapterSection = (
        section: SectionTableOfContentsRecord,
        toc: TableOfContentsRecord
    ) => {
        const { chapters } = toc;
        const chapter = chapters?.find((c) =>
            c.sections?.some((s) => s.id === section.id)
        );

        if (chapter == null) return;

        const newSections = chapter.sections?.map((s) =>
            s.id === section.id ? section : s
        );

        const newChapter = chapter.with({ sections: newSections });
        const newChapters = chapters?.map((c) =>
            c.id === newChapter.id ? newChapter : c
        );

        const newTOC = toc.with({ chapters: newChapters });
        setTableOfContents(newTOC);
    };

    const updateBookmarkIndicator = (
        hasBookmark: boolean,
        toc: TableOfContentsRecord,
        section?: SectionRecord
    ) => {
        if (section == null) return;

        const isAnnexGroup = section.annexId != null && section.groupBy != null;

        if (isAnnexGroup) {
            const annex = toc.annexes?.find((a) => a.id === section.annexId);
            if (annex == null) return;

            const newAnnexGroups = annex?.annexGroups.map((ag) =>
                ag.groupBy === section.groupBy ? ag.with({ hasBookmark }) : ag
            );

            const newAnnex = annex?.with({ annexGroups: newAnnexGroups });
            return replaceAnnex(newAnnex, toc);
        }

        // section is a subsection
        let id = section.rootSectionId;

        // section is root section
        if (id == null) id = section.id;

        if (id == null) return;

        const isAnnex = section.annexId != null;
        const isArticle = section.articleId != null;
        const isChapter = section.chapterId != null;

        let parentType: SectionParentType | null = null;
        if (isAnnex) parentType = SectionParentType.Annex;
        if (isArticle) parentType = SectionParentType.Article;
        if (isChapter && !isArticle) parentType = SectionParentType.Chapter;

        if (parentType === null) return;

        const sectionTOC = findSection(id, parentType, toc);
        if (sectionTOC === undefined) return;

        const newSectionTOC = sectionTOC.with({ hasBookmark });
        replaceSection(newSectionTOC, toc, parentType);
    };

    return {
        findSection,
        getTableOfContents,
        getTableOfContentsArticleSection,
        loading,
        articlesLoading,
        tableOfContents,
        replaceAnnex,
        replaceArticle,
        replaceSection,
        setTableOfContents,
        updateBookmarkIndicator,
    };
}
