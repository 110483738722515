import Button, { ButtonTypes } from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import React, { ReactNode } from "react";
import { useLocalization } from "utilities/hooks/use-localization";
import StringUtils from "utilities/string-utils";
import uuid from "uuid";

interface FilterLabelProps {
    /**
     * Array of filters to be displayed
     * @example `filtered by "A" and "B"
     */
    filters: string[];
    /**
     * Singular name of the items in the results
     * @example "Result"
     */
    itemName: string;
    numberOfResults: number;
    /**
     * Button onclick reference
     */
    onClear: () => void;
}

const FilterLabel: React.FunctionComponent<FilterLabelProps> = (
    props: FilterLabelProps
) => {
    const { filters, numberOfResults, onClear } = props;

    const { t } = useLocalization();

    const filterItems = filters
        .filter((s) => StringUtils.hasValue(s))
        .map((filter) => <strong key={uuid.v4()}>{filter}</strong>);

    let filterMessage: ReactNode[] = [];

    for (let i = 0; i < filterItems.length; i++) {
        if (i === filterItems.length - 1 && filterItems.length > 1) {
            filterMessage.push(`${t("and")} `);
        }

        filterMessage.push(filterItems[i]);

        if (i < filterItems.length - 1) {
            if (filterItems.length > 2) {
                filterMessage.push(", ");
                continue;
            }

            filterMessage.push(" ");
        }
    }

    const renderFilterLabel = () =>
        `${t("filterLabelWithCount_plural", {
            count: numberOfResults,
        })} `;

    return (
        <div className="c-filter-label">
            <label>
                {renderFilterLabel()}
                {filterMessage}
            </label>
            <Button
                onClick={onClear}
                style={ButtonStyles.Anchor}
                type={ButtonTypes.Button}>
                {t("filterLabel-clearWithCount", { count: filters.length })}
            </Button>
        </div>
    );
};

export default FilterLabel;
