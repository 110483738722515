import { Quill } from "react-quill";
import StringUtils from "utilities/string-utils";
import { AnchorTargetTypes } from "@rsm-hcd/javascript-core";

const Embed = Quill.import("blots/embed");

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const DATA_ENTITY_ID_ATTR = "data-entity-id";
const DATA_ENTITY_TYPE_ATTR = "data-entity-type";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ReferenceLinkValue {
    text: string;
    href: string;
    [DATA_ENTITY_ID_ATTR]: string;
    [DATA_ENTITY_TYPE_ATTR]: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Format Class Definition
// -------------------------------------------------------------------------------------------------

export default class ReferenceLink extends Embed {
    // -------------------------------------------------------------------------------------------------
    // #region Public Properties
    // -------------------------------------------------------------------------------------------------

    public static readonly blotName = "referenceLink";
    public static readonly DATA_ENTITY_ID_ATTR = DATA_ENTITY_ID_ATTR;
    public static readonly DATA_ENTITY_TYPE_ATTR = DATA_ENTITY_TYPE_ATTR;
    public static readonly tagName = "A";

    // #endregion Public Properties

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    public static create(value: ReferenceLinkValue): Element | undefined {
        const node = super.create(value);
        ReferenceLink.setDomNodeAttributes(node, value);
        return node;
    }

    public static formats(domNode: Element) {
        return {
            text: domNode.innerHTML,
            href: domNode.getAttribute("href"),
            [ReferenceLink.DATA_ENTITY_ID_ATTR]: domNode.getAttribute(
                ReferenceLink.DATA_ENTITY_ID_ATTR
            ),
            [ReferenceLink.DATA_ENTITY_TYPE_ATTR]: domNode.getAttribute(
                ReferenceLink.DATA_ENTITY_TYPE_ATTR
            ),
        };
    }

    public format(name: string, value: ReferenceLinkValue) {
        if (name !== ReferenceLink.blotName || value == null) {
            super.format(name, value);
            return;
        }

        ReferenceLink.setDomNodeAttributes(this.domNode, value);
    }

    public static value(node: Element) {
        return ReferenceLink.formats(node);
    }

    // #endregion Public Methods

    // -------------------------------------------------------------------------------------------------
    // #region Private Methods
    // -------------------------------------------------------------------------------------------------

    private static setDomNodeAttributes(
        domNode: Element,
        value: ReferenceLinkValue
    ) {
        domNode.setAttribute("href", value.href);
        domNode.setAttribute("title", value.text);
        domNode.innerHTML = value.text;

        if (
            StringUtils.isEmpty(value[ReferenceLink.DATA_ENTITY_ID_ATTR]) ||
            StringUtils.isEmpty(value[ReferenceLink.DATA_ENTITY_TYPE_ATTR])
        ) {
            domNode.setAttribute("target", AnchorTargetTypes.Blank);
            domNode.setAttribute("rel", "noopener noreferrer");
            return;
        }

        domNode.setAttribute(
            ReferenceLink.DATA_ENTITY_ID_ATTR,
            value[ReferenceLink.DATA_ENTITY_ID_ATTR]
        );
        domNode.setAttribute(
            ReferenceLink.DATA_ENTITY_TYPE_ATTR,
            value[ReferenceLink.DATA_ENTITY_TYPE_ATTR]
        );
    }

    // #endregion Private Methods
}

// #endregion Format Class Definition
