import UserCollectionBookmarkRecord from "models/view-models/user-collection-bookmark-record";
import { PagedQuery } from "@rsm-hcd/javascript-core";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface UserCollectionBookmarkQueryParams extends PagedQuery {
    bookmarkId?: number;
    bookmarkIds?: Array<number>;
    collectionId?: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = "usercollectionbookmarks";
const resourceEndpoint = `${baseEndpoint}/:id`;
const resourceType = UserCollectionBookmarkRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const UserCollectionBookmarkService = {
    create: ServiceFactory.create<UserCollectionBookmarkRecord>(
        resourceType,
        baseEndpoint
    ),
    useCreate: ServiceHookFactory.useCreate<UserCollectionBookmarkRecord>(
        resourceType,
        baseEndpoint
    ),
    delete: ServiceFactory.delete(resourceEndpoint),
    useDelete: ServiceHookFactory.useDelete(resourceEndpoint),
    list: ServiceFactory.list<
        UserCollectionBookmarkRecord,
        UserCollectionBookmarkQueryParams
    >(resourceType, baseEndpoint),
    useList: ServiceHookFactory.useList<
        UserCollectionBookmarkRecord,
        UserCollectionBookmarkQueryParams
    >(resourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default UserCollectionBookmarkService;

// #endregion Exports
