import EnhancedContentResourceRecord from "models/view-models/enhanced-content-resource-record";
import { PagedQuery } from "@rsm-hcd/javascript-core";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface EnhancedContentResourcePathParams {
    enhancedContentId: number;
    id?: number;
}

export interface EnhancedContentResourceQueryParams extends PagedQuery {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = "admin/enhanced-content/:enhancedContentId/resources";
const resourceType = EnhancedContentResourceRecord;
const key = "admin-enhanced-content-resources";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const AdminEnhancedContentResourceService = {
    /**
     * EnhancedContenteResource Service implementation of create
     */
    create: ServiceFactory.nestedCreate<
        EnhancedContentResourceRecord,
        EnhancedContentResourcePathParams
    >(resourceType, baseEndpoint),

    /**
     * Hook Wrapper for the create function for EnhancedContentResources
     */
    useCreate: ServiceHookFactory.useNestedCreate<
        EnhancedContentResourceRecord,
        EnhancedContentResourcePathParams
    >(resourceType, baseEndpoint),

    /**
     * EnhancedContenteResource Service implementation of delete
     */
    delete: ServiceFactory.delete(`${baseEndpoint}/:id`),

    /**
     * Hook wrapper function for the delete function on EnhancedContentResources
     */
    useDelete: ServiceHookFactory.useDelete(`${baseEndpoint}/:id`),

    /**
     * EnhancedContenteResource Service implementation of nested List
     */
    list: ServiceFactory.nestedList<
        EnhancedContentResourceRecord,
        EnhancedContentResourcePathParams,
        EnhancedContentResourceQueryParams
    >(resourceType, baseEndpoint),
    /**
     * Hook wrapper function for the nestedList function on EnhancedContentResources
     */
    useList: ServiceHookFactory.useNestedList<
        EnhancedContentResourceRecord,
        EnhancedContentResourcePathParams,
        EnhancedContentResourceQueryParams
    >(resourceType, baseEndpoint),

    useListQuery: QueryHookFactory.useNestedListQuery<
        EnhancedContentResourceRecord,
        EnhancedContentResourcePathParams,
        EnhancedContentResourceQueryParams
    >(key, resourceType, baseEndpoint),

    /**
     * EnhancedContentResource Service implementation of update
     */
    update: ServiceFactory.update<
        EnhancedContentResourceRecord,
        EnhancedContentResourcePathParams
    >(resourceType, `${baseEndpoint}/:id`),

    /**
     * Hook wrapper function for the update function on EnhancedContentResources
     */
    useUpdate: ServiceHookFactory.useUpdate<
        EnhancedContentResourceRecord,
        EnhancedContentResourcePathParams
    >(resourceType, `${baseEndpoint}/:id`),

    useGetQuery: QueryHookFactory.useNestedListQuery<
        EnhancedContentResourceRecord,
        EnhancedContentResourcePathParams,
        EnhancedContentResourceQueryParams
    >(key, resourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default AdminEnhancedContentResourceService;

// #endregion Exports
