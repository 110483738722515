import Anchor from "atoms/anchors/anchor";
import React from "react";
import { useLocalization } from "utilities/hooks/use-localization";

interface SituationalNavigationResultsToolbarProps {
    cssClassName: string;
    isFilled: boolean;
    numberOfResults: Number;
    viewResultsRoute: string;
}

const SituationalNavigationToolbar: React.FunctionComponent<
    SituationalNavigationResultsToolbarProps
> = (props: SituationalNavigationResultsToolbarProps) => {
    const {
        cssClassName,
        isFilled: hasMadeAllPossibleSelections,
        numberOfResults,
        viewResultsRoute,
    } = props;

    const { t } = useLocalization();
    const resultsMessage = t("result", { count: numberOfResults });
    const actionView = t("view");

    return (
        <div
            className={`${cssClassName} ${
                hasMadeAllPossibleSelections ? "-filled" : ""
            }`}>
            <div className={`${cssClassName}__left`}>{resultsMessage}</div>
            <div className={`${cssClassName}__right`}>
                <Anchor to={viewResultsRoute}>{actionView}</Anchor>
            </div>
        </div>
    );
};

export default SituationalNavigationToolbar;
