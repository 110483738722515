import { RecordUtils } from "@rsm-hcd/javascript-core";
import PublicationTypes from "constants/publication-types";
import { UnicodeCharacterConstants } from "constants/unicode-character-constants";
import { Record } from "immutable";
import {
    AnnexParams,
    AnnexPermalinkParams,
    AnnexPreviewParams,
} from "interfaces/routing/route-params";
import { siteMap } from "internal-sitemap";
import AnnexTableOfContents from "models/interfaces/table-of-contents/annex-table-of-contents";
import { CollectionUtils } from "utilities/collection-utils";
import { t } from "utilities/localization-utils";
import { RouteUtils } from "utilities/route-utils";
import StringUtils from "utilities/string-utils";
import AnnexGroupTableOfContentsRecord from "./annex-group-table-of-contents-record";
import SectionTableOfContentsRecord from "./section-table-of-contents-record";

const defaultValues: AnnexTableOfContents =
    RecordUtils.defaultValuesFactory<AnnexTableOfContents>({
        id: undefined,
        annexGroups: [],
        displaySequence: undefined,
        externalId: "",
        hasBookmark: false,
        label: "",
        nfpaLabel: "",
        sections: undefined,
        title: "",
    });

export default class AnnexTableOfContentsRecord
    extends Record(defaultValues)
    implements AnnexTableOfContents
{
    constructor(params?: AnnexTableOfContents) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (CollectionUtils.hasValues(params.annexGroups)) {
            params.annexGroups = RecordUtils.ensureRecords(
                params.annexGroups,
                AnnexGroupTableOfContentsRecord
            );
        }

        if (CollectionUtils.hasValues(params.sections)) {
            params.sections = RecordUtils.ensureRecords(
                params.sections,
                SectionTableOfContentsRecord
            );
        }

        super(params);
    }

    /**
     * @example Informative Annex A (NEC)
     * @example Annex A (NFC)
     */
    public getDisplayLabel(
        type: PublicationTypes = PublicationTypes.NEC,
        locale?: string
    ) {
        if (StringUtils.hasValue(this.label)) {
            return StringUtils.translateDomainTerms(this.label, locale);
        }

        const label =
            type === PublicationTypes.NEC ? t("informativeAnnex") : t("annex");

        return `${label} ${this.nfpaLabel}`;
    }

    /**
     * @example Informative Annex A - Safety Standards (NEC)
     * @example Annex A - Safety Standards (NFC)
     */
    public getDisplayTitle(locale?: string) {
        if (StringUtils.isEmpty(this.title)) return "";

        const label = this.getDisplayLabel();

        const title = StringUtils.hasValue(label)
            ? `${label} ${UnicodeCharacterConstants.EmDash} ${this.title}`
            : this.title;

        return StringUtils.translateDomainTerms(title, locale);
    }

    public getPluralLabel(type?: PublicationTypes) {
        return type === PublicationTypes.NEC
            ? t("informativeAnnex_plural")
            : t("annex_plural");
    }

    public getRoute(params: AnnexParams, isAdmin?: boolean) {
        return isAdmin
            ? this.getPreviewRoute(params)
            : this.getPermalinkRoute(params);
    }

    public getTitleWithoutLabel(
        type: PublicationTypes = PublicationTypes.NEC,
        locale?: string
    ) {
        return (
            this.title?.replace(this.getDisplayLabel(type, locale), "") ?? ""
        );
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<AnnexTableOfContentsRecord>} values
     */
    public with(
        values: Partial<AnnexTableOfContents>
    ): AnnexTableOfContentsRecord {
        return new AnnexTableOfContentsRecord(
            Object.assign(this.toJS(), values) as AnnexTableOfContentsRecord
        );
    }

    private getPermalinkRoute(params: AnnexPermalinkParams) {
        if (params.code == null || params.edition == null) return "#";

        return RouteUtils.getUrl(siteMap.publications.permalinks.annex, {
            code: params.code,
            edition: params.edition,
            nfpaLabel: this.nfpaLabel,
        });
    }

    private getPreviewRoute(params: AnnexPreviewParams) {
        return RouteUtils.getUrl(siteMap.publications.adminPreview.annex, {
            publicationId: params?.publicationId ?? params.id,
            id: this.id,
        });
    }
}
