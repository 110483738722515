import type ReferencePanelContext from "models/interfaces/reference-panel-context";
import { Record, List } from "immutable";
import UserReferenceRecord from "models/view-models/user-reference-record";
import { RecordUtils } from "@rsm-hcd/javascript-core";
import SectionRecord from "models/view-models/section-record";
import type ServiceHookResult from "utilities/interfaces/service-hook-result";

const defaultValues: ReferencePanelContext =
    RecordUtils.defaultValuesFactory<ReferencePanelContext>({
        currentSection: undefined,
        currentSectionId: undefined,
        currentUserReferenceId: undefined,
        errors: undefined,
        isOpen: false,
        loaded: false,
        loading: true,
        userReferences: List<UserReferenceRecord>(),
    });

export default class ReferencePanelContextRecord
    extends Record(defaultValues)
    implements ReferencePanelContext
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: ReferencePanelContext) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.currentSection != null) {
            params.currentSection = RecordUtils.ensureRecord(
                params.currentSection,
                SectionRecord
            );
        }

        if (params.userReferences != null) {
            params.userReferences = List(
                params.userReferences?.map(
                    (userReference: UserReferenceRecord) =>
                        RecordUtils.ensureRecord(
                            userReference,
                            UserReferenceRecord
                        )
                )
            );
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Extracts values from ServiceHookResult and returns a new instance.
     */
    public fromServiceHookResult(
        serviceHookResult: ServiceHookResult<Partial<ReferencePanelContext>>
    ): ReferencePanelContextRecord {
        return this.with({
            ...serviceHookResult,
            ...serviceHookResult.resultObject,
        });
    }

    /**
     * Merges new values into the record and returns a new instance.
     */
    public with(
        values: Partial<ReferencePanelContext>
    ): ReferencePanelContextRecord {
        return new ReferencePanelContextRecord(
            Object.assign(this.toJS(), values) as ReferencePanelContextRecord
        );
    }

    // #endregion Public Methods
}
