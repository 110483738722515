import { Dispatch, SetStateAction } from "react";
import GlobalStateRecord from "models/view-models/global-state-record";
import { atom, useAtom } from "jotai";

export type GlobalStateUpdater = Dispatch<SetStateAction<GlobalStateRecord>>;

const defaultState = GlobalStateRecord.getInitialGlobalState();

const globalStateAtom = atom<GlobalStateRecord>(defaultState);

/**
 * Convenience wrapper to get a typed version of our global state
 * @param context
 */
export function useGlobalState() {
    const [globalState, setGlobalState] = useAtom(globalStateAtom);

    return {
        globalState,
        isAuthenticated: globalState.isAuthenticated(),
        setGlobalState,
    };
}
