import Loader from "atoms/loaders/loader";
import { useUpdateAtom } from "jotai/utils";
import SectionRecord from "models/view-models/section-record";
import UnorderedList from "molecules/lists/unordered-list";
import HiddenContentAlertBanner from "organisms/alerts/hidden-content-alert-banner";
import PublicationContentArea from "organisms/publication-content/publication-content-area";
import React, { useEffect, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import { CollectionUtils } from "utilities/collection-utils";
import useCurrentPublication from "utilities/contexts/use-current-publication";
import PublicationComponentType from "utilities/enumerations/publication-component-type";
import useSections from "utilities/hooks/domain/publications/chapters/sections/use-sections";
import useChapter from "utilities/hooks/domain/publications/chapters/use-chapter";
import { ActiveIdAtom } from "utilities/hooks/scroll-spy/use-section-scroll-spy";
import { useErrorBoundary } from "utilities/hooks/use-error-boundary";
import useErrors from "utilities/hooks/use-errors";
import useLoading from "utilities/hooks/use-loading";
import { useLocalization } from "utilities/hooks/use-localization";
import NumberUtils from "utilities/number-utils";
import NumberIndexedObject from "utilities/types/number-indexed-object";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface SectionContentProps {}

interface SectionContentParams {
    chapterId: string;
    chapterNfpaLabel: string;
    externalId: string;
    id: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-publication-page-layout";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SectionContent: React.FunctionComponent<SectionContentProps> = (
    props: SectionContentProps
) => {
    const { publication } = useCurrentPublication();

    const {
        chapterId,
        chapterNfpaLabel,
        externalId,
        id: sectionId,
    } = useParams<SectionContentParams>();
    const containerRef = useRef<HTMLDivElement>(null);
    const { t } = useLocalization();
    const setActiveId = useUpdateAtom(ActiveIdAtom);

    // -----------------------------------------------------------------------------------------
    // #region Service Hooks
    // -----------------------------------------------------------------------------------------

    const {
        errors: chapterErrors,
        loading: chapterLoading,
        resultObject: chapter,
    } = useChapter({
        chapterId,
        nfpaLabel: chapterNfpaLabel,
        publicationId: publication?.id,
    });

    const {
        errors: sectionsErrors,
        loaded: sectionsLoaded,
        loading: sectionsLoading,
        resultObject: sections,
    } = useSections(publication?.id, chapter.id);

    // #endregion Service Hooks

    // -----------------------------------------------------------------------------------------
    // #region Side Effects
    // -----------------------------------------------------------------------------------------

    const errors = useErrors(chapterErrors, sectionsErrors);
    useErrorBoundary(errors);
    const loading = useLoading(chapterLoading, sectionsLoading);

    useEffect(
        function setScrollPosition() {
            if (containerRef.current == null) {
                return;
            }
            containerRef.current.scrollTop = 0;
        },
        [sectionId]
    );

    // #endregion Side Effects

    // -----------------------------------------------------------------------------------------
    // #region Transforms
    // -----------------------------------------------------------------------------------------

    const groupedSections = useMemo(
        (): NumberIndexedObject<SectionRecord[]> =>
            sections.reduce(
                (
                    mappedSections: NumberIndexedObject<SectionRecord[]>,
                    currRecord: SectionRecord
                ) => {
                    var groupKey = currRecord.rootSectionId ?? currRecord.id;

                    if (groupKey == null) {
                        return mappedSections;
                    }

                    var mappedSection = mappedSections[groupKey];

                    // Add new grouping with section record
                    if (mappedSection == null) {
                        return {
                            ...mappedSections,
                            [groupKey]: [currRecord],
                        };
                    }

                    // Add section record to existing group
                    return {
                        ...mappedSections,
                        [groupKey]: [...mappedSection, currRecord],
                    };
                },
                {}
            ),
        [sections]
    );

    const activeSectionId = useMemo(() => {
        if (!sectionsLoaded) {
            return;
        }
        if (sectionId != null) {
            return NumberUtils.parseInt(sectionId);
        }

        const activeSection = sections.find((s) => s.externalId === externalId);
        return activeSection?.id;
    }, [sectionId, sectionsLoaded, externalId, sections]);

    const activeSubSections = useMemo(() => {
        if (activeSectionId == null) {
            return [];
        }

        return groupedSections[activeSectionId] ?? [];
    }, [activeSectionId, groupedSections]);

    // set the active id to highlight the current section in the metanav
    useEffect(() => {
        setActiveId(activeSectionId ?? 0);
    }, [activeSectionId, setActiveId]);

    // #endregion Transforms

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    return (
        <React.Fragment>
            {
                // if
                CollectionUtils.hasValues(errors) && (
                    <UnorderedList
                        cssClassName={`${BASE_CLASS_NAME}__errors`}
                        listItems={errors}
                    />
                )
            }
            {
                // if
                loading ? (
                    <Loader
                        accessibleText={t("loadingItem", {
                            item: t("section"),
                        })}
                    />
                ) : (
                    <>
                        <HiddenContentAlertBanner
                            id={activeSectionId}
                            type={PublicationComponentType.Section}
                            externalId={externalId}
                        />
                        <PublicationContentArea
                            sections={activeSubSections}
                            externalId={chapter.externalId}
                        />
                    </>
                )
            }
        </React.Fragment>
    );

    // #endregion Render
};
// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default SectionContent;

// #endregion Exports
