import { RecordUtils } from "@rsm-hcd/javascript-core";
import { Record } from "immutable";
import {
    AnnexGroupParams,
    AnnexGroupPermalinkParams,
    AnnexGroupPreviewParams,
    AnnexParams,
    AnnexPermalinkParams,
    AnnexPreviewParams,
    ArticleParams,
    ArticlePermalinkParams,
    ArticlePreviewParams,
    SectionParams,
    SectionPermalinkParams,
    SectionPreviewParams,
} from "interfaces/routing/route-params";
import { siteMap } from "internal-sitemap";
import SectionTableOfContents from "models/interfaces/table-of-contents/section-table-of-contents";
import { SectionParentType } from "utilities/enumerations/section-parent-type";
import { RouteUtils } from "utilities/route-utils";
import StringUtils from "utilities/string-utils";

const defaultValues: SectionTableOfContents =
    RecordUtils.defaultValuesFactory<SectionTableOfContents>({
        id: undefined,
        chapterId: undefined,
        displaySequence: undefined,
        externalId: "",
        hasBookmark: false,
        isDeleted: false,
        label: "",
        nfpaLabel: "",
        partId: undefined,
        title: "",
        useSectionRoute: false,
    });

export default class SectionTableOfContentsRecord
    extends Record(defaultValues)
    implements SectionTableOfContents
{
    constructor(params?: SectionTableOfContents) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    /**
     *  @example (B) Raceways over 100mm (4 in.) Wide But Not over 200 mm (8 in.) Wide
     */
    public getDisplayTitle() {
        const title = StringUtils.translateDomainTerms(this.title);

        if (this.label == null || this.label === "") return title;
        if (title.includes(this.label)) return title;

        return `${this.label} ${title}`;
    }

    public getLabel() {
        return StringUtils.hasValue(this.label) ? this.label : "";
    }

    /**
     * Returns an anchor href string to an element in the DOM representing this Section.
     */
    public getHashLink(): string {
        return `#${this.externalId}`;
    }

    public getRoute(
        params: SectionParams,
        isAdmin: boolean,
        type: SectionParentType
    ) {
        switch (type) {
            case SectionParentType.Annex:
                return this.getAnnexRoute(params, isAdmin);
            case SectionParentType.AnnexGroup:
                return this.getAnnexGroupRoute(params, isAdmin);
            case SectionParentType.Article:
                return this.getArticleRoute(params, isAdmin);
            case SectionParentType.Chapter:
            default:
                return this.getChapterRoute(params, isAdmin);
        }
    }

    public getTitleWithoutLabel() {
        return this.title?.replace(this.label ?? "", "").trim() ?? "";
    }

    /**
     * Merges new values into the record and returns a new instance.
     */
    public with(
        values: Partial<SectionTableOfContents>
    ): SectionTableOfContentsRecord {
        return new SectionTableOfContentsRecord(
            Object.assign(this.toJS(), values)
        );
    }

    private getAnnexRoute(params: AnnexParams, isAdmin: boolean) {
        return isAdmin
            ? this.getAnnexPreviewRoute(params)
            : this.getAnnexPermalinkRoute(params);
    }

    private getAnnexGroupRoute(params: AnnexGroupParams, isAdmin: boolean) {
        return isAdmin
            ? this.getAnnexGroupPreviewRoute(params)
            : this.getAnnexGroupPermalinkRoute(params);
    }

    private getArticleRoute(params: ArticleParams, isAdmin: boolean) {
        return isAdmin
            ? this.getArticlePreviewRoute(params)
            : this.getArticlePermalinkRoute(params);
    }

    private getChapterRoute(params: SectionParams, isAdmin?: boolean) {
        if (this.useSectionRoute) {
            return isAdmin
                ? this.getSectionPreviewRoute(params)
                : this.getSectionPermalinkRoute(params);
        }
        return isAdmin
            ? this.getChapterPreviewRoute(params)
            : this.getChapterPermalinkRoute(params);
    }

    private getAnnexPermalinkRoute(params: AnnexPermalinkParams) {
        return RouteUtils.getUrl(
            siteMap.publications.permalinks.annex,
            {
                code: params.code,
                edition: params.edition,
                nfpaLabel: params.nfpaLabel,
            },
            {},
            this.getHashLink()
        );
    }

    private getAnnexPreviewRoute(params: AnnexPreviewParams) {
        return RouteUtils.getUrl(
            siteMap.publications.adminPreview.annex,
            {
                publicationId: params.publicationId,
                id: params.id,
            },
            {},
            this.getHashLink()
        );
    }

    private getAnnexGroupPermalinkRoute(params: AnnexGroupPermalinkParams) {
        return RouteUtils.getUrl(
            siteMap.publications.permalinks.annexGroup,
            {
                code: params.code,
                edition: params.edition,
                nfpaLabel: params.nfpaLabel,
                id: params.id,
            },
            {},
            this.getHashLink()
        );
    }

    private getAnnexGroupPreviewRoute(params: AnnexGroupPreviewParams) {
        return RouteUtils.getUrl(
            siteMap.publications.adminPreview.annexGroup,
            {
                publicationId: params.publicationId,
                annexId: params.annexId,
                id: params.id,
            },
            {},
            this.getHashLink()
        );
    }

    private getArticlePermalinkRoute(params: ArticlePermalinkParams) {
        if (
            params.code == null ||
            params.edition == null ||
            params.chapterNfpaLabel == null ||
            params.nfpaLabel == null
        )
            return "#";

        return RouteUtils.getUrl(
            siteMap.publications.permalinks.article,
            {
                code: params.code,
                edition: params.edition,
                chapterNfpaLabel: params.chapterNfpaLabel,
                nfpaLabel: params.nfpaLabel,
            },
            {},
            this.getHashLink()
        );
    }

    private getArticlePreviewRoute(params: ArticlePreviewParams) {
        return RouteUtils.getUrl(
            siteMap.publications.adminPreview.article,
            {
                publicationId: params.publicationId,
                chapterId: params.chapterId,
                id: params.id,
            },
            {},
            this.getHashLink()
        );
    }

    private getChapterPermalinkRoute(params: SectionPermalinkParams) {
        if (
            params.code == null ||
            params.edition == null ||
            params.nfpaLabel == null
        ) {
            return "#";
        }

        return RouteUtils.getUrl(
            siteMap.publications.permalinks.chapter,
            {
                code: params.code,
                edition: params.edition,
                nfpaLabel: params.nfpaLabel,
            },
            {},
            this.getHashLink()
        );
    }

    private getChapterPreviewRoute(params: SectionPreviewParams) {
        return RouteUtils.getUrl(
            siteMap.publications.adminPreview.chapter,
            {
                publicationId: params?.publicationId ?? params?.id,
                id: this.chapterId,
            },
            {},
            this.getHashLink()
        );
    }

    private getSectionPermalinkRoute(params: SectionPermalinkParams) {
        // Check for NFPA 70/Chapter 9
        const chapterNfpaLabel =
            params.code === "70" && params.nfpaLabel === "9"
                ? params.nfpaLabel
                : params.chapterNfpaLabel;

        return RouteUtils.getUrl(siteMap.publications.permalinks.section, {
            chapterNfpaLabel: chapterNfpaLabel,
            code: params.code,
            edition: params.edition,
            externalId: this.externalId,
        });
    }

    private getSectionPreviewRoute(params: SectionPreviewParams) {
        return RouteUtils.getUrl(siteMap.publications.adminPreview.section, {
            publicationId: params?.publicationId ?? params?.id,
            chapterId: this.chapterId,
            id: this.id,
        });
    }
}
