import { Record } from "immutable";
import type Topic from "models/interfaces/topic";
import { RecordUtils } from "@rsm-hcd/javascript-core";

const defaultValues: Topic = RecordUtils.auditableDefaultValuesFactory<Topic>({
    externalIdentifier: undefined,
    name: "",
});

export default class TopicRecord
    extends Record(defaultValues)
    implements Topic
{
    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: Topic) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<Topic>} values
     */
    public with(values: Partial<Topic>): TopicRecord {
        return new TopicRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}
