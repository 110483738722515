import { RecordUtils } from "@rsm-hcd/javascript-core";
import { Record } from "immutable";
import type SectionCollection from "models/interfaces/section-collection";
import { PartRecord } from "internal";
import SectionRecord from "models/view-models/section-record";
import { CollectionUtils } from "utilities/collection-utils";

const defaultValues: SectionCollection =
    RecordUtils.defaultValuesFactory<SectionCollection>({
        parts: [],
        sections: [],
    });

export default class SectionCollectionRecord
    extends Record(defaultValues)
    implements SectionCollection
{
    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: SectionCollection) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (CollectionUtils.hasValues(params.parts)) {
            params.parts = params.parts.map((part: PartRecord) =>
                RecordUtils.ensureRecord(part, PartRecord)
            );
        }

        if (CollectionUtils.hasValues(params.sections)) {
            params.sections = params.sections.map((section: SectionRecord) =>
                RecordUtils.ensureRecord(section, SectionRecord)
            );
        }

        super(params);
    }

    // #endregion Constructor

    // ---------------------------------------------------------------------------------------------
    // #region Public Methods
    // ---------------------------------------------------------------------------------------------

    /**
     * Returns the first SectionRecord from the list if there are any Sections attached, otherwise
     * returns `undefined`
     *
     */
    public firstSection(): SectionRecord | undefined {
        if (!this.hasSections()) {
            return undefined;
        }

        return this.sections[0];
    }

    /**
     * Returns the 'grouped' top-level Sections.
     *
     * _Those without a Part are sorted first, followed by those **with** a Part. The first Section
     * that relates to a Part will have the `part` navigation property populated, if it can be found
     * in the `parts` array._
     *
     * @returns {SectionRecord[]}
     */
    public getGroupedTopLevelSections(): SectionRecord[] {
        const topLevelSections = this.getTopLevelSections();
        let topLevelSectionsWithPart = CollectionUtils.intersection(
            this.getSectionsWithPartId(),
            topLevelSections
        );
        const topLevelSectionsWithoutPart = CollectionUtils.intersection(
            this.getSectionsWithoutPartId(),
            topLevelSections
        );

        // Attach the corresponding Part to the first Section found that matches by PartId
        this.parts.forEach((part: PartRecord) => {
            topLevelSectionsWithPart = this.attachPartToFirstRelatedSection(
                part,
                topLevelSectionsWithPart
            );
        });

        const emptyParts = this.parts.filter(
            (p: PartRecord) =>
                !topLevelSections.some((s: SectionRecord) => s.partId === p.id)
        );

        const partSectionWrappers = emptyParts.map((part: PartRecord) =>
            new SectionRecord().with({
                part: part,
                partId: part.id,
                externalId: part.externalId,
            })
        );

        return [
            ...topLevelSectionsWithoutPart,
            ...topLevelSectionsWithPart,
            ...partSectionWrappers,
        ];
    }

    /**
     * Sorts and returns a flat list of sections with included Parts ready to display
     * @returns SectionRecord
     */
    public flatten(): SectionRecord[] {
        const roots = this.getGroupedTopLevelSections();
        let flatList: Array<SectionRecord> = [];
        let level = 0;
        roots.forEach((r: SectionRecord) => {
            this.flattenRecursive(flatList, r, level);
        });

        return flatList;
    }

    /**
     * Recurses over an array of sections to order and add the type virtual property
     *
     * @param  {Array<SectionRecord>} list
     * @param  {SectionRecord} parent
     * @param  {number} level
     */
    public flattenRecursive(
        list: Array<SectionRecord>,
        parent: SectionRecord,
        level: number
    ) {
        parent = parent.with({ type: level });
        list.push(parent);
        level++;
        const subSections = this.getSectionsByParentId(parent.id!);
        subSections.forEach((s) => this.flattenRecursive(list, s, level));
    }

    /**
     * Returns Sections that are subsections of the given `parentId`
     *
     * @param parentId Id of the parent Section to filter Sections by
     */
    public getSectionsByParentId(parentId: number): SectionRecord[] {
        return this.sections.filter(
            (section: SectionRecord) =>
                section.hasParentId() && section.parentId === parentId
        );
    }

    /**
     * Returns 'top-level' Sections (those without a `parentId`)
     *
     * @returns {SectionRecord[]}
     */
    public getTopLevelSections(): SectionRecord[] {
        return this.sections.filter(
            (section: SectionRecord) => !section.hasParentId()
        );
    }

    /**
     * Returns whether or not the collection contains Parts
     */
    public hasParts(): boolean {
        return CollectionUtils.hasValues(this.parts);
    }

    /**
     * Returns whether or not the collection contains Sections
     */
    public hasSections(): boolean {
        return CollectionUtils.hasValues(this.sections);
    }

    /**
     * Returns the last SectionRecord from the list if there are any Sections attached, otherwise
     * returns `undefined`
     *
     */
    public lastSection(): SectionRecord | undefined {
        if (!this.hasSections()) {
            return undefined;
        }

        return this.sections[this.sections.length - 1];
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<SectionCollection>} values
     */
    public with(values: Partial<SectionCollection>): SectionCollectionRecord {
        return new SectionCollectionRecord(
            Object.assign(this.toJS(), values) as SectionCollectionRecord
        );
    }

    // #endregion Public Methods

    // -----------------------------------------------------------------------------------------
    // #region Private Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Attaches the provided `PartRecord` to the first related `Section` in the array.
     *
     * If for some reason a Section cannot be found, it returns the unmodified input array.
     *
     * @param part Record to be attached to the first `Section` with a matching `partId`
     * @param sections Array to search for the first matching `Section` by `partId`
     */
    private attachPartToFirstRelatedSection(
        part: PartRecord,
        sections: SectionRecord[]
    ): SectionRecord[] {
        const index = sections.findIndex(
            (section: SectionRecord) =>
                section.hasPartId() && section.getPartId() === part.id
        );

        if (index < 0) {
            return sections;
        }

        let firstSectionForPart = sections[index];
        if (firstSectionForPart == null) {
            return sections;
        }

        firstSectionForPart = firstSectionForPart.with({ part });

        return CollectionUtils.replaceElementAt(
            sections,
            index,
            firstSectionForPart
        );
    }

    /**
     * Returns Sections that do not relate to a Part
     */
    private getSectionsWithoutPartId(): SectionRecord[] {
        return this.sections.filter(
            (section: SectionRecord) => !section.hasPartId()
        );
    }

    /**
     * Returns Sections that relate to a Part
     */
    private getSectionsWithPartId(): SectionRecord[] {
        return this.sections.filter((section: SectionRecord) =>
            section.hasPartId()
        );
    }

    // #endregion Private Methods
}
