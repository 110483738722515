import Anchor from "atoms/anchors/anchor";
import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { AppNameWithHtml } from "constants/app-name-tm";
import { TrackingCode } from "constants/nfpa-urls/tracking-codes";
import { siteMap } from "internal-sitemap";
import SubscriptionType from "organisms/enums/subscription-type";
import React from "react";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import { RouteUtils } from "utilities/route-utils";
import SubscriptionTypeUtils from "utilities/subscription-type-utils";

const CSS_BASE_CLASS = "c-toast-message";
const plansRoute = RouteUtils.getUrl(siteMap.signup.plans, null, {
    icid: TrackingCode.FreeAccessSubscribe,
});
const freeTrialRoute = RouteUtils.getUrl(
    siteMap.signup.registerPlanSelected,
    {
        subscriptionType: SubscriptionTypeUtils.toRouteParam(
            SubscriptionType.FreeTrial
        ),
    },
    { icid: TrackingCode.FreeAccessSubscribe }
);

const FreeTrialToast = ({
    bodyKey,
    buttonKey,
    route,
}: {
    bodyKey: keyof CultureResources;
    buttonKey: keyof CultureResources;
    route: string;
}) => {
    const { t } = useLocalization();
    const header = t("toastHeader", { appNameWithHtml: AppNameWithHtml });
    const body = t(bodyKey, { AppNameWithHtml: AppNameWithHtml });
    const buttonText = t(buttonKey);

    return (
        <div className={CSS_BASE_CLASS}>
            <span
                className={`${CSS_BASE_CLASS} -free-access -title`}
                dangerouslySetInnerHTML={{
                    __html: header,
                }}
            />
            <br />
            <span
                className={`${CSS_BASE_CLASS} -free-access -body`}
                dangerouslySetInnerHTML={{ __html: body }}
            />
            <br />
            <Anchor to={route}>
                <Button
                    accessibleText={buttonText}
                    cssClassName={`${CSS_BASE_CLASS} -free-access -button`}
                    size={ButtonSizes.Medium}
                    style={ButtonStyles.Secondary}>
                    {buttonText}
                </Button>
            </Anchor>
        </div>
    );
};

export const EligibleFreeTrialToast = () => (
    <FreeTrialToast
        bodyKey="eligibleForTrialBody"
        buttonKey="activateTrial"
        route={freeTrialRoute}
    />
);

export const NotEligibleFreeTrialToast = () => (
    <FreeTrialToast
        bodyKey="notEligibleForTrialBody"
        buttonKey="subscribe-now"
        route={plansRoute}
    />
);
