import { useAsyncEffect } from "@rsm-hcd/javascript-react";
import type EnhancedContentMigrationRecord from "models/view-models/enhanced-content-migration-record";
import type PublicationRecord from "models/view-models/publication-record";
import { EnhancedContentMigrationAction } from "organisms/admin/publications/enhanced-content/enhanced-content-migration-modal";
import { useCallback, useEffect, useState } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import EnhancedContentMigrationService from "utilities/services/admin/enhanced-content/enhanced-content-migration-service";
import AdminPublicationService from "utilities/services/admin/publications/admin-publication-service";
import JobService from "utilities/services/jobs/job-service";
import UserService from "utilities/services/users/user-service";
import StringUtils from "utilities/string-utils";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const ERROR_LOADING_ENHANCED_CONTENT_MIGRATION =
    "There was an issue loading enhanced content migrations.";

const ERROR_STARTING_ENHANCED_CONTENT_MIGRATION =
    "There was an issue starting an enhanced content migration.";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface UseEnhancedContentMigrationsOptions {
    destination: PublicationRecord;
    migrationAction: EnhancedContentMigrationAction;
}

// #endregion Interfaces

/**
 * Utility helper hook for enhanced content migrations
 *
 * @param {UseEnhancedContentMigrationsOptions} options
 * @return {*}
 */
export default function useEnhancedContentMigrations(
    options: Partial<UseEnhancedContentMigrationsOptions>
) {
    const { destination, migrationAction } = options;

    const { code: destinationCode, edition: destinationEdition } =
        destination ?? {};

    // -----------------------------------------------------------------------------------------
    // #region Service Hooks
    // -----------------------------------------------------------------------------------------

    const { list: listPublications } = AdminPublicationService.useList();

    const { create: createEnhancedContentMigration } =
        EnhancedContentMigrationService.useCreate();

    const { list: listEnhancedContentMigrations } =
        EnhancedContentMigrationService.useList();

    const { get: getJob } = JobService.useGet();

    const { get: getUser } = UserService.useGet();

    // #endregion Service Hooks

    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------

    const [errors, setErrors] = useState<Array<string>>([]);

    const [lastMigrationIsLoading, setLastMigrationIsLoading] = useState(true);

    const [lastMigration, setLastMigration] =
        useState<EnhancedContentMigrationRecord>();

    // #endregion State

    // -----------------------------------------------------------------------------------------
    // #region Computations
    // -----------------------------------------------------------------------------------------

    const addErrors = (result?: { listErrorMessages: () => Array<string> }) => {
        setErrors((prev) => [...prev, ...(result?.listErrorMessages() ?? [])]);
    };

    const getSourcePublication = useCallback(
        async (code?: string, edition?: string) => {
            if (StringUtils.hasValue(code) && StringUtils.hasValue(edition)) {
                if (
                    lastMigration?.sourcePublication?.code === code &&
                    lastMigration?.sourcePublication?.edition === edition
                ) {
                    return lastMigration.sourcePublication;
                }

                try {
                    const { result, resultObjects } = await listPublications({
                        code,
                        edition,
                    });

                    if (
                        result?.hasErrors() ||
                        CollectionUtils.isEmpty(resultObjects)
                    ) {
                        addErrors(result);
                        return;
                    }

                    return resultObjects[0];
                } catch (error) {
                    setErrors([ERROR_LOADING_ENHANCED_CONTENT_MIGRATION]);
                }
            }
        },
        [lastMigration, listPublications]
    );

    const getUpdatedBy = useCallback(
        async (updatedById?: number) => {
            if (updatedById != null) {
                if (lastMigration?.updatedBy?.id === updatedById) {
                    return lastMigration.updatedBy;
                }

                try {
                    const { result, resultObject } = await getUser({
                        id: updatedById,
                    });

                    if (result?.hasErrors() || resultObject == null) {
                        addErrors(result);
                        return;
                    }

                    return resultObject;
                } catch (error) {
                    setErrors([ERROR_LOADING_ENHANCED_CONTENT_MIGRATION]);
                }
            }
        },
        [getUser, lastMigration]
    );

    const getMigrationJob = useCallback(
        async (jobId?: number) => {
            if (jobId != null) {
                if (lastMigration?.job?.id === jobId) {
                    return lastMigration.job;
                }

                try {
                    const { result, resultObject } = await getJob({
                        id: jobId,
                    });

                    if (result?.hasErrors() || resultObject == null) {
                        addErrors(result);
                        return;
                    }

                    return resultObject;
                } catch (error) {
                    setErrors([ERROR_LOADING_ENHANCED_CONTENT_MIGRATION]);
                }
            }
        },
        [getJob, lastMigration]
    );

    const withNavigationProperties = useCallback(
        async (migration?: EnhancedContentMigrationRecord) => {
            if (migration == null) {
                return;
            }

            const [job, sourcePublication, updatedBy] = await Promise.all([
                getMigrationJob(migration.jobId),
                getSourcePublication(migration.code, migration.sourceEdition),
                getUpdatedBy(migration.updatedById),
            ]);

            return migration.with({ job, sourcePublication, updatedBy });
        },
        [getSourcePublication, getMigrationJob, getUpdatedBy]
    );

    const getLastMigration = useCallback(
        async (code?: string, edition?: string) => {
            try {
                const { result, resultObjects } =
                    await listEnhancedContentMigrations({
                        code: code,
                        destinationEdition: edition,
                        mostRecent: true,
                    });

                if (result?.hasErrors()) {
                    addErrors(result);
                    return;
                }

                if (CollectionUtils.hasValues(resultObjects)) {
                    return withNavigationProperties(resultObjects[0]);
                }
            } catch (error) {
                setErrors([ERROR_LOADING_ENHANCED_CONTENT_MIGRATION]);
            }
        },
        [listEnhancedContentMigrations, withNavigationProperties]
    );

    const startMigration = useCallback(
        (migration: EnhancedContentMigrationRecord) => {
            const createMigration = async (
                migration: EnhancedContentMigrationRecord
            ) => {
                setErrors([]);
                setLastMigration(migration);

                try {
                    const { result, resultObject } =
                        await createEnhancedContentMigration(migration);

                    if (result?.hasErrors()) {
                        setErrors([ERROR_STARTING_ENHANCED_CONTENT_MIGRATION]);
                        return;
                    }

                    const migrationWithNavigationProperties =
                        await withNavigationProperties(resultObject);

                    setLastMigration(migrationWithNavigationProperties);
                } catch (error) {
                    setErrors([ERROR_STARTING_ENHANCED_CONTENT_MIGRATION]);
                }
            };

            createMigration(migration);
        },
        [createEnhancedContentMigration, withNavigationProperties]
    );

    // #endregion Computations

    // -----------------------------------------------------------------------------------------
    // #region Side-Effects
    // -----------------------------------------------------------------------------------------

    useAsyncEffect(
        async function enhancedContentMigrations() {
            setLastMigrationIsLoading(true);
            setLastMigration(undefined);

            let lastMigration = await getLastMigration(
                destinationCode,
                destinationEdition
            );

            if (lastMigration != null) {
                setLastMigration(lastMigration);
            }

            setLastMigrationIsLoading(false);
        },
        [destinationCode, destinationEdition]
    );

    useEffect(
        function pollMigrationStatus() {
            let intervalId: number;

            if (lastMigration?.isMigrating()) {
                intervalId = window.setInterval(async () => {
                    const lastMigration = await getLastMigration(
                        destinationCode,
                        destinationEdition
                    );

                    if (lastMigration != null) {
                        setLastMigration(lastMigration);

                        if (!lastMigration.isMigrating()) {
                            clearInterval(intervalId);
                        }
                    }
                }, 1000);
            }

            // clear interval
            return () => clearInterval(intervalId);
        },
        [destinationCode, destinationEdition, getLastMigration, lastMigration]
    );

    useEffect(() => {
        setErrors([]);
    }, [destination, migrationAction]);

    // #endregion Side-Effects

    return {
        startMigration,
        errors,
        isLoading: lastMigrationIsLoading,
        lastMigration: lastMigration,
    };
}
