import { Do, ResultRecord } from "@rsm-hcd/javascript-core";
import type FileRecord from "models/view-models/file-record";
import { useCallback, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { CollectionUtils } from "utilities/collection-utils";
import usePageErrors from "utilities/hooks/use-page-errors";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import FileService from "utilities/services/file-service";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface UseFilesOptions {
    ids: number[];
}

interface UseFilesResult extends ServiceHookResult<FileRecord[]> {
    refresh: () => void;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const FILE_BATCH_SIZE = 25;

// #endregion Constants

/**
 * Custom hook for retrieving Files
 */
export default function useFiles(options: UseFilesOptions): UseFilesResult {
    const { ids } = options;
    const [files, setFiles] = useState<Array<FileRecord>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);

    const { list: batchFileListApi } = FileService.useBatchedList();
    const { handlePageLoadError, pageErrors: errors } = usePageErrors();

    const getFiles = useCallback(
        (fileIds: number[]) => {
            if (CollectionUtils.isEmpty(fileIds)) {
                return;
            }

            Do.try(async () => {
                setLoading(true);

                batchFileListApi({ ids: fileIds }, "ids", FILE_BATCH_SIZE)
                    .then((result) => {
                        setFiles(result.resultObjects);
                        setLoading(false);
                        setLoaded(true);
                    })
                    .catch((error) => handlePageLoadError(error));
            }).catch((result?: ResultRecord<FileRecord>, error?: any) => {
                if (result == null) {
                    handlePageLoadError(error);
                    return;
                }
            });
        },
        [batchFileListApi, handlePageLoadError]
    );

    const refresh = useCallback(() => {
        getFiles(ids);
    }, [getFiles, ids]);

    useDeepCompareEffect(() => {
        getFiles(ids);
    }, [getFiles, ids]);

    return {
        errors,
        loaded,
        loading,
        refresh,
        resultObject: files,
    };
}
