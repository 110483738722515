import Button from "atoms/buttons/button";
import ModalCloseButton from "atoms/buttons/modal-close-button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import { DataConfiguration } from "constants/data-configuration";
import GroupRecord from "models/view-models/group-record";
import { ModalCloseButtonStyle } from "molecules/constants/modal-close-button-style";
import { ModalTypes } from "molecules/constants/modal-types";
import InputFormField from "molecules/form-fields/input-form-field";
import Form from "molecules/forms/form";
import Modal, { ModalProps } from "molecules/modals/modal";
import React, { useCallback, useState } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import { useLocalization } from "utilities/hooks/use-localization";
import { t } from "utilities/localization-utils";
import GroupService from "utilities/services/groups/group-service";
import StringUtils from "utilities/string-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface TeamManagementTeamNameModalProps
    extends Pick<ModalProps, "isVisible" | "closeDialog"> {
    group: GroupRecord;
    onChange: (value: GroupRecord) => void;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const MaximumTeamNameLength = DataConfiguration.ExtraShortStringLength;

const CSS_CLASS_NAME = "c-account-dashboard__modal";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const TeamManagementTeamNameModal: React.FC<
    TeamManagementTeamNameModalProps
> = (props) => {
    const { closeDialog, isVisible, group, onChange } = props;

    // -----------------------------------------------------------------------------------------
    // #region Custom Hooks
    // -----------------------------------------------------------------------------------------

    const { update: updateGroup } = GroupService.useUpdate();
    const { t } = useLocalization();

    // #endregion Custom Hooks

    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------

    const [errors, setErrors] = useState<Array<string>>();
    const [savingTeamName, setSavingTeamName] = useState(false);
    const [teamName, setTeamName] = useState(group.name);

    // #endregion State

    // -----------------------------------------------------------------------------------------
    // #region Computations
    // -----------------------------------------------------------------------------------------

    const isValidTeamName = CollectionUtils.isEmpty(errors);

    const ERROR_UPDATING_TEAM_NAME = t("errors-actionResource", {
        action: t("updating"),
        resource: t("teamName"),
    });
    const LABEL_TEAM_NAME = t("teamName");
    const MODAL_TITLE = t("changeTeamName");

    // #endregion Computations

    // -----------------------------------------------------------------------------------------
    // #region Event Handlers
    // -----------------------------------------------------------------------------------------

    const handleOnClose = useCallback(() => {
        setErrors([]);
        setTeamName(group.name);
        closeDialog();
    }, [closeDialog, group]);

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTeamName(e.currentTarget.value);
    };

    const saveTeamName = useCallback(async (): Promise<{
        errors?: string[];
        group?: GroupRecord;
    }> => {
        try {
            const validationErrors = validateTeamName(teamName);

            if (CollectionUtils.hasValues(validationErrors)) {
                return { errors: validationErrors };
            }

            const { result, resultObject: savedGroupRecord } =
                await updateGroup(group.with({ name: teamName }), {
                    id: group.id!,
                });

            if (savedGroupRecord == null || result?.hasErrors()) {
                return {
                    errors: [ERROR_UPDATING_TEAM_NAME],
                };
            }

            return { group: savedGroupRecord };
        } catch (error) {
            return {
                errors: [ERROR_UPDATING_TEAM_NAME],
            };
        }
    }, [group, teamName, updateGroup, ERROR_UPDATING_TEAM_NAME]);

    const handleOnSubmit = useCallback(async () => {
        setSavingTeamName(true);
        setErrors([]);

        const { errors, group } = await saveTeamName();

        if (CollectionUtils.isEmpty(errors)) {
            onChange(group!);
            closeDialog();
        }

        setErrors(errors);
        setSavingTeamName(false);
    }, [closeDialog, onChange, saveTeamName]);

    // #endregion Event Handlers

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    const renderSaveButonContent = () => {
        if (savingTeamName) {
            return t("savingChanges");
        }

        return t("saveChanges");
    };

    return (
        <Modal
            closeButtonStyle={ModalCloseButtonStyle.InsideDialog}
            closeDialog={handleOnClose}
            cssClassName={CSS_CLASS_NAME}
            isVisible={isVisible}
            label={MODAL_TITLE}
            type={ModalTypes.Base}>
            <div className={`${CSS_CLASS_NAME}__header`}>
                <Heading priority={HeadingPriority.Two}>{MODAL_TITLE}</Heading>
                <ModalCloseButton onClick={handleOnClose} />
            </div>
            <div className={`${CSS_CLASS_NAME}__body`}>
                <div className={`${CSS_CLASS_NAME}__content`}>
                    <Form onSubmit={handleOnSubmit}>
                        <InputFormField
                            errorMessages={errors}
                            disabled={savingTeamName}
                            isValid={isValidTeamName}
                            label={LABEL_TEAM_NAME}
                            maxLength={MaximumTeamNameLength}
                            onChange={handleOnChange}
                            placeholder={LABEL_TEAM_NAME}
                            value={teamName}
                        />
                    </Form>
                </div>
                <div className={`${CSS_CLASS_NAME}__footer`}>
                    {!savingTeamName && (
                        <Button
                            onClick={handleOnClose}
                            style={ButtonStyles.Secondary}>
                            {t("cancel")}
                        </Button>
                    )}
                    <Button disabled={savingTeamName} onClick={handleOnSubmit}>
                        {renderSaveButonContent()}
                    </Button>
                </div>
            </div>
        </Modal>
    );

    // #endregion Render
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Private Functions
// -----------------------------------------------------------------------------------------

const validateTeamName = (teamName?: string) => {
    if (StringUtils.isEmpty(teamName)) {
        return [t("teamNameBlank")];
    }

    if (teamName.length > MaximumTeamNameLength) {
        return [t("teamNameMaxCharacters", { length: MaximumTeamNameLength })];
    }
};

// #endregion Private Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default TeamManagementTeamNameModal;

// #endregion Exports
