import { CategoryType } from "models/enumerations/situational-navigation/categories/category-type";
import CategoryRecord from "models/view-models/situational-navigation/categories/category-record";
import { PagedQuery } from "@rsm-hcd/javascript-core";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface CategoryPathParams {
    id: number;
}

export interface CategoryQueryParams extends PagedQuery {
    categoryIds?: Array<number>;
    relatedCategoryIds?: Array<number>;
    type?: CategoryType;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const key = "situational-navigation-categories";
const baseEndpoint = "situational-navigation/categories";
const resourceType = CategoryRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const CategoryService = {
    get: ServiceFactory.get<CategoryRecord, CategoryPathParams>(
        resourceType,
        `${baseEndpoint}/:id`
    ),
    useGet: ServiceHookFactory.useGet<CategoryRecord, CategoryPathParams>(
        resourceType,
        `${baseEndpoint}/:id`
    ),
    useGetQuery: QueryHookFactory.useGetQuery<
        CategoryRecord,
        CategoryPathParams
    >(key, resourceType, `${baseEndpoint}/:id`),
    list: ServiceFactory.list<CategoryRecord, CategoryQueryParams>(
        resourceType,
        baseEndpoint
    ),
    useList: ServiceHookFactory.useList<CategoryRecord, CategoryQueryParams>(
        resourceType,
        baseEndpoint
    ),
    useListQuery: QueryHookFactory.useListQuery<
        CategoryRecord,
        CategoryQueryParams
    >(key, resourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default CategoryService;

// #endregion Exports
