import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import React from "react";
import useAccountInformationTab from "utilities/hooks/use-account-information-tab";
import StringUtils from "utilities/string-utils";
import AccountDisplayInformation from "./account-information/account-display-information";
import AccountLanguagePreferences from "./account-information/account-language-preferences";
import { t } from "utilities/localization-utils";
import Paragraph from "atoms/typography/paragraph";
import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface AccountOrganizationMemberInformationTabPanelProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS = "c-account-dashboard__information";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const AccountOrganizationMemberInformationTabPanel: React.FC<
    AccountOrganizationMemberInformationTabPanelProps
> = () => {
    const {
        email,
        emailLabel,
        emailNotAvailable,
        fullName,
        nameLabel,
        nameNotAvailable,
        password,
    } = useAccountInformationTab();
    return (
        <div className={BASE_CLASS}>
            <div className={`${BASE_CLASS}__personal`}>
                <Heading priority={HeadingPriority.Five}>
                    {t("yourProfile")}
                </Heading>
                <AccountDisplayInformation
                    heading={t("organization")}
                    information={"Need to add once endpoint ready"}
                />
                <AccountDisplayInformation
                    heading={nameLabel}
                    information={
                        StringUtils.isEmpty(fullName)
                            ? nameNotAvailable
                            : fullName!
                    }
                />
                <AccountDisplayInformation
                    heading={emailLabel}
                    information={
                        StringUtils.isEmpty(email) ? emailNotAvailable : email!
                    }
                />
                <AccountDisplayInformation
                    heading={t("password")}
                    information={password}
                />
                <div className={`${BASE_CLASS}__orgSupport`}>
                    <Paragraph
                        cssClassName={`${BASE_CLASS}__orgSupportHelpText`}>
                        {t("orgAccountSettingsSupportText")}
                    </Paragraph>
                    <Button style={ButtonStyles.Secondary}>
                        {t("organizationAccountSupport")}
                    </Button>
                </div>
            </div>
            <AccountLanguagePreferences />
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default AccountOrganizationMemberInformationTabPanel;

// #endregion Exports
