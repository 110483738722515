import {
    ResultErrorRecord,
    ResultRecord,
    ServiceResponse,
} from "@rsm-hcd/javascript-core";
import GroupInvitationRecord from "models/view-models/group-invitations/group-invitation-record";
import InviteOnDemandRecord from "models/view-models/group-invitations/invite-on-demand.record";
import { useEffect, useState } from "react";
import InviteOnDemandService from "utilities/services/invite-on-demand/invite-on-demand-service";
import useEmailAddressValidation from "../validation/use-email-validation";

export const InviteOnDemandErrors = {
    ERROR_NO_INVITES_LEFT:
        "GroupInvitationValidationConductor.Validate.ErrorNoLicensesAvailable",
    ERROR_USER_ALREADY_IN_GROUP:
        "GroupInvitationValidationConductor.Validate.ErrorExistingUserRoleGroup",
    ERROR_DOMAIN_NOT_FOUND:
        "EmailValidationConductor.Validate.ValidEmailAddressRequired",
    ERROR_INVITE_ALREADY_EXISTS:
        "GroupInvitationValidationConductor.Validate.ErrorExistingInvitation",
};

const ERROR_UNKNOWN = new ResultRecord({
    errors: [
        new ResultErrorRecord({
            message: "An unknown error has occurred. Please try again.",
        }),
    ],
});

interface UseInviteOnDemandOptions {
    groupId: number;
}

const useInviteOnDemand = ({ groupId }: UseInviteOnDemandOptions) => {
    const [group, setGroup] = useState<InviteOnDemandRecord | null>(null);
    const [isLoading, setLoading] = useState(true);
    const { validateEmail } = useEmailAddressValidation();

    const createInviteOnDemand = async (
        email: string
    ): Promise<{
        validationErrors?: string[];
        result?:
            | ServiceResponse<GroupInvitationRecord>
            | ResultRecord<GroupInvitationRecord>;
    }> => {
        setLoading(true);
        try {
            const validationResult = await validateEmail(email);
            if (!validationResult.isValid) {
                return { validationErrors: [validationResult.errorMessage!] };
            }

            const payload = new GroupInvitationRecord({
                email,
                groupId,
            });
            const result = await InviteOnDemandService.create(payload);
            return { result: result };
        } catch (e) {
            return e instanceof ResultRecord
                ? { result: e }
                : { result: ERROR_UNKNOWN };
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const loadGroup = async () => {
            setLoading(true);
            try {
                const result = await InviteOnDemandService.get({ groupId });
                if (result.resultObject == null) {
                    return;
                }
                setGroup(result.resultObject);
            } finally {
                setLoading(false);
            }
        };

        loadGroup();
    }, [groupId]);

    return {
        isLoading,
        group,
        createInviteOnDemand,
    };
};

export default useInviteOnDemand;
