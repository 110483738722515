import { RecordUtils } from "@rsm-hcd/javascript-core";
import { Record } from "immutable";
import type OfflineDevice from "models/interfaces/offline/offline-device";
import OfflineBookRecord from "models/view-models/offline/offline-book-record";

const defaultValues: OfflineDevice =
    RecordUtils.auditableDefaultValuesFactory<OfflineDevice>({
        deviceId: "",
        name: "",
        offlineBooks: [],
    });

export default class OfflineDeviceRecord
    extends Record(defaultValues)
    implements OfflineDevice
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: OfflineDevice) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.offlineBooks != null) {
            params.offlineBooks = params.offlineBooks.map((ob) =>
                RecordUtils.ensureRecord(ob, OfflineBookRecord)
            );
        }

        super(params);
    }
    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    public with(values: Partial<OfflineDevice>): OfflineDeviceRecord {
        return new OfflineDeviceRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}
