/**
 * Disabling eslint rule as currently it does not support currying of custom hooks
 * and thinks we are calling it
 */
/* eslint-disable react-hooks/rules-of-hooks */
/**
 * Disabling this as putting in exhaustive deps recommended causes infinite re-rendering from reference
 * changing
 */
/* eslint-disable react-hooks/exhaustive-deps */
import { ServiceResponse } from "@rsm-hcd/javascript-core";
import {
    ServiceHookFactory as RsmServiceHookFactory,
    useCancellablePromise,
} from "@rsm-hcd/javascript-react";
import { useCallback } from "react";
import ServiceFactory, {
    BatchedListService,
    BulkCreateService,
    BulkUpdateService,
    CreateService,
    DeleteService,
    ListService,
    NestedBatchedListService,
    NestedCreateService,
    NestedListService,
    RecordType,
    UpdateService,
} from "utilities/services/service-factory";

// -----------------------------------------------------------------------------------------
// #region Types
// -----------------------------------------------------------------------------------------

/**
 * Type defining the return object from calling `useBulkCreate()`
 */
export type BulkCreateServiceHook<TRecord> = () => {
    create: BulkCreateService<TRecord>;
};

/**
 * Type defining the return object from calling `useBulkUpdate()`
 */
export type BulkUpdateServiceHook<TRecord, TPathParams> = () => {
    update: BulkUpdateService<TRecord, TPathParams>;
};

/**
 * Type defining the return object from calling `useCreate()`
 */
export type CreateServiceHook<TRecord> = () => {
    create: CreateService<TRecord>;
};

/**
 * Type defining the return object from calling `useDelete()`
 */
export type DeleteServiceHook = () => {
    delete: DeleteService;
};

/**
 * Type defining the return object from calling `useList()`
 */
export type ListServiceHook<TRecord, TQueryParams> = () => {
    list: ListService<TRecord, TQueryParams>;
};

/**
 * Type defining the return object from calling `useListBatched()`
 */
export type BatchedListServiceHook<TRecord, TQueryParams> = () => {
    list: BatchedListService<TRecord, TQueryParams>;
    // TODO: Will need to have batching as this is abstracted
    // onBatchError: (queryParamValues: Array<any>) => void;
    // onBatchSuccess: (items: Array<TRecord>) => void;
};

export type NestedBatchedListServiceHook<TRecord, TPathParams, TQueryParams> =
    () => {
        list: NestedBatchedListService<TRecord, TPathParams, TQueryParams>;
        // TODO: Will need to have batching as this is abstracted
        // onBatchError: (queryParamValues: Array<any>) => void;
        // onBatchSuccess: (items: Array<TRecord>) => void;
    };

/**
 * Type defining the return object from calling `useNestedCreate()`
 */
export type NestedCreateServiceHook<TRecord, TPathParams> = () => {
    create: NestedCreateService<TRecord, TPathParams>;
};

/**
 * Type defining the return object from calling `useNestedList()`
 */
export type NestedListServiceHook<TRecord, TPathParams, TQueryParams> = () => {
    list: NestedListService<TRecord, TPathParams, TQueryParams>;
};

/**
 * Type defining the return object from calling `useUpdate()`
 */
export type UpdateServiceHook<
    TRecord,
    TPathParams,
    TQueryParams = undefined,
> = () => {
    update: UpdateService<TRecord, TPathParams, TQueryParams>;
};

// #endregion Types

// ---------------------------------------------------------------------------------------------
// #region Functions
// ---------------------------------------------------------------------------------------------

/**
 * Factory to encapsulate common service function logic
 */
const ServiceHookFactory = {
    useBulkCreate<TRecord extends RecordType>(
        recordType: { new (): TRecord },
        baseEndpoint: string
    ): BulkCreateServiceHook<TRecord> {
        return () => {
            const { cancellablePromise } = useCancellablePromise();

            const serviceBulkCreate = ServiceFactory.bulkCreate(
                recordType,
                baseEndpoint
            );

            function create(
                records?: Array<TRecord>
            ): Promise<ServiceResponse<TRecord>> {
                return cancellablePromise(
                    serviceBulkCreate(records)
                ) as Promise<ServiceResponse<TRecord>>;
            }

            return { create: useCallback(create, []) };
        };
    },

    /**
     * Creates conventional hook for using batched service list function for the supplied resource type.
     * Automatically handles cancellation tokens internally.
     *
     * ### Recommendation
     * Use `useNestedBatchedList` when route is nested!
     *
     * @param recordType
     * @param baseEndpoint
     * @param property
     * @param size
     */
    useBatchedList<TRecord, TQueryParams>(
        recordType: { new (): TRecord },
        baseEndpoint: string
    ): BatchedListServiceHook<TRecord, TQueryParams> {
        return () => {
            const { cancellablePromise } = useCancellablePromise();

            const serviceList = ServiceFactory.batchedList<
                TRecord,
                TQueryParams
            >(recordType, baseEndpoint);

            function list(
                queryParams: TQueryParams,
                property: keyof TQueryParams,
                size: number
            ): Promise<ServiceResponse<TRecord>> {
                return cancellablePromise(
                    serviceList(queryParams, property, size)
                ) as Promise<ServiceResponse<TRecord>>;
            }

            return { list: useCallback(list, []) };
        };
    },

    /**
     * Creates conventional hook for using service create function for the supplied resource type.
     * Automatically handles cancellation tokens internally.
     *
     * ### Recommendation
     * Use `useNestedCreate` when route is nested!
     *
     * @param recordType
     * @param baseEndpoint
     */
    useCreate<TRecord extends RecordType>(
        recordType: { new (): TRecord },
        baseEndpoint: string
    ): CreateServiceHook<TRecord> {
        return () => {
            const { cancellablePromise } = useCancellablePromise();

            const serviceCreate = ServiceFactory.create(
                recordType,
                baseEndpoint
            );

            function create(
                record?: TRecord
            ): Promise<ServiceResponse<TRecord>> {
                return cancellablePromise(
                    serviceCreate(record ?? new recordType())
                ) as Promise<ServiceResponse<TRecord>>;
            }

            return { create: useCallback(create, []) };
        };
    },

    /**
     * Creates conventional hook for using service delete function for the supplied resource type.
     * Automatically handles cancellation tokens internally.
     * @param recordType
     * @param baseEndpoint
     */
    useDelete<TPathParams extends any>(
        resourceEndpoint: string
    ): DeleteServiceHook {
        return () => {
            const { cancellablePromise } = useCancellablePromise();

            const serviceDelete =
                ServiceFactory.delete<TPathParams>(resourceEndpoint);

            function _delete(
                id: number,
                pathParams?: any
            ): Promise<ServiceResponse<Boolean>> {
                return cancellablePromise(
                    serviceDelete(id, pathParams)
                ) as Promise<ServiceResponse<Boolean>>;
            }

            return { delete: useCallback(_delete, []) };
        };
    },

    /**
     * Creates conventional hook for using service get function for the supplied resource type.
     * Automatically handles cancellation tokens internally.
     * @param recordType
     * @param resourceEndpoint
     */
    useGet: RsmServiceHookFactory.useGet,

    /**
     * Creates conventional hook for using service list function for the supplied resource type.
     * Automatically handles cancellation tokens internally.
     *
     * ### Recommendation
     * Use `useNestedList` when route is nested!
     *
     * @param recordType
     * @param baseEndpoint
     */
    useList<TRecord, TQueryParams>(
        recordType: { new (): TRecord },
        baseEndpoint: string
    ): ListServiceHook<TRecord, TQueryParams> {
        return () => {
            const { cancellablePromise } = useCancellablePromise();

            const serviceList = ServiceFactory.list<TRecord, TQueryParams>(
                recordType,
                baseEndpoint
            );

            function list(
                queryParams?: TQueryParams
            ): Promise<ServiceResponse<TRecord>> {
                return cancellablePromise(serviceList(queryParams)) as Promise<
                    ServiceResponse<TRecord>
                >;
            }

            return { list: useCallback(list, []) };
        };
    },

    /**
     * Creates conventional hook for using batched service nested list function for the supplied resource type.
     * Automatically handles cancellation tokens internally.
     *
     * @param recordType
     * @param baseEndpoint
     * @param property
     * @param size
     */
    useNestedBatchedList<TRecord, TPathParams, TQueryParams>(
        recordType: { new (): TRecord },
        baseEndpoint: string
    ): NestedBatchedListServiceHook<TRecord, TPathParams, TQueryParams> {
        return () => {
            const { cancellablePromise } = useCancellablePromise();

            const serviceList = ServiceFactory.nestedBatchedList<
                TRecord,
                TPathParams,
                TQueryParams
            >(recordType, baseEndpoint);

            function list(
                pathParams: TPathParams,
                queryParams: TQueryParams,
                property: keyof TQueryParams,
                size: number
            ): Promise<ServiceResponse<TRecord>> {
                return cancellablePromise(
                    serviceList(pathParams, queryParams, property, size)
                ) as Promise<ServiceResponse<TRecord>>;
            }

            return { list: useCallback(list, []) };
        };
    },

    /**
     * Creates conventional hook for using service nested create function for the supplied resource type.
     * Automatically handles cancellation tokens internally.
     * @param recordType
     * @param baseEndpoint
     */
    useNestedCreate<TRecord extends RecordType, TPathParams>(
        recordType: { new (): TRecord },
        baseEndpoint: string
    ): NestedCreateServiceHook<TRecord, TPathParams> {
        return () => {
            const { cancellablePromise } = useCancellablePromise();

            const serviceCreate = ServiceFactory.nestedCreate<
                TRecord,
                TPathParams
            >(recordType, baseEndpoint);

            function create(
                record: TRecord,
                pathParams: TPathParams
            ): Promise<ServiceResponse<TRecord>> {
                return cancellablePromise(
                    serviceCreate(record, pathParams)
                ) as Promise<ServiceResponse<TRecord>>;
            }

            return { create: useCallback(create, []) };
        };
    },

    /**
     * Creates conventional hook for using service nested list function for the supplied resource type.
     * Automatically handles cancellation tokens internally.
     * @param recordType
     * @param baseEndpoint
     */
    useNestedList<TRecord, TPathParams, TQueryParams>(
        recordType: { new (): TRecord },
        baseEndpoint: string
    ): NestedListServiceHook<TRecord, TPathParams, TQueryParams> {
        return () => {
            const { cancellablePromise } = useCancellablePromise();

            const serviceList = ServiceFactory.nestedList<
                TRecord,
                TPathParams,
                TQueryParams
            >(recordType, baseEndpoint);

            function list(
                pathParams: TPathParams,
                queryParams?: TQueryParams
            ): Promise<ServiceResponse<TRecord>> {
                return cancellablePromise(
                    serviceList(pathParams, queryParams)
                ) as Promise<ServiceResponse<TRecord>>;
            }

            return { list: useCallback(list, []) };
        };
    },

    /**
     * Creates conventional hook for using service update function for the supplied resource type.
     * Automatically handles cancellation tokens internally.
     * @param recordType
     * @param baseEndpoint
     */
    useUpdate<
        TRecord extends RecordType,
        TPathParams,
        TQueryParams = undefined,
    >(
        recordType: { new (): TRecord },
        resourceEndpoint: string
    ): UpdateServiceHook<TRecord, TPathParams, TQueryParams> {
        return () => {
            const { cancellablePromise } = useCancellablePromise();

            const serviceUpdate = ServiceFactory.update<
                TRecord,
                TPathParams,
                TQueryParams
            >(recordType, resourceEndpoint);

            function update(
                record: TRecord,
                pathParams?: TPathParams,
                queryParams?: TQueryParams
            ): Promise<ServiceResponse<TRecord>> {
                return cancellablePromise(
                    serviceUpdate(record, pathParams, queryParams)
                ) as Promise<ServiceResponse<TRecord>>;
            }

            return { update: useCallback(update, []) };
        };
    },

    /**
     * Creates conventional hook for using service update function for an array of the supplied resource type.
     * Automatically handles cancellation tokens internally.
     * @param recordType
     * @param resourceEndpoint
     */
    useBulkUpdate<TRecord extends RecordType, TPathParams>(
        recordType: { new (): TRecord },
        resourceEndpoint: string
    ): BulkUpdateServiceHook<TRecord, TPathParams> {
        return () => {
            const { cancellablePromise } = useCancellablePromise();

            const serviceUpdate = ServiceFactory.bulkUpdate(
                recordType,
                resourceEndpoint
            );

            function update(
                records: Array<TRecord>,
                pathParams: TPathParams
            ): Promise<ServiceResponse<TRecord>> {
                return cancellablePromise(
                    serviceUpdate(records, pathParams)
                ) as Promise<ServiceResponse<TRecord>>;
            }

            return { update: useCallback(update, []) };
        };
    },
};

// #endregion Functions

// ---------------------------------------------------------------------------------------------
// #region Exports
// ---------------------------------------------------------------------------------------------

export default ServiceHookFactory;

// #endregion Exports
