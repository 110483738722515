import { RecordUtils } from "@rsm-hcd/javascript-core";
import { Record as ImmutableRecord } from "immutable";
import Tia from "models/interfaces/tia";
import moment from "moment";
import ExceptionSection from "organisms/section-detail-components/exception-section";
import FigureSection from "organisms/section-detail-components/figure-section";
import InformationalNoteSection from "organisms/section-detail-components/informational-note-section";
import { ReactNode } from "react";
import XmlUtils from "utilities/xml-utils";

const defaultValues: Tia = RecordUtils.defaultValuesFactory<Tia>({
    id: undefined,
    name: undefined,
    publicationId: undefined,
    externalId: undefined,
    type: undefined,
    title: undefined,
    titleAsPlainText: undefined,
    body: undefined,
    bodyRaw: undefined,
    caapsType: undefined,
    effectiveDate: undefined,
    changeSummary: undefined,
    annexId: undefined,
    articleId: undefined,
    chapterId: undefined,
    partId: undefined,
    rootSectionId: undefined,
});

export default class TiaRecord
    extends ImmutableRecord(defaultValues)
    implements Tia
{
    constructor(params?: Tia) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    /**
     * Returns the body (if it exists) as React components. See `xml-utils.ts` for more info.
     *
     * If no body is present, returns an empty string.
     */
    public getBody(debug?: boolean): ReactNode {
        // If we have no body, just return an empty string.
        if (this.body == null) {
            return "";
        }

        // Otherwise, converts the XML to React components using default and custom converters.
        const xmlConverters = {
            ...XmlUtils.defaultConverters,
            ...XmlUtils.publicationInlineConverters,
            ...XmlUtils.publicationTableConverters,
            div: XmlUtils.xmlConverter(XmlUtils.FragmentWrapper),
            exception: XmlUtils.xmlConverter(ExceptionSection),
            note: XmlUtils.xmlConverter(InformationalNoteSection),
            fig: XmlUtils.xmlConverter(FigureSection),
        };

        return XmlUtils.convert(this.body, xmlConverters, debug);
    }

    /**
     * Returns the title as React components (Usually only text, but <inline> elements can exist for changes).
     */
    public getTitle(debug?: boolean): ReactNode {
        // If we have no title, just return an empty string.
        if (this.title == null) {
            return "";
        }

        // Otherwise, converts the XML to React components using default and custom converters.
        const xmlConverters = {
            ...XmlUtils.defaultConverters,
            ...XmlUtils.publicationInlineConverters,
        };

        return XmlUtils.convert(this.title, xmlConverters, debug);
    }

    public getFormattedEffectiveDate() {
        return moment.utc(this.effectiveDate).format("MMMM Do YYYY");
    }

    public getFormattedLastDateEffective() {
        return moment
            .utc(this.effectiveDate)
            .subtract(1, "day")
            .format("MMMM Do YYYY");
    }
}
