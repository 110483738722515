import { XmlChangeNotationConstants } from "constants/xml-change-notation-constants";
import TiaType from "models/enumerations/tia-type";
import SectionRecord from "models/view-models/section-record";
import TiaRecord from "models/view-models/tia-record";
import IndicatorIcons from "organisms/indicator-buttons/indicator-icons";
import TiaBookviewHeading from "organisms/panels/tia-changes/tia-bookview-heading";
import React from "react";
import { CollectionUtils } from "utilities/collection-utils";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import useBookmarks from "utilities/hooks/domain/user-bookmarks/use-bookmarks";
import DeletedSectionContents from "./deleted-section-contents";

interface DeletedSectionProps {
    handleDiffPanelOpen: () => void;
    handleTiaClick: (tiaExternalId: string) => void;
    hasTiaOpen: boolean;
    section: SectionRecord;
    tias: TiaRecord[];
    cssBaseClass: string;
}

const DeletedSection = (props: DeletedSectionProps) => {
    const {
        handleDiffPanelOpen,
        handleTiaClick,
        hasTiaOpen,
        section,
        tias,
        cssBaseClass,
    } = props;

    const DELETED_SECTION_CLASSNAME = "c-deleted-section";
    const { bookmarks } = useBookmarks();
    const sectionBookmarks = bookmarks.filter(
        (b) => b.externalId === section.externalId
    );

    const hasBookmark = CollectionUtils.hasValues(sectionBookmarks);
    const { globalState } = useGlobalState();
    const showDeletedTias =
        globalState.getUserSetting("TiasDeletedShow")?.getValue<boolean>() ??
        false;
    const hasDeletedTIA =
        section.tiaChanges === XmlChangeNotationConstants.TIA_DELETION;
    const deletedTIA = tias.filter((tia) => tia.type === TiaType.Deletion)[0];
    const showDeletedTIASection =
        hasDeletedTIA && showDeletedTias && deletedTIA != null;
    return (
        <>
            {showDeletedTIASection && (
                <div className={`${DELETED_SECTION_CLASSNAME}`}>
                    {hasTiaOpen && <TiaBookviewHeading tias={tias} />}

                    <span className={`${cssBaseClass}__title`}>
                        <IndicatorIcons
                            tias={tias}
                            changes={XmlChangeNotationConstants.DELETION}
                            handleDiffPanelOpen={handleDiffPanelOpen}
                            handleTiaClick={handleTiaClick}
                        />

                        <span className={`${cssBaseClass}__title -deleted`}>
                            {deletedTIA.getTitle()}
                        </span>
                    </span>

                    <div className={`${DELETED_SECTION_CLASSNAME} -deleted`}>
                        {deletedTIA.getBody()}
                    </div>
                </div>
            )}
            {hasTiaOpen && !showDeletedTIASection && (
                <>
                    <TiaBookviewHeading tias={tias} />

                    {hasBookmark && (
                        <DeletedSectionContents
                            bookmarks={sectionBookmarks}
                            section={section}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default DeletedSection;
