import { RecordUtils } from "@rsm-hcd/javascript-core";
import { Record } from "immutable";
import SupportUser from "models/interfaces/support-user";
import SupportUserSubscriptionRecord from "models/view-models/support-user-subscription-record";
import DateUtils from "utilities/date-utils";
import RoleType from "utilities/enumerations/role-type";

const defaultValues: SupportUser =
    RecordUtils.defaultValuesFactory<SupportUser>({
        id: undefined,
        fullName: undefined,
        externalId: undefined,
        email: undefined,
        eulaAccepted: false,
        lastLogin: undefined,
        subscriptions: [],
    });

export default class SupportUserRecord
    extends Record(defaultValues)
    implements SupportUser
{
    constructor(params?: SupportUser) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.subscriptions) {
            params.subscriptions = RecordUtils.ensureRecords(
                params.subscriptions,
                SupportUserSubscriptionRecord
            );
        }

        super(params);
    }

    formatLastLogin() {
        return DateUtils.formatDate(this.lastLogin);
    }

    getFreeTrialExpiration() {
        const expiration = this.subscriptions?.find(
            (ur) => ur.roleType === RoleType.FREE_TRIAL
        )?.expiresOn;

        if (!expiration) return null;

        return DateUtils.formatIsoDate(expiration);
    }

    hasHadFreeTrial() {
        return (
            this.subscriptions?.some(
                (ur) => ur.roleType === RoleType.FREE_TRIAL
            ) ?? false
        );
    }

    getFreeTrial() {
        return this.subscriptions?.find(
            (ur) => ur.roleType === RoleType.FREE_TRIAL
        );
    }

    public with(values: Partial<SupportUser>): SupportUserRecord {
        return new SupportUserRecord(Object.assign(this.toJS(), values));
    }
}
