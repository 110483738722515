import React, { useState } from "react";
import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import DisplayInformation from "./account-display-information";
import LocalizationUtils from "utilities/localization-utils";
import Button from "atoms/buttons/button";
import LanguagePreferencesModal from "organisms/modals/language-preferences-modal/language-preferences-modal";
import { ButtonStyles } from "atoms/constants/button-styles";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import { useLocalization } from "utilities/hooks/use-localization";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
const BASE_CLASS = "c-account-dashboard__information";

const AccountLanguagePreferences = () => {
    const { i18N } = useFeatureFlags();
    const { globalState } = useGlobalState();
    const { t } = useLocalization();
    const { isOnline } = useNetworkInformation();

    const editButtonLabel = t("edit");
    const languageLabel = t("language");

    const preferencesLabel = t("preference_plural");

    const preferredLanguageSetting =
        globalState.getUserSetting("PreferredLanguage");
    const [shouldOpenLanguageModal, setShouldOpenLanguageModal] =
        useState(false);

    const togglePrefferedLanguageModal = () =>
        setShouldOpenLanguageModal((previous: boolean) => !previous);

    return (
        i18N && (
            <>
                <div className={`${BASE_CLASS}__personal`}>
                    <Heading priority={HeadingPriority.Five}>
                        {preferencesLabel}
                    </Heading>
                    <DisplayInformation
                        heading={languageLabel}
                        information={
                            <span
                                className={`${BASE_CLASS}__personal__language`}>
                                {LocalizationUtils.getLanguageCodeFullLabel(
                                    preferredLanguageSetting?.value
                                )}
                                <Button
                                    disabled={!isOnline}
                                    onClick={togglePrefferedLanguageModal}
                                    style={ButtonStyles.Anchor}>
                                    {editButtonLabel}
                                </Button>
                            </span>
                        }
                    />
                </div>

                <LanguagePreferencesModal
                    isVisible={shouldOpenLanguageModal}
                    onHeaderClose={togglePrefferedLanguageModal}
                    onCancel={togglePrefferedLanguageModal}
                    onSave={togglePrefferedLanguageModal}
                />
            </>
        )
    );
};

export default AccountLanguagePreferences;
