import { HeadingPriority } from "atoms/constants/heading-priority";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import Heading from "atoms/typography/heading";
import SubscriptionType from "organisms/enums/subscription-type";
import React from "react";
import CultureResources from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization-utils";

interface SubscriptionTypeLabelProps {
    type: SubscriptionType;
    iconSize?: IconSizes;
    headingPriority?: HeadingPriority;
    textCssClassName?: string;
    showSize?: boolean;
}

const SubscriptionTypeLabel: React.FunctionComponent<
    SubscriptionTypeLabelProps
> = (props: SubscriptionTypeLabelProps) => {
    const {
        type,
        iconSize = IconSizes.Large,
        headingPriority = HeadingPriority.Two,
        showSize = true,
    } = props;

    let headingIcon: Icons;
    let heading = t(
        `subscriptionType-${type.toString()}` as keyof CultureResources
    );
    const upTo10UsersText = t("upToItem", {
        item: `10 ${t("user_plural")}`,
    });
    switch (type) {
        case SubscriptionType.Team:
            headingIcon = Icons.Team;
            heading = showSize
                ? `${heading} (${upTo10UsersText})`
                : `${heading}`;
            break;
        case SubscriptionType.Enterprise:
            headingIcon = Icons.Enterprise;
            heading = `${heading} (10+ ${t("user_plural")})`;
            break;
        default:
            headingIcon = Icons.Individual;
            break;
    }

    return (
        <React.Fragment>
            <Icon type={headingIcon} size={iconSize} />
            <Heading
                cssClassName={props.textCssClassName ?? ""}
                priority={headingPriority}>
                {heading}
            </Heading>
        </React.Fragment>
    );
};

export default SubscriptionTypeLabel;
