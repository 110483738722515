import { InputTypes } from "atoms/constants/input-types";
import { DataTestAttributes } from "interfaces/data-test-attributes";
import React, { forwardRef, Ref } from "react";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface CheckboxInputProperties
    extends Pick<DataTestAttributes, "dataTestId"> {
    checked: boolean;
    disabled?: boolean;
    label: string | React.ReactNode;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    tabindex?: number;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "e-checkbox";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const CheckboxInput: React.ForwardRefExoticComponent<
    CheckboxInputProperties & React.RefAttributes<HTMLLabelElement>
> = forwardRef((props: CheckboxInputProperties, ref: Ref<HTMLLabelElement>) => {
    const { checked, dataTestId, disabled, label, onChange, tabindex } = props;
    let className = BASE_CLASS_NAME;
    if (disabled) {
        className += " -disabled";
    }

    if (checked) {
        className += " -checked";
    }

    return (
        <label className={className} ref={ref}>
            {label}
            <input
                checked={checked}
                data-test-id={dataTestId}
                disabled={disabled}
                onChange={onChange}
                type={InputTypes.Checkbox}
                value={checked.toString()}
                tabIndex={tabindex}
            />
            <span className={`${BASE_CLASS_NAME}__checkmark`} />
        </label>
    );
});

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default CheckboxInput;

// #endregion Export
