import { siteMap } from "internal-sitemap";
import { useAtom } from "jotai";
import _ from "lodash";
import IdentityMetadataType from "models/enumerations/pendo/identity-meta-data-type";
import AccountIdentityMetadata from "models/interfaces/pendo/account";
import VisitorIdentityMetadata from "models/interfaces/pendo/visitor";
import React, { useEffect } from "react";
import PendoStateAtom from "utilities/atoms/pendo-state-atom";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import useIsNestedRoute from "utilities/hooks/routing/use-is-nested-route";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import { usePendoIdentityMetadata } from "utilities/hooks/use-pendo-identity-metadata";
import { RouteUtils } from "utilities/route-utils";

const Pendo: React.FC = () => {
    const { globalState } = useGlobalState();
    const { usePendo } = useFeatureFlags();
    const linkPendo: pendo.Pendo = pendo as pendo.Pendo;
    const { currentIdentity } = globalState;
    const { routeIsFreeAccess } = useIsNestedRoute();
    const isARedirectRoute =
        RouteUtils.assertCurrentUrl(siteMap.azureLogin) ||
        RouteUtils.assertCurrentUrl(siteMap.redirectToLogin);

    const isHome = RouteUtils.assertCurrentUrl(siteMap.home);
    const isPlansPage = RouteUtils.assertCurrentUrl(siteMap.signup.plans);

    const [isValidAccount, account] = usePendoIdentityMetadata(
        IdentityMetadataType.Account,
        currentIdentity
    );

    const [isValidVisitor, visitor] = usePendoIdentityMetadata(
        IdentityMetadataType.Visitor,
        currentIdentity
    );

    const [storedPendoState, setStoredPendoState] = useAtom(PendoStateAtom);

    const shouldPendoInitialize =
        usePendo &&
        !routeIsFreeAccess &&
        !isARedirectRoute &&
        !isHome &&
        !isPlansPage &&
        isValidVisitor &&
        isValidAccount;

    const shouldPendoIdentify =
        shouldPendoInitialize &&
        (isSameVisitor(visitor, storedPendoState?.visitor) ||
            isSameAccount(account, storedPendoState?.account));

    useEffect(() => {
        // If pendo should not initialize, stop
        if (!shouldPendoInitialize) {
            return;
        } else {
            // Wrapping check because pendo.isReady does not appear to be available when some ad blockers are in use
            if (typeof linkPendo.isReady === "function") {
                // If Pendo is already initialized, don't re-initialize it
                if (linkPendo.isReady()) {
                    return;
                }
                linkPendo.initialize({
                    visitor: visitor?.toJS() as pendo.IdentityMetadata,
                    account: account?.toJS() as pendo.IdentityMetadata,
                });
            }
        }
    }, [account, shouldPendoInitialize, visitor]);

    useEffect(() => {
        if (!shouldPendoIdentify) {
            return;
        }

        // Giving Pendo a new object via ToJS because Pendo changes the properties of the object
        linkPendo.identify({
            visitor: visitor?.toJS() as pendo.IdentityMetadata,
            account: account?.toJS() as pendo.IdentityMetadata,
        });

        setStoredPendoState({
            visitor,
            account,
        });
    }, [account, setStoredPendoState, shouldPendoIdentify, visitor]);

    return null;
};

const isSameVisitor = (
    currentVisitor?: VisitorIdentityMetadata,
    storedVisitor?: VisitorIdentityMetadata
) => _.isEqual(currentVisitor, storedVisitor);

const isSameAccount = (
    currentAccount?: AccountIdentityMetadata,
    storedAccount?: AccountIdentityMetadata
) => _.isEqual(currentAccount, storedAccount);

export default Pendo;
