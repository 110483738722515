import logo from "assets/images/nfpa-link-color.png";
import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import CreateAccountAnchor from "molecules/create-account-anchor/create-account-anchor";
import SubscriptionType from "organisms/enums/subscription-type";
import AccountLoginIssuesModal from "organisms/modals/account-login-issues-modal/account-login-issues-modal";
import GroupInvitationAccountLoginIssuesModal from "organisms/modals/account-login-issues-modal/group-invitation-account-login-issues-modal";
import * as React from "react";
import { useState } from "react";
import { useLocalization } from "utilities/hooks/use-localization";

export enum NewUserLoginFormType {
    GroupInvitation,
}

export interface NewUserLoginFormProps {
    defaultEmail?: string;
    displayLoginToasts?: boolean;
    freeTrialAgreementError?: string;
    freeTrialTermsAccepted?: boolean;
    isSigningIn?: boolean;
    newUserLoginFormType?: NewUserLoginFormType;
    onError?: (message?: string) => void;
    onFreeTrialTermsAccepted?: (accepted: boolean) => void;
    onSubmit?: () => boolean;
    submitButtonText?: string;

    /**
     * Optional callback that will be fired after successfully logging in the user.
     */
    onSuccess?: () => void;
    selectedSubscriptionType?: SubscriptionType;
    showLogo?: boolean;
    showSignInTitle?: boolean;
}

const NewUserLoginForm: React.FunctionComponent<NewUserLoginFormProps> = (
    props: NewUserLoginFormProps
) => {
    const [accountLoginIssuesModalIsOpen, setAccountLoginIssuesModalIsOpen] =
        useState<boolean>(false);

    const { t } = useLocalization();

    const getLoginIssuesModal = () => {
        switch (props.newUserLoginFormType) {
            case NewUserLoginFormType.GroupInvitation:
                return (
                    <GroupInvitationAccountLoginIssuesModal
                        closeDialog={() =>
                            setAccountLoginIssuesModalIsOpen(false)
                        }
                        isVisible={accountLoginIssuesModalIsOpen}
                    />
                );
            default:
                return (
                    <AccountLoginIssuesModal
                        closeDialog={() =>
                            setAccountLoginIssuesModalIsOpen(false)
                        }
                        isVisible={accountLoginIssuesModalIsOpen}
                    />
                );
        }
    };

    return (
        <div className="c-userlogin-new-form">
            {(props.showLogo ?? true) && (
                <img crossOrigin="anonymous" src={logo} alt={t("nfpaLogo")} />
            )}
            {(props.showSignInTitle ?? true) && (
                <Heading priority={HeadingPriority.One}>{t("signIn")}</Heading>
            )}

            <CreateAccountAnchor cssClassName="c-button" />

            {accountLoginIssuesModalIsOpen && getLoginIssuesModal()}
        </div>
    );
};

export default NewUserLoginForm;
