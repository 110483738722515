import React from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import useSubscription from "utilities/hooks/domain/subscriptions/use-subscription";
import AccountExpirationAlertBanner from "organisms/alerts/account-expiration-alert-banner";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface AccountExpirationAlertsProps {
    bannerClassName?: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const AccountExpirationAlerts: React.FC<AccountExpirationAlertsProps> = (
    props: AccountExpirationAlertsProps
) => {
    const { bannerClassName } = props;
    const { globalState } = useGlobalState();
    const subscriptionExpirationErrorWindowInDays =
        globalState.systemSettings?.subscriptionExpirationErrorWindowInDays;
    const subscriptionExpirationWarningWindowInDays =
        globalState.systemSettings?.subscriptionExpirationWarningWindowInDays;

    const currentIdentity = globalState.currentIdentity;
    const currentUserRole = currentIdentity?.getCurrentUserRole();
    const isGroupAdmin = currentIdentity?.isGroupAdmin() ?? false;
    const isExpired = currentUserRole?.isExpired() ?? false;
    const daysUntilSubscriptionExpiration =
        currentUserRole?.daysUntilSubscriptionExpiration();

    const { resultObject: subscription, loaded: subscriptionLoaded } =
        useSubscription(currentUserRole?.subscriptionId);

    if (
        daysUntilSubscriptionExpiration == null ||
        subscriptionExpirationErrorWindowInDays == null ||
        subscriptionExpirationWarningWindowInDays == null ||
        !subscriptionLoaded ||
        subscription?.id == null
    ) {
        return null;
    }

    return (
        <AccountExpirationAlertBanner
            cssClassName={bannerClassName}
            currentRole={currentUserRole?.role}
            daysUntilExpiration={daysUntilSubscriptionExpiration}
            errorWindowInDays={subscriptionExpirationErrorWindowInDays}
            isAutoRenewing={subscription.isAutoRenewing}
            isExpired={isExpired}
            isGroupAdmin={isGroupAdmin}
            pin={subscription.sku}
            warningWindowInDays={subscriptionExpirationWarningWindowInDays}
        />
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default AccountExpirationAlerts;

// #endregion Export
