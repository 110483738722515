import Heading from "atoms/typography/heading";
import { siteMap } from "internal-sitemap";
import SolutionRecord from "models/view-models/situational-navigation/solutions/solution-record";
import { LinkCardTypes } from "molecules/constants/link-card-types";
import LinkCard from "molecules/link-card/link-card";
import React from "react";
import { useLocalization } from "utilities/hooks/use-localization";
import { RouteUtils } from "utilities/route-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface SolutionRelationshipsSectionProps {
    relatedsolutions: Array<SolutionRecord>;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-related-solutions-section";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const RelatedSolutionsSection: React.FC<SolutionRelationshipsSectionProps> = (
    props: SolutionRelationshipsSectionProps
) => {
    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    const classNames = [CSS_CLASS_NAME];

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Hooks
    // -----------------------------------------------------------------------------------------

    const { t } = useLocalization();

    // #endregion Hooks

    return (
        <div className={classNames.join(" ")}>
            <Heading cssClassName={`${CSS_CLASS_NAME}__header`}>
                {t("solution_plural")}
            </Heading>
            {props.relatedsolutions.map((relatedSolution) => (
                <LinkCard
                    key={relatedSolution.id}
                    includeIcon={true}
                    label={relatedSolution.subtitle}
                    to={RouteUtils.getUrl(
                        siteMap.situationalNavigation.solutions,
                        { id: relatedSolution.id }
                    )}
                    type={LinkCardTypes.Link}>
                    {relatedSolution.title}
                </LinkCard>
            ))}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default RelatedSolutionsSection;

// #endregion Exports
