import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Icon from "atoms/icons/icon";
import Paragraph from "atoms/typography/paragraph";
import { siteMap } from "internal-sitemap";
import SubscriptionRecord from "models/view-models/subscription-record";
import SubscriptionDetailCardContent from "molecules/subscription-detail-card/subscription-detail-card-content";
import AccountExpirationAlerts from "organisms/alerts/account-expiration-alerts";
import SubscriptionCancellationModal from "organisms/modals/subscription-cancellation-modal/subscription-cancellation-modal";
import ContactModal from "pages/support/contact-modal";
import SalesforceConfirmationModal from "pages/support/salesforce-confirmation-modal";
import React, { Fragment, useState } from "react";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import RoleType from "utilities/enumerations/role-type";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import { useLocalization } from "utilities/hooks/use-localization";
import useModalActions from "utilities/hooks/use-modal-actions";
import StringUtils from "utilities/string-utils";
import SubscriptionDetailCardTeamAdmins from "./subscription-detail-card-team-admins";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface SubscriptionDetailCardProps {
    canCancelSubscription: boolean;
    canModifySubscription: boolean;
    isFreeTrial: boolean;
    isFromContactForm: boolean;
    subscription: SubscriptionRecord;
    teamName?: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS = "c-subscription-detail-card";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SubscriptionDetailCard: React.FC<SubscriptionDetailCardProps> = (
    props: SubscriptionDetailCardProps
) => {
    const {
        canCancelSubscription,
        canModifySubscription,
        isFreeTrial,
        isFromContactForm,
        subscription,
        teamName,
    } = props;

    const { globalState } = useGlobalState();
    const currentUserRole = globalState?.currentIdentity?.getCurrentUserRole();
    const isIndividualOrTeam =
        currentUserRole?.isTeamRole() || currentUserRole?.isIndividualRole();
    const { t } = useLocalization();
    const { isOnline } = useNetworkInformation();

    const isLaptop = useBreakpoint(
        Breakpoints.Laptop,
        BreakpointComparer.MaxWidth
    );

    const includeSubscriptionCancelationWithDetails = !isLaptop;

    const {
        handleClose: handleContactModalClose,
        handleOpen: handleContactModalOpen,
        isOpen: isContactModalOpen,
    } = useModalActions();

    const [cancelModalIsVisible, setCancelModalIsVisible] =
        useState<boolean>(false);

    const modifySubscriptionText = `${t("modify")} ${t("subscription")}`;
    const confirmModalMessage = isFromContactForm
        ? t("supportPage-messageConfirmation")
        : t("thankYouForBeingASubscriber");

    const icon = getIconType(subscription?.roleType);

    const roleNameKey = subscription.getRoleNameCultureKey();
    const subscriptionText = roleNameKey == null ? "--" : t(roleNameKey);
    const howToHelpText = t("support-howToHelp");
    const contactReasonOption = t("contactReasons-modifySubscription");

    const returnURL = siteMap.account.subscription;

    const renderCancelComponents = () => {
        if (!canCancelSubscription && !canModifySubscription) {
            return null;
        }

        return (
            <div className={`${BASE_CLASS}__modify-subscription`}>
                {canModifySubscription && (
                    <Button
                        disabled={!isOnline}
                        onClick={handleContactModalOpen}
                        size={ButtonSizes.Small}>
                        {modifySubscriptionText}
                    </Button>
                )}
                {canCancelSubscription && (
                    <Fragment>
                        <Button
                            disabled={!isOnline}
                            onClick={() => setCancelModalIsVisible(true)}
                            size={ButtonSizes.Small}
                            style={ButtonStyles.Destructive}>
                            {t("cancelItem", { item: t("subscription") })}
                        </Button>
                        <SubscriptionCancellationModal
                            closeDialog={() => setCancelModalIsVisible(false)}
                            isVisible={cancelModalIsVisible}
                            subscription={subscription}
                        />
                    </Fragment>
                )}
            </div>
        );
    };

    return (
        <Fragment>
            {!includeSubscriptionCancelationWithDetails &&
                renderCancelComponents()}

            <div
                className={`${BASE_CLASS} ${isFreeTrial ? "-free-trial" : ""}`}>
                <div className={`${BASE_CLASS}__title`}>
                    <Paragraph size={ParagraphSizes.Large}>
                        {t("subscriptionDetails")}
                    </Paragraph>
                </div>
                <div className={`${BASE_CLASS}__type`}>
                    <div className={`${BASE_CLASS}__type__icon`}>
                        <Icon size={IconSizes.Large} type={icon} />
                        <span>{subscriptionText}</span>
                    </div>

                    {StringUtils.hasValue(teamName) && (
                        <Paragraph cssClassName="-team-name">
                            {teamName}
                        </Paragraph>
                    )}
                </div>
                <SubscriptionDetailCardTeamAdmins />
                <SubscriptionDetailCardContent
                    daysUntilExpiration={
                        currentUserRole?.daysUntilSubscriptionExpiration()!
                    }
                    isFreeTrial={isFreeTrial}
                    subscription={subscription}
                />
                {isIndividualOrTeam && (
                    <AccountExpirationAlerts
                        bannerClassName={"-cta-alternate"}
                    />
                )}
                <ContactModal
                    closeDialog={handleContactModalClose}
                    isVisible={isContactModalOpen}
                    selectedReasonOption={contactReasonOption}
                    returnURL={returnURL}>
                    <p>{howToHelpText}</p>
                </ContactModal>

                {includeSubscriptionCancelationWithDetails &&
                    renderCancelComponents()}
            </div>

            <SalesforceConfirmationModal
                label={t("submissionConfirmation")}
                message={confirmModalMessage}
            />
        </Fragment>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

const getIconType = (roleType?: RoleType): keyof typeof Icons => {
    switch (roleType) {
        case RoleType.TEAM:
            return Icons.Team;
        case RoleType.ENTERPRISE:
            return Icons.Enterprise;
        default:
            return Icons.Individual;
    }
};

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default SubscriptionDetailCard;

// #endregion Exports
