import React from "react";
import {
    /* eslint-disable-next-line no-restricted-imports */
    Trans as I18NextTrans,
    TransProps,
} from "react-i18next";
import CultureResources from "utilities/interfaces/culture-resources";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

/**
 * Typed wrapper around `Trans` component from react-i18next
 */
const Trans = (props: TransProps<keyof CultureResources>) => (
    <I18NextTrans {...props} />
);

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default Trans;

// #endregion Export
