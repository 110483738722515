import React, { useEffect } from "react";
import TrainingContractPageForm from "./training-contract-page-form";
import { useTrainingContractAccessCode } from "utilities/hooks/domain/access-code/use-training-contract-access-code";
import InviteContentHeader from "organisms/content-headers/invite-content-header";
import { AppNameWithHtml } from "constants/app-name-tm";
import { t } from "utilities/localization-utils";
import FullScreenTransition from "organisms/full-screen-transition/full-screen-transition";
import TrainingContractPageError from "./training-contract-page-error";
import { Redirect, useParams } from "react-router-dom";
import useGetTrainingContract from "utilities/hooks/domain/training-contracts/use-get-training-contract";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { siteMap } from "internal-sitemap";
import { ToastManager } from "utilities/toast/toast-manager";
import Anchor from "atoms/anchors/anchor";
import useGetTrianingContractUserRole from "utilities/hooks/domain/training-contract-user-roles/use-get-training-contract-user-role";
import { useUrlCallback } from "utilities/routing/url-callback/use-url-callback";

interface TrainingContractPageProps {}

interface TrainingContractPageParams {
    id: string;
}

export const TRAINING_CONTRACT_BASE_CLASS = "c-training-contract";

const TrainingContractPage = (props: TrainingContractPageProps) => {
    const { id } = useParams<TrainingContractPageParams>();
    const { callbackUrl, setCallbackUrlParam } = useUrlCallback();

    const { globalState } = useGlobalState();
    const { currentIdentity } = globalState;
    const userId = currentIdentity?.user?.id;

    const hasActiveSubscription = currentIdentity?.hasActiveSubscription();

    const {
        getTrainingContract,
        isLoading: isTrainingContractLoading,
        isNotFound,
        trainingContractRecord,
    } = useGetTrainingContract();

    const {
        getTrainingContractUserRole,
        hasPreexistingTrainingAccount,
        isLoading: isTrainingUserRoleRecordLoading,
        trainingContractUserRoleRecord,
    } = useGetTrianingContractUserRole();

    const trainingUserRole = trainingContractUserRoleRecord?.userRole;
    const hasExpiredTrainingRole = trainingUserRole?.isExpired() ?? false;

    const {
        handleSuccessfulAccessCode,
        isLoading: isAccessCodeLoading,
        verifiedAccessCode,
        errorMessages,
    } = useTrainingContractAccessCode(hasPreexistingTrainingAccount);

    const headerText = t("trainingContractRegistrationHeader", {
        appNameTm: AppNameWithHtml,
        durationDays: trainingContractRecord?.subscriptionDurationInDays,
    });
    const organizationName = trainingContractRecord?.organizationName ?? "";

    const isLoading =
        isAccessCodeLoading ||
        isTrainingContractLoading ||
        isTrainingUserRoleRecordLoading;

    if (callbackUrl !== window.location.pathname) {
        setCallbackUrlParam(window.location.pathname);
    }

    // Triggers upon return from login screen with verified access code param in URL
    useEffect(() => {
        const shouldNotCreateUserRole =
            isAccessCodeLoading ||
            isTrainingContractLoading ||
            trainingContractRecord?.id == null ||
            Number.isNaN(verifiedAccessCode);

        if (shouldNotCreateUserRole) return;

        handleSuccessfulAccessCode({
            id: trainingContractRecord?.id,
            accessCode: verifiedAccessCode,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isAccessCodeLoading,
        isTrainingContractLoading,
        trainingContractRecord?.id,
        verifiedAccessCode,
    ]);

    useEffect(() => {
        const contractId = Number(id);

        getTrainingContract(contractId);
    }, [getTrainingContract, id]);

    useEffect(() => {
        getTrainingContractUserRole(userId, trainingContractRecord?.id);
    }, [getTrainingContractUserRole, trainingContractRecord?.id, userId]);

    const hasActiveSubscriptionAndExpiredTrainingRole =
        hasActiveSubscription && hasExpiredTrainingRole;
    const hasActiveTrainingSubscription =
        hasPreexistingTrainingAccount && !hasExpiredTrainingRole;

    const shouldRedirectToDashboard =
        hasActiveSubscriptionAndExpiredTrainingRole ||
        hasActiveTrainingSubscription;

    useEffect(() => {
        if (hasActiveSubscription && !shouldRedirectToDashboard) {
            ToastManager.info(<ToastMessage />, {
                autoClose: false,
                closeButton: true,
            });
        }
    }, [hasActiveSubscription, shouldRedirectToDashboard]);

    if (isNotFound) return <Redirect to={siteMap.errors.notFound} />;

    if (shouldRedirectToDashboard)
        return <Redirect to={siteMap.dashboards.user} />;

    return (
        <>
            {isLoading ? (
                <FullScreenTransition transitionText="" />
            ) : (
                <section className={`${TRAINING_CONTRACT_BASE_CLASS}__wrapper`}>
                    <div className={`${TRAINING_CONTRACT_BASE_CLASS}__content`}>
                        <div
                            className={`${TRAINING_CONTRACT_BASE_CLASS}__left`}>
                            <InviteContentHeader
                                groupName={organizationName}
                                groupInfoTitle={`${t("providedBy")}`}>
                                <span
                                    className={`${TRAINING_CONTRACT_BASE_CLASS}__left__superscript`}
                                    dangerouslySetInnerHTML={{
                                        __html: headerText,
                                    }}
                                />
                            </InviteContentHeader>
                        </div>
                        <div
                            className={`${TRAINING_CONTRACT_BASE_CLASS}__right`}>
                            {errorMessages && (
                                <TrainingContractPageError
                                    message={errorMessages}
                                />
                            )}
                            <TrainingContractPageForm
                                contractAccessCode={
                                    trainingContractRecord?.accessCode ?? 0
                                }
                                contractId={trainingContractRecord?.id ?? 0}
                                onSuccessfulAccessCode={
                                    handleSuccessfulAccessCode
                                }
                            />
                        </div>
                    </div>
                </section>
            )}
        </>
    );
};
export default TrainingContractPage;

const ToastMessage = () => (
    <div>
        <p>{t("haveSubscription")}</p>
        <Anchor to={siteMap.dashboards.user}>
            <p>{t("selectTopicsPage-dashboardLink")}</p>
        </Anchor>
    </div>
);
