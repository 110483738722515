import { SkipNavContent } from "@reach/skip-nav";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import { LoaderStyles } from "atoms/constants/loader-styles";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Icon from "atoms/icons/icon";
import Loader from "atoms/loaders/loader";
import Paragraph from "atoms/typography/paragraph";
import { siteMap } from "internal-sitemap";
import { MetaTag, MetaTagTypes } from "models/interfaces/header-data";
import AnnouncementContextRecord from "models/view-models/announcement-context-record";
import AnnouncementRecord from "models/view-models/announcement-record";
import UserPublicationFavoriteRecord from "models/view-models/user-publication-favorite-record";
import AccountExpirationAlerts from "organisms/alerts/account-expiration-alerts";
import AnnouncementModal from "organisms/announcements/announcement-modal";
import DashboardFeaturedPublications from "organisms/dashboard-featured-publications/dashboard-featured-publications";
import FavoritePublications from "organisms/favorite-publications/favorite-publications";
import GettingStartedDashboardPanel from "organisms/getting-started-dashboard-panel/getting-started-dashboard-panel";
import RecentBookmarksList from "organisms/recent-bookmarks-list/recent-bookmarks-list";
import SalesforceConfirmationModal from "pages/support/salesforce-confirmation-modal";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import { Redirect } from "react-router-dom";
import { CollectionUtils } from "utilities/collection-utils";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import { useAnnouncementContext } from "utilities/contexts/use-announcement-context";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useHeaderData } from "utilities/contexts/use-header-data-context";
import useAnnouncements from "utilities/hooks/domain/announcements/use-announcements";
import useFeaturedBookPublications from "utilities/hooks/domain/publications/use-featured-book-publications";
import useDashboardBookmarks from "utilities/hooks/domain/user-bookmarks/use-dashboard-bookmarks";
import usePublicationFavorites from "utilities/hooks/domain/user-publication-favorites/use-publication-favorites";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import useLoading from "utilities/hooks/use-loading";
import { useLocalization } from "utilities/hooks/use-localization";
import StringUtils from "utilities/string-utils";
import { v4 } from "uuid";

interface UserDashboardPageProps {}

const BASE_CLASS_NAME = "c-dashboard";
const TITLE_ID = v4();

const META_TAGS: Array<MetaTag> = [
    {
        name: MetaTagTypes.Title,
        content: "NFPA LiNK® User Dashboard",
    },
    {
        name: MetaTagTypes.Description,
        content: "NFPA LiNK®: Content & Activity",
    },
];

const UserDashboardPage: React.FC<UserDashboardPageProps> = () => {
    const { t } = useLocalization();
    const networkState = useNetworkInformation();

    const loadingPublicationsMessage = t("loadingItem", {
        item: t("publication_plural"),
    });
    const { useOfflineFeatures } = useFeatureFlags();

    const { publications: featuredPublications } =
        useFeaturedBookPublications();

    const {
        favorites,
        setFavorites,
        loading: loadingFavorites,
        refresh: refreshFavorites,
    } = usePublicationFavorites();

    const favoritePublications = favorites.map((x) => x.publication);

    const featuredNotFavoritedPublications = featuredPublications.filter(
        (publication) =>
            !favoritePublications.some(
                (favorited) =>
                    publication.code === favorited.code &&
                    publication.edition === favorited.edition
            )
    );

    const setAndRefreshFavorites = useCallback(
        (favorites: Array<UserPublicationFavoriteRecord>): void => {
            setFavorites(favorites);
            refreshFavorites();
        },
        [refreshFavorites, setFavorites]
    );

    const publicationDataLoading = useLoading(loadingFavorites);

    const { loading: bookmarksLoading, bookmarks } = useDashboardBookmarks();

    const { globalState } = useGlobalState();
    const isConfiguredUser =
        globalState.currentIdentity?.isConfiguredWithActiveSubscription();

    useHeaderData({ metaTags: META_TAGS });

    const showFeaturedPublications =
        CollectionUtils.hasValues(featuredPublications);

    const { acknowledge } = useAnnouncements();

    const [announcementDismissed, setAnnouncementDismissed] =
        useState<boolean>(false);

    const { announcementContext, setAnnouncementContext } =
        useAnnouncementContext();

    const announcement = useMemo(
        () =>
            announcementContext.announcements.get(0) ??
            new AnnouncementRecord(),
        [announcementContext.announcements]
    );

    const showAnnouncement = useMemo(
        () =>
            !announcementDismissed &&
            announcement.shouldAlertUser() &&
            announcement.publishedOn != null &&
            StringUtils.isEmpty(announcement.url) &&
            isConfiguredUser,
        [announcement, announcementDismissed, isConfiguredUser]
    );

    const handleCloseAnnouncementModal = (acknowledged: boolean) => {
        if (!acknowledged) {
            return;
        }

        const announcementIndex = announcementContext.announcements.findIndex(
            (a) => a.id === announcement.id
        );
        const updatedRecord = announcement.with({
            acknowledged: acknowledge(announcement),
        });
        setAnnouncementContext((prev: AnnouncementContextRecord) =>
            prev.with({
                announcements: prev.announcements.update(
                    announcementIndex,
                    () => updatedRecord
                ),
            })
        );

        setAnnouncementDismissed(true);
    };

    if (useOfflineFeatures && !networkState.isOnline) {
        return <Redirect to={siteMap.dashboards.offline} />;
    }

    return (
        <Fragment>
            <SkipNavContent>
                <div className={BASE_CLASS_NAME}>
                    <div className={`${BASE_CLASS_NAME}__content`}>
                        <div
                            className={`${BASE_CLASS_NAME}__panel -left`}
                            tabIndex={-1}>
                            <SalesforceConfirmationModal />
                            <AccountExpirationAlerts />
                            <div
                                className={`${BASE_CLASS_NAME}__panel -left__title`}>
                                <Icon
                                    type={Icons.Book}
                                    size={IconSizes.Large}
                                />
                                <Paragraph
                                    id={TITLE_ID}
                                    size={ParagraphSizes.Large}>
                                    {t("publication_plural")}
                                </Paragraph>
                            </div>
                            {publicationDataLoading && (
                                <Loader
                                    accessibleText={loadingPublicationsMessage}
                                    type={LoaderStyles.LinkGlyphGray}
                                />
                            )}
                            {!publicationDataLoading && (
                                <nav
                                    aria-labelledby={TITLE_ID}
                                    className={`${BASE_CLASS_NAME}__publications`}>
                                    <FavoritePublications
                                        favoritePublications={
                                            favoritePublications
                                        }
                                        favorites={favorites}
                                        onChangeFavorites={
                                            setAndRefreshFavorites
                                        }
                                    />

                                    {showFeaturedPublications && (
                                        <DashboardFeaturedPublications
                                            featuredPublications={
                                                featuredNotFavoritedPublications
                                            }
                                            onFavoriteSelection={
                                                setAndRefreshFavorites
                                            }
                                        />
                                    )}
                                </nav>
                            )}
                        </div>
                        <div className={`${BASE_CLASS_NAME}__panel -right`}>
                            <RecentBookmarksList
                                bookmarks={bookmarks}
                                loading={bookmarksLoading}
                                loaded={!bookmarksLoading}
                            />
                            <GettingStartedDashboardPanel
                                cssClassName={`${BASE_CLASS_NAME}__panel -right__getting-started`}
                            />
                        </div>
                    </div>
                </div>
            </SkipNavContent>

            {showAnnouncement && (
                <AnnouncementModal
                    announcement={announcement}
                    isVisible={showAnnouncement}
                    onClose={handleCloseAnnouncementModal}
                />
            )}
        </Fragment>
    );
};

export default UserDashboardPage;
