import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { HeadingPriority } from "atoms/constants/heading-priority";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import { InputTypes } from "atoms/constants/input-types";
import Icon from "atoms/icons/icon";
import Heading from "atoms/typography/heading";
import UserCollection from "models/interfaces/user-collection";
import UserCollectionValidator from "models/validation/users/user-collections/user-collection-validator";
import UserCollectionRecord from "models/view-models/user-collection-record";
import { ModalCloseButtonStyle } from "molecules/constants/modal-close-button-style";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import InputFormField from "molecules/form-fields/input-form-field";
import Modal from "molecules/modals/modal";
import React, { useCallback, useEffect, useState } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useUpdateUserCollection from "utilities/hooks/domain/users/user-collections/use-update-user-collection";
import useBreakpoint from "utilities/hooks/use-breakpoint";
import { useLocalization } from "utilities/hooks/use-localization";
import { ObjectValidationResult } from "utilities/validation/object-validator/object-validator";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const ERROR_DUPLICATE_USERCOLLECTION_EXISTS_KEY =
    "UserCollectionsController.Create.DuplicateUserCollectionExists";
const ERROR_DUPLICATE_USERCOLLECTION_EXISTS_MESSAGE =
    "There is already a collection with this name. Please try a different name.";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface UpdateUserCollectionModalProps {
    closeDialog: () => void;
    isVisible: boolean;
    userCollection: UserCollectionRecord;
    onConfirm: () => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UpdateUserCollectionModal: React.FC<UpdateUserCollectionModalProps> = (
    props: UpdateUserCollectionModalProps
) => {
    const CSS_CLASS_NAME = "c-user-collection-modal";
    const validator = new UserCollectionValidator();
    const { closeDialog, isVisible, userCollection, onConfirm } = props;
    const isDesktop = useBreakpoint(Breakpoints.SmallTablet);
    const modalTransition = isDesktop
        ? ModalTransitions.Fade
        : ModalTransitions.SlideUp;

    const { t } = useLocalization();
    const cancelText = t("cancel");
    const renameItemText = t("renameItem", { item: t("collection") });
    const saveText = t("save");
    const collectionTitle = t("collectionTitle");
    const collectionTitlePlaceholder = t("collectionTitlePlaceholder");

    const [validationErrors, setValidationErrors] = useState<
        ObjectValidationResult<UserCollection>
    >({});
    const { updateUserCollection, pageErrors } = useUpdateUserCollection(
        userCollection!
    );
    const [collectionName, setCollectionName] = useState("");

    const reset = useCallback(() => {
        setCollectionName(userCollection.name);
    }, [setCollectionName, userCollection]);

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        setCollectionName(e.target.value);

    const handleSaveForm = async () => {
        const updatedUserCollection = userCollection.with({
            name: collectionName,
        });

        const validationResult = validator.validate(updatedUserCollection);
        setValidationErrors(validationResult);

        if (UserCollectionValidator.hasErrors(validationResult)) {
            return;
        }

        const result = await updateUserCollection(updatedUserCollection);
        if (result == null) {
            return;
        }
        onConfirm();
    };

    // Set the name of the collection.
    useEffect(() => {
        setCollectionName(userCollection.name);
    }, [setCollectionName, userCollection]);

    // When the modal is not visible, reset the form.
    useEffect(() => {
        if (!isVisible) {
            reset();
        }
    }, [isVisible, reset]);

    // Add server validation results to validation result when available
    useEffect(() => {
        if (CollectionUtils.isEmpty(pageErrors)) {
            return;
        }
        if (
            pageErrors.find(
                (key) => key === ERROR_DUPLICATE_USERCOLLECTION_EXISTS_KEY
            ) == null
        ) {
            return;
        }
        const result: ObjectValidationResult<UserCollectionRecord> = {
            name: [ERROR_DUPLICATE_USERCOLLECTION_EXISTS_MESSAGE],
        };
        setValidationErrors(result);
    }, [pageErrors]);

    return (
        <Modal
            closeButtonStyle={ModalCloseButtonStyle.InsideDialog}
            closeDialog={closeDialog}
            cssClassName={CSS_CLASS_NAME}
            isVisible={isVisible}
            label={renameItemText}
            transition={modalTransition}
            type={ModalTypes.Base}>
            <div className={`${CSS_CLASS_NAME}__header`}>
                <Heading priority={HeadingPriority.Two}>
                    {renameItemText}
                </Heading>
                <Button
                    accessibleText={t("cancel")}
                    cssClassName={"-modal-close -icon"}
                    onClick={closeDialog}
                    size={ButtonSizes.Medium}
                    style={ButtonStyles.Tertiary}>
                    <Icon type={Icons.Close} size={IconSizes.Large} />
                </Button>
            </div>
            <div className={`${CSS_CLASS_NAME}__content`}>
                <div className={`${CSS_CLASS_NAME}__container`}>
                    <InputFormField
                        errorMessages={validationErrors.name}
                        fieldId="collection_title"
                        isValid={CollectionUtils.isEmpty(validationErrors.name)}
                        label={collectionTitle}
                        maxLength={60}
                        name="collection_title"
                        placeholder={collectionTitlePlaceholder}
                        onChange={handleNameChange}
                        required={true}
                        showCharacterCount={true}
                        type={InputTypes.Text}
                        value={collectionName}
                    />
                </div>
            </div>
            <div className={`${CSS_CLASS_NAME}__footer`}>
                <div className={`${CSS_CLASS_NAME}__container`}>
                    <Button
                        onClick={closeDialog}
                        style={ButtonStyles.Secondary}>
                        {cancelText}
                    </Button>
                    <Button onClick={handleSaveForm}>{saveText}</Button>
                </div>
            </div>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default UpdateUserCollectionModal;

// #endregion Exports
