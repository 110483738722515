import { Record } from "immutable";
import type UserConfiguration from "models/interfaces/user-configuration";
import { RecordUtils } from "@rsm-hcd/javascript-core";

const defaultValues: UserConfiguration =
    RecordUtils.defaultValuesFactory<UserConfiguration>({
        isValid: false,
        userId: 0,
    });

export default class UserConfigurationRecord
    extends Record(defaultValues)
    implements UserConfiguration
{
    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: UserConfiguration) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<UserConfiguration>} values
     */
    public with(values: Partial<UserConfiguration>): UserConfigurationRecord {
        return new UserConfigurationRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}
