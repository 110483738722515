import Anchor from "atoms/anchors/anchor";
import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { HeadingPriority } from "atoms/constants/heading-priority";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import Heading from "atoms/typography/heading";
import PublicationTypes from "constants/publication-types";
import PublicationRecord from "models/view-models/publication-record";
import SectionRecord from "models/view-models/section-record";
import TableRecord from "models/view-models/table-record";
import { ModalCloseButtonStyle } from "molecules/constants/modal-close-button-style";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import Modal, { ModalProps } from "molecules/modals/modal";
import ContentPreview from "molecules/preview/content-preview";
import React from "react";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import { useReferencePanelContext } from "utilities/contexts/reference-panel/use-reference-panel-context";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useTias from "utilities/hooks/domain/publications/use-tias";
import useCultureCodeRoute from "utilities/hooks/routing/use-culture-code-route";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface CodesModalProps
    extends Omit<ModalProps, "transition" | "type" | "label"> {
    isLoading?: boolean;
    onClickOpenInBookView?: (
        e: React.MouseEvent<HTMLElement>,
        route?: string
    ) => void;
    route?: string;
    content: SectionRecord | TableRecord;
    subContent?: SectionRecord[];
    publication?: PublicationRecord;
    publicationDisplayTitle: string;
    publicationType: PublicationTypes | undefined;
    enableReferencePanel?: boolean;
    code?: string;
    edition?: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASS_NAME = "c-codes-modal";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const CodesModal: React.FunctionComponent<CodesModalProps> = (props) => {
    const {
        closeDialog,
        isLoading = false,
        isVisible,
        onClickOpenInBookView,
        content,
        subContent,
        publicationDisplayTitle,
        publicationType,
        publication,
        enableReferencePanel = false,
        code,
        edition,
    } = props;

    const { t } = useLocalization();
    const { isOnline } = useNetworkInformation();
    const { generateCultureCodedRoute } = useCultureCodeRoute();

    const { tias } = useTias(content?.externalId);

    let route = props.route;

    if (route == null) {
        route = content != null ? content.getRoute(publicationType) : "";
    }

    const isMobile = useBreakpoint(
        Breakpoints.SmallTablet,
        BreakpointComparer.MaxWidth
    );

    const transitionEffect = isMobile
        ? ModalTransitions.SlideUp
        : ModalTransitions.Fade;

    const modalType = isMobile ? ModalTypes.Fullscreen : ModalTypes.Base;

    const onClick = (event: React.MouseEvent<HTMLElement>) => {
        if (onClickOpenInBookView == null || route == null) {
            return;
        }

        onClickOpenInBookView(event, generateCultureCodedRoute(route));
    };

    const { handleAdd, referenceForSectionExists } = useReferencePanelContext();
    const handleAddReferenceClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        handleAdd(content?.id);
        closeDialog();
    };

    const referenceExists = referenceForSectionExists(content?.id);

    const referencePanelMobileAction: keyof CultureResources = referenceExists
        ? "openItem"
        : "addItem";

    const referencePanelDesktopAction: keyof CultureResources = referenceExists
        ? "reference-panel-open-in"
        : "reference-panel-add-to";
    const addToReferencePanelButtonCopy = isMobile
        ? t(referencePanelMobileAction, { item: t("reference") })
        : t(referencePanelDesktopAction);

    const footerModifierClass = enableReferencePanel
        ? "-with-reference-button"
        : "";

    return (
        <Modal
            closeButtonStyle={ModalCloseButtonStyle.InsideDialog}
            closeDialog={closeDialog}
            cssClassName={CSS_BASE_CLASS_NAME}
            isVisible={isVisible}
            label={t("code")}
            transition={transitionEffect}
            type={modalType}>
            <div
                className={`${CSS_BASE_CLASS_NAME}__header`}
                data-test-id={content?.externalId}>
                <Heading priority={HeadingPriority.Two}>{t("code")}</Heading>
                <Button
                    accessibleText={t("closeItem", { item: t("dialog") })}
                    cssClassName={"-modal-close -icon"}
                    onClick={closeDialog}
                    size={ButtonSizes.Medium}
                    style={ButtonStyles.Tertiary}>
                    <Icon type={Icons.Close} size={IconSizes.Large} />
                </Button>
            </div>
            <div className={`${CSS_BASE_CLASS_NAME}__body`}>
                <ContentPreview
                    isLoading={isLoading}
                    content={content}
                    subContent={subContent}
                    tias={tias}
                    publication={publication}
                    publicationDisplayTitle={publicationDisplayTitle}
                    code={code}
                    edition={edition}
                />
            </div>
            <div
                className={`${CSS_BASE_CLASS_NAME}__footer ${footerModifierClass}`}>
                {enableReferencePanel && (
                    <Button
                        accessibleText={addToReferencePanelButtonCopy}
                        cssClassName={"-icon-left"}
                        disabled={!isOnline}
                        onClick={handleAddReferenceClick}
                        style={ButtonStyles.Tertiary}>
                        <Icon
                            size={IconSizes.Large}
                            type={Icons.ReferencePanel}></Icon>
                        {addToReferencePanelButtonCopy}
                    </Button>
                )}
                <Anchor
                    cssClassName="c-button -secondary"
                    onClick={onClick}
                    to={route}>
                    {t("openInBookView")}
                </Anchor>
            </div>
        </Modal>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default CodesModal;

// #endregion Export
