import { PagedQuery } from "@rsm-hcd/javascript-core";
import BookTopicRecord from "models/view-models/book-topic-record";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface BookTopicQueryParams extends PagedQuery {}

export interface BookTopicBasePathParams {
    bookId: number;
}

export interface BookTopicPathParams extends BookTopicBasePathParams {
    id: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = "books/:bookId/topics";
const resourceEndpoint = `${baseEndpoint}/:id`;
const resourceType = BookTopicRecord;
const key = "book-topics";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const BookTopicService = {
    delete: ServiceFactory.delete(resourceEndpoint),
    list: ServiceFactory.nestedList<
        BookTopicRecord,
        BookTopicBasePathParams,
        BookTopicQueryParams
    >(resourceType, baseEndpoint),
    useList: ServiceHookFactory.useNestedList<
        BookTopicRecord,
        BookTopicBasePathParams,
        BookTopicQueryParams
    >(resourceType, baseEndpoint),
    useListQuery: QueryHookFactory.useNestedListQuery<
        BookTopicRecord,
        BookTopicBasePathParams,
        BookTopicQueryParams
    >(key, resourceType, baseEndpoint),
    update: ServiceFactory.bulkUpdate<BookTopicRecord, BookTopicPathParams>(
        resourceType,
        baseEndpoint
    ),
    useCreate: ServiceHookFactory.useNestedCreate<
        BookTopicRecord,
        BookTopicBasePathParams
    >(resourceType, baseEndpoint),
    useDelete:
        ServiceHookFactory.useDelete<BookTopicPathParams>(resourceEndpoint),
    useUpdate: ServiceHookFactory.useBulkUpdate<
        BookTopicRecord,
        BookTopicPathParams
    >(resourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default BookTopicService;

// #endregion Exports
