import MultiSelect, { MultiSelectProps } from "atoms/forms/multi-select";
import { SelectOption } from "atoms/forms/select";
import TopicRecord from "models/view-models/topic-record";
import React, { useMemo } from "react";
import { ActionMeta } from "react-select";
import useTopics from "utilities/hooks/domain/topics/use-topics";
import { useLocalization } from "utilities/hooks/use-localization";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------
export interface TopicsSelectsProps
    extends Pick<MultiSelectProps, "errorMessage"> {
    handlePrimaryTopicChange: (
        newValue: SelectOption<TopicRecord>,
        actionMeta?: ActionMeta<SelectOption<TopicRecord>>
    ) => void;
    handleSecondaryTopicsChange: (
        newValues: SelectOption<TopicRecord>[],
        actionMeta?: ActionMeta<SelectOption<TopicRecord>>
    ) => void;
    primaryTopicId?: string;
    secondaryTopicIds?: string[];
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-topics-selects";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const TopicsSelects: React.FC<TopicsSelectsProps> = (
    props: TopicsSelectsProps
) => {
    // -----------------------------------------------------------------------------------------
    // #region Hooks
    // -----------------------------------------------------------------------------------------

    const { t } = useLocalization();
    const {
        errorMessage,
        handlePrimaryTopicChange,
        handleSecondaryTopicsChange,
        primaryTopicId,
        secondaryTopicIds,
    } = props;
    const { resultObject: topics } = useTopics();

    // #endregion Hooks

    const topicSelectOptions = useMemo(
        () =>
            topics.map((topic: TopicRecord) => {
                const option: SelectOption<TopicRecord, string> = {
                    value: topic.id!.toString(),
                    label: topic.name,
                    data: topic,
                };
                return option;
            }),
        [topics]
    );

    const secondarySelectOptions = topicSelectOptions.filter(
        (tso) => tso.value !== primaryTopicId
    );

    const PRIMARY_SELECT_LABEL = `${t("topic-of-interest")} - ${t("primary")}`;
    const SECONDARY_SELECT_LABEL = `${t("topic-of-interest")} - ${t(
        "secondary"
    )} ${t("field-optional")}`;

    return (
        <div className={CSS_CLASS_NAME}>
            {/* Single Topic */}
            <MultiSelect
                cssClassName={`${CSS_CLASS_NAME}__primary`}
                errorMessage={errorMessage}
                isClearable={false}
                isMulti={false}
                label={PRIMARY_SELECT_LABEL}
                onChange={handlePrimaryTopicChange}
                options={topicSelectOptions}
                required={true}
                value={[primaryTopicId ?? ""]}
            />

            {/* Multiple Topics */}
            <MultiSelect
                cssClassName={`${CSS_CLASS_NAME}__secondary`}
                disabled={primaryTopicId == null}
                hideSelectedOptions={true}
                label={SECONDARY_SELECT_LABEL}
                onChange={handleSecondaryTopicsChange}
                options={secondarySelectOptions}
                value={secondaryTopicIds}
            />
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default TopicsSelects;

// #endregion Exports
