import { Do, ResultRecord } from "@rsm-hcd/javascript-core";
import RoleRecord from "models/view-models/role-record";
import UserRecord from "models/view-models/user-record";
import UserRoleRecord from "models/view-models/user-role-record";
import { useCallback, useEffect, useState } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import RoleType, { AdminRoleTypes } from "utilities/enumerations/role-type";
import AdminUserRoleService from "utilities/services/admin/admin-user-role-service";
import AdminUserService from "utilities/services/admin/admin-user-service";
import RoleService from "utilities/services/roles/role-service";
import {
    CreateService,
    DeleteService,
} from "utilities/services/service-factory";
import StringUtils from "utilities/string-utils";
import { ToastManager } from "utilities/toast/toast-manager";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const ERROR_CANNOT_REMOVE_OWN_ROLE =
    "Web.Admin.UserRolesController.ERROR_CANNOT_REMOVE_OWN_ROLE";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

/**
 * Custom hook to handle user management actions.
 */
export default function useUserManagement() {
    const { list: listUsersApi } = AdminUserService.useList();
    const { list: listUserRolesApi } = AdminUserRoleService.useList();
    const { create: createUserRoleApi } = AdminUserRoleService.useCreate();
    const { delete: deleteUserRoleApi } = AdminUserRoleService.useDelete();
    const { list: listRolesApi } = RoleService.useList();

    const [loadingUsers, setLoadingUsers] = useState(true);
    const [users, setUsers] = useState<Array<UserRecord>>([]);
    const [roles, setRoles] = useState<Array<RoleRecord>>([]);

    const searchUsers = useCallback(
        async (searchText: string, filterByRole: number | undefined) => {
            Do.try(async () => {
                setLoadingUsers(true);
                const usersResult = await listUsersApi({
                    includeRoleTypes:
                        filterByRole != null ? [filterByRole] : AdminRoleTypes,
                    searchText: StringUtils.hasValue(searchText)
                        ? searchText
                        : undefined,
                });
                const userIds = usersResult.resultObjects!.map(
                    (u: UserRecord) => u.id!
                );
                const userRolesResult = await listUserRolesApi({ userIds });
                setUsers(
                    mapUserRoleNavProps(
                        usersResult.resultObjects!,
                        userRolesResult.resultObjects!,
                        roles
                    )
                );
            })
                .catch(() =>
                    ToastManager.error("There was an issue refreshing users.")
                )
                .finally(() => setLoadingUsers(false));
        },
        [listUserRolesApi, listUsersApi, roles]
    );

    const updateUserRoles = useCallback(
        (userRoles: Array<UserRoleRecord>, editingUser?: UserRecord) => {
            Do.try(async () => {
                setLoadingUsers(true);

                const userRolesToDelete = findUserRolesToDelete(
                    userRoles,
                    editingUser
                );
                await createAndDeleteUserRoles(
                    userRoles,
                    userRolesToDelete,
                    createUserRoleApi,
                    deleteUserRoleApi
                );
                await searchUsers("", undefined);

                const action = CollectionUtils.isEmpty(userRolesToDelete)
                    ? "created"
                    : "updated";
                ToastManager.success(
                    `Admin roles have been ${action} successfully`
                );
            })
                .catch(handleApiError)
                .finally(() => setLoadingUsers(false));
        },
        [createUserRoleApi, deleteUserRoleApi, searchUsers]
    );

    useEffect(() => {
        Do.try(async () => {
            setLoadingUsers(true);

            const [usersResult, rolesResult] = await Promise.all([
                listUsersApi({ includeRoleTypes: AdminRoleTypes }),
                listRolesApi(),
            ]);

            const userIds = usersResult.resultObjects!.map(
                (u: UserRecord) => u.id!
            );
            const userRolesResult = await listUserRolesApi({ userIds });

            // we only care about admin roles here.
            const adminRoles = rolesResult.resultObjects!.filter(
                (role: RoleRecord) =>
                    AdminRoleTypes.some(
                        (roleType: RoleType) => role.roleType === roleType
                    )
            );

            setUsers(
                mapUserRoleNavProps(
                    usersResult.resultObjects!,
                    userRolesResult.resultObjects!,
                    adminRoles
                )
            );
            setRoles(adminRoles);
        })
            .catch(() =>
                ToastManager.error("There was an issue loading users.")
            )
            .finally(() => setLoadingUsers(false));
    }, [listUsersApi, listUserRolesApi, listRolesApi]);

    return {
        updateUserRoles,
        loadingUsers,
        searchUsers,
        roles,
        users,
    };
}

// #endregion Hook

// -------------------------------------------------------------------------------------------------
// #region Functions
// -------------------------------------------------------------------------------------------------

const createAndDeleteUserRoles = async (
    userRolesToCreate: Array<UserRoleRecord>,
    userRolesToDelete: Array<UserRoleRecord>,
    createApi: CreateService<UserRoleRecord>,
    deleteApi: DeleteService
) =>
    Promise.all<any>([
        ...userRolesToCreate.map((userRole: UserRoleRecord) =>
            createApi(userRole)
        ),
        ...userRolesToDelete.map((userRole: UserRoleRecord) =>
            deleteApi(userRole.id!, { id: userRole.id! })
        ),
    ]);

const findUserRolesToDelete = (
    newUserRoles: Array<UserRoleRecord>,
    user?: UserRecord
): Array<UserRoleRecord> => {
    if (user == null) {
        return [];
    }

    return user.userRoles!.filter(
        (existingUserRole: UserRoleRecord) =>
            !newUserRoles.some(
                (ur: UserRoleRecord) => existingUserRole.roleId === ur.roleId
            )
    );
};

const handleApiError = (result?: ResultRecord<UserRoleRecord>) => {
    if (result?.hasErrorFor(ERROR_CANNOT_REMOVE_OWN_ROLE)) {
        ToastManager.error("You cannot remove your own role.");
        return;
    }

    ToastManager.error("There was an issue updating the user roles.");
};

const mapUserRoleNavProps = (
    users: Array<UserRecord>,
    userRoles: Array<UserRoleRecord>,
    roles: Array<RoleRecord>
): Array<UserRecord> => {
    const userRolesWithRolesMapped = userRoles
        .map((ur: UserRoleRecord) => {
            // map Role onto UserRoles
            const role = roles.find((r: RoleRecord) => r.id === ur.roleId);
            return ur.with({ role });
        })
        .filter(
            // we only care about admin roles here
            (ur: UserRoleRecord) =>
                AdminRoleTypes.some(
                    (type: RoleType) => ur.role?.roleType === type
                )
        );

    return users.map((u: UserRecord) => {
        // map UserRoles onto Users
        const userRoles = userRolesWithRolesMapped.filter(
            (ur: UserRoleRecord) => ur.userId === u.id
        );
        return u.with({ userRoles });
    });
};

// #endregion Functions
