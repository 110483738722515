import { Icons } from "atoms/constants/icons";
import { InputTypes } from "atoms/constants/input-types";
import Icon from "atoms/icons/icon";
import { KeyboardConstants } from "constants/keyboard-constants";
import React, { forwardRef, Ref } from "react";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface CheckboxDivProperties {
    ariaLabel?: string;
    checked: boolean;
    checkedIcon?: Icons;
    uncheckedIcon?: Icons;
    disabled?: boolean;
    label?: string | React.ReactNode;
    onBlur?: ((event: React.FocusEvent<HTMLDivElement>) => void) | undefined;
    onChange: (newValue: boolean) => void;
    onFocus?: ((event: React.FocusEvent<HTMLDivElement>) => void) | undefined;
    onKeyDown?: (e: React.KeyboardEvent<HTMLSpanElement>) => void;
    tabIndex?: number;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-checkbox";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const Checkbox = forwardRef(
    (props: CheckboxDivProperties, ref: Ref<HTMLDivElement>) => {
        const {
            checked,
            checkedIcon,
            uncheckedIcon,
            disabled,
            label,
            onChange,
            onBlur,
            onFocus,
        } = props;
        let className = BASE_CLASS_NAME;
        if (disabled) {
            className += " -disabled";
        }

        if (checked) {
            className += " -checked";
        }

        const onKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>) => {
            event.stopPropagation();
            if (disabled) {
                return;
            }

            if (event.key === KeyboardConstants.Space) {
                event.preventDefault();
                onChange(!checked);
            }

            props.onKeyDown?.(event);
        };

        const handleOnClick = (
            event: React.MouseEvent<HTMLDivElement, MouseEvent>
        ): void => {
            event.preventDefault();
            event.stopPropagation();

            if (disabled) {
                return;
            }

            onChange(!checked);
        };

        const tabIndex = disabled ? -1 : (props.tabIndex ?? 0);

        let icon: Icons | null = null;
        if (checkedIcon != null && uncheckedIcon != null) {
            icon = checked ? checkedIcon : uncheckedIcon;
        }

        return (
            <div
                aria-checked={checked}
                aria-disabled={disabled}
                className={className}
                onBlur={onBlur}
                onClick={handleOnClick}
                onFocus={onFocus}
                onKeyDown={onKeyDown}
                ref={ref}
                role={InputTypes.Checkbox}
                tabIndex={tabIndex}>
                {label}
                <React.Fragment>
                    {icon != null ? (
                        <span className={`${BASE_CLASS_NAME}__icon-checkmark`}>
                            <Icon type={icon} />
                        </span>
                    ) : (
                        <span className={`${BASE_CLASS_NAME}__checkmark`} />
                    )}
                </React.Fragment>
            </div>
        );
    }
);

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default Checkbox;

// #endregion Export
