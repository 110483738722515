import { History } from "history";
import { siteMap } from "internal-sitemap";
import GroupInvitationRecord from "models/view-models/group-invitations/group-invitation-record";
import FrozenSubscriptionsAlert from "molecules/frozen-subscriptions-alert/frozen-subscriptions-alert";
import Moment from "moment";
import AcceptGroupInvitationForm from "organisms/accept-group-invitation/accept-group-invitation-form";
import React, { SetStateAction, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import OnboardingLayout from "templates/onboarding-layout";
import SignupLayout from "templates/signup-layout";
import { CollectionUtils } from "utilities/collection-utils";
import useConfigurableAlertMessages from "utilities/hooks/use-configurable-alert-messages";
import { useLocalization } from "utilities/hooks/use-localization";
import usePageErrors from "utilities/hooks/use-page-errors";
import { RouteUtils } from "utilities/route-utils";
import GroupInvitationService, {
    GroupInvitationListQueryParams,
    GroupInvitationResourcePathParams,
} from "utilities/services/group-invitations/group-invitation-service";
import { NestedListService } from "utilities/services/service-factory";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface AcceptGroupInvitationParams {
    groupId: string;
    token: string;
}

interface AcceptGroupInvitationPageProps {}

export interface AcceptGroupInvitationPageQueryParams {
    invitedBy: string;
    teamName: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const AcceptGroupInvitationPage: React.FC<AcceptGroupInvitationPageProps> = (
    props: AcceptGroupInvitationPageProps
) => {
    const { groupId, token } = useParams<AcceptGroupInvitationParams>();
    const [invitation, setInvitation] = useState<GroupInvitationRecord>(
        new GroupInvitationRecord()
    );

    const history = useHistory();
    const { t } = useLocalization();
    const { subscriptionFreezeActive } = useConfigurableAlertMessages();

    // Page errors + API service hooks
    const { handlePageLoadError } = usePageErrors();
    const { list: listGroupInvitationsApi } = GroupInvitationService.useList();

    const {
        invitedBy,
        teamName = t("acceptGroupInvitation-unavailableTeamname"),
    } = RouteUtils.queryStringToObject<AcceptGroupInvitationPageQueryParams>(
        window.location.search
    );

    useEffect(() => {
        listGroupInvitations(
            groupId!,
            handlePageLoadError,
            listGroupInvitationsApi,
            setInvitation,
            token!,
            history
        );
    }, [groupId, handlePageLoadError, listGroupInvitationsApi, token, history]);

    return (
        <>
            {subscriptionFreezeActive ? (
                <OnboardingLayout>
                    <FrozenSubscriptionsAlert />
                </OnboardingLayout>
            ) : (
                <SignupLayout>
                    <AcceptGroupInvitationForm
                        invitation={invitation}
                        invitedBy={invitedBy}
                        teamName={teamName}
                    />
                </SignupLayout>
            )}
        </>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Api Calls
// -----------------------------------------------------------------------------------------

const listGroupInvitations = async (
    groupId: string,
    handlePageLoadError: (result: any) => void,
    listGroupInvitationsApi: NestedListService<
        GroupInvitationRecord,
        GroupInvitationResourcePathParams,
        GroupInvitationListQueryParams
    >,
    setInvitation: React.Dispatch<SetStateAction<GroupInvitationRecord>>,
    token: string,
    history: History
) => {
    try {
        const response = await listGroupInvitationsApi(
            {
                groupId: parseInt(groupId, 10),
            },
            {
                isPending: true,
                token: token,
            }
        );
        const invitation = response.resultObjects![0];
        const now = Moment();
        if (
            CollectionUtils.isEmpty(response.resultObjects) ||
            invitation == null ||
            (invitation.expiresOn != null &&
                now.isAfter(Moment(invitation.expiresOn)))
        ) {
            history.push(siteMap.errors.notFound);
            return;
        }

        setInvitation(invitation);
    } catch (error) {
        history.push(siteMap.errors.notFound);
        handlePageLoadError(error);
    }
};

// #endregion Api Calls

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default AcceptGroupInvitationPage;

// #endregion Exports
