import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { IconSizes } from "atoms/constants/icon-sizes";
import MenuButton from "molecules/menu-button/menu-button";
import React from "react";
import { useLocalization } from "utilities/hooks/use-localization";

export interface UserCollectionListItemMenuProps {
    children: React.ReactNode;
    handleOpen: () => void;
}

const UserCollectionListItemMenu = (props: UserCollectionListItemMenuProps) => {
    const { handleOpen } = props;

    const { t } = useLocalization();
    const menuLabel = t("userCollectionMenu");
    const collectionVisibility = t("collectionVisibility");

    return (
        <>
            <MenuButton
                iconSize={IconSizes.Base}
                buttonAccessibleText={menuLabel}
                triggerButtonSize={ButtonSizes.Large}>
                <Button
                    accessibleText={collectionVisibility}
                    onClick={handleOpen}>
                    {collectionVisibility}
                </Button>
            </MenuButton>
            {props.children}
        </>
    );
};

export default UserCollectionListItemMenu;
