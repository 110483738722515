import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import Trans from "atoms/i18n/trans";
import Icon from "atoms/icons/icon";
import Paragraph from "atoms/typography/paragraph";
import UserCollectionShareType from "models/enumerations/user-collection-share-type";
import UserCollectionRecord from "models/view-models/user-collection-record";
import ButtonGroup from "organisms/button-groups/button-group";
import React, { Dispatch, SetStateAction, useMemo } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import RoleType from "utilities/enumerations/role-type";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import { useLocalization } from "utilities/hooks/use-localization";
import WorkGroupMultiSelect from "./workgroup-multiselect";

const BASE_CLASS_NAME = "c-user-collection-modal";

interface UserCollectionShareTypeFormProps {
    errorMessages?: string[];
    userCollection: UserCollectionRecord;
    setUserCollection: Dispatch<SetStateAction<UserCollectionRecord>>;
}

const UserCollectionShareTypeForm = (
    props: UserCollectionShareTypeFormProps
) => {
    const { errorMessages, userCollection, setUserCollection } = props;
    const { globalState } = useGlobalState();
    const { useWorkGroups } = useFeatureFlags();
    const { t } = useLocalization();

    const currentGroup = useMemo(
        () => globalState.currentIdentity?.getCurrentTeam(),
        [globalState]
    );
    const isEnterpriseGroup = useMemo(
        () => globalState.currentIdentity?.isCurrentRole(RoleType.ENTERPRISE),
        [globalState]
    );

    const getButtonStyle = (shareType: UserCollectionShareType) => {
        return userCollection.shareType === shareType
            ? ButtonStyles.Primary
            : ButtonStyles.Secondary;
    };
    const handleUserBtnClick = () => {
        setUserCollection((prev) => {
            return prev.with({
                groupId: undefined,
                shareType: UserCollectionShareType.User,
                workGroups: [],
            });
        });
    };
    const handleWorkGroupBtnClick = () => {
        setUserCollection((prev) => {
            return currentGroup == null
                ? prev
                : prev.with({
                      groupId: currentGroup.id,
                      shareType: UserCollectionShareType.WorkGroup,
                  });
        });
    };
    const handleTeamBtnClick = () => {
        setUserCollection((prev) => {
            return currentGroup == null
                ? prev
                : prev.with({
                      groupId: currentGroup.id,
                      shareType: UserCollectionShareType.Team,
                      workGroups: [],
                  });
        });
    };

    return (
        <div className={`${BASE_CLASS_NAME}__share-type`}>
            <div className="c-form-field">
                <label htmlFor="">{t("shareWith")}:</label>
                <ButtonGroup cssClassName={`${BASE_CLASS_NAME}__button-group`}>
                    <Button
                        onClick={handleUserBtnClick}
                        style={getButtonStyle(UserCollectionShareType.User)}
                        cssClassName={`${BASE_CLASS_NAME}__button-group__button-group-item`}>
                        {t("justYou")}
                    </Button>
                    {isEnterpriseGroup && useWorkGroups && (
                        <Button
                            onClick={handleWorkGroupBtnClick}
                            style={getButtonStyle(
                                UserCollectionShareType.WorkGroup
                            )}
                            cssClassName={`${BASE_CLASS_NAME}__button-group__button-group-item`}>
                            {t("workgroups")}
                        </Button>
                    )}
                    <Button
                        onClick={handleTeamBtnClick}
                        style={getButtonStyle(UserCollectionShareType.Team)}
                        cssClassName={`${BASE_CLASS_NAME}__button-group__button-group-item`}>
                        {t("entireTeam")}
                    </Button>
                </ButtonGroup>
            </div>
            {userCollection.shareType === UserCollectionShareType.WorkGroup && (
                <WorkGroupMultiSelect
                    errorMessages={errorMessages}
                    setUserCollection={setUserCollection}
                />
            )}
            {userCollection.shareType === UserCollectionShareType.Team && (
                <Paragraph
                    cssClassName={`${BASE_CLASS_NAME}__content__team-message`}>
                    <Icon type="Warning" size={IconSizes.Large} />
                    <span>
                        <Trans i18nKey="addUserCollectionModalTeamMessage">
                            This collection will be visible to
                            <strong>
                                <>{{ teamSize: currentGroup?.totalLicenses }}</>
                            </strong>
                            people on your team.
                        </Trans>
                    </span>
                </Paragraph>
            )}
        </div>
    );
};

export default UserCollectionShareTypeForm;
