import { ResultRecord, ServiceResponse } from "@rsm-hcd/javascript-core";
import OfflineBookRecord from "models/view-models/offline/offline-book-record";
import { useCallback } from "react";
import {
    useMutation,
    UseMutationOptions,
    UseMutationResult,
} from "react-query";
import NumberUtils from "utilities/number-utils";
import OfflineBooksService, {
    OfflineBooksResourcePathParams,
} from "utilities/services/offline/offline-books-service";

export default function useOfflineBooksCreateOrUpdateMutation(
    options?: UseMutationOptions<
        ServiceResponse<OfflineBookRecord>,
        ResultRecord<OfflineBookRecord>,
        OfflineBookRecord,
        OfflineBooksResourcePathParams
    >
): UseMutationResult<
    ServiceResponse<OfflineBookRecord>,
    ResultRecord<OfflineBookRecord>,
    OfflineBookRecord,
    OfflineBooksResourcePathParams
> {
    const { update: updateOfflineBook } = OfflineBooksService.useUpdate();
    const { create: createOfflineBook } = OfflineBooksService.useCreate();

    const createOrUpdateOfflineBook = useCallback(
        (variables: OfflineBookRecord) => {
            if (NumberUtils.isDefault(variables.id)) {
                return createOfflineBook(variables, {
                    offlineDeviceId: variables.offlineDeviceId,
                });
            }
            return updateOfflineBook(variables, {
                offlineDeviceId: variables.offlineDeviceId,
                id: variables.id,
            });
        },
        [createOfflineBook, updateOfflineBook]
    );

    // TODO: Convert to Factory
    return useMutation<
        ServiceResponse<OfflineBookRecord>,
        ResultRecord<OfflineBookRecord>,
        OfflineBookRecord,
        OfflineBooksResourcePathParams
    >(createOrUpdateOfflineBook, options);
}
