import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import GroupRecord from "models/view-models/group-record";
import Tooltip, { TooltipPlacement } from "molecules/tooltips/tooltip";
import TeamManagementTeamNameModal from "pages/account/tabs/tab-panels/team-management-team-name-modal";
import React, { Fragment, useState } from "react";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import { useLocalization } from "utilities/hooks/use-localization";
import useModalActions from "utilities/hooks/use-modal-actions";
import { t } from "utilities/localization-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface TeamManagementTeamNameFieldProps {
    group?: GroupRecord;
    onChange: (group: GroupRecord) => void;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS = "c-account-dashboard__team-name-field";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const TeamManagementTeamNameField: React.FC<
    TeamManagementTeamNameFieldProps
> = (props) => {
    const { group, onChange } = props;

    // -----------------------------------------------------------------------------------------
    // #region Custom Hooks
    // -----------------------------------------------------------------------------------------

    const { globalState } = useGlobalState();
    const { handleClose, handleOpen, isOpen } = useModalActions();
    const { t } = useLocalization();
    const { isOnline } = useNetworkInformation();
    const { bypassHermes } = useFeatureFlags();

    // #endregion Custom Hooks

    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------

    const [teamName, setTeamName] = useState(getTeamName(group));

    // #endregion State

    // -----------------------------------------------------------------------------------------
    // #region Computations
    // -----------------------------------------------------------------------------------------

    const canChangeTeamName =
        group != null && globalState.currentIdentity?.isGroupAdmin();

    // #endregion Computations

    // -----------------------------------------------------------------------------------------
    // #region Event Handlers
    // -----------------------------------------------------------------------------------------

    const handleGroupChange = async (group?: GroupRecord) => {
        if (group != null) {
            setTeamName(getTeamName(group));
            onChange(group);
        }
    };

    // #endregion Event Handlers

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    return (
        <div className={`${BASE_CLASS}`}>
            <div className={`${BASE_CLASS}__title`}>{t("teamName")}</div>
            <div className={`${BASE_CLASS}__name`}>{teamName}</div>

            {canChangeTeamName && (
                <Fragment>
                    <Tooltip
                        placement={TooltipPlacement.Right}
                        content={t("bypassHermes-feature-unavailable-tooltip")}
                        disabled={!bypassHermes}
                        durationInMs={0}
                        hideOnClick={true}>
                        <span>
                            <Button
                                disabled={!isOnline || bypassHermes}
                                onClick={handleOpen}
                                size={ButtonSizes.Small}
                                style={ButtonStyles.Anchor}>
                                {t("changeTeamName")}
                            </Button>
                        </span>
                    </Tooltip>
                    <TeamManagementTeamNameModal
                        closeDialog={handleClose}
                        group={group!}
                        isVisible={isOpen}
                        onChange={handleGroupChange}
                    />
                </Fragment>
            )}
        </div>
    );

    // #endregion Render
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Private Functions
// -----------------------------------------------------------------------------------------

const getTeamName = (group?: GroupRecord) =>
    group?.name ?? t("itemNotAvailable", { item: t("teamName") });

// #endregion Private Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default TeamManagementTeamNameField;

// #endregion Exports
