import { AnchorTargetTypes } from "@rsm-hcd/javascript-core";
import Anchor from "atoms/anchors/anchor";
import FileRecord from "models/view-models/file-record";
import React from "react";
import StringUtils from "utilities/string-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface FileLinkProps {
    accessibleText: string;
    /**
     * Allowing `children` to be a function allows
     * us to pass the link down to child components
     */
    children?:
        | string
        | React.ReactNode
        | React.ReactNodeArray
        | ((link: string) => string | React.ReactNode | React.ReactNodeArray);
    cssClassName?: string;
    file?: FileRecord;
    /**
     * whether or not to set target="_blank"
     * true by default.
     */
    openInNewTab?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const FileLink: React.FC<FileLinkProps> = (props: FileLinkProps) => {
    const CSS_CLASS_NAME = "c-file-link";
    const absoluteUrl = props.file?.presignedUrl ?? "";

    /**
     * If props.children is a function that returns a component,
     * call the function, passing the absolute file link as a parameter,
     * then return the component. If props.children is just a component,
     * just return props.children.
     */
    const renderChildren = () => {
        if (props.children == null) {
            return null;
        }

        try {
            const childrenFunc = props.children as (
                link: string
            ) => string | React.ReactNode;
            return childrenFunc(absoluteUrl);
        } catch (e) {
            return props.children;
        }
    };

    const classNames = [CSS_CLASS_NAME];

    if (StringUtils.isEmpty(absoluteUrl)) {
        classNames.push("-invalid");
    }

    if (StringUtils.hasValue(props.cssClassName)) {
        classNames.push(props.cssClassName);
    }

    const getTarget = (): AnchorTargetTypes | undefined => {
        if (StringUtils.isEmpty(absoluteUrl) || props.openInNewTab === false) {
            return undefined;
        }

        return AnchorTargetTypes.Blank;
    };

    return (
        <Anchor
            ariaLabel={props.accessibleText}
            external={true}
            to={absoluteUrl}
            cssClassName={classNames.join(" ")}
            target={getTarget()}>
            {renderChildren()}
        </Anchor>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default FileLink;

// #endregion Exports
