import Anchor from "atoms/anchors/anchor";
import FileLink from "atoms/anchors/file-link";
import FileRecord from "models/view-models/file-record";
import { ResourceRecord } from "models/interfaces/resource-record";
import { ListBoxItemClassName } from "molecules/lists/list-box";
import ResourceSubtextLabel from "organisms/admin/situational-navigation/solutions/resource-subtext-label";
import React from "react";
import StringUtils from "utilities/string-utils";
import { AnchorTargetTypes } from "@rsm-hcd/javascript-core";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AdminResourceListboxItemProps<T extends ResourceRecord> {
    file?: FileRecord;
    resource: T;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AdminResourceListboxItem = <T extends ResourceRecord>(
    props: AdminResourceListboxItemProps<T>
) => {
    const { resource, file } = props;

    const getResourceSubtext = (r: T, f?: FileRecord): string => {
        if (r.isExternal(true)) {
            return r.absoluteUrlDraft!;
        }

        return f?.fileName() ?? "File";
    };

    return (
        <div className={`${ListBoxItemClassName}__text__container`}>
            <div>
                {
                    // if
                    resource.isExternal(true) && (
                        <Anchor
                            external={true}
                            target={AnchorTargetTypes.Blank}
                            to={resource.absoluteUrlDraft!}>
                            {resource.titleDraft}
                        </Anchor>
                    )
                }
                {
                    // if
                    !resource.isExternal(true) && (
                        <FileLink
                            accessibleText={`Download ${resource.titleDraft}`}
                            file={file}>
                            {resource.titleDraft}
                        </FileLink>
                    )
                }
            </div>
            <div>
                {
                    // if
                    StringUtils.hasValue(resource.descriptionDraft) && (
                        <ResourceSubtextLabel
                            content={resource.descriptionDraft}
                        />
                    )
                }
            </div>
            <div>
                <ResourceSubtextLabel
                    content={getResourceSubtext(resource, file)}
                />
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default AdminResourceListboxItem;

// #endregion Exports
