import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import RichTextArea from "molecules/rich-text-area/rich-text-area";
import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { useLocalization } from "utilities/hooks/use-localization";
import StringUtils from "utilities/string-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ExpandableRichTextAreaProps {
    content: string;
    collapsedMaxHeight: number;
    hasExpandableContent?: boolean;
    setHasExpandableContent?: (hasExpandableContent: boolean) => void;
    cssClassName?: string;
    onAnchorMouseEnter?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    onAnchorMouseOut?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

/**
 * Set a max height on this component with CSS to get a more/less button.
 * @param props
 * @constructor
 */
const ExpandableRichTextArea: React.FC<ExpandableRichTextAreaProps> = (
    props: ExpandableRichTextAreaProps
) => {
    const CSS_CLASS_NAME = "c-expandable-rich-text-area";

    const innerRef = useRef<HTMLDivElement>(null);
    const [anchorHovered, setAnchorHovered] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const styles: CSSProperties = { maxHeight: props.collapsedMaxHeight };
    const { t } = useLocalization();

    useEffect(() => {
        const isOverflowed = () => {
            if (innerRef.current == null) {
                return false;
            }

            const innerHeight = innerRef.current.clientHeight;
            return innerHeight > props.collapsedMaxHeight;
        };

        if (isOverflowed() && props.setHasExpandableContent) {
            props.setHasExpandableContent(true);
        }
    }, [props]);

    const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        setExpanded(!expanded);
        e.preventDefault();
        e.stopPropagation();
    };

    const handleMouseEnter = (e: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorHovered(true);
        props.onAnchorMouseEnter?.(e);
    };

    const handleMouseOut = (e: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorHovered(false);
        props.onAnchorMouseOut?.(e);
    };

    const classNames = [CSS_CLASS_NAME];

    if (expanded) {
        classNames.push("-expanded");
        styles.maxHeight = "9999999px";
    }

    if (anchorHovered) {
        classNames.push("-anchor-hovered");
    }

    if (StringUtils.hasValue(props.cssClassName)) {
        classNames.push(props.cssClassName!);
    }

    return (
        <div className={classNames.join(" ")} style={styles}>
            <RichTextArea content={props.content} ref={innerRef} />
            {(props.hasExpandableContent ||
                Number(styles.maxHeight) > props.collapsedMaxHeight) && (
                <Button
                    style={ButtonStyles.Anchor}
                    cssClassName={"-expand-collapse"}
                    onClick={handleButtonClick}
                    onMouseEnter={handleMouseEnter}
                    onMouseOut={handleMouseOut}>
                    {expanded ? t("less") : t("more")}
                </Button>
            )}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default ExpandableRichTextArea;

// #endregion Exports
