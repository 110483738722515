import React, { useEffect, useState } from "react";

import Anchor from "atoms/anchors/anchor";
import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import RadioButton from "atoms/forms/radio-button-input";
import Icon from "atoms/icons/icon";
import Paragraph from "atoms/typography/paragraph";
import PublicationRecord from "models/view-models/publication-record";
import { ModalTypes } from "molecules/constants/modal-types";
import Modal, { ModalProps } from "molecules/modals/modal";
import RadioList from "molecules/radio-lists/radio-list";
import { CollectionUtils } from "utilities/collection-utils";
import { useLocalization } from "utilities/hooks/use-localization";
import useModalTransition from "utilities/hooks/use-modal-transition";
import { tErrorLoading } from "utilities/localization-utils";
import PublicationService from "utilities/services/publications/publication-service";
import { ToastManager } from "utilities/toast/toast-manager";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface EditionSelectModalProps
    extends Pick<ModalProps, "isVisible" | "closeDialog"> {
    currentPublication: PublicationRecord;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASS_NAME = "c-change-edition-modal";
const DATA_TEST_ID = "change-edition-modal";
const EDITIONS_RADIO_NAME = "editions";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ChangeEditionModal: React.FunctionComponent<EditionSelectModalProps> = (
    props
) => {
    const { closeDialog, isVisible, currentPublication } = props;

    const [publications, setPublications] = useState<PublicationRecord[]>([]);
    const [selectedEdition, setSelectedEdition] = useState(currentPublication);
    const [editionOptions, setEditionOptions] = useState<JSX.Element[]>([]);
    const { list } = PublicationService.useList();
    const [autofocus, setAutofocus] = useState<boolean>();

    const { t } = useLocalization();
    const transitionEffect = useModalTransition();

    const handleClose = () => {
        closeDialog?.();
        setSelectedEdition(currentPublication);
    };

    useEffect(() => {
        const listPublications = async () => {
            try {
                const { resultObjects } = await list({
                    code: currentPublication.code!,
                });

                if (CollectionUtils.isEmpty(resultObjects)) {
                    setPublications([]);
                    return;
                }

                setPublications(resultObjects);
            } catch (result) {
                ToastManager.error(tErrorLoading("publication_plural"));
            }
        };
        listPublications();
    }, [currentPublication, list, t]);

    useEffect(() => {
        if (CollectionUtils.isEmpty(publications)) {
            setEditionOptions([]);
        }

        const items = publications.map((publication: PublicationRecord) => {
            return (
                <RadioButton
                    autofocus={
                        autofocus && selectedEdition.id === publication.id
                    }
                    checked={selectedEdition.id === publication.id}
                    id={publication.id!.toString()}
                    key={publication.id!}
                    label={`${publication.edition} ${t("edition")}`}
                    name={EDITIONS_RADIO_NAME}
                    onCheck={() => {
                        setAutofocus(true);
                        setSelectedEdition(publication);
                    }}
                    value={publication.id}>
                    <Paragraph size={ParagraphSizes.XSmall}>
                        {publication.getDisplayTitle(false)}
                    </Paragraph>
                </RadioButton>
            );
        });
        setEditionOptions(items);
    }, [autofocus, publications, selectedEdition, t]);

    return (
        <Modal
            closeDialog={handleClose}
            cssClassName={CSS_BASE_CLASS_NAME}
            dataTestId={DATA_TEST_ID}
            isVisible={isVisible}
            label={t("changeEditionModal-selectPublicationEdition")}
            transition={transitionEffect}
            type={ModalTypes.Base}>
            <div className={`${CSS_BASE_CLASS_NAME}__header`}>
                <h2>{t("changeEdition")}</h2>
                <Button
                    accessibleText={t("close")}
                    cssClassName={"-modal-close -icon"}
                    onClick={handleClose}
                    size={ButtonSizes.Medium}
                    style={ButtonStyles.Tertiary}>
                    <Icon type={Icons.Close} size={IconSizes.Large} />
                </Button>
            </div>
            <div className={`${CSS_BASE_CLASS_NAME}__content`}>
                <span className={`${CSS_BASE_CLASS_NAME}__title`}>
                    {currentPublication.getDisplayTitle(false)}
                </span>
                <RadioList items={editionOptions!} />
            </div>
            <div className={`${CSS_BASE_CLASS_NAME}__footer`}>
                <Button
                    onClick={handleClose}
                    size={ButtonSizes.Medium}
                    style={ButtonStyles.Secondary}>
                    {t("cancel")}
                </Button>
                <Anchor
                    cssClassName="c-button -primary"
                    onClick={closeDialog}
                    to={selectedEdition.getRoute()}>
                    {t("changeEdition")}
                </Anchor>
            </div>
        </Modal>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default ChangeEditionModal;

// #endregion Exports
