import Button, { ButtonTypes } from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import Paragraph from "atoms/typography/paragraph";
import React, { ReactElement } from "react";
import { CollectionUtils } from "utilities/collection-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ListBoxItem<T> {
    customAction?: React.ReactNode | React.ReactNodeArray;
    id: T;
    label?: React.ReactNode | React.ReactNodeArray;
    text: React.ReactNode | React.ReactNodeArray;
}

export interface ListBoxProps<T> {
    actionText?: string;
    hideWhenNoItems?: boolean;
    items?: Array<ListBoxItem<T>>;
    onActionClick?: (id: T) => void;
    children?: React.ReactNode | React.ReactNodeArray;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

export const ListBoxBaseClassName = "c-list-box";
export const ListBoxItemClassName = `${ListBoxBaseClassName}__item`;

const ListBox = <T extends any>(
    props: ListBoxProps<T>
): ReactElement<ListBoxProps<T>> | null => {
    if (!CollectionUtils.hasValues(props.items) && props.items != null) {
        if (props.hideWhenNoItems === true) {
            return null;
        }

        return (
            <div className={`${ListBoxBaseClassName} -empty`}>
                <Paragraph>No Items Added</Paragraph>
            </div>
        );
    }

    return (
        <div className={ListBoxBaseClassName}>
            {CollectionUtils.hasValues(props.items) &&
                props.items!.map((item: ListBoxItem<T>) => (
                    <div className={ListBoxItemClassName} key={`${item.id}`}>
                        {
                            // if
                            item.label != null && (
                                <div
                                    className={`${ListBoxItemClassName}__label`}>
                                    {item.label}
                                </div>
                            )
                        }
                        <div className={`${ListBoxItemClassName}__text`}>
                            {item.text}
                        </div>
                        {
                            // if
                            props.onActionClick != null && (
                                <Button
                                    type={ButtonTypes.Button}
                                    cssClassName={`${ListBoxItemClassName}__action`}
                                    onClick={() =>
                                        props.onActionClick!(item.id)
                                    }
                                    size={ButtonSizes.Small}
                                    style={ButtonStyles.TertiaryAlt}>
                                    {props.actionText ?? "Action"}
                                </Button>
                            )
                        }
                        {item.customAction}
                    </div>
                ))}
            {props.children}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default ListBox;

// #endregion Exports
