import { ServiceResponse } from "@rsm-hcd/javascript-core";
import { GetService } from "@rsm-hcd/javascript-react";
import type ChapterRecord from "models/view-models/chapter-record";
import { useCallback, useEffect, useReducer } from "react";
import NumberUtils from "utilities/number-utils";
import ChapterService, {
    ChapterListQueryParams,
    ChapterResourcePathParams,
} from "utilities/services/chapters/chapter-service";
import usePageErrors from "utilities/hooks/use-page-errors";

// -----------------------------------------------------------------------------------------
// #region Types & Interfaces
// -----------------------------------------------------------------------------------------

interface UseChapterState {
    chapter?: ChapterRecord;
    loading: boolean;
    loaded: boolean;
}

type UseChapterAction =
    | { type: "errored" }
    | { type: "loading" }
    | { type: "loaded"; payload?: ChapterRecord };

// #endregion Types & Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const initialState: UseChapterState = {
    chapter: undefined,
    loading: false,
    loaded: false,
};

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Reducers
// -----------------------------------------------------------------------------------------

function reducer(
    state: UseChapterState,
    action: UseChapterAction
): UseChapterState {
    switch (action.type) {
        case "loaded":
            return {
                ...state,
                loading: false,
                loaded: true,
                chapter: action.payload,
            };
        case "loading":
            return { ...state, loading: true, loaded: false };
        case "errored":
        default:
            return initialState;
    }
}

// #endregion Reducers

// -----------------------------------------------------------------------------------------
// #region Custom Hook
// -----------------------------------------------------------------------------------------

const useChapter = (
    code: string,
    edition: string,
    nfpaLabel: string,
    chapterId?: number
) => {
    // -----------------------------------------------------------------------------------------
    // #region Hooks
    // -----------------------------------------------------------------------------------------

    const { list: getListApi } = ChapterService.useList();
    const { get } = ChapterService.useGet();
    const {
        handlePageLoadError,
        pageErrors: errors,
        resetPageErrors,
    } = usePageErrors();

    // #endregion Hooks

    // -----------------------------------------------------------------------------------------
    // #region useReducers
    // -----------------------------------------------------------------------------------------

    const [{ chapter, loaded, loading }, dispatch] = useReducer(
        reducer,
        initialState
    );

    // #endregion useReducers

    // -----------------------------------------------------------------------------------------
    // #region Functions
    // -----------------------------------------------------------------------------------------

    const fetchDefault = useCallback(_fetchByCodeEditionAndLabel, [
        getListApi,
        handlePageLoadError,
        resetPageErrors,
    ]);

    const fetchById = useCallback(_fetchById, [
        get,
        handlePageLoadError,
        resetPageErrors,
    ]);

    // #endregion Functions

    // -----------------------------------------------------------------------------------------
    // #region Side Effects
    // -----------------------------------------------------------------------------------------

    useEffect(
        function load() {
            if (code == null || edition == null || nfpaLabel == null) {
                return;
            }

            fetchDefault(
                code,
                edition,
                nfpaLabel,
                dispatch,
                resetPageErrors,
                handlePageLoadError,
                getListApi
            );
        },
        [
            code,
            edition,
            nfpaLabel,
            fetchDefault,
            getListApi,
            handlePageLoadError,
            resetPageErrors,
        ]
    );

    useEffect(
        function loadById() {
            if (NumberUtils.isDefault(chapterId)) {
                return;
            }
            fetchById(
                chapterId,
                dispatch,
                resetPageErrors,
                handlePageLoadError,
                get
            );
        },
        [chapterId, fetchById, get, handlePageLoadError, resetPageErrors]
    );

    // #endregion Side Effects

    // -----------------------------------------------------------------------------------------
    // #region Returns
    // -----------------------------------------------------------------------------------------

    return {
        errors,
        loaded,
        loading,
        chapter,
    };

    // #endregion Returns
};

// #endregion Custom Hook

// -----------------------------------------------------------------------------------------
// #region Private Functions
// -----------------------------------------------------------------------------------------

const _fetchByCodeEditionAndLabel = async (
    code: string,
    edition: string,
    nfpaLabel: string,
    dispatch: (value: UseChapterAction) => void,
    resetPageErrors: () => void,
    handlePageLoadError: (result: any) => void,
    getListApi: (
        queryParams?: ChapterListQueryParams | undefined
    ) => Promise<ServiceResponse<ChapterRecord>>
) => {
    resetPageErrors();
    dispatch({ type: "loading" });
    try {
        const response = await getListApi({
            code,
            edition,
            nfpaLabel,
        });

        const chapter = response?.resultObjects?.find(
            (c: ChapterRecord) => c.id !== null
        );

        dispatch({ type: "loaded", payload: chapter });
    } catch (e) {
        handlePageLoadError(e);
        dispatch({ type: "errored" });
    }
};

const _fetchById = async (
    id: number,
    dispatch: (value: UseChapterAction) => void,
    resetPageErrors: () => void,
    handlePageLoadError: (result: any) => void,
    get: GetService<ChapterRecord, ChapterResourcePathParams, undefined>
) => {
    resetPageErrors();
    dispatch({ type: "loading" });
    try {
        const response = await get({
            id,
        });

        const chapter = response.resultObject;

        dispatch({ type: "loaded", payload: chapter });
    } catch (e) {
        handlePageLoadError(e);
        dispatch({ type: "errored" });
    }
};

// #endregion Private Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default useChapter;

// #endregion Exports
