import Anchor from "atoms/anchors/anchor";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Paragraph from "atoms/typography/paragraph";
import { AppNameTm } from "constants/app-name-tm";
import UserConfigurationErrorKeys from "constants/user-configuration-error-keys";
import { MetaTagTypes } from "models/interfaces/header-data";
import FrozenSubscriptionsAlert from "molecules/frozen-subscriptions-alert/frozen-subscriptions-alert";
import StructuredData from "molecules/structured-data/structured-data";
import SubscriptionType from "organisms/enums/subscription-type";
import FullScreenTransition from "organisms/full-screen-transition/full-screen-transition";
import SubscriptionCards from "organisms/subscription-cards/subscription-cards";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useHeaderData } from "utilities/contexts/use-header-data-context";
import useSubscriptionCards from "utilities/hooks/domain/subscriptions/use-subscription-cards";
import usePlansStructuredData from "utilities/hooks/structured-data/use-plans-structured-data";
import useConfigurableAlertMessages from "utilities/hooks/use-configurable-alert-messages";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import { useLocalization } from "utilities/hooks/use-localization";
import useUnauthorizedResult from "utilities/hooks/use-unauthorized-result";
import LocalizationUtils from "utilities/localization-utils";
import { RouteUtils } from "utilities/route-utils";
import { ScrollUtils } from "utilities/scroll-utils";
import StringUtils from "utilities/string-utils";
import { siteMap } from "../../internal-sitemap";
import SelectPlanPageHeader from "./select-plan-page-header";
import useCurrentIdentity from "utilities/hooks/use-current-identity";

interface SelectPlanPageProps {}

const BASE_CLASS_NAME = "c-select-plan-page";

const FREE_TRIAL_REGISTRATION_ROUTE = RouteUtils.getRegistrationPageRoute(
    SubscriptionType.FreeTrial
);

const SelectPlanPage: React.FC<SelectPlanPageProps> = () => {
    const { t } = useLocalization();
    const { setMetaTags } = useHeaderData({
        title: t("plansPage-pageTitle", { appName: AppNameTm }),
    });
    const unauthorizedResult = useUnauthorizedResult(
        UserConfigurationErrorKeys.ERROR_CURRENT_ROLE_EXPIRED
    );
    const { build, loading } = useCurrentIdentity();
    const { isAuthenticated, globalState, setGlobalState } = useGlobalState();
    const {
        handleBillingCycleCheck,
        handleEnterpriseCardSelect,
        handleIndividualCardSelect,
        handleTeamCardSelect,
        resetModal,
        showBillingModal,
        selectedBillingCycle,
        selectedSubscriptionType,
    } = useSubscriptionCards();
    const plansStructuredData = usePlansStructuredData();
    const { usePlansPageStructuredData, useAzureB2CForSSO } = useFeatureFlags();
    const { subscriptionFreezeActive } = useConfigurableAlertMessages();

    const currentCultureCode =
        LocalizationUtils.currentCultureCode() ?? "en-US";

    const selectPlanDescriptionHtml =
        globalState.systemSettings?.subscriptionContentSettings
            ?.plansDescription[currentCultureCode] ?? "";

    const isCurrentRoleExpiredFreeTrial =
        globalState.currentIdentity?.isCurrentRoleExpiredFreeTrial();

    const [hasExpiredCurrentRoleError, setHasExpiredCurrentRoleError] =
        useState<boolean>(
            unauthorizedResult.hasErrorFor(
                UserConfigurationErrorKeys.ERROR_CURRENT_ROLE_EXPIRED
            )
        );

    const [identityLoading, setIdentityLoading] = useState<boolean>(false);
    const [identityLoaded, setIdentityLoaded] = useState<boolean>(false);

    useEffect(function initializeScrollPosition() {
        ScrollUtils.scrollToTop();
    }, []);

    useEffect(() => {
        setMetaTags([
            {
                name: MetaTagTypes.Title,
                content: `${AppNameTm}: Select a Plan to Get Started`,
            },
            {
                name: MetaTagTypes.Description,
                content: StringUtils.stripHtmlTags(selectPlanDescriptionHtml),
            },
        ]);
    }, [selectPlanDescriptionHtml, setMetaTags]);

    useEffect(() => {
        if (!hasExpiredCurrentRoleError || identityLoading || identityLoaded) {
            return;
        }

        build(globalState.currentIdentity?.userLogin);
    }, [
        build,
        globalState,
        hasExpiredCurrentRoleError,
        identityLoaded,
        identityLoading,
        setGlobalState,
        setIdentityLoaded,
        setIdentityLoading,
    ]);

    useEffect(() => {
        if (identityLoading && !loading) {
            setIdentityLoaded(true);
        }
        setIdentityLoading(loading);
    }, [identityLoading, loading]);

    // Listen for changes on the unauthorizedResult to see if the current role has expired error comes back.
    // We'll want to save this in state and show specific text to the user.
    useEffect(() => {
        if (hasExpiredCurrentRoleError) {
            return;
        }

        if (
            unauthorizedResult.doesNotHaveErrors() ||
            !unauthorizedResult.hasErrorFor(
                UserConfigurationErrorKeys.ERROR_CURRENT_ROLE_EXPIRED
            )
        ) {
            return;
        }

        setHasExpiredCurrentRoleError(true);
    }, [
        hasExpiredCurrentRoleError,
        setHasExpiredCurrentRoleError,
        unauthorizedResult,
    ]);

    if (identityLoading) {
        return <FullScreenTransition transitionText={t("loading")} />;
    }

    if (!isAuthenticated && useAzureB2CForSSO) {
        return <Redirect to={{ pathname: siteMap.home, hash: "plans" }} />;
    }

    return (
        <>
            {subscriptionFreezeActive ? (
                <FrozenSubscriptionsAlert />
            ) : (
                <React.Fragment>
                    {usePlansPageStructuredData && (
                        <StructuredData jsonLd={plansStructuredData} />
                    )}
                    <div className={BASE_CLASS_NAME}>
                        <SelectPlanPageHeader
                            hasExpiredCurrentRoleError={
                                hasExpiredCurrentRoleError
                            }
                            isCurrentRoleExpiredFreeTrial={
                                isCurrentRoleExpiredFreeTrial
                            }
                            selectPlanDescriptionHtml={
                                selectPlanDescriptionHtml
                            }
                        />
                        <SubscriptionCards
                            cssClassName={BASE_CLASS_NAME}
                            handleEnterpriseCardSelect={
                                handleEnterpriseCardSelect
                            }
                            handleIndividualCardSelect={
                                handleIndividualCardSelect
                            }
                            handleTeamCardSelect={handleTeamCardSelect}
                            resetModal={resetModal}
                            handleBillingCycleCheck={handleBillingCycleCheck}
                            selectedBillingCycle={selectedBillingCycle}
                            selectedSubscriptionType={selectedSubscriptionType}
                            showBillingModal={showBillingModal}
                            showNewBillingModal={useAzureB2CForSSO}
                        />
                        <div className={`${BASE_CLASS_NAME}__footer`}>
                            {!globalState?.currentIdentity?.hasHadFreeTrial() && (
                                <Paragraph size={ParagraphSizes.Large}>
                                    {t("plansPage-interestedInTryingLink", {
                                        appName: AppNameTm,
                                    })}
                                    &nbsp;
                                    <Anchor to={FREE_TRIAL_REGISTRATION_ROUTE}>
                                        {t("plansPage-freeTrial-callToAction", {
                                            appName: AppNameTm,
                                        })}
                                    </Anchor>
                                </Paragraph>
                            )}
                        </div>
                    </div>
                </React.Fragment>
            )}
        </>
    );
};

export default SelectPlanPage;
