import { PagedQuery } from "@rsm-hcd/javascript-core";
import UserBookmarkSortOption from "models/enumerations/sorting/user-bookmarks/user-bookmark-sort-option";
import UserBookmarkRecord from "models/view-models/user-bookmark-record";
import { UserBookmarkColors } from "utilities/enumerations/user-bookmark-colors";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";
import SearchQuery from "utilities/interfaces/search-query";
import SortedQuery from "utilities/interfaces/sorted-query";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// ---------------------------------------------------------------------------------------------
// #region Interfaces
// ---------------------------------------------------------------------------------------------

export interface UserBookmarkBasePathParams {}

export interface UserBookmarkResourcePathParams
    extends UserBookmarkBasePathParams {
    id: number;
}

/**
 * Search parameters when listing UserBookmark resources.
 * These get translated to QueryString parameters
 */
export interface UserBookmarkListQueryParams
    extends PagedQuery,
        SearchQuery,
        SortedQuery<UserBookmarkSortOption> {
    collectionId?: number;
    colors?: Array<UserBookmarkColors>;
    currentUserOnly?: boolean;
    includeRelatedNfpaLabels?: boolean;
    searchText?: string;
    userRoleId?: number;
    orderBy?: UserBookmarkSortOption;
}

export interface ListBookmarksQueryParams {
    code?: string;
    edition?: string;
    skip?: number;
    take?: number;
    searchText?: string;
    colors?: UserBookmarkColors[];
    orderBy?: UserBookmarkSortOption;
    currentUserOnly?: boolean;
    collectionId?: number;
}

// #endregion Interfaces

// ---------------------------------------------------------------------------------------------
// #region Constants
// ---------------------------------------------------------------------------------------------

const baseEndpoint = "userbookmarks";
const resourceType = UserBookmarkRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;
const v2Endpoint = `${baseEndpoint}/v2`;

// #endregion Constants

// ---------------------------------------------------------------------------------------------
// #region Service
// ---------------------------------------------------------------------------------------------
const key = "userbookmarks";

const UserBookmarkService = {
    key,
    create: ServiceFactory.create(resourceType, baseEndpoint),
    delete: ServiceFactory.delete(resourceEndpoint),
    get: ServiceFactory.get<UserBookmarkRecord, UserBookmarkResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),
    list: ServiceFactory.list<UserBookmarkRecord, UserBookmarkListQueryParams>(
        resourceType,
        baseEndpoint
    ),
    listV2: ServiceFactory.list<
        UserBookmarkRecord,
        UserBookmarkListQueryParams
    >(resourceType, v2Endpoint),
    update: ServiceFactory.update(resourceType, resourceEndpoint),
    useCreate: ServiceHookFactory.useCreate(resourceType, baseEndpoint),
    useDelete: ServiceHookFactory.useDelete(resourceEndpoint),
    useGet: ServiceHookFactory.useGet<
        UserBookmarkRecord,
        UserBookmarkResourcePathParams
    >(resourceType, resourceEndpoint),
    useList: ServiceHookFactory.useList<
        UserBookmarkRecord,
        UserBookmarkListQueryParams
    >(resourceType, baseEndpoint),
    useUpdate: ServiceHookFactory.useUpdate<
        UserBookmarkRecord,
        UserBookmarkResourcePathParams
    >(resourceType, resourceEndpoint),
    useListQuery: QueryHookFactory.useListQuery<
        UserBookmarkRecord,
        UserBookmarkListQueryParams
    >(key, resourceType, baseEndpoint),
    useListQueryV2: QueryHookFactory.useListQuery<
        UserBookmarkRecord,
        UserBookmarkListQueryParams
    >(key, resourceType, v2Endpoint),
};

// #endregion Service

// ---------------------------------------------------------------------------------------------
// #region Exports
// ---------------------------------------------------------------------------------------------

export default UserBookmarkService;

// #region Exports
