import { AnchorTargetTypes } from "@rsm-hcd/javascript-core";
import Anchor from "atoms/anchors/anchor";
import Button from "atoms/buttons/button";
import PublishStatus from "models/enumerations/publish-status";
import FreeAccessPublicationRecord from "models/view-models/free-access-publication-record";
import MenuButton from "molecules/menu-button/menu-button";
import PublishStatusBadge from "organisms/admin/situational-navigation/publish-status-badge";
import React from "react";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface FreeAccessPublicationsAdminTableRowProps {
    freeAccessPublication: FreeAccessPublicationRecord;
    onEditPublication: (args0: FreeAccessPublicationRecord) => void;
    toggleIsPublished: (
        publication: FreeAccessPublicationRecord
    ) => Promise<void>;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const FreeAccessPublicationsAdminTableRow = (
    props: FreeAccessPublicationsAdminTableRowProps
) => {
    const { freeAccessPublication, onEditPublication, toggleIsPublished } =
        props;

    const publicationRoute = `/free-access/publications/${freeAccessPublication.code}/${freeAccessPublication.edition}`;
    const codeAndEdition = `${freeAccessPublication.code} (${freeAccessPublication.edition})`;

    const publishText = freeAccessPublication.isPublished
        ? "Unpublish"
        : "Publish";

    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------
    // #endregion State

    // -----------------------------------------------------------------------------------------
    // #region Event Handlers
    // -----------------------------------------------------------------------------------------
    const editPublication = () => {
        onEditPublication(freeAccessPublication);
    };
    // #endregion Event Handlers

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    return (
        <tr>
            <td>{codeAndEdition}</td>
            <td>{freeAccessPublication.title}</td>
            <td className={`-status -right-col`}>
                <PublishStatusBadge
                    hasUnpublishedChanges={false}
                    status={
                        freeAccessPublication.isPublished
                            ? PublishStatus.Published
                            : PublishStatus.Unpublished
                    }
                />
            </td>
            <td className={"-collapse"}>
                <MenuButton buttonAccessibleText={"Options"}>
                    <Anchor
                        cssClassName="c-button"
                        to={publicationRoute}
                        target={AnchorTargetTypes.Blank}>
                        View Publication
                    </Anchor>
                    <Button onClick={editPublication}>Edit Details</Button>
                    <Button
                        onClick={() =>
                            toggleIsPublished(freeAccessPublication)
                        }>
                        {publishText}
                    </Button>
                </MenuButton>
            </td>
        </tr>
    );

    // #endregion Render
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default FreeAccessPublicationsAdminTableRow;

// #endregion Exports
