import SectionRecord from "models/view-models/section-record";
import { PagedQuery } from "@rsm-hcd/javascript-core";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface SectionQueryParams extends PagedQuery {
    /**
     * Return all sections with a matching parentId and recursively return their subsections
     */
    parentId?: number;

    /**
     * Filter by publication ID.
     */
    publicationId?: number;

    /**
     * Return sections with matching label or title
     */
    searchText?: string;

    /**
     * (Optional) Array of SectiondIds to filter results by.
     */
    sectionIds?: number[];

    /**
     * Return sections related to a solution with matching Id
     */
    solutionId?: number;

    /**
     * Return the matching section from the latest publication version of the matching code and edition
     */
    useLatest?: boolean;

    /**
     * Return sections with any of the specified externalIds.
     */
    externalIds?: Array<string>;
}

interface SectionResourcePathParams {
    id: number;
}

interface SectionExternalIdPathParams {
    publicationId: number;
    externalId: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = "sections";
const resourceEndpoint = `${baseEndpoint}/:id`;
const resourceType = SectionRecord;
const externalIdEndpoint = `${baseEndpoint}/externalId/:externalId`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const SectionService = {
    key: "sections",
    get: ServiceFactory.get<SectionRecord, SectionResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),

    list: ServiceFactory.list<SectionRecord, SectionQueryParams>(
        resourceType,
        baseEndpoint
    ),

    useBatchList: ServiceHookFactory.useBatchedList<
        SectionRecord,
        SectionQueryParams
    >(resourceType, baseEndpoint),

    useGet: ServiceHookFactory.useGet<SectionRecord, SectionResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),

    useGetByExternalId: ServiceHookFactory.useGet<
        SectionRecord,
        SectionExternalIdPathParams
    >(resourceType, externalIdEndpoint),

    useList: ServiceHookFactory.useList<SectionRecord, SectionQueryParams>(
        resourceType,
        baseEndpoint
    ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default SectionService;

// #endregion Exports
