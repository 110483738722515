import Button, { ButtonTypes } from "atoms/buttons/button";
import ModalCloseButton from "atoms/buttons/modal-close-button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import WorkGroupRecord from "models/view-models/work-group/work-group-record";
import { ModalCloseButtonStyle } from "molecules/constants/modal-close-button-style";
import { ModalTypes } from "molecules/constants/modal-types";
import InputFormField from "molecules/form-fields/input-form-field";
import Form from "molecules/forms/form";
import Modal, { ModalProps } from "molecules/modals/modal";
import React from "react";
import useWorkGroupModalActions from "utilities/hooks/domain/work-groups/use-work-group-modal-actions";
import { useLocalization } from "utilities/hooks/use-localization";
import WorkGroupManageUsers from "./work-group-manage-users";

const BASE_CLASS = "c-account-dashboard__modal";
const FORM_ID = "workgroup-form";

interface WorkGroupModalProps
    extends Pick<ModalProps, "isVisible" | "closeDialog"> {
    heading: string;
    loading: boolean;
    onSave: (workGroup: WorkGroupRecord) => Promise<void>;
    existingWorkGroup?: WorkGroupRecord;
}

const WorkgroupModal: React.FC<WorkGroupModalProps> = (props) => {
    const {
        closeDialog,
        isVisible,
        heading,
        loading,
        onSave,
        existingWorkGroup,
    } = props;

    const { isWorkGroupNameValid, setWorkGroup, updateWorkGroup, workGroup } =
        useWorkGroupModalActions(existingWorkGroup);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateWorkGroup({ title: e.currentTarget.value });
    };

    const isNewWorkGroup = existingWorkGroup == null;

    const handleClose = () => {
        if (isNewWorkGroup) {
            setWorkGroup((prev) =>
                prev.with({ title: "", workGroupMembers: [] })
            );
        } else {
            setWorkGroup(existingWorkGroup as WorkGroupRecord);
        }
        closeDialog();
    };

    const handleSubmit = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault();

        onSave(workGroup);

        handleClose();
    };

    const isDisabled = loading || !isWorkGroupNameValid;

    const { t } = useLocalization();
    const workGroupNameLabel = `${t("workgroup")} ${t("name")}`;
    const placeholder = `${t("newWorkgroupPlaceholder")}...`;
    const cancelText = t("cancel");
    const saveText = loading ? t("saving") : t("save");

    return (
        <Modal
            closeButtonStyle={ModalCloseButtonStyle.InsideDialog}
            closeDialog={handleClose}
            cssClassName={BASE_CLASS}
            isVisible={isVisible}
            label={heading}
            type={ModalTypes.Base}>
            <div className={`${BASE_CLASS}__header`}>
                <Heading priority={HeadingPriority.Two}>{heading}</Heading>
                <ModalCloseButton onClick={handleClose} />
            </div>
            <div className={`${BASE_CLASS}__body`}>
                <div
                    id={"workgroup-modal-content"}
                    className={`${BASE_CLASS}__content`}>
                    <Form id={FORM_ID}>
                        <InputFormField
                            autofocus={isNewWorkGroup}
                            isValid={true}
                            label={workGroupNameLabel}
                            maxLength={150}
                            onChange={handleChange}
                            placeholder={placeholder}
                            value={workGroup.title}
                        />
                        <WorkGroupManageUsers
                            workGroupMembers={workGroup.workGroupMembers ?? []}
                            updateWorkGroup={updateWorkGroup}
                        />
                    </Form>
                </div>
            </div>
            <div className={`${BASE_CLASS}__footer`}>
                <Button
                    accessibleText={cancelText}
                    onClick={handleClose}
                    style={ButtonStyles.Secondary}>
                    {cancelText}
                </Button>
                <Button
                    accessibleText={saveText}
                    ariaDisabled={isDisabled}
                    disabled={isDisabled}
                    form={FORM_ID}
                    onClick={handleSubmit}
                    type={ButtonTypes.Submit}>
                    {saveText}
                </Button>
            </div>
        </Modal>
    );
};

export default WorkgroupModal;
