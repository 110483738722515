import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import PublishStatus from "models/enumerations/publish-status";
import { PublishStatusMenuProps } from "organisms/admin/situational-navigation/publish-status-menu";
import React from "react";
import { useLocalization } from "utilities/hooks/use-localization";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface PublishStatusBadgeProps
    extends Pick<PublishStatusMenuProps, "hasDraftableFields"> {
    status: PublishStatus;
    hasUnpublishedChanges: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-publish-status-badge-with-icon";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const PublishStatusBadgeWithIcon: React.FC<PublishStatusBadgeProps> = (
    props: PublishStatusBadgeProps
) => {
    const classNames = [CSS_CLASS_NAME];
    const { t } = useLocalization();
    const { hasDraftableFields = true, hasUnpublishedChanges, status } = props;
    const isPublished = status === PublishStatus.Published;

    let statusClassModifier: string;

    statusClassModifier = isPublished ? "-published" : "-unpublished";

    if (hasUnpublishedChanges && hasDraftableFields) {
        statusClassModifier = `${statusClassModifier} -has-unpublished-changed`;
    }

    classNames.push(statusClassModifier);

    const getStatusLabel = () =>
        isPublished ? t("published") : t("unpublished");

    const getStatusIcon = () => (isPublished ? Icons.Eye : Icons.Edit);

    return (
        <div className={classNames.join(" ")}>
            <Icon type={getStatusIcon()} size={IconSizes.Base} />
            {getStatusLabel()}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default PublishStatusBadgeWithIcon;

// #endregion Exports
