import { Icons } from "atoms/constants/icons";
import SubscriptionType from "organisms/enums/subscription-type";
import SubscriptionCard from "organisms/subscription-card/subscription-card";
import SubscriptionFeatureList from "organisms/subscription-card/subscription-feature-list";
import React from "react";
import { SubscriptionFeaturesType } from "utilities/hooks/domain/subscriptions/use-subscription-features";
import { useLocalization } from "utilities/hooks/use-localization";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface EnterpriseSubscriptionCardProps {
    onSelect: () => void;
    cssClassNameModifier?: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const EnterpriseSubscriptionCard: React.FunctionComponent<
    EnterpriseSubscriptionCardProps
> = (props: EnterpriseSubscriptionCardProps) => {
    const { onSelect, cssClassNameModifier = "" } = props;
    const { t } = useLocalization();
    return (
        <SubscriptionCard
            billingText={t("subscriptionCard-enterprise-billingText")}
            buttonText={t("subscriptionCard-enterprise-buttonText")}
            headingIcon={Icons.Enterprise}
            onSelect={onSelect}
            price={t("subscriptionCard-enterprise-pricingVaries")}
            type={SubscriptionType.Enterprise}
            cssClassNameModifier={cssClassNameModifier}>
            <SubscriptionFeatureList
                type={SubscriptionFeaturesType.Enterprise}
            />
        </SubscriptionCard>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default EnterpriseSubscriptionCard;

// #endregion Export
