import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { Icons } from "atoms/constants/icons";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Icon from "atoms/icons/icon";
import Paragraph from "atoms/typography/paragraph";
import { SetStateAction } from "jotai";
import PublishStatus from "models/enumerations/publish-status";
import ChangeSummaryRecord from "models/view-models/change-summary-record";
import RichTextArea from "molecules/rich-text-area/rich-text-area";
import ChangeSummariesAdminActions from "organisms/admin/publications/change-summaries/change-summaries-admin-actions";
import PublishStatusBadge from "organisms/admin/situational-navigation/publish-status-badge";
import React, { Dispatch, useState } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useLocalization } from "utilities/hooks/use-localization";
import { v4 } from "uuid";

const BASE_CLASS_NAME = "c-change-summary";
const accordionContentId = v4();
const buttonId = v4();

interface ChangeSummariesContentProps {
    changeSummary: ChangeSummaryRecord;
    setChangeSummaries: Dispatch<SetStateAction<ChangeSummaryRecord[]>>;
}

const ChangeSummaryContent = ({
    changeSummary,
    setChangeSummaries,
}: ChangeSummariesContentProps) => {
    const { t } = useLocalization();
    const [isCollapsed, setCollapsed] = useState<boolean>(true);
    const { globalState } = useGlobalState();

    const handleToggle = () => {
        setCollapsed(!isCollapsed);
    };
    const updateChangeSummary = (changeSummary: ChangeSummaryRecord) => {
        setChangeSummaries((prev) =>
            prev.map((x) => {
                if (x.id !== changeSummary.id) return x;
                return changeSummary;
            })
        );
    };
    const removeChangeSummary = (changeSummaryId: number) => {
        setChangeSummaries((prev) =>
            prev.filter((x) => x.id !== changeSummaryId)
        );
    };

    const accessibleText = t("changeSummaryAction", {
        action: isCollapsed ? t("expand") : t("collapse"),
    });
    const expandOrCollapse = isCollapsed ? t("expand") : t("collapse");
    const changeSummaryHeader = t("changeSummaryHeader");
    const expandedClassName = !isCollapsed ? "-expanded" : "";

    const iconType = isCollapsed ? Icons.Expand : Icons.Collapse;

    const showAdminActions =
        (!isCollapsed && globalState.currentIdentity?.isAdmin()) ||
        globalState.currentIdentity?.isAuthorOrPublisher();

    const isAdminOrPublisher =
        globalState.currentIdentity?.isAdmin() ||
        globalState.currentIdentity?.isAuthorOrPublisher();

    const content = isAdminOrPublisher
        ? changeSummary.bodyDraft
        : changeSummary.body;

    return (
        <div className={BASE_CLASS_NAME}>
            <Button
                cssClassName={`${BASE_CLASS_NAME}__heading`}
                ariaControls={accordionContentId}
                accessibleText={accessibleText}
                ariaExpanded={!isCollapsed}
                onClick={handleToggle}
                id={buttonId}
                style={ButtonStyles.None}>
                <div>
                    <Icon type={Icons.Information} />
                    {changeSummaryHeader}
                </div>
                <div>
                    {expandOrCollapse}
                    <Icon cssClassName="-icon" type={iconType} />
                    <span className={"sr-only"}>{expandOrCollapse}</span>
                </div>
            </Button>
            <div
                aria-hidden={isCollapsed}
                aria-labelledby={buttonId}
                className={`${BASE_CLASS_NAME}__content ${expandedClassName}`}
                id={accordionContentId}
                role="region">
                <div
                    className={`${BASE_CLASS_NAME}__accordion ${expandedClassName}`}>
                    <RichTextArea content={content ?? ""} />
                </div>
            </div>
            {showAdminActions && (
                <div className={`${BASE_CLASS_NAME}__admin`}>
                    <div>
                        <Paragraph size={ParagraphSizes.XXSmall}>
                            {changeSummary.getDisplayStatusAndDate()}{" "}
                            {changeSummary.updatedByName != null &&
                                `by ${changeSummary.updatedByName}`}
                        </Paragraph>
                    </div>
                    <div className={`${BASE_CLASS_NAME}__admin__actions`}>
                        <PublishStatusBadge
                            status={changeSummary.publishStatus!}
                            hasUnpublishedChanges={
                                changeSummary.publishStatus ===
                                PublishStatus.PublishedWithUnpublishedChanges
                            }
                        />
                        <ChangeSummariesAdminActions
                            adminNote={changeSummary.adminNote ?? ""}
                            updateChangeSummary={updateChangeSummary}
                            removeChangeSummary={removeChangeSummary}
                            bookviewRoute={changeSummary.getBookviewRoute()}
                            changeSummary={changeSummary}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChangeSummaryContent;
