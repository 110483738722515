import { useMsal } from "@azure/msal-react";
import { siteMap } from "internal-sitemap";
import { useCallback } from "react";
import { RouteUtils } from "utilities/route-utils";
import B2C_CONFIG from "./b2c-config";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useUrlCallback } from "utilities/routing/url-callback/use-url-callback";

const useAzure = () => {
    const { instance } = useMsal();
    const { globalState } = useGlobalState();
    const currentCulture = globalState.getPreferredOrCurrentCulture();
    const { callbackUrl } = useUrlCallback();

    const login = useCallback(() => {
        instance.loginRedirect({
            scopes: [B2C_CONFIG.CLIENT_ID],
            redirectStartPage: callbackUrl ?? siteMap.dashboards.user,
            extraQueryParameters: { ui_locales: currentCulture },
        });
    }, [currentCulture, instance, callbackUrl]);

    const logout = useCallback(async () => {
        document.cookie =
            "AccessToken=; path=/; expires = Thu, 01 Jan 1970 00:00:00 GMT";
        return instance.logoutRedirect({
            postLogoutRedirectUri: B2C_CONFIG.POST_LOGOUT_REDIRECT_URI,
        });
    }, [instance]);

    const signUp = useCallback(
        (redirectStartPage) => {
            instance.loginRedirect({
                scopes: [B2C_CONFIG.CLIENT_ID],
                redirectStartPage: handleRedirect(redirectStartPage),
                extraQueryParameters: {
                    option: "signUp",
                    ui_locales: currentCulture,
                },
            });
        },
        [currentCulture, instance]
    );

    const forgotPassword = useCallback(() => {
        instance.loginRedirect({
            scopes: [B2C_CONFIG.CLIENT_ID],
            redirectStartPage: isComingFromLogin()
                ? siteMap.dashboards.user
                : window.location.pathname + window.location.search,
            authority: `https://${B2C_CONFIG.DOMAIN}/${B2C_CONFIG.TENANT}.onmicrosoft.com/${B2C_CONFIG.FORGOT_PASSWORD_POLICY}`,
            extraQueryParameters: { ui_locales: currentCulture },
        });
    }, [currentCulture, instance]);

    return {
        login,
        logout,
        signUp,
        forgotPassword,
    };
};

export default useAzure;

const isComingFromLogin = () => RouteUtils.assertCurrentUrl(siteMap.home);

const handleRedirect = (redirectStartPage: string) => {
    if (redirectStartPage) {
        return redirectStartPage;
    } else if (isComingFromLogin()) {
        return siteMap.dashboards.user;
    } else {
        return window.location.pathname + window.location.search;
    }
};
