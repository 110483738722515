import Button from "atoms/buttons/button";
import FullScreenTransition from "organisms/full-screen-transition/full-screen-transition";
import OrganizationManifestRecord from "models/view-models/organization-manifest-record";
import React, { useState } from "react";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import useOrganizationManifest from "utilities/hooks/domain/organizations/use-organization-manifest";
import { t } from "utilities/localization-utils";
import { Redirect, useParams } from "react-router-dom";
import { siteMap } from "internal-sitemap";
import OnboardingLayout from "templates/onboarding-layout";
import Heading from "atoms/typography/heading";
import { HeadingPriority } from "atoms/constants/heading-priority";
import Paragraph from "atoms/typography/paragraph";
import Trans from "atoms/i18n/trans";
import { ButtonStyles } from "atoms/constants/button-styles";
import OrganizationSupportModal from "organisms/organization-login/organization-support-modal";

interface OrganizationLoginPageParams {
    loginSlug: string;
}
export const ORGANIZATION_BASE_CLASS = "c-organization-login-page";

const OrganizationLoginPage = () => {
    const { allowOrganizationSSO } = useFeatureFlags();
    const { loginSlug } = useParams<OrganizationLoginPageParams>();
    const [showSupportModal, setShowSupportModal] = useState(false);

    const { isLoading, organizationManifest } = useOrganizationManifest({
        slug: loginSlug,
    });

    if (isLoading) {
        return <FullScreenTransition transitionText="" />;
    } else if ((!isLoading && !organizationManifest) || !allowOrganizationSSO) {
        return <Redirect to={siteMap.errors.notFound} />;
    }

    const handleLogin = (org: OrganizationManifestRecord) => {
        alert("Login clicked for " + org.name);
    };

    const handleSupportClick = () => {
        setShowSupportModal(true);
    };

    return (
        <OnboardingLayout showActions={false}>
            {organizationManifest && (
                <section className={`${ORGANIZATION_BASE_CLASS}`}>
                    <Heading
                        priority={HeadingPriority.One}
                        cssClassName={`${ORGANIZATION_BASE_CLASS}__heading`}>
                        {t("orgLoginHeading")}
                    </Heading>
                    <Paragraph
                        cssClassName={`${ORGANIZATION_BASE_CLASS}__subheading`}>
                        {t("orgLoginSubheading")}
                    </Paragraph>
                    <Paragraph
                        cssClassName={`${ORGANIZATION_BASE_CLASS}__helpText`}>
                        <Trans i18nKey="orgLoginHelpText">
                            For issues logging in, please{" "}
                            <Button
                                style={ButtonStyles.AnchorBlue}
                                cssClassName={`${ORGANIZATION_BASE_CLASS}__supportLink`}
                                onClick={handleSupportClick}>
                                contact your organization for help
                            </Button>
                            .
                        </Trans>
                    </Paragraph>
                    <div
                        className={`${ORGANIZATION_BASE_CLASS}__organizationBlock`}>
                        <span
                            className={`${ORGANIZATION_BASE_CLASS}__organizationLabel`}>
                            {t("organization")}:
                        </span>
                        <span
                            className={`${ORGANIZATION_BASE_CLASS}__organizationName`}>
                            {organizationManifest.name}
                        </span>
                    </div>
                    <Button
                        cssClassName={`${ORGANIZATION_BASE_CLASS}__loginButton`}
                        onClick={() => handleLogin(organizationManifest)}>
                        {t("logInToLink")}
                    </Button>
                    <OrganizationSupportModal
                        showModal={showSupportModal}
                        organization={organizationManifest}
                        onHideModal={() => {
                            setShowSupportModal(false);
                        }}
                    />
                </section>
            )}
        </OnboardingLayout>
    );
};

export default OrganizationLoginPage;
