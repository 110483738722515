import { SkipNavContent } from "@reach/skip-nav";
import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import AlertLevels from "constants/alert-levels";
import { AppNameTm } from "constants/app-name-tm";
import { siteMap } from "internal-sitemap";
import AlertBanner from "molecules/alerts/alert-banner";
import RouteTabs from "molecules/route-tabs/route-tabs";
import React, { useEffect, useMemo } from "react";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useHeaderData } from "utilities/contexts/use-header-data-context";
import useCultureCodeRoute from "utilities/hooks/routing/use-culture-code-route";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import { useLocalization } from "utilities/hooks/use-localization";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASS = "c-account-dashboard";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface AccountDashboardPageProps {}
interface TabEntry {
    label: string;
    route: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const AccountDashboardPage: React.FC<AccountDashboardPageProps> = (
    props: any
) => {
    const { t } = useLocalization();
    const { isOnline } = useNetworkInformation();
    const { bypassHermes } = useFeatureFlags();
    const pageTitle = t("accountDashboard-header");
    const informationLabel = t("yourItem", {
        item: t("information"),
    });
    const teamLabel = t("yourItem", {
        item: t("subscriptionType-Team"),
    });
    const teamManagementLabel = t("accountDashboard-teamManagement");
    const workGroupLabel = t("accountDashboard-workGroup");
    const subscriptionDetails = t("accountDashboard-subscriptionDetails");
    const offlineLabel = t("accountDashboard-offline");

    const { globalState } = useGlobalState();
    const { setPageTitle } = useHeaderData();
    const { generateCultureCodedRoute } = useCultureCodeRoute();
    const { useOfflineFeatures, useWorkGroups } = useFeatureFlags();

    // Memoize tabs to prevent issues with RouteTabs stealing focus from other components
    const tabs = useMemo(() => {
        let tabs: TabEntry[] = [];

        // Determine Organization Account page vs non-Organization Account Page
        if (globalState.currentIdentity?.isOrganizationMember()) {
            tabs.push({
                label: informationLabel,
                route: generateCultureCodedRoute(
                    siteMap.account.organizationMemberInformation
                ),
            });
        } else {
            tabs.push({
                label: informationLabel,
                route: generateCultureCodedRoute(siteMap.account.information),
            });
        }

        if (globalState.currentIdentity?.isGroupMember()) {
            tabs.push({
                label: globalState.currentIdentity.isGroupAdmin()
                    ? teamManagementLabel
                    : teamLabel,
                route: generateCultureCodedRoute(siteMap.account.team),
            });
        }
        if (
            useWorkGroups &&
            globalState.currentIdentity?.isAdminOfCurrentEnterpriseGroup()
        ) {
            tabs.push({
                label: workGroupLabel,
                route: generateCultureCodedRoute(siteMap.account.workGroup),
            });
        }
        if (
            globalState.currentIdentity?.getCurrentUserRole()?.isSubscription()
        ) {
            tabs.push({
                label: subscriptionDetails,
                route: generateCultureCodedRoute(siteMap.account.subscription),
            });
        }

        if (useOfflineFeatures) {
            tabs.push({
                label: offlineLabel,
                route: generateCultureCodedRoute(siteMap.account.offline),
            });
        }

        return tabs;
    }, [
        informationLabel,
        generateCultureCodedRoute,
        globalState.currentIdentity,
        useWorkGroups,
        useOfflineFeatures,
        teamManagementLabel,
        teamLabel,
        workGroupLabel,
        subscriptionDetails,
        offlineLabel,
    ]);

    useEffect(() => {
        setPageTitle(pageTitle);
    }, [setPageTitle, pageTitle]);

    return (
        <React.Fragment>
            <SkipNavContent>
                <div className={CSS_BASE_CLASS} tabIndex={-1}>
                    <Heading priority={HeadingPriority.Four}>
                        {pageTitle}
                    </Heading>
                    {!isOnline && (
                        <div className={`${CSS_BASE_CLASS}__offline-warning`}>
                            <AlertBanner alertLevel={AlertLevels.Warning}>
                                {t("offline-banner-default", {
                                    appName: AppNameTm,
                                })}
                            </AlertBanner>
                        </div>
                    )}
                    {isOnline && bypassHermes && (
                        <div className={`${CSS_BASE_CLASS}__offline-warning`}>
                            <AlertBanner alertLevel={AlertLevels.Warning}>
                                {t("hermes-off-banner-default")}
                            </AlertBanner>
                        </div>
                    )}
                    <RouteTabs routes={props.routes} tabs={tabs} />
                </div>
            </SkipNavContent>
        </React.Fragment>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default AccountDashboardPage;

// #endregion Exports
