import { GetService } from "@rsm-hcd/javascript-react";
import BookRecord from "models/view-models/book-record";
import OfflineBookRecord from "models/view-models/offline/offline-book-record";
import OfflineBookUrlsRecord from "models/view-models/offline/offline-book-urls-record";
import { useCallback, useState } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import useServiceWorker from "utilities/contexts/service-worker/use-service-worker";
import useOfflineDevice from "utilities/hooks/domain/offline/use-offline-device";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import { ServiceWorkerCommandTypes } from "utilities/service-worker/constants/service-worker-command-types";
import { PreloadUrlsCommand } from "utilities/service-worker/interfaces/commands/preload-urls-command";
import { ServiceWorkerCommandData } from "utilities/service-worker/interfaces/commands/service-worker-command-data";
import BookService, {
    BookPathParams,
    BookQueryParams,
} from "utilities/services/books/book-service";
import OfflineBookUrlsService, {
    OfflineBookUrlsBasePathParams,
} from "utilities/services/offline/offline-book-urls-service";
import OfflineBooksService from "utilities/services/offline/offline-books-service";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface UseOfflineSyncHookResult {
    error: any;
    removeOfflineBook: (offlineBookId: number) => void;
    syncOfflineBook: (
        offlineBook: OfflineBookRecord,
        includeOptionsChanged?: boolean
    ) => Promise<void>;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Hook
// -----------------------------------------------------------------------------------------

export default function useOfflineSync(): UseOfflineSyncHookResult {
    const { sendCommand: sendServiceWorkerCommand } = useServiceWorker();

    const { t } = useLocalization();

    const [error, setError] = useState<any>();

    const { resultObject: currentDevice } = useOfflineDevice();

    const { get: getBook } = BookService.useGet();
    const { get: getOfflineSyncUrls } = OfflineBookUrlsService.useGet();
    const { delete: deleteOfflineBook } = OfflineBooksService.useDelete();

    const removeOfflineBook = useCallback(
        async (offlineBookId: number) => {
            try {
                if (currentDevice?.id == null || offlineBookId == null) {
                    throw new Error(t("offline-removeOfflineBookError"));
                }
                await deleteOfflineBook(offlineBookId, {
                    offlineDeviceId: currentDevice.id,
                });
            } catch (error) {
                setError(error);
            }
        },
        [currentDevice, deleteOfflineBook, t]
    );

    const syncOfflineBook = useCallback(
        (
            offlineBook: OfflineBookRecord,
            includeOptionsChanged: boolean = false
        ) =>
            syncOfflineBookAsync(
                getBook,
                getOfflineSyncUrls,
                offlineBook,
                sendServiceWorkerCommand,
                setError,
                t,
                includeOptionsChanged
            ),
        [getBook, getOfflineSyncUrls, sendServiceWorkerCommand, t]
    );

    return {
        error,
        removeOfflineBook,
        syncOfflineBook,
    };
}

// #endregion Hook

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

const syncOfflineBookAsync = async (
    getBook: GetService<BookRecord, BookPathParams, BookQueryParams>,
    getOfflineSyncUrls: GetService<
        OfflineBookUrlsRecord,
        OfflineBookUrlsBasePathParams
    >,
    offlineBook: OfflineBookRecord,
    sendServiceWorkerCommand: <T extends ServiceWorkerCommandData>(
        command: T
    ) => void,
    setError: <T = any>(error: T) => void,
    t: <K extends keyof CultureResources>(
        key: K,
        options?: object | undefined
    ) => string,
    includeOptionsChanged: boolean
) => {
    try {
        const { resultObject: book } = await getBook({
            id: offlineBook.bookId,
        });

        if (
            book?.id == null ||
            offlineBook?.id == null ||
            offlineBook.offlineDeviceId == null
        ) {
            throw new Error(t("offline-syncErrorMessage"));
        }

        const { resultObject } = await getOfflineSyncUrls({
            offlineBookId: offlineBook.id,
            offlineDeviceId: offlineBook.offlineDeviceId,
        });

        const urls = sanitizeUrls(resultObject?.urls);

        sendServiceWorkerCommand<PreloadUrlsCommand>({
            bookId: book.id,
            clearPreviousDownload: includeOptionsChanged,
            code: book.code,
            edition: book.edition,
            type: ServiceWorkerCommandTypes.PreloadUrls,
            urls: urls,
        });

        setError(undefined);
    } catch (error) {
        setError(error);
    }
};

const sanitizeUrls = (urls?: Array<string>): Array<string> => {
    if (CollectionUtils.isEmpty(urls)) {
        return urls ?? [];
    }

    return urls.map((urlPath: string) => {
        if (urlPath.startsWith("http")) {
            return urlPath;
        }

        return `${window.location.origin}${urlPath}`;
    });
};

// #endregion Functions
