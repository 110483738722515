import { ServiceResponse } from "@rsm-hcd/javascript-core";
import Button from "atoms/buttons/button";
import ModalCloseButton from "atoms/buttons/modal-close-button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import Paragraph from "atoms/typography/paragraph";
import { List } from "immutable";
import EnhancedContentRecord from "models/view-models/enhanced-content-record";
import EnhancedContentResourceRecord from "models/view-models/enhanced-content-resource-record";
import FileRecord from "models/view-models/file-record";
import ResourceCollectionRecord from "models/view-models/resource-collection-record";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import { ConfirmationModal } from "molecules/modals/confirmation-modal";
import Modal from "molecules/modals/modal";
import RichTextEditor from "molecules/rich-text/rich-text-editor";
import { EnhancedContentPanelProps } from "organisms/enhanced-content/enhanced-content-panel";
import AdminResourcesSection from "organisms/resources/admin-resources-section";
import React, { useCallback, useState } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useEnhancedContentResourcesReducer from "utilities/hooks/domain/admin/enhanced-content/use-enhanced-content-resources-reducer";
import { useUpdateEnhancedContentResources } from "utilities/hooks/domain/admin/enhanced-content/use-update-enhanced-content-resources";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import { ToastManager } from "utilities/toast/toast-manager";
import useCurrentPublication from "utilities/contexts/use-current-publication";
import useEnhancedContent from "utilities/hooks/domain/enhanced-content/use-enhanced-content";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface EnhancedContentModalProps
    extends Pick<EnhancedContentPanelProps, "onEditClick"> {
    /**
     * Callback to handle the create EnhancedContent API call
     */
    onCreate: (
        enhancedContent: EnhancedContentRecord
    ) => Promise<ServiceResponse<EnhancedContentRecord>>;

    /**
     * Callback to handle the update EnhancedContent API call
     */
    onUpdate: (
        enhancedContent: EnhancedContentRecord
    ) => Promise<ServiceResponse<EnhancedContentRecord>>;

    enhancedContent?: EnhancedContentRecord;
    externalId: string;
    files?: List<FileRecord>;
    onClose: () => void;

    /**
     * Callback to run on successful creation/update
     */
    onSuccess?: (enhancedContent: EnhancedContentRecord) => void;
    refreshResources?: () => void;
    resources?: List<EnhancedContentResourceRecord>;
    title: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-enhanced-content-modal";

const SAVING_ENHANCED_CONTENT_ERROR =
    "There was an issue saving enhanced content.";
const UPDATING_ENHANCED_CONTENT_SUCCESS = "Enhanced content saved.";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const EnhancedContentModal: React.FC<EnhancedContentModalProps> = (props) => {
    // -----------------------------------------------------------------------------------------
    // #region Props
    // -----------------------------------------------------------------------------------------
    const { onCreate, onClose, onSuccess, onUpdate, refreshResources } = props;

    // #endregion Props

    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------

    const { globalState } = useGlobalState();
    const { updateEnhancedContentOnAdd, updateEnhancedContentOnEdit } =
        useEnhancedContent({});
    const [enhancedContentSaving, setEnhancedContentSaving] = useState(false);
    const [enhancedContent, setEnhancedContent] = useState(
        props.enhancedContent ?? new EnhancedContentRecord()
    );
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [initialResourceCollection] = useState(
        new ResourceCollectionRecord(EnhancedContentResourceRecord, {
            resources: props.resources ?? List<EnhancedContentResourceRecord>(),
            files: props.files ?? List<FileRecord>(),
        })
    );

    const {
        resources: resourceCollection,
        setResources: setResourceCollection,
    } = useEnhancedContentResourcesReducer(
        initialResourceCollection,
        enhancedContent.id
    );

    // #endregion State

    // -----------------------------------------------------------------------------------------
    // #region Private Properties
    // -----------------------------------------------------------------------------------------
    const { publication } = useCurrentPublication();

    const isMobile = useBreakpoint(
        Breakpoints.Phone,
        BreakpointComparer.MaxWidth
    );

    const modalTransition = isMobile
        ? ModalTransitions.SlideUp
        : ModalTransitions.Fade;

    const label = `${
        enhancedContent.isPersisted() ? "Edit" : "Add"
    } Enhanced Content`;

    // #endregion Private Properties

    // -----------------------------------------------------------------------------------------
    // #region Services
    // -----------------------------------------------------------------------------------------

    const { bulkUpdate } = useUpdateEnhancedContentResources();

    // #endregion Services

    // -----------------------------------------------------------------------------------------
    // #region Handlers
    // -----------------------------------------------------------------------------------------

    const handleSuccess = useCallback(
        (enhancedContent: EnhancedContentRecord) => {
            refreshResources?.();
            ToastManager.success(UPDATING_ENHANCED_CONTENT_SUCCESS);
            onSuccess?.(enhancedContent);
            onClose();
        },
        [onClose, onSuccess, refreshResources]
    );

    const handleChange = useCallback(
        (newValue: string) => {
            setEnhancedContent((enhancedContent: EnhancedContentRecord) =>
                enhancedContent.with({ bodyDraft: newValue })
            );
        },
        [setEnhancedContent]
    );

    const handleSave = async () => {
        try {
            if (enhancedContentSaving) {
                return;
            }

            setEnhancedContentSaving(true);

            const updatedBy = globalState.currentIdentity?.user;

            if (enhancedContent.isPersisted()) {
                const updateResponse = await onUpdate(enhancedContent);

                setEnhancedContentSaving(false);

                if (updateResponse.result?.hasErrors()) {
                    ToastManager.error(SAVING_ENHANCED_CONTENT_ERROR);
                    return;
                }

                const updatedResources = await (
                    await bulkUpdate(
                        enhancedContent.id!,
                        initialResourceCollection.resources.toArray(),
                        resourceCollection.resources.toArray()
                    )
                ).map((r: EnhancedContentResourceRecord) =>
                    r.withFile(resourceCollection.files.toArray())
                );

                const updatedEnhancedContent =
                    updateResponse.resultObject
                        ?.with({ updatedBy })
                        .withResources(updatedResources) ?? enhancedContent;

                updateEnhancedContentOnEdit(updatedEnhancedContent);

                handleSuccess(updatedEnhancedContent);
                return;
            }

            const dto = enhancedContent.with({
                code: publication?.code,
                edition: publication?.edition,
                externalId: props.externalId,
                title: props.title,
            });
            const createResponse = await onCreate(dto);

            setEnhancedContentSaving(false);

            if (createResponse.result?.hasErrors()) {
                ToastManager.error(SAVING_ENHANCED_CONTENT_ERROR);
                return;
            }
            const createdEnhancedContentId = createResponse.resultObject?.id;

            const updatedResources = await (
                await bulkUpdate(
                    createdEnhancedContentId!,
                    initialResourceCollection.resources.toArray(),
                    CollectionUtils.collapseDisplaySequences(
                        resourceCollection.resources.toArray(),
                        (
                            resource: EnhancedContentResourceRecord,
                            displaySequenceDraft: number
                        ) =>
                            resource.with({
                                displaySequenceDraft,
                                enhancedContentId: createdEnhancedContentId,
                            })
                    )
                )
            ).map((r: EnhancedContentResourceRecord) =>
                r.withFile(resourceCollection.files.toArray())
            );

            const newEnhancedContent =
                createResponse.resultObject
                    ?.with({ updatedBy })
                    .withResources(updatedResources) ?? enhancedContent;

            updateEnhancedContentOnAdd(newEnhancedContent);

            handleSuccess(newEnhancedContent);
        } catch (error) {
            setEnhancedContentSaving(false);
            ToastManager.error(SAVING_ENHANCED_CONTENT_ERROR);
        }
    };

    const confirmBeforeClose = () => {
        setShowConfirmationModal(true);
    };

    const handleResourcesReordered = (startIndex: number, endIndex: number) => {
        const resourcesArray = resourceCollection.resources.toArray();
        const sortedResources = CollectionUtils.collapseDisplaySequences(
            CollectionUtils.handleDragAndDropReorder(
                resourcesArray,
                startIndex,
                endIndex
            ),
            (r: EnhancedContentResourceRecord, displaySequenceDraft: number) =>
                r.with({ displaySequenceDraft })
        );
        setResourceCollection(
            new ResourceCollectionRecord(EnhancedContentResourceRecord, {
                resources: List(sortedResources),
                files: resourceCollection.files,
            })
        );
    };

    // #endregion Handlers

    return (
        <>
            <Modal
                cssClassName={CSS_CLASS_NAME}
                closeDialog={confirmBeforeClose}
                isVisible={true}
                label={label}
                type={ModalTypes.Base}
                transition={modalTransition}>
                <div className={`${CSS_CLASS_NAME}__header`}>
                    <Heading priority={HeadingPriority.Two}>{label}</Heading>
                    <ModalCloseButton onClick={confirmBeforeClose} />
                </div>
                <div className={`${CSS_CLASS_NAME}__body`}>
                    <div className={`${CSS_CLASS_NAME}__content`}>
                        <Paragraph
                            cssClassName={`${CSS_CLASS_NAME}__content__title`}>
                            {props.title}
                        </Paragraph>
                        <RichTextEditor
                            allowImages={true}
                            onChange={handleChange}
                            placeholder={"Enhanced content body..."}
                            preserveWhitespace={true}
                            value={enhancedContent.bodyDraft ?? ""}
                        />
                        <AdminResourcesSection
                            allowDragAndDrop={true}
                            resources={resourceCollection}
                            onReordered={handleResourcesReordered}
                            onResourcesChanged={setResourceCollection}
                            loading={enhancedContentSaving}
                            recordType={EnhancedContentResourceRecord}
                        />
                    </div>
                    <div className={`${CSS_CLASS_NAME}__footer`}>
                        <Button
                            onClick={confirmBeforeClose}
                            style={ButtonStyles.Secondary}>
                            Cancel
                        </Button>
                        <Button
                            disabled={enhancedContentSaving}
                            onClick={handleSave}
                            style={ButtonStyles.Primary}>
                            Save
                        </Button>
                    </div>
                </div>
            </Modal>
            {showConfirmationModal && (
                <ConfirmationModal
                    onCancel={() => setShowConfirmationModal(false)}
                    onConfirm={onClose}
                    isVisible={true}
                    label="Are you sure?"
                    message="If you close this editor without saving, any changes you made will be lost."
                />
            )}
        </>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default EnhancedContentModal;

// #endregion Exports
