import * as React from "react";
import { forwardRef, Ref } from "react";

export interface ImageProps {
    /**
     * Alt text to display for screenreaders or if the image does not load.
     */
    altText?: string;

    /**
     * Optional class name to be applied to the img element.
     */
    cssClassName?: string;
    /**
     * If true, set crossorigin="anonymous" which allows us
     * to get/analyze/manipulate the image data via JS.
     */
    crossOrigin?: boolean;
    onImageLoaded?: () => void;

    ref?: Ref<HTMLImageElement>;

    /**
     * Path to the image to be rendered.
     */
    src: string;
}

const Image = forwardRef((props: ImageProps, ref: Ref<HTMLImageElement>) => {
    const crossOrigin = props.crossOrigin === true ? "anonymous" : undefined;

    return (
        <img
            cross-origin={crossOrigin} // props.crossOrigin occasionally ineffective, use native attribute
            onLoad={props.onImageLoaded}
            ref={ref}
            alt={props.altText}
            className={props.cssClassName}
            src={props.src}
        />
    );
});

export default Image;
