import { GoogleAnalyticsIds } from "constants/google-analytics-ids";
import { useAtomValue } from "jotai/utils";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import Modal, { CloseDialogEvent, ModalProps } from "molecules/modals/modal";
import PageNavigationMenu from "molecules/page-navigation/page-navigation-menu";
import SearchModalFiltersContainer from "organisms/modals/search-modal/filters/search-modal-filters-container";
import SearchModalHeader from "organisms/modals/search-modal/search-modal-header";
import SearchModalResults from "organisms/modals/search-modal/search-modal-results";
import {
    SearchModalResultType,
    SearchStatus,
} from "organisms/modals/search-modal/utils/search-modal-reducer";
import useSearchModal from "organisms/modals/search-modal/utils/use-search-modal";
import React from "react";
import CurrentPublicationEntityAtom from "utilities/atoms/current-publication-entity-atom";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import useCurrentPublication from "utilities/contexts/use-current-publication";
import { AriaRole } from "utilities/enumerations/aria-role";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import { useLocalization } from "utilities/hooks/use-localization";
import useModalActions from "utilities/hooks/use-modal-actions";
import usePagedResults from "utilities/hooks/use-paged-results";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface SearchModalProps
    extends Pick<ModalProps, "closeDialog" | "isVisible" | "onCloseComplete"> {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-search-modal-v2";
const DEFAULT_ONLINE_PAGE_SIZES = [10, 20, 50, 100];
const DEFAULT_OFFLINE_PAGE_SIZES = [10, 20];
const DEFAULT_TAKE_SIZE = 10;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Enums
// -----------------------------------------------------------------------------------------

// #endregion Enums

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SearchModal: React.FunctionComponent<SearchModalProps> = (props) => {
    const { closeDialog, isVisible, onCloseComplete } = props;

    const { t } = useLocalization();
    const { isOnline } = useNetworkInformation();
    const { useOfflineFeatures } = useFeatureFlags();
    const {
        isOpen: filterModalIsOpen,
        handleOpen: handleOpenFilterModal,
        handleClose: handleCloseFilterModal,
    } = useModalActions();
    const defaultPageSizes = isOnline
        ? DEFAULT_ONLINE_PAGE_SIZES
        : DEFAULT_OFFLINE_PAGE_SIZES;

    const currentPublicationEntity = useAtomValue(CurrentPublicationEntityAtom);
    const { publication } = useCurrentPublication();

    const {
        currentPage,
        numberOfPages,
        onPageLast,
        onPageNext,
        onPageSizeChange,
        onSelectPage,
        resetPagination,
        setRowCount,
        skip,
        take,
    } = usePagedResults(DEFAULT_TAKE_SIZE);

    const {
        codesEntityTypes,
        codesRowCount,
        codesSearchResults,
        directEntityTypes,
        directRowCount,
        directSearching,
        directSearchResults,
        enhancedContentTypes,
        favoritePublications,
        handleClearFilters,
        handleCodesEntityTypesUpdated,
        handleDirectEntityTypesUpdated,
        handleEnhancedContentTypesUpdated,
        handleSearchClear,
        handleSelectedPublicationsUpdated,
        handleSetReducerState,
        handleSetSelectedResultType,
        handleSubmit,
        publications,
        publicationSearching,
        searchText,
        selectedPublicationIds,
        selectedResultType,
        selectedRowCount,
        status,
    } = useSearchModal({
        currentPublicationEntity,
        currentPublication: publication,
        skip,
        take,
        resetPagination,
        setPagingRowCount: setRowCount,
    });

    const handleClose = (event?: CloseDialogEvent) => closeDialog(event);

    const transition =
        window.innerWidth < Breakpoints.Phone
            ? ModalTransitions.SlideUp
            : ModalTransitions.SlideRight;
    const useOfflineSearch = !isOnline && useOfflineFeatures;
    const offlineClassName = useOfflineSearch ? "-offline" : "";
    const results =
        selectedResultType === SearchModalResultType.Codes
            ? codesSearchResults
            : directSearchResults;

    const entityTypes =
        selectedResultType === SearchModalResultType.Codes
            ? [...codesEntityTypes, ...enhancedContentTypes]
            : directEntityTypes;

    const searchHintText = t("inputHintText", {
        text: t("searchHintText"),
    });

    return (
        <Modal
            closeDialog={handleClose}
            isVisible={isVisible}
            label={t("searchItem", { item: t("menu") })}
            onCloseComplete={onCloseComplete}
            showAppSidebar={true}
            transition={transition}
            type={ModalTypes.Search}>
            <div className={`${BASE_CLASS_NAME} ${offlineClassName}`}>
                <SearchModalHeader
                    directResultsCount={directRowCount}
                    directSearching={directSearching}
                    favoritePublications={favoritePublications}
                    hintText={searchHintText}
                    onSearchClear={handleSearchClear}
                    onSearchTextChange={(text: string) =>
                        handleSetReducerState({ searchText: text })
                    }
                    onSubmit={handleSubmit}
                    publicationResultsCount={codesRowCount}
                    publications={publications}
                    publicationSearching={publicationSearching}
                    searchText={searchText}
                    selectedResultType={selectedResultType}
                    setSelectedResultType={handleSetSelectedResultType}
                />
                <div
                    className={`${BASE_CLASS_NAME}__content`}
                    role={AriaRole.TabPanel}>
                    <SearchModalFiltersContainer
                        codesEntityTypes={codesEntityTypes}
                        directEntityTypes={directEntityTypes}
                        enhancedContentTypes={enhancedContentTypes}
                        favoritePublications={favoritePublications}
                        isModalOpen={filterModalIsOpen}
                        onClearFilters={handleClearFilters}
                        onCodesEntityTypesUpdated={
                            handleCodesEntityTypesUpdated
                        }
                        onDirectEntityTypesUpdated={
                            handleDirectEntityTypesUpdated
                        }
                        onEnhancedContentTypesUpdated={
                            handleEnhancedContentTypesUpdated
                        }
                        onModalClose={handleCloseFilterModal}
                        onSelectedPublicationsUpdated={
                            handleSelectedPublicationsUpdated
                        }
                        publications={publications}
                        resetPagination={resetPagination}
                        searchResultType={selectedResultType}
                        selectedPublications={selectedPublicationIds}
                    />
                    <SearchModalResults
                        filterCount={getFilterCount(
                            entityTypes,
                            selectedPublicationIds
                        )}
                        loading={
                            status !== SearchStatus.Initial &&
                            (publicationSearching || directSearching)
                        }
                        onFilterModalOpen={handleOpenFilterModal}
                        onResultClick={handleClose}
                        results={results}
                        resultTotal={selectedRowCount}
                        searchText={searchText}
                    />
                </div>
                {selectedRowCount > 0 && (
                    <div className={`${BASE_CLASS_NAME}__footer`}>
                        <PageNavigationMenu
                            currentPage={currentPage}
                            id={GoogleAnalyticsIds.SEARCH_MODAL_PAGE_NAVIGATION}
                            numberOfPages={numberOfPages}
                            onNavigateBack={onPageLast}
                            onNavigateForward={onPageNext}
                            onPageSizeChange={onPageSizeChange}
                            onSelectPage={onSelectPage}
                            pageSize={take}
                            pageSizes={defaultPageSizes}
                            resultTotal={selectedRowCount}
                        />
                    </div>
                )}
            </div>
        </Modal>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

const getFilterCount = (
    selectedEntityTypes: Array<string>,
    selectedFavorites: Array<number>
) => selectedEntityTypes.length + selectedFavorites.length;

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default SearchModal;

// #endregion Export
