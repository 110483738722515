import { SectionType } from "models/enumerations/section-type";
import TiaRecord from "models/view-models/tia-record";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import Modal from "molecules/modals/modal";
import React, { useState } from "react";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useTias from "utilities/hooks/domain/publications/use-tias";
import useBreakpoint from "utilities/hooks/use-breakpoint";
import TiaListContent from "./tia-list-panel-content";

export interface GroupedTias {
    date: string;
    tias: TiaRecord[];
}

export interface SectionLevel {
    type: SectionType | undefined;
    externalId: string;
}

interface TiaListPanelProps {
    externalId: string;
    sectionLevels: Record<string, number | undefined>;
}

const CSS_BASE_CLASS = "c-tia-changes-panel";

const TiaListPanel = (props: TiaListPanelProps) => {
    const { externalId, sectionLevels } = props;

    const {
        tias: titleTias,
        groupedTias,
        handleTiaListPanelClose: handleClose,
    } = useTias(externalId);

    const isDesktop = useBreakpoint(Breakpoints.SmallDesktop);

    const [index] = useState(0);

    const currentTias = groupedTias[index]?.tias ?? [];
    const headingTia = titleTias[index];

    return isDesktop ? (
        <div className={`${CSS_BASE_CLASS} -list`}>
            <div className={`${CSS_BASE_CLASS}__container`}>
                <TiaListContent
                    handleClose={handleClose}
                    index={index}
                    titleTias={titleTias}
                    headingTia={headingTia}
                    currentTias={currentTias}
                    sectionLevels={sectionLevels}
                />
            </div>
        </div>
    ) : (
        <Modal
            cssClassName={"c-codes-modal"}
            closeDialog={handleClose}
            isVisible={true}
            label={"hello"}
            transition={ModalTransitions.SlideUp}
            type={ModalTypes.Fullscreen}
            useDialogOverlay={true}>
            <TiaListContent
                handleClose={handleClose}
                index={index}
                titleTias={titleTias}
                headingTia={headingTia}
                currentTias={currentTias}
                sectionLevels={sectionLevels}
            />
        </Modal>
    );
};

export default TiaListPanel;
