import Heading from "atoms/typography/heading";
import FileRecord from "models/view-models/file-record";
import ResourceCollectionRecord from "models/view-models/resource-collection-record";
import SolutionResourceRecord from "models/view-models/situational-navigation/solutions/solution-resource-record";
import ResourceCard from "organisms/situational-navigation/resources/resource-card";
import React from "react";
import { useLocalization } from "utilities/hooks/use-localization";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ResourcesSectionProps {
    resources: ResourceCollectionRecord<SolutionResourceRecord>;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-resources-section";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ResourcesSection: React.FC<ResourcesSectionProps> = (
    props: ResourcesSectionProps
) => {
    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    const { resources } = props;

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Hooks
    // -----------------------------------------------------------------------------------------

    const { t } = useLocalization();

    // #endregion Hooks

    // -----------------------------------------------------------------------------------------
    // #region Returns
    // -----------------------------------------------------------------------------------------

    return (
        <div className={CSS_CLASS_NAME}>
            {
                // if
                resources.documents().hasValues() && (
                    <Heading cssClassName={`${CSS_CLASS_NAME}__type-header`}>
                        {t("resourceType-Document_plural")}
                    </Heading>
                )
            }
            {resources
                .documents()
                .map((r: SolutionResourceRecord, f?: FileRecord) => (
                    <ResourceCard
                        accessibleText={t(
                            "resourceSection-resourceCard-documents-accessibleText",
                            { title: r.title }
                        )}
                        key={r.id!}
                        resource={r}
                        file={f}
                    />
                ))}
            {
                // if
                resources.videos().hasValues() && (
                    <Heading cssClassName={`${CSS_CLASS_NAME}__type-header`}>
                        {t("resourceType-Video_plural")}
                    </Heading>
                )
            }
            {resources
                .videos()
                .map((r: SolutionResourceRecord, f?: FileRecord) => (
                    <ResourceCard
                        accessibleText={t(
                            "resourceSection-resourceCard-video-accessibleText",
                            { title: r.title }
                        )}
                        key={r.id!}
                        resource={r}
                        file={f}
                    />
                ))}
            {
                // if
                resources.images().hasValues() && (
                    <Heading cssClassName={`${CSS_CLASS_NAME}__type-header`}>
                        {t("resourceType-Image_plural")}
                    </Heading>
                )
            }
            {resources
                .images()
                .map((r: SolutionResourceRecord, f?: FileRecord) => (
                    <ResourceCard
                        accessibleText={t(
                            "resourceSection-resourceCard-image-accessibleText",
                            { title: r.title }
                        )}
                        key={r.id!}
                        resource={r}
                        file={f}
                    />
                ))}
            {
                // if
                resources.otherFiles().hasValues() && (
                    <Heading cssClassName={`${CSS_CLASS_NAME}__type-header`}>
                        {t("resourceType-File_plural")}
                    </Heading>
                )
            }
            {resources
                .otherFiles()
                .map((r: SolutionResourceRecord, f?: FileRecord) => (
                    <ResourceCard
                        accessibleText={t(
                            "resourceSection-resourceCard-files-accessibleText",
                            { title: r.title }
                        )}
                        key={r.id!}
                        resource={r}
                        file={f}
                    />
                ))}
            {
                // if
                resources.externalResources().hasValues() && (
                    <Heading cssClassName={`${CSS_CLASS_NAME}__type-header`}>
                        {t("resourceType-Link_plural")}
                    </Heading>
                )
            }
            {resources.externalResources().map((r: SolutionResourceRecord) => (
                <ResourceCard
                    accessibleText={t(
                        "resourceSection-resourceCard-externalVideos-accessibleText",
                        { title: r.title }
                    )}
                    key={r.id!}
                    resource={r}
                />
            ))}
        </div>
    );

    // #endregion Returns
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default ResourcesSection;

// #endregion Exports
