import { ResultRecord, ServiceResponse } from "@rsm-hcd/javascript-core";
import {
    QueryFunction,
    QueryKey,
    QueryObserverOptions,
    QueryObserverResult,
    useQuery,
} from "react-query";

export default function useServiceQuery<T>(
    queryKey: QueryKey,
    queryFunction: QueryFunction<ServiceResponse<T>>,
    options?: QueryObserverOptions<ServiceResponse<T>, ResultRecord<T>>
): QueryObserverResult<ServiceResponse<T>, ResultRecord<T>> {
    return useQuery<ServiceResponse<T>, ResultRecord<T>>(
        queryKey,
        queryFunction,
        options
    );
}
