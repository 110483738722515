import CustomQuillToolbar from "molecules/rich-text/custom-quill-toolbar";
import RichTextEditorFooter from "molecules/rich-text/rich-text-editor-footer";
import RichTextImageUploadModal from "molecules/rich-text/rich-text-image-upload-modal";
import RichTextReferenceLinkModal from "molecules/rich-text/rich-text-reference-link-modal";
import React, { useRef } from "react";
import ReactQuill from "react-quill";
import useQuill from "utilities/hooks/quill/use-quill";
import QuillConfig from "utilities/quill/quill-config";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface RichTextEditorProps {
    /**
     * Enable the image upload functionality.
     * This feature is opt-in, i.e. false by default.
     *
     * @default false
     */
    allowImages?: boolean;
    /**
     * Enable the publication reference link button.
     * This feature is enabled by default.
     *
     * @default true
     */
    allowReferenceLinks?: boolean;
    disabled?: boolean;
    errors?: string[];
    value: string;
    label?: string | React.ReactNode;
    maxLength?: number;
    onBlur?: () => void;
    onFocus?: () => void;
    onChange: (newValue: string, rawValue: string) => void;
    placeholder?: string;
    preserveWhitespace?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Quill Module Setup
// -------------------------------------------------------------------------------------------------

QuillConfig.registerCustomModules();

// #endregion Quill Module Setup

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-rich-text-editor__v2";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const RichTextEditor: React.FC<RichTextEditorProps> = (
    props: RichTextEditorProps
) => {
    const {
        disabled,
        errors,
        label,
        maxLength,
        onBlur,
        placeholder,
        preserveWhitespace,
        value,
        allowImages = false,
        allowReferenceLinks = true,
    } = props;

    const reactQuillRef = useRef<ReactQuill>(null);

    const {
        className,
        closeImageModal,
        closeReferenceLinkModal,
        containerId,
        containerSelector,
        handleChange,
        handleFocus,
        handleImageConfirmed,
        handleReferenceLinkConfirmed,
        handleSelectionChange,
        isImageModalOpen,
        isReferenceLinkModalOpen,
        plainTextValue,
        quillModules,
        toolbarId,
    } = useQuill(CSS_CLASS_NAME, props, reactQuillRef);

    return (
        <div className={className}>
            {label != null && (
                <label className={`${CSS_CLASS_NAME}__label`}>{label}</label>
            )}
            <CustomQuillToolbar
                allowImages={allowImages!}
                allowReferenceLinks={allowReferenceLinks!}
                disabled={disabled}
                id={toolbarId}
            />
            <ReactQuill
                bounds={containerSelector}
                className={`${CSS_CLASS_NAME}__editor`}
                formats={QuillConfig.quillFormats}
                id={containerId}
                modules={quillModules}
                onBlur={onBlur}
                onChange={handleChange}
                onChangeSelection={handleSelectionChange}
                onFocus={handleFocus}
                placeholder={placeholder}
                preserveWhitespace={preserveWhitespace}
                readOnly={disabled}
                ref={reactQuillRef}
                theme={QuillConfig.theme}
                value={value}
            />
            <RichTextEditorFooter
                baseClassName={CSS_CLASS_NAME}
                currentLength={plainTextValue.length}
                errors={errors}
                maxLength={maxLength}
            />
            {allowImages && (
                <RichTextImageUploadModal
                    baseClassName={`${CSS_CLASS_NAME}__image-modal`}
                    closeDialog={closeImageModal}
                    isVisible={isImageModalOpen}
                    onConfirm={handleImageConfirmed}
                />
            )}
            {allowReferenceLinks && (
                <RichTextReferenceLinkModal
                    baseClassName={`${CSS_CLASS_NAME}__reference-link-modal`}
                    closeDialog={closeReferenceLinkModal}
                    isVisible={isReferenceLinkModalOpen}
                    onConfirm={handleReferenceLinkConfirmed}
                />
            )}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default RichTextEditor;

// #endregion Exports
