import Anchor from "atoms/anchors/anchor";
import { HeadingPriority } from "atoms/constants/heading-priority";
import { Icons } from "atoms/constants/icons";
import { LoaderStyles } from "atoms/constants/loader-styles";
import Icon from "atoms/icons/icon";
import Loader from "atoms/loaders/loader";
import Heading from "atoms/typography/heading";
import Paragraph from "atoms/typography/paragraph";
import { AppNameTm } from "constants/app-name-tm";
import UserConfigurationErrorKeys from "constants/user-configuration-error-keys";
import { siteMap } from "internal-sitemap";
import { MetaTag, MetaTagTypes } from "models/interfaces/header-data";
import GroupRecord from "models/view-models/group-record";
import InputFormField from "molecules/form-fields/input-form-field";
import UserTopicsList from "organisms/user-topics-list/user-topics-list";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import OnboardingLayout from "templates/onboarding-layout";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useHeaderData } from "utilities/contexts/use-header-data-context";
import { useLocalization } from "utilities/hooks/use-localization";
import usePageErrors from "utilities/hooks/use-page-errors";
import useRefreshIdentity from "utilities/hooks/use-refresh-identity";
import useUnauthorizedResult from "utilities/hooks/use-unauthorized-result";
import CultureResources from "utilities/interfaces/culture-resources";
import GroupService from "utilities/services/groups/group-service";
import ExternalTopicService from "utilities/services/topics/external-topic-service";
import StringUtils from "utilities/string-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import { Translator } from "utilities/types/translator-type";
import useTopics from "../../utilities/hooks/domain/topics/use-topics";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface SelectTopicsPageProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const translateMetaTags = (t: Translator<CultureResources>): Array<MetaTag> => [
    {
        name: MetaTagTypes.Title,
        content: t("selectTopicsPage-meta-title", {
            appNameTm: AppNameTm,
        }),
    },
];
const translatePageTitle = (t: Translator<CultureResources>): string =>
    t("selectTopicsPage-title", { appNameTm: AppNameTm });

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SelectTopicsPage: React.FC<SelectTopicsPageProps> = (
    props: SelectTopicsPageProps
) => {
    const { t } = useLocalization();
    const updateTopicsError = t("selectTopicsPage-errors-updateTopics");
    const updateTeamNameError = t("selectTopicsPage-errors-updateTeam");

    const baseClass = "c-select-topics-page";

    const headerData = useMemo(
        () => ({
            title: translatePageTitle(t),
            metaTags: translateMetaTags(t),
        }),
        [t]
    );
    const heading = t("selectTopicsPage-heading");
    const description = t("selectTopicsPage-description");
    const teamNameHeading = t("selectTopicsPage-teamNameHeading");
    const topicsValidationMessage = t(
        "selectTopicsPage-errors-topicValidation"
    );
    const teamNameInputLabel = t("teamName");
    const teamNameInputPlaceholder = t(
        "selectTopicsPage-inputs-teamName-placeholder"
    );
    const loaderAccessibleText = t("selectTopicsPage-loader-accessible-text");
    const continueLinkText = t("continue");

    const { setPageTitle } = useHeaderData(headerData);

    // Clean up the unauthorizedResult from GlobalState if found/relevant to this page
    useUnauthorizedResult(
        UserConfigurationErrorKeys.ERROR_TOPICS_NOT_UPDATED,
        UserConfigurationErrorKeys.ERROR_TEAM_NOT_INITIALIZED
    );

    const history = useHistory();
    const [externalTopicsLoaded, setExternalTopicsLoaded] =
        useState<boolean>(false);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [showValidationError, setShowValidationError] =
        useState<boolean>(false);
    const [teamValidationError, setTeamValidationError] = useState<
        string | undefined
    >(undefined);
    const [teamName, setTeamName] = useState<string>("");
    const { globalState } = useGlobalState();
    const [selectedTopicIds, setSelectedTopics] = useState<number[]>([]);
    const uninitializedTeam =
        globalState.currentIdentity?.findAdminUserRoleWithUninitializedTeam();

    const { handlePageLoadError, pageErrors } = usePageErrors();
    const getExternalTopicsApi = ExternalTopicService.list;
    const { update: updateGroupApi } = GroupService.useUpdate();
    const { refresh: refreshIdentity } = useRefreshIdentity();
    const { loading, loaded, resultObject: topics } = useTopics();

    useEffect(() => {
        if (externalTopicsLoaded) {
            return;
        }

        async function getUserTopics() {
            const response = await getExternalTopicsApi();

            const externalTopics = response.resultObjects || [];
            setSelectedTopics(externalTopics.map((u) => u.id));
            setExternalTopicsLoaded(true);
        }

        try {
            getUserTopics();
        } catch (result) {
            handlePageLoadError(result);
        }
    }, [
        getExternalTopicsApi,
        externalTopicsLoaded,
        handlePageLoadError,
        pageErrors,
    ]);

    useEffect(() => {
        if (uninitializedTeam) {
            setPageTitle(t("selectTopicsPage-teamTitle"));
        }
    }, [uninitializedTeam, setPageTitle, t]);

    async function updateTeam(): Promise<boolean> {
        const groupRecord = new GroupRecord({
            externalIdentifier: "",
            id: uninitializedTeam!.userRoleGroup!.groupId,
            name: teamName,
        });

        try {
            await updateGroupApi(groupRecord);
            return true;
        } catch (result) {
            handlePageLoadError(result);
            setShowLoader(false);
            ToastManager.error(updateTeamNameError);
            return false;
        }
    }

    async function updateUserTopics(): Promise<boolean> {
        const selectedTopics = topics.filter(
            (x) => x.id && selectedTopicIds.includes(x.id)
        );

        try {
            await ExternalTopicService.updateSelected(selectedTopics);
            return true;
        } catch (result) {
            handlePageLoadError(result);
            setShowLoader(false);
            ToastManager.error(updateTopicsError);
            return false;
        }
    }

    const updateUserInfo = async () => {
        const updateTopicsResult = await updateUserTopics();

        let updateTeamResult = true;
        if (uninitializedTeam != null) {
            updateTeamResult = await updateTeam();
        }

        if (!updateTopicsResult || !updateTeamResult) {
            return;
        }

        await refreshIdentity();
        history.push(siteMap.favorites);
    };

    const handleCheck = (
        e: React.ChangeEvent<HTMLInputElement>,
        identifier?: number
    ) => {
        let topics = selectedTopicIds.some((s) => s === identifier!)
            ? selectedTopicIds.filter((t) => t !== identifier)
            : selectedTopicIds.concat(identifier!);

        setSelectedTopics(topics);
    };

    const onClick = async (
        e: React.MouseEvent<HTMLElement, MouseEvent>,
        value?: any
    ) => {
        e.preventDefault();
        e.nativeEvent.returnValue = false; // IE
        if (selectedTopicIds.length === 0) {
            setShowValidationError(true);
            return;
        }
        if (uninitializedTeam != null && teamName.length === 0) {
            setTeamValidationError(
                t("propertyIsRequired", {
                    name: t("teamName"),
                })
            );
            return;
        }

        setShowValidationError(false);
        setShowLoader(true);

        await updateUserInfo();

        setShowLoader(false);
    };
    const linkTitle =
        selectedTopicIds.length === 0 ? topicsValidationMessage : undefined;

    return (
        <OnboardingLayout>
            <div className={baseClass}>
                <Heading
                    cssClassName={baseClass + "__title"}
                    priority={HeadingPriority.One}>
                    {heading}
                </Heading>
                <div className={baseClass + "__topics"}>
                    <Paragraph>{description}</Paragraph>
                    <UserTopicsList
                        loaderType={LoaderStyles.LinkGlyphGray}
                        onCheck={handleCheck}
                        selectedTopicIds={selectedTopicIds}
                        loaded={loaded}
                        loading={loading}
                        topics={topics}
                    />
                    <div className={baseClass + "__divider"}></div>
                </div>
                {
                    //
                    uninitializedTeam != null && (
                        <div
                            className={baseClass + "__team"}
                            data-test-id="select-team-name">
                            <Heading
                                cssClassName={baseClass + "__title"}
                                priority={HeadingPriority.One}>
                                {teamNameHeading}
                            </Heading>
                            <InputFormField
                                errorMessage={teamValidationError}
                                isValid={StringUtils.isEmpty(
                                    teamValidationError
                                )}
                                label={teamNameInputLabel}
                                maxLength={100}
                                onChange={(e) => setTeamName(e.target.value)}
                                placeholder={teamNameInputPlaceholder}
                                required={true}
                                inputTestId="input-team-name"
                                value={teamName}
                            />
                            <div className={baseClass + "__divider"}></div>
                        </div>
                    )
                }

                <div className={baseClass + "__footer"}>
                    <div className={baseClass + "__footer__errors"}>
                        {showValidationError && (
                            <React.Fragment>
                                <span data-test-id="topic-validation-error">
                                    <Icon type={Icons.Information} />
                                    {topicsValidationMessage}
                                </span>
                            </React.Fragment>
                        )}
                    </div>
                    {showLoader ? (
                        <Loader
                            accessibleText={loaderAccessibleText}
                            type={LoaderStyles.LinkGlyphGray}
                        />
                    ) : (
                        // Semantically this is a link for accessiblity, the onClick is handling navigation.
                        <Anchor
                            dataTestId="continue"
                            ariaLabel="Select Favorites"
                            onClick={onClick}
                            to={siteMap.favorites}
                            title={linkTitle}>
                            {continueLinkText}
                        </Anchor>
                    )}
                </div>
            </div>
        </OnboardingLayout>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default SelectTopicsPage;

// #endregion Exports
