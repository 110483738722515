import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import RadioButton from "atoms/forms/radio-button-input";
import Icon from "atoms/icons/icon";
import Paragraph from "atoms/typography/paragraph";
import PublicationRecord from "models/view-models/publication-record";
import { ModalTypes } from "molecules/constants/modal-types";
import Modal from "molecules/modals/modal";
import RadioList, {
    RadioMarkerPosition,
} from "molecules/radio-lists/radio-list";
import React, { useCallback, useState } from "react";
import { useLocalization } from "utilities/hooks/use-localization";
import StringUtils from "utilities/string-utils";

interface PublicationAdminBulkActionsModalProps {
    closeDialog: () => void;
    handleJob: (startNow: boolean) => void;
    isVisible: boolean;
    processLabel: string;
    publications: Array<PublicationRecord>;
}

const CSS_BASE_CLASS_NAME = "c-publication-admin-bulk-actions-modal";

const PublicationAdminBulkActionsModal: React.FunctionComponent<
    PublicationAdminBulkActionsModalProps
> = (props) => {
    const { closeDialog, isVisible, handleJob, processLabel, publications } =
        props;

    const publicationCodesAndEditions = publications.map((pub) =>
        pub.getDisplayCodeAndEdition()
    );

    const displayCodesAndEditions = StringUtils.formatToList(
        publicationCodesAndEditions
    );

    const { t } = useLocalization();
    const closeLabel = t("close");
    const cancelLabel = t("cancel");
    const startNowButtonLabel = t("publication-batch_start-job-now");
    const startLaterButtonLabel = t("publication-batch_start-job-later");
    const startNowText = t("publication-batch_start-job-now-description");
    const startLaterText = t("publication-batch_start-job-later-description");

    const [startNow, setStartNow] = useState<boolean>(false);

    const buttonText = startNow ? startNowButtonLabel : startLaterButtonLabel;

    const handleClick = (startNow: boolean) => {
        closeDialog();
        handleJob(startNow);
    };

    const handleCheck = useCallback(() => setStartNow(!startNow), [startNow]);

    const radioButtons = [
        <RadioButton
            checked={!startNow}
            label={"Schedule For Overnight"}
            name={"start later"}
            onCheck={handleCheck}
        />,
        <RadioButton
            checked={startNow}
            label={"Start Now"}
            name={"start now"}
            onCheck={handleCheck}
        />,
    ];

    return (
        <Modal
            closeDialog={closeDialog}
            cssClassName={CSS_BASE_CLASS_NAME}
            isVisible={isVisible}
            label={processLabel}
            type={ModalTypes.Base}>
            <div className={`${CSS_BASE_CLASS_NAME}__header`}>
                <h2>{processLabel}</h2>
                <Button
                    accessibleText={closeLabel}
                    cssClassName={"-modal-close -icon"}
                    onClick={closeDialog}
                    size={ButtonSizes.Medium}
                    style={ButtonStyles.Tertiary}>
                    <Icon type={Icons.Close} size={IconSizes.Large} />
                </Button>
            </div>
            <div className={`${CSS_BASE_CLASS_NAME}__content`}>
                <Paragraph size={ParagraphSizes.Small}>
                    {`This will affect ${publications.length} publications: ${displayCodesAndEditions}`}
                </Paragraph>
                <RadioList
                    items={radioButtons}
                    markerPosition={RadioMarkerPosition.Left}
                />
                <Paragraph size={ParagraphSizes.Small}>
                    {startNow ? startNowText : startLaterText}
                </Paragraph>
            </div>
            <div className={`${CSS_BASE_CLASS_NAME}__buttons`}>
                <Button
                    accessibleText={cancelLabel}
                    onClick={closeDialog}
                    style={ButtonStyles.Secondary}>
                    {cancelLabel}
                </Button>
                <Button
                    accessibleText={buttonText}
                    onClick={() => handleClick(startNow)}
                    style={ButtonStyles.Primary}>
                    {buttonText}
                </Button>
            </div>
        </Modal>
    );
};

export default PublicationAdminBulkActionsModal;
