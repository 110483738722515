import OrganizationErrorKey from "constants/organization-error-keys";
import OrganizationManifestRecord from "models/view-models/organization-manifest-record";
import OrganizationManifestsService from "utilities/services/organizations/organization-manifests-service";
import StringUtils from "utilities/string-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization-utils";
import { useCallback, useEffect, useState } from "react";

const defaultErrorHandler = (errorMessage: string) =>
    ToastManager.error(errorMessage);

export interface UseOrganizationManifestOptions {
    slug: string;
    onError?: () => void;
}

export default function useOrganizationManifest(
    options: UseOrganizationManifestOptions
) {
    const { slug, onError } = options;
    const errorMessage = t("error-loading-bookmarks");

    const [isLoading, setIsLoading] = useState(true);
    const [organizationManifest, setOrganizationManifest] = useState<
        OrganizationManifestRecord | undefined
    >();
    const { list: listApi } = OrganizationManifestsService.useList();

    // Handlers
    const handleError = useCallback(() => {
        if (onError != null) {
            onError();
            return;
        }

        defaultErrorHandler(errorMessage);
    }, [errorMessage, onError]);

    const get = useCallback(async () => {
        try {
            if (slug == null) {
                handleError();
                return;
            }

            setIsLoading(true);

            const result = await listApi();

            if (result.resultObjects === undefined) {
                throw new Error(
                    OrganizationErrorKey.NO_ORGANIZATION_FOUND_ERROR
                );
            }

            const organizationManifests = result.resultObjects;

            const foundManifest = organizationManifests.find((manifest) =>
                StringUtils.isEqual(manifest.loginSlug, slug)
            );

            if (foundManifest == null) {
                return;
            }

            setOrganizationManifest(foundManifest);
        } catch (error) {
            handleError();
        } finally {
            setIsLoading(false);
        }
    }, [handleError, listApi, slug]);

    useEffect(() => {
        get();
    }, [get]);

    return {
        get,
        isLoading,
        organizationManifest,
    };
}
