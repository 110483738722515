import { LoaderStyles } from "atoms/constants/loader-styles";
import Image, { ImageProps } from "atoms/images/image";
import FileRecord from "models/view-models/file-record";
import React, { Ref, forwardRef, useMemo } from "react";
import useFile from "utilities/hooks/domain/files/use-file";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface RemoteImageProps extends Omit<ImageProps, "src"> {
    fileId?: number;
    file?: FileRecord;
    loaderType?: LoaderStyles;
    onImageLoaded?: () => void;
    ref?: Ref<HTMLImageElement>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const RemoteImage = forwardRef<HTMLImageElement, RemoteImageProps>(
    (props: RemoteImageProps, ref: Ref<HTMLImageElement>) => {
        const { file: propsFile } = props;

        const { file: fileById } = useFile(props.fileId);

        const file = useMemo(
            () => propsFile ?? fileById,
            [fileById, propsFile]
        );

        const src = file?.presignedUrl ?? "";

        return <Image crossOrigin={true} ref={ref} src={src} {...props} />;
    }
);

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Export
// -------------------------------------------------------------------------------------------------

export default RemoteImage;

// #endregion Export
