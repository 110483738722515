import { AnchorTargetTypes } from "@rsm-hcd/javascript-core";
import Anchor from "atoms/anchors/anchor";
import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { AppNameWithHtml } from "constants/app-name-tm";
import { siteMap } from "internal-sitemap";
import { ModalTypes } from "molecules/constants/modal-types";
import BaseModalHeader from "molecules/modals/headers/base-modal-header";
import Modal from "molecules/modals/modal";
import React, { useEffect, useState } from "react";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useCheckPublication from "utilities/hooks/domain/publications/use-check-publication";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import { useLocalization } from "utilities/hooks/use-localization";
import useModalActions from "utilities/hooks/use-modal-actions";
import { RouteUtils } from "utilities/route-utils";

interface Props {
    code: string;
    edition: string;
    isSubscriber: boolean;
}

const BASE_CLASSNAME = "c-modal";

const FreeAccessPublicationExistsModal = (props: Props) => {
    const { t } = useLocalization();
    const { doesPublicationExist } = useCheckPublication();

    const { code, edition, isSubscriber } = props;
    const header = t("viewInLink", { appNameWithHtml: AppNameWithHtml });
    const body = t("switchToLink", { appNameWithHtml: AppNameWithHtml });
    const buttonText = t("takeToLink", { appNameWithHtml: AppNameWithHtml });

    const [route, setRoute] = useState<string>("");

    const { isOpen, handleClose, handleOpen } = useModalActions();

    const isTablet = useBreakpoint(
        Breakpoints.Tablet,
        BreakpointComparer.MaxWidth
    );

    useEffect(() => {
        const checkPublication = async () => {
            if (isSubscriber) {
                const publicationExists = await doesPublicationExist({
                    code,
                    edition,
                });

                if (publicationExists) {
                    setRoute(
                        RouteUtils.getUrl(
                            siteMap.publications.permalinks.publication,
                            { code, edition }
                        )
                    );
                    handleOpen();
                }
            }
        };

        checkPublication();
    }, [code, edition, isSubscriber, doesPublicationExist, handleOpen]);

    return (
        <Modal
            cssClassName={`${BASE_CLASSNAME} ${isTablet ? "-no-padding" : ""}`}
            isVisible={isOpen}
            label={header}
            type={isTablet ? ModalTypes.Bottom : ModalTypes.Base}
            closeDialog={handleClose}>
            <BaseModalHeader
                closeDialog={handleClose}
                heading={
                    <span
                        dangerouslySetInnerHTML={{
                            __html: header,
                        }}
                    />
                }></BaseModalHeader>
            <div className={`${BASE_CLASSNAME}__body`}>
                <span dangerouslySetInnerHTML={{ __html: body }} />
            </div>
            <div
                className={`${BASE_CLASSNAME}__footer -noBorderTop -centeredCtas`}>
                <Anchor target={AnchorTargetTypes.Blank} to={route}>
                    <Button
                        accessibleText={buttonText}
                        onClick={handleClose}
                        size={ButtonSizes.Medium}
                        style={ButtonStyles.Secondary}>
                        <span
                            dangerouslySetInnerHTML={{ __html: buttonText }}
                        />
                    </Button>
                </Anchor>
            </div>
        </Modal>
    );
};

export default FreeAccessPublicationExistsModal;
