import React from "react";
import { Helmet } from "react-helmet-async";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface StructuredDataProps {
    jsonLd: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const StructuredData: React.FC<StructuredDataProps> = (
    props: StructuredDataProps
) => {
    const { jsonLd } = props;
    return (
        <Helmet>
            <script type="application/ld+json">{jsonLd}</script>
        </Helmet>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default StructuredData;

// #endregion Exports
