import { RecordUtils } from "@rsm-hcd/javascript-core";
import { Record } from "immutable";
import {
    ArticlePreviewParams,
    ArticleParams,
    ArticlePermalinkParams,
} from "interfaces/routing/route-params";
import { siteMap } from "internal-sitemap";
import ArticleTableOfContents from "models/interfaces/table-of-contents/article-table-of-contents";
import { RouteUtils } from "utilities/route-utils";
import StringUtils from "utilities/string-utils";
import SectionTableOfContentsRecord from "./section-table-of-contents-record";

const defaultValues: ArticleTableOfContents =
    RecordUtils.defaultValuesFactory<ArticleTableOfContents>({
        id: undefined,
        chapterId: undefined,
        displaySequence: undefined,
        externalId: "",
        hasBookmark: false,
        isDeleted: false,
        label: "",
        nfpaLabel: "",
        sections: undefined,
        title: "",
        parts: undefined,
    });

export default class ArticleTableOfContentsRecord
    extends Record(defaultValues)
    implements ArticleTableOfContents
{
    constructor(params?: ArticleTableOfContents) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.sections != null) {
            params.sections = RecordUtils.ensureRecords(
                params.sections,
                SectionTableOfContentsRecord
            );
        }

        super(params);
    }

    /**
     * Gets the full 'display' title of the Article in the format of: Article {Label} {Title}
     * @example Article 402 Fixture Wires
     */
    public getDisplayTitle(locale?: string) {
        const label =
            StringUtils.translateDomainTerms(this.label, locale) ?? "";

        const title =
            StringUtils.translateDomainTerms(this.title, locale) ?? "";

        return `${label} ${title}`;
    }

    /**
     * Returns the label (if it exists). Otherwise, returns an empty string.
     */
    public getLabel(locale?: string): string {
        if (StringUtils.isEmpty(this.label)) {
            return "";
        }

        return StringUtils.translateDomainTerms(this.label);
    }

    public getRoute(
        params: ArticleParams,
        chapterNfpaLabel?: string,
        isAdmin?: boolean
    ) {
        return isAdmin
            ? this.getAdminRoute(params)
            : this.getPermalinkRoute(params, chapterNfpaLabel);
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<ArticleTableOfContentsRecord>} values
     */
    public with(
        values: Partial<ArticleTableOfContents>
    ): ArticleTableOfContentsRecord {
        return new ArticleTableOfContentsRecord(
            Object.assign(this.toJS(), values)
        );
    }

    private getPermalinkRoute(
        params: ArticlePermalinkParams,
        chapterNfpaLabel?: string
    ) {
        if (params.code == null || params.edition == null) return "#";

        return RouteUtils.getUrl(siteMap.publications.permalinks.article, {
            code: params?.code,
            edition: params?.edition,
            chapterNfpaLabel: params?.chapterNfpaLabel ?? chapterNfpaLabel,
            nfpaLabel: this.nfpaLabel,
        });
    }

    public getAdminRoute(params: ArticlePreviewParams) {
        return RouteUtils.getUrl(siteMap.publications.adminPreview.article, {
            publicationId: params?.publicationId ?? params?.id,
            chapterId: this.chapterId,
            id: this.id,
        });
    }
}
