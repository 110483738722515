import { useAtom } from "jotai";
import { useState } from "react";
import WorkGroupsAtom from "utilities/atoms/work-group/work-groups-atom";
import { useLocalization } from "utilities/hooks/use-localization";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import { ToastManager } from "utilities/toast/toast-manager";

const resourceEndpoint = "workgroups/:id";

const useWorkGroupDeleteService = () => {
    const [workGroupRecords, setWorkGroupRecords] = useAtom(WorkGroupsAtom);
    const useDelete = ServiceHookFactory.useDelete(resourceEndpoint);
    const { delete: DeleteService } = useDelete();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { t } = useLocalization();

    const removeWorkGroupFromCache = (workGroupId: number): void => {
        const newWorkGroupRecords = workGroupRecords.filter(
            (workGroupRecord) => {
                return workGroupRecord.id !== workGroupId;
            }
        );
        setWorkGroupRecords(newWorkGroupRecords);
    };

    const sendDeleteRequestToServer = async (
        workGroupId: number
    ): Promise<void> => {
        const response = await DeleteService(workGroupId);
        if (!isWorkGroupDeleted(response.status)) {
            throw new Error(response.status.toString());
        }
    };

    const isWorkGroupDeleted = (status: number): boolean => {
        if (status === 200) {
            return true;
        }
        return false;
    };

    const deleteWorkGroup = async (workGroupId: number): Promise<void> => {
        try {
            setIsLoading(true);
            await sendDeleteRequestToServer(workGroupId);
            removeWorkGroupFromCache(workGroupId);
            ToastManager.success(t("workgroup-delete-success"));
        } catch (error) {
            ToastManager.error(t("workgroup-delete-failure"));
        } finally {
            setIsLoading(false);
        }
    };

    return { deleteWorkGroup, isLoading } as const;
};

export default useWorkGroupDeleteService;
