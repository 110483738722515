import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { Icons } from "atoms/constants/icons";
import React from "react";
import { useSolutionsPageContext } from "utilities/contexts/use-solutions-page-context";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useReturnRoute from "utilities/hooks/domain/situational-navigation/use-return-route";
import useBreakpoint from "utilities/hooks/use-breakpoint";
import { useLocalization } from "utilities/hooks/use-localization";
import AnchorWithIcon from "./anchor-with-icon";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const DIRECT = "DiRECT";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const DirectBackAnchor: React.FC = () => {
    // -----------------------------------------------------------------------------------------
    // #region Hooks
    // -----------------------------------------------------------------------------------------

    const { t } = useLocalization();
    const { solutionsPageContext } = useSolutionsPageContext();
    const isDesktop = useBreakpoint(Breakpoints.SmallDesktop);
    const returnRoute = useReturnRoute(
        solutionsPageContext.categories,
        isDesktop
    );

    // #endregion Hooks

    // -----------------------------------------------------------------------------------------
    // #region Returns
    // -----------------------------------------------------------------------------------------

    return (
        <AnchorWithIcon
            icon={Icons.ChevronLeft}
            size={ButtonSizes.Small}
            style={ButtonStyles.Tertiary}
            to={returnRoute}>
            {solutionsPageContext.categories.isEmpty()
                ? t("backToLocation", { location: DIRECT })
                : t("directBackButton-backToResults")}
        </AnchorWithIcon>
    );

    // #endregion Returns
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default DirectBackAnchor;

// #endregion Exports
