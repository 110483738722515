import { Icons } from "atoms/constants/icons";
import SelectSizes from "atoms/constants/select-sizes";
import Select, { SelectOption } from "atoms/forms/select";
import Icon from "atoms/icons/icon";
import { SubscriptionTeamSize } from "models/enumerations/subscription-team-size";
import React from "react";
import { useLocalization } from "utilities/hooks/use-localization";
import { t } from "utilities/localization-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface TeamSizeSelectProps {
    errored?: boolean;
    onChange: (newValue: SubscriptionTeamSize) => void;
    requiredIndicator?: boolean;
    selectSize?: SelectSizes;
    value?: SubscriptionTeamSize;
    labelText?: string;
    labelCssClassName?: string;
}

/**
 * Wrapper type for a SelectOption used in the context of this component
 */
type TeamSizeSelectOption = SelectOption<unknown, SubscriptionTeamSize | "">;

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-team-size-select";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Components
// -----------------------------------------------------------------------------------------

const TeamSizeSelect = (props: TeamSizeSelectProps) => {
    const {
        errored,
        onChange,
        requiredIndicator = true,
        selectSize = SelectSizes.Base,
        labelText,
        labelCssClassName = "",
    } = props;

    const erroredModifier = errored === true ? "-error" : "";

    const { t } = useLocalization();

    const handleChange = (selectedOption?: TeamSizeSelectOption) => {
        if (
            selectedOption?.value === SubscriptionTeamSize.UpToFive ||
            selectedOption?.value === SubscriptionTeamSize.UpToTen
        ) {
            onChange(selectedOption.value);
        }
    };

    const fieldLabel =
        labelText ?? [t("team-size"), requiredIndicator ? "*" : ""].join(" ");

    return (
        <div className={`${CSS_CLASS_NAME} ${selectSize} ${erroredModifier}`}>
            <label className={labelCssClassName}>{fieldLabel}</label>
            <Select
                cssClassName={`${CSS_CLASS_NAME}`}
                label={`${t("subscriptionCard-teamSize")} *`}
                onChange={handleChange}
                options={buildOptions(props.value)}
                value={props.value}
            />
            {errored === true && (
                <div className={`${CSS_CLASS_NAME}__errors`}>
                    <Icon type={Icons.Information} />
                    <span>{t("subscriptionCard-mustSelectTeamSize")}</span>
                </div>
            )}
        </div>
    );
};

// #endregion Components

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

const buildOptions = (
    currentValue?: SubscriptionTeamSize
): TeamSizeSelectOption[] => {
    const baseOptions = [
        buildOption(SubscriptionTeamSize.UpToFive),
        buildOption(SubscriptionTeamSize.UpToTen),
    ];

    if (currentValue != null) {
        return baseOptions;
    }

    return [
        {
            isPlaceholder: true,
            label: t("subscriptionCard-selectTeamSize"),
            value: "",
        },
        ...baseOptions,
    ];
};

const buildOption = (value: SubscriptionTeamSize): TeamSizeSelectOption => {
    const item = `${value} ${t("team-member_plural").toLowerCase()}`;

    return { data: value, label: t("upToItem", { item }), value };
};

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default TeamSizeSelect;

// #endregion Exports
