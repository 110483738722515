import { Icons } from "atoms/constants/icons";
import SelectSizes from "atoms/constants/select-sizes";
import Select, { SelectOption } from "atoms/forms/select";
import UserBookmarkSortOption from "models/enumerations/sorting/user-bookmarks/user-bookmark-sort-option";
import { SearchFormSizes } from "molecules/enums/search-form-sizes";
import { SearchFormStyles } from "molecules/enums/search-form-style";
import SearchForm from "molecules/forms/search-form";
import FilterButton from "organisms/filter-button/filter-button";
import BookmarkColorFilterSelect, {
    CountedColorSelectOption,
} from "organisms/my-link/bookmark-color-filter-select";
import MyBookmarksSortAndFilterModal from "organisms/my-link/my-bookmarks/my-bookmarks-sort-and-filter-modal";
import { MY_BOOKMARKS_CSS_CLASS_NAME } from "organisms/my-link/my-bookmarks/my-bookmarks-tab";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import { UserBookmarkColors } from "utilities/enumerations/user-bookmark-colors";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import { useLocalization } from "utilities/hooks/use-localization";
import useModalActions from "utilities/hooks/use-modal-actions";
import CultureResources from "utilities/interfaces/culture-resources";
import { Translator } from "utilities/types/translator-type";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const SortOptions = (
    t: Translator<CultureResources>
): Array<SelectOption<UserBookmarkSortOption>> => {
    return [
        {
            label: t("sortOptionNewest"),
            value: UserBookmarkSortOption.CreatedOnDescending.toString(),
            data: UserBookmarkSortOption.CreatedOnDescending,
        },
        {
            label: t("sortOptionOldest"),
            value: UserBookmarkSortOption.CreatedOnAscending.toString(),
            data: UserBookmarkSortOption.CreatedOnAscending,
        },
    ];
};

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface MyBookmarksTabFiltersProps {
    colorFilters: UserBookmarkColors[];
    colorOptions: CountedColorSelectOption[];
    onChangeSearchText: (searchText: string) => void;
    onChangeColorFilters: (colorFilters: UserBookmarkColors[]) => void;
    onChangeSortBy: (sortBy: UserBookmarkSortOption) => void;
    searchText: string;
    sortBy: UserBookmarkSortOption;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

export const MyBookmarksTabFilters: React.FC<MyBookmarksTabFiltersProps> = ({
    colorFilters,
    colorOptions,
    onChangeSearchText,
    onChangeColorFilters,
    onChangeSortBy,
    searchText,
    sortBy,
}) => {
    const { t } = useLocalization();
    const [searchTextInput, setSearchTextInput] = useState(searchText);
    const [selectedColors, setSelectedColors] =
        useState<UserBookmarkColors[]>(colorFilters);
    const {
        isOpen: showSortAndFilterModal,
        handleOpen,
        handleClose,
    } = useModalActions();

    const isMobile = useBreakpoint(
        Breakpoints.Phone,
        BreakpointComparer.MaxWidth
    );

    const handleClearSearchTextForm = () => {
        onChangeSearchText("");
    };

    const handleResetFilters = () => {
        onChangeSearchText("");
        onChangeColorFilters([]);
    };

    const handleModalFiltersConfirmed = (
        colors: Array<UserBookmarkColors>,
        sortBy: UserBookmarkSortOption
    ) => {
        onChangeColorFilters(colors);
        onChangeSortBy(sortBy);
    };

    const handleSearchTextChanged = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSearchTextInput(e.target.value);
        e.preventDefault();
        e.stopPropagation();
    };

    const handleSortByChanged = (
        selectedOption?: SelectOption<UserBookmarkSortOption>
    ) =>
        onChangeSortBy(
            selectedOption?.data ?? UserBookmarkSortOption.CreatedOnDescending
        );

    const handleWithSearch = (e: React.FormEvent | React.MouseEvent) => {
        e.preventDefault();
        onChangeSearchText(searchTextInput);
    };

    const handleColorFilterSelectionChange = (colors: UserBookmarkColors[]) =>
        setSelectedColors(colors);

    const handleColorSelectionMenuClose = useCallback(
        () => onChangeColorFilters(selectedColors),
        [onChangeColorFilters, selectedColors]
    );

    useEffect(() => {
        setSelectedColors(colorFilters);
    }, [colorFilters]);

    useEffect(() => {
        setSearchTextInput(searchText);
    }, [searchText]);

    const sortAndFilter = `${t("sort")} ${t("and")} ${t("filter")}`;

    return (
        <div className={`${MY_BOOKMARKS_CSS_CLASS_NAME}__filters`}>
            <SearchForm
                accessibleText={t(
                    "myBookmarksTabFilters-searchForm-accessibleText"
                )}
                buttonIcon={Icons.Search}
                id="my-bookmarks-search-input"
                onClear={handleClearSearchTextForm}
                onSearchClick={handleWithSearch}
                onSearchTextChange={handleSearchTextChanged}
                onSubmit={handleWithSearch}
                placeholder={t("myBookmarksTabFilters-searchForm-placeholder")}
                searchText={searchTextInput}
                size={SearchFormSizes.Small}
                style={SearchFormStyles.TertiaryAlt}
            />

            {!isMobile && (
                <div
                    className={`${MY_BOOKMARKS_CSS_CLASS_NAME}__filters__dropdowns`}>
                    <BookmarkColorFilterSelect
                        onChange={handleColorFilterSelectionChange}
                        onMenuClosed={handleColorSelectionMenuClose}
                        options={colorOptions.map(
                            (option: CountedColorSelectOption) => ({
                                ...option,
                            })
                        )}
                        value={selectedColors}
                    />
                    <Select
                        id="user-bookmark-sortby-select"
                        onChange={handleSortByChanged}
                        options={SortOptions(t)}
                        size={SelectSizes.Small}
                        value={sortBy.toString()}
                    />
                </div>
            )}

            {isMobile && (
                <Fragment>
                    <FilterButton
                        count={colorFilters.length}
                        onClick={handleOpen}>
                        {sortAndFilter}
                    </FilterButton>

                    <MyBookmarksSortAndFilterModal
                        closeDialog={handleClose}
                        isVisible={showSortAndFilterModal}
                        onConfirm={handleModalFiltersConfirmed}
                        onReset={handleResetFilters}
                        options={colorOptions}
                        selectedColors={selectedColors}
                        sortBy={sortBy}
                    />
                </Fragment>
            )}
        </div>
    );
};

// #endregion Component
