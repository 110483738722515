import { useState } from "react";
import useModalActions from "utilities/hooks/use-modal-actions";
import uuid from "uuid";
import { Quill } from "quill";

/**
 * Custom hook to wrap up the Quill editor component state.
 * Creates IDs/CSS selectors for the editor container and toolbar,
 * caches selection index on selection change, and handles the
 * custom modals.
 */
export default function useQuillState() {
    // stateful so it doesn't recompute on every render
    const [containerId] = useState(`rich-text-editor-${uuid.v4()}`);
    const [containerSelector] = useState(`#${containerId}`);
    const [toolbarId] = useState(`rich-text-editor-toolbar-${uuid.v4()}`);

    const {
        handleClose: closeImageModal,
        handleOpen: openImageModal,
        isOpen: isImageModalOpen,
    } = useModalActions();
    const {
        handleClose: closeReferenceLinkModal,
        handleOpen: openReferenceLinkModal,
        isOpen: isReferenceLinkModalOpen,
    } = useModalActions();

    /**
     * Cache the last selection index; sometimes, if the editor loses focus (like when
     * the image or reference link modal opens) the Quill selection object gets set back
     * to null, but when we confirm the image/reference link, we want it to be inserted
     * at the last place the cursor was.
     */
    const [quillSelectionIndex, setQuillSelectionIndex] = useState<number>();

    /**
     * Gets the current selection index, or, if null, the last non-null
     * selected index, or, if that's also null, then the index of the end
     * of the editor content.
     * @param quill the editor instance.
     */
    const getCurrentIndex = (quill: Quill) =>
        quill.getSelection()?.index ??
        quillSelectionIndex ??
        quill.getLength() ??
        0;

    return {
        closeImageModal,
        closeReferenceLinkModal,
        containerId,
        containerSelector,
        getCurrentIndex,
        isImageModalOpen,
        isReferenceLinkModalOpen,
        openImageModal,
        openReferenceLinkModal,
        quillSelectionIndex,
        setQuillSelectionIndex,
        toolbarId,
    };
}
