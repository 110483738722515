import {
    ServiceUtils as AndcultureCodeServiceUtils,
    PromiseFactory,
    ResultRecord,
} from "@rsm-hcd/javascript-core";
import { AxiosResponse } from "axios";
import GlobalStateRecord from "models/view-models/global-state-record";
import { GlobalStateUpdater } from "./contexts/use-global-state-context";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface ServicesConfiguration {
    cultureCode?: string;
    globalState: GlobalStateRecord;
    setGlobalState: GlobalStateUpdater;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Variables
// -----------------------------------------------------------------------------------------

let _configuration: ServicesConfiguration;

// #endregion Variables

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

/**
 * Configure services globally
 */
const configure = (configuration: ServicesConfiguration) => {
    _configuration = configuration;

    AndcultureCodeServiceUtils.configure(
        _configuration.cultureCode,
        handleApiResponseError,
        handleApiResponseSuccess
    );
};

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Event Handlers
// -----------------------------------------------------------------------------------------

const handleApiResponseError = (error: any) => {
    if (error == null || error.response == null) {
        return Promise.reject(error);
    }

    const response = error.response;

    // Handle global HTTP status codes
    switch (response.status) {
        case 401: {
            _configuration.setGlobalState(
                _configuration.globalState.setUnauthenticated()
            );
            return PromiseFactory.pending(); // Short-circuit promise chain
        }
        case 333:
        case 403: {
            // Try to instantiate a new ResultRecord from the response data, if it is an actual object
            let resultRecord = new ResultRecord().addError("Unknown", error);
            if (response.data != null && typeof response.data === "object") {
                resultRecord = new ResultRecord(response.data);
            }

            _configuration.setGlobalState((globalState: GlobalStateRecord) =>
                globalState.with({
                    unauthorizedResult: resultRecord,
                })
            );
            return PromiseFactory.pending(); // Short-circuit promise chain
        }
        case 590: {
            _configuration.setGlobalState((globalState: GlobalStateRecord) =>
                globalState.with({
                    isAppInMaintenanceMode: true,
                })
            );
            return PromiseFactory.pending(); // Short-circuit promise chain
        }
    }

    // If shape of a Result, directly wrap in result record
    const data = response.data;
    if (data != null && data.errors != null) {
        return Promise.reject(new ResultRecord(data));
    }

    // Wrap unhandled error in result record
    let unhandledResult = new ResultRecord();
    unhandledResult = unhandledResult.addError("Unknown", error);
    return Promise.reject(unhandledResult);
};

const handleApiResponseSuccess = (response: AxiosResponse) => response;

// #endregion Event Handlers

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const ServiceUtils = {
    configure,
    handleApiResponseError,
    handleApiResponseSuccess,
    mapAxiosResponse: AndcultureCodeServiceUtils.mapAxiosResponse,
    mapPagedAxiosResponse: AndcultureCodeServiceUtils.mapPagedAxiosResponse,
};

export default ServiceUtils;

// #endregion Exports
