import { BaseSpanishSpain, Culture } from "@rsm-hcd/javascript-core";
import CultureResources from "utilities/interfaces/culture-resources";
import LocalizationUtils from "utilities/localization-utils";

const ProfessionallyTranslatedSpanishSpain = {
    "aboutApp": "Acerca de {{appName}}",
    "acceptGroupInvitation-invitedByNameToJoin": "{{byName}} te invitó {{toJoin}}",
    "acceptGroupInvitation-invitedToJoin": "Has sido invitado {{toJoin}}",
    "acceptGroupInvitation-join": "Unete al grupo",
    "acceptGroupInvitation-mismatch": "Esta invitación no coincide con la dirección de correo electrónico con la que ha iniciado sesión actualmente. Si tiene varias cuentas con NFPA, primero deberá cerrar sesión antes de unirse al nuevo equipo.",
    "acceptGroupInvitation-switchProfiles": "Estás usando {{appNameTm}} como miembro de {{teamName}}. Haz clic en el icono de configuración de la cuenta para cambiar de perfil.",
    "acceptGroupInvitation-toJoin": "para unirte a un grupo en {{appNameTm}}",
    "acceptGroupInvitation-unavailableTeamname": "Nombre del grupo no disponible",
    "accepting": "aceptando",
    "account": "cuenta",
    "accountDashboard-header": "Configuraciones de la cuenta",
    "accountDashboard-manageProfile": "Administra tu perfil en {{address}}",
    "accountDashboard-offline": "Fuera de línea",
    "accountDashboard-subscriptionDetails": "Detalles de la suscripción",
    "accountDashboard-teamManagement": "Administración del grupo",
    "accountInformationTab-header": "Información de la cuenta",
    "accountSettings": "Configuraciones de la cuenta",
    "action-searchByFields": "Buscar por {{fields}}...",
    "actionByUser": "{{action}} por <0>{{name}}</0>",
    "actionItem": "{{action}} {{item}}",
    "actionsForItem": "Acciones para {{item}}",
    "activateTrial": "Activa tu prueba gratuita",
    "add": "Agregar",
    "added": "Agregado",
    "addedJustNow": "Agregado justo ahora",
    "addItem": "Agregar {{item}}",
    "addItemTo": "Agregar {{item}} a {{container}}",
    "additionalPublications": "Publicaciones adicionales",
    "addNoteHere": "Agregar nota aquí...",
    "allBookmarks": "Todos los marcadores",
    "allItem": "Todos los {{item}}",
    "altService": "Tengo un servicio alternativo",
    "and": "y",
    "annex_plural": "Anexos",
    "annex": "Anexo",
    "announcements-whatsNew": "Novedades",
    "article_plural": "Artículos",
    "article": "Artículo",
    "automaticallyRenew": "renovar automáticamente",
    "autoRenew": "No quiero que se renueve automáticamente",
    "backToLocation": "Volver a {{location}}",
    "beginAddingBookmarks": "Empieza a marcar contenido en tus publicaciones.",
    "bestValue": "mejor relación calidad-precio",
    "billingCycle-goToNfpaToPurchase": "completar registracion y compra",
    "billingCycle-individual-annual-autorenew": "Anual con renovación automática",
    "billingCycle-individual-annual": "Anual",
    "billingCycle-individual-monthly-autorenew": "Mensual con renovación automática",
    "billingCycle-planDetails": "Detalles del plan {{subscriptionType}}",
    "billingCycle-selectABillingCycle": "Seleccionar ciclo de facturación de {{subscriptionType}}",
    "billingCycle-selectABillingCycleToContinue": "Elige un ciclo de facturación para continuar.",
    "billingCycle-team-annual-autorenew": "Anual con renovación automática",
    "billingCycle-team-annual": "Anual",
    "black": "Negro",
    "blue": "Azul",
    "book_plural": "Libros",
    "book_update_error": "Hubo un error al actualizar el libro.",
    "book": "Libro",
    "bookmark_plural": "Marcadores",
    "bookmark": "Marcador",
    "bookmarkColorFilterSelect-multiSelect-placeholder": "Todos los colores",
    "bookmarkModifiedText": "{{modifiedDate}} en {{publication}}",
    "bookmarkWithCount_plural": "{{count}} marcadores",
    "bookmarkWithCount": "{{count}} marcador",
    "both": "Ambos",
    "breadcrumbs-myLink-myBookmarks": "Mis marcadores",
    "breadcrumbs": "Breadcrumbs",
    "byUser": "por {{user}}",
    "cancel": "Cancelar",
    "cancelEnterpriseMessage": "Para cancelar tu suscripción empresarial, comunícate con tu representante de ventas de NFPA.",
    "cancelItem": "Cancelar {{item}}",
    "cancellationTerm1": "Si optas por cancelar hoy, la cancelación de tu suscripción tardará entre 3 y 5 días hábiles en surtir efecto.",
    "cancellationTerm2": "Si cancelas durante un período de prueba, podrías perder acceso al contenido de inmediato.",
    "cancellationTerm3": "Si cancelas una suscripción mensual, puedes continuar usando la suscripción hasta la próxima fecha de facturación.",
    "cancellationTerm4": "Si pagaste por adelantado una suscripción anual, no podemos reembolsarte la parte no utilizada de tu suscripción.",
    "cancellationTerm5": "Al finalizar tu suscripción, tus notas y otro contenido personalizado ya no estarán disponibles.",
    "cancellationTermNotes": "Ten en cuenta las siguientes condiciones de cancelación antes de cancelar tu suscripción a NFPA LiNK:",
    "cancelMySubscription": "Cancelar mi suscripción",
    "cancelOrConfirmWithoutSaving": "¿Estás seguro de que deseas continuar sin guardar?",
    "category_plural": "Categorías",
    "category": "Categoría",
    "changeEdition": "Cambiar edición",
    "changeEditionModal-selectPublicationEdition": "Seleccionar edición de publicación",
    "changesSuccessfullySaved": "Los cambios se han guardado exitosamente.",
    "changeTeamName": "Cambiar el nombre del grupo",
    "chapter_plural": "Capítulos",
    "chapter": "Capítulo",
    "chapterNumber": "Capítulo {{number}}",
    "checkOutFaq-link-label": "Consulta las respuestas a las preguntas más frecuentes",
    "checkOutFaq": "Consulta nuestras preguntas frecuentes",
    "close": "Cerrar",
    "closeItem": "Cerrar {{item}}",
    "code": "Código",
    "collapse": "Contraer",
    "collapseAll": "Contraer todo",
    "collection_plural": "Colecciones",
    "collection": "Colección",
    "collections-helpText": "Para crear colecciones ve a MyLiNK > Colecciones",
    "collections": "Colecciones",
    "collectionTitle": "Título de la colección",
    "collectionTitlePlaceholder": "Ingresa un título de la colección...",
    "collectionWithCount_plural": "{{count}} Colecciones",
    "collectionWithCount": "{{count}} Colección",
    "color_plural": "Colores",
    "color": "Color",
    "committeePersonnel": "Personal del comité",
    "confirm": "Confirmar",
    "confirmationMessage": "¿Estás seguro de que deseas {{action}} este {{item}}?",
    "confirmItem": "Confirmar {{item}}",
    "confirmRemoveUserFromTeam": "¿Estás seguro de que deseas eliminar <2>{{user}}</2> de tu grupo?",
    "contactModal-heading": "Envía una pregunta",
    "contactModal-preferredContact": "Método de contacto preferido",
    "contactModal-reasonLabel": "Motivo para comunicarte",
    "contactReasons-accountHelp": "Necesito ayuda con mi cuenta",
    "contactReasons-billing": "Ayuda sobre precios, facturación y licencias",
    "contactReasons-feedback": "Retroalimentación general",
    "contactReasons-modifySubscription": "Modificar mi suscripción",
    "contactReasons-other": "Algo más",
    "contactReasons-suggestions": "Solicitud o sugerencia de función",
    "contactReasons-technical": "Problema técnico",
    "continue": "Continuar",
    "createAnAccount": "Crear una cuenta",
    "creating": "creando",
    "customerService": "Tuve un mal servicio al cliente",
    "delete": "Eliminar",
    "deleteItem": "Eliminar {{item}}",
    "deletingItem": "Eliminando {{item}}",
    "dialog": "Diálogo",
    "directBackButton-backToResults": "Volver a resultados",
    "disclaimerText-disclaimer": "Descargo de responsabilidad",
    "disclaimerText-disclaimerBodySection1": "Reconoces y aceptas que independientemente de cómo se pueda etiquetar o mostrar la información, la NFPA no realiza esfuerzos para brindar servicios de ingeniería profesional o asesoramiento a través del servicio o la información.",
    "disclaimerText-disclaimerBodySection2": "Cada código, estándar u otro documento de la NFPA contenido en la información tiene sus propios descargos de responsabilidad y declaraciones relacionadas con la limitación de responsabilidad. Los términos de cada una de dichas declaraciones permanecerán vigentes y serán adicionales a todo lo establecido en este documento.",
    "done": "Listo",
    "download": "Descargar",
    "downloading": "Descargando",
    "duplicateUserCollectionError": "Ya existe una colección con este nombre. Intenta con otro nombre.",
    "earlierVersion": "Versión anterior",
    "edit": "Editar",
    "edited": "Editado",
    "edition": "Edición",
    "editItem": "Editar {{item}}",
    "effective": "Efectivo",
    "email-multi-input-placeholder": "Ingresa direcciones de correo electrónico",
    "email-required-field": "El correo electrónico es obligatorio.",
    "email-template": "Plantilla de correo electrónico",
    "email": "Correo electrónico",
    "enhancedContent": "Contenido mejorado",
    "enterADescription": "Ingresa una descripción (opcional)...",
    "enterAField_possessive": "Ingresa tu {{fieldName}}",
    "enterAField": "Ingresa un {{fieldName}}",
    "enterAMessage": "Ingresa un mensaje",
    "entireTeam": "Todo el equipo",
    "equation": "Ecuación",
    "errors-actionResource_plural": "Hubo un problema {{action}} {{resource}}.",
    "errors-actionResource_possessive": "Hubo un problema al {{action}} tu {{resource}}.",
    "errors-actionResource": "Hubo un problema al {{action}} este {{resource}}.",
    "exceedsCharacterLimit": "Supera el {{limit}} límite de caracteres",
    "expand": "Expandir",
    "expandAll": "Expandir todo",
    "expandItem": "Expandir {{item}}",
    "expire": "caducar",
    "expiredLink": "Enlace caducado",
    "faq": "Preguntas frecuentes",
    "favorite_plural": "Favoritos",
    "favorite": "Favorito",
    "favorited": "Marcado como favorito",
    "favoriteItem": "Marcar como favorito un {{item}}",
    "featuredBook-admin-checkbox": "Característica en el panel",
    "featuredPublications-availableNow": "<0>Disponible ahora</0> en {{appName}}",
    "featuredPublications-browseAll": "No veo lo que estás buscando? <0>Navega por todas las publicaciones disponibles</0> en {{appNameTm}}",
    "featuredPublications-c2a-buttonText": "Elige un plan",
    "featuredPublications-c2a-text": "¿Listo para empezar?",
    "featuredPublications-comingSoon": "Próximamente",
    "featuredPublications-header": "Todo el contenido nuevo y próximo incluido con tu suscripción",
    "featuredPublications-notifications": "<0>Regístrate para recibir notificaciones</0> cuando haya nuevo contenido disponible.",
    "featuredPublications-subText": "Como suscriptor de {{appName}}, podrás acceder a todo el contenido a medida que se agregue durante el período de tu suscripción, <0> sin cargo adicional.</0>",
    "featuredPublications": "Publicaciones destacadas",
    "field-description": "Descripción",
    "field-email_plural": "Direcciones de correo electrónico",
    "field-email": "Dirección de correo electrónico",
    "field-firstName": "Nombre de pila",
    "field-fullName": "Nombre",
    "field-invalid": "{{name}} no es válido",
    "field-lastName": "Apellido",
    "field-notAvailable": "{{field}} no disponible",
    "field-notes": "Notas",
    "field-optional": "(opcional)",
    "field-phoneNumber": "Número de teléfono",
    "field-title": "Título",
    "figure": "Figura",
    "filter_plural": "Filtros",
    "filter": "Filtro",
    "filterBy": "$t(filtrar) por {{item}}",
    "filteredByWithCount": "Filtrado por {{count}} {{filter}}",
    "filterLabel-clearWithCount_plural": "Borrar {{count}} filtros",
    "filterLabel-clearWithCount": "Borrar {{count}} filtro",
    "filterLabelWithCount_plural": '$t(bookmarkWithCount_plural, {"count": {{count}} }) filtrado por',
    "filterLabelWithCount": "{{count}} marcador filtrado por",
    "forgotPassword": "Contraseña olvidada",
    "forgotYourPassword": "Olvidaste tu contraseña",
    "freeTrialCTA": "Tu prueba gratuita termina en <2>{{endingDate}}</2>. <5><1>Actualiza tu plan</1></5> para seguir usando contenido a pedido.",
    "gettingStarted": "Introducción a NFPA LiNK",
    "gotIt": "¡Entiendo!",
    "goToItem": "Ir al elemento",
    "gotoResultPage": "Ir a la página de resultados {{pageNumber}}",
    "green": "Verde",
    "groupInvite-cancelingInvitationError": "Hubo un problema al cancelar la invitación.",
    "groupInvite-cancelingInvitationSuccess": "La invitación se canceló exitosamente",
    "groupInvite-invitationInputHelperText": "Para invitar a varios miembros del grupo a la vez, separa las direcciones de correo electrónico con una coma (por ejemplo, usuario@dominio.com, usuario@dominio.com)",
    "groupInvite-sendInvitationError": "Hubo un problema al enviar la invitación, actualiza la página y vuelve a intentarlo.",
    "groupInvite-sentToRecipient_plural": "Se han enviado invitaciones del grupo a todos los destinatarios",
    "groupInvite-sentToRecipient": "Se ha enviado la invitación del grupo a {{recipient}}",
    "hide": "Ocultar",
    "home": "Inicio",
    "includeEnhancedContent": "Incluir Contenido Mejorado",
    "individual": "Individual",
    "information": "Información",
    "informativeAnnex_plural": "Anexos informativos",
    "informativeAnnex": "Anexo informativo",
    "introduction": "Introducción",
    "invalid-email-field": "Ingresa una dirección de correo electrónico válida",
    "invalid-emails-field": "Las siguientes direcciones de correo electrónico no son válidas: {{emails}}",
    "invalidShareLink": "Parece que tu enlace para compartir ha caducado o no es válido. Los enlaces para compartir solo permanecen activos durante 14 días.",
    "invitation": "invitación",
    "invite": "Invitar",
    "invited": "Invitado",
    "inviteTeamMembers": "Invitar a miembros del grupo",
    "issueSyncingGroup": "Hubo un problema al sincronizar este grupo.",
    "itemCount": "{{count}} {{item}}",
    "itemNotAvailable": "{{item}} No disponible",
    "itemView": "Ver {{item}}",
    "language_plural": "Idiomas",
    "language": "Idioma",
    "languagePreferences": "Preferencias de idioma",
    "laterVersion": "Última versión",
    "readonlyPublicationDismiss": "Descartar",
    "readonlyPublicationAbout": "LiNK proporciona acceso de solo lectura a ediciones anteriores de ciertas publicaciones de NFPA. Estas publicaciones se muestran en un visor de imágenes que no ofrece las mismas funciones interactivas que están disponibles en las publicaciones actuales.",
    "readonlyPublicationAboutTitle": "Publicaciones de solo lectura",
    "less": "Menos",
    "limitedTime": "Solo se necesitaba por un tiempo limitado",
    "linkWillExpireWarning": "Este enlace caducará en {{expiration}}.",
    "loading": "cargando",
    "loadingItem": "Cargando {{item}}...",
    "menu": "Menú",
    "message": "Mensaje",
    "minute_plural": "Minutos",
    "minute": "Minuto",
    "minuteWithCount_plural": "{{count}} minutos",
    "minuteWithCount": "{{count}} minuto",
    "modal": "Modal",
    "modify": "Modificar",
    "monthAbbreviation": "mes",
    "more": "Más",
    "myBookmarksTab-metaTags-description": "{{appName}}: Mis marcadores",
    "myBookmarksTab-noBookmarksResultsBanner-subTitle": "Comienza a agregar marcadores de publicaciones.",
    "myBookmarksTab-noBookmarksResultsBanner-title": "No se agregaron marcadores",
    "myBookmarksTab-success-removeBookmark": "Se ha eliminado el marcador.",
    "myBookmarksTab-success-removeBookmarkFromCollection": "El marcador se ha eliminado de la colección.",
    "myBookmarksTabFilters-searchForm-accessibleText": "Buscar marcadores",
    "myBookmarksTabFilters-searchForm-placeholder": "Buscar por título",
    "mylinkLayout-tabs-label-myBookmarks": "Mis marcadores",
    "name": "Nombre",
    "nav-aria-direct-link": "Ir a DiRECT",
    "nav-aria-mylink-link": "Ir a MyLiNK",
    "nav-aria-search-link": "Abrir el menú de búsqueda",
    "nav-aria-user-menu-link": "Menú del usuario",
    "nav-tooltip-direct-description": "Encuentra códigos y normas basados en tu situación",
    "nav-tooltip-direct-title": "DiRECT",
    "nav-tooltip-home-description": "Publicaciones y marcadores recientes",
    "nav-tooltip-home-title": "Inicio",
    "nav-tooltip-mylink-description": "Administra tus marcadores y colecciones",
    "nav-tooltip-mylink-title": "MyLiNK",
    "nav-tooltip-search-description": "Encuentra códigos y normas basados en tu situación",
    "nav-tooltip-search-title": "Buscar",
    "needHelpSigningIn": "¿Necesitas ayuda para iniciar sesión?",
    "nevermindKepSubscription": "No importa, mantén la suscripción",
    "newItem": "Nuevo {{item}}",
    "newUserLoginForm-errors-currentRoleExpired": "Tu suscripción a NFPA LiNK ha caducado y pronto perderás el acceso. Comunícate con servicio al cliente.",
    "newUserLoginForm-errors-loginFailed": "Error al iniciar sesión. Proporciona un correo electrónico y una contraseña válidos.",
    "newUserLoginForm-errors-loginGeneral": "Hubo un problema al iniciar sesión. Vuelve a intentarlo.",
    "newUserLoginForm-loader-accessibleText": "Iniciando sesión...",
    "newUserLoginForm-loggedInAs-memberOf": "Estás conectado como miembro de {{teamName}}",
    "newUserLoginForm-loggedInAs-user": "Has iniciado sesión como {{username}}",
    "newUserLoginPage-callToAction": "Regístrate en {{appName}} para obtener acceso a estas funciones:",
    "newUserLoginPage-errors-loggingOut": "Hubo un error al cerrar la sesión. Actualiza página.",
    "newUserLoginPage-errors-selectAnotherRole": "Tu rol actual ha caducado o ha sido eliminado. Vuelve a iniciar sesión para que se te asigne un rol.",
    "newUserLoginPage-meta-description": "Regístrate en {{appName}} para obtener acceso a los códigos y normas de la NFPA, y al contenido mejorado y relacionado.",
    "nextItem": "Siguiente {{item}}",
    "nextResultsPage": "Siguiente página de resultados",
    "nfpaLogo": "Logotipo de la NFPA",
    "noCollections": "No hay colecciones",
    "none": "Ninguna",
    "noOptionsAvailable": "No hay opciones disponibles.",
    "noOtherCollections": "No hay otras colecciones",
    "noSearchResultsBanner-subTitle": "Intenta otra búsqueda o elimina los filtros.",
    "noSearchResultsBanner-title": "0 resultados",
    "noteTextTooLong": "El texto de la nota es demasiado largo",
    "notFavorited": "No marcado como favorito",
    "notUseful": "No creo que el producto sea útil",
    "offlineTab-buildOfflineSearchIndex": "Compilar índice de búsqueda sin conexión",
    "offlineTab-buildSearchIndex": "Compilar índice de búsqueda",
    "offlineTab-buildingSearchIndex": "Compilando índice de búsqueda...",
    "offlineTab-downloadFavorites": "Descargar favoritos para uso sin conexión",
    "open": "Abierto",
    "openInBookView": "Abrir en vista de libro",
    "openItem": "Abrir {{item}}",
    "openNavigation": "Abrir navegación",
    "origins": "Orígenes",
    "other": "Otros",
    "pageNavigation-noResults-label": "Mostrando 0 resultados",
    "pageNavigation-pageSize-label": "Tamaño de página",
    "pageNavigation-results-label": "{{startIndex}}-{{endIndex}} de {{resultTotal}}",
    "password": "Contraseña",
    "passwordMismatch": "Las contraseñas no coinciden",
    "passwordRequirements": 'Debe tener al menos 6 caracteres y contener 3 de los 4 tipos de caracteres siguientes: "mayúscula", "minúscula", "número" o "carácter especial".',
    "pendingInvitations": "invitaciones pendientes",
    "pendingInvitiationsMessage_plural": "Tiene {{count}} $t(pendingInvitations)",
    "pendingInvitiationsMessage": "Tienes {{count}} invitación pendiente",
    "phone": "Teléfono",
    "pink": "Rosa",
    "plansPage-freeTrial-callToAction": "¡Pruébalo gratis durante 14 días!",
    "plansPage-freeTrialExpired-subtitle": "Gracias por probar {{appName}}. Adquiere una suscripción para seguir usando el producto.",
    "plansPage-freeTrialExpired-title": "Tu prueba gratuita de 14 días de {{appName}} ha finalizado.",
    "plansPage-interestedInTryingLink": "¿Te interesa probar {{appName}}?",
    "plansPage-pageTitle": "Selección del plan de {{appName}}",
    "plansPage-seeUpcomingPublications": "Consulta la lista de publicaciones próximas.",
    "plansPage-selectPlanTitle": "Elige un plan de {{appName}} para comenzar",
    "plansPage-subscriptionExpired-subtitle": "Adquiere una suscripción a continuación para seguir usando el producto.",
    "plansPage-subscriptionExpired-title": "Tu suscripción a {{appName}} ha caducado.",
    "preference_plural": "Preferencias",
    "preference": "Preferencia",
    "preferences": "Preferencias",
    "preferredLanguage": "Idioma preferido",
    "previousItem": "Anterior {{item}}",
    "previousResultsPage": "Página de resultados anteriores",
    "primary": "primario",
    "privacyPolicy": "Política de privacidad",
    "propertyIsRequired": "{{name}} es obligatorio.",
    "publication_plural": "Publicaciones",
    "publication": "Publicación",
    "publicationCover": "portada de la publicación",
    "publicationFavorites-errors-addingToFavorites": "Hubo un problema al agregar {{codeAndEdition}} a favoritos.",
    "publicationFavorites-errors-removingFromFavorites": "Hubo un problema al eliminar {{codeAndEdition}} de favoritos.",
    "publicationPage-addedToFavorites": "{{codeAndEdition}} agregado a los favoritos de tu panel",
    "publicationPage-addToFavorites": "Agregar a los favoritos de tu panel",
    "publicationPage-removedFromFavorites": "{{codeAndEdition}} eliminado de los favoritos de tu panel",
    "publicationPage-removeFromFavorites": "Eliminar de los favoritos de tu panel",
    "publicationSelectionModal-searchPlaceholder": "Buscar por título o número",
    "publish": "Publicar",
    "publishChanges": "Publicar cambios",
    "published": "Publicado",
    "publishStatusMenu-deleteConfirmation-message": "¿Estás seguro de que deseas eliminar este {{item}}?",
    "publishStatusMenu-publishChangesConfirmation-message": "¿Estás seguro de que deseas publicar cambios en este {{item}}?",
    "publishStatusMenu-publishConfirmation-message": "¿Estás seguro de que deseas publicar en este {{item}}?",
    "publishStatusMenu-statusText-changesSaved": "Cambios guardados",
    "publishStatusMenu-statusText-unpublishedChangesSaved": "Se han guardado los cambios no publicados",
    "publishStatusMenu-unpublishConfirmation-message": "¿Estás seguro de que deseas anular la publicación de este {{item}}?",
    "purple": "Morado",
    "quickStartGuide-link-label": "Aprende a usar las funciones principales de NFPA LiNK.",
    "quickStartGuide": "GUÍA DE INICIO RÁPIDO",
    "readLess": "Leer menos",
    "readMore": "Leer más",
    "readOnly": "Solo lectura",
    "reason": "Motivo",
    "reasonForCancellation": "Motivo de la cancelación*",
    "recentBookmarks-emptyState": "No hay marcadores recientes",
    "recentBookmarks": "Marcadores recientes",
    "red": "Rojo",
    "reference_plural": "Referencias",
    "reference": "Referencia",
    "reference-panel-add-to": "Agregar al panel de referencia",
    "reference-panel-empty-action-text": 'Elige "{{action}}" en los enlaces de referencia.',
    "reference-panel-empty-references": "0 referencias agregadas",
    "reference-panel-menu": "{{action}} referencias",
    "reference-panel-open-in": "Abrir en el panel de referencia",
    "refresh": "Actualizar",
    "registerOrLogin-loginHeader": "Inicie sesión con tu cuenta existente de NFPA.org para registrarte.",
    "registerOrLogin-loginQuestion": "¿No tienes una cuenta de NFPA?",
    "registerOrLogin-signUpHeader": "Crea una cuenta de NFPA.org para registrarte en {{appNameWithHtml}}",
    "registerOrLogin-signUpQuestion": "¿Ya tienes una cuenta con NFPA.org?",
    "relatedResource_plural": "Recursos relacionados",
    "relatedResource": "Recurso relacionado",
    "remainingInvitesMessage_plural": "Te quedan {{count}} invitaciones restantes ({{pending}})",
    "remainingInvitesMessage": "Te quedan {{count}} invitación restante ({{pending}})",
    "rememberMe": "Recuérdame",
    "remove": "Quitar",
    "removeFromTeam": "Quitar del grupo",
    "removeItemFrom": "Quitar {{item}} de {{container}}",
    "removing": "quitando",
    "removingItemFrom": "Quitando {{item}} de {{container}}...",
    "renameItem": "Renombrar {{item}}",
    "resend": "Reenviar",
    "reset": "Reiniciar",
    "resourceSection-resourceCard-documents-accessibleText": "Descargar documento {{title}}",
    "resourceSection-resourceCard-externalVideos-accessibleText": "Ver {{title}}",
    "resourceSection-resourceCard-files-accessibleText": "Descargar archivo {{title}}",
    "resourceSection-resourceCard-image-accessibleText": "Descargar imagen {{title}}",
    "resourceSection-resourceCard-video-accessibleText": "Ver video {{title}}",
    "resourceType-Document_plural": "Documentos",
    "resourceType-Document": "Documento",
    "resourceType-File_plural": "Archivos",
    "resourceType-File": "Archivo",
    "resourceType-Image_plural": "Imágenes",
    "resourceType-Image": "Imagen",
    "resourceType-Link_plural": "Videos externos",
    "resourceType-Link": "Video externo",
    "resourceType-Video_plural": "Videos",
    "resourceType-Video": "Video",
    "result_plural": "{{count}} resultados",
    "result": "{{count}} resultado",
    "resultsPerPage": "Resultados por página:",
    "save": "Guardar",
    "saveChanges": "Guardar cambios",
    "saving": "Guardando",
    "savingChanges": "Guardando cambios...",
    "search": "Buscar",
    "searchItem": "Buscar {{item}}",
    "searchModal-AllCurrentEditions": "Todas las ediciones actuales",
    "searchModal-AllOfflineEditions": "Todas las ediciones sin conexión",
    "searchModal-CodesAndStandards": "Códigos y normas",
    "searchModal-FilterByOfflinePublications": "Filtrar por publicaciones sin conexión",
    "searchModal-FilterByPublication": "Filtrar por publicación",
    "searchModal-MenuLabel": "Menú de búsqueda",
    "searchModal-OfflineWarning": "La funcionalidad de búsqueda podría estar limitada en el modo sin conexión. Conéctate al wifi o una señal de datos para todas las funciones de búsqueda.",
    "searchPlaceholder": "Búsqueda por palabra clave, sección, artículo, capítulo",
    "searchby-nameOrEmail": "Buscar por nombre o correo electrónico",
    "second_plural": "Segundos",
    "second": "Segundo",
    "secondary": "Secundario",
    "secondWithCount_plural": "{{count}} segundos",
    "secondWithCount": "{{count}} segundo",
    "section_plural": "Secciones",
    "section": "Sección",
    "sectionLinkCardList-error-loadingChildSections": "Hubo un problema al leer las secciones secundarias",
    "sectionShared": "Sección compartida.",
    "sections-include-subsection-loading-error": "Error al cargar la vista previa de la sección.",
    "sections-include-subsections-not-found": "No se encontró ninguna sección o publicación.",
    "select": "Seleccionar",
    "selectAnOption": "Seleccione una opción",
    "selected": "Seleccionado",
    "selectFavoritesPage-banner": "¿No ves la publicación que necesitas? Avanza hacia tu panel para ver la lista completa de publicaciones.",
    "selectFavoritesPage-description": "Encontramos algunas publicaciones basadas en tus temas de interés. Marca como favorito los que más usas y aparecerán en tu panel personalizado. Puedes agregar y eliminar favoritos en cualquier momento desde tu panel.",
    "selectFavoritesPage-description-featuredPublications": "Estos son algunos de nuestros títulos más populares. Marca como favorito los que más usas y aparecerán en tu panel personalizado. Puedes agregar y eliminar favoritos en cualquier momento desde tu panel.",
    "selectFavoritesPage-header": "Seleccionar favoritos (opcional)",
    "selectFavoritesPage-nextButton": "Continuar con el panel",
    "selectTopicsPage-dashboardLink": "Ir al panel",
    "selectTopicsPage-description": "Comparte un poco de información con nosotros y adaptaremos el contenido que compartimos contigo. Si has elegido previamente temas de interés a través de tu perfil NFPA, los hemos preseleccionado para ti a continuación.",
    "selectTopicsPage-errors-topicValidation": "Debes elegir al menos un tema",
    "selectTopicsPage-errors-updateTeam": "Hubo un problema al actualizar el nombre del grupo.",
    "selectTopicsPage-errors-updateTopics": "Hubo un problema al actualizar temas",
    "selectTopicsPage-heading": "Elige tus temas de interés",
    "selectTopicsPage-inputs-teamName-placeholder": "Ingresa un nombre para tu grupo...",
    "selectTopicsPage-inputs-teamName": "Nombre del grupo",
    "selectTopicsPage-loader-accessible-text": "Guardando temas",
    "selectTopicsPage-meta-title": "{{appNameTm}}: temas de interés",
    "selectTopicsPage-teamNameHeading": "Elige un nombre de grupo",
    "selectTopicsPage-teamTitle": "Página de incorporación",
    "selectTopicsPage-title": "Incorporación de la cuenta de {{appNameTm}}",
    "sendEmail": "Enviar correo electrónico",
    "share": "Compartir",
    "shareTo": "Compartir a",
    "shareWith": "Compartir con",
    "sharing": "Compartiendo",
    "show": "Mostrar",
    "showLess": "Mostrar menos",
    "showMore": "Mostrar más",
    "signedInAs": "Registrado como",
    "signIn": "Iniciar sesión",
    "signingIn": "Iniciando sesión",
    "signInOrCreateAccount": "Inicia sesión o crea una cuenta",
    "signOut": "Cerrar sesión",
    "signUp": "Registro",
    "signUpCTAMessage": "Regístrate para una prueba gratuita o inicia sesión en <1> NFPA LiNK® </1> para continuar viendo el código.",
    "sitnav-about-buttonText": "Acerca de",
    "sitnav-about-modalHeading": "Acerca de {{sitnavTitle}}",
    "sitnav-about": "{{sitnavTitle}} es una herramienta de navegación paso a paso que presentará resultados según la ocupación, el sistema, el espacio o el tipo de grupo.",
    "sitnav-categories-additionalHelpText": "Continúa agregando filtros de categorías adicionales para filtrar soluciones.",
    "sitnav-categories-helpText": "Para ubicar el contenido aplicable a una situación específica, elige entre las categorías siguientes para comenzar a filtrar por soluciones.",
    "sitnav-categorySelectModal-filter-label": "Mostrando <1>{{filteredCategoriesSize}} de {{allCategoriesSize}}</1> {{labelsPlural}} filtrado por <4>{{selectedOptionsLabel}}</4>",
    "sitnav-categorySelectModal-helptext": "Elige <1>uno</1> de los filtros a continuación que mejor describa tu situación",
    "sitnav-results-message_plural": "<0>{{total}}</0> resultados filtrados por <2>{{categoryFilters}}</2>",
    "sitnav-results-message": "<0>{{total}}</0> resultado filtrado por <2>{{categoryFilters}}</2>",
    "sitnav-results-placeholder": "Empieza a aplicar filtros para generar resultados.",
    "situation_plural": "Situaciones",
    "situation": "Situación",
    "situationPage-additionalInfo": "Información adicional",
    "situationPage-covers": "Esta situación cubre",
    "skipToMainContent": "Saltar al contenido principal",
    "solution_plural": "Soluciones",
    "solution": "Solución",
    "sort": "Ordenar",
    "sortBy": "$t(sort) por",
    "sortOptionNewest": "Fecha en que se agregó (la más nueva primero)",
    "sortOptionOldest": "Fecha en que se agregó (la más antigua primero)",
    "subject": "Asunto",
    "submissionConfirmation": "Confirmación de envío",
    "submit": "Enviar",
    "subscription": "Suscripción",
    "subscriptionCard-changePlan": "Cambio de plan",
    "subscriptionCard-enterprise-billingText": "Facturado anualmente",
    "subscriptionCard-enterprise-buttonText": "Solicitud de cotización",
    "subscriptionCard-enterprise-pricingVaries": "El precio varía",
    "subscriptionCard-individual-billingText": "Facturado mensualmente con renovación automática",
    "subscriptionCard-individual-buttonText": "Seleccionar plan",
    "subscriptionCard-mustSelectBillingCycle": "Debes seleccionar un ciclo de facturación para continuar",
    "subscriptionCard-mustSelectTeamSize": "Debes seleccionar el tamaño del grupo para continuar",
    "subscriptionCard-selectTeamSize": "Selecciona el tamaño del grupo",
    "subscriptionCard-selectedPlan": "Plan seleccionado",
    "subscriptionCard-smallTeam-billingText": "Facturado anualmente, precio inicial",
    "subscriptionCard-team-billingText": "Facturado anualmente con renovación automática",
    "subscriptionCard-team-buttonText": "Seleccionar plan",
    "subscriptionCard-teamSize": "Tamaño del grupo",
    "subscriptionDetails": "Detalles de la suscripción",
    "subscriptionDetailsHelp": "Para actualizar o modificar tu plan de suscripción, envíanos un correo electrónico a <2>{{email}}</2>, o llámanos al <5>{{phone}}</5>; si llamas desde fuera de EE. UU. <8>{{internationalPhone}}</8>. Nuestro equipo atención al cliente está disponible para ayudarte {{hoo}}.",
    "subscriptionNotice": "Tu suscripción se {{subscriptionExpirationText}} el <4>{{date}}</4>.",
    "subscriptionType-Enterprise": "Empresarial",
    "subscriptionType-Free Trial": "Prueba gratis",
    "subscriptionType-Individual": "Individual",
    "subscriptionType-Team": "Grupo",
    "successfullyRemovedFromTeam": "{{user}} se eliminó correctamente de este grupo.",
    "successfullySavedToMyLiNK": "Guardado correctamente en MyLiNK.",
    "support-accountSettings": "¿Cómo modifico mi información personal o la configuración de mi cuenta?",
    "support-billing_answer": "<0>Para actualizar tu información de pago para renovaciones automáticas, envíanos un correo electrónico a <1></1>, o llámanos al <3></3>; si llamas desde fuera de EE. UU. o Canadá, marca <5></5>. Nuestro equipo de atención al cliente está disponible para ayudarte {{supportHours}}.</0>",
    "support-billing": "¿Cómo actualizo mi información de pago?",
    "support-browserRequirements": "¿{{appName}} tiene requisitos de navegador específicos?",
    "support-cancelSubscription_answer": '<0>Los pasos de cancelación pueden diferir en función de tu nivel de suscripción. Ten en cuenta las siguientes condiciones de cancelación antes de proceder con esta </0><1><0>Si optas por cancelar hoy, la cancelación de tu suscripción tardará entre 3 y 5 días laborables en surtir efecto.</0><1>Si cancelas durante un periodo de prueba, podrías perder el acceso al contenido inmediatamente.</1><2>Si cancelas una suscripción mensual, puedes seguir usando la suscripción hasta la siguiente fecha de facturación.</2><3>Si has pagado por adelantado una suscripción anual, no podemos reembolsarte la parte no utilizada de tu suscripción.</3><4><strong>Cancelación de suscripciones individuales:</strong><1><0><0>Inicia sesión</0> en tu cuenta desde cualquier navegador.</0><1>Dirígete a la configuración de la cuenta y selecciona "DETALLES DE LA SUSCRIPCIÓN".</1><2>Haz clic en el enlace "CANCELAR MI SUSCRIPCIÓN".</2><3>Rellena el formulario y envíalo.</3><4>Si tienes algún problema, envía un correo electrónico a <1></1>.</4></1></4><5><strong>Cancelación de las suscripciones del grupo:</strong><1><0>Como el administrador de la cuenta del grupo, <2>inicia sesión</2> en tu cuenta desde cualquier navegador.</0><1>Dirígete a Configuración de la cuenta y selecciona "DETALLES DE SUSCRIPCIÓN".</1><2>Haz clic en el enlace "CANCELAR SUSCRIPCIÓN".</2><3>Rellena el formulario y envíalo.</3><4>Si tienes algún problema, envía un correo electrónico a <1></1>.</4></1></5><6><strong>Cancelación de suscripciones de empresa:</strong><1><0>Comunícate con tu administrador de cuenta.</0><1>Si tienes algún problema, envía un correo electrónico a <1></1>.</1></1></6></1>',
    "support-cancelSubscription": "¿Cómo cancelo mi suscripción?",
    "support-changeIndicators_answer": "<0>Los códigos y normas de la NFPA incluyen símbolos de revisión que identifican cambios con respecto a la edición anterior. Los siguientes indicadores están diseñados para servir de guía. [Nota: es posible que el reordenamiento de las secciones no se capture en los símbolos de revisión].</0><1><0>Las revisiones del texto están <2><strong>sombreadas</strong></2>.</0><1><0></0> antes de un número de sección indica que se eliminaron las palabras dentro de esa sección.</1><2><0></0> a la izquierda de una tabla o figura, el número indica una revisión de una tabla o figura existente.</2><3>Cuando un capítulo fue revisado en profundidad, todo el capítulo está marcado con el símbolo <2></2>.</3><4>Cuando se eliminaron una o más secciones, se coloca un <1></1> entre las secciones restantes.</4><5>Los capítulos, anexos, secciones, figuras y tablas que son nuevos se indican con un <2><0></0>.</2></5></1>",
    "support-changeIndicators": "¿Existe alguna forma de identificar fácilmente los cambios de la edición anterior?",
    "support-forgottenPassword_answer": "<0>Si has olvidado su contraseña, puedes <1>restablecerla aquí</1>. Ingresa tu dirección de correo electrónico y recibirás un correo electrónico que te proporcionará un enlace para restablecer tu contraseña. Si no recibes un correo electrónico dentro de 24 horas, envíanos un correo electrónico a <3></3>, o llámanos al <5></5>; si llamas desde fuera de EE. UU.<7></7>. Nuestro equipo de atención al cliente está disponible para ayudarte {{supportHours}}.</0>",
    "support-forgottenPassword": "¿Qué pasa si olvidé mi contraseña de NFPA?",
    "support-freeTrial": "¿Puedo probar {{appName}} antes de comprarlo?",
    "support-hours": "De lunes a viernes, de 8:30 a.m. a 5:00 p.m., hora del este",
    "support-howDoIAccess": "¿Cómo accedo a {{appName}}?",
    "support-howDoISignIn_answer": "<0><0><0><strong>Si ya tienes una cuenta de NFPA existente</strong>, <3>inicia sesión</3> con tus credenciales de inicio de sesión de NFPA y elige el plan de {{appName}} y las opciones de facturación que prefieras. Una vez que hayas seleccionado el plan de tu elección, serás llevado al catálogo en línea de la NFPA para completar tu compra. ¿Olvidaste tu contraseña de NFPA? <10>Restablécela aquí</10>.</0></0><1><0><strong>Si necesitas crear una cuenta de NFPA</strong>, selecciona el plan de {{appName}} y las opciones de facturación de tu elección <6>aquí</6> y se te pedirá que registres una nueva cuenta. Una vez que hayas creado una cuenta, serás llevado al catálogo en línea de NFPA para completar tu compra.</0></1></0>",
    "support-howDoISignIn": "¿Cómo inicio sesión en {{appName}}?",
    "support-howToHelp": "Haznos saber cómo podemos ayudarte.",
    "support-licenseAgreement_answer": "<0>Para usar {{appName}}, debes leer y aceptar el <4>Acuerdo de licencia de usuario final</4>. Lee atentamente todo el acuerdo. Si no estás de acuerdo con los términos, no podrás acceder al sitio.</0>",
    "support-licenseAgreement": "¿Dónde puedo revisar el contrato de licencia de {{appName}}?",
    "support-plans_answer": "<0>Entendemos que no todos los profesionales y grupos necesitan la misma solución. {{appName}} ofrece una <3>variedad de planes y opciones de facturación</3>. Ofrecemos opciones de facturación mensual y anual, así como planes individuales, de grupo y empresariales. Aprovecha la opción de renovación automática para facilitar el pago y ahorrar costos.</0>",
    "support-plans": "¿Qué tipos de planes y opciones de facturación ofrecen?",
    "support-refundPolicy_answer": "<0>Los productos digitales no son retornables ni reembolsables, pero la NFPA se enorgullece de la calidad de los productos y servicios que ofrecemos. Si no estás completamente satisfecho con tu orden, nos gustaría hablar contigo. <2>Comunícate con nosotros</2> para enviarnos tus comentarios.</0>",
    "support-refundPolicy": "¿Cuál es la política de reembolso de la NFPA?",
    "support-renewSubscription": "¿Cómo renuevo mi suscripción?",
    "support-updateSubscription_answer": "<0>Para actualizar o modificar tu plan de suscripción, envíanos un correo electrónico a <1></1>, o llámanos al <3></3>; si llamas desde fuera de EE. UU. o Canadá, marca <5></5>. Nuestro equipo de atención al cliente está disponible para ayudarte {{supportHours}}.</0>",
    "support-updateSubscription": "¿Cómo modifico o actualizo mi suscripción?",
    "support-whatIsAppName_answer": "<0>{{appName}} es una aplicación basada en una suscripción que ofrece códigos y normas de la NFPA, y contenido de apoyo. Esta nueva herramienta permite a los usuarios buscar y navegar fácilmente en una variedad de diferentes tipos de contenido basados en escenarios de la vida real. {appName} te permite seleccionar y compartir información relevante para tu trabajo y educarte a ti mismo y a tu grupo para estar mejor preparados para su trabajo diario. Los usuarios tendrán acceso a:</0><1><0>Códigos y estándares de la NFPA</0><0>Comentarios de expertos y ayudas visuales</0><0>Navegación situacional</0><0>Colecciones personalizadas</0><0>Anotaciones personales</0><0>Marcadores</0><0>Compartir</0><0>Colaboración en grupo</0></1>",
    "support-whatIsAppName": "¿Qué es {{appName}}?",
    "support-whatsInApp": "¿A qué información y contenido tendré acceso en {{appName}}?",
    "support": "Soporte",
    "supportPage-furtherAssistance": "<0><0><strong>¿Necesitas más ayuda?</strong></0><1>Nuestro equipo de atención al cliente está disponible para ayudarte {{supportHours}}. Si estás fuera de nuestro horario comercial, envíanos un correo electrónico a <3></3> y te responderemos el siguiente día útil.</1><2>Haznos saber cómo podemos ayudarte.</2><3><0><strong>Teléfono</strong></0><1><0></0> (EE. UU. y Canadá)</1><2><0>{{altPhone}}</0> (EE. UU. y Canadá)</2><3><0></0> (Internacional)</3><4><0>{{mxPhone}}</0> (México)</4><5>Envía una pregunta</5></3></0>",
    "supportPage-messageConfirmation": "Gracias por enviar tu consulta a la NFPA. Tu pregunta es importante para nosotros y será respondida dentro de un (1) día útil.",
    "supportPage-metaDescription": "Si necesitas asistencia con {{appName}}, nuestro equipo de atención al cliente está disponible para ayudartede lunes a viernes, {{supportHours}}. Comunícate con atención al cliente: {{supportPhone}}. Si estás fuera de nuestro horario de atención, envíanos un correo electrónico a {{supportEmail}} y te responderemos el siguiente día útil.",
    "supportPage-metaKeywords": "ayuda, contáctenos, soporte, preguntas frecuentes",
    "supportPage-metaTitle": "Soporte de {{appName}}: preguntas y respuestas",
    "supportPage-title": "Soporte de {{appName}}",
    "switchProfile-header": "Seleccione un perfil para usar",
    "switchProfile-loadingMessage": "Cambiando perfiles...",
    "switchProfile": "Cambiar perfil",
    "table": "Tabla",
    "tableOfContents": "Índice",
    "tables": "Tablas",
    "teal": "Verde azulado",
    "team-member_plural": "Miembros del grupo",
    "team-member": "Miembro del grupo",
    "team-name": "Nombre del grupo",
    "team-size": "Tamaño del grupo",
    "team": "grupo",
    "teamInvitePlaceholder": "Ingresa la dirección de correo electrónico para enviar la invitación al grupo…",
    "teamManagement": "Administracion del grupo",
    "teamName": "Nombre del grupo",
    "teamNameBlank": "El nombre del grupo no puede estar en blanco",
    "teamNameMaxCharacters": "El nombre del grupo tiene un máximo de {{length}} caracteres.",
    "techicalProblems": "Tuve problemas técnicos",
    "termsAndConditions-acceptButtonText": "Acepto el acuerdo de licencia de usuario",
    "termsAndConditions-contactCustomerServiceText": "Si tienes preguntas, comunícate con Servicio al cliente al {{usPhoneNumber}}; si llamas desde fuera de EE. UU. {{itnlPhoneNumber}}. Nuestro grupo de atención al cliente está disponible para ayudarte {{businessHours}}.",
    "termsAndConditions-documentTitle": "Acuerdo de licencia de usuario",
    "termsAndConditions-IAgreeText": "Acepto el acuerdo de licencia de usuario.",
    "termsAndConditions-meta-title": "Acuerdo de licencia de usuario final",
    "termsAndConditions-mustAgreeToContinue": "Debes aceptar el acuerdo de licencia de usuario para continuar.",
    "termsAndConditions-pageTitle": "Incorporación de la cuenta",
    "termsOfUse": "Términos de uso",
    "thankYouForBeingASubscriber": "Gracias por suscribirte a NFPA LiNK®. Tu suscripción se cancelará en los próximos 3-5 días hábiles.",
    "to": "para",
    "tooExpensive": "Es demasiado caro",
    "topic_plural": "temas",
    "topic-of-interest_plural": "Temas de interés",
    "topic-of-interest": "Tema de interés",
    "topic": "tema",
    "topicsOfInterest-description": "Esto nos ayudará a brindarte el contenido que más te interesa.",
    "unfavorite": "Quitar como favorito",
    "unHappy": "No estoy contento con la calidad del contenido",
    "unpublish": "No publicar",
    "unpublished": "No publicado",
    "updating": "actualizando",
    "upToItem": "hasta {{item}}",
    "useNfpaDirect-link-label": "Prueba esta herramienta de navegación paso a paso para encontrar tu solución más rápido.",
    "useNfpaDirect": "Usa NFPA DiRECT®",
    "user_plural": "usuarios",
    "user": "usuario",
    "userbookmarks-deleteLabel": "¿Estás seguro de que deseas eliminar este marcador?",
    "userbookmarks-deleteMessage": "Eliminar este marcador lo eliminará de Mis marcadores y de todas las colecciones.",
    "userbookmarks-removeFromCollectionConfirmation": "¿Eliminar de la colección?",
    "userbookmarks-removeFromCollectionLabel": "Eliminar de la colección",
    "userbookmarks-removeFromCollectionMessage": "Este marcador aún se guardará en Mis marcadores.",
    "userCollectionPage-deleteConformationModal-confirmButtonText": "Sí, eliminar",
    "userCollectionPage-deleteConformationModal-text": "Los marcadores de esta colección se seguirán guardando en Mis marcadores.",
    "userCollectionPage-deleteConformationModal-title": "¿Estás seguro de que deseas eliminar esta colección?",
    "userCollectionPage-error-loadingCollection": "Hubo un problema al cargar esta colección.",
    "userCollectionPage-teamCollection": "Colección del grupo",
    "userCollectionsTabFilters-searchForm-placeholder": "Buscar por título de colección",
    "usersettings-enhancedContentHidden": "El contenido mejorado está oculto.",
    "usersettings-expandByDefault": "Ampliar de forma predeterminada",
    "usersettings-noPreferencesFound": "No se encontraron preferencias",
    "usersettings-showEnhancedContent": "Mostrar contenido mejorado",
    "usersettings-viewingPreferences": "Preferencias de visualización",
    "validation-atLeastOne": "Se requiere al menos un {{type}}.",
    "validation-multipleInvalid": "Uno o más {{type}} no son válidos",
    "validation-notAValid": "Esto no es un {{type}} válido",
    "view": "Ver",
    "viewInPublication": "Ver en publicación",
    "browse-all-available-publications": "Explore todas las publicaciones disponibles",
    "viewItem": "Ver {{item}}",
    "warnings-thisActionCannotBeUndone": "Esta acción no se puede deshacer.",
    "yellow": "Amarillo",
    "yesAction": "Sí, {{action}}",
    "you": "Tú",
    "yourItem": "Su {{item}}",
};

const SpanishSpain: Culture<CultureResources> = LocalizationUtils.cultureFactory<CultureResources>(BaseSpanishSpain, {
    resources: {
        ...ProfessionallyTranslatedSpanishSpain,
        "accessCodeIsInvalid": "El código de acceso no es válido",
        "accessCodeIsRequired": "Se requiere código de acceso.",
        "accessLink": "Acceso al enlace {{appNameTm}} ahora",
        "accountDashboard-manageAccount": "Administre la información de su cuenta NFPA, cambie su contraseña o actualice las opciones de pago.",
        "accountDashboard-workGroup": "Gestionar Trabajo Grupos",
        "add-change-summary": "Agregar/Editar resumen de cambio",
        "addBookmarkButton": "añadir marcador botón",
        "addTo": "Añadir a {{container}}",
        "addUserCollectionModalTeamMessage": "Esta colección será visible para <1>{{teamSize}}</1> gente de tu equipo.",
        "addUserCollectionModalWorkGroupMessage": "Esta colección será visible para los grupos de trabajo que seleccione a continuación.",
        "addWorkGroupsShare": "Añadir grupos de trabajo para compartir...",
        "admin": "Administración",
        "all": "todas",
        "allOfItem": "Todo {{item}}",
        "annual": "Anual",
        "annually": "Anualmente",
        "auto-renew": "Auto renovación",
        "available": "Disponible",
        "backToDashboard": "Volver al tablero",
        "billing": "Facturación",
        "browsePublications": "Explorar publicaciones",
        "bypassHermes-feature-unavailable-tooltip": "Esta acción no está disponible actualmente.Por favor, inténtelo de nuevo más tarde.",
        "changeSummaryAction": "Botón de cambio de resumen {{action}}",
        "changeSummaryHeader": "RESUMEN DE CAMBIOS",
        "checkBackSoon": "¡Vuelve a consultar pronto!",
        "clearAll": "Limpiar todo",
        "clearAllReferences": "Borrar todas las referencias",
        "clearAllReferences_question": "¿Limpiar todo?",
        "clearAllReferences-confirmationMessage": "Borrar todas las referencias eliminará todas sus referencias de su panel de referencia.",
        "collectionVisibility": "Visibilidad de la recolección",
        "completeRegistration": "Registro completo",
        "contactPerson": "Persona de contacto",
        "contactReasons-renewEnterpriseSubscription": "Renovar suscripción empresarial",
        "contactSalesToRenew": "Póngase en contacto con las ventas para renovar",
        "content": "Contenido",
        "continueToPayment": "Continuar con el pago",
        "continueToPurchase": "Continuar comprando",
        "copy": "Copiar",
        "day": "Día",
        "deletedSectionBookmark": "Esta sección ha sido eliminada desde que la marcó.",
        "deviceStorageUsed": "{{cantidad}} del almacenamiento de dispositivos utilizado",
        "diffPanelHeaderChanged": "Contenido modificado",
        "diffPanelHeaderDeleted": "Contenido eliminado",
        "diffPanelHeaderNew": "Nuevo contenido",
        "diffPanelSubHeader": "Texto de la edición anterior:",
        "diffPanelSubHeaderNew": "Este contenido no estaba en la edición anterior.",
        "diffPanelSubHeaderPriorEdition": "Consulte la edición anterior de estos cambios.",
        "download_plural": "Descargas",
        "ecommerceRedirectionModal-defaultDescription": "Está siendo redirigido a nfpa.org para finalizar su compra.",
        "ecommerceRedirectionModal-shortenedDescription": "Usted está siendo redirigido a nfpa.org",
        "effectiveBefore": "Efectivo antes",
        "enterpriseConfirmationModal-heading": "Su solicitud ha sido enviada.",
        "emailOrganizationSupportSpecialist": "Especialista en soporte de organización de correo electrónico",
        "enhancedcontent_plural": "Contenido mejorado",
        "enterYourAccessCode": "Ingrese su código de acceso para comenzar.",
        "error-loading-bookmarks": "Hubo un problema cargando marcadores de usuarios.",
        "error-loading-organization": "Hubo un problema al cargar la organización.",
        "eligibleForTrialBody": "Comentarios de expertos, ayudas visuales, búsqueda robusta, marcadores y anotaciones, en línea o fuera de línea. Regístrese para una prueba gratuita de 2 semanas para ver cómo {{AppNameWithHtml}} puede cambiar su forma de trabajar",
        "exception": "Excepción",
        "expiration-date": "Fecha de caducidad",
        "expired-offline-session-alert-description": "No has iniciado sesión por 21 días.Debe restablecer una conexión a Internet e inicie sesión para acceder al contenido fuera de línea.",
        "expired-offline-session-alert-title": "Su sesión fuera de línea ha caducado",
        "expired-offline-session-warning-description": "No has iniciado sesión por 21 días.Si no restablece una conexión a Internet en las próximas 24 horas, no podrá acceder al contenido fuera de línea.",
        "expired-offline-session-warning-title": "Su sesión fuera de línea expirará en 24 horas.",
        "extend": "Extender",
        "firstResultsPage": "Primera página de resultados",
        "free-access-nav-tooltip-direct-description": "Navegue a situaciones de la vida real construidas por expertos de NFPA. Obtenga información agregada con ayudas visuales y enlaces a secciones de código relevantes.",
        "free-access-nav-tooltip-direct-title": "NFPA DIRECT®",
        "free-access-nav-tooltip-mylink-description": "Acceda rápidamente a los marcadores personalizados, las notas y las colecciones curadas.Colabore y comparta notas con sus compañeros con una suscripción de equipo.",
        "free-access-nav-tooltip-search-description": "Busque en publicaciones y comentarios expertos utilizando palabras clave y opciones de filtrado.",
        "free-access-nav-tooltip-search-title": "Buscar enlace NFPA <PUP> ® </PUP>",
        "freeAccessPrintTitle": "Acceso libre - NFPA {{code}}: {{title}}",
        "freeTrialTerms": "No se requiere información de pago para inscribirse en una prueba gratuita. El acceso caducará después de 14 días a menos que elija suscribirse a un plan pago. Al registrarse en NFPA LiNK® gratis prueba, acepta que lo contactemos sobre las características del producto y las opciones de compra.",
        "freeTrialUpgradeMessage": "Actualice su plan para continuar usando el contenido a pedido.",
        "freeTrialNewUserLoginFormSubmitButtonText": "Iniciar sesión y registrarse",
        "get-users-error": "Hubo un error al obtener usuarios",
        "goToMyAccount": "ir a mi cuenta",
        "header": "el Encabezado",
        "hide-earlier-editions": "Ocultar ediciones anteriores",
        "freezeAccountCreationAndChangesMessage": "Las compras de registro y suscripción de la cuenta no están disponibles temporalmente, pero si tiene una suscripción existente de NFPA LiNK®, puede acceder a su cuenta a continuación.",
        "freezeSubscriptionsMessageForTooltip": "Las compras de registro y suscripción de la cuenta no están disponibles temporalmente.",
        "informationalNote": "Nota informativa",
        "inputHintText": "Insinuación: {{text}}",
        "group": "Grupo",
        "haveSubscription": "Ya tienes una suscripción.",
        "lastResultsPage": "Última página de resultados",
        "hermes-off-banner-default": "Alguna funcionalidad podría ser limitada actualmente.Por favor, inténtelo de nuevo más tarde.",
        "hiddenTiaContent": "Este {{item}} puede tener contenido eliminado oculto.",
        "hiddenTiaAndEnhancedContent": "Este {{item}} puede tener contenido oculto, eliminado y mejorado.",
        "hiddenEnhancedContent": "Este {{item}} puede tener contenido mejorado oculto.",
        "login-offline-warning": "Conectarse a una señal de datos o WiFi para iniciar sesión",
        "manageWorkGroups-heading": "Administrar Trabajo Grupos",
        "manageWorkGroups-newWorkGroupModal-button": "Nuevo Trabajo Grupos",
        "manageWorkGroups-searchForm-accessibleText": "Buscar Trabajo Grupos",
        "manageWorkGroups-searchForm-placeholder": "Buscar por Trabajo Grupos",
        "landingPageContent": "<0> NFPA LiNK®, una plataforma digital innovadora que proporciona acceso instantáneo, a través de su dispositivo favorito, a códigos y estándares NFPA®, además de comentarios expertos exclusivos, ayudas visuales y más. </0>",
        "landingPageTitle": "<0> NFPA LiNK® pone nuestra base de conocimiento confiable a su alcance. </0>",
        "monthly": "Mensual",
        "learnEvenMore": "Aprender aún más",
        "learnMore": "Aprende más",
        "logIn": "Acceso",
        "logInToLink": "Iniciar sesión en enlace",
        "logOut": "Cerrar sesión",
        "my-current-plan": "Mi plan actual",
        "newUserLoginFormSubmitButtonText": "Iniciar sesión y comprar",
        "job_plural": "Trabajos",
        "justYou": "Solo tu",
        "newCollection": "Se ha creado una nueva colección",
        "newWorkgroupPlaceholder": "Escriba para nombrar grupo de trabajo",
        "moreQuestions": "<0>¿Tienes más preguntas? Visite la <1><0>página de soporte {{appName}}</0></1> para ver las preguntas frecuentes más detalladas.</0>",
        "my-collections": "Mis colecciones",
        "notEligibleForTrialBody": "Comentarios de expertos, ayudas visuales, búsqueda sólida, marcadores y anotaciones, en línea o fuera de línea. Suscripciones disponibles por tan solo $8.33/mes.",
        "not-found-page-button-text": "Ir al panel de control",
        "not-found-page-description": "Lo siento, no pudimos encontrar la página que estabas buscando.",
        "no-invites-available": "No hay licencias disponibles.",
        "not-found-page-title": "Extraviado",
        "now": "ahora",
        "off": "Apagada",
        "offline-banner-default": "Es posible que algunas funciones estén limitadas mientras están en modo sin conexión. Debe establecer la conectividad para usar todas las características de {{appName}}.",
        "not-found-resource": "El {{recurso}} solicitado no fue encontrado",
        "offline-books-empty-banner-title": "Aún no tiene nada descargado en este dispositivo",
        "notAddedWorkgroups": "Todavía no se ha agregado a ningún grupo de trabajo.",
        "offline-dashboard-banner-message": "Estás desconectado. Sólo se muestran las publicaciones descargadas.",
        "offline-dashboard-empty-banner-subtitle": "Conéctese a una red Wi-Fi para almacenar publicaciones en su dispositivo para verlas sin conexión. Puede descargar publicaciones seleccionando la opción 'configuración sin conexión' en el menú que se muestra en cada una de las portadas de los libros.",
        "offline-dashboard-recent-bookmarks-disabled-description": "Debe establecer la conectividad para utilizar todas las características de {{appName}}.",
        "offline-dashboard-recent-bookmarks-disabled-title": "Los marcadores recientes están deshabilitados mientras están fuera de línea.",
        "offline-data-informational-message": "Este contenido se almacenará para el acceso sin conexión con la cuenta actual y el dispositivo / navegador. Iniciar sesión con una cuenta o dispositivo / navegador diferente requerirá volver a descargar este contenido, incluidos los marcadores y el contenido mejorado.",
        "offline-download-browser-warning": "No cierre el navegador hasta que la descarga haya completado.",
        "offline-fallback-description": "Debe conectarse a una red Wi-Fi para usar esta función.",
        "offline-fallback-title": "Actualmente estás fuera de línea",
        "offline-loading-text": "Preparando su experiencia de búsqueda fuera de línea",
        "offline-removeOfflineBookError": "Se produjo un error al intentar eliminar un libro fuera de línea.",
        "offline-settings-bookmarks-label": "Incluir marcadores y notas",
        "offline-settings-bookmarks-tooltip": "Guarde todos los marcadores y notas de esta publicación para obtener acceso sin conexión.",
        "offline-settings-enhanced-content-label": "Incluir contenido mejorado",
        "offline-settings-enhanced-content-tooltip": "Además del contenido del código, guarde cualquier comentario de expertos incluido en esta publicación para obtener acceso sin conexión.",
        "offline-settings-header": "Configuración fuera de línea",
        "offline-settings-store-description": "Caché esto en su dispositivo en segundo plano mientras continúa usando el enlace normalmente",
        "offline-settings-store-label": "Tienda para uso fuera de línea",
        "offline-storage-limit": "No hay suficiente espacio para continuar descargando {{publication}}. Retire otra publicación fuera de línea, o espacio libre en su dispositivo.",
        "offline-syncErrorMessage": "Se produjo un error al intentar sincronizar un libro sin conexión.",
        "offline-tab-empty-banner-subtitle": "Conéctese a una red Wi-Fi para almacenar publicaciones a su dispositivo para la visualización fuera de línea.",
        "offline-unsupported-browser": "Hemos detectado que su navegador no admite las características necesarias para el acceso sin conexión.Si es posible, actualícelo a un navegador moderno.",
        "offlineBookDownloadStatus-downloaded": "Descargado al dispositivo",
        "offlineBookDownloadStatus-updateAvailable": "Actualización disponible",
        "on": "En",
        "oopsErrorOccurred": "¡Vaya! ha ocurrido un error!",
        "option": "opción",
        "option_plural": "opciones",
        "okay": "Bueno",
        "or": "o",
        "on-demand-already-on-team": "Ya eres parte de este equipo.",
        "on-demand-already-on-team-1": "Parece que ya tienes un",
        "on-demand-already-on-team-2": "cuenta asociada con este equipo.",
        "on-demand-contact-admin": "¿No recibió un correo electrónico?Póngase en contacto con el administrador de su equipo.",
        "on-demand-contact-admin-1": "Si cree que está recibiendo este mensaje por error, comuníquese con el administrador de su equipo.",
        "on-demand-enter-email": "Ingrese su dirección de correo electrónico para comenzar.",
        "on-demand-login": "Iniciar sesión en su cuenta.",
        "on-demand-no-invites-1": "Lo sentimos, pero parece que su equipo ha alcanzado su capacidad máxima. Póngase en contacto con el administrador de su equipo para obtener más información.",
        "on-demand-no-invites-contact-admin": "Si cree que ha recibido este mensaje por error, comuníquese con el administrador de su equipo.",
        "on-demand-receive-email-1": "Recibirá un correo electrónico con más instrucciones para configurar su",
        "on-demand-receive-email-2": "cuenta.",
        "on-demand-sign-in": "Parece que ya ha enviado su dirección de correo electrónico para inscribirse en",
        "on-demand-sign-in-1": ". Verifique su bandeja de entrada para continuar activando su cuenta.",
        "on-demand-sign-up-1": "Su solicitud fue enviada con éxito. Por favor revise su bandeja de entrada para activar y configurar su",
        "on-demand-team-header-1": "Matricularse en",
        "on-demand-team-header-2": "con tu organización",
        "on-demand-thank-you": "Gracias.",
        "on-demand-unrecognized-email": "Lo sentimos, pero parece que su dirección de correo electrónico no coincide con el equipo al que está tratando de unirse.Querer",
        "on-demand-unrecognized-email-1": "Dirección de correo electrónico no reconocida.",
        "part": "Parte",
        "part_plural": "Partes",
        "price": "Precio",
        "priorText": "Texto Anterior",
        "orgAccountSettingsSupportText": "Si usted forma parte de una cuenta de organización y tiene algún problema, comuníquese con nuestro especialista a continuación.",
        "orgLoginHeading": "Acceso a NFPA Link® requiere credenciales de su organización",
        "orgLoginHelpText": "Para los problemas que inician sesión, <2>comuníquese con su organización para obtener ayuda</2>.",
        "orgLoginSubheading": "Inicie sesión a través de su organización y será redirigido a nuestro sitio web.",
        "organization": "Organización",
        "organizationAccountSupport": "Apoyo de la cuenta de la organización",
        "publicationOptionsMenu": "Menú de opciones de publicación",
        "publicationPreview-callToAction-public": "Regístrese para obtener acceso completo a todos nuestros códigos y estándares.",
        "publicationPreview-callToAction-signedIn": "Tienes acceso al contenido completo de la publicación en su cuenta de enlace.",
        "plansPage-browseAllPublications": "<0> Explore todas las publicaciones disponibles </0> en {{AppName}}.",
        "plansPage-exploreAllPublications": "Explore una lista de <1>publicaciones disponibles</1> para averiguar qué códigos y estándares se incluyen con una suscripción a {{AppName}}.",
        "plansPage-subscriptionExpired-content": "{{appName}} es una plataforma digital innovadora que proporciona acceso instantáneo a los códigos y estándares NFPA, además de comentarios expertos exclusivos, ayudas visuales y más.Explore una lista de <2>publicaciones disponibles</2> para averiguar qué códigos y estándares se incluyen con una suscripción a {{appName}}.",
        "playVideo": "Reproduce el video",
        "postGoLiveBanner": "¡NFPA ha actualizado su inicio único, lo que significa que sus pantallas de inicio de sesión para {{appName}} se ve un poco diferente!",
        "publicationPreview-previewMode": "Usted está en modo de vista previa",
        "publication-admin-sectionTitleHasLabel-checkbox": "Los títulos de la sección tienen etiquetas.",
        "providedBy": "Proporcionado  por",
        "publication-batch_start-job-later": "Trabajo de horario",
        "publication-batch_start-job-later-description": "La programación de trabajos sin prisa ahorra recursos del servidor y reduce los gastos.Su trabajo se programará para esta noche y se realizará al comienzo de su día mañana.",
        "publication-batch_start-job-now": "Empezar el trabajo ahora",
        "publication-batch_start-job-now-description": "Sus procesos comenzarán inmediatamente.Recibirá una alerta cuando la cola haya completado.",
        "redirecting": "Redirección",
        "publicationPage": "página de publicación",
        "registerAccountPageMetaTagDescription": "Regístrese en NFPA LiNK® para obtener acceso a los códigos y estándares de la NFPA, comentarios de expertos y ayudas visuales, marcadores y colecciones personalizadas, navegación de códigos basados ​​en situaciones, uso compartido y más.",
        "registerAccountPageMetaTagTitle": "NFPA LiNK ®: Cree una cuenta NFPA",
        "registerAccountPageTitle": "Registro de cuenta NFPA LiNK®",
        "removeFrom": "Eliminar de {{container}}",
        "reachOutToAccountAdministratorsMessage": "Comuníquese con los administrador(es) de su cuenta con preguntas sobre su suscripción:",
        "renew": "renovar",
        "refreshing": "Refrescante...",
        "refreshingInNSeconds": "Refrescante en {{timeInSeconds}} segundos",
        "renewal-date": "Fecha de renovación",
        "scope": "Alcance",
        "searchHintText": "Utilizar cotizaciones “ “ para una coincidencia exacta.",
        "registerHeading": "Registrarse para {{appNameWithHtml}}",
        "registerHeading-freeTrial": "¡Pruebe el {{appNameWithHtml}} durante 14 días gratis!",
        "registerOrLogin-loginHeader": "Inicie sesión con su cuenta NFPA.org existente para registrarse.",
        "registerOrLogin-signinHeader": "Inicie sesión con su cuenta NFPA.org existente para registrarse.",
        "registrationEmail": "Cree una cuenta utilizando el correo electrónico de la invitación:",
        "searchModal-ResultFilters": "Filtros de resultados de búsqueda",
        "searchModal-SearchResults": "Resultados de la búsqueda",
        "searchModal-FindMatchingPublications": "Buscar publicaciones coincidentes",
        "resetItHere": "Reiniciarlo aquí",
        "searchModal-MatchingCodesAndStandards": "Códigos y estándares coincidentes",
        "searchModal-SearchResultsFound": "{{count}} Resultados encontrados para {{key}}",
        "searchUsers": "Buscar para agregar usuarios",
        "section-detail-menu-feature-name": "Marcadores y compartir",
        "select-plan": "Seleccione el plan",
        "selectItem": "Seleccione un artículo}}",
        "serviceWorkerStatus-disabled": "Inactivo.Por favor actualice la página",
        "serviceWorkerStatus-enabled": "Activa",
        "serviceWorkerStatus-unsupported": "Estás utilizando un navegador no compatible",
        "serviceWorkerStatus-waiting": "Actualizar página para activar",
        "setAsRole": "Set as {{role}}",
        "selectAPlanToGetStarted": "Seleccione un plan para comenzar",
        "sharedWith": "Compartido con",
        "selectAPlanShortDescription": "NFPA LiNK ofrece precios para adaptarse a su equipo y su presupuesto.Múltiples opciones de precios y acceso significan que obtiene exactamente lo que necesita.",
        "selectYourBillingCycle": "Seleccione su ciclo de facturación",
        "selectYourTeamSize": "Seleccione el tamaño de su equipo",
        "shared-collections": "Colecciones compartidas",
        "show-earlier-editions": "Mostrar ediciones anteriores",
        "stored-publication": "publicación almacenada",
        "subscription-choice-card-buttontext": "Actualizar ahora",
        "subscription-choice-card-header": "¡Regístrate ahora!",
        "subscription-choice-card-plans-linktext": "Ver todos los planes",
        "subscriptionFeatures-allIndividualPlanFeatures": "Todas las características del plan individual",
        "subscriptionFeatures-bookmarking": "Marcador",
        "spotlightsNewPublicationsBody": "<0> Los suscriptores NFPA LiNK® tienen acceso a más de 1,500 códigos y estándares NFPA.Esto incluye todas las ediciones actuales, títulos seleccionados en español, así como una biblioteca de títulos heredados que se remontan a cinco ediciones.Los suscriptores también tienen acceso temprano a ediciones recientemente lanzadas antes de que el libro impreso esté disponible para su compra. </0>",
        "spotlightsNewPublicationsTitle": "Acceso temprano a publicaciones recientemente publicadas",
        "spotlightsOfflineModeBody": "<0> Ahora puede hacer el trabajo incluso si no tiene acceso a Internet.La función de modo fuera de línea en NFPA LiNK® le permite acceder a su contenido donde sea que esté.Simplemente guarde publicaciones relevantes en su dispositivo para que estén disponibles cuando las necesite, en cualquier momento y en cualquier lugar.Esta nueva característica le permite almacenar sus publicaciones más utilizadas en su dispositivo, incluidos sus marcadores y notas. </0>",
        "spotlightsOfflineModeTitle": "Introducción del modo fuera de línea",
        "subscriptionFeatures-customCollections": "Colecciones personalizadas",
        "subscriptionFeatures-enterpriseCollaboration": "Colaboración empresarial",
        "subscribeLink": "Suscríbete al enlace {{appNameTm}}",
        "subscriptionFeatures-digitalAccess": "Acceso digital en línea y fuera de línea",
        "subscriptionFeatures-enterpriseTeamNotesSharing": "Equipo empresarial Notas y compartir",
        "subscriptionFeatures-expertCommentaryVisualAids": "Comentario experto y ayudas visuales",
        "subscriptionFeatures-personalNotations": "Notaciones personales",
        "subscriptionFeatures-referencePanel": "Panel de referencia",
        "subscriptionFeatures-search": "Buscar",
        "subscriptionFeatures-sharing": "Intercambio",
        "subscriptionFeatures-situationalNavigation": "Navegación situacional",
        "subscriptionFeatures-teamCollaboration": "Colaboración en equipo",
        "subscriptionFeatures-teamNotesSharing": "Notas del equipo y compartir",
        "subscriptionFeatures-userManagement": "Gestión de usuarios",
        "subscribe-now": "Suscríbase ahora",
        "support-browserRequirements_answer": "Para acceder a NFPA LiNK, debes estar conectado a Internet. NFPA LiNK es compatible con los siguientes navegadores web: Chrome 80+, Safari 13+, Edge 18+, Firefox 73+, Internet Explorer 11+. Es posible que algunas funciones no se comporten según lo previsto si se utilizan con otros navegadores.",
        "support-concernedWithSecurity": "¿Qué sucede si a mi empresa se refiere a la seguridad / descarga de contenido?",
        "support-concernedWithSecurity_answer": "Los usuarios no están obligados a instalar nada para usar el modo sin conexión.Desde una perspectiva de seguridad, esta aplicación web progresiva es mucho más segura que otras opciones porque todo el contenido y los datos están contenidos dentro del almacenamiento del navegador y solo se puede acceder a la propia aplicación NFPA LINK ®.Nuestra aplicación no tiene la capacidad de alcanzar el exterior del dominio nfpa.org para nada en el almacenamiento del navegador, que es muy similar a cómo funcionan las cookies del sitio web.",
        "support-foundInAppStore": "¿Puedo instalar una aplicación móvil que se encuentra en mi App Store?",
        "support-foundInAppStore_answer": "No, NFPA LINK ® no es una aplicación nativa, por lo que no está disponible para descargar en las tiendas de aplicaciones móviles.Si desea descargar la aplicación en su escritorio, puede hacerlo usando Chrome o Edge.Vea cómo acceder a mi contenido fuera de línea para obtener más detalles.",
        "support-howDoIAccess_answer": "Puedes acceder a {{appName}} a través de cualquier navegador web en tus dispositivos favoritos. Con conectividad a Internet y una suscripción activa, podrás acceder a la información que necesitas. ¿No tienes acceso a Internet en tu lugar de trabajo? ¡Estamos trabajando en una solución para eso! Mantente atento para obtener más información sobre las capacidades sin conexión que llegarán en 2021.",
        "subscriptionType-Training": "Capacitación",
        "support-accountSettings_answer": "<0>Puede actualizar la información de su cuenta, las preferencias de notificación, los detalles del pago y cambiar su contraseña en su <1>cuenta NFPA</1>.</0>",
        "support-howDoIAccessOffline": "¿Cómo accedo a mi contenido fuera de línea?",
        "support-howDoIAccessOffline_answer": "Se puede acceder a su contenido fuera de línea a través del dispositivo, el navegador o la aplicación en la que se almacenó.Una vez que pierda la conectividad, vaya a link.nfpa.org o la aplicación instalada y sus publicaciones fuera de línea se mostrarán en su panel principal.Cualquier marcador, notas y contenido mejorado que haya guardado para uso fuera de línea se mostrará en línea al ver esas publicaciones.También puede buscar estas publicaciones en el modo sin conexión.Aquí hay algunos pasos opcionales que ofrecen acceso simplificado y una experiencia más óptima: cuando se utiliza una computadora, tiene la opción de instalar la aplicación web NFPA LINK ®.Puede hacer esto haciendo clic en el ícono de instalación en el lado derecho de la barra de su navegador.En los dispositivos móviles, puede agregar NFPA LINK ® a su pantalla de inicio seleccionando la opción 'Agregar a la pantalla de inicio'.Esto agregará un icono de aplicación para NFPA LINK ® a su teléfono para ayudar a agilizar el acceso.",
        "support-howDoIDeleteContent": "¿Cómo puedo eliminar el contenido que he almacenado para uso fuera de línea para liberar espacio de almacenamiento en mi dispositivo?",
        "support-howDoIDeleteContent_answer": "Para eliminar el contenido almacenado y el almacenamiento de dispositivos gratuitos, los usuarios deben iniciar sesión en NFPA LINK ® y vaya a la configuración de la cuenta> pestaña Offline para eliminar las publicaciones descargadas y el contenido asociado con aquellos.",
        "support-howMuchContent": "¿Cuánto contenido puedo almacenar para uso fuera de línea?",
        "support-freeTrial_answer": "<0>{{appName}} ofrece una prueba gratuita de 14 días.Esta opción se puede encontrar a continuación las <2>opciones del plan</2> en la página Iniciar sesión, o puede <4>inscribirse aquí</4>.Para participar en la prueba gratuita, debe tener una cuenta NFPA y aceptar los términos de la prueba.</0><1>Si tiene una cuenta NFPA existente, elija la opción 'Iniciar sesión' en <1>la página de inscripción</1> e ingrese sus credenciales de NFPA para continuar registrados para la prueba.Si no tiene una cuenta NFPA, seleccione la opción 'Crear una cuenta' en <1>la página de inscripción</1> para registrarse.</1>",
        "support-howMuchContent_answer": "La cantidad de contenido que puede almacenar para uso fuera de línea dependerá de las limitaciones de almacenamiento de su dispositivo.La aplicación NFPA LINK ® le permite usar hasta el 50% de su almacenamiento de dispositivos disponibles para nuestro contenido, con un máximo de 2 GB admitido.",
        "support-newBookmarksWhileOnline": "¿Qué pasa si creo nuevos marcadores y notas en línea?",
        "support-newBookmarksWhileOnline_answer": "Los nuevos marcadores / notas creadas en publicaciones que ya ha guardado para uso fuera de línea no se sincronizarán automáticamente con su contenido fuera de línea.Si va a la configuración de la cuenta> Sin conexión a sus publicaciones almacenadas tendrá un indicador si hay una actualización disponible.Desde allí, puede presionar el botón 'Descargar' para actualizar la versión que ha almacenado.",
        "support-offline-use": "¿Puedo usar el enlace {{appName}} está fuera de línea?",
        "support-offline-use_answer": "Con los navegadores admitidos, puede descargar sus publicaciones de uso frecuente para uso fuera de línea.Podrá acceder a este contenido sin conexión con su cuenta y perfil actual.Iniciar sesión con una cuenta diferente requiere volver a descargar este contenido.",
        "support-howDoILogIn": "¿Cómo inicio sesión en NFPA LiNK®?",
        "support-howDoILogIn_answer": "<0> <0> <0> <strong> Si ya tiene una cuenta NFPA existente </strong>, <3> iniciar sesión </3> usando sus credenciales de cuenta NFPA y seleccione el plan NFPA LiNK® de suelección. Una vez que haya seleccionado el plan de su elección, lo traerán a NFPA para completar su transacción. Una vez que haya completado su compra, lo devolverán al enlace NFPA para comenzar a usar su suscripción. Las credenciales de su cuenta NFPA son las mismas credenciales que utilizará para iniciar sesión en sus suscripciones de enlace NFPA. Si ha olvidado su contraseña de NFPA, puede <10> reiniciarla aquí </10>. </0> </0> <1> <0> <strong> Si necesita crear una cuenta de NFPA </strong>, <2> Seleccione el plan NFPA LiNK® de su elección. </2> Luego será llevado a NFPA para completar su transacción. Durante el proceso de pago, se le solicitará que cree una cuenta. Una vez que haya completado su compra, lo devolverán al enlace NFPA para comenzar a usar su suscripción. Las credenciales de su cuenta NFPA son las mismas credenciales que utilizará para iniciar sesión en sus suscripciones de enlace NFPA.",
        "support-offlineLimitations": "¿Hay alguna limitación para el acceso sin conexión?",
        "support-offlineLimitations_answer": "<0> <0> Los usuarios deben iniciar sesión en NFPA LINK ® para acceder a su contenido fuera de línea.Recomendamos iniciar sesión antes de ir sin conexión para confirmar el contenido que necesita se almacena en ese dispositivo / navegador o aplicación, y para asegurarse de que tiene una sesión activa en ejecución. </ 0> <1> Después de 20 días sin conectividad, NFPA LiNK® mostrará una advertencia de que su sesión caduque en 1 día. </ 1> <2> Después de 21 días sin conectividad, NFPA LINK ® terminará con su sesión fuera de línea y le pedirá que se vuelva a conectar para validar que aún quieta una suscripción activaExiste. </ 2> <3> El acceso sin conexión es compatible con los siguientes navegadores: Chrome V45 +, Safari V11.1 + / iOS 13+, Edge V17 +, Firefox V44 +. </ 3> </ 0>",
        "support-otherFormsOfContact": "Otras formas de contacto.",
        "support-otherFormsOfContact_answer": "<0>Nuestro equipo de atención al cliente está disponible para ayudarlo {{supportHours}}. Si está fuera de nuestro horario comercial, envíenos un correo electrónico a <3></3> Y le responderemos en nuestro próximo día hábil.</0><1><strong>Teléfono</strong></1><2><0></0> (U.S. & Canada)</2><3><0>{{altPhone}}</0> (U.S. & Canada)</3><4><0></0> (Internacional)</4><5><0>{{mxPhone}}</0> (México)</5>",
        "support-renewSubscription_answer": "<0>Si elegiste la renovación automática en el punto de compra, ¡no tienes que hacer nada más! Recibirás el beneficio de no tener que volver a preocuparte por la pérdida de acceso y se te cobrará automáticamente en tu fecha de renovación.</0><1>Si no eligiste la renovación automática al realizar la compra, deberás ir a nuestra <1>página de selección de planes</1> para renovar tu suscripción.</1>",
        "support-videoDemos": "¿Tienes videos de demostración para ayudarme a aprender a usar las funciones de NFPA LINK ®?",
        "support-videoDemos_answer": "<0> ¡Sí!No solo hemos desarrollado una guía de inicio rápido de <2> </ 2> para ayudarlo a familiarizarse con NFPA LINK ®, pero también hemos desarrollado estos videos de acompañamiento cortos: <5> <0> Dashboard </ 0> <1>Vista de libros </ 1> <2> Directo </ 2> <3> MyLiNK </ 3> <4> Offline Access </ 4> <5> <0> Panel de referencia </ 0> </ 5> <6>Búsqueda </ 6> </ 5> </ 0>",
        "support-whatHappensClearCookies": "¿Qué sucede si borro mi caché de cookies / navegador?",
        "support-whatHappensClearCookies_answer": "<0> <0> En una computadora, borrar el caché del navegador no debe eliminar ninguno del contenido que ha almacenado para su uso fuera de línea en ese dispositivo, navegador o aplicación instalada. </ 0> <1> en un dispositivo móvil, borrando el navegadorEl caché eliminará todo el contenido que ha almacenado para uso fuera de línea en esa aplicación de dispositivo, navegador o pantalla de inicio. </ 1> </ 0>",
        "support-whatsInApp_answer": "<0>NFPA LiNK®, una plataforma digital innovadora que brinda acceso instantáneo a los códigos y estándares de NFPA®, además de comentarios exclusivos de expertos, ayudas visuales y más. Los suscriptores de NFPA LiNK® ahora tienen acceso a más de 1500 códigos y normas NFPA. Esto incluye todas las ediciones actuales, así como una biblioteca de títulos heredados que se remonta a cinco ediciones. Los suscriptores también obtienen acceso anticipado a las ediciones recién publicadas antes de que el libro impreso esté disponible para su compra. <1><0>Navegar por las publicaciones disponibles.</0></1></0>",
        "freeTrialBillingCycle-goToNfpaToPurchase": "Registro completo",
        "freeTrialTermsAndConditions-mustAgreeToContinue": "Debes aceptar el acuerdo de prueba gratuita para continuar.",
        "freeTrialAgreementCheckBoxLabel": "Aceptar el acuerdo de prueba gratuita",
        "freeTrialAgreementLabel": "Acuerdo de prueba gratuita",
        "pin": "Fijar",
        "tryAgain": "Intentar otra vez",
        "switchToLink": "Cambie a su suscripción {{appNameWithHtml}} para acceder a más contenido, características y una experiencia interactiva.Desde {{appNameWithHtml}} puede ver comentarios de expertos, crear marcadores y notas, curar contenido, compartir y colaborar.",
        "system-issues-banner-text": "Alguna funcionalidad podría ser limitada actualmente.",
        "takeToLink": "Llévame a {{appNameWithHtml}}",
        "teamAdministrators": "Administradoras de equipo",
        "textCopied": "¡Texto copiado!",
        "thank-you": "Gracias",
        "tiaPanelNewAddition": "Este contenido no estaba en la versión anterior.",
        "toastHeader": "{{appNameWithHtml}} - Mucho más que códigos y estándares",
        "training-contract-role-already-exists": "Tu acceso a la formación ha caducado. Para continuar utilizando {{appName}}, <4>inscríbase en una nueva suscripción</4>.",
        "trainingContractRegistrationHeader": "¡Regístrese para su acceso de capacitación {{AppNametM}} para {{DurationDays}} días!",
        "trainingContractAccessCodeError": "Ha ingresado un código de acceso no válido.Inténtalo de nuevo.",
        "translationInfo": "Información de traducción",
        "tryLinkFree": "Pruebe el enlace {{appNameTm}} gratis",
        "unavailableOffline": "No disponible fuera de línea",
        "unpin": "Desanclar",
        "updatedItem": "Actualizado {{item}}.",
        "unrecognized-email": "Dirección de correo electrónico no reconocida",
        "updateViewingPreferences": "Actualice sus preferencias de visualización",
        "upgrade": "Mejora",
        "userCollectionMenu": "Menú de recopilación de usuarios",
        "urlLanguagePath": "es",
        "userRole": "rol del usuario",
        "view-bookmarks": "Ver marcadores",
        "userSupportPage-searchFormPlaceholder": "Filtrar por nombre, dirección de correo electrónico o ID NFPA.",
        "usersettings-deleteTiasHidden": "El contenido de TIA eliminado está oculto",
        "usersettings-showDeletedTIAs": "Mostrar contenido de TIA eliminado",
        "sectionDetailLabel": "Sección Detalle Diferencia",
        "viewChanges": "Ver cambios",
        "viewInLink": "Ver esto en {{appNameWithHtml}}",
        "watchVideo": "Ver el vídeo",
        "where": "dónde",
        "withAutoRenewal": "con renovación automática",
        "workgroup": "Trabajogrupo",
        "workgroups": "Trabajogrupos",
        "workGroupVisibility": "Los siguientes grupos de trabajo pueden ver esta colección:",
        "workgroup-create-failure": "Hubo un error al crear el grupo de trabajo",
        "workgroup-create-success": "grupo de trabajo creado con éxito",
        "workgroup-delete": "Eliminar Trabajogrupo",
        "workgroup-delete-cancel": "No, volver a Grupos de trabajo",
        "workgroup-delete-message": "Eliminar esto significa que los usuarios y las colecciones ya no estarán asociados con este grupo de trabajo. Los usuarios, marcadores o colecciones NO se eliminarán de LiNK®.",
        "workgroup-delete-confirmation-label": "¿Eliminar el grupo de trabajo?",
        "workgroup-delete-member-button": "Botón de eliminación de miembros del grupo de trabajo",
        "workgroup-delete-success": "Trabajogrupo eliminado con éxito",
        "workgroup-delete-failure": "El Trabajogrupo no pudo eliminar",
        "workGroup-groupMembers": "Miembros del Grupo",
        "workgroup-groups": "Grupos",
        "workgroup-manage-workgroups": "Administrar Trabajogrupo",
        "workgroup-members": "miembros",
        "workGroup-manage-group-members": "Administrar miembros del Trabajogrupo",
        "workgroup-update-failure": "Hubo un error al actualizar el grupo de trabajo",
        "workgroup-update-success": "Grupo de trabajo actualizado con éxito",
        "workgroups-information-interest": "Estos grupos de trabajo determinarán a qué colecciones compartidas puede acceder y ayudar a su equipo a mantenerse organizado.",
        "workgroups-information-label": "Tus grupos de trabajo",
        "your-free-trial": "Tu prueba gratuita",
        "your-plan": "Tu plan",
        "yourProfile": "Tu perfil",
        "zoomIn": "Acercarse",
        "zoomOut": "Disminuir el zoom",
    },
});

const Spanish = { ...SpanishSpain };
Spanish.code = "es";

export default SpanishSpain;

export { Spanish };
