import { PagedQuery } from "@rsm-hcd/javascript-core";
import AnnexRecord from "models/view-models/annex-record";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface AnnexBasePathParams {
    publicationId: number;
}

export interface AnnexResourcePathParams extends AnnexBasePathParams {
    id: number;
}

/**
 * Search parameters when listing Annex resources.
 * These get translated to QueryString parameters
 */
export interface AnnexListQueryParams extends PagedQuery {
    nfpaLabel?: string;
}

// #endregion Interfaces

// ---------------------------------------------------------------------------------------------
// #region Constants
// ---------------------------------------------------------------------------------------------

const baseEndpoint = "publications/:publicationId/annexes";
const key = "publication-annex";
const resourceEndpoint = `${baseEndpoint}/:id`;
const resourceType = AnnexRecord;

// #endregion Constants

// ---------------------------------------------------------------------------------------------
// #region Service
// ---------------------------------------------------------------------------------------------

const AnnexService = {
    key,
    /**
     * Retrieve a specific Annex resource
     */
    get: ServiceFactory.get<AnnexRecord, AnnexResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),

    /**
     * Retrieves a list of Annex resources based upon supplied search criteria
     */
    list: ServiceFactory.nestedList<
        AnnexRecord,
        AnnexBasePathParams,
        AnnexListQueryParams
    >(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service get calls in react components
     */
    useGet: ServiceHookFactory.useGet<AnnexRecord, AnnexResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),

    /**
     * Custom hook for leveraging service list calls in react components
     */
    useList: ServiceHookFactory.useNestedList<
        AnnexRecord,
        AnnexBasePathParams,
        AnnexListQueryParams
    >(resourceType, baseEndpoint),

    useListQuery: QueryHookFactory.useNestedListQuery<
        AnnexRecord,
        AnnexBasePathParams,
        AnnexListQueryParams
    >(key, resourceType, baseEndpoint),
};

// #endregion Service

// ---------------------------------------------------------------------------------------------
// #region Exports
// ---------------------------------------------------------------------------------------------

export default AnnexService;

// #endregion Exports
