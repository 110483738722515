import { CatchResultHandler, ResultRecord } from "@rsm-hcd/javascript-core";

/**
 * Default error handler to help debugging. Only registered in development environment.
 * @see index.tsx
 * @param result errored ResultRecord, or undefined
 * @param error Javascript error object, or anything since you can throw anything in JS, or undefined
 */
const DoTryDefaultCatchHandler: CatchResultHandler<any> = (
    result?: ResultRecord<any>,
    error?: any
) => {
    if (result != null) {
        console.error(result.toJS());
    }

    if (error != null) {
        console.error(error);
    }
};

export default DoTryDefaultCatchHandler;
