import PublishStatus from "models/enumerations/publish-status";
import { useState } from "react";
import { useDebounce } from "@rsm-hcd/javascript-react";
import usePublicationSelectOptions from "./use-publication-select-options";

const useAdminControls = () => {
    const [hasUnpublishedChanges, setHasUnpublishedChanges] =
        useState<boolean>();
    const [searchText, setSearchText] = useState("");
    const [publishStatus, setPublishStatusFilter] = useState<PublishStatus>();

    const debouncedText = useDebounce(searchText, 250);

    const {
        publicationCode,
        publicationEdition,
        publications,
        publicationSelectOptions,
        handleSelectedPublication,
    } = usePublicationSelectOptions({});

    return {
        publicationCode,
        publicationEdition,
        publications,
        publicationSelectOptions,
        handleSelectedPublication,
        searchText,
        setHasUnpublishedChanges,
        setSearchText,
        publishStatus,
        setPublishStatusFilter,
        hasUnpublishedChanges,
        debouncedText,
    };
};

export default useAdminControls;
