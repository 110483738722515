import { XmlChangeNotationConstants } from "constants/xml-change-notation-constants";
import { PartRecord } from "internal";
import { useUpdateAtom } from "jotai/utils";
import SectionCollectionRecord from "models/view-models/section-collection-record";
import SectionRecord from "models/view-models/section-record";
import TiaListPanel from "organisms/panels/tia-changes/tia-list-panel";
import SectionDetailWrapper from "organisms/section-detail/section-detail-wrapper";
import * as React from "react";
import { PropsWithChildren, useEffect, useMemo, useRef } from "react";
import useChangeIndicatorDiffPanel from "utilities/atoms/change-indicator-diff-panel/use-change-indicator-diff-panel";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useTias from "utilities/hooks/domain/publications/use-tias";
import useSectionScrollSpy, {
    IsClickedAtom,
} from "utilities/hooks/scroll-spy/use-section-scroll-spy";
import useSectionScrollSpyHandlers from "utilities/hooks/scroll-spy/use-section-scroll-spy-handlers";
import useBreakpoint from "utilities/hooks/use-breakpoint";
import ScrollSpy from "utilities/scoll-spy/scroll-spy";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface PublicationContentAreaProps {
    parts?: PartRecord[];
    sections: SectionRecord[];
    memoizedChildren?: JSX.Element;

    // the external id of the parent component
    // optional becaue annex groups do not have an external id
    externalId?: string;
}

const CSS_CLASS_NAME = "c-publication-page-layout__content__main";

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const PublicationContentArea: React.FunctionComponent<
    PropsWithChildren<PublicationContentAreaProps>
> = (props: PropsWithChildren<PublicationContentAreaProps>) => {
    const { externalId, parts, sections } = props;
    const sectionCollection = useMemo(
        () =>
            new SectionCollectionRecord({
                parts: parts ?? [],
                sections,
            }).flatten(),
        [parts, sections]
    );

    const showDeletedTias =
        useGlobalState()
            .globalState.getUserSetting("TiasDeletedShow")
            ?.getValue<boolean>() ?? false;

    const { showDiffPanel } = useChangeIndicatorDiffPanel();
    const { isTiaListPanelOpen, isTiaPanelOpen } = useTias();

    const displaySections = useMemo(
        () =>
            sectionCollection.filter((currentSection, index, sections) => {
                if (
                    !showDeletedTias &&
                    currentSection.tiaChanges ===
                        XmlChangeNotationConstants.TIA_DELETION
                ) {
                    return false;
                }

                const nextSection = sections[index + 1];
                if (!currentSection.isDeleted() || nextSection == null) {
                    return true;
                }

                return !(currentSection.isDeleted() && nextSection.isDeleted());
            }),
        [sectionCollection, showDeletedTias]
    );

    const sectionLevels = useMemo(() => {
        const types: Record<string, number | undefined> = {};

        sectionCollection.forEach((s) => (types[s.externalId] = s.type));

        return types;
    }, [sectionCollection]);

    const sectionRefs = useRef<(HTMLElement | null)[]>([]);

    const { callback } = useSectionScrollSpy();
    const { addScrollListener } = useSectionScrollSpyHandlers();

    const isDesktop = useBreakpoint(Breakpoints.SmallDesktop);
    const showChanges = (showDiffPanel || isTiaPanelOpen) && isDesktop;
    const showTiaListPanel = isTiaListPanelOpen && externalId != null;
    const showChangesClassName = showChanges ? "-show-changes" : "";
    const tiaHighlightClassName =
        isTiaListPanelOpen && isDesktop ? "-highlight-content" : "";

    const setIsClicked = useUpdateAtom(IsClickedAtom);

    useEffect(() => {
        let isClicked = false;
        setIsClicked((prev) => {
            isClicked = prev;
            return prev;
        });

        if (isClicked) addScrollListener();
    }, [addScrollListener, setIsClicked]);

    const root = document.getElementsByClassName(
        "c-publication-page-layout__content__main"
    )[0];
    const threshold = Array.from(Array(11).keys(), (_, i) => i / 10);
    const options = { root, threshold };

    return (
        <div className={"content-panel-container"}>
            <div
                className={`${CSS_CLASS_NAME}__content-wrapper ${showChangesClassName} ${tiaHighlightClassName}`}>
                {props.memoizedChildren}
                <ScrollSpy
                    callback={callback}
                    sectionRefs={sectionRefs}
                    options={options}>
                    {displaySections.map((section: SectionRecord) => (
                        <SectionDetailWrapper
                            key={section.id}
                            ref={(el) => {
                                if (!section.isDeleted())
                                    sectionRefs.current.push(el);
                            }}
                            section={section}
                            sectionCollection={displaySections}
                        />
                    ))}
                </ScrollSpy>
            </div>

            {showChanges && (
                // we need this container for the change diff pannel, tia panel, and tia list panel
                <div className={"changes-panel-wrapper"}>
                    {showTiaListPanel && (
                        <TiaListPanel
                            externalId={externalId!}
                            sectionLevels={sectionLevels}
                        />
                    )}
                </div>
            )}

            {/* For mobile */}
            {!showChanges && showTiaListPanel && (
                <TiaListPanel
                    externalId={externalId!}
                    sectionLevels={sectionLevels}
                />
            )}
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default React.memo(PublicationContentArea);

// #endregion Exports
