import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import { DataTestAttributes } from "interfaces/data-test-attributes";
import React, { forwardRef, ReactNode } from "react";
import StringUtils from "utilities/string-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface ParagraphProps extends Pick<DataTestAttributes, "dataTestId"> {
    cssClassName?: string;
    dangerouslySetInnerHTML?: { __html: string };
    id?: string;
    ref?: React.RefObject<HTMLParagraphElement> | null;
    size?: ParagraphSizes;
    children?: ReactNode | ReactNode[];
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const Paragraph = forwardRef(
    (props: ParagraphProps, ref: React.Ref<HTMLParagraphElement>) => {
        const {
            children,
            cssClassName,
            dangerouslySetInnerHTML,
            dataTestId,
            id,
            size,
        } = props;
        let cssClassNames: Array<string> = [];

        if (StringUtils.hasValue(cssClassName)) {
            cssClassNames.push(cssClassName);
        }

        if (size != null) {
            cssClassNames.push(size);
        }

        return (
            <p
                className={cssClassNames.join(" ")}
                dangerouslySetInnerHTML={dangerouslySetInnerHTML}
                data-test-id={dataTestId}
                id={id}
                ref={ref}>
                {children}
            </p>
        );
    }
);

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default Paragraph;

// #endregion Exports
