import StringUtils from "utilities/string-utils";
import { CollectionUtils } from "utilities/collection-utils";
import { useAsyncEffect } from "@rsm-hcd/javascript-react";
import AnnouncementRecord from "models/view-models/announcement-record";
import InputFormField from "molecules/form-fields/input-form-field";
import RichTextEditor from "molecules/rich-text/rich-text-editor";
import AnnouncementEditorFooter from "organisms/admin/annoucements/announcement-editor-footer";
import React, { useEffect, useState } from "react";
import useAnnouncements from "utilities/hooks/domain/announcements/use-announcements";
import NumberUtils from "utilities/number-utils";
import usePageErrors from "utilities/hooks/use-page-errors";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface AnnouncementEditorProps {
    announcementId: number;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS = "c-announcement-editor";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const AnnouncementEditor: React.FC<AnnouncementEditorProps> = (props) => {
    const { announcementId } = props;
    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [initialAnnouncement, setInitialAnnouncement] =
        useState<AnnouncementRecord>(new AnnouncementRecord());
    const [announcement, setAnnouncement] = useState<AnnouncementRecord>(
        new AnnouncementRecord()
    );

    const isDirty = announcement.isDirty(initialAnnouncement);

    const { pageErrors, resetPageErrors, setPageErrors } = usePageErrors();

    // #endregion State

    const { get: getAnnouncement, refreshContext: refreshAnnouncementContext } =
        useAnnouncements();

    useAsyncEffect(
        async function loadAnnouncement() {
            if (loading || loaded || NumberUtils.isDefault(announcementId)) {
                return;
            }

            setLoading(true);
            const result = await getAnnouncement(announcementId);

            setLoaded(true);
            setLoading(false);
            if (result == null) {
                return;
            }

            setAnnouncement(result!);
            setInitialAnnouncement(result!);
        },
        [
            getAnnouncement,
            loaded,
            loading,
            setAnnouncement,
            setLoaded,
            setLoading,
        ]
    );

    useEffect(
        function resetLoadingState() {
            setLoaded(false);
        },
        [announcementId]
    );

    const handleTitleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setAnnouncement((prevState: AnnouncementRecord) =>
            prevState.with({ titleDraft: value })
        );
    };

    const handleBodyChanged = (value: string) => {
        setAnnouncement((prevState: AnnouncementRecord) =>
            prevState.with({ bodyDraft: value.trim() })
        );
    };

    const handleUrlChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setAnnouncement((prevState: AnnouncementRecord) =>
            prevState.with({ urlDraft: value.trim() })
        );
    };

    const validate = (): boolean => {
        if (StringUtils.isEmpty(announcement.titleDraft)) {
            setPageErrors(["Title is required."]);
            return false;
        }

        if (
            StringUtils.hasValue(announcement.urlDraft) &&
            !announcement.urlDraft.startsWith("/")
        ) {
            setPageErrors(["Url must begin with a /"]);
            return false;
        }

        resetPageErrors();
        return true;
    };
    const titleErrors = pageErrors.filter((e) => e.startsWith("Title"));
    const urlErrors = pageErrors.filter((e) => e.startsWith("Url"));

    return (
        <React.Fragment>
            <div className={`${BASE_CLASS}__content`}>
                <InputFormField
                    disabled={loading}
                    errorMessages={titleErrors}
                    isValid={CollectionUtils.isEmpty(titleErrors)}
                    label="Announcement Title"
                    maxLength={150}
                    onChange={handleTitleChanged}
                    placeholder="Enter an announcement title..."
                    required={true}
                    value={announcement?.titleDraft}
                />
                <RichTextEditor
                    allowImages={true}
                    disabled={loading}
                    label="Announcement Body"
                    onChange={handleBodyChanged}
                    placeholder="Enter announcement text here.."
                    preserveWhitespace={true}
                    value={announcement?.bodyDraft!}
                />
                <InputFormField
                    disabled={loading}
                    errorMessages={urlErrors}
                    isValid={CollectionUtils.isEmpty(urlErrors)}
                    label="Announcement URL"
                    maxLength={1000}
                    onChange={handleUrlChanged}
                    placeholder="Enter an application route to display this announcement on (i.e /account/information) ..."
                    required={false}
                    value={announcement?.urlDraft}
                />
            </div>
            <AnnouncementEditorFooter
                announcement={announcement}
                loading={loading}
                isDirty={isDirty}
                onAnnouncementUpdated={() => {
                    refreshAnnouncementContext();
                    setLoaded(false);
                }}
                validate={validate}
            />
        </React.Fragment>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default AnnouncementEditor;

// #endregion Export
