import React, { forwardRef, Ref } from "react";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface TextAreaProps {
    disabled?: boolean;
    id: string;
    maxLength?: number;
    name?: string;
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onInput?: () => void;
    placeholder?: string;
    ref?: Ref<HTMLTextAreaElement>;
    rows?: number;

    /**
     * Unique identifier used select the underlying <textarea> for functional/e2e testing
     */
    testId?: string;
    value?: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const TextArea = forwardRef(
    (props: TextAreaProps, ref: Ref<HTMLTextAreaElement>) => {
        const {
            disabled,
            id,
            maxLength,
            name,
            onChange,
            onInput,
            placeholder,
            rows,
            testId,
            value,
        } = props;

        return (
            <textarea
                data-test-id={testId}
                disabled={disabled}
                id={id}
                maxLength={maxLength}
                name={name}
                onChange={onChange}
                onInput={onInput}
                placeholder={placeholder}
                ref={ref}
                rows={rows}
                value={value}
            />
        );
    }
);

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default TextArea;

// #endregion Exports
