import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import MenuButton from "molecules/menu-button/menu-button";
import PublishStatusBadge from "organisms/admin/situational-navigation/publish-status-badge";
import EnhancedContentDeleteActionModal from "organisms/enhanced-content/enhanced-content-action-modals/enhanced-content-delete-action-modal";
import EnhancedContentPublishActionModal from "organisms/enhanced-content/enhanced-content-action-modals/enhanced-content-publish-action-modal";
import EnhancedContentUnpublishActionModal from "organisms/enhanced-content/enhanced-content-action-modals/enhanced-content-unpublish-action-modal";
import EnhancedContentDeleteAction from "organisms/enhanced-content/enhanced-content-actions/enhanced-content-delete-action";
import EnhancedContentEditAction from "organisms/enhanced-content/enhanced-content-actions/enhanced-content-edit-action";
import EnhancedContentPublishAction from "organisms/enhanced-content/enhanced-content-actions/enhanced-content-publish-action";
import EnhancedContentUnpublishAction from "organisms/enhanced-content/enhanced-content-actions/enhanced-content-unpublish-action";
import EnhancedContentChanges from "organisms/enhanced-content/enhanced-content-changes";
import { EnhancedContentPanelProps } from "organisms/enhanced-content/enhanced-content-panel";
import React, { useState } from "react";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";

// -----------------------------------------------------------------------------------------
// #region Interface
// -----------------------------------------------------------------------------------------

interface EnhancedContentPanelFooterProps
    extends Pick<
        EnhancedContentPanelProps,
        | "enhancedContent"
        | "isPreview"
        | "onDeleteConfirm"
        | "onEditClick"
        | "onSuccess"
    > {}

// #endregion Interface

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-enhanced-content-panel";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const EnhancedContentPanelFooter = (props: EnhancedContentPanelFooterProps) => {
    const { enhancedContent, isPreview, onEditClick } = props;
    const { isOnline } = useNetworkInformation();

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showPublishConfirmation, setShowPublishConfirmation] =
        useState(false);
    const [showUnpublishConfirmation, setShowUnpublishConfirmation] =
        useState(false);

    const showActions = !isPreview;

    return (
        <div className={`${BASE_CLASS_NAME}__footer`}>
            <EnhancedContentChanges enhancedContent={enhancedContent} />
            {showActions && (
                <div className={`${BASE_CLASS_NAME}__footer__actions`}>
                    <PublishStatusBadge
                        hasUnpublishedChanges={enhancedContent.hasUnpublishedChanges()}
                        status={enhancedContent.getPublishStatus()}
                    />
                    <MenuButton
                        buttonAccessibleText="Enhanced content options menu"
                        disabled={!isOnline}
                        triggerButtonSize={ButtonSizes.XSmall}
                        triggerButtonStyle={ButtonStyles.TertiaryAlt}>
                        <EnhancedContentPublishAction
                            {...props}
                            onClick={() => setShowPublishConfirmation(true)}
                        />
                        <EnhancedContentEditAction onClick={onEditClick} />
                        <EnhancedContentUnpublishAction
                            {...props}
                            onClick={() => setShowUnpublishConfirmation(true)}
                        />
                        <EnhancedContentDeleteAction
                            {...props}
                            onClick={() => setShowDeleteConfirmation(true)}
                        />
                    </MenuButton>

                    {showDeleteConfirmation && (
                        <EnhancedContentDeleteActionModal
                            {...props}
                            onClose={() => setShowDeleteConfirmation(false)}
                        />
                    )}

                    {showPublishConfirmation && (
                        <EnhancedContentPublishActionModal
                            {...props}
                            onClose={() => setShowPublishConfirmation(false)}
                        />
                    )}

                    {showUnpublishConfirmation && (
                        <EnhancedContentUnpublishActionModal
                            {...props}
                            onClose={() => setShowUnpublishConfirmation(false)}
                            updatePublicationPageContext={true}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default EnhancedContentPanelFooter;

// #endregion Export
