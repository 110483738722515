import { atom, useAtom } from "jotai";
import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router";

const callbackUrlAtom = atom<string>("");

export const useUrlCallback = () => {
    const [callbackUrlValue, setCallbackUrl] = useAtom(callbackUrlAtom);
    const history = useHistory();
    const location = useLocation();

    const setCallbackUrlParam = useCallback(
        (url: string): void => {
            const searchParams = new URLSearchParams(location.search);
            if (url) {
                searchParams.set("callback_url", url);
            } else {
                searchParams.delete("callback_url");
            }
            const newUrl = `${location.pathname}?${searchParams.toString()}`;
            history.push(newUrl);
            setCallbackUrl(url);
        },
        [history, location.pathname, location.search, setCallbackUrl]
    );

    const callbackUrl = useMemo(() => {
        if (callbackUrlValue) {
            return callbackUrlValue;
        }
        const searchParams = new URLSearchParams(location.search);
        return searchParams.get("callback_url");
    }, [callbackUrlValue, location.search]);

    return { callbackUrl, setCallbackUrlParam };
};
