import "assets/scss/app.scss";
import "lazysizes";
import FullScreenTransition from "organisms/full-screen-transition/full-screen-transition";
import ServiceWorkerUpdate from "organisms/service-worker-update/service-worker-update";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "react-toastify/dist/ReactToastify.min.css";
import { HeaderDataProvider } from "utilities/contexts/header-data-context-provider";
import ServiceWorkerOfflineBookSync from "organisms/service-worker-offline-book-sync/service-worker-offline-book-sync";
import Pendo from "molecules/pendo/pendo";
import WindowContextProvider from "utilities/contexts/window/window-context-provider";
import DelightedSurvey from "organisms/marketing/delighted-survey";
import { MsalProvider } from "@azure/msal-react";
import ConfiguredApp from "./configured-app";
import PUBLIC_CLIENT_APPLICATION from "./public-client-application";
import useSetupApp from "./use-setup-app";
import PageViews from "./utilities/hooks/domain/page-views";
import MaintenancePage from "pages/errors/maintenance";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import { Helmet, HelmetProvider } from "react-helmet-async";

const App = () => {
    const { htmlAttributes, globalState } = useSetupApp();
    const isMaintenanceModeActive =
        globalState.systemSettings?.maintenanceMode?.isActive;
    const { isOnline } = useNetworkInformation();

    return (
        <HelmetProvider>
            <MsalProvider instance={PUBLIC_CLIENT_APPLICATION}>
                <Helmet htmlAttributes={htmlAttributes} />
                <Router>
                    <HeaderDataProvider>
                        <WindowContextProvider>
                            <Pendo />
                            <DelightedSurvey />
                            <ServiceWorkerUpdate />
                            <ServiceWorkerOfflineBookSync />
                            <PageViews />
                            {globalState.systemSettingsLoaded ? (
                                isMaintenanceModeActive && isOnline ? (
                                    <MaintenancePage />
                                ) : (
                                    <ConfiguredApp />
                                )
                            ) : (
                                <FullScreenTransition transitionText="" />
                            )}
                        </WindowContextProvider>
                    </HeaderDataProvider>
                </Router>
            </MsalProvider>
        </HelmetProvider>
    );
};

export default App;
