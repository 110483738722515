import { useAtom } from "jotai";
import OfflineDeviceRecord from "models/view-models/offline/offline-device-record";
import { useEffect } from "react";
import { CurrentOfflineDeviceAtom } from "utilities/atoms/current-offline-device-atom";
import NumberUtils from "utilities/number-utils";
import OfflineStorageProviderFactory from "utilities/service-worker/storage/offline-storage-provider-factory";
import OfflineDevicesService from "utilities/services/offline/offline-devices-service";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const offlineStorageProvider = OfflineStorageProviderFactory.create();

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Hook
// -----------------------------------------------------------------------------------------

/**
 * Gets or creates an OfflineDevice entity for the provided userId
 */
const useInitializeOfflineDevice = (userId?: number, hasRole?: boolean) => {
    const { create: createOfflineDevice } = OfflineDevicesService.useCreate();
    const [currentOfflineDevice, setCurrentOfflineDevice] = useAtom(
        CurrentOfflineDeviceAtom
    );

    useEffect(() => {
        const isFreeAccess = window.location.pathname.includes("free-access");

        if (
            isFreeAccess ||
            NumberUtils.isDefault(userId) ||
            currentOfflineDevice?.createdById === userId
        ) {
            return;
        }

        const getOrCreateOfflineDevice = async () => {
            if (!hasRole) return;

            const localOfflineDevice =
                await offlineStorageProvider.getCurrentOfflineDevice(userId);

            if (localOfflineDevice.id != null) {
                setCurrentOfflineDevice(
                    new OfflineDeviceRecord(localOfflineDevice)
                );
                return;
            }

            const { resultObject } = await createOfflineDevice(
                new OfflineDeviceRecord(localOfflineDevice)
            );

            await offlineStorageProvider.saveOfflineDevice(
                resultObject!.toJS() as OfflineDeviceRecord
            );

            setCurrentOfflineDevice(resultObject);
        };

        getOrCreateOfflineDevice();
    }, [
        createOfflineDevice,
        currentOfflineDevice,
        setCurrentOfflineDevice,
        userId,
        hasRole,
    ]);
};

// #endregion Hook

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default useInitializeOfflineDevice;

// #endregion Exports
