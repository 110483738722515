import { AnnexGroupParams } from "interfaces/routing/route-params";
import AnnexGroupTableOfContentsRecord from "models/view-models/table-of-contents/annex-group-table-of-contents-record";
import TableOfContentsCollapsePanelLink from "organisms/table-of-contents/table-of-contents-collapse-panel-link";
import React from "react";
import { useParams } from "react-router-dom";
import useAdminPreview from "utilities/hooks/use-admin-preview";
import { useLocalization } from "utilities/hooks/use-localization";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------
interface TableOfContentsAnnexGroupProps {
    annexGroup: AnnexGroupTableOfContentsRecord;
    annexNfpaLabel: string;
    isPublic?: boolean;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const TableOfContentsAnnexGroup = (props: TableOfContentsAnnexGroupProps) => {
    const { annexGroup, annexNfpaLabel, isPublic = false } = props;

    const { t } = useLocalization();
    const { isAdminPreview } = useAdminPreview();
    const params = useParams<AnnexGroupParams>();

    if (params.nfpaLabel == null) params.nfpaLabel = annexNfpaLabel;

    const route = annexGroup.getRoute(params, isAdminPreview);

    return (
        <TableOfContentsCollapsePanelLink
            dataTestId="annex-group-link"
            dataTestDisplaySequence={annexGroup.groupBy}
            hasBookmark={annexGroup.hasBookmark}
            index={annexGroup.groupBy}
            isPublic={isPublic}
            to={route}>
            <span>{t("chapterNumber", { number: +annexGroup.groupBy })}</span>
        </TableOfContentsCollapsePanelLink>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default TableOfContentsAnnexGroup;

// #endregion Export
