import SituationRecord from "models/view-models/situational-navigation/situations/situation-record";
import SolutionRecord from "models/view-models/situational-navigation/solutions/solution-record";
import { LinkCardTypes } from "molecules/constants/link-card-types";
import LinkCard from "molecules/link-card/link-card";
import SituationRelatedSituationCard from "organisms/situational-navigation/situations/situation-related-situation-card";
import React from "react";
import SituationRelationshipRecord from "models/view-models/situational-navigation/situations/situation-relationship-record";
import { RecordUtils } from "@rsm-hcd/javascript-core";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface SituationRelationshipCardProps {
    accessibleText: string;
    value: SolutionRecord | SituationRecord;
    situationRelationship?: SituationRelationshipRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const SituationRelationshipCard: React.FC<SituationRelationshipCardProps> = (
    props: SituationRelationshipCardProps
) => {
    const CSS_BASE_CLASS = "c-situation-relationship-card";
    const isHotSpot =
        props.situationRelationship?.hotSpotX != null &&
        props.situationRelationship?.hotSpotY != null;

    return (
        <div className={CSS_BASE_CLASS}>
            {RecordUtils.isRecord(props.value, SituationRecord) && (
                <SituationRelatedSituationCard
                    accessibleText={`View related situation, ${props.value.title}`}
                    situation={props.value}>
                    {
                        // if
                        isHotSpot && (
                            <span
                                className={`${CSS_BASE_CLASS}__hotspot -situation`}>
                                {props.situationRelationship!.displaySequence +
                                    1}
                            </span>
                        )
                    }
                </SituationRelatedSituationCard>
            )}
            {RecordUtils.isRecord(props.value, SolutionRecord) && (
                <LinkCard
                    includeIcon={true}
                    label={props.value.subtitle}
                    to={props.value.getRoute()}
                    type={LinkCardTypes.Link}
                    cssParagraphClassModifier={`${CSS_BASE_CLASS}__paragraph -solution`}>
                    <span>{props.value.title}</span>
                    {
                        // if
                        isHotSpot && (
                            <span
                                className={`${CSS_BASE_CLASS}__hotspot -solution`}>
                                {props.situationRelationship!.displaySequence +
                                    1}
                            </span>
                        )
                    }
                </LinkCard>
            )}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default SituationRelationshipCard;

// #endregion Exports
