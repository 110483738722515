import { RecordUtils } from "@rsm-hcd/javascript-core";
import { Record } from "immutable";
import type UserLogin from "models/interfaces/user-login";
import RoleRecord from "models/view-models/role-record";
import UserRoleRecord from "models/view-models/user-role-record";
import RoleType from "utilities/enumerations/role-type";

const defaultValues: UserLogin =
    RecordUtils.auditableDefaultValuesFactory<UserLogin>({
        isSuccessful: undefined,
        keepAliveOn: undefined,
        password: undefined,
        role: undefined,
        roleId: undefined,
        userId: undefined,
        userName: undefined,
        userRole: undefined,
        userRoleId: undefined,
    });

export default class UserLoginRecord
    extends Record(defaultValues)
    implements UserLogin
{
    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: UserLogin) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.role != null) {
            params.role = RecordUtils.ensureRecord(params.role, RoleRecord);
        }

        if (params.userRole != null) {
            params.userRole = RecordUtils.ensureRecord(
                params.userRole,
                UserRoleRecord
            );
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Returns whether or not the current UserLoginRecord has an associated RoleRecord
     * Note: This can be at the top-level (this.role) or nested (this.userRole.role)
     */
    public hasRole(): boolean {
        return this.role != null || this.userRole?.role != null;
    }

    /**
     * Returns whether or not the current UserLoginRecord is associated with the given RoleType
     *
     * @param {RoleType} type
     */
    public isCurrentRole(type: RoleType): boolean {
        if (!this.hasRole()) {
            return false;
        }

        // Check for the role at the top-level or nested under UserRole just in-case the caller
        // has built the record with one vs. the other.
        if (this.role?.is(type) || this.userRole?.role?.is(type)) {
            return true;
        }

        return false;
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<UserLogin>} values
     */
    public with(values: Partial<UserLogin>): UserLoginRecord {
        return new UserLoginRecord(Object.assign(this.toJS(), values));
    }

    /**
     * Adds RoleRecord relationship to UserLoginRecord from a list of RoleRecords.
     *
     * @param {RoleRecord[]} [roles]
     */
    public withRole(roles?: RoleRecord[]): UserLoginRecord {
        if (roles == null) {
            return this.with({});
        }

        return this.with({
            role: roles.find((role: RoleRecord) => role.id === this.roleId),
        });
    }

    /**
     * Adds UserRoleRecord relationship to UserLoginRecord from a list of UserRoleRecords.
     *
     * @param {UserRoleRecord[]} [userRoles]
     */
    public withUserRole(userRoles?: UserRoleRecord[]): UserLoginRecord {
        if (userRoles == null) {
            return this.with({});
        }

        return this.with({
            userRole: userRoles.find(
                (userRole: UserRoleRecord) => userRole.id === this.userRoleId
            ),
        });
    }

    // #endregion Public Methods
}
