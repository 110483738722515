import { Record } from "immutable";
import type CreateGroupInvitationDto from "models/interfaces/group-invitations/create-group-invitation-dto";
import { RecordUtils } from "@rsm-hcd/javascript-core";

const defaultValues: CreateGroupInvitationDto =
    RecordUtils.auditableDefaultValuesFactory<CreateGroupInvitationDto>({
        acceptedOn: undefined,
        emails: [],
        expiresOn: undefined,
        groupId: undefined,
        magicLinkUrlTemplate: undefined,
    });

export default class CreateGroupInvitationDtoRecord
    extends Record(defaultValues)
    implements CreateGroupInvitationDto
{
    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: Partial<CreateGroupInvitationDto>) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<GroupInvitation>} values
     */
    public with(
        values: Partial<CreateGroupInvitationDto>
    ): CreateGroupInvitationDtoRecord {
        return new CreateGroupInvitationDtoRecord(
            Object.assign(this.toJS(), values)
        );
    }

    // #endregion Public Methods
}
