import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { Icons } from "atoms/constants/icons";
import ChangeIndicatorIcon from "atoms/icons/change-indicator-icon";
import Icon from "atoms/icons/icon";
import { TitleConverterId } from "atoms/titles/title-converter";
import Paragraph from "atoms/typography/paragraph";
import { XmlChangeNotationConstants } from "constants/xml-change-notation-constants";
import { XmlConvertedComponentProps } from "interfaces/forms/xml-converted-component-props";
import { ModalCloseButtonStyle } from "molecules/constants/modal-close-button-style";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import Modal from "molecules/modals/modal";
import * as React from "react";
import { useState } from "react";
import useCurrentPublication from "utilities/contexts/use-current-publication";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import { useLocalization } from "utilities/hooks/use-localization";
import { tForCulture } from "utilities/localization-utils";
import XmlUtils from "utilities/xml-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface FigureSectionProps extends XmlConvertedComponentProps {
    label?: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const FigureSection: React.FunctionComponent<FigureSectionProps> = (
    props: FigureSectionProps
) => {
    const { publication } = useCurrentPublication();
    const { t } = useLocalization();
    const expandFigure = t("expandItem", { item: t("figure") });
    const figureView = t("itemView", { item: t("figure") });

    const [showFullScreen, setShowFullScreen] = useState<boolean>(false);

    const isMobile = useBreakpoint(
        Breakpoints.Phone,
        BreakpointComparer.MaxWidth
    );

    const closeButtonStyle = isMobile
        ? ModalCloseButtonStyle.InsideDialog
        : ModalCloseButtonStyle.OutsideDialog;

    const className = "c-figure-section";
    const changeIndicatorClassName = "c-icon -change-indicator";

    const children = React.Children.toArray(props.children ?? []);
    const captionElement = XmlUtils.findElementByConverterId(
        TitleConverterId,
        children as JSX.Element[]
    );
    const processedChildren = XmlUtils.filterChildrenByConverterId(
        TitleConverterId,
        children as JSX.Element[]
    );
    let captionTitle = props.label ?? "";

    // Prepend 'Figure' if the caption title does not already include it. For some figures,
    // the data label already contains this, ie 'Informational Note Figure 800.100(B)(1)'
    const figureText = tForCulture(publication?.locale, "figure");
    if (!captionTitle.toLowerCase().includes(figureText.toLowerCase())) {
        captionTitle = `${figureText} ${captionTitle}`;
    }

    const isDeleted = props.diffchanged === XmlChangeNotationConstants.DELETION;
    const hasChanges =
        props.diffchanged != null &&
        props.diffchanged !== XmlChangeNotationConstants.NO_CHANGES;
    const classNameModifier = `${hasChanges && "-changed"} ${
        isDeleted && "c-code-change -deletion"
    }`;

    return (
        <div
            className={`${className} ${classNameModifier}`}
            id={props.externalId}>
            <div className={`${className}__expandable`}>
                <div className={`${className}__expander`}>
                    <Button
                        accessibleText={expandFigure}
                        cssClassName="-icon"
                        onClick={() => setShowFullScreen(true)}
                        size={ButtonSizes.Small}
                        style={ButtonStyles.Primary}>
                        <Icon type={Icons.Enlarge} />
                    </Button>
                </div>
                {processedChildren}
                {showFullScreen && (
                    <Modal
                        closeButtonStyle={closeButtonStyle}
                        closeDialog={() => setShowFullScreen(false)}
                        cssClassName={`${className}-modal`}
                        isVisible={showFullScreen}
                        label={figureView}
                        transition={ModalTransitions.Fade}
                        type={ModalTypes.Fullscreen}>
                        {processedChildren}
                    </Modal>
                )}
            </div>

            <Paragraph cssClassName={`${className}__caption`}>
                {props.diffchanged != null && (
                    <ChangeIndicatorIcon
                        changes={props.diffchanged}
                        cssClassName={changeIndicatorClassName}
                    />
                )}
                <strong>{captionTitle}</strong> {captionElement}
            </Paragraph>
        </div>
    );
};

// #endregion Component

export default FigureSection;
