import { Record } from "immutable";
import type Job from "models/interfaces/job";
import type { Auditable } from "@rsm-hcd/javascript-core";
import { RecordUtils } from "@rsm-hcd/javascript-core";

const defaultValues: Job = RecordUtils.auditableDefaultValuesFactory<Job>({
    backgroundJobId: undefined,
    debugJson: undefined,
    endedOn: undefined,
    error: undefined,
    startedOn: undefined,
    status: undefined,
    workerArgs: undefined,
    workerName: undefined,
    startNow: undefined,
});

export default class JobRecord
    extends Record(defaultValues)
    implements Job, Auditable
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: Job) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    // #endregion Public Methods
}
