import { ClassNameConstants } from "constants/classname-constants";
import { XmlChangeNotationConstants } from "constants/xml-change-notation-constants";
import { ArticlePermalinkParams } from "interfaces/routing/route-params";
import { SectionDividerType } from "models/enumerations/section-divider-type";
import { SectionType } from "models/enumerations/section-type";
import SectionRecord from "models/view-models/section-record";
import PartSeparator from "molecules/part-separator/part-separator";
import SectionSpacer from "molecules/spacers/section-spacer";
import ChangeIndicatorDiffPanel from "organisms/panels/change-indicator-diff/change-indicator-diff-panel";
import TiaChangesPanel from "organisms/panels/tia-changes/tia-changes-panel";
import { VirtualListRowProps } from "organisms/virtual-list/virtual-list";
import * as React from "react";
import { forwardRef, Ref, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import useChangeIndicatorDiffPanel from "utilities/atoms/change-indicator-diff-panel/use-change-indicator-diff-panel";
import { useReferencePanelContext } from "utilities/contexts/reference-panel/use-reference-panel-context";
import useCurrentPublication from "utilities/contexts/use-current-publication";
import useTias from "utilities/hooks/domain/publications/use-tias";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import StringUtils from "utilities/string-utils";
import SectionDetail from "./section-detail";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface SectionDetailProps extends VirtualListRowProps {
    /**
     * Section to be displayed. **Note** If a part is meant to separate this section from another,
     * it should have the `part` navigation property populated by some means.
     * (For example, by `SectionCollectionRecord.getGroupedTopLevelSections`)
     */
    section: SectionRecord;

    /**
     * Flat list of Sections to display
     */
    sectionCollection: Array<SectionRecord>;

    /**
     * Is this SectionDetail being rendered in a VirtualList? (Applies the '-virtualized' className
     * if true)
     */
    virtualized?: boolean;
    allowSectionDetailActions?: boolean;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

// const SECTION_LEVEL_0_CLASS_NAME = "c-section-detail";
// const SECTION_LEVEL_1_CLASS_NAME = "c-sub-section-detail";
// const SECTION_LEVEL_2_CLASS_NAME = "c-sub-sub-section-detail";

const BASE_CSS_CLASSNAME = "c-section-wrapper";

export const SectionLevelClassNames = {
    zero: "c-section-detail",
    one: "c-sub-section-detail",
    two: "c-sub-sub-section-detail",
};

export const LevelModifiers = {
    zero: "-level-zero",
    one: "-level-one",
    two: "-level-two",
};

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SectionDetailWrapper = forwardRef<HTMLElement, SectionDetailProps>(
    (props: SectionDetailProps, ref: Ref<HTMLElement>) => {
        const { allowSectionDetailActions = true, section } = props;

        const sectionRef = useRef(null);

        const { useReducedRendersInBookview } = useFeatureFlags();

        const [showContents, setShowContents] = useState(
            !useReducedRendersInBookview
        );

        const { part } = section;

        const { publication } = useCurrentPublication();

        // Observes entire section, and shows its contents if on screen
        const sectionObserver = useReducedRendersInBookview
            ? new IntersectionObserver(
                  (entries) => {
                      entries.forEach((entry) => {
                          if (entry.isIntersecting && !showContents) {
                              setShowContents(true);
                              sectionObserver?.disconnect();
                          }
                      });
                  },
                  {
                      root: null,
                      threshold: 0,
                  }
              )
            : null;

        // Attaches observer once section is available
        useEffect(() => {
            if (sectionRef.current && useReducedRendersInBookview) {
                sectionObserver?.observe(sectionRef.current!);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [sectionRef]);

        const {
            isOpen: isReferencePanelOpen,
            handleClose: closeReferencePanel,
        } = useReferencePanelContext();

        const {
            showDiffPanel,
            handleOpen,
            handleClose: handleChangeIndicatorClose,
            content: diffPanelContent,
        } = useChangeIndicatorDiffPanel();

        const {
            currentTia,
            tias,
            handleTiaClick,
            handleClose: handleTiaPanelClose,
            closePanels,
            isTiaPanelOpen,
            currentExternalId: tiaExternalId,
        } = useTias(section?.externalId);

        const { cssBaseClass, levelModifier } = useMemo(() => {
            switch (section.type) {
                case SectionType.Section:
                    return {
                        cssBaseClass: SectionLevelClassNames.zero,
                        levelModifier: LevelModifiers.zero,
                    };
                case SectionType.SubSection:
                    return {
                        cssBaseClass: SectionLevelClassNames.one,
                        levelModifier: LevelModifiers.one,
                    };
                case SectionType.SubSubSection:
                default:
                    return {
                        cssBaseClass: SectionLevelClassNames.two,
                        levelModifier: LevelModifiers.two,
                    };
            }
        }, [section.type]);
        const classNames = [cssBaseClass];

        // adding this margin will make the panel header visible for section changes in chapter 9
        const { chapterNfpaLabel, code } = useParams<ArticlePermalinkParams>();
        const style =
            levelModifier === LevelModifiers.zero &&
            chapterNfpaLabel === "9" &&
            code === "70" &&
            (showDiffPanel || isTiaPanelOpen)
                ? { margin: "4rem 0" }
                : {};

        const titleClassName: string = `${cssBaseClass}__title ${section.getChangesClassModifier()}`;
        const { prependLabelToSectionTitle } = useFeatureFlags();

        const sectionLabel = useMemo(
            () =>
                getLabel({
                    sectionLabel: section.getLabel(),
                    sectionTitleHasLabel:
                        publication?.sectionTitleHasLabel ?? true,
                    includeSectionLabel:
                        publication?.includeSectionLabel() ?? false,
                    prependLabelToSectionTitle,
                }),
            [publication, prependLabelToSectionTitle, section]
        );

        const handleDiffPanelOpen = React.useCallback(() => {
            if (isReferencePanelOpen) closeReferencePanel();
            if (isTiaPanelOpen) closePanels();
            handleOpen({
                externalId: section.externalId,
                titleClassName: titleClassName,
                title: section.getTitle(),
                body: section.getBody(),
                changes: section.changes,
                label: sectionLabel,
            });
        }, [
            closePanels,
            closeReferencePanel,
            handleOpen,
            isReferencePanelOpen,
            isTiaPanelOpen,
            section,
            sectionLabel,
            titleClassName,
        ]);

        const handleTiaPanelOpen = React.useCallback(
            (tiaExternalId: string) => {
                if (showDiffPanel) handleChangeIndicatorClose();
                handleTiaClick(tiaExternalId);
            },
            [handleChangeIndicatorClose, handleTiaClick, showDiffPanel]
        );

        const deleted = section.changes === XmlChangeNotationConstants.DELETION;
        const deletedClassName = deleted ? ClassNameConstants.Deleted : "";

        if (deleted) {
            classNames.push(deletedClassName);
        }

        return (
            <div
                className={`${BASE_CSS_CLASSNAME}__fragment ${
                    showContents ? "" : "-hidden"
                }`}
                ref={sectionRef}>
                {part != null && (
                    <PartSeparator key={part.id} part={part}>
                        {part.getBody()}
                    </PartSeparator>
                )}
                <section
                    // TODO: metanav section anchors use the same id
                    id={section.id?.toString()}
                    // id={section.externalId}
                    data-root-section-id={section.rootSectionId}
                    className={`${BASE_CSS_CLASSNAME} ${deletedClassName} ${levelModifier}`}
                    data-test-display-sequence={section.displaySequence}
                    data-test-part-id={section.partId}
                    ref={ref}
                    style={style}>
                    <SectionDetail
                        allowSectionDetailActions={allowSectionDetailActions}
                        section={section}
                        tias={tias}
                        cssBaseClass={cssBaseClass}
                        classNames={classNames}
                        handleDiffPanelOpen={handleDiffPanelOpen}
                        handleTiaPanelOpen={handleTiaPanelOpen}
                        diffPanelContent={diffPanelContent}
                        sectionLabel={sectionLabel}
                        tiaExternalId={tiaExternalId}
                        titleClassName={titleClassName}
                        renderContent={showContents}
                    />
                    <SectionSpacer
                        type={getSpacerType(section, props.sectionCollection)}
                    />
                    {diffPanelContent.externalId === section.externalId && (
                        <ChangeIndicatorDiffPanel />
                    )}
                    {section.externalId === tiaExternalId && (
                        <TiaChangesPanel
                            tias={tias}
                            handleClose={handleTiaPanelClose}
                            title={
                                <span className={titleClassName}>
                                    <span>
                                        {sectionLabel}
                                        {currentTia.getTitle()}
                                    </span>
                                </span>
                            }
                            body={
                                <div className={`${cssBaseClass}__body`}>
                                    {currentTia.getBody()}
                                </div>
                            }
                        />
                    )}
                </section>
            </div>
        );
    }
);

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------
export default SectionDetailWrapper;

// #endregion Export

// -----------------------------------------------------------------------------------------
// #region Private Functions
// -----------------------------------------------------------------------------------------

const getSpacerType = (
    currentSection: SectionRecord,
    sections: SectionRecord[]
): SectionDividerType => {
    const index = sections.indexOf(currentSection);
    const nextSection = sections[index + 1];

    // Pad the bottom of the document
    if (index === sections.length - 1) {
        return SectionDividerType.Medium;
    }

    if (
        currentSection.isDeleted() &&
        currentSection.type! <= SectionType.SubSection
    ) {
        return SectionDividerType.Small;
    }

    // If next section is Level 0, add divider with rule
    if (nextSection.type === SectionType.Section) {
        if (nextSection.part != null) {
            return SectionDividerType.Medium;
        }

        return SectionDividerType.Large;
    }

    if (
        currentSection.type! >= SectionType.SubSubSection &&
        nextSection.type! >= SectionType.SubSubSection
    ) {
        return SectionDividerType.None;
    }

    if (currentSection.type! >= nextSection.type!) {
        return SectionDividerType.Small;
    }

    return SectionDividerType.None;
};

interface GetLabelProps {
    sectionLabel: string;
    sectionTitleHasLabel: boolean;
    includeSectionLabel: boolean;
    prependLabelToSectionTitle: boolean;
}

const getLabel = ({
    sectionLabel,
    sectionTitleHasLabel,
    includeSectionLabel,
    prependLabelToSectionTitle,
}: GetLabelProps) => {
    if (prependLabelToSectionTitle) {
        if (sectionTitleHasLabel) {
            return;
        }

        return <React.Fragment>{`${sectionLabel} `}</React.Fragment>;
    } else {
        if (StringUtils.hasValue(sectionLabel) && includeSectionLabel) {
            return <React.Fragment>{`${sectionLabel} `}</React.Fragment>;
        }

        return;
    }
};

// #endregion Private Functions
