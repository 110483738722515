import { Icons } from "atoms/constants/icons";
import AlertLevels from "constants/alert-levels";
import AlertNotice from "molecules/banners/alert-notice";
import React from "react";
import { useLocalization } from "utilities/hooks/use-localization";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface OfflineAlertBannerProps {
    buttonText: string;
    onClick: VoidFunction;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const DATA_TEST_ID = "offline-alert-banner";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const OfflineAlertBanner: React.FC<OfflineAlertBannerProps> = (
    props: OfflineAlertBannerProps
) => {
    const { buttonText, onClick } = props;
    const { t } = useLocalization();
    return (
        <AlertNotice
            buttonText={buttonText}
            dataTestId={DATA_TEST_ID}
            description={t("offline-fallback-description")}
            icon={Icons.WifiOffline}
            level={AlertLevels.Info}
            onClick={onClick}
            title={t("offline-fallback-title")}
        />
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default OfflineAlertBanner;

// #endregion Exports
