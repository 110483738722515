import { RecordUtils } from "@rsm-hcd/javascript-core";
import { CollectionUtils } from "utilities/collection-utils";
import { Record } from "immutable";
import type TableOfContents from "models/interfaces/table-of-contents/table-of-contents";
import AnnexTableOfContentsRecord from "./annex-table-of-contents-record";
import ChapterTableOfContentsRecord from "./chapter-table-of-contents-record";
import PublicationMetadataRecord from "../publication-metadata-record";

const defaultValues: TableOfContents =
    RecordUtils.defaultValuesFactory<TableOfContents>({
        annexes: undefined,
        chapters: undefined,
        publicationMetadata: undefined,
    });

export default class TableOfContentsRecord
    extends Record(defaultValues)
    implements TableOfContents
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: TableOfContents) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (CollectionUtils.hasValues(params.annexes)) {
            params.annexes = RecordUtils.ensureRecords(
                params.annexes,
                AnnexTableOfContentsRecord
            );
        }

        if (CollectionUtils.hasValues(params.chapters)) {
            params.chapters = RecordUtils.ensureRecords(
                params.chapters,
                ChapterTableOfContentsRecord
            );
        }

        if (CollectionUtils.hasValues(params.publicationMetadata)) {
            params.publicationMetadata = RecordUtils.ensureRecords(
                params.publicationMetadata,
                PublicationMetadataRecord
            );
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<TableOfContentsRecord>} values
     */
    public with(values: Partial<TableOfContents>): TableOfContentsRecord {
        return new TableOfContentsRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}
