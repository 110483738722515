import ServiceFactory, {
    ListService,
} from "utilities/services/service-factory";
import ExternalTopicRecord from "models/view-models/external-topic-record";
import axios from "axios";
import { ServiceResponse } from "@rsm-hcd/javascript-core";
import { LocalStorageUtils } from "../../local-storage-utils";
import LocalStorageKey from "../../enumerations/local-storage-keys";
import Topic from "../../../models/interfaces/topic";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface ExternalTopicBasePathParams {}
export interface ExternalTopicPutDto {
    externalTopicIdentifiers: number[];
}

export interface ExternalTopicResourcePathParams
    extends ExternalTopicBasePathParams {
    id: number;
}

/**
 * Search parameters when listing ExternalTopic resources
 * These get translated to QueryString parameters
 */
export interface ExternalTopicListQueryParams {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const baseEndpoint = "externaltopics";
const updateSelectedEndpoint = baseEndpoint + "/selected";
const resourceType = ExternalTopicRecord;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Service
// -----------------------------------------------------------------------------------------

const networkOnlyListStrategy = ServiceFactory.list<
    ExternalTopicRecord,
    ExternalTopicListQueryParams
>(resourceType, baseEndpoint);

const localStorageBackupListStrategy: ListService<
    ExternalTopicRecord,
    ExternalTopicListQueryParams
> = async (
    queryParams?: ExternalTopicListQueryParams
): Promise<ServiceResponse<ExternalTopicRecord>> => {
    const externalResponse = await networkOnlyListStrategy(queryParams);

    if (externalResponse.status === 200 && externalResponse.rowCount)
        return externalResponse;

    const localExternalTopics = LocalStorageUtils.get<Topic[]>(
        LocalStorageKey.ExternalTopics
    );
    if (localExternalTopics == null) return externalResponse;

    const localExternalTopicsRecords = localExternalTopics.map(
        (x) =>
            new ExternalTopicRecord({
                id: x.externalIdentifier ?? -1,
                name: x.name,
            })
    );
    const localResponse: ServiceResponse<ExternalTopicRecord> = {
        resultObjects: localExternalTopicsRecords,
        rowCount: localExternalTopicsRecords.length,
        status: 200,
    };
    return localResponse;
};

const ExternalTopicService = {
    /**
     * Custom hook for leveraging service list calls in react components
     */
    list: localStorageBackupListStrategy,

    updateSelected: async (selectedTopics: Topic[]): Promise<boolean> => {
        LocalStorageUtils.set(LocalStorageKey.ExternalTopics, selectedTopics);

        const data: ExternalTopicPutDto = {
            externalTopicIdentifiers: selectedTopics.map(
                (x) => x.externalIdentifier ?? -1
            ),
        };
        return await axios.put(updateSelectedEndpoint, data);
    },
};

// #endregion Service

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default ExternalTopicService;

// #endregion Exports
