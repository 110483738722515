import Breadcrumb from "atoms/breadcrumbs/breadcrumb";
import React from "react";
import { match } from "react-router-dom";
import { useHeaderData } from "utilities/contexts/use-header-data-context";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import { Translator } from "utilities/types/translator-type";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const translatePageTitle = (t: Translator<CultureResources>) =>
    t("collection_plural");

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface MyLinkCollectionsBreadcrumbProps {
    match: match;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const MyLinkCollectionsBreadcrumb: React.FC<
    MyLinkCollectionsBreadcrumbProps
> = (props: MyLinkCollectionsBreadcrumbProps) => {
    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    const { match } = props;

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Hooks
    // -----------------------------------------------------------------------------------------

    const { t } = useLocalization();

    useHeaderData({ match, title: translatePageTitle(t) });

    // #endregion Hooks

    // -----------------------------------------------------------------------------------------
    // #region Returns
    // -----------------------------------------------------------------------------------------

    return (
        <Breadcrumb>
            <span className="c-breadcrumb__label">{translatePageTitle(t)}</span>
        </Breadcrumb>
    );

    // #endregion Returns
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default MyLinkCollectionsBreadcrumb;

// #endregion Exports
