import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import React, { Dispatch, ReactNode, SetStateAction, useEffect } from "react";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import { useLocalization } from "utilities/hooks/use-localization";
import { v4 } from "uuid";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface SlidingDrawerProps {
    children: ReactNode;
    buttonAriaText: string;
    isOpen: boolean;
    cssClassName?: string;
    closedCssClassName?: string;
    onDrawerOpen: Dispatch<SetStateAction<void>>;
    onDrawerClose: Dispatch<SetStateAction<void>>;
}

// #endregion Interfaces

const SlidingDrawer: React.FunctionComponent<SlidingDrawerProps> = (
    props: SlidingDrawerProps
) => {
    const {
        children,
        buttonAriaText,
        isOpen,
        cssClassName = "c-image-viewer__left-sidebar",
        closedCssClassName = "-closed",
        onDrawerOpen,
        onDrawerClose,
    } = props;

    const { t } = useLocalization();

    // Opens/closes drawers if initialIsOpen changes (i.e., on a screen resize being watched by a parent

    const isTablet = useBreakpoint(
        Breakpoints.Tablet,
        BreakpointComparer.MaxWidth
    );

    const accessibleText = isOpen ? t("open") : t("close");
    const iconType = isOpen
        ? isTablet
            ? Icons.Close
            : Icons.ChevronLeft
        : Icons.ChevronRight;
    const accordionContentId = v4();
    const buttonId = v4();

    const handleClick = () => {
        isOpen ? onDrawerClose() : onDrawerOpen();
    };

    useEffect(() => {
        if (isTablet) {
            onDrawerClose();
        } else {
            onDrawerOpen();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTablet]);

    return (
        <div className={`${cssClassName} ${isOpen ? "" : closedCssClassName}`}>
            {children}
            <Button
                accessibleText={`${accessibleText} ${buttonAriaText}`}
                ariaControls={accordionContentId}
                ariaExpanded={!isOpen}
                cssClassName="c-image-viewer__sidebar-toggle"
                id={buttonId}
                onClick={handleClick}
                size={ButtonSizes.XSmall}
                style={ButtonStyles.Tertiary}>
                <Icon type={iconType} size={IconSizes.Medium} />
            </Button>
        </div>
    );
};

export default SlidingDrawer;
