import { ScrollUtils, ServiceResponse } from "@rsm-hcd/javascript-core";
import { useRef, useCallback, useEffect } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import useChangeIndicatorDiffPanel from "utilities/atoms/change-indicator-diff-panel/use-change-indicator-diff-panel";
import {
    GlobalStateUpdater,
    useGlobalState,
} from "utilities/contexts/use-global-state-context";
import NumberUtils from "utilities/number-utils";
import SystemSettingsService from "utilities/services/system-settings/system-settings-service";
import UserConfigurationService from "utilities/services/user-configurations/user-configuration-service";
import useRefreshIdentity from "../use-refresh-identity";
import { Location } from "history";
import GlobalStateRecord from "models/view-models/global-state-record";
import SystemSettingsRecord from "models/view-models/system-settings-record";
import UserLoginRecord from "models/view-models/user-login-record";
import useTias from "./publications/use-tias";
import AuthService from "utilities/services/auth/auth-service";
import useFeatureFlags from "../use-feature-flags";

const IDENTITY_REFRESH_INTERVAL = window.location.href.match(/local|working/)
    ? 60000
    : 3600000;

const PageViews = () => {
    const currentLocation = useRef("");
    const location = useLocation();
    const { globalState, setGlobalState } = useGlobalState();
    const { refresh: refreshIdentity } = useRefreshIdentity();
    const { currentIdentity } = globalState;
    const userLoginId = currentIdentity?.userLogin?.id ?? 0;
    const { handleClose, showDiffPanel } = useChangeIndicatorDiffPanel();
    const { closePanels } = useTias();
    const { refreshOnPageChange } = useFeatureFlags();

    const { get: getUserConfiguration } =
        UserConfigurationService.useGetQuery();
    const { get: getAuth } = AuthService.useGetQuery();
    const { get: getSystemSettings } = SystemSettingsService.useGetQuery();

    useQuery("refreshIdentity", refreshIdentity, {
        refetchInterval: IDENTITY_REFRESH_INTERVAL,
    });

    const { isLoading: loadingUserConfiguration } = getUserConfiguration(
        {},
        { setNewestUserRole: true },
        {
            enabled: isReturningFromNfpa(location),
            onSuccess: handleUserConfigurationSuccess(setGlobalState),
        }
    );

    const { refetch: refreshUserLogin } = getAuth(
        { id: userLoginId },
        undefined,
        {
            enabled: false,
            onSuccess: handleUserLoginSuccess(setGlobalState),
        }
    );

    const { refetch: refreshSystemSettings } = getSystemSettings(
        {},
        undefined,
        {
            enabled: false,
            onSuccess: handleSystemSettingsSuccess(globalState, setGlobalState),
        }
    );

    const refreshUserIdentity = useCallback(() => {
        if (loadingUserConfiguration || NumberUtils.isDefault(userLoginId)) {
            return;
        }

        if (isReturningFromNfpa(location)) {
            refreshIdentity();
            return;
        }

        if (refreshOnPageChange) {
            refreshUserLogin();
        }
    }, [
        loadingUserConfiguration,
        location,
        refreshIdentity,
        refreshOnPageChange,
        refreshUserLogin,
        userLoginId,
    ]);

    useEffect(() => {
        if (currentLocation.current === location.pathname) {
            return;
        }

        currentLocation.current = location.pathname;

        // Reset the focus back to the document root element on page change so tabbing
        // re-opens the Skip to content accessibility option
        setFocusToRoot();

        refreshUserIdentity();
        refreshSystemSettings();
    }, [location, refreshIdentity, refreshSystemSettings, refreshUserIdentity]);

    // Effect for scrolling to the hash in the route after the DOM has rendered.
    useEffect(() => {
        if (showDiffPanel) {
            handleClose();
        }

        closePanels();
        const timeout = setTimeout(() => {
            ScrollUtils.scrollToHash(location);
        }, 0);

        return () => clearTimeout(timeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return null;
};

export default PageViews;

const isReturningFromNfpa = (location: Location<unknown>): boolean =>
    location.search.includes("fromNfpa");

const handleUserConfigurationSuccess =
    (setGlobalState: GlobalStateUpdater) => () =>
        setGlobalState((globalState: GlobalStateRecord) =>
            globalState.with({
                hasLoadedUserConfigurationsFromNfpa: true,
            })
        );

const handleUserLoginSuccess =
    (setGlobalState: GlobalStateUpdater) =>
    (response: ServiceResponse<UserLoginRecord>) =>
        setGlobalState((prev: GlobalStateRecord) =>
            prev.setIdentity(
                prev.currentIdentity?.withUserLogin(response.resultObject!)
            )
        );

const handleSystemSettingsSuccess =
    (globalState: GlobalStateRecord, setGlobalState: GlobalStateUpdater) =>
    (response: ServiceResponse<SystemSettingsRecord>) => {
        const currentSettings = globalState.systemSettings;
        const updateSettings = response.resultObject;

        const shouldUpdate =
            JSON.stringify(currentSettings) !== JSON.stringify(updateSettings);

        if (shouldUpdate)
            setGlobalState((prev: GlobalStateRecord) =>
                prev.with({
                    systemSettings: response.resultObject,
                    systemSettingsLoaded: true,
                })
            );
    };

const setFocusToRoot = () => {
    const root = document.getElementById("root");

    if (root != null) {
        root.tabIndex = -1;
        root.focus();
    }
};
