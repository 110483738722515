import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { HeadingPriority } from "atoms/constants/heading-priority";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import Heading from "atoms/typography/heading";
import { ModalProps } from "molecules/modals/modal";
import React from "react";
import { useLocalization } from "utilities/hooks/use-localization";

interface BaseModalHeaderProps extends Pick<ModalProps, "closeDialog"> {
    heading?: React.ReactNode;
    hideCloseButton?: boolean;
}
const BaseModalHeader: React.FunctionComponent<BaseModalHeaderProps> = (
    props: BaseModalHeaderProps
) => {
    const { t } = useLocalization();
    const showCloseButton = !props.hideCloseButton;
    return (
        <div className="c-modal__header">
            <Heading priority={HeadingPriority.Two}>{props.heading}</Heading>
            {showCloseButton && (
                <Button
                    accessibleText={t("closeItem", { item: t("dialog") })}
                    cssClassName={"-modal-close -icon -modal-close-tertiary"}
                    onClick={props.closeDialog}
                    size={ButtonSizes.Medium}
                    style={ButtonStyles.Tertiary}>
                    <Icon type={Icons.Close} size={IconSizes.Large} />
                </Button>
            )}
        </div>
    );
};

export default BaseModalHeader;
