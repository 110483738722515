import { Record } from "immutable";
import type ExternalTopic from "models/interfaces/external-topic";
import { RecordUtils } from "@rsm-hcd/javascript-core";

const defaultValues: ExternalTopic =
    RecordUtils.defaultValuesFactory<ExternalTopic>({
        id: 0,
        name: "",
    });

export default class ExternalTopicRecord
    extends Record(defaultValues)
    implements ExternalTopic
{
    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: ExternalTopic) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<ExternalTopic>} values
     */
    public with(values: Partial<ExternalTopic>): ExternalTopicRecord {
        return new ExternalTopicRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}
