import { Do, ResultRecord } from "@rsm-hcd/javascript-core";
import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import CreateGroupInvitationDtoRecord from "models/view-models/group-invitations/create-group-invitation-dto-record";
import GroupInvitationRecord from "models/view-models/group-invitations/group-invitation-record";
import GroupRecord from "models/view-models/group-record";
import TextAreaFormField from "molecules/form-fields/text-area-form-field";
import Moment from "moment";
import React, { SetStateAction, useEffect, useState } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import { useLocalization } from "utilities/hooks/use-localization";
import usePageErrors from "utilities/hooks/use-page-errors";
import GroupInvitationService, {
    GroupInvitationListQueryParams,
    GroupInvitationResourcePathParams,
} from "utilities/services/group-invitations/group-invitation-service";
import {
    DeleteService,
    NestedCreateService,
    NestedListService,
} from "utilities/services/service-factory";
import StringUtils from "utilities/string-utils";
import { ToastManager } from "utilities/toast/toast-manager";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface GroupInviteCardProps {
    group: GroupRecord;
    magicLinkUrlTemplate: string;
    /**
     * Optional flag to trigger a refresh of the Group Invitations list
     */
    refreshInvites?: boolean;
    existingUserRoleGroupCount: number;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CLASS_NAME = "c-group-invite-card";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const GroupInviteCard: React.FC<GroupInviteCardProps> = (
    props: GroupInviteCardProps
) => {
    const { existingUserRoleGroupCount } = props;
    const [pendingInvitations, setPendingInvitations] = useState<
        GroupInvitationRecord[]
    >([]);
    const [inviteEmail, setInviteEmail] = useState<string>("");
    const [emailError, setEmailError] = useState<string | undefined>(undefined);
    const [refreshInvitationsFlag, setRefreshInvitationsFlag] =
        useState<boolean>(false);
    const pendingInvites = pendingInvitations?.length ?? 0;
    const existingGroupCount = existingUserRoleGroupCount ?? 0;
    const totalLicenses = props.group?.totalLicenses ?? 0;
    const maxInvitesReached =
        existingGroupCount + pendingInvites >= totalLicenses;

    // -----------------------------------------------------------------------------------------
    // #region Hooks
    // -----------------------------------------------------------------------------------------

    const { t } = useLocalization();
    const { handlePageLoadError } = usePageErrors();
    const { isOnline } = useNetworkInformation();

    const { create: createGroupInvitationsApi } =
        GroupInvitationService.useCreate();
    const { delete: deleteGroupInvitationsApi } =
        GroupInvitationService.useDelete();
    const { list: listGroupInvitationsApi } = GroupInvitationService.useList();

    useEffect(() => {
        listGroupInvitations(
            props.group?.id ?? 0,
            handlePageLoadError,
            listGroupInvitationsApi,
            setPendingInvitations
        );
    }, [
        props.group,
        props.refreshInvites,
        handlePageLoadError,
        listGroupInvitationsApi,
        refreshInvitationsFlag,
    ]);
    // #endregion Hooks

    // -----------------------------------------------------------------------------------------
    // #region Utilities
    // -----------------------------------------------------------------------------------------

    const ERROR_CANCELING_INVITATION_MESSAGE = t(
        "groupInvite-cancelingInvitationError"
    );
    const ERROR_SENDING_INVITATION_MESSAGE = t(
        "groupInvite-sendInvitationError"
    );

    const SUCCESS_CANCELING_INVITATION_MESSAGE = t(
        "groupInvite-cancelingInvitationSuccess"
    );

    const INVITATION_INPUT_HELPER_TEXT = t(
        "groupInvite-invitationInputHelperText"
    );

    // Remove empty and duplicate emails.
    const filterEmails = (emails: Array<string>): Array<string> => {
        return emails.filter(
            (email, id) =>
                StringUtils.hasValue(email) && emails.indexOf(email) === id
        );
    };

    const validateEmails = (emails: Array<string>): boolean => {
        if (CollectionUtils.isEmpty(emails)) {
            setEmailError(t("email-required-field"));
            return false;
        }

        if (emails.length === 1 && !StringUtils.isValidEmail(emails[0])) {
            setEmailError(t("invalid-email-field"));
            return false;
        }

        const invalidEmails: string[] = [];
        emails.forEach((e) => {
            const isValid = StringUtils.isValidEmail(e);
            if (!isValid) {
                invalidEmails.push(e);
            }
        });

        if (CollectionUtils.hasValues(invalidEmails)) {
            setEmailError(
                t("invalid-emails-field", { emails: invalidEmails.join() })
            );
            return false;
        }

        setEmailError(undefined);
        return true;
    };

    const formatSuccessToastMessage = (emails: Array<string>): string => {
        if (CollectionUtils.isEmpty(emails)) {
            return "";
        }

        const singluarRecipient = t("groupInvite-sentToRecipient", {
            recipient: emails[0],
        });
        const pluralRecipients = t("groupInvite-sentToRecipient_plural");

        return emails.length > 1 ? pluralRecipients : singluarRecipient;
    };

    const handleInviteClick = () => {
        const emails = inviteEmail.split(",").map((e) => e.trim());
        const filteredEmails = filterEmails(emails);
        const validEmails = validateEmails(filteredEmails);

        if (!validEmails) {
            return;
        }

        const successToastMessage = formatSuccessToastMessage(filteredEmails);

        createGroupInvitation(
            props.group.id!,
            filteredEmails,
            props.magicLinkUrlTemplate,
            successToastMessage,
            ERROR_SENDING_INVITATION_MESSAGE,
            createGroupInvitationsApi,
            handlePageLoadError,
            listGroupInvitationsApi,
            setEmailError,
            setInviteEmail,
            setPendingInvitations
        );
    };

    const handleResendInvitation = async (
        email: string,
        groupId: number,
        groupInvitationId: number
    ) => {
        // Delete the current invitation.
        const deleteInvitationResult = await deleteGroupInvitation(
            ERROR_CANCELING_INVITATION_MESSAGE,
            groupId,
            groupInvitationId,
            deleteGroupInvitationsApi
        );

        if (!deleteInvitationResult) {
            return;
        }

        const successToastMessage = formatSuccessToastMessage([email]);

        // After deleting the current invitation, resend a new invitation.
        createGroupInvitation(
            groupId,
            [email],
            props.magicLinkUrlTemplate,
            successToastMessage,
            ERROR_SENDING_INVITATION_MESSAGE,
            createGroupInvitationsApi,
            handlePageLoadError,
            listGroupInvitationsApi,
            setEmailError,
            setInviteEmail,
            setPendingInvitations
        );
    };

    const onCancelInvitationSuccess = () => {
        setRefreshInvitationsFlag(
            (refreshInvitationsFlag) => !refreshInvitationsFlag
        );
        ToastManager.success(SUCCESS_CANCELING_INVITATION_MESSAGE);
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
        setInviteEmail(e.currentTarget.value);
    const invitesRemaining =
        totalLicenses - (pendingInvites + existingGroupCount);

    // #endregion Utilities

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    return (
        <div className={CLASS_NAME}>
            <div className={`${CLASS_NAME}__content`}>
                <span className="-title">{t("inviteTeamMembers")}</span>
                <span className="-remaining">
                    {t("remainingInvitesMessage", {
                        count: invitesRemaining,
                        pending: t("pendingInvitiationsMessage", {
                            count: pendingInvites,
                        }),
                    })}
                </span>
                {!maxInvitesReached && (
                    <div
                        className={`${CLASS_NAME}__content__email${
                            StringUtils.hasValue(emailError) ? " -invalid" : ""
                        }`}>
                        <TextAreaFormField
                            disabled={!isOnline}
                            errorMessage={emailError}
                            isValid={StringUtils.isEmpty(emailError)}
                            label={""}
                            placeholder={t("teamInvitePlaceholder")}
                            onChange={handleOnChange}
                            helperText={INVITATION_INPUT_HELPER_TEXT}
                            rows={1}
                            value={inviteEmail}
                        />
                        <Button
                            disabled={!isOnline}
                            onClick={handleInviteClick}>
                            {t("invite")}
                        </Button>
                    </div>
                )}
            </div>
            {CollectionUtils.hasValues(pendingInvitations) && (
                <div className={`${CLASS_NAME}__pending`}>
                    <div className={`${CLASS_NAME}__pending__title`}>
                        {t("pendingInvitations")}
                    </div>
                    <div className={`${CLASS_NAME}__pending__content`}>
                        {pendingInvitations?.map((pi) => (
                            <div
                                className={`${CLASS_NAME}__pending__content__row`}
                                key={pi.id}>
                                <span
                                    className={`${CLASS_NAME}__pending__content__row__email`}>
                                    {pi.email}
                                </span>
                                <div
                                    className={`${CLASS_NAME}__pending__content__row__actions`}>
                                    <div
                                        className={`${CLASS_NAME}__pending__content__row__actions__info`}>
                                        <span>{t("invited")} </span>
                                        <span>
                                            {Moment(pi.createdOn).fromNow()}
                                        </span>
                                    </div>
                                    <div
                                        className={`${CLASS_NAME}__pending__content__row__actions__buttons`}>
                                        <Button
                                            disabled={!isOnline}
                                            onClick={() =>
                                                deleteGroupInvitation(
                                                    ERROR_CANCELING_INVITATION_MESSAGE,
                                                    pi.groupId!,
                                                    pi.id!,
                                                    deleteGroupInvitationsApi,
                                                    onCancelInvitationSuccess
                                                )
                                            }
                                            size={ButtonSizes.Small}
                                            style={ButtonStyles.Destructive}>
                                            {t("cancel")}
                                        </Button>
                                        <Button
                                            disabled={!isOnline}
                                            onClick={() =>
                                                handleResendInvitation(
                                                    pi.email!,
                                                    pi.groupId!,
                                                    pi.id!
                                                )
                                            }
                                            size={ButtonSizes.Small}
                                            style={ButtonStyles.Secondary}>
                                            {t("resend")}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
    // #endregion Render
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Api Calls
// -----------------------------------------------------------------------------------------

/**
 * Create a GroupInvitation and refresh the list of invitations
 * @param groupId
 * @param email
 * @param magicLinkUrlTemplate
 * @param successToastMessage
 * @param errorSendingInvitationMessage
 * @param createApi
 * @param handlePageLoadError
 * @param listGroupInvitationsApi
 * @param setEmailError
 * @param setInviteEmail
 * @param setPendingInvitations
 */
const createGroupInvitation = async (
    groupId: number,
    emails: string[],
    magicLinkUrlTemplate: string,
    successToastMessage: string,
    errorSendingInvitationMessage: string,
    createApi: NestedCreateService<
        CreateGroupInvitationDtoRecord,
        GroupInvitationResourcePathParams
    >,
    handlePageLoadError: (result: any) => void,
    listGroupInvitationsApi: NestedListService<
        GroupInvitationRecord,
        GroupInvitationResourcePathParams,
        GroupInvitationListQueryParams
    >,
    setEmailError: React.Dispatch<SetStateAction<string | undefined>>,
    setInviteEmail: React.Dispatch<SetStateAction<string>>,
    setPendingInvitations: React.Dispatch<
        SetStateAction<GroupInvitationRecord[]>
    >
) =>
    Do.try(async () => {
        const dto = new CreateGroupInvitationDtoRecord({
            emails: emails,
            groupId: groupId,
            magicLinkUrlTemplate: magicLinkUrlTemplate,
        });
        const response = await createApi(dto, {
            groupId: groupId,
        });

        if (response.result?.hasErrors()) {
            ToastManager.error(errorSendingInvitationMessage);
            return;
        }

        setInviteEmail("");
        await listGroupInvitations(
            groupId!,
            handlePageLoadError,
            listGroupInvitationsApi,
            setPendingInvitations
        );

        ToastManager.success(successToastMessage);
    }).catch((result?: ResultRecord<GroupInvitationRecord>, error?: any) => {
        if (result == null) {
            handlePageLoadError(error);
            return;
        }

        const validationError = result.errors?.find((e) =>
            e.key?.startsWith("GroupInvitationValidationConductor")
        );
        if (validationError != null) {
            setEmailError(validationError.message);
            return;
        }

        ToastManager.error(errorSendingInvitationMessage);
    });

/**
 * Delete a GroupInvitation with an optional callback to refresh the list of invitations.
 * @param groupId
 * @param groupInvitationId
 * @param groupInvitationsDeleteApi
 * @param onCancelInvitationSuccess
 */
const deleteGroupInvitation = async (
    errorCancelingInvitationMessage: string,
    groupId: number,
    groupInvitationId: number,
    groupInvitationsDeleteApi: DeleteService,
    onCancelInvitationSuccess?: () => void
) => {
    try {
        const response = await groupInvitationsDeleteApi(groupInvitationId, {
            groupId: groupId,
        });

        if (response.result?.hasErrors()) {
            throw Error;
        }

        onCancelInvitationSuccess?.();

        return true;
    } catch (error) {
        ToastManager.error(errorCancelingInvitationMessage);
        return false;
    }
};

/**
 * Retrieve a list of pending GroupInvitations
 * @param groupId
 * @param handlePageLoadError
 * @param listGroupInvitationsApi
 * @param setPendingInvitations
 */
const listGroupInvitations = async (
    groupId: number,
    handlePageLoadError: (result: any) => void,
    listGroupInvitationsApi: NestedListService<
        GroupInvitationRecord,
        GroupInvitationResourcePathParams,
        GroupInvitationListQueryParams
    >,
    setPendingInvitations: React.Dispatch<
        SetStateAction<GroupInvitationRecord[]>
    >
) => {
    try {
        if (groupId === 0) {
            return;
        }

        const response = await listGroupInvitationsApi(
            {
                groupId: groupId,
            },
            {
                isPending: true,
            }
        );
        setPendingInvitations(response.resultObjects!);
    } catch (error) {
        handlePageLoadError(error);
    }
};

// #endregion Api Calls

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default GroupInviteCard;

// #endregion Exports
