import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Paragraph from "atoms/typography/paragraph";
import EnhancedContentResourceRecord from "models/view-models/enhanced-content-resource-record";
import ResourceCard, {
    ResourceCardStyle,
} from "organisms/situational-navigation/resources/resource-card";
import React from "react";
import { CollectionUtils } from "utilities/collection-utils";
import { useLocalization } from "utilities/hooks/use-localization";

interface EnhancedContentPanelResourcesProps {
    cssClassName: string;
    disableTooltip?: boolean;
    resources?: Array<EnhancedContentResourceRecord>;
    hasAdminAccess?: boolean;
}

const EnhancedContentPanelResources: React.FunctionComponent<
    EnhancedContentPanelResourcesProps
> = (props: EnhancedContentPanelResourcesProps) => {
    const { t } = useLocalization();
    const disableTooltip = props.disableTooltip;
    const hasAdminAccess = props.hasAdminAccess === true;
    if (CollectionUtils.isEmpty(props.resources)) {
        return null;
    }

    return (
        <div className={props.cssClassName}>
            <Paragraph size={ParagraphSizes.XSmall}>
                {t("relatedResource_plural")}
            </Paragraph>
            {props.resources!.map((resource: EnhancedContentResourceRecord) => (
                <ResourceCard
                    accessibleText={t(
                        "resourceSection-resourceCard-documents-accessibleText",
                        { title: resource.title }
                    )}
                    draftMode={hasAdminAccess}
                    disableTooltip={disableTooltip}
                    file={hasAdminAccess ? resource.fileDraft : resource.file}
                    key={resource.id!}
                    resource={resource}
                    style={ResourceCardStyle.Tile}
                />
            ))}
        </div>
    );
};

// Memoized to preserve the ResourceCards. State changes from parents cause images to 'flicker' otherwise.
export default React.memo(EnhancedContentPanelResources);
