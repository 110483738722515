import { CategoryType } from "models/enumerations/situational-navigation/categories/category-type";
import CategoryRecord from "models/view-models/situational-navigation/categories/category-record";
import { PagedQuery } from "@rsm-hcd/javascript-core";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AdminCategoryQueryParams extends PagedQuery {
    searchText?: string;
    situationId?: number;
    solutionId?: number;
    type?: CategoryType;
}

export interface AdminCategoryPathParams {
    id: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = "admin/situational-navigation/categories";
const resourceEndpoint = "admin/situational-navigation/categories/:id";
const resourceType = CategoryRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const AdminCategoryService = {
    create: ServiceFactory.create<CategoryRecord>(resourceType, baseEndpoint),
    useCreate: ServiceHookFactory.useCreate<CategoryRecord>(
        resourceType,
        baseEndpoint
    ),
    delete: ServiceFactory.delete<AdminCategoryPathParams>(resourceEndpoint),
    useDelete:
        ServiceHookFactory.useDelete<AdminCategoryPathParams>(resourceEndpoint),
    list: ServiceFactory.list<CategoryRecord, AdminCategoryQueryParams>(
        resourceType,
        baseEndpoint
    ),
    useList: ServiceHookFactory.useList<
        CategoryRecord,
        AdminCategoryQueryParams
    >(resourceType, baseEndpoint),
    update: ServiceFactory.update<CategoryRecord, AdminCategoryPathParams>(
        resourceType,
        resourceEndpoint
    ),
    useUpdate: ServiceHookFactory.useUpdate<
        CategoryRecord,
        AdminCategoryPathParams
    >(resourceType, resourceEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default AdminCategoryService;

// #endregion Exports
