import placeholder from "assets/images/nfpa-link-glyph-grayscale-large.png";
import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import Image from "atoms/images/image";
import RemoteImage from "atoms/images/remote-image";
import { PublicationCardProps } from "molecules/cards/publication-card";
import Tooltip, { TooltipPlacement } from "molecules/tooltips/tooltip";
import React from "react";
import { BrowserUtils } from "utilities/browser-utils";
import useUpdatePublicationFavorite from "utilities/hooks/domain/user-publication-favorites/use-update-publication-favorite";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import FileService from "utilities/services/file-service";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface PublicationCardSimpleProps extends PublicationCardProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-publication-card-simple";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const PublicationCardSimple: React.FC<PublicationCardSimpleProps> = (
    props: PublicationCardSimpleProps
) => {
    const { publication, favorites, onChangeFavorites } = props;
    const { get: getFileApi } = FileService.useGetQuery();
    const { t } = useLocalization();

    const getFileResult = getFileApi({
        id: publication.coverImageFileId!,
    });
    const imageFile = getFileResult.data?.resultObject;

    const {
        addFavorite,
        unfavorite,
        isFavorited,
        loading: loadingFavorites,
    } = useUpdatePublicationFavorite(publication, favorites, onChangeFavorites);

    const handleFavoriteClick = async () => {
        if (isFavorited) {
            await unfavorite();
            return;
        }

        await addFavorite();
    };

    const classNames = [CSS_CLASS_NAME];
    if (publication.coverImageFileId == null || imageFile == null) {
        classNames.push("-no-image");
    }

    if (isFavorited) {
        classNames.push("-selected");
    }

    const tooltipText = (key: keyof CultureResources) =>
        t(key, { container: t("favorite_plural").toLowerCase() });

    const favoriteTooltip = isFavorited
        ? tooltipText("removeItemFrom")
        : tooltipText("addItemTo");

    const favoriteTooltipDisabled =
        BrowserUtils.isTouchscreenDevice() || loadingFavorites;
    const favoriteButtonIcon = isFavorited
        ? Icons.StarFilled
        : Icons.StarOutline;
    const favoriteAccessibleText = isFavorited
        ? t("favorited")
        : t("notFavorited");

    const cssClassNames = classNames.join(" ");

    return (
        <React.Fragment>
            <Button
                style={ButtonStyles.None}
                cssClassName={cssClassNames}
                onClick={handleFavoriteClick}
                accessibleText={`${publication.getDisplayCodeAndEdition()} ${favoriteAccessibleText}`}>
                <div className={`${CSS_CLASS_NAME}__image-container`}>
                    {imageFile != null ? (
                        <RemoteImage
                            file={imageFile}
                            altText={publication.getDisplayCodeEditionAndTitle()}
                        />
                    ) : (
                        <Image
                            src={placeholder}
                            cssClassName=""
                            altText={publication.getDisplayCodeEditionAndTitle()}
                        />
                    )}
                </div>

                <div className={`${CSS_CLASS_NAME}__favorite-icon`}>
                    <Tooltip
                        content={favoriteTooltip}
                        disabled={favoriteTooltipDisabled}
                        placement={TooltipPlacement.Top}>
                        <Icon type={favoriteButtonIcon} />
                    </Tooltip>
                </div>
            </Button>
        </React.Fragment>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default PublicationCardSimple;

// #endregion Exports
