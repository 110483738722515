import Anchor from "atoms/anchors/anchor";
import Button, { ButtonTypes } from "atoms/buttons/button";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import MultiSelect from "atoms/forms/multi-select";
import { SelectOption } from "atoms/forms/select";
import Paragraph from "atoms/typography/paragraph";
import SolutionRecord from "models/view-models/situational-navigation/solutions/solution-record";
import ListBox, { ListBoxItem } from "molecules/lists/list-box";
import { SituationEditorContentAreaId } from "organisms/admin/situational-navigation/situations/situation-editor";
import RelatedSolutionDescription from "organisms/admin/situational-navigation/solutions/related-solution-description";
import RelatedSolutionTitle from "organisms/admin/situational-navigation/solutions/related-solution-title";
import { SolutionEditorContentAreaId } from "organisms/admin/situational-navigation/solutions/solution-editor";
import React, { Reducer, useEffect, useReducer, useState } from "react";
import { siteMap } from "internal-sitemap";
import useDebounce from "utilities/hooks/use-debounce";
import { RouteUtils } from "utilities/route-utils";
import AdminSolutionService from "utilities/services/admin/situational-navigation/solutions/admin-solution-service";
import StringUtils from "utilities/string-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import { AnchorTargetTypes } from "@rsm-hcd/javascript-core";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const SEARCH_SOLUTION_ERROR = "There was an issue searching solutions.";
const OPTION_LIST_SIZE = 20;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AdminRelatedSolutionsSectionProps {
    /**
     * Null if editor is in create mode, or if used in
     * the situation editor (as opposed to solution editor);
     * if editing an existing solution, this will be the
     * ID of that solution.
     */
    currentSolutionId?: number;
    dropdownPortal?: HTMLElement;
    onRemoved: (removedValue: SolutionRecord) => void;
    onSelected: (newValue: SolutionRecord) => void;
    value: Array<SolutionRecord>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AdminRelatedSolutionsSection: React.FC<
    AdminRelatedSolutionsSectionProps
> = (props: AdminRelatedSolutionsSectionProps) => {
    const CSS_CLASS_NAME = "c-admin-related-solutions-section";

    const { list: listSolutionsApi } = AdminSolutionService.useList();

    const [loading, setLoading] = useState(false);
    const [selectedSln, setSelectedSln] = useState<SolutionRecord>();
    const [searchText, setSearchText] = useState("");
    const debouncedText = useDebounce(searchText);

    /**
     * using useReducer here so that we can filter the new values
     * by the already selected values without adding props.value
     * to the dependency array in the useEffect that triggers the API,
     * because that would cause undesired behavior, including hitting the API
     * twice when it only needs to be hit once.
     */
    const [solutionOptions, setSolutionOptions] = useReducer<
        Reducer<Array<SolutionRecord>, Array<SolutionRecord>>
    >(
        (
            currentValue: Array<SolutionRecord>,
            newValues: Array<SolutionRecord>
        ): Array<SolutionRecord> => {
            return (newValues ?? []).filter(
                // filter out ones we already have selected
                (s: SolutionRecord) =>
                    !props.value.some(
                        (selected: SolutionRecord) => s.id === selected.id
                    ) &&
                    // filter out the current solution
                    s.id !== props.currentSolutionId
            );
        },
        []
    );

    useEffect(() => {
        const searchSolutions = async () => {
            setSolutionOptions([]);
            setLoading(true);
            try {
                const result = await listSolutionsApi({
                    searchText: debouncedText,
                    take: OPTION_LIST_SIZE,
                });
                setSolutionOptions(result.resultObjects!);
            } catch (e) {
                ToastManager.error(SEARCH_SOLUTION_ERROR);
                setSolutionOptions([]);
            }
            setLoading(false);
        };

        if (StringUtils.hasValue(debouncedText)) {
            searchSolutions();
        }
    }, [debouncedText, props.currentSolutionId, setLoading, listSolutionsApi]);

    useEffect(() => {
        if (StringUtils.isEmpty(searchText) && selectedSln == null) {
            // if they have cleared search text without selecting
            // an option, clear the options (they will be performing
            // a new search)
            setSolutionOptions([]);
        }
    }, [searchText, selectedSln]);

    const getOptions = (): Array<SelectOption<SolutionRecord>> =>
        solutionOptions.map((s: SolutionRecord) => ({
            data: s,
            label: s.titleDraft,
            value: s.id!.toString(),
        }));

    const renderOption = (option: SelectOption<SolutionRecord>) => (
        <div className={`${CSS_CLASS_NAME}__select-container__option`}>
            <div
                className={`${CSS_CLASS_NAME}__select-container__option__title`}>
                {option.data?.titleDraft}
            </div>
            <div
                className={`${CSS_CLASS_NAME}__select-container__option__subtitle`}>
                {option.data?.subtitleDraft}
            </div>
        </div>
    );

    const listBoxItems = (): Array<ListBoxItem<number>> =>
        props.value.map((s: SolutionRecord) => ({
            id: s.id!,
            text: (
                <React.Fragment>
                    <Anchor
                        ariaLabel={`Go to Solution "${s.titleDraft}"`}
                        target={AnchorTargetTypes.Blank}
                        to={RouteUtils.getUrl(
                            siteMap.situationalNavigation.solutions,
                            { id: s.id! }
                        )}>
                        <RelatedSolutionTitle>
                            {s.titleDraft}
                        </RelatedSolutionTitle>
                    </Anchor>
                    <RelatedSolutionDescription>
                        {s.subtitle}
                    </RelatedSolutionDescription>
                </React.Fragment>
            ),
        }));

    const handleSelected = (option: SelectOption) => {
        if (option != null) {
            setSelectedSln(
                solutionOptions.find(
                    (s: SolutionRecord) => s.id!.toString() === option.value
                )
            );
            return;
        }
        // select was cleared; clear search text, selected value, and option list
        setSearchText("");
        setSolutionOptions([]);
        setSelectedSln(undefined);
    };

    const handleApply = () => {
        if (selectedSln == null) {
            return;
        }

        props.onSelected(selectedSln!);
        setSolutionOptions([]);
        setSelectedSln(undefined);
    };

    const handleRemove = (id: number) => {
        const record = props.value.find(
            (section: SolutionRecord) => section.id === id
        );
        if (record != null) {
            props.onRemoved(record);
        }
    };

    const handleSearchTextChange = (newValue: string) => {
        // API will be called when search text changes
        // setLoading here prevents the "no options"
        // message in the dropdown from flickering before
        // the API call starts
        setLoading((newValue ?? "").length > 0);
        setSearchText(newValue);
    };

    const handleShouldCloseMenuOnScroll = (e: Event) =>
        e.target != null &&
        ((e.target as HTMLElement).id === SolutionEditorContentAreaId ||
            (e.target as HTMLElement).id === SituationEditorContentAreaId);

    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__select-container`}>
                <Paragraph size={ParagraphSizes.Large}>
                    Related Solutions
                </Paragraph>
                <MultiSelect
                    closeMenuOnScroll={handleShouldCloseMenuOnScroll}
                    closeMenuOnSelect={true}
                    dropdownPortal={props.dropdownPortal}
                    escapeClearsValue={true}
                    hideNoOptionsMessage={searchText.length === 0 || loading}
                    isMulti={false}
                    loading={loading}
                    loadingMessage="Searching solutions..."
                    onChange={handleSelected}
                    onSearchChange={handleSearchTextChange}
                    options={getOptions()}
                    placeholder="Search by solution title or description..."
                    renderOption={renderOption}
                    value={[selectedSln?.id?.toString() ?? ""]}>
                    <Button onClick={handleApply} type={ButtonTypes.Button}>
                        Add
                    </Button>
                </MultiSelect>
            </div>
            <ListBox
                actionText="Remove"
                items={listBoxItems()}
                onActionClick={handleRemove}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default AdminRelatedSolutionsSection;

// #endregion Exports
