import { RecordUtils } from "@rsm-hcd/javascript-core";
import { Record } from "immutable";
import {
    AnnexGroupParams,
    AnnexGroupPermalinkParams,
    AnnexGroupPreviewParams,
} from "interfaces/routing/route-params";
import { siteMap } from "internal-sitemap";
import AnnexGroupTableOfContents from "models/interfaces/table-of-contents/annex-group-table-of-contents";
import { CollectionUtils } from "utilities/collection-utils";
import { t } from "utilities/localization-utils";
import { RouteUtils } from "utilities/route-utils";
import SectionTableOfContentsRecord from "./section-table-of-contents-record";

const defaultValues: AnnexGroupTableOfContents =
    RecordUtils.defaultValuesFactory<AnnexGroupTableOfContents>({
        annexId: undefined,
        groupBy: "",
        hasBookmark: false,
        sections: [],
    });

export default class AnnexGroupTableOfContentsRecord
    extends Record(defaultValues)
    implements AnnexGroupTableOfContents
{
    constructor(params?: AnnexGroupTableOfContents) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (CollectionUtils.hasValues(params.sections)) {
            params.sections = RecordUtils.ensureRecords(
                params.sections,
                SectionTableOfContentsRecord
            );
        }

        super(params);
    }

    public getTitle() {
        const label = t("chapter");
        return `${label} ${this.groupBy}`;
    }

    public getRoute(params: AnnexGroupParams, isAdmin?: boolean) {
        return isAdmin
            ? this.getPreviewRoute(params)
            : this.getPermalinkRoute(params);
    }

    public with(
        values: Partial<AnnexGroupTableOfContents>
    ): AnnexGroupTableOfContentsRecord {
        return new AnnexGroupTableOfContentsRecord(
            Object.assign(
                this.toJS(),
                values
            ) as AnnexGroupTableOfContentsRecord
        );
    }

    private getPermalinkRoute(params: AnnexGroupPermalinkParams) {
        if (params.code == null || params.edition == null) return "#";

        return RouteUtils.getUrl(siteMap.publications.permalinks.annexGroup, {
            code: params.code,
            edition: params.edition,
            nfpaLabel: params.nfpaLabel,
            id: this.groupBy,
        });
    }

    private getPreviewRoute(params: AnnexGroupPreviewParams) {
        return RouteUtils.getUrl(siteMap.publications.adminPreview.annexGroup, {
            publicationId: params?.publicationId ?? params.id,
            annexId: this.annexId,
            id: this.groupBy,
        });
    }
}
