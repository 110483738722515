import { NfpaUrlPathsV2 } from "constants/nfpa-urls/nfpa-url-paths-v2";
import { replace } from "lodash";
import AccessDeniedPage from "pages/access-denied/access-denied-page";
import * as React from "react";
import {
    Redirect,
    Route,
    RouteComponentProps,
    useHistory,
    useLocation,
} from "react-router-dom";
import { routes } from "routes";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import RoleType from "utilities/enumerations/role-type";
import useIsNestedRoute from "utilities/hooks/routing/use-is-nested-route";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import { RouteDefinition } from "utilities/interfaces/route-definition";
import { RouteUtils } from "utilities/route-utils";
import { useUrlCallback } from "utilities/routing/url-callback/use-url-callback";

/*
-------------------------------------------------------------------------
Interfaces
-------------------------------------------------------------------------
*/

interface AuthenticatedRouteProps extends RouteComponentProps<any> {
    route: RouteDefinition;
    render: (props: any) => any;
}

/*
-------------------------------------------------------------------------
Components
-------------------------------------------------------------------------
*/

const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({
    render,
    route,
    ...rest
}) => {
    const { globalState } = useGlobalState();
    const isAuthenticated = globalState.isAuthenticated();
    const location = useLocation();
    const history = useHistory();
    const { useAzureB2CForSSO } = useFeatureFlags();
    const { routeIsFreeAccess } = useIsNestedRoute();
    const { setCallbackUrlParam } = useUrlCallback();

    const renderIfAuthenticated = (props: any): any => {
        const nfpaUrl = globalState.systemSettings?.getNfpaUrlV2(
            NfpaUrlPathsV2.Login
        );
        if (!isAuthenticated) {
            const callbackLink = RouteUtils.getUrl(
                location.pathname,
                null,
                location.search,
                location.hash
            );

            setCallbackUrlParam(callbackLink);

            if (!useAzureB2CForSSO && routeIsFreeAccess && nfpaUrl) {
                const newUrl = `${nfpaUrl}?cpg=${window.location.href}&npg=${window.location.href}`;
                history.replace(newUrl);
                return null;
            }

            if (route.unathenticatedRedirectToAzureB2C) {
                return (
                    <Redirect
                        to={{
                            pathname: routes.redirectToLogin.path,
                            state: { from: props.location },
                        }}
                    />
                );
            }
            return (
                <Redirect
                    to={{
                        pathname: routes.userlogins.routes.new.path,
                        state: { from: props.location },
                    }}
                />
            );
        }

        let allow = true;
        if (route.requiresRoles != null) {
            // checking all role types in array to see if any of them satisfy requirements
            allow = !route.requiresRoles.every(
                (roleType: RoleType) =>
                    !globalState.currentIdentity!.isCurrentRole(roleType)
            );
        }

        if (!allow) {
            return <AccessDeniedPage />;
        }

        return render(props);
    };

    return <Route {...rest} render={renderIfAuthenticated} />;
};

export default AuthenticatedRoute;
