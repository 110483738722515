import logo from "assets/images/logo-blue.png";
import Anchor from "atoms/anchors/anchor";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Paragraph from "atoms/typography/paragraph";
import { UnicodeCharacterConstants } from "constants/unicode-character-constants";
import { siteMap } from "internal-sitemap";
import moment from "moment";
import CultureDropdown from "organisms/culture-dropdown/culture-dropdown";
import React, { HTMLAttributes, PropsWithChildren } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import { useLocalization } from "utilities/hooks/use-localization";
import useNfpaUrlPaths from "utilities/hooks/use-nfpa-url-paths";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface UnauthenticatedFooterProps
    extends HTMLAttributes<HTMLDivElement> {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS = "c-unauthenticated-footer";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UnauthenticatedFooter: React.FC<
    PropsWithChildren<UnauthenticatedFooterProps>
> = (props: PropsWithChildren<UnauthenticatedFooterProps>) => {
    const { globalState } = useGlobalState();
    const { i18N } = useFeatureFlags();
    const { privacyUrl, termsUrl } = useNfpaUrlPaths();

    const { t } = useLocalization();

    // Terms of Use should link to the license agreement url
    const termsLinkText = t("termsOfUse");
    const privacyLinkText = t("privacyPolicy");
    const supportPageLinkText = t("support");

    if (globalState.currentIdentity?.isValid()) {
        return null;
    }

    return (
        <div className={BASE_CLASS}>
            <img src={logo} alt="Home" />
            {i18N && <CultureDropdown />}
            <div className={`${BASE_CLASS}__links`}>
                <Anchor to={termsUrl} external={true}>
                    {termsLinkText}
                </Anchor>
                <Anchor to={privacyUrl} external={true}>
                    {privacyLinkText}
                </Anchor>
                <Anchor to={siteMap.support.faq}>{supportPageLinkText}</Anchor>
            </div>
            <Paragraph size={ParagraphSizes.XSmall}>
                {UnicodeCharacterConstants.Copyright} National Fire Protection
                Association (NFPA) {moment().year()}
            </Paragraph>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default UnauthenticatedFooter;

// #endregion Exports
