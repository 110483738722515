import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { HeadingPriority } from "atoms/constants/heading-priority";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import { SelectOption } from "atoms/forms/select";
import Icon from "atoms/icons/icon";
import Heading from "atoms/typography/heading";
import PublicationRecord from "models/view-models/publication-record";
import CheckboxList from "molecules/checkbox-list/checkbox-list";
import { ModalCloseButtonStyle } from "molecules/constants/modal-close-button-style";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import { SearchFormStyles } from "molecules/enums/search-form-style";
import SearchForm from "molecules/forms/search-form";
import Modal, { ModalProps } from "molecules/modals/modal";
import React, { useMemo, useState } from "react";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useOfflinePublications from "utilities/hooks/domain/publications/use-offline-publications";
import usePublications from "utilities/hooks/domain/publications/use-publications";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import { useLocalization } from "utilities/hooks/use-localization";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

type IgnoredModalProps =
    | "closeButtonStyle"
    | "cssClassName"
    | "label"
    | "transition"
    | "type";

export interface PublicationFilterModalProps
    extends Omit<ModalProps, IgnoredModalProps> {
    selected: Array<number>;
    onSelection: (selectedIds: Array<number>) => void;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASS_NAME = "c-publication-filter-modal";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const PublicationFilterModal: React.FC<PublicationFilterModalProps> = (
    props: PublicationFilterModalProps
) => {
    const { closeDialog, selected, onSelection } = props;
    const { t } = useLocalization();
    const { isOnline } = useNetworkInformation();

    const [searchText, setSearchText] = useState("");
    const [submittedSearchText, setSubmittedSearchText] = useState("");
    const [currentSelected, setCurrentSelected] = useState(selected ?? []);

    const { resultObject: onlinePublications } = usePublications({
        searchText: submittedSearchText,
    });
    const { searchResults: offlinePublications } = useOfflinePublications({
        searchText: submittedSearchText,
    });

    const publicationSelectOptions = useMemo(() => {
        const publications = isOnline
            ? onlinePublications
            : offlinePublications;

        return publications?.map((p) => {
            return {
                value: p.id,
                data: p,
                label: p.getDisplayCodeEditionAndTitle(),
            } as SelectOption<PublicationRecord, number>;
        });
    }, [isOnline, offlinePublications, onlinePublications]);

    const handleClose = () => {
        onSelection(currentSelected);
        closeDialog();
    };

    const handleSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.currentTarget.value);
    };

    const handleSearchClear = () => {
        setSubmittedSearchText("");
        setSearchText("");
    };

    const handleSelectionChange = (
        selectedValues: Array<SelectOption<PublicationRecord, number>>
    ) => setCurrentSelected(selectedValues.map((sv) => sv.value));

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    const isMobile = useBreakpoint(
        Breakpoints.SmallTablet,
        BreakpointComparer.MaxWidth
    );

    const transitionEffect = isMobile
        ? ModalTransitions.SlideUp
        : ModalTransitions.Fade;
    const type = isMobile ? ModalTypes.Fullscreen : ModalTypes.Base;

    const modalTitle = t("allItem", {
        item: t("publication_plural"),
    });
    const closeDialogStyle = isMobile
        ? ButtonStyles.Secondary
        : ButtonStyles.Tertiary;
    const closeDialogClassName = isMobile
        ? ""
        : "-modal-close -icon -modal-close-tertiary";

    return (
        <Modal
            {...props}
            closeButtonStyle={ModalCloseButtonStyle.Hidden}
            cssClassName={CSS_BASE_CLASS_NAME}
            label={modalTitle}
            transition={transitionEffect}
            type={type}>
            <div className={`${CSS_BASE_CLASS_NAME}__header`}>
                <span>
                    {isMobile && <Icon type={Icons.ChevronLeft} />}
                    <Heading priority={HeadingPriority.Two}>
                        {modalTitle}
                    </Heading>
                </span>
                <Button
                    accessibleText={t("closeItem", { item: t("dialog") })}
                    cssClassName={closeDialogClassName}
                    onClick={closeDialog}
                    size={isMobile ? ButtonSizes.Small : ButtonSizes.Medium}
                    style={closeDialogStyle}>
                    {isMobile ? (
                        t("close")
                    ) : (
                        <Icon type={Icons.Close} size={IconSizes.Large} />
                    )}
                </Button>
            </div>
            <div className={`${CSS_BASE_CLASS_NAME}__search`}>
                <SearchForm
                    onClear={handleSearchClear}
                    onSearchClick={(e) => setSubmittedSearchText(searchText)}
                    onSearchTextChange={handleSearchTextChange}
                    placeholder={t(
                        "publicationSelectionModal-searchPlaceholder"
                    )}
                    searchText={searchText}
                    style={SearchFormStyles.TertiaryAlt}
                />
            </div>
            <div className={`${CSS_BASE_CLASS_NAME}__body`} tabIndex={0}>
                <CheckboxList<PublicationRecord, number>
                    onChange={handleSelectionChange}
                    options={publicationSelectOptions ?? []}
                    values={currentSelected}
                />
            </div>
            <div className={`${CSS_BASE_CLASS_NAME}__footer`}>
                <Button
                    onClick={() => setCurrentSelected([])}
                    size={ButtonSizes.Small}
                    style={ButtonStyles.Tertiary}>
                    {t("reset")}
                </Button>
                <Button onClick={handleClose} size={ButtonSizes.Small}>
                    {t("actionItem", {
                        action: t("show"),
                        item: t("result_plural"),
                    })}
                </Button>
            </div>
        </Modal>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default PublicationFilterModal;

// #endregion Exports
