import Loader from "atoms/loaders/loader";
import PublicationCodeGroupRecord from "models/view-models/publication-code-group-record";
import UserPublicationFavoriteRecord from "models/view-models/user-publication-favorite-record";
import React from "react";
import { useLocalization } from "utilities/hooks/use-localization";
import PublicationListGroup from "./publication-list-group";

export interface PublicationsListProps {
    observerRef: (node: any) => void;
    favorites: UserPublicationFavoriteRecord[];
    onChangeFavorites: (newValue: UserPublicationFavoriteRecord[]) => void;
    onResultClick?: Function;
    publicationGroups: PublicationCodeGroupRecord[];
    loading: boolean;
}

const PublicationsList: React.FunctionComponent<PublicationsListProps> = (
    props
) => {
    const {
        observerRef,
        favorites,
        onChangeFavorites,
        onResultClick,
        publicationGroups,
        loading,
    } = props;

    const { t } = useLocalization();

    const loadingText = t("loadingItem", { item: t("publication") });

    return (
        <>
            {publicationGroups.map((publicationGroup) => {
                return (
                    <PublicationListGroup
                        key={publicationGroup.code}
                        favorites={favorites}
                        onChangeFavorites={onChangeFavorites}
                        onResultClick={onResultClick}
                        publicationGroup={publicationGroup}
                    />
                );
            })}
            <div ref={observerRef} style={{ height: "20px" }}></div>
            {loading && <Loader accessibleText={loadingText} />}
        </>
    );
};

export default PublicationsList;
