import ModalCloseButton from "atoms/buttons/modal-close-button";
import { HeadingPriority } from "atoms/constants/heading-priority";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import Heading from "atoms/typography/heading";
import UserBookmarkRecord from "models/view-models/user-bookmark-record";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import Modal from "molecules/modals/modal";
import React, { PropsWithChildren } from "react";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface BookmarkSettingsMobileModalProps {
    cssClassName: string;
    initialUserBookmark: UserBookmarkRecord;
    onCancelClick: () => void;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const BookmarkSettingsMobileModal: React.FC<
    PropsWithChildren<BookmarkSettingsMobileModalProps>
> = (props: PropsWithChildren<BookmarkSettingsMobileModalProps>) => {
    const { cssClassName, initialUserBookmark, onCancelClick } = props;
    const { t } = useLocalization();
    const actionKey: keyof CultureResources =
        initialUserBookmark != null ? "editItem" : "addItem";

    const labelHeading = t(actionKey, { item: t("bookmark") });

    return (
        <Modal
            cssClassName={cssClassName}
            closeDialog={onCancelClick}
            isVisible={true}
            label={labelHeading}
            type={ModalTypes.Base}
            transition={ModalTransitions.SlideUp}>
            <div className={`${cssClassName}__header`}>
                <Icon type={Icons.Bookmark} />
                <Heading priority={HeadingPriority.Two}>{labelHeading}</Heading>
                <ModalCloseButton onClick={onCancelClick} />
            </div>
            <div className={`${cssClassName}__content`}>{props.children}</div>
        </Modal>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default BookmarkSettingsMobileModal;

// #endregion Exports
