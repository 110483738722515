import { InputTypes } from "atoms/constants/input-types";
import { InputProperties } from "interfaces/forms/input-properties";
import FormFieldErrors from "molecules/form-fields/form-field-errors";
import React, { forwardRef, Ref, RefObject, useState } from "react";
import uuid from "uuid";

/*
-----------------------------------------------------------------------------------------
Interfaces
-----------------------------------------------------------------------------------------
*/

export interface InputFormFieldProps extends InputProperties {
    autofocus?: boolean;
    errorMessage?: string;
    errorMessages?: string[];

    /**
     * Unique identifier used select the underlying <input> for functional/e2e testing
     */
    inputTestId?: string;

    fieldId?: string;
    label: string;
    maxLength?: number;
    name?: string;
    onBlur?: () => void;
    onFocus?: () => void;
    ref?: RefObject<HTMLInputElement>;
    required?: boolean;
    showCharacterCount?: boolean;
    showLabelForScreenReadersOnly?: boolean;
}

/*
---------------------------------------------------------------------------------------------
Component
---------------------------------------------------------------------------------------------
*/

const InputFormField = forwardRef(
    (props: InputFormFieldProps, ref: Ref<HTMLInputElement>) => {
        const {
            autocomplete,
            autofocus,
            disabled,
            inputTestId,
            isValid,
            label,
            maxLength,
            name,
            onBlur,
            onChange,
            onFocus,
            placeholder,
            required,
            showLabelForScreenReadersOnly,
            type,
            value,
        } = props;

        // stateful so it doesn't recompute on every render
        const [defaultFieldId] = useState(`input-${uuid.v4()}`);
        const fieldId = props.fieldId ?? defaultFieldId;

        return (
            <div className={`c-form-field ${isValid ? "" : "-invalid"}`}>
                <label htmlFor={fieldId}>
                    {showLabelForScreenReadersOnly ? (
                        <span className="sr-only">{label}</span>
                    ) : (
                        <React.Fragment>{label}</React.Fragment>
                    )}
                    {required && (
                        <span className="c-form-field__required">{" *"}</span>
                    )}
                </label>
                <input
                    autoComplete={autocomplete}
                    autoFocus={autofocus}
                    data-test-id={inputTestId}
                    disabled={disabled}
                    id={fieldId}
                    placeholder={placeholder}
                    maxLength={maxLength ?? 20}
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    onFocus={onFocus}
                    ref={ref}
                    type={type ?? InputTypes.Text}
                    value={value}
                />
                <FormFieldErrors {...props} />
            </div>
        );
    }
);

/*
---------------------------------------------------------------------------------------------
Exports
---------------------------------------------------------------------------------------------
*/

export default InputFormField;
