import Anchor from "atoms/anchors/anchor";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Icon from "atoms/icons/icon";
import Loader from "atoms/loaders/loader";
import Paragraph from "atoms/typography/paragraph";
import { AppNameTm } from "constants/app-name-tm";
import { siteMap } from "internal-sitemap";
import UserBookmarkRecord from "models/view-models/user-bookmark-record";
import NoResultsBanner from "molecules/banners/no-results-banner";
import RecentBookmarkAnchor from "molecules/recent-bookmark-anchor/recent-bookmark-anchor";
import React from "react";
import { CollectionUtils } from "utilities/collection-utils";
import { useLocalization } from "utilities/hooks/use-localization";
import StringUtils from "utilities/string-utils";
import { v4 } from "uuid";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface RecentBookmarksListProps {
    bookmarks: UserBookmarkRecord[];
    loading: boolean;
    loaded: boolean;
    disabled?: boolean;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-recent-bookmarks-list";
const TITLE_ID = v4();

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const RecentBookmarksList: React.FunctionComponent<RecentBookmarksListProps> = (
    props: RecentBookmarksListProps
) => {
    const { bookmarks, loaded, loading } = props;
    const { t } = useLocalization();
    const loaderText = t("loadingItem", { item: t("bookmark_plural") });
    const disabled = props.disabled ?? false;
    const showNoResults =
        loaded && !loading && CollectionUtils.isEmpty(bookmarks) && !disabled;

    const anchorStyles = ["c-button", ButtonSizes.Small, ButtonStyles.Tertiary];
    const disabledTitle = t(
        "offline-dashboard-recent-bookmarks-disabled-title"
    );
    const disabledDescription = t(
        "offline-dashboard-recent-bookmarks-disabled-description",
        { appName: AppNameTm }
    );
    return (
        <div className={BASE_CLASS_NAME}>
            <Paragraph
                cssClassName={`${BASE_CLASS_NAME}__title`}
                id={TITLE_ID}
                size={ParagraphSizes.Large}>
                <Icon type={Icons.BookmarkOutline} size={IconSizes.Large} />
                {t("recentBookmarks")}
            </Paragraph>
            {CollectionUtils.hasValues(bookmarks) && (
                <React.Fragment>
                    <nav aria-labelledby={TITLE_ID}>
                        {bookmarks.map((bookmark: UserBookmarkRecord) => (
                            <RecentBookmarkAnchor
                                bookmark={bookmark}
                                key={bookmark.id}
                            />
                        ))}
                    </nav>
                    <Anchor
                        cssClassName={StringUtils.joinClassNames(anchorStyles)}
                        dataTestId="my-bookmarks-link"
                        to={siteMap.myLink.myBookmarks}>
                        {t("viewItem", { item: t("allBookmarks") })}
                    </Anchor>
                </React.Fragment>
            )}
            {loading && <Loader accessibleText={loaderText} />}
            {showNoResults && (
                <NoResultsBanner
                    title={t("recentBookmarks-emptyState")}
                    titleSize={ParagraphSizes.XSmall}
                    subtitle={t("beginAddingBookmarks")}
                    subtitleSize={ParagraphSizes.XSmall}
                />
            )}
            {disabled && (
                <NoResultsBanner
                    title={disabledTitle}
                    titleSize={ParagraphSizes.XSmall}
                    subtitle={disabledDescription}
                    subtitleSize={ParagraphSizes.XSmall}
                />
            )}
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default RecentBookmarksList;

// #endregion Export
