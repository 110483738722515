import { RecordUtils } from "@rsm-hcd/javascript-core";
import PublicationTypes from "constants/publication-types";
import { UnicodeCharacterConstants } from "constants/unicode-character-constants";
import { Record as ImmutableRecord } from "immutable";
import type Annex from "models/interfaces/annex";
import PublicationAnchorRecord from "models/view-models/publication-anchor-record";
import PublicationRecord from "models/view-models/publication-record";
import { PublicationSidebarNavItem } from "molecules/sidebar-chapter-navigation/publication-sidebar-nav-link";
import { ReactNode } from "react";
import CaapsRecordUtils from "utilities/caaps-record-utils";
import { CollectionUtils } from "utilities/collection-utils";
import PublicationComponentType from "utilities/enumerations/publication-component-type";
import type CultureResources from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization-utils";
import StringUtils from "utilities/string-utils";
import XmlUtils from "utilities/xml-utils";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const AnnexTypeDescription: Record<PublicationTypes, keyof CultureResources> = {
    [PublicationTypes.NFC]: "annex",
    [PublicationTypes.NEC]: "informativeAnnex",
};

const defaultValues: Annex = RecordUtils.auditableDefaultValuesFactory<Annex>({
    body: undefined,
    displaySequence: undefined,
    externalId: "",
    label: undefined,
    nfpaLabel: undefined,
    number: undefined,
    publication: undefined,
    publicationId: 0,
    title: undefined,
    titleAsPlainText: "",
    changes: undefined,
});

// #endregion Constants

export default class AnnexRecord
    extends ImmutableRecord(defaultValues)
    implements Annex
{
    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: Annex) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.publication != null) {
            params.publication = RecordUtils.ensureRecord(
                params.publication,
                PublicationRecord
            );
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Get an annex group route
     * @param groupId
     */
    public getAnnexGroupRoute(
        groupId: string | number,
        isAdminPreview: boolean = false
    ): string {
        const anchor = PublicationAnchorRecord.fromAnnexGroup(
            this,
            groupId,
            isAdminPreview
        );
        return anchor.getRoute() ?? "#";
    }

    /**
     * Returns the body (if it exists) as React components. See `xml-utils.ts` for more info.
     */
    public getBody(debug?: boolean): ReactNode {
        if (this.body == null) {
            return "";
        }

        const xmlConverters = {
            ...XmlUtils.defaultConverters,
            ...XmlUtils.publicationInlineConverters,
            "np:body": XmlUtils.xmlConverter("div"),
            div: XmlUtils.xmlConverter(XmlUtils.FragmentWrapper),
        };

        return XmlUtils.convert(this.body!, xmlConverters, debug);
    }

    /**
     * Returns the value of label or if null, the value of getTitleWithNumber()
     */
    public getDisplayLabel(): string {
        if (StringUtils.hasValue(this.label)) {
            return StringUtils.translateDomainTerms(
                this.label,
                this.publication?.locale!
            );
        }

        return this.getTitleWithNumber();
    }

    /**
     * Removes the text Annex description text from annex title.
     *
     * @example "Informative Annex A  Product Safety Standards" becomes "Product Safety Standards"
     */
    public getDisplayTitle(): string {
        let title = this.titleAsPlainText ?? "";
        if (StringUtils.isEmpty(this.title)) {
            return "";
        }

        if (StringUtils.hasValue(this.number)) {
            title = title.replace(this.getDisplayLabel(), "").trim();
        }

        return StringUtils.translateDomainTerms(
            title,
            this.publication?.locale!
        );
    }

    /**
     * Adds the annex number/label to the beginning of the display title.
     *
     * @example "Informative Annex A  Product Safety Standards" becomes "Annex A - Product Safety Standards"
     */
    public getDisplayTitleWithNumber(): string {
        const displayTitle = this.getDisplayTitle();

        if (StringUtils.isEmpty(displayTitle)) {
            return "";
        }

        if (StringUtils.isEmpty(this.number)) {
            return displayTitle;
        }

        return `${this.getDisplayLabel()} ${
            UnicodeCharacterConstants.EmDash
        } ${displayTitle}`;
    }

    public getPluralDescription(publicationType?: PublicationTypes): string {
        const publication =
            this.publication ??
            new PublicationRecord({ type: publicationType });

        return publication.getAnnexesDisplayLabel();
    }

    /**
     * Get the application route to view the current Annex in Book View
     * @param isAdminPreview
     */
    public getRoute(
        isAdminPreview: boolean = false,
        includeHash: boolean = true
    ): string {
        const anchor = PublicationAnchorRecord.fromAnnexRecord(
            this,
            isAdminPreview
        );
        return anchor.getRoute(includeHash) ?? "#";
    }

    /**
     * Returns the title as React components (Usually only text, but <inline> elements can exist for changes).
     */
    public getTitle(debug?: boolean): ReactNode {
        // If we have no title, just return an empty string.
        if (this.title == null) {
            return "";
        }

        // Otherwise, converts the XML to React components using default and custom converters.
        const xmlConverters = {
            ...XmlUtils.defaultConverters,
            ...XmlUtils.publicationInlineConverters,
        };

        return XmlUtils.convert(this.title, xmlConverters, debug);
    }

    /**
     * @returns Whether or not the record title or body XML has changes.
     * Uses XmlUtils.hasAttributeDeep to recursively check every element in the XML tree.
     */
    public doesTitleOrBodyHaveChanges(): boolean {
        const hasChanges = CaapsRecordUtils().doesTitleOrBodyHaveChanges(
            this.title,
            this.body,
            this.changes
        );

        return hasChanges;
    }

    /**
     * Adds the annex number/label to the beginning of the annex number.
     *
     * @return {*}  {string}
     */
    public getTitleWithNumber(): string {
        const publicationType = this.publication?.type ?? PublicationTypes.NEC;
        const annexTypeDescription = t(AnnexTypeDescription[publicationType]);

        return `${annexTypeDescription} ${this.number}`;
    }

    public isPersisted(): boolean {
        return this.id != null && this.id > 0;
    }

    /**
     * Transforms this Annex into an object representing the PublicationSidebarNavItem interface
     *
     * Useful when mapping out a set of AnnexRecords to nav items.
     *
     * @param {Partial<PublicationSidebarNavItem>} [values] (Optional) Values to override the default transformation
     */
    public toNavItem(
        values?: Partial<PublicationSidebarNavItem>,
        isAdminPreview?: boolean
    ): PublicationSidebarNavItem {
        const defaultNavItem: PublicationSidebarNavItem = {
            dataTestDisplaySequence: this.displaySequence,
            id: this.id!,
            label: this.getDisplayLabel(),
            route: this.getRoute(isAdminPreview),
            title: this.getDisplayTitle(),
            type: PublicationComponentType.Annex,
            searchForLinkOnPage: false,
        };

        return Object.assign(defaultNavItem, values);
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<Annex>} values
     */
    public with(values: Partial<Annex>): AnnexRecord {
        return new AnnexRecord(Object.assign(this.toJS(), values));
    }

    /**
     * Adds PublicationRecord relationship based on ExternalId from a list of PublicationRecords.
     *
     * @param {PublicationRecord[]} [publications]
     */
    public withPublication(publications?: PublicationRecord[]): AnnexRecord {
        if (CollectionUtils.isEmpty(publications)) {
            return this;
        }

        return this.with({
            publication: publications?.find(
                (publication: PublicationRecord) =>
                    publication.id === this.publicationId
            ),
        });
    }

    // #endregion Public Methods
}
