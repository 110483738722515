import { ResultRecord } from "@rsm-hcd/javascript-core";
import _ from "lodash";
import UserBookmarkSortOption from "models/enumerations/sorting/user-bookmarks/user-bookmark-sort-option";
import UserBookmarkRecord from "models/view-models/user-bookmark-record";
import UserCollectionBookmarkRecord from "models/view-models/user-collection-bookmark-record";
import type UserCollectionRecord from "models/view-models/user-collection-record";
import { useCallback, useEffect, useState } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { UserBookmarkColors } from "utilities/enumerations/user-bookmark-colors";
import usePagedResults from "utilities/hooks/use-paged-results";
import NumberUtils from "utilities/number-utils";
import UserBookmarkService from "utilities/services/user-bookmarks/user-bookmark-service";
import UserCollectionBookmarkService from "utilities/services/users/user-collection-bookmarks/user-collection-bookmark-service";
import UserCollectionService from "utilities/services/users/user-collections/user-collection-service";
import { ToastManager } from "utilities/toast/toast-manager";

export interface MyBookmarkOptions {
    collectionId?: number;
    searchText?: string;
    colors?: Array<UserBookmarkColors>;
    filterByCurrentUser?: boolean;
    sortBy?: UserBookmarkSortOption;
    includeProperties?: Array<
        "section" | "publication" | "collection" | "user"
    >;
    collections?: Array<UserCollectionRecord>;
    showPagination?: boolean;
}

/**
 * Custom hook to load MyBookmarks and related data.
 */
export default function useMyBookmarks(options: MyBookmarkOptions) {
    // -----------------------------------------------------------------------------------------
    // #region Private Members
    // -----------------------------------------------------------------------------------------
    const loadCollections: boolean =
        options.includeProperties?.includes("collection") ?? false;

    const DEFAULT_TAKE_SIZE = 10;

    const { globalState } = useGlobalState();
    const { list: listCollections } = UserCollectionService.useListQuery();
    const { list: listCollectionBookmarks } =
        UserCollectionBookmarkService.useNestedListQuery();

    const userId = globalState.currentIdentity?.userId();

    const [bookmarks, setBookmarks] = useState<UserBookmarkRecord[]>();
    const [loading, setLoading] = useState(false);

    const {
        currentPage,
        numberOfPages,
        onPageLast,
        onPageNext,
        onPageSizeChange,
        onSelectPage,
        setRowCount,
        rowCount,
        take,
        skip,
        setSkip,
    } = usePagedResults(DEFAULT_TAKE_SIZE);

    // #endregion Private Members

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------
    // #region UserBookmarks
    // -----------------------------------------------------------------------------------------

    const currentUserOnly = options.filterByCurrentUser === true;

    const getUserBookmarks = useCallback(async () => {
        const skipTake = options.showPagination
            ? { skip: skip, take: take }
            : {};
        const endpointOptions = {
            ...skipTake,
            currentUserOnly,
            collectionId: options.collectionId,
            colors: options.colors,
            searchText: options.searchText,
            includeRelatedNfpaLabels: true,
            sortBy: options.sortBy,
        };
        setLoading(true);
        try {
            const results = await UserBookmarkService.listV2(endpointOptions);
            setBookmarks(results.resultObjects);
            setRowCount(results.rowCount ?? 0);
        } catch (error) {
            if (error instanceof ResultRecord) {
                ToastManager.error(error.listErrorMessages());
            }
        } finally {
            setLoading(false);
        }
    }, [
        currentUserOnly,
        options.showPagination,
        options.collectionId,
        options.colors,
        options.searchText,
        options.sortBy,
        skip,
        take,
        setRowCount,
    ]);

    useEffect(() => {
        setSkip(0);
    }, [options.colors, setSkip]);

    useEffect(() => {
        getUserBookmarks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        currentUserOnly,
        options.collectionId,
        options.colors,
        options.searchText,
        options.sortBy,
        skip,
        take,
    ]);

    // #endregion UserBookmarks

    // -----------------------------------------------------------------------------------------
    // #region Collections
    // -----------------------------------------------------------------------------------------

    const includeCollections =
        loadCollections && !NumberUtils.isDefault(userId);
    const userCollectionQuery = listCollections(
        { userId: userId! },
        undefined,
        { enabled: includeCollections }
    );

    const collections =
        options.collections ?? userCollectionQuery.data?.resultObjects ?? [];

    const userCollectionBookmarksQuery = listCollectionBookmarks(
        { userId: userId! },
        undefined,
        { enabled: includeCollections }
    );

    const collectionBookmarks =
        userCollectionBookmarksQuery.data?.resultObjects ?? [];

    // #endregion Collections

    // #endregion Public Methods

    // -----------------------------------------------------------------------------------------
    // #region Public Properties
    // -----------------------------------------------------------------------------------------

    const myBookmarks = _.chain(bookmarks)
        .map(
            buildUserBookmarkRecord({
                collectionBookmarks,
                collections,
            })
        )
        .value();

    const refresh = () => {
        getUserBookmarks();
        userCollectionBookmarksQuery.refetch();
        userCollectionQuery.refetch();
    };

    const refreshCollections = userCollectionQuery.refetch;

    // #endregion Public Properties

    //TODO: Errors and error handling
    //TODO: Loaded state reporting
    return {
        collectionBookmarks,
        collections,
        loading,
        myBookmarks,
        refresh,
        refreshCollections,
        currentPage,
        numberOfPages,
        onPageLast,
        onPageNext,
        onPageSizeChange,
        onSelectPage,
        setRowCount,
        rowCount,
        take,
        skip,
    };
}

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

const buildUserBookmarkRecord = (buildOptions: {
    collectionBookmarks: UserCollectionBookmarkRecord[];
    collections: UserCollectionRecord[];
}) => {
    const { collectionBookmarks, collections } = buildOptions;

    return (b: UserBookmarkRecord) =>
        b.withUserCollectionBookmarks(collectionBookmarks, collections);
};

// #endregion Functions
