import SituationRelationshipRecord from "models/view-models/situational-navigation/situations/situation-relationship-record";
import { PagedQuery } from "@rsm-hcd/javascript-core";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AdminSituationRelationshipPathParams {
    situationId: number;
}

export interface AdminSituationRelationshipQueryParams extends PagedQuery {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint =
    "admin/situational-navigation/situations/:situationId/relationships";
const resourceEndpoint = `${baseEndpoint}/:id`;
const resourceType = SituationRelationshipRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const AdminSituationRelationshipService = {
    list: ServiceFactory.nestedList<
        SituationRelationshipRecord,
        AdminSituationRelationshipPathParams,
        AdminSituationRelationshipQueryParams
    >(resourceType, baseEndpoint),
    useList: ServiceHookFactory.useNestedList<
        SituationRelationshipRecord,
        AdminSituationRelationshipPathParams,
        AdminSituationRelationshipQueryParams
    >(resourceType, baseEndpoint),
    create: ServiceFactory.nestedCreate<
        SituationRelationshipRecord,
        AdminSituationRelationshipPathParams
    >(resourceType, baseEndpoint),
    useCreate: ServiceHookFactory.useNestedCreate<
        SituationRelationshipRecord,
        AdminSituationRelationshipPathParams
    >(resourceType, baseEndpoint),
    update: ServiceFactory.update<
        SituationRelationshipRecord,
        AdminSituationRelationshipPathParams
    >(resourceType, resourceEndpoint),
    useUpdate: ServiceHookFactory.useUpdate<
        SituationRelationshipRecord,
        AdminSituationRelationshipPathParams
    >(resourceType, resourceEndpoint),
    delete: ServiceFactory.delete<AdminSituationRelationshipPathParams>(
        resourceEndpoint
    ),
    useDelete:
        ServiceHookFactory.useDelete<AdminSituationRelationshipPathParams>(
            resourceEndpoint
        ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default AdminSituationRelationshipService;

// #endregion Exports
