import { Do, ResultRecord, ServiceResponse } from "@rsm-hcd/javascript-core";
import type BookTopicRecord from "models/view-models/book-topic-record";
import { useCallback, useEffect, useState } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import { useLocalization } from "utilities/hooks/use-localization";
import usePageErrors from "utilities/hooks/use-page-errors";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import BookTopicService from "utilities/services/books/topics/book-topic-service";
import { ToastManager } from "utilities/toast/toast-manager";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface UseBookTopicsOptions {
    bookId?: number;
}

interface UseBookTopicsResult
    extends Pick<
        ServiceHookResult<BookTopicRecord[]>,
        "errors" | "loading" | "loaded" | "resultObject"
    > {
    refresh?: (bookId: number) => void;
    createOrDeleteBookTopics: (
        initialItems: BookTopicRecord[],
        items: BookTopicRecord[]
    ) => Promise<void>;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const defaultOptionValues: UseBookTopicsOptions = {
    bookId: undefined,
};

// #endregion Constants

/**
 * Custom hook for retrieving BookTopics
 *
 * @param options
 */
export default function useBookTopics(
    options: UseBookTopicsOptions
): UseBookTopicsResult {
    const { bookId } = Object.assign({}, defaultOptionValues, options);
    const [bookTopics, setBookTopics] = useState<BookTopicRecord[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);

    const { t } = useLocalization();
    const { list: listApi } = BookTopicService.useList();
    const { delete: deleteApi } = BookTopicService.useDelete();
    const { create: createApi } = BookTopicService.useCreate();
    const { handlePageLoadError, pageErrors: errors } = usePageErrors();

    const apiErrorToast = t("errors-actionResource_plural", {
        action: t("updating"),
        resource: t("topic_plural"),
    });

    const list = useCallback(
        async (bookId: number) => {
            if (bookId == null) {
                return;
            }

            Do.try(async () => {
                setLoading(true);
                setLoaded(false);

                const BookTopicsResult = await listApi({ bookId });
                setBookTopics(BookTopicsResult.resultObjects);
                setLoading(false);
                setLoaded(true);
            }).catch((result?: ResultRecord<BookTopicRecord>, error?: any) => {
                if (result == null) {
                    handlePageLoadError(error);
                    return;
                }
            });
        },
        [listApi, handlePageLoadError, setLoaded, setLoading]
    );

    const _createOrDeleteBookTopics = async (
        initialItems: BookTopicRecord[],
        items: BookTopicRecord[]
    ) => {
        Do.try(async () => {
            const recordsToDelete = CollectionUtils.difference(
                initialItems,
                items,
                "id"
            );

            const deleteApiCalls = recordsToDelete.map((r) =>
                deleteApi(r.id!, { bookId: r.bookId })
            );
            const deleteResponses = await Promise.all(deleteApiCalls);
            if (deleteResponses.some((dr) => dr.result?.hasErrors())) {
                ToastManager.error(apiErrorToast);
                return;
            }
            const recordsToCreate = CollectionUtils.difference(
                items,
                initialItems,
                "id"
            );

            const createResponses: Array<ServiceResponse<BookTopicRecord>> = [];
            recordsToCreate.forEach(async (r) => {
                createResponses.push(await createApi(r, { bookId: r.bookId }));
            });
            if (createResponses.some((dr) => dr.result?.hasErrors())) {
                ToastManager.error(apiErrorToast);
                return;
            }
        }).catch(() => ToastManager.error(apiErrorToast));
    };

    useEffect(() => {
        if (bookId == null) {
            return;
        }

        list(bookId);
    }, [bookId, list]);

    return {
        errors,
        loaded,
        loading,
        refresh: list,
        resultObject: bookTopics,
        createOrDeleteBookTopics: useCallback(_createOrDeleteBookTopics, [
            apiErrorToast,
            deleteApi,
            createApi,
        ]),
    };
}
