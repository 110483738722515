import SituationRecord from "models/view-models/situational-navigation/situations/situation-record";
import SolutionRecord from "models/view-models/situational-navigation/solutions/solution-record";
import NumberUtils from "utilities/number-utils";
import { RecordUtils } from "@rsm-hcd/javascript-core";

export interface SituationSolutionComponentId {
    type: typeof SituationRecord | typeof SolutionRecord;
    id: number;
}

const getComponentId = (record: SituationRecord | SolutionRecord) =>
    `${
        RecordUtils.isRecord(record, SituationRecord) ? "situation" : "solution"
    }-${record.id!}`;

const parseComponentId = (
    componentId: string
): SituationSolutionComponentId | undefined => {
    try {
        const idParts = componentId.split("-");
        if (idParts.length < 2) {
            return undefined;
        }
        const type =
            idParts[0] === "solution" ? SolutionRecord : SituationRecord;
        const id = NumberUtils.parseInt(idParts[1]);
        if (id == null) {
            return undefined;
        }

        return { type, id };
    } catch (e) {
        return undefined;
    }
};

const SitNavUtils = {
    getComponentId,
    parseComponentId,
};

export default SitNavUtils;
