import { LocalizationInitOptions } from "@rsm-hcd/javascript-core";
import LocalizationUtils from "./utilities/localization-utils";

const LOCALIZATION_OPTIONS: LocalizationInitOptions = {
    ...LocalizationUtils.defaultInitOptions,
    detection: {
        lookupFromPathIndex: 0,
        lookupLocalStorage: LocalizationUtils.routeParam,
        order: ["localStorage", "path"], // order and from where user language should be detected
    },
    debug: false,
};

export default LOCALIZATION_OPTIONS;
