import SolutionResourceRecord from "models/view-models/situational-navigation/solutions/solution-resource-record";
import { PagedQuery } from "@rsm-hcd/javascript-core";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AdminSolutionResourcePathParams {
    solutionId: number;
    id?: number;
}

export interface AdminSolutionResourceQueryParams extends PagedQuery {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint =
    "admin/situational-navigation/solutions/:solutionId/resources";
const resourceType = SolutionResourceRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const AdminSolutionResourceService = {
    create: ServiceFactory.nestedCreate<
        SolutionResourceRecord,
        AdminSolutionResourcePathParams
    >(resourceType, baseEndpoint),
    useCreate: ServiceHookFactory.useNestedCreate<
        SolutionResourceRecord,
        AdminSolutionResourcePathParams
    >(resourceType, baseEndpoint),
    delete: ServiceFactory.delete(`${baseEndpoint}/:id`),
    useDelete: ServiceHookFactory.useDelete(`${baseEndpoint}/:id`),
    list: ServiceFactory.nestedList<
        SolutionResourceRecord,
        AdminSolutionResourcePathParams,
        AdminSolutionResourceQueryParams
    >(resourceType, baseEndpoint),
    useList: ServiceHookFactory.useNestedList<
        SolutionResourceRecord,
        AdminSolutionResourcePathParams,
        AdminSolutionResourceQueryParams
    >(resourceType, baseEndpoint),
    update: ServiceFactory.update<
        SolutionResourceRecord,
        AdminSolutionResourcePathParams
    >(resourceType, `${baseEndpoint}/:id`),
    useUpdate: ServiceHookFactory.useUpdate<
        SolutionResourceRecord,
        AdminSolutionResourcePathParams
    >(resourceType, `${baseEndpoint}/:id`),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default AdminSolutionResourceService;

// #endregion Exports
