import CollapsePanel from "molecules/collapse-panel/collapse-panel";
import React, { ReactNode } from "react";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface SearchModalFilterSectionProps {
    isVisible: boolean;
    panelText: string;
    panelTriggerAriaText: string;
    children?: ReactNode;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS = "c-search-modal-filters__section";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SearchModalFilterSection: React.FC<SearchModalFilterSectionProps> = (
    props: SearchModalFilterSectionProps
) => {
    const { isVisible, panelText, panelTriggerAriaText, children } = props;

    if (!isVisible) {
        return null;
    }

    return (
        <div className={BASE_CLASS}>
            <CollapsePanel
                buttonAriaText={panelTriggerAriaText}
                collapse={false}
                panelTop={panelText}>
                {children}
            </CollapsePanel>
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default SearchModalFilterSection;

// #endregion Exports
