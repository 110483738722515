import StringUtils from "utilities/string-utils";
import { Helmet } from "react-helmet-async";
import React, { useMemo } from "react";
import { useHeaderData } from "utilities/contexts/use-header-data-context";
import { AppNameTm } from "constants/app-name-tm";
import { useLocation } from "react-router-dom";

export const DefaultPageTitle = AppNameTm;

export const HeaderData = () => {
    const { fullPageTitle, metaTags } = useHeaderData();
    const location = useLocation();
    const pageTitle = useMemo(() => {
        return StringUtils.hasValue(fullPageTitle)
            ? fullPageTitle.startsWith(DefaultPageTitle) ||
              location.pathname.includes("free-access")
                ? fullPageTitle
                : `${DefaultPageTitle} - ${fullPageTitle}`
            : DefaultPageTitle;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fullPageTitle]);

    return (
        <Helmet>
            <title>{pageTitle}</title>

            {metaTags.map((mt) => (
                <meta key={mt.name} name={mt.name} content={mt.content} />
            ))}
        </Helmet>
    );
};
