import { ServiceResponse } from "@rsm-hcd/javascript-core";
import Anchor from "atoms/anchors/anchor";
import Button from "atoms/buttons/button";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Loader from "atoms/loaders/loader";
import Paragraph from "atoms/typography/paragraph";
import { List } from "immutable";
import { siteMap } from "internal-sitemap";
import FileRecord from "models/view-models/file-record";
import ResourceCollectionRecord from "models/view-models/resource-collection-record";
import SectionRecord from "models/view-models/section-record";
import AdminEditorPageContextRecord from "models/view-models/situational-navigation/admin-editor-page-context-record";
import CategoryCollectionRecord from "models/view-models/situational-navigation/categories/category-collection-record";
import CategoryRecord from "models/view-models/situational-navigation/categories/category-record";
import SolutionCategoryRecord from "models/view-models/situational-navigation/solutions/solution-category-record";
import SolutionRecord from "models/view-models/situational-navigation/solutions/solution-record";
import SolutionRelationshipRecord from "models/view-models/situational-navigation/solutions/solution-relationship-record";
import SolutionResourceRecord from "models/view-models/situational-navigation/solutions/solution-resource-record";
import SolutionSectionRecord from "models/view-models/situational-navigation/solutions/solution-section-record";
import InputFormField from "molecules/form-fields/input-form-field";
import Form from "molecules/forms/form";
import RichTextEditor from "molecules/rich-text/rich-text-editor";
import UnsavedChangesPrompt from "molecules/unsaved-changes-prompt/unsaved-changes-prompt";
import moment from "moment";
import AdminCategoriesSection from "organisms/admin/situational-navigation/admin-categories-section";
import PublishStatusMenu from "organisms/admin/situational-navigation/publish-status-menu";
import AdminRelatedSolutionsSection from "organisms/admin/situational-navigation/solutions/admin-related-solutions-section";
import SolutionCodeSections from "organisms/admin/situational-navigation/solutions/solution-code-sections";
import AdminResourcesSection from "organisms/resources/admin-resources-section";
import { SolutionsAdminPageContainerId } from "pages/admin/situational-navigation/solutions-page";
import React, { useCallback, useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { CollectionUtils } from "utilities/collection-utils";
import { useAdminEditorPageContext } from "utilities/contexts/admin/use-admin-editor-page-context";
import FileUtils from "utilities/file-utils";
import useLoading from "utilities/hooks/use-loading";
import usePageErrors from "utilities/hooks/use-page-errors";
import NumberUtils from "utilities/number-utils";
import { RouteUtils } from "utilities/route-utils";
import AdminCategoryService from "utilities/services/admin/situational-navigation/categories/admin-category-service";
import AdminSectionService from "utilities/services/admin/situational-navigation/sections/admin-section-service";
import AdminSolutionCategoryService from "utilities/services/admin/situational-navigation/solutions/admin-solution-category-service";
import AdminSolutionPublishService from "utilities/services/admin/situational-navigation/solutions/admin-solution-publish-service";
import AdminSolutionRelationshipService from "utilities/services/admin/situational-navigation/solutions/admin-solution-relationship-service";
import AdminSolutionResourceService from "utilities/services/admin/situational-navigation/solutions/admin-solution-resource-service";
import AdminSolutionSectionService from "utilities/services/admin/situational-navigation/solutions/admin-solution-section-service";
import AdminSolutionService from "utilities/services/admin/situational-navigation/solutions/admin-solution-service";
import FileService from "utilities/services/file-service";
import StringUtils from "utilities/string-utils";
import { ToastManager } from "utilities/toast/toast-manager";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

export const SolutionEditorContentAreaId =
    "solution-editor-content-area-container";

const SOLUTION_CREATED_SUCCESSFUL = "New solution has been created.";
const SOLUTION_DELETED_SUCCESSFUL = "Solution successfully deleted.";
const SOLUTION_PUBLISH_SUCCESSFUL = "Solution successfully published.";
const SOLUTION_CREATED_ERROR = "There was an issue creating this solution.";
const SOLUTION_DELETED_ERROR = "There was an issue deleting this solution.";
const SOLUTION_PUBLISH_ERROR = "There was an issue publishing this solution.";
const SOLUTION_SAVE_SUCCESSFUL = "Solution successfully saved.";
const SOLUTION_SAVE_ERROR = "There was an issue saving this solution.";
const SOLUTION_UNPUBLISH_SUCCESSFUL =
    "Solution has been successfully unpublished.";
const SOLUTION_UNPUBLISH_ERROR =
    "There was an issue unpublishing the solution.";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const getDropdownPortalElement = (): HTMLElement =>
    document.getElementById(SolutionsAdminPageContainerId)!;

const SolutionEditor: React.FC<any> = () => {
    const cssClassName = "c-admin-editor-layout";

    const { id } = useParams<{ id: string }>();

    const { context, setContext } = useAdminEditorPageContext();

    /**
     * Services
     */
    const { update: updateSolutionApi } = AdminSolutionService.useUpdate();
    const { update: updateSolutionCategoriesApi } =
        AdminSolutionCategoryService.useUpdate();
    const { update: updateSolutionSectionsApi } =
        AdminSolutionSectionService.useUpdate();
    const { create: createSolutionApi } = AdminSolutionService.useCreate();
    const { get: getSolutionApi } = AdminSolutionService.useGet();
    const { list: listCategoriesApi } = AdminCategoryService.useList();
    const { list: listSectionsApi } = AdminSectionService.useList();

    const { list: listResourcesApi } = AdminSolutionResourceService.useList();
    const { get: getFileApi } = FileService.useGet();

    const { create: createResourceApi } =
        AdminSolutionResourceService.useCreate();
    const { update: updateResourceApi } =
        AdminSolutionResourceService.useUpdate();
    const { delete: deleteResourceApi } =
        AdminSolutionResourceService.useDelete();

    const { list: listRelationshipsApi } =
        AdminSolutionRelationshipService.useList();

    const { create: createRelationshipApi } =
        AdminSolutionRelationshipService.useCreate();
    const { delete: deleteRelationshipApi } =
        AdminSolutionRelationshipService.useDelete();

    const { update: publishSolutionApi } =
        AdminSolutionPublishService.usePublish();
    const { delete: unpublishSolutionApi } =
        AdminSolutionPublishService.useUnpublish();

    const [initialSolution, setInitialSolution] = useState(
        new SolutionRecord()
    );
    const [solution, setSolution] = useState(new SolutionRecord());

    const [loadingSolutionResources, setLoadingSolutionResources] =
        useState(false);
    const [loadingFiles, setLoadingFiles] = useState(false);
    const loadingResources = useLoading(loadingSolutionResources, loadingFiles);
    const [loadingCategories, setLoadingCategories] = useState(false);
    const [loadingSections, setLoadingSections] = useState(false);
    const [loadingRelatedSolutions, setLoadingRelatedSolutions] =
        useState(false);
    const [loadingSolution, setLoadingSolution] = useState(false);
    const loading = useLoading(
        loadingResources,
        loadingCategories,
        loadingSections,
        loadingRelatedSolutions,
        loadingSolution
    );

    const [isDirty, setIsDirty] = useState(false);
    const {
        pageErrors: titleErrors,
        setPageErrors: setTitleErrors,
        resetPageErrors: resetTitleErrors,
        handlePageLoadError: handleSubmitError,
    } = usePageErrors();
    const [initialCategories, setInitialCategories] = useState(
        new CategoryCollectionRecord()
    );
    const [categories, setCategories] = useState(
        new CategoryCollectionRecord()
    );

    /**
     * This is needed because, for example, on successful creation, the setIsDirty(false)
     * doesn't go through before the page attempts to redirect, so we need to ignore the isDirty
     * flag in order to prevent the confirm prompt from showing when it shouldn't
     */
    const [redirectIgnoreIsDirty, setRedirectIgnoreIsDirty] =
        useState<string>();
    const [initialCodeSections, setInitialCodeSections] =
        useState<Array<SectionRecord>>();
    const [codeSections, setCodeSections] = useState<Array<SectionRecord>>([]);
    const [initialRelatedSolutions, setInitialRelatedSolutions] = useState<
        Array<SolutionRecord>
    >([]);
    const [initialRelationships, setInitialRelationships] = useState<
        Array<SolutionRelationshipRecord>
    >([]);
    const [relatedSolutions, setRelatedSolutions] = useState<
        Array<SolutionRecord>
    >([]);

    const [initialResources, setInitialResources] = useState(
        new ResourceCollectionRecord(SolutionResourceRecord)
    );
    const [resources, setResources] = useState(
        new ResourceCollectionRecord(SolutionResourceRecord)
    );

    const initializeRelatedSolutions = (
        solutions?: Array<SolutionRecord>,
        relationships?: Array<SolutionRelationshipRecord>
    ) => {
        setInitialRelatedSolutions(solutions ?? []);
        setRelatedSolutions(solutions ?? []);
        setInitialRelationships(relationships ?? []);
    };

    const initializeSolution = useCallback(
        (solution?: SolutionRecord) => {
            setInitialSolution(solution ?? new SolutionRecord());
            setSolution(solution ?? new SolutionRecord());
            setContext((context: AdminEditorPageContextRecord) =>
                context.with({ currentValue: solution })
            );
        },
        [setContext]
    );

    const initializeCategories = (
        categoryCollection?: CategoryCollectionRecord
    ) => {
        setCategories(categoryCollection ?? new CategoryCollectionRecord());
        setInitialCategories(
            categoryCollection ?? new CategoryCollectionRecord()
        );
    };

    const initializeSections = (sections?: Array<SectionRecord>) => {
        setCodeSections(sections ?? []);
        setInitialCodeSections(sections ?? []);
    };

    const initializeResources = (
        resources?: Array<SolutionResourceRecord>,
        files?: Array<FileRecord>
    ) => {
        const collection = ResourceCollectionRecord.fromArrays(
            SolutionResourceRecord,
            resources ?? [],
            files ?? []
        );
        setResources(collection);
        setInitialResources(collection.clone()); // make a copy
    };

    const reset = useCallback(() => {
        initializeRelatedSolutions();
        initializeResources();
        initializeSections();
        initializeCategories();
    }, []);

    const loadAllData = useCallback(async () => {
        const loadSolution = async () => {
            setLoadingSolution(true);
            const slnId = NumberUtils.parseInt(id);
            if (slnId == null) {
                initializeSolution();
                setLoadingSolution(false);
                return;
            }

            try {
                const result = await getSolutionApi({ id: slnId });
                initializeSolution(result.resultObject ?? new SolutionRecord());
            } catch (e) {
                ToastManager.error("There was an issue loading the solution.");
            }

            setLoadingSolution(false);
        };

        if (NumberUtils.parseInt(id) == null) {
            return;
        }

        const loadSolutionCategories = async () => {
            setLoadingCategories(true);
            try {
                const result = await listCategoriesApi({
                    solutionId: NumberUtils.parseInt(id),
                });
                if (CollectionUtils.hasValues(result.resultObjects)) {
                    // even though the fromArray method does it's own null-check,
                    // it still breaks in IE11 without this conditional here for some reason...
                    const collection = CategoryCollectionRecord.fromArray(
                        result.resultObjects
                    );
                    initializeCategories(collection);
                } else {
                    initializeCategories();
                }
            } catch (e) {
                ToastManager.error(
                    "There was an issue loading solution categories."
                );
            }
            setLoadingCategories(false);
        };

        const loadSolutionSections = async () => {
            setLoadingSections(true);
            try {
                const result = await listSectionsApi({
                    solutionId: NumberUtils.parseInt(id)!,
                });
                initializeSections(result.resultObjects);
            } catch (e) {
                ToastManager.error(
                    "There was an issue loading solution code sections."
                );
            }
            setLoadingSections(false);
        };

        const loadFiles = async (
            fileIds: Array<number>
        ): Promise<Array<FileRecord>> => {
            if (CollectionUtils.isEmpty(fileIds)) {
                return [];
            }

            try {
                const result = (await Promise.all(
                    fileIds.map((id: number) => getFileApi({ id }))
                )) as Array<ServiceResponse<FileRecord>>;
                setLoadingFiles(false);
                return result.map(
                    (response: ServiceResponse<FileRecord>) =>
                        response.resultObject!
                );
            } catch (e) {
                ToastManager.error(
                    "There was an issue loading resource files."
                );
            }

            return [];
        };

        const loadResources = async () => {
            setLoadingSolutionResources(true);
            setLoadingFiles(true);

            try {
                const result = await listResourcesApi({
                    solutionId: NumberUtils.parseInt(id)!,
                });
                const fileIds = (result.resultObjects ?? [])
                    .map((r: SolutionResourceRecord) => r.fileDraftId)
                    .filter(
                        (id: number | undefined) => id != null
                    ) as Array<number>;
                const files = await loadFiles(fileIds);
                initializeResources(result.resultObjects, files);
            } catch (e) {
                ToastManager.error(
                    "There was an issue loading solution resources."
                );
            }

            setLoadingSolutionResources(false);
            setLoadingFiles(false);
        };

        const loadRelatedSolutions = async () => {
            setLoadingRelatedSolutions(true);

            try {
                const slnId = NumberUtils.parseInt(id)!;
                const result = await listRelationshipsApi({
                    solutionId: slnId,
                });
                if (CollectionUtils.isEmpty(result.resultObjects)) {
                    initializeRelatedSolutions();
                    setLoadingRelatedSolutions(false);
                    return;
                }
                // get list of IDs which are NOT this current solution's ID (i.e. the related solution IDs)
                const relatedSolutionIds = result.resultObjects!.map(
                    (relationship: SolutionRelationshipRecord) =>
                        relationship.solution1Id === slnId
                            ? relationship.solution2Id
                            : relationship.solution1Id
                );

                const solutionsResult = (await Promise.all(
                    relatedSolutionIds.map((id: number) =>
                        getSolutionApi({ id })
                    )
                )) as Array<ServiceResponse<SolutionRecord>>;
                const solutions = solutionsResult
                    .map((r: ServiceResponse<SolutionRecord>) => r.resultObject)
                    .filter((s: SolutionRecord | undefined) => s != null)
                    // Additional map to let TS know we know it's not nil
                    .map((s: SolutionRecord | undefined) => s!);

                initializeRelatedSolutions(solutions, result.resultObjects!);
            } catch (e) {
                ToastManager.error(
                    "There was an issue loading related solutions."
                );
            }

            setLoadingRelatedSolutions(false);
        };

        await Promise.all([
            loadSolution(),
            loadSolutionCategories(),
            loadSolutionSections(),
            loadResources(),
            loadRelatedSolutions(),
        ]);
    }, [
        id,
        listCategoriesApi,
        listSectionsApi,
        getFileApi,
        listResourcesApi,
        listRelationshipsApi,
        getSolutionApi,
        initializeSolution,
    ]);

    /**
     * reset data when ID changes
     */
    useEffect(() => reset(), [id, reset]);

    /**
     * when the id changes in the route, update the selected
     * solution/data and reload the selected spaces
     */
    useEffect(() => {
        loadAllData();
    }, [id, loadAllData]);

    /**
     * update the isDirty flag when any of the data changes
     */
    useEffect(() => {
        if (solution == null) {
            // this only happens when you delete a solution
            setIsDirty(false);
            return;
        }

        if (solution.id == null) {
            setIsDirty(
                solution.titleDraft.length > 0 ||
                    solution.subtitleDraft.length > 0 ||
                    solution.bodyDraft.length > 0 ||
                    categories.hasValues() ||
                    codeSections.length > 0 ||
                    resources.hasValues() ||
                    relatedSolutions.length > 0
            );
            return;
        }

        setIsDirty(
            solution.titleDraft !== initialSolution.titleDraft ||
                solution.subtitleDraft !== initialSolution.subtitleDraft ||
                solution.bodyDraft !== initialSolution.bodyDraft ||
                !CollectionUtils.equalsBy(
                    (c: CategoryRecord) => c.id,
                    categories.toList().toJS() as CategoryRecord[],
                    initialCategories.toList().toJS() as CategoryRecord[]
                ) ||
                !CollectionUtils.equalsBy(
                    (s: SectionRecord) => s.id,
                    codeSections,
                    initialCodeSections
                ) ||
                !CollectionUtils.equalsBy(
                    (r: SolutionResourceRecord) => r.id,
                    resources.resources as unknown as SolutionResourceRecord[],
                    initialResources.resources as unknown as SolutionResourceRecord[]
                ) ||
                !CollectionUtils.equalsBy(
                    (r: SolutionResourceRecord) => r.fileDraftId,
                    resources.resources as unknown as SolutionResourceRecord[],
                    initialResources.resources as unknown as SolutionResourceRecord[]
                ) ||
                !CollectionUtils.equalsBy(
                    (s: SolutionRecord) => s.id,
                    relatedSolutions,
                    initialRelatedSolutions
                )
        );
    }, [
        id,
        context,
        initialSolution,
        solution,
        categories,
        initialCategories,
        codeSections,
        initialCodeSections,
        resources,
        initialResources,
        relatedSolutions,
        initialRelatedSolutions,
    ]);

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        setSolution((prevState: SolutionRecord) =>
            prevState.with({ titleDraft: e.target.value })
        );
    const handleSubtitleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        setSolution((prevState: SolutionRecord) =>
            prevState.with({ subtitleDraft: e.target.value })
        );
    const handleBodyChange = (newValue: string) =>
        setSolution((prevState: SolutionRecord) =>
            prevState.with({ bodyDraft: newValue })
        );
    const handleCategoriesChanged = (newValues: CategoryCollectionRecord) =>
        setCategories(newValues);
    const handleSectionAdded = (newValue: SectionRecord) =>
        setCodeSections([...codeSections, newValue]);
    const handleSectionRemoved = (removedValue: SectionRecord) =>
        setCodeSections(
            codeSections.filter(
                (section: SectionRecord) => section.id !== removedValue.id
            )
        );
    const handleRelatedSolutionAdded = (newValue: SolutionRecord) =>
        setRelatedSolutions([...relatedSolutions, newValue]);
    const handleRelatedSolutionRemoved = (removedValue: SolutionRecord) =>
        setRelatedSolutions(
            relatedSolutions.filter(
                (sln: SolutionRecord) => sln.id !== removedValue.id
            )
        );

    const handleFormSubmit = () => {
        if (!validate()) {
            return;
        }

        if (solution.id == null) {
            createSolution();
            return;
        }

        updateSolution();
    };

    const handleResourceChanged = (
        resources: ResourceCollectionRecord<SolutionResourceRecord>
    ) => setResources(resources);

    const handleDeleteClick = () => {
        if (id === undefined) {
            return;
        }

        deleteSolution(id);
    };

    const updateSolutionCategories = async (solutionId?: number) => {
        const slnId = solutionId ?? NumberUtils.parseInt(id)!;
        const solutionCategories = categories.toList().map(
            (c: CategoryRecord) =>
                new SolutionCategoryRecord({
                    solutionId: slnId,
                    categoryId: c.id ?? -1,
                })
        );
        try {
            await updateSolutionCategoriesApi(solutionCategories.toArray(), {
                solutionId: slnId,
            });
            setInitialCategories(categories);
        } catch (e) {
            ToastManager.error(
                "There was an issue updating solution categories."
            );
        }
    };

    const updateSolutionSections = async (solutionId?: number) => {
        const slnId = solutionId ?? NumberUtils.parseInt(id)!;
        const solutionSections = codeSections.map(
            (s: SectionRecord) =>
                new SolutionSectionRecord({
                    solutionId: slnId,
                    sectionId: s.id!,
                })
        );
        try {
            await updateSolutionSectionsApi(solutionSections, {
                solutionId: slnId,
            });
            setInitialCodeSections(codeSections);
        } catch (e) {
            ToastManager.error(
                "There was an issue updating solution code sections."
            );
        }
    };

    const handleDeletingResources = async (
        resourcesToBeDeleted: ResourceCollectionRecord<SolutionResourceRecord>,
        solutionId: number
    ): Promise<void> => {
        const deleteResults = await Promise.all(
            resourcesToBeDeleted
                .map((resource: SolutionResourceRecord) =>
                    deleteResourceApi(resource.id!, { solutionId })
                )
                .toArray()
        );

        const allSucceeded = deleteResults.some(
            (response: ServiceResponse<Boolean>) => !response.resultObject
        );
        if (allSucceeded) {
            ToastManager.error(
                "There was an issue deleting one or more solution resources."
            );
        }
    };

    const updateResources = async (solutionId?: number) => {
        const slnId = solutionId ?? NumberUtils.parseInt(id)!;
        const solutionResources = resources.map((r: SolutionResourceRecord) =>
            r.with({ solutionId: slnId })
        );
        try {
            // get IDs that are missing from current list but exist in initial list
            const resourcesToDelete = initialResources.filterResources(
                (initialResource: SolutionResourceRecord) =>
                    !resources.anyResources(
                        (resource: SolutionResourceRecord) =>
                            initialResource.id != null &&
                            initialResource.id === resource.id
                    )
            );

            // wait for all create, update, and delete promises
            const results = await Promise.all(
                (
                    solutionResources
                        .map((r: SolutionResourceRecord) =>
                            r.id == null
                                ? createResourceApi(r, {
                                      solutionId: r.solutionId,
                                  })
                                : updateResourceApi(r, {
                                      solutionId: r.solutionId,
                                  })
                        )
                        .toArray() as Array<Promise<any>>
                ).concat(handleDeletingResources(resourcesToDelete, slnId))
            );
            const createAndUpdateResults = results.filter(
                (value: any) => value?.resultObject != null
            ) as Array<ServiceResponse<SolutionResourceRecord>>;
            const newResources = createAndUpdateResults.map(
                (r: ServiceResponse<SolutionResourceRecord>) => r.resultObject!
            );
            const newFiles = resources.files.filter((f: FileRecord) =>
                newResources.some(
                    (r: SolutionResourceRecord) =>
                        r.fileId === f.id || r.fileDraftId === f.id
                )
            );
            const newResourcesCollection = resources.with({
                resources: List(newResources),
                files: newFiles,
            });
            setResources(newResourcesCollection);
            setInitialResources(newResourcesCollection);
        } catch (e) {
            ToastManager.error(
                "There was an issue updating solution resources."
            );
        }
    };

    const updateRelatedSolutions = async (solutionId?: number) => {
        const slnId = solutionId ?? NumberUtils.parseInt(id)!;
        try {
            const relationshipsToDelete = initialRelationships.filter(
                (r: SolutionRelationshipRecord) =>
                    !relatedSolutions.some(
                        (s: SolutionRecord) =>
                            r.solution1Id === s.id || r.solution2Id === s.id
                    )
            );
            const relationshipsToCreate = relatedSolutions
                .filter(
                    (s: SolutionRecord) =>
                        !initialRelationships.some(
                            (r: SolutionRelationshipRecord) =>
                                r.solution1Id === s.id || r.solution2Id === s.id
                        )
                )
                .map(
                    (s: SolutionRecord) =>
                        new SolutionRelationshipRecord({
                            solution1Id: slnId,
                            solution2Id: s.id!,
                        })
                );
            await Promise.all<Promise<ServiceResponse<any>>>([
                ...relationshipsToDelete.map((r: SolutionRelationshipRecord) =>
                    deleteRelationshipApi(r.id!, { solutionId: slnId })
                ),
                ...relationshipsToCreate.map((r: SolutionRelationshipRecord) =>
                    createRelationshipApi(r, { solutionId: slnId })
                ),
            ]);
            const indexResult = await listRelationshipsApi({
                solutionId: slnId,
            });
            if (CollectionUtils.isEmpty(indexResult.resultObjects)) {
                initializeRelatedSolutions();
                return;
            }
            // get list of IDs which are NOT this current solution's ID (i.e. the related solution IDs)
            const relatedSolutionIds = indexResult.resultObjects!.map(
                (relationship: SolutionRelationshipRecord) =>
                    relationship.solution1Id === slnId
                        ? relationship.solution2Id
                        : relationship.solution1Id
            );
            const solutionsResult = (await Promise.all(
                relatedSolutionIds.map((id: number) => getSolutionApi({ id }))
            )) as Array<ServiceResponse<SolutionRecord>>;
            const solutions = solutionsResult
                .map((r: ServiceResponse<SolutionRecord>) => r.resultObject)
                .filter(
                    (s: SolutionRecord | undefined) => s != null
                ) as Array<SolutionRecord>;
            initializeRelatedSolutions(solutions, indexResult.resultObjects);
        } catch (e) {
            ToastManager.error(
                "There was an issue updating related solutions."
            );
        }
    };

    const handleFileDeletion = async (fileIds: Array<number>) => {
        if (CollectionUtils.isEmpty(fileIds)) {
            return;
        }

        const fileDeleteResults = await Promise.all(
            fileIds.map((id: number) => FileUtils.deleteFileById(id))
        );
        if (fileDeleteResults.some((success: boolean) => !success)) {
            ToastManager.error(
                "There was an issue deleting one or more removed files."
            );
        }
    };

    const handlePublishClick = async () => {
        setLoadingSolution(true);

        let fileIdsToDelete = resources.resources
            .filter((r: SolutionResourceRecord) => {
                if (r.fileId == null) {
                    return false;
                }

                if (r.fileDraftId == null) {
                    return true;
                }

                return r.fileId !== r.fileDraftId;
            })
            .map((r: SolutionResourceRecord) => r.fileId)
            .filter((id) => id != null)
            .toArray() as Array<number>;

        const deletedResources = initialResources.resources.filter(
            (deleted: SolutionResourceRecord) =>
                resources.resources.find(
                    (r: SolutionResourceRecord) => r.id === deleted.id
                ) == null
        );
        const fileIdsForDeletedResources = deletedResources
            .map((r: SolutionResourceRecord) => r.fileId)
            .concat(
                deletedResources.map(
                    (r: SolutionResourceRecord) => r.fileDraftId
                )
            )
            .filter((id) => id != null)
            .toArray() as Array<number>;

        if (CollectionUtils.hasValues(fileIdsForDeletedResources)) {
            fileIdsToDelete = [
                ...fileIdsToDelete,
                ...fileIdsForDeletedResources,
            ];
        }

        if (isDirty) {
            await updateSolution(true);
            await handleFileDeletion(fileIdsToDelete);
            return;
        }

        try {
            await publishSolutionApi(solution, {
                id: solution.id!,
            });
            await handleFileDeletion(fileIdsToDelete);
            await loadAllData();

            ToastManager.success(SOLUTION_PUBLISH_SUCCESSFUL);
        } catch (e) {
            ToastManager.error(SOLUTION_PUBLISH_ERROR);
        }

        setLoadingSolution(false);
    };

    const handleUnpublishClick = async () => {
        setLoadingSolution(true);

        try {
            const unpublished = solution.with({
                publishedById: undefined,
                publishedOn: undefined,
            });
            await unpublishSolutionApi(unpublished.id!);
            initializeSolution(
                solution.with({
                    publishedOn: undefined,
                    publishedById: undefined,
                })
            );
            ToastManager.success(SOLUTION_UNPUBLISH_SUCCESSFUL);
        } catch (e) {
            ToastManager.error(SOLUTION_UNPUBLISH_ERROR);
        }

        setLoadingSolution(false);
    };

    const updateSolution = async (shouldPublish: boolean = false) => {
        setLoadingSolution(true);
        try {
            const [updateResult] = await Promise.all([
                updateSolutionApi(solution, {
                    id: solution.id!!,
                }),
                updateSolutionCategories(),
                updateSolutionSections(),
                updateResources(),
                updateRelatedSolutions(),
            ]);

            if (shouldPublish) {
                try {
                    const result = await publishSolutionApi(solution, {
                        id: solution.id!,
                    });
                    initializeSolution(result.resultObject!);
                    ToastManager.success(SOLUTION_PUBLISH_SUCCESSFUL);
                } catch (e) {
                    handleSubmitError(e);
                    ToastManager.error(SOLUTION_PUBLISH_ERROR);
                }

                setLoadingSolution(false);
                return;
            }

            initializeSolution(updateResult.resultObject!);
            ToastManager.success(SOLUTION_SAVE_SUCCESSFUL);
        } catch (e) {
            handleSubmitError(e);
            ToastManager.error(SOLUTION_SAVE_ERROR);
        }
        setLoadingSolution(false);
    };

    const createSolution = async () => {
        setLoadingSolution(true);
        try {
            const createResult = await createSolutionApi(solution);
            await Promise.all([
                updateSolutionCategories(createResult.resultObject!.id),
                updateSolutionSections(createResult.resultObject!.id),
                updateResources(createResult.resultObject!.id),
                updateRelatedSolutions(createResult.resultObject!.id),
            ]);
            ToastManager.success(SOLUTION_CREATED_SUCCESSFUL);
            setRedirectIgnoreIsDirty(
                RouteUtils.getUrl(
                    siteMap.admin.situationalNavigation.solutions.edit,
                    {
                        id: createResult.resultObject!.id,
                    }
                )
            );
            return;
        } catch (e) {
            handleSubmitError(e);
            ToastManager.error(SOLUTION_CREATED_ERROR);
        }
        setLoadingSolution(false);
    };

    const deleteSolution = async (id: string) => {
        setLoadingSolution(true);
        try {
            const response = await AdminSolutionService.delete(
                Number.parseInt(id)
            );

            if (response.result!.hasErrors()) {
                return;
            }

            // date format doesn't matter here, it's not saved to database; setting it just
            // to tell sidebar to remove it from the list
            setContext(
                context.with({
                    currentValue: context.currentValue?.with({
                        deletedOn: moment().format(),
                    }),
                })
            );

            ToastManager.success(SOLUTION_DELETED_SUCCESSFUL);
            setRedirectIgnoreIsDirty(
                RouteUtils.getUrl(
                    siteMap.admin.situationalNavigation.solutions.dashboard
                )
            );
        } catch (e) {
            ToastManager.error(SOLUTION_DELETED_ERROR);
        }
        setLoadingSolution(false);
    };

    const validate = (): boolean => {
        let valid = true;
        if (solution.titleDraft.length === 0) {
            valid = false;
            setTitleErrors(["Title is required."]);
        } else {
            resetTitleErrors();
        }

        return valid;
    };

    if (StringUtils.hasValue(redirectIgnoreIsDirty)) {
        return <Redirect to={redirectIgnoreIsDirty!} />;
    }

    return (
        <div className={`${cssClassName} c-solution-editor`}>
            <div
                className={`${cssClassName}__content`}
                id={SolutionEditorContentAreaId}>
                <Paragraph
                    size={ParagraphSizes.Large}
                    cssClassName={`${cssClassName}__content__heading`}>
                    {solution.id == null ? "New" : "Edit"} Solution
                </Paragraph>
                <Form onSubmit={handleFormSubmit}>
                    <InputFormField
                        disabled={loading}
                        label="Title"
                        onChange={handleTitleChange}
                        required={true}
                        value={solution.titleDraft}
                        errorMessages={titleErrors}
                        maxLength={150}
                        isValid={titleErrors.length === 0}
                        placeholder="Solution title..."
                    />
                    <InputFormField
                        disabled={loading}
                        label="Subtitle"
                        onChange={handleSubtitleChange}
                        value={solution.subtitleDraft}
                        maxLength={150}
                        isValid={true}
                        placeholder="Solution subtitle..."
                    />
                    <RichTextEditor
                        allowImages={true}
                        disabled={loading}
                        label="Body"
                        onChange={handleBodyChange}
                        placeholder="Solution body..."
                        preserveWhitespace={true}
                        value={solution.bodyDraft}
                    />
                    <AdminCategoriesSection
                        baseClassName={cssClassName}
                        dropdownPortal={getDropdownPortalElement()}
                        onChange={handleCategoriesChanged}
                        options={context.categories}
                        loading={loading}
                        value={categories}
                    />
                    <SolutionCodeSections
                        dropdownPortal={getDropdownPortalElement()}
                        value={codeSections}
                        onSelected={handleSectionAdded}
                        onRemoved={handleSectionRemoved}
                    />
                    <AdminResourcesSection
                        loading={loadingResources}
                        resources={resources}
                        onResourcesChanged={handleResourceChanged}
                        recordType={SolutionResourceRecord}
                    />
                    <AdminRelatedSolutionsSection
                        currentSolutionId={NumberUtils.parseInt(id)}
                        dropdownPortal={getDropdownPortalElement()}
                        onRemoved={handleRelatedSolutionRemoved}
                        onSelected={handleRelatedSolutionAdded}
                        value={relatedSolutions}
                    />
                </Form>
            </div>
            <div className={`${cssClassName}__footer`}>
                {loading && (
                    <Loader
                        accessibleText={
                            solution.id == null ? "Creating" : "Saving"
                        }
                    />
                )}
                {!loading && (
                    <React.Fragment>
                        <div className={`${cssClassName}__footer__left`}>
                            {solution.id == null && (
                                <Anchor
                                    to={
                                        siteMap.admin.situationalNavigation
                                            .solutions.dashboard
                                    }
                                    cssClassName="c-button -secondary">
                                    Cancel
                                </Anchor>
                            )}
                            <Button onClick={handleFormSubmit}>
                                {solution.id == null ? "Create" : "Save"}{" "}
                                Solution
                            </Button>
                        </div>
                        <div className={`${cssClassName}__footer__right`}>
                            <PublishStatusMenu
                                isValueDirty={isDirty}
                                onDeleteClick={handleDeleteClick}
                                onPublishClick={handlePublishClick}
                                onUnpublishClick={handleUnpublishClick}
                                recordLabel="solution"
                                value={solution}
                            />
                        </div>
                    </React.Fragment>
                )}
            </div>
            <UnsavedChangesPrompt
                isDirty={isDirty}
                message="Any unsaved changes will be lost."
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default SolutionEditor;

// #endregion Exports
