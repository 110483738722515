import Loader from "atoms/loaders/loader";
import { List } from "immutable";
import EnhancedContentRecord from "models/view-models/enhanced-content-record";
import EnhancedContentAdminTableRow from "organisms/admin/publications/enhanced-content/enhanced-content-admin-table-row";
import DataTable from "organisms/data-tables/data-table";
import React from "react";
import { CollectionUtils } from "utilities/collection-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface EnhancedContentAdminTableProps {
    enhancedContentRecords: List<EnhancedContentRecord>;
    loading: boolean;
    refreshRecords: () => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-enhanced-content-admin-table";
const CSS_CLASS_NAME_HEADER = `${CSS_CLASS_NAME}__header`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EnhancedContentAdminTable: React.FC<EnhancedContentAdminTableProps> = (
    props: EnhancedContentAdminTableProps
) => {
    const { enhancedContentRecords, loading, refreshRecords } = props;

    if (loading) {
        return <Loader accessibleText={"Loading Enhanced Content..."} />;
    }

    return (
        <DataTable cssClassName={`${CSS_CLASS_NAME}`}>
            <thead className={`${CSS_CLASS_NAME_HEADER}`}>
                <tr>
                    <th className={`${CSS_CLASS_NAME_HEADER}-content`}>
                        Content
                    </th>
                    <th className={`${CSS_CLASS_NAME_HEADER}-publication`}>
                        Publication
                    </th>
                    <th className={`${CSS_CLASS_NAME_HEADER}-note`}>Note</th>
                    <th
                        className={`${CSS_CLASS_NAME_HEADER}-last-change -right-col`}>
                        Last Change
                    </th>
                    <th
                        className={`${CSS_CLASS_NAME_HEADER}-status -right-col`}>
                        Status
                    </th>
                    <th className={"-collapse"}>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                {enhancedContentRecords.map(
                    (enhancedContentRecord: EnhancedContentRecord) => (
                        <EnhancedContentAdminTableRow
                            enhancedContent={enhancedContentRecord}
                            key={enhancedContentRecord.id}
                            onRefresh={refreshRecords}
                        />
                    )
                )}

                {CollectionUtils.isEmpty(
                    enhancedContentRecords as unknown as EnhancedContentRecord[]
                ) && (
                    <tr>
                        <td colSpan={6} className="-no-results-row">
                            No Results
                        </td>
                    </tr>
                )}
            </tbody>
        </DataTable>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default EnhancedContentAdminTable;

// #endregion Exports
