import { RecordUtils, ResultRecord } from "@rsm-hcd/javascript-core";
import UserBookmarkConstants from "constants/user-bookmark-constants";
import { UserBookmarkValidator } from "models/validation/user-bookmark-validator";
import SectionRecord from "models/view-models/section-record";
import UserBookmarkRecord from "models/view-models/user-bookmark-record";
import UserCollectionRecord from "models/view-models/user-collection-record";
import BookmarkSettingsContent from "molecules/bookmark-settings/bookmark-settings-content";
import BookmarkSettingsFooter from "molecules/bookmark-settings/bookmark-settings-footer";
import BookmarkSettingsHeader from "molecules/bookmark-settings/bookmark-settings-header";
import { ConfirmationModal } from "molecules/modals/confirmation-modal";
import BookmarkSettingsMobileModal from "organisms/section-detail/bookmark-settings-mobile-modal";
import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import useCurrentPublication from "utilities/contexts/use-current-publication";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import { UserBookmarkColors } from "utilities/enumerations/user-bookmark-colors";
import UserBookmarkType from "utilities/enumerations/user-bookmark-type";
import useBookmarkCollections from "utilities/hooks/domain/my-link/use-bookmark-collections";
import useDeleteBookmark from "utilities/hooks/domain/my-link/use-delete-bookmark";
import useUpdateBookmarkCollections from "utilities/hooks/domain/my-link/use-update-bookmark-collections";
import useBookviewTableOfContents from "utilities/hooks/domain/publications/use-bookview-table-of-contents";
import useBookmarks from "utilities/hooks/domain/user-bookmarks/use-bookmarks";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import useDebounce from "utilities/hooks/use-debounce";
import useLoading from "utilities/hooks/use-loading";
import { useLocalization } from "utilities/hooks/use-localization";
import usePageErrors from "utilities/hooks/use-page-errors";
import { t } from "utilities/localization-utils";
import {
    CreateService,
    UpdateService,
} from "utilities/services/service-factory";
import UserBookmarkService, {
    UserBookmarkResourcePathParams,
} from "utilities/services/user-bookmarks/user-bookmark-service";
import StringUtils from "utilities/string-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import { ObjectValidator } from "utilities/validation/object-validator/object-validator";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface BookmarkSettingsProps {
    onCancel: () => void;
    onDelete: () => void;
    externalId: string;
    title: string;
    bookmark?: UserBookmarkRecord;
    section?: SectionRecord;
    userBookmarkType: UserBookmarkType;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-bookmark-settings";
const FOOTER_CLASS_NAME = `${BASE_CLASS_NAME}__footer`;
const HEADER_CLASS_NAME = `${BASE_CLASS_NAME}__header`;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const BookmarkSettings: React.FunctionComponent<BookmarkSettingsProps> = (
    props: BookmarkSettingsProps
) => {
    const {
        bookmark,
        onCancel,
        onDelete,
        externalId,
        section,
        title,
        userBookmarkType,
    } = props;
    const { t } = useLocalization();
    const { publication } = useCurrentPublication();

    const { globalState } = useGlobalState();
    const { handlePageLoadError, pageErrors, resetPageErrors } =
        usePageErrors();
    const { create } = UserBookmarkService.useCreate();
    const { update } = UserBookmarkService.useUpdate();
    const {
        addBookmark,
        getUserBookmarks,
        removeUserBookmark,
        updateBookmark,
    } = useBookmarks();
    const { updateBookmarkIndicator, tableOfContents } =
        useBookviewTableOfContents();

    const initialUserBookmark =
        bookmark ??
        getUserBookmarks(externalId, globalState.currentIdentity?.userId());

    const newBookMarkRecord = new UserBookmarkRecord().with({
        userBookmarkType,
        createdOnPublicationId: publication?.id,
        color: UserBookmarkColors.Red,
        externalId,
        userId: globalState.currentIdentity?.userId(),
        groupId: globalState.currentIdentity?.getCurrentTeam()?.id ?? undefined,
    });

    const [loadingBookmark, setLoadingBookmark] = useState(false);
    const [userBookmark, setUserBookmark] = useState<UserBookmarkRecord>(
        initialUserBookmark ? initialUserBookmark : newBookMarkRecord
    );

    const [showCancelConfirmationModal, setShowCancelConfirmationModal] =
        useState(false);

    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
        useState(false);

    const {
        collectionBookmarks,
        collections,
        setCollections,
        allCollections,
        loading: loadingCollections,
    } = useBookmarkCollections(userBookmark.id);

    const { updateUserCollectionBookmarks, saving: savingCollections } =
        useUpdateBookmarkCollections(collectionBookmarks);

    const loading = useDebounce(
        useLoading(loadingBookmark, loadingCollections, savingCollections)
    );

    const isMobile = useBreakpoint(
        Breakpoints.Phone,
        BreakpointComparer.MaxWidth
    );

    const { deleteBookmark } = useDeleteBookmark(userBookmark, onDelete);

    // Event handlers
    const handleDeleteConfirmed = useCallback(async () => {
        setShowDeleteConfirmationModal(false);
        await deleteBookmark();
        removeUserBookmark(userBookmark);
    }, [deleteBookmark, removeUserBookmark, userBookmark]);

    const notesLabel = (
        <label>
            {`${t("field-notes")} `}
            <label className="-help-text">{t("field-optional")}</label>
        </label>
    );

    const bookmarkSettings = (
        <React.Fragment>
            <BookmarkSettingsHeader
                cssClassName={HEADER_CLASS_NAME}
                onCancelClick={handleCancelClick}
                initialUserBookmark={initialUserBookmark!}
                isMobile={isMobile}
                loading={loading}
                onCancel={onCancel}
                title={title}
                setShowCancelConfirmationModal={setShowCancelConfirmationModal}
                setShowDeleteConfirmationModal={setShowDeleteConfirmationModal}
                userBookmark={userBookmark}
            />
            <BookmarkSettingsContent
                allCollections={allCollections}
                collections={collections}
                cssClassName={`${BASE_CLASS_NAME}__content`}
                onDescriptionChanged={(newValue: string, rawValue: string) =>
                    handleDescriptionChanged(
                        newValue,
                        rawValue,
                        resetPageErrors,
                        setUserBookmark
                    )
                }
                onUserBookmarkColorChanged={(newValue: number) =>
                    handleUserBookmarkColorChanged(
                        newValue,
                        resetPageErrors,
                        setUserBookmark
                    )
                }
                isMobile={isMobile}
                loading={loading}
                notesLabel={notesLabel}
                pageErrors={pageErrors}
                setCollections={setCollections}
                userBookmark={userBookmark}
            />
            <BookmarkSettingsFooter
                cssClassName={FOOTER_CLASS_NAME}
                isMobile={isMobile}
                loading={loading}
                onCancelClick={() =>
                    handleCancelClick(
                        initialUserBookmark,
                        userBookmark,
                        setShowCancelConfirmationModal,
                        onCancel
                    )
                }
                onConfirm={() =>
                    handleConfirm(
                        addBookmark,
                        create,
                        onCancel,
                        handlePageLoadError,
                        resetPageErrors,
                        publication?.id,
                        setLoadingBookmark,
                        update,
                        updateBookmark,
                        userBookmark,
                        collections,
                        updateUserCollectionBookmarks,
                        () =>
                            updateBookmarkIndicator(
                                true,
                                tableOfContents,
                                section
                            )
                    )
                }
                setShowDeleteConfirmationModal={setShowDeleteConfirmationModal}
            />
            <ConfirmationModal
                confirmButtonText={t("yesAction", { action: t("continue") })}
                isVisible={showCancelConfirmationModal}
                message={t("cancelOrConfirmWithoutSaving")}
                onCancel={() => setShowCancelConfirmationModal(false)}
                onConfirm={onCancel}
            />
            <ConfirmationModal
                confirmButtonText={t("yesAction", { action: t("delete") })}
                isVisible={showDeleteConfirmationModal}
                label={t("userbookmarks-deleteLabel")}
                message={t("userbookmarks-deleteMessage")}
                onCancel={() => setShowDeleteConfirmationModal(false)}
                onConfirm={handleDeleteConfirmed}
            />
        </React.Fragment>
    );

    if (isMobile) {
        return (
            <BookmarkSettingsMobileModal
                cssClassName={`${BASE_CLASS_NAME}-mobile-modal`}
                onCancelClick={() =>
                    handleCancelClick(
                        initialUserBookmark,
                        userBookmark,
                        setShowCancelConfirmationModal,
                        onCancel
                    )
                }
                initialUserBookmark={initialUserBookmark!}>
                {bookmarkSettings}
            </BookmarkSettingsMobileModal>
        );
    }

    return <div className={BASE_CLASS_NAME}>{bookmarkSettings}</div>;
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

const handleCancelClick = (
    initialBookmark: UserBookmarkRecord | undefined,
    currentBookmark: UserBookmarkRecord,
    setShowConfirmModal: Dispatch<SetStateAction<boolean>>,
    onCancel: () => void
): void => {
    let isDirty: boolean;
    if (StringUtils.isEmpty(initialBookmark?.descriptionAsPlainText)) {
        isDirty = StringUtils.hasValue(currentBookmark.descriptionAsPlainText);
    } else {
        isDirty =
            initialBookmark?.descriptionAsPlainText !==
            currentBookmark.descriptionAsPlainText;
    }

    if (isDirty) {
        setShowConfirmModal(true);
        return;
    }

    onCancel();
};

const handleDescriptionChanged = (
    newValue: string,
    rawValue: string,
    resetPageErrors: () => void,
    setUserBookmark: Dispatch<SetStateAction<UserBookmarkRecord>>
): void => {
    resetPageErrors();

    setUserBookmark((userBookmark: UserBookmarkRecord) =>
        userBookmark.with({
            description: newValue,
            descriptionAsPlainText: rawValue,
        })
    );
};

const handleConfirm = (
    addBookmark: (bookmark: UserBookmarkRecord) => void,
    create: CreateService<UserBookmarkRecord>,
    handleClose: () => void,
    handlePageLoadError: (result: any) => void,
    resetPageErrors: () => void,
    publicationId: number | undefined,
    setIsLoading: Dispatch<SetStateAction<boolean>>,
    update: UpdateService<UserBookmarkRecord, UserBookmarkResourcePathParams>,
    updateBookmark: (bookmark: UserBookmarkRecord) => void,
    userBookmark: UserBookmarkRecord,
    selectedCollections: Array<UserCollectionRecord>,
    updateUserCollectionBookmarks: (
        bookmarkId: number,
        collectionIds: Array<number>
    ) => Promise<boolean>,
    refreshUserBookmarkIndicators?: () => void
): void => {
    resetPageErrors();

    if (!isValid(userBookmark, resetPageErrors, handlePageLoadError)) {
        return;
    }

    const createOrUpdateNote = async () => {
        const updateCollections = async (
            bookmarkId: number
        ): Promise<boolean> => {
            return await updateUserCollectionBookmarks(
                bookmarkId,
                selectedCollections.map((uc: UserCollectionRecord) => uc.id!)
            );
        };

        if (userBookmark.isPersisted()) {
            try {
                setIsLoading(true);

                // Copy over the updated PublicationId from the publicationPageContext's currentSection
                userBookmark = userBookmark.with({
                    updatedOnPublicationId: publicationId,
                });

                await update(userBookmark);

                if (!(await updateCollections(userBookmark.id!))) {
                    setIsLoading(false);
                    return;
                }

                updateBookmark(userBookmark);
                refreshUserBookmarkIndicators?.();
                setIsLoading(false);
                handleClose();
                ToastManager.success(t("changesSuccessfullySaved"));
            } catch (result) {
                setIsLoading(false);

                // If somehow we got an error for the description back, short-circuit and show the
                // empty description message.
                if (
                    RecordUtils.isRecord(result, ResultRecord) &&
                    result.hasErrorFor("Description")
                ) {
                    handlePageLoadError(result.listErrorMessages());
                    return;
                }

                ToastManager.error(
                    t("errors-actionResource", {
                        action: t("saving"),
                        resource: t("bookmark"),
                    })
                );
            }

            return;
        }

        try {
            setIsLoading(true);
            const result = await create(userBookmark);

            if (!(await updateCollections(result.resultObject!.id!))) {
                setIsLoading(false);
                return;
            }

            if (result.resultObject != null) addBookmark(result.resultObject);

            refreshUserBookmarkIndicators?.();

            setIsLoading(false);
            handleClose();

            ToastManager.success(t("successfullySavedToMyLiNK"));
        } catch (result) {
            setIsLoading(false);

            // If somehow we got an error for the description back, short-circuit and show the
            // empty description message.
            if (
                RecordUtils.isRecord(result, ResultRecord) &&
                result.hasErrorFor("Description")
            ) {
                handlePageLoadError(result.listErrorMessages());
                return;
            }

            ToastManager.error(
                t("errors-actionResource", {
                    action: t("saving"),
                    resource: t("bookmark"),
                })
            );
        }
    };

    createOrUpdateNote();
};

const handleUserBookmarkColorChanged = (
    newValue: number,
    resetPageErrors: () => void,
    setUserBookmark: Dispatch<SetStateAction<UserBookmarkRecord>>
): void => {
    resetPageErrors();

    setUserBookmark((userBookmark: UserBookmarkRecord) =>
        userBookmark.with({
            color: newValue,
        })
    );
};

const isValid = (
    userBookmark: UserBookmarkRecord,
    resetPageErrors: () => void,
    setPageErrors: Dispatch<SetStateAction<string[]>>
): boolean => {
    resetPageErrors();

    const validationResult = new UserBookmarkValidator().validate(
        userBookmark,
        UserBookmarkConstants.MAX_LENGTH
    );

    if (ObjectValidator.hasErrorsFor("description", validationResult)) {
        setPageErrors(
            ObjectValidator.getErrorsFor("description", validationResult)
        );

        return false;
    }

    return true;
};

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default BookmarkSettings;

// #endregion Export
