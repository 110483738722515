import PublishStatus from "models/enumerations/publish-status";
import { PublishStatusMenuProps } from "organisms/admin/situational-navigation/publish-status-menu";
import React from "react";
import { useLocalization } from "utilities/hooks/use-localization";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface PublishStatusBadgeProps
    extends Pick<PublishStatusMenuProps, "hasDraftableFields"> {
    status: PublishStatus;
    hasUnpublishedChanges: boolean;
    small?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-publish-status-badge";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const PublishStatusBadge: React.FC<PublishStatusBadgeProps> = (
    props: PublishStatusBadgeProps
) => {
    const {
        status,
        small,
        hasUnpublishedChanges,
        hasDraftableFields = true,
    } = props;
    const classNames = [CSS_CLASS_NAME];
    const { t } = useLocalization();
    let statusClassModifier: string;

    switch (status) {
        case PublishStatus.Published:
            statusClassModifier = "-published";
            break;
        case PublishStatus.PublishedWithUnpublishedChanges:
            statusClassModifier = "-has-unpublished-changed";
            break;
        case PublishStatus.Unpublished:
        default:
            statusClassModifier = "-unpublished";
            break;
    }

    if (hasUnpublishedChanges && hasDraftableFields) {
        statusClassModifier = `${statusClassModifier} -has-unpublished-changed`;
    }

    classNames.push(statusClassModifier);

    if (small === true) {
        classNames.push("-small");
    }

    const getStatusLabel = () => {
        if (small === true) {
            switch (status) {
                case PublishStatus.Published:
                case PublishStatus.PublishedWithUnpublishedChanges:
                    return "P";
                case PublishStatus.Unpublished:
                default:
                    return "U";
            }
        }

        switch (status) {
            case PublishStatus.Published:
            case PublishStatus.PublishedWithUnpublishedChanges:
                return t("published");
            case PublishStatus.Unpublished:
            default:
                return t("unpublished");
        }
    };

    return <div className={classNames.join(" ")}>{getStatusLabel()}</div>;
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default PublishStatusBadge;

// #endregion Exports
