import Button, { ButtonTypes } from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import React from "react";
import CultureResources from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization-utils";
import StringUtils from "utilities/string-utils";
import { ToastManager } from "utilities/toast/toast-manager";
const BASE_CSS_CLASS = "c-copy-text-button";
interface CopyTextButtonProps {
    text: string;
    successMessage?: keyof CultureResources;
}
const CopyTextButton = ({
    text,
    successMessage = "textCopied",
}: CopyTextButtonProps) => {
    const handleClick = () => {
        navigator.clipboard.writeText(text);
        ToastManager.success(t(successMessage));
    };
    return StringUtils.hasValue(text) ? (
        <Button
            cssClassName={`${BASE_CSS_CLASS}__button`}
            style={ButtonStyles.None}
            type={ButtonTypes.Button}
            onClick={handleClick}>
            {text}{" "}
            <Icon
                size={IconSizes.Base}
                type={Icons.Copy}
                cssClassName={`${BASE_CSS_CLASS}__icon`}
            />
        </Button>
    ) : (
        "--"
    );
};

export default CopyTextButton;
