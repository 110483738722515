import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import PublicationRecord from "models/view-models/publication-record";
import UserPublicationFavoriteRecord from "models/view-models/user-publication-favorite-record";
import BigButton from "molecules/buttons/big-button";
import PublicationCard from "molecules/cards/publication-card";
import PublicationSelectionModal from "organisms/modals/publication-selection-modal/publication-selection-modal";
import * as React from "react";
import { CollectionUtils } from "utilities/collection-utils";
import { useLocalization } from "utilities/hooks/use-localization";
import useModalActions from "utilities/hooks/use-modal-actions";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-favorite-publications";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface FavoritePublicationsProps {
    favoritePublications: PublicationRecord[];
    favorites: UserPublicationFavoriteRecord[];
    onChangeFavorites: (favorites: UserPublicationFavoriteRecord[]) => void;
}

// #endregion Interfaces

const FavoritePublications = (props: FavoritePublicationsProps) => {
    const { favoritePublications, favorites, onChangeFavorites } = props;

    const { t } = useLocalization();
    const viewAllPublications = t("viewItem", {
        item: t("allItem", {
            item: t("publication_plural"),
        }),
    });

    const {
        isOpen: isPublicationSelectionModalOpen,
        handleOpen: handlePublicationSelectionModalOpen,
        handleClose: handlePublicationSelectionModalClose,
    } = useModalActions();

    const cssClassName = [
        BASE_CLASS_NAME,
        CollectionUtils.isEmpty(favoritePublications) ? "-empty" : "",
    ].join(" ");

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    return (
        <div className={cssClassName}>
            <label>{t("favorite_plural")}</label>
            {CollectionUtils.isEmpty(favoritePublications) && (
                <Button
                    cssClassName={`${BASE_CLASS_NAME}__favorite-a-publication`}
                    onClick={handlePublicationSelectionModalOpen}
                    size={ButtonSizes.Large}
                    style={ButtonStyles.None}>
                    <Icon type={Icons.StarOutline} />
                    {t("favoriteItem", { item: t("publication") })}
                </Button>
            )}

            {CollectionUtils.hasValues(favoritePublications) && (
                <div className={`${BASE_CLASS_NAME}__cards`}>
                    {favoritePublications.map((p: PublicationRecord) => (
                        <PublicationCard
                            favorites={favorites}
                            key={p.id}
                            onChangeFavorites={onChangeFavorites}
                            publication={p}
                        />
                    ))}
                </div>
            )}
            <div className={`${BASE_CLASS_NAME}__view-all-publications`}>
                <BigButton
                    icon={Icons.Book}
                    onClick={handlePublicationSelectionModalOpen}>
                    <div
                        className={`${BASE_CLASS_NAME}__view-all-publications__button-content`}>
                        <div
                            className={`${BASE_CLASS_NAME}__view-all-publications__button-content__title`}>
                            {viewAllPublications}
                        </div>
                        <div
                            className={`${BASE_CLASS_NAME}__view-all-publications__button-content__subtitle`}>
                            {t("browse-all-available-publications")}
                        </div>
                    </div>
                </BigButton>
            </div>
            <PublicationSelectionModal
                closeDialog={handlePublicationSelectionModalClose}
                favorites={favorites}
                isVisible={isPublicationSelectionModalOpen}
                onChangeFavorites={onChangeFavorites}
                onResultClick={() => {}}
            />
        </div>
    );

    // #endregion Render
};

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default FavoritePublications;

// #endregion Exports
