import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import PublicationCodeGroupRecord from "models/view-models/publication-code-group-record";
import React, { useState } from "react";
import { useLocalization } from "utilities/hooks/use-localization";
import PublicationListItem, {
    PublicationTextStyle,
} from "./publication-list-item";
import { PublicationsListProps } from "./publications-list";

type SharedProps = Pick<
    PublicationsListProps,
    "favorites" | "onChangeFavorites" | "onResultClick"
>;

interface PublicationListGroupProps extends SharedProps {
    publicationGroup: PublicationCodeGroupRecord;
}

const PublicationListGroup = (props: PublicationListGroupProps) => {
    const { favorites, onChangeFavorites, onResultClick, publicationGroup } =
        props;

    const [showEarlierEditions, setShowEarlierEditions] = useState(false);

    const mostRecentEdition = publicationGroup.publications![0];
    const earlierEditions = publicationGroup.publications!.slice(1);

    const { t } = useLocalization();

    const buttonText = showEarlierEditions
        ? t("hide-earlier-editions")
        : t("show-earlier-editions");
    const accessibleText = buttonText;
    const controlId = `earlier-editions-${publicationGroup.code}`;

    const toggleEarlierEditions = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault();
        e.stopPropagation();
        setShowEarlierEditions(!showEarlierEditions);
    };

    return (
        <>
            <PublicationListItem
                favorites={favorites}
                onChangeFavorites={onChangeFavorites}
                onResultClick={onResultClick}
                publication={mostRecentEdition}
                style={PublicationTextStyle.TitleFirst}>
                {earlierEditions.length > 0 && (
                    <Button
                        accessibleText={accessibleText}
                        ariaControls={controlId}
                        ariaExpanded={showEarlierEditions}
                        onClick={toggleEarlierEditions}
                        style={ButtonStyles.Anchor}
                        size={ButtonSizes.Small}>
                        {buttonText}
                    </Button>
                )}
            </PublicationListItem>
            {showEarlierEditions && (
                <div id={controlId}>
                    {earlierEditions.map((publication) => {
                        return (
                            <PublicationListItem
                                key={publication.id}
                                favorites={favorites}
                                isNested={true}
                                onChangeFavorites={onChangeFavorites}
                                onResultClick={onResultClick}
                                publication={publication}
                                style={PublicationTextStyle.EditionFirst}
                            />
                        );
                    })}
                </div>
            )}
        </>
    );
};

export default PublicationListGroup;
