import EnhancedContentResourceRecord from "models/view-models/enhanced-content-resource-record";
import { useCallback } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import usePageErrors from "utilities/hooks/use-page-errors";
import AdminEnhancedContentResourceService from "utilities/services/admin/enhanced-content/resources/enhanced-content-resource-service";

export interface EnhancedContentResourceServiceHookResult {
    // additional properties
    bulkUpdate: (
        enhancedContentId: number,
        initialResources: EnhancedContentResourceRecord[],
        resources: EnhancedContentResourceRecord[]
    ) => Promise<EnhancedContentResourceRecord[]>;
    errors: string[];
}

export function useUpdateEnhancedContentResources(): EnhancedContentResourceServiceHookResult {
    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------

    const { pageErrors, resetPageErrors, setPageErrors } = usePageErrors();
    const { create } = AdminEnhancedContentResourceService.useCreate();
    const { delete: deleteResource } =
        AdminEnhancedContentResourceService.useDelete();

    const { update } = AdminEnhancedContentResourceService.useUpdate();

    const { list } = AdminEnhancedContentResourceService.useList();

    // #endregion State

    // -----------------------------------------------------------------------------------------
    // #region Hooks
    // -----------------------------------------------------------------------------------------

    const saveEnhancedContentResources = useCallback(
        async (
            enhancedContentId: number,
            initialResources: Array<EnhancedContentResourceRecord>,
            resources: Array<EnhancedContentResourceRecord>
        ): Promise<EnhancedContentResourceRecord[]> => {
            resetPageErrors();
            // Any resources without an id are creates
            const resourcesToCreate = resources.filter(
                (resource: EnhancedContentResourceRecord) =>
                    !resource.isPersisted()
            );
            // Any resources that have been removed from the list
            const resourcesToDelete = initialResources.filter(
                (initialResource: EnhancedContentResourceRecord) => {
                    //
                    return !resources.some(
                        (resource: EnhancedContentResourceRecord) =>
                            initialResource.id === resource.id
                    );
                }
            );

            // Any resources with an id but have changed are updates
            const resourcesToUpdate = CollectionUtils.intersectionWith(
                resources,
                initialResources,
                (initialResource, modifiedResource) =>
                    initialResource.id === modifiedResource.id
            );

            let promises: Array<Promise<any>> = [];
            if (CollectionUtils.hasValues(resourcesToCreate)) {
                promises.push(
                    ...resourcesToCreate.map((resource) =>
                        create(resource, {
                            enhancedContentId,
                        })
                    )
                );
            }
            if (CollectionUtils.hasValues(resourcesToDelete)) {
                promises.push(
                    ...resourcesToDelete.map((resource) =>
                        deleteResource(resource.id!, {
                            enhancedContentId,
                        })
                    )
                );
            }
            if (CollectionUtils.hasValues(resourcesToUpdate)) {
                promises.push(
                    ...resourcesToUpdate.map((resource) =>
                        update(resource, { enhancedContentId, id: resource.id })
                    )
                );
            }

            let resultObjects: EnhancedContentResourceRecord[] = [];
            try {
                // Create, update and delete
                await Promise.all(promises);

                // Retrieve updated records
                const listResult = await list({ enhancedContentId });
                resultObjects = listResult.resultObjects ?? [];
            } catch (error) {
                setPageErrors(error as string[]);
            }

            return resultObjects;
        },
        [create, deleteResource, list, resetPageErrors, setPageErrors, update]
    );

    // #endregion Hooks

    return {
        bulkUpdate: saveEnhancedContentResources,
        errors: pageErrors,
    };
}
