import { RecordUtils } from "@rsm-hcd/javascript-core";
import { SelectOption } from "atoms/forms/select";
import PublicationTypes from "constants/publication-types";
import { UnicodeCharacterConstants } from "constants/unicode-character-constants";
import { Record as ImmutableRecord } from "immutable";
import PublishStatus from "models/enumerations/publish-status";
import type Publication from "models/interfaces/publication";
import FileRecord from "models/view-models/file-record";
import { siteMap } from "internal-sitemap";
import { PublicationColor } from "utilities/enumerations/publication-color";
import type CultureResources from "utilities/interfaces/culture-resources";
import { t, tForCulture } from "utilities/localization-utils";
import { PublishableRecord } from "utilities/publishable-utils";
import ReferenceLink, {
    ReferenceLinkValue,
} from "utilities/quill/formats/reference-link";
import { RouteUtils } from "utilities/route-utils";
import StringUtils from "utilities/string-utils";
import PublicationContentFormatType from "utilities/enumerations/publication-content-format-type";
import { PdfPublication } from "organisms/admin/publications/pdf-publication-import-form";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

export const PublicationAnnexTypeDescriptions: Record<
    PublicationTypes,
    keyof CultureResources
> = {
    [PublicationTypes.NFC]: "annex_plural",
    [PublicationTypes.NEC]: "informativeAnnex_plural",
};

// #endregion Constants
export const defaultValues: Publication =
    RecordUtils.auditableDefaultValuesFactory<Publication>({
        code: undefined,
        color: undefined,
        contentSourceFileId: undefined,
        coverImageFileId: undefined,
        deletedDraftById: undefined,
        deletedDraftOn: undefined,
        edition: undefined,
        locale: undefined,
        printing: undefined,
        publicationContentFormatType: undefined,
        publishedById: undefined,
        publishedOn: undefined,
        publishStatus: undefined,
        sectionTitleHasLabel: undefined,
        subTitle: undefined,
        title: undefined,
        type: undefined,
        coverImageFile: undefined,
        contentSourceFile: undefined,
        usePdfBookmarkTitles: undefined,
    });

export default class PublicationRecord
    extends ImmutableRecord(defaultValues)
    implements Publication, PublishableRecord
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: Publication) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.coverImageFile != null) {
            params.coverImageFile = RecordUtils.ensureRecord(
                params.coverImageFile,
                FileRecord
            );
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    public static fromPdfPublication(
        publication: PdfPublication
    ): PublicationRecord {
        return new PublicationRecord({ ...publication });
    }

    public getColorOrDefault(): PublicationColor {
        return this.color ?? PublicationColor.Red;
    }

    /**
     * Get the plural term when displaying annexes
     *
     * @return {*}  {string}
     */
    public getAnnexesDisplayLabel(): string {
        const publicationType = this.type ?? PublicationTypes.NEC;
        return tForCulture(
            this.locale,
            PublicationAnnexTypeDescriptions[publicationType]
        );
    }

    /**
     * Get just the display code and edition,
     * e.g. NEC 70 (2020)
     */
    public getDisplayCodeAndEdition(): string {
        let displayTitle = "";
        if (StringUtils.hasValue(this.code)) {
            displayTitle += `NFPA ${this.code}`;
        }

        if (StringUtils.hasValue(this.edition)) {
            displayTitle += ` (${this.edition})`;
        }

        return displayTitle;
    }

    /**
     * Gets the full 'display' title of the Publication in the format of: NFPA {Code} {Title} ({Edition})
     * @example NFPA 70 - National Electric Code (2020)
     */
    public getDisplayTitle(includeEdition: boolean = true): string {
        if (this.title == null || this.title.length === 0) {
            return "";
        }

        let title = this.title;

        if (this.code != null) {
            title = `NFPA ${this.code} ${UnicodeCharacterConstants.EmDash} ${title}`;
        }

        if (includeEdition && this.edition != null) {
            title = `${title} (${this.edition})`;
        }

        return title;
    }

    public getDisplayEdition(): string {
        if (this.edition == null || this.edition.length === 0) {
            return "";
        }

        return `${this.edition} ${t("edition")}`;
    }

    public getPageTitle(): string {
        if (
            this.edition == null ||
            this.edition.length === 0 ||
            this.code == null ||
            this.code.length === 0
        ) {
            return "";
        }

        return `${this.edition} NFPA-${this.code}`;
    }

    public getDisplayCodeAndTitle(): string {
        if (this.code == null || this.code.length === 0) {
            return "";
        }

        if (this.title == null || this.title.length === 0) {
            return "";
        }

        return `NFPA ${this.code} ${UnicodeCharacterConstants.EmDash} ${this.title}`;
    }

    /**
     * Get display title in the format:
     * NEC-70 (2020) - National Electric Code
     */
    public getDisplayCodeEditionAndTitle(): string {
        let displayTitle = "";
        if (StringUtils.hasValue(this.code)) {
            displayTitle += `NFPA ${this.code}`;
        }

        if (StringUtils.hasValue(this.edition)) {
            displayTitle += ` (${this.edition})`;
        }

        if (StringUtils.hasValue(this.title)) {
            displayTitle += ` ${UnicodeCharacterConstants.EmDash} ${this.title}`;
        }

        return displayTitle.trim();
    }

    public getRoute(isAdminPreview: boolean = false): string {
        if (!this.isPersisted()) {
            return "#";
        }
        if (isAdminPreview) {
            return RouteUtils.getUrl(
                siteMap.publications.adminPreview.publication,
                {
                    id: this.id,
                }
            );
        }

        if (
            StringUtils.isEmpty(this.code) ||
            StringUtils.isEmpty(this.edition)
        ) {
            return "#";
        }

        return RouteUtils.getUrl(siteMap.publications.permalinks.publication, {
            code: this.code,
            edition: this.edition,
        });
    }

    public getIsLegacy(): boolean {
        return (
            this.publicationContentFormatType ===
            PublicationContentFormatType.Image
        );
    }

    public hasUnpublishedChanges(): boolean {
        // this can be updated if we extend the Publication publishing process to include
        // fields, rather than just the entire record wholesale.
        return false;
    }

    public isPersisted(): boolean {
        return this.id != null && this.id > 0;
    }

    /**
     * Checks if the Publication is published
     */
    public isPublished(): boolean {
        return this.getPublishStatus() === PublishStatus.Published;
    }

    public getPublishStatus(): PublishStatus {
        // return PublishableUtils.getPublishStatus(this);
        return this.publishStatus ?? PublishStatus.Unpublished;
    }

    public includeSectionLabel(): boolean {
        // TODO: Pending resolution of [NFPA-3522](https://app.clickup.com/t/2219993/NFPA-3522)
        // Long term decision on whether labels will be displayed
        return this.code === "70";
    }

    /**
     * Generate a ReferenceLinkValue to be inserted in the RTE.
     * @param text the link display text
     */
    public toReferenceLinkValue(text: string): ReferenceLinkValue {
        return {
            text: text.trim(),
            href: this.getRoute(),
            [ReferenceLink.DATA_ENTITY_ID_ATTR]: this.id?.toString() ?? "",
            [ReferenceLink.DATA_ENTITY_TYPE_ATTR]: "publication",
        };
    }

    /**
     * Convert a publication to a SelectOption<PublicationRecord, number>
     */
    public toSelectOption(): SelectOption<PublicationRecord, number> {
        return {
            data: this,
            label: this.getDisplayCodeEditionAndTitle(),
            value: this.id ?? -1,
        };
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<Publication>} values
     */
    public with(values: Partial<Publication>): PublicationRecord {
        return new PublicationRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}
