import { SkipNavContent } from "@reach/skip-nav";
import AnchorWithIcon from "atoms/anchors/anchor-with-icon";
import Button, { ButtonTypes } from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { HeadingPriority } from "atoms/constants/heading-priority";
import { Icons } from "atoms/constants/icons";
import { LoaderSizes } from "atoms/constants/loader-sizes";
import { LoaderStyles } from "atoms/constants/loader-styles";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Icon from "atoms/icons/icon";
import RemoteImage from "atoms/images/remote-image";
import Loader from "atoms/loaders/loader";
import StickyNotification from "atoms/sticky-notification/sticky-notification";
import Heading from "atoms/typography/heading";
import Paragraph from "atoms/typography/paragraph";
import { siteMap } from "internal-sitemap";
import PublicationRecord from "models/view-models/publication-record";
import UnorderedList from "molecules/lists/unordered-list";
import MenuButton from "molecules/menu-button/menu-button";
import Tooltip, { TooltipPlacement } from "molecules/tooltips/tooltip";
import ChangeEditionModal from "organisms/modals/change-edition-modal/change-edition-modal";
import TableOfContents from "organisms/table-of-contents/table-of-contents";
import React from "react";
import { BrowserUtils } from "utilities/browser-utils";
import { CollectionUtils } from "utilities/collection-utils";
import usePublicationFavorites from "utilities/hooks/domain/user-publication-favorites/use-publication-favorites";
import useUpdatePublicationFavorite from "utilities/hooks/domain/user-publication-favorites/use-update-publication-favorite";
import { useErrorBoundary } from "utilities/hooks/use-error-boundary";
import { useLocalization } from "utilities/hooks/use-localization";
import useModalActions from "utilities/hooks/use-modal-actions";
import CultureResources from "utilities/interfaces/culture-resources";
import { RouteUtils } from "utilities/route-utils";
import StringUtils from "utilities/string-utils";
import { ToastManager } from "utilities/toast/toast-manager";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface PublicationPageProps {
    publication: PublicationRecord;
    publicationLoaded: boolean;
    pageErrors: string[];
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASSNAME = "c-publication-page";
const DATA_TEST_ID_CHANGE_EDITION_BUTTON = "change-edition-button";
const DATA_TEST_ID_CHANGE_EDITION_BUTTON_MOBILE = `${DATA_TEST_ID_CHANGE_EDITION_BUTTON}-mobile`;
const DATA_TEST_ID_MOBILE_MENU_BUTTON = "mobile-menu-button";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const PublicationPage: React.FC<PublicationPageProps> = (
    props: PublicationPageProps
) => {
    const { publication, publicationLoaded, pageErrors } = props;

    // -----------------------------------------------------------------------------------------
    // #region Hooks
    // -----------------------------------------------------------------------------------------
    const { t } = useLocalization();

    // #endregion Hooks

    // -----------------------------------------------------------------------------------------
    // #region Functions
    // -----------------------------------------------------------------------------------------

    const handleFavoriteButtonClick = () => {
        if (isFavorited) {
            unfavorite();
            return;
        }

        addFavorite();
    };

    const handleFavoriteSuccess = (isFavorited: boolean) => {
        const key: keyof CultureResources = isFavorited
            ? "publicationPage-addedToFavorites"
            : "publicationPage-removedFromFavorites";
        return ToastManager.success(
            t(key, {
                codeAndEdition: publication.getDisplayCodeAndEdition(),
            })
        );
    };

    // #endregion Functions

    const {
        handleOpen: handleChangeEditionOpen,
        handleClose: handleChangeEditionClose,
        isOpen: showChangeEditionModal,
    } = useModalActions();

    const {
        favorites,
        setFavorites,
        loading: loadingFavorites,
    } = usePublicationFavorites();

    const {
        addFavorite,
        unfavorite,
        isFavorited,
        loading: savingFavorite,
    } = useUpdatePublicationFavorite(
        publication,
        favorites,
        setFavorites,
        handleFavoriteSuccess
    );

    useErrorBoundary(pageErrors);

    // const { getTableOfContents, setTableOfContents } =
    //     useBookviewTableOfContents();

    // useEffect(() => {
    //     if (publication.id != null) getTableOfContents(publication.id);

    //     return () => setTableOfContents(new TableOfContentsRecord());
    // }, [getTableOfContents, publication.id, setTableOfContents]);

    // -----------------------------------------------------------------------------------------
    // #region Computations
    // -----------------------------------------------------------------------------------------

    const favoriteButtonTooltip = isFavorited
        ? t("publicationPage-removeFromFavorites")
        : t("publicationPage-addToFavorites");
    const favoriteButtonTooltipDisabled = BrowserUtils.isTouchscreenDevice();
    const favoriteButtonIcon = isFavorited
        ? Icons.StarFilled
        : Icons.StarOutline;
    const favoriteButtonText = isFavorited ? t("favorited") : t("favorite");

    // #endregion Computations

    return (
        <div className={BASE_CLASSNAME}>
            <div className={`${BASE_CLASSNAME}__metadata`}>
                <div className={`${BASE_CLASSNAME}__metadata__top`}>
                    <AnchorWithIcon
                        accessibleText={t("backToLocation", {
                            location: t("home"),
                        })}
                        icon={Icons.ChevronLeft}
                        size={ButtonSizes.Small}
                        style={ButtonStyles.TertiaryAlt}
                        to={RouteUtils.getUrl(siteMap.dashboards.user)}>
                        {t("home")}
                    </AnchorWithIcon>
                    <div className={`${BASE_CLASSNAME}__metadata__top__right`}>
                        {(loadingFavorites || !publication.isPersisted()) && (
                            <Loader
                                type={LoaderStyles.LinkGlyphGray}
                                size={LoaderSizes.Small}
                            />
                        )}
                        {!loadingFavorites && publication.isPersisted() && (
                            <Tooltip
                                content={favoriteButtonTooltip}
                                disabled={favoriteButtonTooltipDisabled}
                                placement={TooltipPlacement.Bottom}>
                                <Button
                                    cssClassName={`${BASE_CLASSNAME}__metadata__top__right__favorite`}
                                    disabled={savingFavorite}
                                    onClick={handleFavoriteButtonClick}
                                    style={ButtonStyles.None}
                                    type={ButtonTypes.Button}>
                                    <Icon type={favoriteButtonIcon} />
                                    <span>{favoriteButtonText}</span>
                                </Button>
                            </Tooltip>
                        )}

                        <div
                            className={`${BASE_CLASSNAME}__metadata__top__right__more-menu`}>
                            <MenuButton
                                dataTestId={DATA_TEST_ID_MOBILE_MENU_BUTTON}>
                                <Button
                                    dataTestId={
                                        DATA_TEST_ID_CHANGE_EDITION_BUTTON_MOBILE
                                    }
                                    onClick={handleChangeEditionOpen}>
                                    {t("changeEdition")}
                                </Button>
                            </MenuButton>
                        </div>
                    </div>
                </div>
                {
                    // if
                    !publicationLoaded &&
                        CollectionUtils.isEmpty(pageErrors) && (
                            <Loader
                                accessibleText={t("loadingItem", {
                                    item: t("publication"),
                                })}
                                type={LoaderStyles.LinkGlyphGray}
                            />
                        )
                }
                {
                    // if
                    CollectionUtils.hasValues(pageErrors) && (
                        <UnorderedList
                            cssClassName={`${BASE_CLASSNAME}__errors`}
                            listItems={pageErrors}
                        />
                    )
                }
                {publicationLoaded && publication != null && (
                    <React.Fragment>
                        <div className={`${BASE_CLASSNAME}__menu`}>
                            {publication.coverImageFileId != null && (
                                <div className={`${BASE_CLASSNAME}__cover`}>
                                    <RemoteImage
                                        file={publication.coverImageFile}
                                        loaderType={LoaderStyles.LinkGlyphGray}
                                    />
                                </div>
                            )}
                            <div
                                className={`${BASE_CLASSNAME}__identification`}>
                                <div className={`${BASE_CLASSNAME}__edition`}>
                                    <Paragraph size={ParagraphSizes.Large}>
                                        {publication.edition}
                                    </Paragraph>
                                </div>
                                <Heading
                                    cssClassName={`${BASE_CLASSNAME}__code`}
                                    priority={HeadingPriority.Three}>
                                    {`NFPA ${publication.code}`}
                                </Heading>
                                <Heading
                                    cssClassName={`${BASE_CLASSNAME}__title`}
                                    priority={HeadingPriority.Five}>
                                    {publication.title}
                                </Heading>
                                <Paragraph
                                    cssClassName={`${BASE_CLASSNAME}__subtitle`}
                                    size={ParagraphSizes.Base}>
                                    {publication.subTitle}
                                </Paragraph>
                            </div>
                        </div>

                        <div className="c-publication-page__footer">
                            <Button
                                cssClassName="-link"
                                dataTestId={DATA_TEST_ID_CHANGE_EDITION_BUTTON}
                                onClick={handleChangeEditionOpen}>
                                {t("changeEdition")}
                            </Button>
                        </div>

                        <ChangeEditionModal
                            closeDialog={handleChangeEditionClose}
                            currentPublication={publication}
                            isVisible={showChangeEditionModal}
                        />
                    </React.Fragment>
                )}
            </div>
            <div className="c-publication-page__toc">
                <SkipNavContent>
                    <TableOfContents publication={publication} />
                </SkipNavContent>
            </div>
            {publicationLoaded &&
                StringUtils.isEmpty(publication?.publishedOn) && (
                    <StickyNotification>{t("unpublished")}</StickyNotification>
                )}
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default PublicationPage;

// #endregion Exports
