import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import UserBookmarkRecord from "models/view-models/user-bookmark-record";
import MenuButton from "molecules/menu-button/menu-button";
import React, { Dispatch, SetStateAction } from "react";
import { useLocalization } from "utilities/hooks/use-localization";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface BookmarkSettingsHeaderProps {
    cssClassName: string;
    initialUserBookmark: UserBookmarkRecord;
    isMobile: boolean;
    loading: boolean;
    onCancelClick: (
        initalBookmark: UserBookmarkRecord,
        currentBookmark: UserBookmarkRecord,
        setShowConfirmModal: Dispatch<SetStateAction<boolean>>,
        onCancel: () => void
    ) => void;
    onCancel: () => void;
    title: string;
    setShowCancelConfirmationModal: Dispatch<SetStateAction<boolean>>;
    setShowDeleteConfirmationModal: (value: boolean) => void;
    userBookmark: UserBookmarkRecord;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const BookmarkSettingsHeader: React.FC<BookmarkSettingsHeaderProps> = (
    props
) => {
    const {
        cssClassName,
        initialUserBookmark,
        isMobile,
        loading,
        onCancelClick,
        onCancel,
        title,
        setShowCancelConfirmationModal,
        setShowDeleteConfirmationModal,
        userBookmark,
    } = props;

    const { t } = useLocalization();

    return (
        <div className={cssClassName}>
            <span className={`${cssClassName}__title`}>
                {!isMobile && <Icon type={Icons.Bookmark} />}
                {title}
            </span>
            {!isMobile && (
                <div className={`${cssClassName}__actions`}>
                    {userBookmark.isPersisted() && (
                        <MenuButton
                            disabled={loading}
                            icon={Icons.MoreHorizontal}
                            iconSize={IconSizes.Base}
                            triggerButtonSize={ButtonSizes.Small}
                            triggerButtonStyle={ButtonStyles.TertiaryAlt}>
                            <Button
                                onClick={() =>
                                    setShowDeleteConfirmationModal(true)
                                }>
                                {t("deleteItem", {
                                    item: t("bookmark"),
                                })}
                            </Button>
                        </MenuButton>
                    )}
                    <Button
                        accessibleText={t("closeItem", { item: t("bookmark") })}
                        onClick={() =>
                            onCancelClick(
                                initialUserBookmark,
                                userBookmark,
                                setShowCancelConfirmationModal,
                                onCancel
                            )
                        }
                        size={ButtonSizes.Small}
                        style={ButtonStyles.TertiaryAlt}>
                        <Icon type={Icons.Close} size={IconSizes.Base} />
                    </Button>
                </div>
            )}
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default BookmarkSettingsHeader;

// #endregion Export
