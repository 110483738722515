import { ButtonProps } from "atoms/buttons/button";
import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import { ModalTypes } from "molecules/constants/modal-types";
import UnorderedList from "molecules/lists/unordered-list";
import BaseModalFooter from "molecules/modals/footers/base-modal-footer";
import BaseModalHeader from "molecules/modals/headers/base-modal-header";
import Modal from "molecules/modals/modal";
import React from "react";
import { useLocalization } from "utilities/hooks/use-localization";

const BASE_CLASS_NAME = "c-user-collection-visibility-modal";
const MODAL_CLASS_NAME = "c-modal";

export interface UserCollectionVisibilityModalProps {
    closeDialog: () => void;
    isVisible: boolean;
    title: string;
    workGroupTitles: string[];
}

const UserCollectionVisibilityModal = (
    props: UserCollectionVisibilityModalProps
) => {
    const { closeDialog, isVisible, title, workGroupTitles } = props;

    const { t } = useLocalization();

    const closeText = t("close");
    const workGroupVisibility = t("workGroupVisibility");

    const buttonProps: ButtonProps = {
        accessibleText: closeText,
        onClick: closeDialog,
    };

    return (
        <Modal
            cssClassName={BASE_CLASS_NAME}
            closeDialog={closeDialog}
            isVisible={isVisible}
            label={title}
            type={ModalTypes.Base}>
            <BaseModalHeader closeDialog={closeDialog} heading={title} />

            <div className={`${MODAL_CLASS_NAME}__body`}>
                <Heading priority={HeadingPriority.Six}>
                    {workGroupVisibility}
                </Heading>
                <UnorderedList listItems={workGroupTitles}></UnorderedList>
            </div>

            <BaseModalFooter
                primaryButtonProps={buttonProps}
                primaryText={closeText}
                closeDialog={closeDialog}
            />
        </Modal>
    );
};

export default UserCollectionVisibilityModal;
