import SelectSizes from "atoms/constants/select-sizes";
import Loader from "atoms/loaders/loader";
import UserBookmarkConstants from "constants/user-bookmark-constants";
import UserBookmarkRecord from "models/view-models/user-bookmark-record";
import UserCollectionRecord from "models/view-models/user-collection-record";
import RichTextEditorDeprecated from "molecules/rich-text/rich-text-editor-deprecated";
import BookmarkCollectionMultiSelect from "organisms/my-link/my-bookmarks/bookmark-collection-multi-select";
import ColorSelect, {
    ColorSelectType,
} from "organisms/my-link/my-bookmarks/color-select";
import React, { Dispatch, SetStateAction } from "react";
import { UserBookmarkColors } from "utilities/enumerations/user-bookmark-colors";
import { useLocalization } from "utilities/hooks/use-localization";
// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface BookmarkSettingsContentProps {
    allCollections: Array<UserCollectionRecord>;
    collections: Array<UserCollectionRecord>;
    cssClassName: string;
    isMobile: boolean;
    onUserBookmarkColorChanged: (newValue: number) => void;
    onDescriptionChanged: (newValue: string, rawValue: string) => void;
    loading: boolean;
    notesLabel: JSX.Element;
    pageErrors: string[];
    setCollections: Dispatch<SetStateAction<Array<UserCollectionRecord>>>;
    userBookmark: UserBookmarkRecord;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const BookmarkSettingsContent: React.FC<BookmarkSettingsContentProps> = (
    props
) => {
    const {
        allCollections,
        collections,
        cssClassName,
        isMobile,
        onUserBookmarkColorChanged,
        onDescriptionChanged,
        loading,
        notesLabel,
        pageErrors,
        setCollections,
        userBookmark,
    } = props;

    const { t } = useLocalization();
    const selectSize = !isMobile ? SelectSizes.Small : undefined;

    return (
        <div className={cssClassName}>
            {loading && (
                <Loader accessibleText={`${t("saving")} ${t("bookmark")}`} />
            )}
            {!loading && (
                <React.Fragment>
                    <ColorSelect
                        enumObject={UserBookmarkColors}
                        onChange={(newValue: number) =>
                            onUserBookmarkColorChanged(newValue)
                        }
                        type={ColorSelectType.Bookmark}
                        selectSize={selectSize}
                        value={userBookmark.color}
                    />
                    <RichTextEditorDeprecated
                        errors={pageErrors}
                        onChange={(newValue: string, rawValue: string) =>
                            onDescriptionChanged(newValue, rawValue)
                        }
                        label={notesLabel}
                        maxLength={UserBookmarkConstants.MAX_LENGTH}
                        placeholder={t("addNoteHere")}
                        toolbarConfig={
                            UserBookmarkConstants.RICH_TEXT_TOOLBAR_CONFIG
                        }
                        value={userBookmark.description}
                    />
                    <BookmarkCollectionMultiSelect
                        allCollections={allCollections}
                        onChange={setCollections}
                        selectSize={selectSize}
                        value={collections}
                    />
                </React.Fragment>
            )}
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default BookmarkSettingsContent;

// #endregion Export
