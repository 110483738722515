import { AnchorTargetTypes } from "@rsm-hcd/javascript-core";
import Anchor from "atoms/anchors/anchor";
import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { siteMap } from "internal-sitemap";
import useAzure from "pages/azure-login/use-azure";
import React from "react";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import { t } from "utilities/localization-utils";

interface LogInAnchorProps {
    buttonText?: string;
    cssClassName?: string;
    lowerCase?: boolean;
    style?: ButtonStyles;
    target?: AnchorTargetTypes;
}

const LogInAnchor = (props: LogInAnchorProps) => {
    const {
        cssClassName = "c-button -anchor",
        buttonText,
        lowerCase = false,
        style,
        target,
    } = props;
    const { useAzureB2CForSSO } = useFeatureFlags();
    const { login } = useAzure();

    let text = (buttonText ?? useAzureB2CForSSO) ? t("logIn") : t("signIn");

    if (lowerCase) text = text.toLowerCase();

    return useAzureB2CForSSO ? (
        <Button onClick={login} cssClassName={cssClassName} style={style}>
            {text}
        </Button>
    ) : (
        <Anchor
            cssClassName={cssClassName}
            external={useAzureB2CForSSO}
            target={target}
            to={siteMap.userlogins.new}>
            {text}
        </Anchor>
    );
};

export default LogInAnchor;
