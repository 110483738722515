import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Paragraph from "atoms/typography/paragraph";
import PublishStatus from "models/enumerations/publish-status";
import MenuButton from "molecules/menu-button/menu-button";
import { ConfirmationModal } from "molecules/modals/confirmation-modal";
import PublishStatusBadge from "organisms/admin/situational-navigation/publish-status-badge";
import React, { useEffect, useState } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import DateUtils from "utilities/date-utils";
import { useLocalization } from "utilities/hooks/use-localization";
import { PublishableRecord } from "utilities/publishable-utils";
import StringUtils from "utilities/string-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface PublishStatusMenuProps {
    customMenuItem?: React.ReactNode;
    disabled?: boolean;
    disabledComment?: string;
    /**
     * For Publications, the publishing process
     * is for the entire record, wholesale. In
     * this case, we don't need the dirty checking
     * to show the "published with unpublished changes"
     * state.
     */
    hasDraftableFields?: boolean;
    isValueDirty: boolean;
    onDeleteClick?: () => void;
    onPublishClick: () => void;
    /**
     * Publications are not unpublishable.
     * If the handler is null, the unpublish option is hidden.
     */
    onUnpublishClick?: () => void;
    recordLabel: string;
    value: PublishableRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-publish-status-menu";

const DATA_TEST_ID_MENU_PUBLISH = "publish-status-menu-publish";
const DATA_TEST_ID_MENU_UNPUBLISH = "publish-status-menu-unpublish";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const PublishStatusMenu: React.FC<PublishStatusMenuProps> = (
    props: PublishStatusMenuProps
) => {
    const {
        customMenuItem,
        disabled,
        disabledComment,
        hasDraftableFields = true,
        isValueDirty,
        onDeleteClick,
        onPublishClick,
        onUnpublishClick,
        recordLabel,
        value,
    } = props;
    const { t } = useLocalization();
    const [timestamp, setTimestamp] = useState<string>();
    const [confirmPublishModalOpen, setConfirmPublishModalOpen] =
        useState(false);
    const [confirmUnpublishModalOpen, setConfirmUnpublishModalOpen] =
        useState(false);
    const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
    const { globalState } = useGlobalState();

    const handlePublishConfirm = () => {
        onPublishClick();
        setConfirmPublishModalOpen(false);
    };

    const handleUnpublishConfirm = () => {
        if (onUnpublishClick != null) {
            onUnpublishClick();
            setConfirmUnpublishModalOpen(false);
        }
    };

    const handleDeleteConfirm = () => {
        onDeleteClick?.();
        setConfirmDeleteModalOpen(false);
    };

    useEffect(
        () => setTimestamp(DateUtils.formatLastEditedDate(value)),
        [value]
    );

    const hasUnpublishedChanges = (): boolean => {
        if (value.getPublishStatus() !== PublishStatus.Published) {
            return false;
        }

        return value.hasUnpublishedChanges();
    };

    const getStatusText = (): JSX.Element => {
        if (value.getPublishStatus() === PublishStatus.Published) {
            if (hasUnpublishedChanges() && hasDraftableFields === true) {
                return (
                    <Paragraph cssClassName="-bold">
                        {t(
                            "publishStatusMenu-statusText-unpublishedChangesSaved"
                        )}
                    </Paragraph>
                );
            }

            return <Paragraph>{t("published")}</Paragraph>;
        }

        return (
            <Paragraph>
                {t("publishStatusMenu-statusText-changesSaved")}
            </Paragraph>
        );
    };

    const publishConfirmModalText = () => {
        const key = hasDraftableFields
            ? "publishStatusMenu-publishChangesConfirmation-message"
            : "publishStatusMenu-publishConfirmation-message";

        return t(key, { item: recordLabel.toLowerCase() });
    };

    const publishConfirmModalContent = (
        <React.Fragment>
            {publishConfirmModalText()}
            {onUnpublishClick == null && (
                <React.Fragment>
                    <br />
                    <strong>{t("warnings-thisActionCannotBeUndone")}</strong>
                </React.Fragment>
            )}
        </React.Fragment>
    );

    const publishConfirmButtonText = hasDraftableFields
        ? t("yesAction", { action: t("publishChanges") })
        : t("yesAction", { action: t("publish") });

    if (value?.id == null) {
        return null;
    }

    const canPublish =
        globalState.currentIdentity?.isPublisher() ||
        globalState.currentIdentity?.isAdmin();

    const shouldShowPublishOption = () => {
        if (!canPublish) return false;

        if (hasDraftableFields) {
            return true;
        }

        return (
            StringUtils.isEmpty(value.publishedOn) ||
            value.getPublishStatus() === PublishStatus.Unpublished
        );
    };

    const shouldShowUnpublishOption = () => {
        if (!canPublish) return false;

        const status = value.getPublishStatus();

        return (
            onUnpublishClick != null &&
            (status === PublishStatus.Published ||
                status === PublishStatus.PublishedWithUnpublishedChanges)
        );
    };

    // workaround for internet explorer; for some reason on IE
    // if some items are hidden, we need to manually control
    // the menu container height...
    const menuItems: React.ReactNode[] = [];
    if (shouldShowPublishOption()) {
        const publishButtonText =
            hasUnpublishedChanges() || isValueDirty
                ? t("publishChanges")
                : t("publish");
        menuItems.push(
            <Button
                accessibleText={publishButtonText}
                cssClassName="-bold"
                dataTestId={DATA_TEST_ID_MENU_PUBLISH}
                disabled={value.id == null}
                key="publish-menu-item"
                onClick={() => setConfirmPublishModalOpen(true)}>
                {publishButtonText}
            </Button>
        );
    }

    if (shouldShowUnpublishOption()) {
        menuItems.push(
            <Button
                accessibleText={t("unpublish")}
                dataTestId={DATA_TEST_ID_MENU_UNPUBLISH}
                key="unpublish-menu-item"
                onClick={() => setConfirmUnpublishModalOpen(true)}>
                {t("unpublish")}
            </Button>
        );
    }

    if (value.id != null && onDeleteClick != null) {
        menuItems.push(
            <Button
                accessibleText={t("delete")}
                key="delete-menu-item"
                onClick={() => setConfirmDeleteModalOpen(true)}>
                {t("delete")}
            </Button>
        );
    }

    if (customMenuItem != null) {
        menuItems.push(customMenuItem);
    }

    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__left`}>
                {StringUtils.hasValue(timestamp) && (
                    <React.Fragment>
                        {getStatusText()}
                        <Paragraph>{timestamp}</Paragraph>
                    </React.Fragment>
                )}
            </div>
            <div className={`${CSS_CLASS_NAME}__right`}>
                {disabled && StringUtils.hasValue(disabledComment) && (
                    <Paragraph
                        cssClassName={`${CSS_CLASS_NAME}__right__comment`}
                        size={ParagraphSizes.XSmall}>
                        {disabledComment}
                    </Paragraph>
                )}

                <PublishStatusBadge
                    hasDraftableFields={hasDraftableFields}
                    hasUnpublishedChanges={hasUnpublishedChanges()}
                    status={value.getPublishStatus()}
                />
                {CollectionUtils.hasValues(menuItems) && (
                    <MenuButton
                        buttonAccessibleText="Publishing Options"
                        cssClassName={`-items-${menuItems.length}`}
                        disabled={disabled}>
                        {menuItems}
                    </MenuButton>
                )}
            </div>
            <ConfirmationModal
                confirmButtonStyle={ButtonStyles.Primary}
                confirmButtonText={publishConfirmButtonText}
                isVisible={confirmPublishModalOpen}
                message={publishConfirmModalContent}
                onCancel={() => setConfirmPublishModalOpen(false)}
                onConfirm={handlePublishConfirm}
            />
            <ConfirmationModal
                confirmButtonStyle={ButtonStyles.Primary}
                confirmButtonText={t("yesAction", { action: t("unpublish") })}
                isVisible={confirmUnpublishModalOpen}
                message={t("publishStatusMenu-unpublishConfirmation-message", {
                    item: recordLabel.toLowerCase(),
                })}
                onCancel={() => setConfirmUnpublishModalOpen(false)}
                onConfirm={handleUnpublishConfirm}
            />
            <ConfirmationModal
                confirmButtonText="Yes, Delete"
                isVisible={confirmDeleteModalOpen}
                message={t("publishStatusMenu-deleteConfirmation-message", {
                    item: recordLabel.toLowerCase(),
                })}
                onCancel={() => setConfirmDeleteModalOpen(false)}
                onConfirm={handleDeleteConfirm}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default PublishStatusMenu;

// #endregion Exports
