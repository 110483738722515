import { useAsyncEffect } from "@rsm-hcd/javascript-react";
import { HeadingPriority } from "atoms/constants/heading-priority";
import Loader from "atoms/loaders/loader";
import Heading from "atoms/typography/heading";
import PublicationMetadataTypeEnum from "models/interfaces/publication-metadata-types";
import PublicationMetadataRecord from "models/view-models/publication-metadata-record";
import UnorderedList from "molecules/lists/unordered-list";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { CollectionUtils } from "utilities/collection-utils";
import useCurrentPublication from "utilities/contexts/use-current-publication";
import { useErrorBoundary } from "utilities/hooks/use-error-boundary";
import useLoadResourceStatus from "utilities/hooks/use-load-resource-status";
import { useLocalization } from "utilities/hooks/use-localization";
import usePageErrors from "utilities/hooks/use-page-errors";
import PublicationMetadataService from "utilities/services/publications/publicationMetadata/publication-metadata-service";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface MetadataProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-publication-page-layout";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const MetadataContent: React.FunctionComponent<MetadataProps> = (
    props: MetadataProps
) => {
    const { t } = useLocalization();
    const { type } = useParams<{ type: string }>();
    const { publication } = useCurrentPublication();
    const { loading, setLoading } = useLoadResourceStatus();
    const { handlePageLoadError, pageErrors, resetPageErrors } =
        usePageErrors();
    useErrorBoundary(pageErrors);
    const [metadata, setMetadata] = useState<Array<PublicationMetadataRecord>>(
        []
    );

    const { list } = PublicationMetadataService.useList();

    useAsyncEffect(
        async function load() {
            resetPageErrors();
            if (!publication?.isPersisted()) {
                return;
            }
            try {
                setLoading(true);
                const response = await list(
                    {
                        publicationId: publication.id!,
                    },
                    {
                        type: type,
                    }
                );

                // create new array and hydrate the publication property with the publication
                // the endpoint does not include the publication in it's query
                const publicationMetadata: PublicationMetadataRecord[] = [];

                response.resultObjects.forEach((metadata) => {
                    publicationMetadata.push(
                        metadata.with({ publication: publication })
                    );
                });

                setMetadata(publicationMetadata);
            } catch (result) {
                handlePageLoadError(result);
            }
            setLoading(false);
        },
        [publication, type]
    );

    const current = metadata.find((m) => m.type === type);
    const headingClass =
        type === PublicationMetadataTypeEnum.COMMITTEES ? "text-center" : "";
    const loaderText = t("loadingItem", { item: type });

    return (
        <React.Fragment>
            {
                // if
                CollectionUtils.hasValues(pageErrors) && (
                    <UnorderedList
                        cssClassName={`${BASE_CLASS_NAME}__errors`}
                        listItems={pageErrors}
                    />
                )
            }
            {loading && <Loader accessibleText={loaderText} />}

            {current != null && (
                <Heading
                    cssClassName={`${BASE_CLASS_NAME}__content__title ${headingClass}`}
                    dataTestId={"metadata-header"}
                    priority={HeadingPriority.One}>
                    {current.getTypeDisplayName()}
                </Heading>
            )}
            {metadata.map((m) => m.getXmlContent())}
        </React.Fragment>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default MetadataContent;

// #endregion Exports
