import Breadcrumb from "atoms/breadcrumbs/breadcrumb";
import React, { useEffect } from "react";
import { match, useParams } from "react-router-dom";
import { useHeaderData } from "utilities/contexts/use-header-data-context";
import useSection from "utilities/hooks/domain/publications/use-section";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface SectionBreadcrumbProps {
    match: match;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SectionBreadcrumb: React.FunctionComponent<SectionBreadcrumbProps> = (
    props: SectionBreadcrumbProps
) => {
    const { match } = props;
    const { publicationId, id: sectionId } = useParams<{
        publicationId: string;
        id: string;
    }>();
    const { resultObject: section, loading } = useSection(
        publicationId,
        sectionId
    );
    const { setPageTitle } = useHeaderData({ match });

    useEffect(() => {
        if (section != null) {
            setPageTitle(section.getFullyQualifiedDisplayTitle());
        }
    }, [setPageTitle, section]);

    return (
        <Breadcrumb>
            <span className="c-breadcrumb__label">
                {loading ? "..." : section.getDisplayTitle()}
            </span>
        </Breadcrumb>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default SectionBreadcrumb;

// #endregion Export
