import { Record } from "immutable";
import type UserPublicationFavorite from "models/interfaces/user-publication-favorite";
import { RecordUtils } from "@rsm-hcd/javascript-core";
import PublicationRecord from "./publication-record";

const defaultValues: UserPublicationFavorite =
    RecordUtils.auditableDefaultValuesFactory({
        bookId: undefined,
        publicationCode: "",
        publicationEdition: "",
        userRoleId: 0,
        publication: undefined,
    });

export default class UserPublicationFavoriteRecord
    extends Record(defaultValues)
    implements UserPublicationFavorite
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: UserPublicationFavorite) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.publication) {
            params.publication = RecordUtils.ensureRecord(
                params.publication,
                PublicationRecord
            );
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    public with(
        values: Partial<UserPublicationFavorite>
    ): UserPublicationFavoriteRecord {
        return new UserPublicationFavoriteRecord(
            Object.assign(this.toJS(), values) as UserPublicationFavoriteRecord
        );
    }

    // #endregion Public Methods
}
