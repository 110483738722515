import Anchor from "atoms/anchors/anchor";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import Paragraph from "atoms/typography/paragraph";
import UserCollectionRecord from "models/view-models/user-collection-record";
import UnorderedList from "molecules/lists/unordered-list";
import Tooltip, { TooltipPlacement } from "molecules/tooltips/tooltip";
import React, { useMemo, useRef, useState } from "react";
import { BrowserUtils } from "utilities/browser-utils";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import { useLocalization } from "utilities/hooks/use-localization";
import useOnClickOutside from "utilities/hooks/use-onclick-outside";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface BookmarkCollectionsBadgeProps {
    collections: Array<UserCollectionRecord>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const BookmarkCollectionsBadge: React.FC<BookmarkCollectionsBadgeProps> = (
    props: BookmarkCollectionsBadgeProps
) => {
    const CSS_CLASS_NAME = "c-bookmark-collections-badge";
    const { collections } = props;

    const { t } = useLocalization();

    const renderTooltipContent = () => (
        <div ref={containerRef}>
            <UnorderedList
                cssClassName={`${CSS_CLASS_NAME}__tooltipList`}
                listIcon={Icons.Folder}
                listItems={collections.map((c: UserCollectionRecord) => (
                    <Anchor to={c.getRoute()}>{c.name}</Anchor>
                ))}
            />
        </div>
    );

    const containerRef = useRef<HTMLDivElement>(null);
    const isMobileOrTablet = useBreakpoint(
        Breakpoints.Tablet,
        BreakpointComparer.MaxWidth
    );
    const isTouchscreen = useMemo(() => BrowserUtils.isTouchscreenDevice(), []);
    const [showTooltip, setShowTooltip] = useState(false);
    useOnClickOutside(containerRef, () => setShowTooltip(false));

    const collectionCountMessage = useMemo(() => {
        if (isMobileOrTablet) {
            return collections.length.toString();
        }

        return t("collectionWithCount", { count: collections.length });
    }, [isMobileOrTablet, collections, t]);

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (isTouchscreen) {
            // on touchscreen devices, both a touch event and a click event fire
            return;
        }

        setShowTooltip(!showTooltip);
    };

    const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        setShowTooltip(!showTooltip);
        e.stopPropagation();
        e.preventDefault();
    };

    const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <Tooltip
            content={renderTooltipContent()}
            cssClassName={`${CSS_CLASS_NAME}__tooltip`}
            interactive={true}
            placement={TooltipPlacement.AutoStart}
            visible={
                isMobileOrTablet && isTouchscreen ? showTooltip : undefined
            }>
            <div
                className={CSS_CLASS_NAME}
                onClick={handleClick}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                tabIndex={0}>
                <Icon type={Icons.Folder} />
                <Paragraph>{collectionCountMessage}</Paragraph>
            </div>
        </Tooltip>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default BookmarkCollectionsBadge;

// #endregion Exports
