import React, { useCallback } from "react";
import { ConfirmationModal } from "molecules/modals/confirmation-modal";
import { ToastManager } from "utilities/toast/toast-manager";
import EnhancedContentPublishService, {
    AdminEnhancedContentPublishPathParams,
} from "utilities/services/admin/enhanced-content/enhanced-content-publish-service";
import { EnhancedContentPanelProps } from "organisms/enhanced-content/enhanced-content-panel";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import EnhancedContentRecord from "models/view-models/enhanced-content-record";
import UserRecord from "models/view-models/user-record";
import { UpdateService } from "utilities/services/service-factory";
import useEnhancedContent from "utilities/hooks/domain/enhanced-content/use-enhanced-content";
import { ResultRecord } from "@rsm-hcd/javascript-core";
import EnhancedContentPublishErrorKeys from "constants/enhanced-content-error-keys";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface EnhancedContentPublishActionModalProps
    extends Pick<EnhancedContentPanelProps, "enhancedContent" | "onSuccess"> {
    onClose: () => void;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const ERROR_PUBLISH_ENHANCED_CONTENT =
    "There was an issue publishing this enhanced content";
const SUCCESS_PUBLISH_ENHANCED_CONTENT = "Enhanced content published.";
const ERROR_SEARCH_UPLOAD =
    "The enhanced content was successfully published, but there was an issue uploading the search index. Refresh this page to update the publish status badge.";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Components
// -----------------------------------------------------------------------------------------

const EnhancedContentPublishActionModal = (
    props: EnhancedContentPublishActionModalProps
) => {
    const { enhancedContent, onClose, onSuccess } = props;

    const { globalState } = useGlobalState();
    const { updateEnhancedContentOnEdit } = useEnhancedContent({});

    const { update: enhancedContentPublishApi } =
        EnhancedContentPublishService.usePublish();

    // -----------------------------------------------------------------------------------------
    // #region Event Handlers
    // -----------------------------------------------------------------------------------------

    const handlePublish = useCallback(async () => {
        try {
            const record = await publishEnhancedContentRecord(
                enhancedContent,
                enhancedContentPublishApi,
                updateEnhancedContentOnEdit,
                globalState.currentIdentity?.user
            );

            if (record != null) {
                onSuccess?.(record);
                ToastManager.success(SUCCESS_PUBLISH_ENHANCED_CONTENT);
            } else {
                ToastManager.error(ERROR_PUBLISH_ENHANCED_CONTENT);
            }
        } catch (error) {
            if (
                error instanceof ResultRecord &&
                error.hasErrorFor(
                    EnhancedContentPublishErrorKeys.SEARCH_UPLOAD_ERROR
                )
            ) {
                ToastManager.error(ERROR_SEARCH_UPLOAD);
            } else {
                ToastManager.error(ERROR_PUBLISH_ENHANCED_CONTENT);
            }
        }
        onClose();
    }, [
        enhancedContent,
        enhancedContentPublishApi,
        globalState.currentIdentity,
        onClose,
        onSuccess,
        updateEnhancedContentOnEdit,
    ]);

    // #endregion Event Handlers

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    return (
        <ConfirmationModal
            confirmButtonText="Yes, Publish"
            isVisible={true}
            label="Confirm Publish"
            message="Are you sure you want to publish this enhanced content live to customers?"
            onCancel={onClose}
            onConfirm={handlePublish}
        />
    );

    // #endregion Render
};

// #endregion Components

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

/**
 * Helper function to publish an enhanced content record
 *
 * @param {EnhancedContentRecord} enhancedContent
 * @param {UpdateService<EnhancedContentRecord, AdminEnhancedContentPublishPathParams>} enhancedContentPublishApi
 * @param {Dispatch<SetStateAction<PublicationPageContextRecord>>} updateEnhancedContentOnEdit
 * @param {UserRecord} [updatedBy]
 */
async function publishEnhancedContentRecord(
    enhancedContent: EnhancedContentRecord,
    enhancedContentPublishApi: UpdateService<
        EnhancedContentRecord,
        AdminEnhancedContentPublishPathParams
    >,
    updateEnhancedContentOnEdit: (
        updatedContent: EnhancedContentRecord
    ) => void,
    updatedBy?: UserRecord
): Promise<EnhancedContentRecord | undefined> {
    if (enhancedContent.id == null) {
        return undefined;
    }

    const response = await enhancedContentPublishApi(enhancedContent, {
        id: enhancedContent.id,
    });

    if (response.result?.hasErrors() ?? true) {
        return undefined;
    }

    const { resultObject } = response;
    if (resultObject == null) {
        return undefined;
    }

    const publishedRecord = resultObject
        .with({ updatedBy })
        // Merging in resources from original record to prevent them from disappearing in UI
        .withResources(enhancedContent.resources);

    updateEnhancedContentOnEdit(publishedRecord);

    return publishedRecord;
}

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default EnhancedContentPublishActionModal;

// #endregion Exports
