import Anchor from "atoms/anchors/anchor";
import Button, { ButtonTypes } from "atoms/buttons/button";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import MultiSelect from "atoms/forms/multi-select";
import Select, { SelectOption } from "atoms/forms/select";
import Paragraph from "atoms/typography/paragraph";
import FileRecord from "models/view-models/file-record";
import SituationRecord from "models/view-models/situational-navigation/situations/situation-record";
import SituationRelationshipRecord from "models/view-models/situational-navigation/situations/situation-relationship-record";
import SolutionRecord from "models/view-models/situational-navigation/solutions/solution-record";
import DragAndDropListBox from "molecules/lists/drag-and-drop-list-box";
import { ListBoxItem } from "molecules/lists/list-box";
import AdminSituationHotSpotImage from "organisms/admin/situational-navigation/situations/admin-situation-hotspot-image";
import { SituationEditorContentAreaId } from "organisms/admin/situational-navigation/situations/situation-editor";
import RelatedSolutionDescription from "organisms/admin/situational-navigation/solutions/related-solution-description";
import RelatedSolutionTitle from "organisms/admin/situational-navigation/solutions/related-solution-title";
import { SolutionEditorContentAreaId } from "organisms/admin/situational-navigation/solutions/solution-editor";
import React, { Reducer, useEffect, useReducer, useState } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import useDebounce from "utilities/hooks/use-debounce";
import AdminSituationService from "utilities/services/admin/situational-navigation/situations/admin-situation-service";
import AdminSolutionService from "utilities/services/admin/situational-navigation/solutions/admin-solution-service";
import StringUtils from "utilities/string-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import uuid from "uuid";
import SitNavUtils from "utilities/situational-navigation/sitnav-utils";
import { AnchorTargetTypes } from "@rsm-hcd/javascript-core";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const SEARCH_SITUATION_ERROR = "There was an issue searching situations.";
const SEARCH_SOLUTION_ERROR = "There was an issue searching solutions.";

const OPTION_LIST_SIZE = 20;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AdminRelationshipsSectionProps {
    dropdownPortal?: HTMLElement;
    hotSpotImageFile?: FileRecord;
    loadingFile: boolean;
    onImageSelected: (file?: FileRecord) => void;
    onRemoved: (removedValue: SolutionRecord | SituationRecord) => void;
    onSelected: (newValue: SolutionRecord | SituationRecord) => void;
    onReordered: (newList: Array<SituationRelationshipRecord>) => void;
    onHotSpotUpdate: (newHotSpots: Array<SituationRelationshipRecord>) => void;
    /**
     * the ID of the situation populated in the editor (so we can filter this out from
     * options for related situations). Null if editor is in create mode.
     */
    situationId?: number;
    selectedSolutions: Array<SolutionRecord>;
    selectedSituations: Array<SituationRecord>;
    value: Array<SituationRelationshipRecord>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const SearchTypeOptions: Array<SelectOption> = [
    {
        label: "Solutions",
        value: "solution",
    },
    {
        label: "Situations",
        value: "situation",
    },
];

enum SearchType {
    Solution = "solution",
    Situation = "situation",
}

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AdminSituationRelationshipsSection: React.FC<
    AdminRelationshipsSectionProps
> = (props: AdminRelationshipsSectionProps) => {
    const CSS_CLASS_NAME = "c-admin-situation-solutions-section";

    const { list: listSolutionsApi } = AdminSolutionService.useList();
    const { list: listSituationsApi } = AdminSituationService.useList();

    const [loading, setLoading] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<
        SolutionRecord | SituationRecord
    >();
    const [searchText, setSearchText] = useState("");
    const debouncedText = useDebounce(searchText);

    const [searchType, setSearchType] = useState(SearchType.Solution);

    /**
     * using useReducer here so that we can filter the new values
     * by the already selected values without adding props.value
     * to the dependency array in the useEffect that triggers the API,
     * because that would cause undesired behavior, including hitting the API
     * twice when it only needs to be hit once.
     */
    const [solutionOptions, setSolutionOptions] = useReducer<
        Reducer<Array<SolutionRecord>, Array<SolutionRecord>>
    >(
        (
            currentValue: Array<SolutionRecord>,
            newValues: Array<SolutionRecord>
        ): Array<SolutionRecord> => {
            return (newValues ?? []).filter(
                // filter out ones we already have selected
                (s: SolutionRecord) =>
                    !props.value.some(
                        (selected: SituationRelationshipRecord) =>
                            s.id === selected.relatedSolutionId
                    )
            );
        },
        []
    );

    const [situationOptions, setSituationOptions] = useReducer<
        Reducer<Array<SituationRecord>, Array<SituationRecord>>
    >(
        (
            currentValue: Array<SituationRecord>,
            newValues: Array<SituationRecord>
        ): Array<SituationRecord> => {
            return (newValues ?? []).filter(
                // filter out ones we already have selected
                (s: SituationRecord) =>
                    !props.value.some(
                        (selected: SituationRelationshipRecord) =>
                            s.id === selected.relatedSituationId
                    ) && s.id !== props.situationId
            );
        },
        []
    );

    useEffect(() => {
        const searchSolutions = async () => {
            setSolutionOptions([]);
            setLoading(true);
            try {
                const result = await listSolutionsApi({
                    searchText: debouncedText,
                    take: OPTION_LIST_SIZE,
                });
                setSolutionOptions(result.resultObjects!);
            } catch (e) {
                ToastManager.error(SEARCH_SOLUTION_ERROR);
                setSolutionOptions([]);
            }
            setLoading(false);
        };

        const searchSituations = async () => {
            setSituationOptions([]);
            setLoading(true);
            try {
                const result = await listSituationsApi({
                    searchText: debouncedText,
                    take: OPTION_LIST_SIZE,
                });
                setSituationOptions(result.resultObjects!);
            } catch (e) {
                ToastManager.error(SEARCH_SITUATION_ERROR);
                setSituationOptions([]);
            }
            setLoading(false);
        };

        if (StringUtils.hasValue(debouncedText)) {
            if (searchType === SearchType.Solution) {
                searchSolutions();
                return;
            }

            searchSituations();
        }
    }, [
        debouncedText,
        searchType,
        setLoading,
        listSolutionsApi,
        listSituationsApi,
    ]);

    useEffect(() => {
        if (StringUtils.isEmpty(searchText) && selectedRecord == null) {
            // if they have cleared search text without selecting
            // an option, clear the options (they will be performing
            // a new search)
            setSolutionOptions([]);
            setSituationOptions([]);
        }
    }, [searchText, selectedRecord]);

    const getOptions = (): Array<
        SelectOption<SolutionRecord | SituationRecord>
    > =>
        [...solutionOptions, ...situationOptions].map(
            (s: SolutionRecord | SituationRecord) => ({
                data: s,
                label: s.titleDraft,
                value: s.id!.toString(),
            })
        );

    const renderOption = (
        option: SelectOption<SolutionRecord | SituationRecord>
    ) => (
        <div className={`${CSS_CLASS_NAME}__select-container__option`}>
            <div
                className={`${CSS_CLASS_NAME}__select-container__option__title`}>
                {option.data?.titleDraft}
            </div>
            <div
                className={`${CSS_CLASS_NAME}__select-container__option__subtitle`}>
                {option.data?.subtitleDraft}
            </div>
        </div>
    );

    const listBoxItems = (): Array<ListBoxItem<string>> =>
        props.value.map(
            (situationRelationship: SituationRelationshipRecord) => {
                const solution = props.selectedSolutions.find(
                    (sln: SolutionRecord) =>
                        sln.id === situationRelationship.relatedSolutionId
                );
                const situation = props.selectedSituations.find(
                    (situation: SituationRecord) =>
                        situation.id ===
                        situationRelationship.relatedSituationId
                );
                if (solution == null && situation == null) {
                    return {
                        id: uuid.v4(),
                        text: "",
                    };
                }

                const record: SolutionRecord | SituationRecord = (solution ??
                    situation)!;

                return {
                    id: `${
                        solution == null
                            ? SearchType.Situation
                            : SearchType.Solution
                    }-${record.id!}`,
                    label: StringUtils.capitalize(
                        solution == null
                            ? SearchType.Situation
                            : SearchType.Solution
                    ),
                    text: (
                        <React.Fragment>
                            <Anchor
                                ariaLabel={`Go to Solution "${record.titleDraft}"`}
                                target={AnchorTargetTypes.Blank}
                                to={record.getRoute()}>
                                <RelatedSolutionTitle>
                                    {record.titleDraft}
                                </RelatedSolutionTitle>
                            </Anchor>
                            <RelatedSolutionDescription>
                                {record.subtitleDraft}
                            </RelatedSolutionDescription>
                        </React.Fragment>
                    ),
                };
            }
        );

    const handleSearchTypeChanged = (selectedOption?: SelectOption) => {
        switch (selectedOption?.value?.toLowerCase()) {
            case SearchType.Situation:
                setSearchType(SearchType.Situation);
                break;
            case SearchType.Solution:
            default:
                setSearchType(SearchType.Solution);
                break;
        }
    };

    const handleSelected = (option: SelectOption) => {
        if (option != null) {
            if (searchType === SearchType.Solution) {
                setSelectedRecord(
                    solutionOptions.find(
                        (s: SolutionRecord) => s.id!.toString() === option.value
                    )
                );
                return;
            }

            setSelectedRecord(
                situationOptions.find(
                    (s: SituationRecord) => s.id!.toString() === option.value
                )
            );
            return;
        }
        // select was cleared; clear search text, selected value, and option list
        setSearchText("");
        setSolutionOptions([]);
        setSelectedRecord(undefined);
    };

    const handleApply = () => {
        if (selectedRecord == null) {
            return;
        }

        props.onSelected(selectedRecord);
        setSolutionOptions([]);
        setSelectedRecord(undefined);
    };

    const handleRemove = (id: string) => {
        const component = SitNavUtils.parseComponentId(id);

        if (component == null) {
            return;
        }

        const isSolution = component.type === SolutionRecord;
        const idNumber = component.id;

        if (isSolution) {
            const index = props.selectedSolutions.findIndex(
                (record: SolutionRecord | SituationRecord) =>
                    record.id === idNumber
            );
            if (index > -1) {
                props.onRemoved(props.selectedSolutions[index]);
            }
            return;
        }

        const index = props.selectedSituations.findIndex(
            (section: SolutionRecord | SituationRecord) =>
                section.id === idNumber
        );
        if (index > -1) {
            props.onRemoved(props.selectedSituations[index]);
        }
        return;
    };

    const handleSearchTextChange = (newValue: string) => {
        // API will be called when search text changes
        // setLoading here prevents the "no options"
        // message in the dropdown from flickering before
        // the API call starts
        setLoading((newValue ?? "").length > 0);
        setSearchText(newValue);
    };

    const handleShouldCloseMenuOnScroll = (e: Event) =>
        e.target != null &&
        ((e.target as HTMLElement).id === SolutionEditorContentAreaId ||
            (e.target as HTMLElement).id === SituationEditorContentAreaId);

    const handleItemsReordered = (startIndex: number, endIndex: number) => {
        let newItems = CollectionUtils.handleDragAndDropReorder(
            props.value,
            startIndex,
            endIndex
        );
        newItems = newItems.map(
            (item: SituationRelationshipRecord, index: number) =>
                item.with({ displaySequenceDraft: index })
        );
        props.onReordered(newItems);
    };

    return (
        <div className={CSS_CLASS_NAME}>
            <Paragraph size={ParagraphSizes.Large}>Related Results</Paragraph>
            <AdminSituationHotSpotImage
                hotSpots={props.value}
                hotSpotImageFile={props.hotSpotImageFile}
                loadingFile={props.loadingFile}
                onHotSpotsChanged={props.onHotSpotUpdate}
                onImageFileSelected={props.onImageSelected}
                situations={props.selectedSituations}
                solutions={props.selectedSolutions}
            />
            <div className={`${CSS_CLASS_NAME}__select-container`}>
                <Select
                    id="search-type-select"
                    onChange={handleSearchTypeChanged}
                    options={SearchTypeOptions}
                    value={searchType}
                />
                <MultiSelect
                    closeMenuOnScroll={handleShouldCloseMenuOnScroll}
                    closeMenuOnSelect={true}
                    dropdownPortal={props.dropdownPortal}
                    escapeClearsValue={true}
                    hideNoOptionsMessage={searchText.length === 0 || loading}
                    isMulti={false}
                    loading={loading}
                    loadingMessage="Searching solutions..."
                    onChange={handleSelected}
                    onSearchChange={handleSearchTextChange}
                    options={getOptions()}
                    placeholder={`Search by ${searchType} title or description...`}
                    renderOption={renderOption}
                    value={[selectedRecord?.id?.toString() ?? ""]}>
                    <Button onClick={handleApply} type={ButtonTypes.Button}>
                        Add
                    </Button>
                </MultiSelect>
            </div>
            <DragAndDropListBox
                actionText="Remove"
                droppableId="admin-situation-solution-section"
                items={listBoxItems()}
                onActionClick={handleRemove}
                onReordered={handleItemsReordered}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default AdminSituationRelationshipsSection;

// #endregion Exports
